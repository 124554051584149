import _uniq from 'lodash/uniq'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { load } from 'redux-localstorage-simple'

import { TRADE_IN_SETTINGS_BEHAVIOR } from './constants'
import {
  bootTradeIn,
  bootTradeInMulti,
  dealershipsSetter,
  tradeInStandaloneDealershipsSetter,
  tradeInStandaloneLoader,
} from './helpers'
import TradeInStandalone from './TradeInStandalone/main'

function TradeInStandaloneApp({ config }) {
  const [canBoot, setCanBoot] = useState(true)
  const { t } = useTranslation()

  const loadDataMulti = useCallback(widget_ids => {
    bootTradeInMulti(JSON.stringify({ data: widget_ids }))
      .then(({ data }) => {
        if (Object.keys(data).length === 0) {
          console.error('Trade-In Standalone: no widgets available')
          setCanBoot(false)
          return
        }

        // это массив из calculateByIdentity (true / false)
        const calculatesByIdentity = Object.values(data).map(dv => {
          return dv.calculateByIdentity
        })

        // если хотя бы 1 false есть в массиве,
        // т.е хотя бы в одном виджете отсутствует возможность расчета ТИ через ИД
        // то всегда расчитываем через список
        const isAtLeastOneFalseExist = Boolean(calculatesByIdentity.find(c => c === false))

        // записываем ДЦ в стор
        let items = []
        for (let i in data) {
          if (data.hasOwnProperty(i)) {
            let dealership = null
            if (data[i].dealership) {
              dealership = {
                name: data[i].dealership.name,
                address: data[i].dealership.address,
              }
            }

            items.push({
              dealership,
              clientName: data[i].clientName,
              widget_name: data[i].widgetName,
              widget_id: i,
              car: null,
              forTradeInStandalone: {
                visual: data[i].visual,
                locale: data[i].locale,
                currency: data[i].currency,
                clientModels: data[i].clientModels,
                disableSmartContractLink: data[i].disableSmartContractLink,
                ownerCarPriceDisable: data[i].ownerCarPriceDisable,
                condition: data[i].condition,
                ownerCarPriceHintText: data[i].ownerCarPriceHintText,
                benefitAmount: data[i].benefitAmount,
                partner: data[i].partner,
                defaultCountryCode: data[i].defaultCountryCode,
                possibleCountryCodes: data[i].possibleCountryCodes,
                priceToolTipEnabled: data[i].priceToolTipEnabled,
                calculateByIdentity: isAtLeastOneFalseExist ? false : data[i].calculateByIdentity,
              },
            })
          }
        }

        dealershipsSetter(items)
        tradeInStandaloneDealershipsSetter(items) // Set Trade In Dealerships

        // загружаем 1ый виджет
        const firstWidget = data[Object.keys(data)[0]]

        const dataFromLocalStorage = load({
          states: ['tradeIn', 'form'],
          namespace: '@kodix/smartContract',
          namespaceSeparator: '/',
        })

        let success = tradeInStandaloneLoader({
          visual: firstWidget.visual,
          locale: firstWidget.locale,
          currency: firstWidget.currency,
          widget_id: Object.keys(data)[0],
          clientModels: firstWidget.clientModels,
          disableSmartContractLink: firstWidget.disableSmartContractLink,
          ownerCarPriceDisable: firstWidget.ownerCarPriceDisable,
          condition: firstWidget.condition,
          ownerCarPriceHintText: firstWidget.ownerCarPriceHintText,
          benefitAmount: firstWidget.benefitAmount,
          dataFromLocalStorage,
          partner: firstWidget.partner,
          defaultCountryCode: firstWidget.defaultCountryCode,
          possibleCountryCodes: firstWidget.possibleCountryCodes,
          priceToolTipEnabled: firstWidget.priceToolTipEnabled,
          defaultCities: firstWidget.defaultCities,
          calculateByIdentity: isAtLeastOneFalseExist ? false : firstWidget.calculateByIdentity,
        })

        if (!success) {
          setCanBoot(false)
        }
      })
      .catch(e => {
        console.error(e)
        setCanBoot(false)
      })
  }, [])

  const loadData = useCallback(widget_id => {
    const dataFromLocalStorage = load({
      states: ['tradeIn', 'form'],
      namespace: '@kodix/smartContract',
      namespaceSeparator: '/',
    })

    bootTradeIn(widget_id)
      .then(
        ({
          data: {
            clientModels,
            currency,
            locale,
            visual,
            benefitAmount,
            disableSmartContractLink,
            ownerCarPriceDisable,
            ownerCarPriceHintText,
            condition,
            partner,
            defaultCountryCode,
            possibleCountryCodes,
            priceToolTipEnabled,
            defaultCities,
            calculateByIdentity,
          },
        }) => {
          if (condition.behaviour === TRADE_IN_SETTINGS_BEHAVIOR.DISABLED) {
            console.error('[smart.contract trade in standalone] trade in is disabled in widget settings')
            setCanBoot(false)
            return false
          }

          let success = tradeInStandaloneLoader({
            visual,
            locale,
            currency,
            widget_id,
            clientModels,
            disableSmartContractLink,
            ownerCarPriceDisable,
            condition,
            ownerCarPriceHintText,
            benefitAmount,
            dataFromLocalStorage,
            partner,
            defaultCountryCode,
            possibleCountryCodes,
            priceToolTipEnabled,
            defaultCities,
            calculateByIdentity,
          })

          if (!success) {
            setCanBoot(false)
          }
        },
      )
      .catch(e => {
        console.error(e)
        setCanBoot(false)
      })
      .finally(() => {})
  }, [])

  useEffect(() => {
    if (!config.widget_id) {
      setCanBoot(false)
      return
    }

    if (typeof config.widget_id == 'string') {
      loadData(config.widget_id)
      return
    }

    if (Array.isArray(config.widget_id)) {
      let uniqueWidgets = _uniq(config.widget_id)

      if (uniqueWidgets.length > 1) {
        loadDataMulti(uniqueWidgets)
      } else {
        loadData(uniqueWidgets[0])
      }
    } else {
      setCanBoot(false)
    }
  }, [config, loadData, loadDataMulti])

  return canBoot ? (
    <TradeInStandalone image={config.tradeInPicture} />
  ) : config.showFailedLoadingComponent ? (
    <p>{t('tradeInS.generalError')}</p>
  ) : null
}

export default TradeInStandaloneApp
