import { createRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import {
  ACTION_TYPES,
  CREDIT_PARTNER,
  CREDIT_SETTINGS_BEHAVIOR,
  CREDIT_STATUS_FAILED,
  CREDIT_STATUS_NO_DATA,
  CREDIT_STATUS_RECEIVED,
  CREDIT_STATUS_SUCCESS,
  FINANCE_USER_BEHAVIOUR,
  FORM_TYPE_BASE,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import { approveCreditOnline, carPriceFromTI, creditAmount, formatPrice, formValidation } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  adjustCreditBuybackPayment,
  adjustInitialFee,
  calculateCreditPrograms,
  enableCredit,
  goToAuthScreen,
  goToIndex,
  setActiveProgram,
  setAdditionalParameter,
  setCreditBuybackPayment,
  setCreditBuybackPaymentPercentage,
  setCreditInitialFee,
  setCreditInitialFeePercentage,
  setCreditPeriod,
  setCreditStatus,
  setFinanceBehaviourByUser,
} from '../../../store/actions'
import { createCreditCalculateProgramsPayload } from '../../../store/helpers/credit'
import { createSmartContractData } from '../../../store/helpers/smart_contract'
import BlockAccordion from '../../Ui/blockAccordion'
import BlockOne from '../../Ui/blockOne'
import Button from '../../Ui/button'
import InputWithRange from '../../Ui/inputWithRange'
import Program from '../../Ui/program'
import Terms from '../../Ui/terms'
import Tooltip from '../../Ui/tooltip'
import TooltipTable from '../../Ui/tooltipTable'
import s from './credit.module.scss'

function Credit({
  form,
  fields,
  currency,
  creditPartner,
  programs,
  status,
  active_program,
  creditAmount,
  tradeIn,
  creditParameters,
  creditBehaviourBySettings,
  showOnlineApprovalButton,
  widget_id,
  periods,
  openedFromApi,
  locale,
  defaultConditions,
  vehicleType,
  promoProgram,
  disableBuybackPayment,
}) {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const creditContent = createRef()

  const creditData = useSelector(createCreditCalculateProgramsPayload)
  const dataToSave = useSelector(state => createSmartContractData(state))

  const debouncedCalculate = useDebouncedCallback(() => dispatch(calculateCreditPrograms(creditData)), 150)

  const calculate = useCallback(() => debouncedCalculate(), [debouncedCalculate])

  useEffect(calculate, [calculate, creditParameters])

  /**
   * Текст + тултип
   * @returns {*}
   */
  const creditTitleText = () => {
    return (
      <Tooltip position="bottom" fullHeight={true} tooltipContent={<TooltipTable type={'creditAmount'} />}>
        <div className={s.title}>
          <div className={s.title__text}>
            {t(`leasing.screen.carPrice.${vehicleType}`)} {formatPrice(creditAmount, currency)}
          </div>
          <div className={s.title__tooltip}>
            <svg width="17" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
                fill="#222222"
              />
            </svg>
          </div>
        </div>
      </Tooltip>
    )
  }

  /**
   * Кредитные параметры - input и range
   */
  const { initialFee, initialFeePercentage, buybackPayment, buybackPaymentPercentage, period } = creditParameters

  const initialFeeMin = (() => {
    const { tradeInEnabled, possibleCarPrice } = tradeIn

    if (tradeInEnabled) {
      return possibleCarPrice || 1
    }

    return 1
  })()

  const [initialFeePercentageMin, initialFeePercentageMax] = (() => {
    const { tradeInEnabled } = tradeIn

    const defaults = defaultConditions?.[period]
    const defaultInitialFeePercentageMin = defaults?.initialFeePercentMin
    const defaultInitialFeePercentageMax = defaults?.initialFeePercentMax
    const defaultsExist = !isNaN(defaultInitialFeePercentageMin) && !isNaN(defaultInitialFeePercentageMax)

    if (tradeInEnabled) {
      let initialFeePercentageMin = Math.round((initialFeeMin * 100) / creditAmount)
      let initialFeePercentageMax = 95

      if (defaultsExist) {
        initialFeePercentageMax = defaultInitialFeePercentageMax

        if (defaultInitialFeePercentageMin > initialFeePercentageMin) {
          initialFeePercentageMin = defaultInitialFeePercentageMin
        }
      }

      return [initialFeePercentageMin, initialFeePercentageMax]
    }

    if (defaultsExist) {
      return [defaultInitialFeePercentageMin, defaultInitialFeePercentageMax]
    }

    return [0, 95]
  })()

  useEffect(() => {
    if (
      promoProgram &&
      promoProgram.enabled &&
      promoProgram.identity &&
      active_program?.id?.toString() !== promoProgram?.identity?.toString()
    ) {
      const p = programs.find(program => program?.id?.toString() === promoProgram?.identity?.toString())

      if (p) {
        chooseProgram(p)
      }
    }
  }, [promoProgram, programs])

  useEffect(() => {
    if (initialFeePercentage < initialFeePercentageMin) {
      dispatch(setCreditInitialFeePercentage(initialFeePercentageMin))
    }

    if (initialFeePercentage > initialFeePercentageMax) {
      dispatch(setCreditInitialFeePercentage(initialFeePercentageMax))
    }
  }, [initialFeePercentage, initialFeePercentageMin, dispatch, initialFeePercentageMax])

  const buybackMax = (() => {
    const { tradeInEnabled, possibleCarPrice } = tradeIn

    if (tradeInEnabled) {
      return creditAmount - possibleCarPrice
    }

    return creditAmount
  })()

  const [buybackPercentageMin, buybackPercentageMax] = (() => {
    const { tradeInEnabled } = tradeIn

    const defaults = defaultConditions?.[period]
    const defaultBuybackPaymentPercentMin = defaults?.buybackPaymentPercentMin
    const defaultBuybackPaymentPercentMax = defaults?.buybackPaymentPercentMax
    const defaultsExist = !isNaN(defaultBuybackPaymentPercentMin) && !isNaN(defaultBuybackPaymentPercentMax)

    if (tradeInEnabled) {
      let buybackPercentageMin = 0
      let buybackPercentageMax = Math.round((buybackMax * 100) / creditAmount) - 5

      if (defaultsExist) {
        buybackPercentageMin = defaultBuybackPaymentPercentMin

        if (defaultBuybackPaymentPercentMax < buybackPercentageMax) {
          buybackPercentageMax = defaultBuybackPaymentPercentMax
        }
      }

      return [buybackPercentageMin, buybackPercentageMax]
    }

    if (defaultsExist) {
      return [defaultBuybackPaymentPercentMin, defaultBuybackPaymentPercentMax]
    }

    return [0, 95]
  })()

  useEffect(() => {
    if (buybackPaymentPercentage > buybackPercentageMax) {
      dispatch(setCreditBuybackPaymentPercentage(buybackPercentageMax))
    }

    if (buybackPaymentPercentage < buybackPercentageMin) {
      dispatch(setCreditBuybackPaymentPercentage(buybackPercentageMin))
    }
  }, [buybackPaymentPercentage, buybackPercentageMax, buybackPercentageMin, dispatch])

  /**
   * Форматированный вывод кредитных параметров
   */
  const formattedInitialFee = formatPrice(initialFee, currency)
  const formattedBuybackPayment = formatPrice(buybackPayment, currency)

  /**
   * Изменение чекбоксов
   */
  const additionalParameterChanged = event => {
    const {
      target: { checked, name },
    } = event
    dispatch(setAdditionalParameter(name, checked))
  }

  /**
   * Выбор программы
   */
  const chooseProgram = program => {
    const creditContentCurrent = creditContent.current

    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS))

    Promise.resolve(dispatch(setActiveProgram(program, true))).then(() => {
      setTimeout(() => {
        creditContentCurrent.scrollTo({
          top: creditContentCurrent.scrollHeight,
          behavior: 'smooth',
        })
      }, 300)
    })
  }

  /**
   * Установка кредитной программы
   */
  const confirmCreditProgram = () => {
    dispatch(setCreditStatus(CREDIT_STATUS_SUCCESS))
    dispatch(enableCredit(true, openedFromApi, false))
  }

  /**
   * Онлайн одобрение
   */
  const approveCreditOnlineHandler = () => {
    const errors = formValidation(form, fields, FORM_TYPE_BASE, locale)

    if (errors.length) {
      dispatch(setCreditStatus(CREDIT_STATUS_SUCCESS))
      dispatch(enableCredit(false))
      dispatch(
        goToAuthScreen({
          action: ACTION_TYPES.ONLINE_APPROVAL,
          screen: '',
          additionalData: {},
        }),
      )
    } else {
      approveCreditOnline(dispatch, widget_id, dataToSave)
    }
  }

  /**
   * Онлайн одобрение - текст на тултипе
   */
  const onlineApprovalTooltip = () => {
    return (
      <div className={s.onlineApprovalTooltip} dangerouslySetInnerHTML={{ __html: t('credit.onlineApprovalHint') }} />
    )
  }

  /**
   * Лейбл к первоначальному взносу - текст или текст + тултип (если выбран ТИ)
   * @returns {string|*}
   */
  const initialFeeLabel = () => {
    if (tradeIn.tradeInEnabled) {
      return (
        <Tooltip
          position="bottom"
          fullHeight={true}
          tooltipContent={t(`credit.creditWithTradeInDescription.${vehicleType}`)}
        >
          <div className={s.label}>
            <div className={s.label__text}>{t('credit.initialFee')}</div>
            <div className={s.label__tooltip}>
              <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
                  fill="#222222"
                />
              </svg>
            </div>
          </div>
        </Tooltip>
      )
    } else {
      return t('credit.initialFee')
    }
  }

  /**
   * Лейбл к остаточному платежу - текст + тултип
   * @returns {*}
   */
  const buybackPaymentLabel = () => {
    return (
      <Tooltip position="bottom" fullHeight={true} tooltipContent={t('credit.buybackPaymentHint')}>
        <div className={s.label}>
          <div className={s.label__text}>{t('credit.buybackPayment')}</div>
          <div className={s.label__tooltip}>
            <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
                fill="#222222"
              />
            </svg>
          </div>
        </div>
      </Tooltip>
    )
  }

  /**
   * Показываем ли кнопку применения кредита
   * @type {*|boolean}
   */
  const showCreditApplyButton = active_program?.id && status === CREDIT_STATUS_RECEIVED

  return (
    <div className={s.wrapper} ref={creditContent}>
      <div className={s.wrapper__top}>
        <div className={s.wrapper__block}>
          <BlockOne title={creditTitleText()}>
            <div className={s.row}>
              <div className={s.row__col50}>
                <InputWithRange
                  label={initialFeeLabel()}
                  inputValue={formattedInitialFee}
                  inputChangeHandler={e => dispatch(setCreditInitialFee(e.target.value))}
                  inputBlurHandler={e => dispatch(adjustInitialFee(e.target.value))}
                  disabled={false}
                  rangeValue={initialFeePercentage}
                  rangeChangeHandler={e => dispatch(setCreditInitialFeePercentage(e.target.value))}
                  rangeValues={{ min: initialFeePercentageMin, max: initialFeePercentageMax }}
                />
              </div>
              {!disableBuybackPayment && (
                <div className={s.row__col50}>
                  <InputWithRange
                    label={buybackPaymentLabel()}
                    inputValue={formattedBuybackPayment}
                    inputChangeHandler={e => dispatch(setCreditBuybackPayment(e.target.value))}
                    inputBlurHandler={e => dispatch(adjustCreditBuybackPayment(e.target.value))}
                    disabled={false}
                    rangeValue={buybackPaymentPercentage}
                    rangeChangeHandler={e => dispatch(setCreditBuybackPaymentPercentage(e.target.value))}
                    rangeValues={{ min: buybackPercentageMin, max: buybackPercentageMax }}
                  />
                </div>
              )}
            </div>
            <div className={[s.row, s.mt16].join(' ')}>
              <div className={s.row__col100}>
                <Terms
                  periods={periods}
                  period={period}
                  setPeriodHandler={p => dispatch(setCreditPeriod(p))}
                  label={t('credit.creditPeriod')}
                />
              </div>
            </div>
            {creditPartner.includes(CREDIT_PARTNER.E_CREDIT) && (
              <div className={[s.row, s.mt10].join(' ')}>
                <div className={s.row__col100}>
                  <BlockAccordion title={t('credit.additionalOptions')}>
                    <div className={s.additions}>
                      <input
                        type="checkbox"
                        className="kdxsc-checkbox"
                        data-project-name={getProjectName()}
                        id="life_insurance"
                        checked={creditParameters.life_insurance}
                        name="life_insurance"
                        onChange={additionalParameterChanged}
                      />
                      <label
                        className="kdxsc-checkbox-label"
                        htmlFor="life_insurance"
                        data-project-name={getProjectName()}
                      >
                        {t('credit.lifeInsurance')}
                      </label>
                    </div>
                  </BlockAccordion>
                </div>
              </div>
            )}
          </BlockOne>
        </div>

        {(status === CREDIT_STATUS_SUCCESS || status === CREDIT_STATUS_RECEIVED) &&
          creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS && (
            <div className={s.wrapper__block}>
              <BlockOne title={t('credit.chooseCreditProgram', { count: programs.length })}>
                <div className={s.programs}>
                  {programs.map(program => {
                    return (
                      <div className={s.programs__item} key={program.id} onClick={() => chooseProgram(program)}>
                        <Program
                          program={program}
                          isActive={program.id === active_program?.id}
                          isPromo={
                            promoProgram?.enabled && program.id.toString() === promoProgram?.identity?.toString()
                          }
                          currency={currency}
                          showRedemptionValue={program.redemptionValue > 0 && buybackPayment > 0}
                          type="credit"
                        />
                      </div>
                    )
                  })}
                </div>
              </BlockOne>
            </div>
          )}

        {status === CREDIT_STATUS_NO_DATA &&
          creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS && (
            <div className={s.wrapper__block}>
              <BlockOne title={t('credit.searchNoProgramsHeader')}>{t('credit.searchNoProgramsContent')}</BlockOne>
            </div>
          )}

        {creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS && (
          <div className={s.wrapper__block}>
            <BlockOne title={t('credit.enabledWithoutCalculations.screenHeader')} centered={true}>
              <div className={s.withoutCalculation}>
                <div className={s.withoutCalculation__text}>{t('credit.enabledWithoutCalculations.screenText')}</div>
                <div className={s.withoutCalculation__button}>
                  <Button
                    text={t('leasing.screen.withoutCalculations.button')}
                    handleClick={() => dispatch(goToIndex())}
                  />
                </div>
              </div>
            </BlockOne>
          </div>
        )}

        {status === CREDIT_STATUS_FAILED &&
          creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS && (
            <div className={s.wrapper__block}>
              <BlockOne title={t('credit.searchFailedHeader')}>{t('credit.searchFailedContent')}</BlockOne>
            </div>
          )}
      </div>

      {(showCreditApplyButton || showOnlineApprovalButton) && (
        <div className={s.wrapper__bottom}>
          <div className={s.footer}>
            {showCreditApplyButton && (
              <div className={s.footer__item}>
                <Button handleClick={confirmCreditProgram} text={t('credit.choose')} type={'bordered'} />
              </div>
            )}

            {showOnlineApprovalButton && (
              <div className={s.footer__item}>
                {!active_program.id && (
                  <Tooltip position="top" tooltipContent={onlineApprovalTooltip()} fullHeight={false}>
                    <Button text={t('credit.onlineApproval')} />
                  </Tooltip>
                )}

                {active_program?.id && (
                  <Button handleClick={approveCreditOnlineHandler} text={t('credit.onlineApproval')} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  form,
  settings: {
    currency,
    widget_id,
    visual: { fields },
    locale,
    vehicleType,
  },
  car: { price },
  benefits: { selected },
  accessories: { selected: selectedAccessories },
  credit: {
    periods,
    programs,
    status,
    partner: creditPartner,
    active_program,
    parameters: creditParameters,
    benefitValue: creditBenefitAmount,
    behaviour: creditBehaviourBySettings,
    onlineApprove: showOnlineApprovalButton,
    defaultConditions,
    promoProgram,
    disableBuybackPayment,
  },
  tradeIn: {
    rate: {
      result: { possibleCarPrice },
    },
    behaviourByUser: tradeInBehaviorByUser,
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    ownerCarPriceEnabled,
    ownerCarPrice,
  },
  api: { openedFromApi },
}) {
  const tradeInEnabled = tradeInBehaviorByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  return {
    periods,
    form,
    fields,
    creditBehaviourBySettings,
    currency,
    widget_id,
    creditPartner,
    creditBenefitAmount,
    creditParameters,
    programs,
    status,
    active_program,
    showOnlineApprovalButton,
    creditAmount: creditAmount({
      price,
      selectedBenefits: selected,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviorByUser,
      tradeInBenefitAmount,
      tradeInBenefitAmountWithoutTradeIn,
      creditBenefitAmount,
    }),
    tradeIn: {
      tradeInEnabled,
      possibleCarPrice: carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled),
    },
    openedFromApi,
    locale,
    defaultConditions,
    vehicleType,
    promoProgram,
    disableBuybackPayment,
  }
}

export default connect(mapStateToProps)(Credit)
