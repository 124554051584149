const INIT_STATE = {
  price: 0,
  finalPrice: 0,
  priceWithAllDiscounts: 0,
  totalPayment: 0,
  identity: '',
  url: '',
  image: '',
  name: '',
  modelData: '',
  productType: '',
  discounts: {
    credit: 0,
    tradein: 0,
    credit_tradein: 0,
    insurance: 0,
  },
  additionalOptions: {
    cost: 0,
    list: [],
  },
}

const car = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_CAR_NAME':
      return {
        ...state,
        name: action.name,
      }
    case 'SET_CAR_ADDITIONAL_OPTIONS':
      return {
        ...state,
        additionalOptions: action.additionalOptions,
      }
    case 'SET_MODEL_DATA':
      return {
        ...state,
        modelData: action.modelData,
      }
    case 'SET_CAR_PRICE':
      return {
        ...state,
        price: action.price,
        finalPrice: action.price,
      }
    case 'SET_ADDITIONAL_CAR_PRICES':
      return {
        ...state,
        finalPrice: action.payload.finalPrice,
        totalPayment: action.payload.totalPayment,
        priceWithAllDiscounts: action.payload.priceWithAllDiscounts,
      }
    case 'SET_CAR_IDENTITY':
      return {
        ...state,
        identity: action.identity,
      }
    case 'SET_CAR_URL':
      return {
        ...state,
        url: action.url,
      }
    case 'SET_CAR_IMAGE':
      return {
        ...state,
        image: action.image,
      }
    case 'SET_PRODUCT_TYPE':
      return {
        ...state,
        productType: action.productType,
      }
    case 'SET_DISCOUNTS':
      return {
        ...state,
        discounts: action.discounts,
      }

    default:
      return state
  }
}

export default car
