import { connect, useDispatch } from 'react-redux'

import { setTradeInGeneration } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneGenerationDropdown({ generations, id }) {
  const dispatch = useDispatch()

  const onSet = ({ id, name, external }) => {
    dispatch(setTradeInGeneration({ id, name, external }))
  }

  return (
    <TradeInStandalonePartsRadiobuttons
      name="generation"
      variants={generations}
      checked={id}
      onSet={onSet}
      search={''}
    />
  )
}

function mapStateToProps({
  tradeIn: {
    values: {
      generation: { id },
    },
    dictionaries: { generations },
  },
}) {
  return {
    id,
    generations,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneGenerationDropdown)
