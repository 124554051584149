module.exports = {
  accessories: 'Accessories',

  showAll: 'Show all',
  chooseMore: 'Choose more',

  selectedAccessoriesWithCarName: 'Accessories selected for your {carName}',
  selectedAccessoriesWithoutCarName: {
    auto: 'Accessories selected for your new car',
    moto: 'Accessories selected for your new motorcycle',
    other: 'Accessories selected for your new vehicle',
  },

  selectAccessoriesWithCarName: 'You can choose additional accessories for your {carName}',
  selectAccessoriesWithoutCarName: {
    auto: 'You can choose additional accessories for your new car',
    moto: 'You can choose additional accessories for your new motorcycle',
    other: 'You can choose additional accessories for your new vehicle',
  },

  addToPurchase: 'Add to purchase',

  selectedAccessories: 'Selected accessories',

  shortDescription: 'Short description',
  fullDescription: 'Full description',

  showAllOnScreen: 'Show all',
  showSelectedOnScreen: 'Show selected',
  removeAllOnScreen: 'Clear the selection',

  whatDoYouNeedToRemove: 'To add this accessory you need to remove the "{accessory}" accessory',
}
