import i18n from 'i18next'

import {
  FINANCE_USER_BEHAVIOUR,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import { formatPrice } from '../../helpers'

const textFormatter = value => {
  if (!value) {
    return {
      topText: '',
      bottomText: '',
      areFilled: false,
    }
  }

  let initialValue = value.trim()
  let splittedValue = initialValue.split('|')

  let topText = splittedValue[0].trim().slice(0, 18)
  let bottomText = ''

  if (splittedValue[1]) {
    bottomText = splittedValue[1].trim().slice(0, 18)
  }

  return {
    topText,
    bottomText,
    areFilled: true,
  }
}

export const dataForCarsPointHelper = ({
  dataFromLocalStorage,
  isSCLinkExists,
  isSCLinkExistsFromCCS,
  currency,
  textFromBe,
}) => {
  let { topText, bottomText, areFilled } = textFormatter(textFromBe)

  let data = {
    top: areFilled ? topText : i18n.t(`bullet.cars.top`),
    bottom: areFilled ? bottomText : i18n.t(`bullet.cars.bottom`),
    isFilled: false,
  }

  // если есть сохраненный СК или данные о покупаемой машине в ЛС, то кнопка заполнена и данные записаны
  const {
    car: { identity, price, name },
  } = dataFromLocalStorage

  const necessaryDataForText = identity && price && name

  if (isSCLinkExists || necessaryDataForText || isSCLinkExistsFromCCS) {
    let formattedName = ''

    if (isSCLinkExists || isSCLinkExistsFromCCS) {
      formattedName = i18n.t(`bullet.open`)
    } else if (name?.length) {
      formattedName = name

      if (formattedName.length > 14) {
        formattedName = formattedName.slice(0, 11) + '...'
      }
    }

    data.top = formattedName
    data.bottom = price ? formatPrice(price, currency) : i18n.t(`bullet.sc`)
    data.isFilled = true
  }
  // иначе по умолчанию (текст) и кнопка не заполнена

  return data
}

export const dataForTradeInPointHelper = ({
  dataFromLocalStorage,
  isSCLinkExists,
  isSCLinkExistsFromCCS,
  currency,
  textFromBe,
}) => {
  let { topText, bottomText, areFilled } = textFormatter(textFromBe)

  let data = {
    top: areFilled ? topText : i18n.t(`bullet.trade_in.top`),
    bottom: areFilled ? bottomText : i18n.t(`bullet.trade_in.bottom`),
    isFilled: false,
  }

  // если есть данные про трейд-ин
  const behaviorByWidget = dataFromLocalStorage?.tradeIn?.behaviour
  const behaviorByUser = dataFromLocalStorage?.tradeIn?.behaviourByUser
  const tradeInRateStatus = dataFromLocalStorage?.tradeIn?.rate?.status

  const isBehaviorByUserAvailable = [TRADE_IN_USER_BEHAVIOUR.APPLIED, TRADE_IN_USER_BEHAVIOUR.STARTED].includes(
    behaviorByUser,
  )
  const isBehaviorByWidgetAvailable = [
    TRADE_IN_SETTINGS_BEHAVIOR.ENABLED,
    TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
  ].includes(behaviorByWidget)

  const isStatusAvailable = [
    TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE,
    TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE,
    TRADE_IN_RATE_STATUS.FAILED,
  ].includes(tradeInRateStatus)

  if (isStatusAvailable && isBehaviorByUserAvailable && isBehaviorByWidgetAvailable) {
    let formattedTopText = ''
    let formattedBottomText = ''

    let isPossibleCarPriceDefined = dataFromLocalStorage?.tradeIn?.rate?.result?.possibleCarPrice // может быть 0 +
    let isOwnerCarPriceDefined = dataFromLocalStorage?.tradeIn?.ownerCarPrice // может быть 0 +

    let brand = dataFromLocalStorage?.tradeIn?.values?.brand?.name // должен быть
    let model = dataFromLocalStorage?.tradeIn?.values?.model?.name // должен быть

    const isPriceDefined = isPossibleCarPriceDefined > 0 || isOwnerCarPriceDefined > 0

    if (isPriceDefined) {
      formattedTopText = `${i18n.t(`bullet.my`)} ${brand}` || i18n.t(`bullet.yourCar`)
      formattedBottomText = isOwnerCarPriceDefined
        ? formatPrice(isOwnerCarPriceDefined, currency)
        : formatPrice(isPossibleCarPriceDefined, currency)
    } else {
      formattedTopText = `${i18n.t(`bullet.my`)} ${brand}` || i18n.t(`bullet.yourCar`)
      formattedBottomText = model || i18n.t(`bullet.inTradeIn`)
    }

    data.top = formattedTopText
    data.bottom = formattedBottomText
    data.isFilled = true
  }

  if (isSCLinkExists || isSCLinkExistsFromCCS) {
    data.isFilled = true
  }
  // иначе по умолчанию (текст) и кнопка не заполнена

  return data
}

export const dataForCreditPointHelper = ({
  dataFromLocalStorage,
  dataFromLocalStorageCreditStandalone,
  isSCLinkExists,
  isSCLinkExistsFromCCS,
  currency,
  textFromBe,
}) => {
  let { topText, bottomText, areFilled } = textFormatter(textFromBe)

  let data = {
    top: areFilled ? topText : i18n.t(`bullet.credit.top`),
    bottom: areFilled ? bottomText : i18n.t(`bullet.credit.bottom`),
    isFilled: false,
  }

  // если есть данные по кредитной программе
  let payment = dataFromLocalStorage?.credit?.active_program?.payment
  let behaviorByUser = dataFromLocalStorage?.credit?.behaviourByUser
  let paymentCCS = dataFromLocalStorageCreditStandalone?.credit_SA?.selectedProgram?.payment

  let isProgramFromAVNWidgetExists = payment && behaviorByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED
  let isProgramFromCCSExists = paymentCCS

  if (isProgramFromAVNWidgetExists || isProgramFromCCSExists) {
    data.top = i18n.t(`bullet.credit.bottom`)
    data.isFilled = true

    if (isProgramFromAVNWidgetExists) {
      data.bottom = formatPrice(payment, currency) + '/M'
    } else if (isProgramFromCCSExists) {
      data.bottom = formatPrice(paymentCCS, currency) + '/M'
    }
  }

  if (isSCLinkExists || isSCLinkExistsFromCCS) {
    data.isFilled = true
  }
  // иначе по умолчанию (текст) и кнопка не заполнена

  return data
}
