import i18n from 'i18next'
import { load } from 'redux-localstorage-simple'

import { setActiveDealership } from '../../store/actions'
import { setBuyModelStandaloneActiveDealership } from '../../store/actions/buy_model_standalone'
import {
  setTradeInStandaloneActiveDealership,
  setTradeInStandaloneIsWidgetLoaded,
  setTradeInStandaloneIsWidgetLoadingFailed,
} from '../../store/actions/trade_in_standalone'
import store from '../../store/store'
import { bootMainInfo, buyModelStandaloneLoader, tradeInStandaloneLoader } from '..'

const closeDropdownEvent = new CustomEvent('kdxscCloseDropdown')

/**
 * Установка активного ДЦ для стора виджета с дозагрузкой информации
 * @param d - дц
 * @param config- конфиг
 */
export const setActiveDealershipFromMainStore = (d, config) => {
  const state = store.getState()

  const {
    settings: { calledFromList },
    car: { modelData, discounts, productType, additionalOptions },
  } = state

  store.dispatch(setActiveDealership(d))

  // eslint-disable-next-line no-case-declarations
  const dataFromLocalStorage = load({
    states: ['tradeIn', 'credit', 'benefits', 'form', 'leasing'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  // eslint-disable-next-line no-case-declarations
  let car = d.cars.find(car => car.status === 'available') || d.cars[0]
  if (!car) {
    return
  }

  bootMainInfo(
    {
      widget_id: d.widgetId,
      pagePrice: car.price,
      safeIdentity: car.identity,
      modelData: modelData,
      productType: productType,
      calledFromList,
      discounts: discounts,
      header_text: null,
      form_agreement_text: null,
      palette: null,
      detail: null,
      additionalOptions,
    },
    config,
    dataFromLocalStorage,
    false,
  )

  window.dispatchEvent(closeDropdownEvent)
}

/**
 * Установка активного ДЦ для стора buy_model без доп запросов на БЭ
 * @param d - дц
 */
export const setActiveDealershipFromBuyModelStore = d => {
  store.dispatch(setBuyModelStandaloneActiveDealership(d))

  buyModelStandaloneLoader({
    visual: d.forBuyModel.visual,
    locale: d.forBuyModel.locale,
    currency: d.forBuyModel.currency,
    widgetId: d.widgetId,
    clientModels: d.forBuyModel.clientModels,
    disableSmartContractLink: d.forBuyModel.disableSmartContractLink,
    defaultCountryCode: d.forBuyModel.defaultCountryCode,
    possibleCountryCodes: d.forBuyModel.possibleCountryCodes,
    initialBoot: false,
    dataFromLocalStorage: null,
  })

  window.dispatchEvent(closeDropdownEvent)
}

/**
 * Установка активного ДЦ для ТИ стэндэлона без запроса на БЭ
 * @param d - дц
 */
export const setActiveDealershipInTradeInStandalone = d => {
  store.dispatch(setTradeInStandaloneIsWidgetLoaded(true))
  store.dispatch(setTradeInStandaloneIsWidgetLoadingFailed(false))

  store.dispatch(setTradeInStandaloneActiveDealership(d))

  const dataFromLocalStorage = load({
    states: ['tradeIn', 'form'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  let success = tradeInStandaloneLoader({
    visual: d?.forTradeInStandalone?.visual,
    locale: d?.forTradeInStandalone?.locale,
    currency: d?.forTradeInStandalone?.currency,
    widget_id: d.widgetId,
    clientModels: d?.forTradeInStandalone?.clientModels,
    disableSmartContractLink: d?.forTradeInStandalone?.disableSmartContractLink,
    ownerCarPriceDisable: d?.forTradeInStandalone?.ownerCarPriceDisable,
    condition: d?.forTradeInStandalone?.condition,
    ownerCarPriceHintText: d?.forTradeInStandalone?.ownerCarPriceHintText,
    benefitAmount: d?.forTradeInStandalone?.benefitAmount,
    dataFromLocalStorage,
    partner: d?.forTradeInStandalone?.partner,
    defaultCountryCode: d?.forTradeInStandalone?.defaultCountryCode,
    possibleCountryCodes: d?.forTradeInStandalone?.possibleCountryCodes,
    priceToolTipEnabled: d?.forTradeInStandalone?.priceToolTipEnabled,
    defaultCities: d?.forTradeInStandalone?.defaultCities,
    calculateByIdentity: d?.forTradeInStandalone?.calculateByIdentity,
  })

  window.dispatchEvent(closeDropdownEvent)

  if (!success) {
    store.dispatch(setTradeInStandaloneIsWidgetLoadingFailed(true))
  }
  store.dispatch(setTradeInStandaloneIsWidgetLoaded(false))

  return success
}

/**
 * Проверка, что выбранный ДЦ не активный
 * @param activeDealership
 * @param d
 * @returns {boolean}
 */
export const isSelectedDealershipIsNotActive = (activeDealership, d) => {
  const closeDropdownEvent = new CustomEvent('kdxscCloseDropdown')

  if (activeDealership.widgetId === d.widgetId) {
    window.dispatchEvent(closeDropdownEvent)
    return false
  }

  return true
}

/**
 * Сообщение об ошибках в компоненте ДЦ
 * @param hasDealershipError
 * @param isWidgetLoadingFailed
 * @returns {{hasErrors: boolean, errorsText: boolean}|{hasErrors: boolean, errorsText: string}}
 */
export const dealershipsErrors = (hasDealershipError, isWidgetLoadingFailed) => {
  let res = {
    hasErrors: false,
    errorsText: false,
  }

  if (hasDealershipError) {
    return {
      hasErrors: true,
      errorsText: i18n.t('carOrderForm.requiredFiled'),
    }
  }

  if (isWidgetLoadingFailed) {
    return {
      hasErrors: true,
      errorsText: i18n.t('dealerships.errorMessage'),
    }
  }

  return res
}

/**
 * Заполнен ли ДЦ правильно
 * @param dealerships
 * @param activeDealership
 * @param isWidgetLoadingFailed
 * @returns {*|boolean}
 */
export const isDealershipValid = (dealerships, activeDealership, isWidgetLoadingFailed) => {
  if (dealerships?.length > 1) {
    return activeDealership?.widgetId && !isWidgetLoadingFailed
  } else {
    return true
  }
}
