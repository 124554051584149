import './_theme_generic_v2.scss'

import { forwardRef } from 'react'

import { getProjectName } from '../../../../helpers/app/detectors/project_name'
import ClearIcon from '../../../../svgComponents/clear'
import s from './input.module.scss'

const Input = forwardRef((props, ref) => {
  return (
    <div
      className={[s.fieldBox, props.useWidgetStyles ? s.fieldBoxInWidget : ''].join(' ')}
      data-project-name={getProjectName()}
    >
      <div className={s.inputBox}>
        <input
          value={props.value || ''}
          className={[s.input, props.errors ? s.inputWithErrors : '', props.forCaptcha ? s.forCaptcha : ''].join(' ')}
          ref={ref}
          id={props.id}
          name={props.name}
          placeholder=" "
          type={props.type || 'text'}
          onChange={e => props.handleChange(e.target.value)}
          autoComplete="off"
        />
        <label htmlFor={props.id} className={s.label}>
          {props.label} {props.required && '*'}
        </label>
        {props.value && (
          <div className={s.clear} onClick={() => props.handleChange('')}>
            <ClearIcon />
          </div>
        )}
      </div>
      {<div className={s.errorsText}>{props.errorsText}</div>}
    </div>
  )
})

export default Input
