import { connect, useDispatch } from 'react-redux'

import { setTradeInYear } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneYearDropdown({ years, value }) {
  const dispatch = useDispatch()

  const onSet = ({ id }) => {
    dispatch(setTradeInYear({ value: id }))
  }

  return <TradeInStandalonePartsRadiobuttons name="year" variants={years} checked={value} onSet={onSet} search={''} />
}
function mapStateToProps({
  tradeIn: {
    values: {
      year: { value },
    },
    dictionaries: { years },
  },
}) {
  return {
    value,
    years,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneYearDropdown)
