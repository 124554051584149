import { FINANCE_USER_BEHAVIOUR, LEASING_RATE_STATUS, LEASING_SETTINGS_BEHAVIOR } from '../../../constants'
import {
  bootLeasingParameters,
  clearLeasingActiveProgram,
  setFinanceBehaviourByUser,
  setLeasingActiveProgram,
  setLeasingPeriods,
  setLeasingStaticInfo,
  setLeasingStatus,
} from '../../../store/actions'
import store from '../../../store/store'
import { leasingAmount } from '../../leasing_amount'
import { periodsFormatter } from './periods'

export const leasingSetter = (
  isLeasingEnabledFromSettings,
  isCreditEnabledFromSettings,
  leasing,
  discounts,
  isCustomBenefitsOn,
  price,
  dataFromLocalStorage,
) => {
  // discounts за лизинг пока нет

  const selectedBenefits = store.getState().benefits.selected
  const selectedAccessories = store.getState().accessories.selected

  const amount = leasingAmount(price, selectedBenefits, selectedAccessories, leasing.condition.benefitValue)

  // устанавливаем статичную инфу - она не изменится
  store.dispatch(
    setLeasingStaticInfo({
      behaviour: leasing.condition.behaviour,
      benefitValue: leasing.condition.benefitValue,
      partner: leasing.partner,
      condition: leasing.condition,
    }),
  )

  // если лизинг включен в настройках виджета
  if (isLeasingEnabledFromSettings) {
    const { periodsForStore, numericPeriods } = periodsFormatter(leasing.possiblePeriods)
    store.dispatch(setLeasingPeriods(periodsForStore))

    // устанавливаем начальные параметры
    const initialFeePercentageFromLS = dataFromLocalStorage?.leasing?.parameters?.initialFeePercentage
    let initialFeePercentage = leasing?.defaultInitialFeePercentage || 0

    const defaultBuybackPaymentPercentage = leasing?.defaultBuybackPaymentPercentage || 0

    // лизинг может быть только [0, 50]%
    if (initialFeePercentage > 50) {
      initialFeePercentage = 50
    }

    // лизинг может быть только [0, 50]%
    if (initialFeePercentageFromLS > 0 && initialFeePercentageFromLS <= 50) {
      initialFeePercentage = initialFeePercentageFromLS
    }

    // можем ставить только допустимый год в ЛС, иначе с БЭ
    const periodFromLS = parseInt(dataFromLocalStorage?.leasing?.parameters?.period)
    let period = parseInt(leasing?.defaultPeriod)
    if ([numericPeriods].includes(periodFromLS)) {
      period = periodFromLS
    }

    store.dispatch(
      bootLeasingParameters(
        {
          initialFeePercentage: initialFeePercentage,
          buybackPaymentPercentage: defaultBuybackPaymentPercentage,
          period: period,
        },
        amount,
      ),
    )

    if (leasing.condition.behaviour === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
      store.dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.NONE))
      store.dispatch(setLeasingStatus(LEASING_RATE_STATUS.INITIAL))
      store.dispatch(setLeasingActiveProgram({}))
    }

    // сбрасываем активную программу, если с бека пришли настройки c партнёром, отличным от партнёра из ЛС
    if (dataFromLocalStorage?.leasing.partner && dataFromLocalStorage.leasing.partner !== leasing.partner) {
      store.dispatch(clearLeasingActiveProgram())
    }
  } else {
    if (!isCreditEnabledFromSettings) {
      store.dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.NONE))
    }
    store.dispatch(setLeasingStatus(LEASING_RATE_STATUS.INITIAL))
    store.dispatch(setLeasingActiveProgram({}))
  }
}
