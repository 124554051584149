import s from './form.module.scss'

function FormBlock({ children, title, centered }) {
  return (
    <div className={s.block}>
      {title && <div className={[s.block__title, centered ? s.centered : ''].join(' ')}>{title}</div>}
      <div className={s.block__content}>{children}</div>
    </div>
  )
}

export default FormBlock
