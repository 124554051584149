module.exports = {
  state: {
    subtitleWithBenefit: 'Leasingkauf mit Vorteil',
    subtitleWithoutBenefit: 'Leasingkauf mit Vorteil',
    initialAdditionalText: 'Leasingprogramm auswählen',
    initialAdditionalTextWithoutEstimation: 'Leasingparameter auswählen',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } mo.',
    additionalTextWithCreditDataWithoutRate: '{ bank }. { program }, { period } mo.',
    additionalTextWithCreditDataWithoutRateAndBankName: '{ program }, { period } mo.',
    additionalTextWithCreditDataWithoutBankName: '{ program }, { rate }%, { period } mo.',
    additionalTextWithCreditParameters:
      'Sie haben das Leasing mit der Anzahlung { initialFee } für eine Vertragslaufzeit von { period } Monaten ausgewählt',
    autoEstimationIsImpossible: 'Automatische Schätzung ist nicht möglich',
    unavailable: 'nicht verfügbar',
    // benefitHint: 'Dieser Vorteil gilt beim Leasingkauf dieses Fahrzeugs',
    benefitHint: {
      auto: 'This advantage applies in the case of buying this car on leasing',
      moto: 'This advantage applies in the case of buying this motorcycle on leasing',
      other: 'This advantage applies in the case of buying this vehicle on leasing',
    },
  },

  screen: {
    title: 'Leasingparameter in Höhe von {amount}',
    subtitle: 'Ihr Leasingvorteil von { value } wurde bereits berücksichtigt',
    chooseCreditProgram: 'Leasingprogramm auswählen ({count} found)',
    // carPrice: 'Fahrzeugpreis inklusive Vorteile und Services',
    carPrice: {
      auto: 'Car price including benefits and services',
      moto: 'Motorcycle price including benefits and services',
      other: 'Vehicle price including benefits and services',
    },
    initialFee: 'Vorauszahlung',
    buybackPayment: 'Trade-in price',
    term: 'Leasinglaufzeit',

    withoutCalculations: {
      title: 'Sie können die geeignetsten Leasingbedingungen für sich auswählen',
      button: 'Anwenden',
      body: 'Die automatische Berechnung des Leasingprogramms ist leider nicht verfügbar. Die von Ihnen eingegebenen Daten werden an Ihren Ansprechpartner zur Angebotserstellung gesendet',
    },

    noPrograms: {
      title: 'Leider wurden keine Programme gefunden',
      body: 'Bitte wählen Sie andere Parameter aus',
    },

    error: {
      title: 'Leider ist bei der Programmsuche ein Fehler aufgetreten',
      body: 'Bitte versuchen Sie es später noch einmal oder wählen Sie andere Optionen',
    },
  },
}
