import { useEffect, useState } from 'react'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './input.module.scss'

function InputWithRange({
  inputValue,
  rangeValue,
  inputChangeHandler,
  disabled,
  rangeChangeHandler,
  label,
  rangeValues,
  inputBlurHandler,
  isOnlyPercent,
  isOnlyValues,
}) {
  const [isSliderActive, setIsSliderActive] = useState(false)

  const handleRangeChange = e => {
    rangeChangeHandler(e)
    setIsSliderActive(true)
  }

  useEffect(() => {
    if (rangeValue) {
      setIsSliderActive(true)
    }
  }, [rangeValue])

  const rangeInputStyle =
    getProjectName() === 'generic_v2'
      ? {
          background: `linear-gradient(to right, #828282 ${
            ((rangeValue - rangeValues.min) / (rangeValues.max - rangeValues.min)) * 100
          }%, #2c2c2c ${((rangeValue - rangeValues.min) / (rangeValues.max - rangeValues.min)) * 100}%)`,
        }
      : {}

  return (
    <div className={s.wrapper} data-only-percent={isOnlyPercent} data-only-values={isOnlyValues}>
      <label className={s.wrapper__label}>{label}</label>
      <div className={`${s.wrapper__input} ${isSliderActive ? s.wrapper__input_active : ''}`}>
        <input
          type="text"
          value={inputValue}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
          className={s.input}
        />
        {!isOnlyValues && !isOnlyPercent && <div className={s.percentage}>{rangeValue}%</div>}
      </div>
      <div className={s.wrapper__range}>
        <input
          disabled={disabled}
          type="range"
          className={`${s.range} ${isSliderActive ? s.range_active : ''}`}
          min={rangeValues.min}
          max={rangeValues.max}
          step="1"
          value={rangeValue}
          name=""
          onChange={handleRangeChange}
          style={rangeInputStyle}
        />
      </div>
      {!isOnlyValues && (
        <div className={s.wrapper__percentages}>
          <div className={s.percentages}>
            <div className={s.percentages__item}>{rangeValues.min}%</div>
            <div className={s.percentages__item}>{rangeValues.max}%</div>
          </div>
        </div>
      )}
    </div>
  )
}
export default InputWithRange
