import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatPrice } from '../../../helpers'
import Tooltip from '../tooltip'
import s from './program.module.scss'

function Program({ program, currency, isActive, isPromo, type, showRedemptionValue, onlyOneBank }) {
  const { t } = useTranslation()

  const rate = () => {
    if (type === 'credit') {
      return program.rate
    }

    if (type === 'leasing') {
      return program.percentage
    }

    return ''
  }

  const payment = () => {
    if (type === 'credit') {
      return program.payment
    }

    if (type === 'leasing') {
      return program.paymentPerMonth
    }

    return 0
  }

  const position = window.innerWidth > 500 ? 'left' : 'top'

  const hideProgramPercentage = useSelector(state => state.settings.visual.leasing.hideProgramPercentage)
  const hideProgramBankName = useSelector(state => state.settings.visual.leasing.hideProgramBankName)

  const showProgramBankName = !(type === 'leasing' && onlyOneBank && hideProgramBankName)
  const showProgramPercentage = !(type === 'leasing' && hideProgramPercentage)

  return (
    <div className={[s.program, isActive ? s.active : '', isPromo ? s.promo : ''].join(' ')}>
      <div className={s.program__main}>
        <div className={s.main}>
          <div className={s.main__item}>
            <div className={s.title}>{program.name}</div>
          </div>
          <div className={s.main__item}>
            <div className={s.values}>
              <div className={s.values__item}>{showProgramPercentage && <div className={s.rate}>{rate()}%</div>}</div>
              <div className={s.values__item}>
                <div className={s.payment}>
                  {t('credit.pricePerMonth', {
                    value: formatPrice(payment(), currency),
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.program__addition}>
        <div className={s.addition__item}>
          {showProgramBankName && <div className={s.bank}>{program.bank.name}</div>}
          {!showProgramBankName && <div className={s.bank}>&nbsp;</div>}
        </div>
      </div>

      {program?.buybackPayment && (
        <div className={s.program__addition}>
          <div className={s.addition__item}>
            <div className={s.bayBack}>
              {t('leasing.screen.buybackPayment')}: {formatPrice(program.buybackPayment)}
            </div>
          </div>
        </div>
      )}

      {program.description && (
        <div className={s.program__description}>
          <Tooltip position={position} tooltipContent={program.description}>
            <div className={s.more}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.00008 11.3334C3.05448 11.3334 0.666748 8.94568 0.666748 6.00008C0.666748 3.05448 3.05448 0.666748 6.00008 0.666748C8.94568 0.666748 11.3334 3.05448 11.3334 6.00008C11.3334 8.94568 8.94568 11.3334 6.00008 11.3334ZM6.00008 10.2667C8.3565 10.2667 10.2667 8.3565 10.2667 6.00008C10.2667 3.64367 8.3565 1.73341 6.00008 1.73341C3.64367 1.73341 1.73341 3.64367 1.73341 6.00008C1.73341 8.3565 3.64367 10.2667 6.00008 10.2667ZM5.46675 3.33341H6.53341V4.40008H5.46675V3.33341ZM5.46675 5.46675H6.53341V8.66675H5.46675V5.46675Z"
                  fill="#222222"
                />
              </svg>
              {t('credit.moreAboutProgram')}
            </div>
          </Tooltip>
        </div>
      )}

      {showRedemptionValue && (
        <div className={[s.program__redemption, program.description ? s.mb20 : ''].join(' ')}>
          <div className={s.redemption}>
            <div className={s.redemption__text}>{t('credit.redemptionPrice')}</div>
            <div className={s.redemption__price}>{formatPrice(program.redemptionValue, currency)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Program
