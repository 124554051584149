import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TRADE_IN_STANDALONE_SCREEN } from '../../../constants'
import { setTradeInStandaloneScreen } from '../../../store/actions'
import s from './styles/data.module.scss'

function PersonalData({ name, lastName, phone, email, idSC, disabled }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const topInfo = name + ' ' + lastName

  const bottomInfoItem = (data, index) => {
    return (
      <div className={s.bottom__item} key={index}>
        {data.value}
      </div>
    )
  }

  const bottomInfo = () => {
    const res = []

    if (phone) {
      res.push({
        value: phone,
      })
    }

    if (email) {
      res.push({
        value: email,
      })
    }

    if (res.length) {
      return res.map((r, index) => {
        return bottomInfoItem(r, index)
      })
    } else {
      return null
    }
  }

  const goToEditPersonalDataScreen = () => {
    dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM))
  }

  return (
    <div className={s.wrapper}>
      <div className={s.wrapper__title}>
        <div className={s.title}>
          <div className={s.title__text}>{t('personalData.yourPersonalData')}</div>
          {!idSC && !disabled && (
            <div className={s.title__button}>
              <div className={s.button} onClick={goToEditPersonalDataScreen}>
                <div className={s.button__icon}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.3322 5.92676L10.0732 4.66783L1.78067 12.9604V14.2193H3.0396L11.3322 5.92676ZM12.5911 4.66783L13.85 3.4089L12.5911 2.14997L11.3322 3.4089L12.5911 4.66783ZM3.7768 16H0V12.2223L11.9616 0.260678C12.3093 -0.0868926 12.8729 -0.0868926 13.2206 0.260678L15.7393 2.77943C16.0869 3.12711 16.0869 3.69069 15.7393 4.03836L3.77769 16H3.7768Z"
                      fill="#222222"
                    />
                  </svg>
                </div>
                <div className={s.button__text}>{t('edit')}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={s.wrapper__inner}>
        <div className={s.inner}>
          {topInfo && <div className={s.inner__top}>{topInfo}</div>}
          {bottomInfo() && (
            <div className={s.inner__bottom}>
              <div className={s.bottom}>{bottomInfo()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ form: { values }, saved_sc: { id: idSC } }) {
  const { name, lastName, phone, email } = values

  return {
    name,
    lastName,
    phone,
    email,
    idSC,
  }
}

export default connect(mapStateToProps)(PersonalData)
