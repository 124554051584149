module.exports = {
  trade_in: {
    top: 'berechne',
    bottom: 'Inzahlungnahme',
  },

  credit: {
    top: 'berechne',
    bottom: 'Finanzierung',
  },

  cars: {
    top: 'Fahrzeuge',
    bottom: 'auf Bestand',
  },

  open: 'öffnen',
  sc: 'Smartvertrag',
  my: 'mein',
  yourCar: 'Ihr Auto',
  inTradeIn: 'bei Inzahlungnahme',
}
