import s from './loader.module.scss'

function Loader() {
  return (
    <div className={s.loading}>
      <div className={s.loading__overlay}>
        <div className={s.roller}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
