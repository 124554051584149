import s from './styles/radioButton.module.scss'

function RadioButton({ isChecked, id, value, text, name, onChangeHandler, radiosType }) {
  const classes = () => {
    let c = [s.label]

    if (radiosType && radiosType === 'highlighted') {
      c.push(s.labelHighlighted)
    }
    return c.join(' ')
  }

  return (
    <>
      <input
        className={s.radio}
        type="radio"
        defaultChecked={isChecked}
        id={id}
        value={value}
        onClick={onChangeHandler}
        name={name}
      />
      <label className={classes()} htmlFor={id}>
        {text}
      </label>
    </>
  )
}

export default RadioButton
