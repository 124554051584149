function ICircleIcon() {
  return (
    <>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99996 11.3333C3.05436 11.3333 0.666626 8.94556 0.666626 5.99996C0.666626 3.05436 3.05436 0.666626 5.99996 0.666626C8.94556 0.666626 11.3333 3.05436 11.3333 5.99996C11.3333 8.94556 8.94556 11.3333 5.99996 11.3333ZM5.99996 10.2666C8.35637 10.2666 10.2666 8.35637 10.2666 5.99996C10.2666 3.64354 8.35637 1.73329 5.99996 1.73329C3.64354 1.73329 1.73329 3.64354 1.73329 5.99996C1.73329 8.35637 3.64354 10.2666 5.99996 10.2666ZM5.46663 3.33329H6.53329V4.39996H5.46663V3.33329ZM5.46663 5.46663H6.53329V8.66663H5.46663V5.46663Z"
          fill="#222"
        />
      </svg>
    </>
  )
}

export default ICircleIcon
