import { ACTION_TYPES, FORM_TYPES, SCREEN_INDEX } from '../../constants'
import { checkForAuth, formatCurrency, formatPrice, formType, fullPaymentValue } from '../../helpers'
import { benefitSelected, benefitUnselected } from './events'
import { goToAuthScreen, setOnlinePaymentNewFullPayment } from './index'
import { setLeasingInitialFeePercentage } from './leasing'

export const setBenefits = benefits => ({
  type: 'SET_BENEFITS',
  benefits,
})

export const clearSelectedBenefits = () => ({
  type: 'CLEAR_SELECTED_BENEFITS',
})

export const enableBenefit = (
  benefit,
  checkForAuthRequired = false,
  sendToAnalytics = false,
  adjustFullPaymentValue = true,
) => (dispatch, getState) => {
  const s = getState()

  if (checkForAuthRequired && formType() === FORM_TYPES.NEW_TYPE) {
    const personalDataErrors = checkForAuth(s.settings.visual.fields)
    if (personalDataErrors.length) {
      dispatch(
        goToAuthScreen({
          action: ACTION_TYPES.ENABLE_BENEFIT,
          screen: SCREEN_INDEX,
          additionalData: benefit,
        }),
      )
    }
  }

  if (sendToAnalytics) {
    dispatch(
      benefitSelected({
        value: benefit.attributes.value,
        totalCount: s.benefits.benefits.length,
        position: benefit.position,
      }),
    )
  }

  dispatch({
    type: 'ENABLE_BENEFIT',
    benefit,
  })

  // обновление суммы полной оплаты
  let enableFullPayment = s.onlinePayment.enableFullPayment

  if (enableFullPayment && adjustFullPaymentValue) {
    let p = fullPaymentValue()

    dispatch(
      setOnlinePaymentNewFullPayment({
        price: p,
        formattedPrice: `${formatPrice(p, s.settings.currency)}`,
      }),
    )
  }

  // обновление ПВ лизинга
  const leasingInitialFeePercentage = s.leasing.parameters.initialFeePercentage
  dispatch(setLeasingInitialFeePercentage(leasingInitialFeePercentage))
}

export const disableBenefit = benefit => (dispatch, getState) => {
  const s = getState()

  dispatch(
    benefitUnselected({
      value: benefit.attributes.value,
      totalCount: s.benefits.benefits.length,
      position: benefit.position,
    }),
  )

  dispatch({
    type: 'DISABLE_BENEFIT',
    benefit,
  })

  // обновление суммы полной оплаты
  let enableFullPayment = s.onlinePayment.enableFullPayment

  if (enableFullPayment) {
    let p = fullPaymentValue()

    dispatch(
      setOnlinePaymentNewFullPayment({
        price: p,
        formattedPrice: `${formatPrice(p, s.settings.currency)}`,
      }),
    )
  }

  // обновление ПВ лизинга
  const leasingInitialFeePercentage = s.leasing.parameters.initialFeePercentage
  dispatch(setLeasingInitialFeePercentage(leasingInitialFeePercentage))
}
