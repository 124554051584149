export const formatCurrency = value => {
  if (!value) {
    return '₽'
  }

  switch (value.toLowerCase()) {
    case 'usd':
      return '$'
    case 'kzt':
      return '₸'
    case 'eur':
      return '€'
    case 'byn':
      return 'BYN'
    case 'uzs':
      return 'UZS'
    default:
      return '₽'
  }
}
