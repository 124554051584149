import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { closestTerm, formatCurrency } from '../../helpers'
import { BaseRange } from './RangeTerms'

const LeasingBuyBack = props => {
  const { onChange, setValue, id, value } = props

  const defaultSettingsAvailable = useSelector(state => state.credit_standalone.config.defaultSettingsAvailable)
  const defaultConditions = useSelector(state => state.credit_standalone.conditions.defaultConditions)
  const term = useSelector(state => state.credit_standalone.conditions.term)
  const currencySign = formatCurrency(props.currency)

  const [buybackPercentageMin, buybackPercentageMax] = (() => {
    let closest = closestTerm(defaultConditions, term)

    const defaults = defaultConditions[closest]
    const defaultBuybackPaymentPercentMin = defaults?.buybackPaymentPercentMin
    const defaultBuybackPaymentPercentMax = defaults?.buybackPaymentPercentMax
    const defaultsExist = !isNaN(defaultBuybackPaymentPercentMin) && !isNaN(defaultBuybackPaymentPercentMax)
    if (defaultsExist && defaultSettingsAvailable) {
      return [defaultBuybackPaymentPercentMin, defaultBuybackPaymentPercentMax]
    }

    return [0, 95]
  })()

  useEffect(() => {
    const res = Math.min(Math.max(value || 0, buybackPercentageMin), buybackPercentageMax)
    setValue(id, res)
  }, [buybackPercentageMax, buybackPercentageMin, id, setValue, value])

  const onChangeBayBack = useCallback(
    val => {
      const res = Math.min(Math.max(val || 0, buybackPercentageMin), buybackPercentageMax)
      onChange(res)
      setValue(id, res)
    },
    [buybackPercentageMax, buybackPercentageMin, id, onChange, setValue],
  )

  const suffix = value === 0 && defaultSettingsAvailable ? `% (100 ${currencySign})` : '%'

  return (
    <BaseRange
      {...props}
      min={buybackPercentageMin}
      max={buybackPercentageMax}
      suffix={suffix}
      onChange={onChangeBayBack}
    />
  )
}

export default LeasingBuyBack
