import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { formName } from '../../../carOrderForm/main/helpers'
import DealershipsDropdown from '../../../Components/Parts/DealershipsDropdown/DealershipsDropdown'
import Form from '../../../Components/Parts/FormNew/Form'
import FormBlock from '../../../Components/Parts/FormNew/FormBlock'
import { FORM_SOURCE, FORM_TYPE_BASE, TRADE_IN_STANDALONE_SCREEN } from '../../../constants'
import {
  clearProtections,
  clearSmsAndCaptcha,
  createdEventPayload,
  createSmartContractWithProtection,
  initializeErrorsProcessing,
} from '../../../helpers'
import {
  dealershipsErrors,
  isDealershipValid,
  setActiveDealershipInTradeInStandalone,
} from '../../../helpers/form/activeDealershipSetter'
import {
  setActiveClientModel,
  setCarName,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  setTradeInStandaloneCar,
  setTradeInStandaloneScreen,
  smartContractCreated,
  startLoading,
  stopLoading,
} from '../../../store/actions'
import { createSmartContractData } from '../../../store/helpers/smart_contract'
import Button from '../../ui/button'
import s from './form.module.scss'

function PersonalDataForm({
  widgetId,
  dealerships,
  isWidgetLoadingFailed,
  isDealershipValid,
  activeModel,
  disableSmartContractLink,
  priceToolTipEnabled,
  formValues,
  limitForSaving,
  captchaSettings,
  smsCodeSettings,
  vehicleType,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dataToSave = useSelector(state => createSmartContractData(state, 'TradeInStandalone'))
  const config = {
    source: FORM_SOURCE.TRADE_IN_STANDALONE,
  }

  const [hasErrorWhileSavingSC, setHasErrorWhileSavingSC] = useState(false)
  const [hasDealershipError, setHasDealershipError] = useState(false)

  /**
   * Выбор ДЦ
   * @param d
   */
  const selectDealership = d => {
    // если есть активная модель, то надо проверить есть ли она в новом ДЦ
    // если ее нет в новом ДЦ, то активную модель надо сбросить
    if (activeModel?.id) {
      const isActiveModelExistsInSelectedDealership = d.forTradeInStandalone.clientModels.find(
        model => model.id === activeModel.id,
      )

      if (!isActiveModelExistsInSelectedDealership) {
        dispatch(setActiveClientModel({}))
        dispatch(setTradeInStandaloneCar(''))
        dispatch(setCarName(''))
      }
    }

    setHasErrorWhileSavingSC(false)
    setActiveDealershipInTradeInStandalone(d)
  }

  /**
   * Получаем данные из формы и сохраняем СК
   */
  const listener = useCallback(
    e => {
      if (!e?.detail?.isFromDataValid || !isDealershipValid) {
        return false
      }
      dispatch(startLoading())

      // если нет доступа в СК, то не сохраняем его, а сохраняем на 3 шаге
      if (disableSmartContractLink) {
        dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.SAVE))
        dispatch(stopLoading())
        return
      }

      setHasErrorWhileSavingSC(false)

      createSmartContractWithProtection(
        widgetId,
        {
          ticket_answer: formValues.captcha || formValues.smsCode,
          ticket_id: captchaSettings.ticketId || smsCodeSettings.ticketId,
        },
        JSON.stringify({ data: dataToSave }),
      )
        .then(({ data }) => {
          if (data && data.genericId) {
            dispatch(setSmartContractUrl(data.smartContractUrl))
            dispatch(setSmartContractId(data.id))
            dispatch(setSmartContractToken(data.token))
            dispatch(setSmartContractGenericId(data.genericId))
            dispatch(setSmartContractType('tradeInStandalone'))
            dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.SAVE))
            dispatch(smartContractCreated(createdEventPayload(widgetId, dataToSave, data)))
            clearSmsAndCaptcha()
            clearProtections(FORM_SOURCE.TRADE_IN_STANDALONE)

            window.dataLayer &&
              window.dataLayer.push({
                event: 'SmartContract_Create',
                Status: 'tradeInStandalone',
                buttonName: t('tradeInS.showCarEstimation'),
                widget_id: widgetId,
                deal_id: `${data.genericId}-${data.id}`,
                eventValue: '',
              })
          } else {
            setHasErrorWhileSavingSC(true)
          }
        })
        .catch(e => {
          initializeErrorsProcessing({
            e: e,
            formSource: FORM_SOURCE.TRADE_IN_STANDALONE,
            callbackAction: setHasErrorWhileSavingSC,
            additional: {
              tradeInStandaloneStep: 'personalDataForm',
            },
          })
        })
        .finally(() => {
          dispatch(stopLoading())
        })
    },
    [isDealershipValid, widgetId, dataToSave, dispatch],
  )

  /**
   * Нажатие на кнопку вне формы
   * Проверка на ошибки в ДЦ
   */
  const submitForm = () => {
    // нажали на кнопку submit вне формы
    window.dispatchEvent(new CustomEvent(formName(config).clickOutsideForm))

    if (hasDealershipError) {
      setHasDealershipError(false)
    }

    if (!isDealershipValid) {
      setHasDealershipError(true)
    }
  }

  /**
   * Сбрасываем ошибки при перещелкивании
   */
  useEffect(() => {
    if (isDealershipValid) {
      setHasDealershipError(false)
    }
  }, [isDealershipValid])

  /**
   * Слушаем данные из формы с перс данными
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickInsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickInsideForm, listener)
    }
  }, [listener])

  return (
    <div className={s.block}>
      {dealerships?.length > 1 && (
        <div className={s.block__item}>
          <FormBlock title={t('carOrderForm.dealership')}>
            <div className={s.block__item}>
              <DealershipsDropdown
                selectActiveDealership={selectDealership}
                config={{ source: FORM_SOURCE.TRADE_IN_STANDALONE }}
                errors={dealershipsErrors(hasDealershipError, isWidgetLoadingFailed).hasErrors}
                errorsText={dealershipsErrors(hasDealershipError, isWidgetLoadingFailed).errorsText}
              />
            </div>
          </FormBlock>
        </div>
      )}

      <div className={s.block__item}>
        <Form
          key={'tradeInStandaloneForm'}
          type={FORM_TYPE_BASE}
          config={{ source: FORM_SOURCE.TRADE_IN_STANDALONE }}
        />
      </div>

      {priceToolTipEnabled && (
        <div className={s.block__item}>
          <div className={s.priceTooltip}>{t(`car.tooltipAlert.${vehicleType}`)}</div>
        </div>
      )}

      {!limitForSaving && (
        <div className={s.block__item}>
          <div className={s.button}>
            <Button handleClick={submitForm} text={t('tradeInS.showCarEstimation')} />
          </div>
        </div>
      )}

      {hasErrorWhileSavingSC && (
        <div className={s.block__item}>
          <div className={s.errors}>{limitForSaving ? t('error.limit') : t('ccS.actions.error')}</div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  settings: { widget_id: widgetId, disableSmartContractLink, limitForSaving, vehicleType },
  tradeInStandalone: { dealerships, activeDealership, isWidgetLoadingFailed, priceToolTipEnabled },
  clientModels: { activeModel },
  form: { values: formValues, smsCodeSettings, captchaSettings },
}) {
  return {
    disableSmartContractLink,
    widgetId,
    dealerships,
    activeDealership,
    isWidgetLoadingFailed,
    activeModel,
    priceToolTipEnabled,
    formValues,
    limitForSaving: limitForSaving[FORM_SOURCE.TRADE_IN_STANDALONE],
    captchaSettings: captchaSettings[FORM_SOURCE.TRADE_IN_STANDALONE],
    smsCodeSettings: smsCodeSettings[FORM_SOURCE.TRADE_IN_STANDALONE],
    isDealershipValid: isDealershipValid(dealerships, activeDealership, isWidgetLoadingFailed),
    vehicleType,
  }
}

export default connect(mapStateToProps)(PersonalDataForm)
