// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mileage_wrapper__12-mD{box-sizing:border-box;height:100%;position:relative;width:100%}.mileage_wrapper__input__3YxAt{height:100%;width:100%}.mileage_wrapper__range__2vl04{bottom:-14px;left:-21px;position:absolute;width:calc(100% + 34px);z-index:10000}\n", "",{"version":3,"sources":["webpack://src/TradeInStandalone/firstScreenElements/inputs/mileage/mileage.module.scss"],"names":[],"mappings":"AAAA,wBACE,qBAAsB,CACtB,WAAY,CACZ,iBAAkB,CAClB,UAAW,CAEX,+BACE,WAAY,CACZ,UAAW,CACZ,+BAGC,YAAa,CACb,UAAW,CACX,iBAAkB,CAClB,uBAAwB,CACxB,aAAc","sourcesContent":[".wrapper {\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n  width: 100%;\n\n  &__input {\n    height: 100%;\n    width: 100%;\n  }\n\n  &__range {\n    bottom: -14px;\n    left: -21px;\n    position: absolute;\n    width: calc(100% + 34px);\n    z-index: 10000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "mileage_wrapper__12-mD",
	"wrapper__input": "mileage_wrapper__input__3YxAt",
	"wrapper__range": "mileage_wrapper__range__2vl04"
};
export default ___CSS_LOADER_EXPORT___;
