export const TRADE_IN_TYPES = {
  SET_STATE: 'SET_STATE',
  SET_PARTNER: 'SET_PARTNER',

  SET_BEHAVIOR_BY_SETTINGS: 'SET_BEHAVIOR_BY_SETTINGS',
  SET_TRADEIN_BENEFIT_VALUE: 'SET_TRADEIN_BENEFIT_VALUE',
  SET_TRADEIN_BENEFIT_VALUE_WITHOUT_TRADE_IN: 'SET_TRADEIN_BENEFIT_VALUE_WITHOUT_TRADE_IN',
  SET_BEHAVIOR_BY_USER: 'SET_BEHAVIOR_BY_USER',
  SET_RATE: 'SET_RATE',
  SET_RATE_STATUS: 'SET_RATE_STATUS',

  SET_STEP_WEIGHT: 'SET_STEP_WEIGHT',
  SET_STEP: 'SET_STEP',
  SET_DICTIONARY: 'SET_DICTIONARY',

  SET_BRAND: 'SET_BRAND',
  SET_MODEL: 'SET_MODEL',
  SET_GENERATION: 'SET_GENERATION',
  SET_BODY: 'SET_BODY',
  SET_MODIFICATION: 'SET_MODIFICATION',
  SET_YEAR: 'SET_YEAR',
  SET_COLOR: 'SET_COLOR',
  SET_OWNER: 'SET_OWNER',
  SET_CITY: 'SET_CITY',
  SET_MILEAGE: 'SET_MILEAGE',
  SET_CAR_IDENTITY: 'SET_CAR_IDENTITY',
  SET_CAR_IDENTITY_BY_VIN: 'SET_CAR_IDENTITY_BY_VIN',
  SET_ENGINE: 'SET_ENGINE',
  SET_GEARBOX: 'SET_GEARBOX',
  SET_INTERIOR_TYPE: 'SET_INTERIOR_TYPE',
  SET_INTERIOR_COLOR: 'SET_INTERIOR_COLOR',

  SET_STANDALONE_TYPED_VALUE: 'SET_STANDALONE_TYPED_VALUE',
  SET_STANDALONE_SCREEN: 'SET_TRADEIN_STANDALONE_SCREEN',
  SET_STANDALONE_PRICE: 'SET_STANDALONE_PRICE',
  SET_STANDALONE_CAR: 'SET_STANDALONE_CAR',
  SET_STANDALONE_RATE: 'SET_STANDALONE_RATE',
  SET_STANDALONE_RATE_STATUS: 'SET_STANDALONE_RATE_STATUS',
  SET_STANDALONE_RATE_HASH: 'SET_STANDALONE_RATE_HASH',

  SET_OWNER_CAR_PRICE: 'SET_OWNER_CAR_PRICE',
  SET_OWNER_CAR_PRICE_ENABLED: 'SET_OWNER_CAR_PRICE_ENABLED',
  SET_OWNER_CAR_PRICE_HINT_TEXT: 'SET_OWNER_CAR_PRICE_HINT_TEXT',

  SET_HASH: 'SET_HASH',
  SET_CONDITION: 'SET_TRADEIN_CONDITION',
  SET_HIDE_PARTNER_IN_TEXTS: 'SET_HIDE_PARTNER_IN_TEXTS',
  SET_USE_CITY_AS_STRING: 'SET_USE_CITY_AS_STRING',

  SET_TRADE_IN_DEFAULT_CITIES: 'SET_TRADE_IN_DEFAULT_CITIES',
  SET_TRADE_IN_CITY_TYPE: 'SET_TRADE_IN_CITY_TYPE',
  SET_TRADE_IN_ESTIMATION_TYPE: 'SET_TRADE_IN_ESTIMATION_TYPE',
  SET_IS_CAR_IDENTITY_VALID: 'SET_IS_CAR_IDENTITY_VALID',
  SET_IS_TRADE_IN_BY_IDENTITY_ENABLED: 'SET_IS_TRADE_IN_BY_IDENTITY_ENABLED',
}

export const TRADE_IN_STANDALONE_TYPES = {
  SET_TRADE_IN_STANDALONE_DEALERSHIPS: 'SET_TRADE_IN_STANDALONE_DEALERSHIPS',
  SET_TRADE_IN_STANDALONE_ACTIVE_DEALERSHIP: 'SET_TRADE_IN_STANDALONE_ACTIVE_DEALERSHIP',
  SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADED: 'SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADED',
  SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADING_FAILED: 'SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADING_FAILED',
  SET_TRADE_IN_STANDALONE_LOCALE: 'SET_TRADE_IN_STANDALONE_LOCALE',
  SET_TRADE_IN_STANDALONE_PRICE_TOOLTIP_ENABLED: 'SET_TRADE_IN_STANDALONE_PRICE_TOOLTIP_ENABLED',
  SET_TRADE_IN_STANDALONE_VISUAL: 'SET_TRADE_IN_STANDALONE_VISUAL',
  SET_TRADE_IN_STANDALONE_DEFAULT_CITIES: 'SET_TRADE_IN_STANDALONE_DEFAULT_CITIES',
}

export const BUY_MODEL_STANDALONE_TYPES = {
  SET_BUY_MODEL_STANDALONE_MAIN_SETTINGS: 'SET_BUY_MODEL_STANDALONE_MAIN_SETTINGS',
  SET_BUY_MODEL_STANDALONE_DEALERSHIPS: 'SET_BUY_MODEL_STANDALONE_DEALERSHIPS',
  SET_BUY_MODEL_STANDALONE_ACTIVE_DEALERSHIP: 'SET_BUY_MODEL_STANDALONE_ACTIVE_DEALERSHIP',
  SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADED: 'SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADED',
  SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADING_FAILED: 'SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADING_FAILED',
  SET_BUY_MODEL_STANDALONE_MODELS: 'SET_BUY_MODEL_STANDALONE_MODELS',
  SET_BUY_MODEL_STANDALONE_ACTIVE_MODEL: 'SET_BUY_MODEL_STANDALONE_ACTIVE_MODEL',
  SET_BUY_MODEL_STANDALONE_SAVED_SC: 'SET_BUY_MODEL_STANDALONE_SAVED_SC',
  SET_BUY_MODEL_STANDALONE_LOCALE: 'SET_BUY_MODEL_STANDALONE_LOCALE',
}

export const CREDIT_TYPES = {
  BOOT_CREDIT_PARAMETERS: 'BOOT_CREDIT_PARAMETERS',
  SET_CREDIT_BEHAVIOUR_BY_USER: 'SET_CREDIT_BEHAVIOUR_BY_USER',
  SET_CREDIT_BUYBACK_PAYMENT: 'SET_CREDIT_BUYBACK_PAYMENT',
  SET_LIFE_INSURANCE: 'SET_LIFE_INSURANCE',
  SET_KASKO_INSURANCE: 'SET_KASKO_INSURANCE',
  SET_CREDIT_INITIAL_FEE: 'SET_CREDIT_INITIAL_FEE',
  SET_CREDIT_PERIOD: 'SET_CREDIT_PERIOD',
  SET_CREDIT_PARAMS: 'SET_CREDIT_PARAMS',
  SET_ACTIVE_PROGRAM: 'SET_ACTIVE_PROGRAM',
  SET_CREDIT_STATUS: 'SET_CREDIT_STATUS',
  SET_PROGRAMS: 'SET_PROGRAMS',
  SET_CREDIT_BENEFIT_VALUE: 'SET_CREDIT_BENEFIT_VALUE',
  SET_CREDIT_DISABLE_BUYBACK_PAYMENT: 'SET_CREDIT_DISABLE_BUYBACK_PAYMENT',

  // SET_STANDALONE_TYPED_VALUE: "SET_STANDALONE_TYPED_VALUE",
  SET_STANDALONE_SCREEN: 'SET_CREDIT_STANDALONE_SCREEN',
  // SET_STANDALONE_PRICE: "SET_STANDALONE_PRICE",
  // SET_STANDALONE_CAR: "SET_STANDALONE_CAR",

  SET_CONDITION: 'SET_CREDIT_CONDITION',
  SET_ONLINE_APPROVE: 'SET_ONLINE_APPROVE',
  SET_CREDIT_PLACEHOLDER: 'SET_CREDIT_PLACEHOLDER',
  SET_CREDIT_PERIODS: 'SET_CREDIT_PERIODS',
  SET_DEFAULT_CONDITIONS: 'SET_DEFAULT_CONDITIONS',
  SET_PROMO_PROGRAM: 'SET_PROMO_PROGRAM',
  SET_CREDIT_DEFAULT_SETTINGS_AVAILABLE: 'SET_CREDIT_DEFAULT_SETTINGS_AVAILABLE',
  //SET_PROMO_PROGRAM_ENABLED: 'SET_PROMO_PROGRAM_ENABLED',
}

export const LEASING_TYPES = {
  SET_LEASING_STATIC_INFO: 'SET_LEASING_STATIC_INFO',
  BOOT_LEASING_PARAMETERS: 'BOOT_LEASING_PARAMETERS',
  SET_ACTIVE_LEASING_PROGRAM: 'SET_ACTIVE_LEASING_PROGRAM',
  SET_LEASING_PROGRAMS: 'SET_LEASING_PROGRAMS',
  SET_LEASING_STATUS: 'SET_LEASING_STATUS',
  SET_LEASING_INITIAL_FEE: 'SET_LEASING_INITIAL_FEE',
  SET_LEASING_PERIOD: 'SET_LEASING_PERIOD',
  SET_LEASING_PERIODS: 'SET_LEASING_PERIODS',
  SET_LEASING_BUYBACK_PAYMENT: 'SET_LEASING_BUYBACK_PAYMENT',
}

export const CREDIT_STANDALONE = {
  SET_LOADING: 'CREDIT_STANDALONE/SET_LOADING',
  SET_ERROR: 'CREDIT_STANDALONE/SET_ERROR',
  SET_SCREEN: 'CREDIT_STANDALONE/SET_SCREEN',

  SET_INIT_CONFIG: 'CREDIT_STANDALONE/SET_INIT_CONFIG',

  SET_CAR_MODEL: 'CREDIT_STANDALONE/SET_CAR_MODEL',
  SET_CAR_BENEFITS: 'CREDIT_STANDALONE/SET_CAR_BENEFITS',
  SET_CAR_PRICE: 'CREDIT_STANDALONE/SET_CAR_PRICE',
  SET_INITFEE: 'CREDIT_STANDALONE/SET_INITFEE',
  SET_INITFEE_PERCENT: 'CREDIT_STANDALONE/SET_INITFEE_PERCENT',
  SET_BUYBACK: 'CREDIT_STANDALONE/SET_BUYBACK',
  SET_BUYBACK_PERCENT: 'CREDIT_STANDALONE/SET_BUYBACK_PERCENT',
  SET_TERM: 'CREDIT_STANDALONE/SET_TERM',

  SET_PERSONAL_DATA_FILLED: 'CREDIT_STANDALONE/SET_PERSONAL_DATA_FILLED',

  GET_PROGRAMS: 'CREDIT_STANDALONE/GET_PROGRAMS',
  SET_PROGRAMS: 'CREDIT_STANDALONE/SET_PROGRAMS',
  SET_PROGRAMS_LOADED_ONCE: 'CREDIT_STANDALONE/SET_PROGRAMS_LOADED_ONCE',

  SELECT_PROGRAM: 'CREDIT_STANDALONE/SELECT_PROGRAM',
  CREATE_SMART_CONTRACT: 'CREDIT_STANDALONE/CREATE_SMART_CONTRACT',
  SET_SMART_CONTRACT: 'CREDIT_STANDALONE/SET_SMART_CONTRACT',

  SET_TYPE: 'CREDIT_STANDALONE/SET_TYPE',

  SET_DEALERSHIPS: 'CREDIT_STANDALONE/SET_DEALERSHIPS',
  SET_DEALERSHIPS_CITY: 'CREDIT_STANDALONE/SET_DEALERSHIPS_CITY',
  SET_ACTIVE_DEALERSHIP: 'CREDIT_STANDALONE/SET_ACTIVE_DEALERSHIP',
  SET_DEFAULT_CONDITIONS: 'CREDIT_STANDALONE/SET_DEFAULT_CONDITIONS',
  SET_POSSIBLE_TERMS: 'CREDIT_STANDALONE/SET_POSSIBLE_TERMS',
  SET_SHOW_COMPLECTATIONS: 'CREDIT_STANDALONE/SHOW_COMPLECTATIONS',
  SET_SHOW_BRAND_IN_MODEL_SELECT: 'CREDIT_STANDALONE/SHOW_BRAND_IN_MODEL_SELECT',
  SET_SELECT_CARS: 'CREDIT_STANDALONE/SET_SELECT_CARS',
}

export const BULLET_TYPES = {
  SET_LINKS: 'SET_LINKS',
  SET_TEXTS: 'SET_TEXTS',
  SET_SIZES: 'SET_SIZES',
  SET_FILLED_TYPE: 'SET_FILLED_TYPE',
  SET_USE_BACKGROUND: 'SET_USE_BACKGROUND',
}

export const API_ACTIONS = {
  LOG_LAST_METHOD: 'LOG_LAST_METHOD',
  BOOTED_EVENT: 'BOOTED_EVENT',
  OPENED_FROM_API: 'OPENED_FROM_API',
}
