module.exports = {
  title: 'Kalkulation zur Inzahlungnahme',

  shortYear: 'Ja',
  kilometers: 'Km',

  yourCar: 'Ihr Fahrzeug',

  rateCar: 'Bewerten das Fahrzeug zur Inzahlungnahme',
  showCarEstimation: 'Bewertung Ihres Fahrzeugs anzeigen',

  averageCarPriceText: 'Durchschnittlicher Preis Ihres Fahrzeugs anzeigen (±5%)',
  averageCarZeroPriceText: 'Geben Sie den erwarteten Wert Ihres Fahrzeugs ein',
  averageCarZeroPriceTextDisabled:
    'Leider konnten wir den Wert Ihres Fahrzeugs zur Inzahlungnahme nicht automatisch berechnen, wir geben Ihre eingegebenen Daten jedoch an Ihren Ansprechpartner für eine individuelle Bewertung weiter.',
  desiredCarText: 'Welches Fahrzeug wollen Sie kaufen?',

  openSCStep1: 'Weiter',
  openSCStep2: 'Ansehen',
  openSCStep3: 'Preis anwenden',

  SCDisclaimerText:
    'Smart-Vertrag ist ein Online-Tool zur Auswahl von günstigen Finanzierungs-, Inzahlungsnahme- und Leasingbedingungen um ein Fahrzeugs beim einem autorisierten Händler zu bestellen',
  SCDisclaimerVideo: 'Smartvertrag-Video anschauen',

  noData: 'Nichts gefunden',

  inputPlaceholder: 'Beginnen Sie hier mit der Eingabe ...',

  selectCarPlaceholder: 'Wählen Sie ein Fahrzeug',
  selectCarPlaceholderEmpty: 'Sie haben kein Fahrzeug ausgewählt',

  successText: 'Wir haben Ihre Kalkulationen gespeichert. Um sie anzuschauen, klicken Sie auf den Knopf unten.',
  successTextWithoutLink: 'Danke! Ihr Ansprechpartner setz sich umgehend mit Ihnen in Verbindung.',

  enterMileage: 'Wählen Sie den Kilometersatz aus oder geben Sie diese ein',

  ownerCarPricePlaceholder: 'Geben Sie den gewünschten Wert Ihres Fahrzeugs ein.',
  ownerCarPriceButton: 'Sind Sie mit der Berechnung nicht einverstanden?',

  generalError: 'Unfortunately, an error has been occurred while loading the Trade-in Calculator', // !!!
}
