import {
  ACTION_TYPES,
  CREDIT_SETTINGS_BEHAVIOR,
  DEFAULT_MILEAGES,
  FINANCE_USER_BEHAVIOUR,
  FORM_TYPES,
  SCREEN_INDEX,
  SCREEN_TRADE_IN,
  TI_PARTNER,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_STORAGE_KEY,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import {
  calculateTradeIn,
  calculateTradeInByIdentity,
  carPriceFromTI,
  checkForAuth,
  creditAmount,
  formType,
  getBodies,
  getBrands,
  getColors,
  getEngines,
  getGearBoxes,
  getGenerations,
  getInteriorColors,
  getInteriorTypes,
  getModels,
  getModifications,
  makeNumber,
} from '../../helpers'
import { isCarIdentityValid } from '../../helpers/validations'
import i18n from '../../i18n'
import { createCreditCalculateProgramsPayload } from '../helpers/credit'
import { TRADE_IN_TYPES } from '../types'
import { loadState } from '../utils/localStorage'
import mergeTradeInStates from '../utils/mergeTradeInStates'
import { updateCarPrices } from './cars'
import {
  adjustInitialFee,
  // adjustCreditBuybackPayment,
  calculateCreditPrograms,
  dataLayer,
  goToAuthScreen,
  hideWidget,
  preventBackFromTradeIn,
  setOpenedFromApi,
  startLoading,
  stopLoading,
  switchCredit,
  tradeInOpened,
} from './index'

export const setTradeInState = payload => ({
  type: TRADE_IN_TYPES.SET_STATE,
  payload,
})

export const setTradeInBehaviorBySettings = payload => ({
  type: TRADE_IN_TYPES.SET_BEHAVIOR_BY_SETTINGS,
  payload,
})

export const setTradeInBenefitValue = payload => ({
  type: TRADE_IN_TYPES.SET_TRADEIN_BENEFIT_VALUE,
  payload,
})

export const setTradeInBenefitValueWithoutTradeIn = payload => ({
  type: TRADE_IN_TYPES.SET_TRADEIN_BENEFIT_VALUE_WITHOUT_TRADE_IN,
  payload,
})

export const disableTradeIn = () => (dispatch, getState) => {
  dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED))

  const {
    tradeIn: {
      rate: {
        result: { possibleCarPrice },
      },
    },
    settings: { listVersion },
  } = getState()

  if (possibleCarPrice > 0) {
    dispatch(adjustInitialFee(possibleCarPrice, true))
  }

  if (listVersion === '2') {
    dispatch(updateCarPrices())
  }

  dispatch(dataLayer({ event: 'tradeIn', status: 'off' }))
}

export const setTradeInBehaviourByUser = payload => ({
  type: TRADE_IN_TYPES.SET_BEHAVIOR_BY_USER,
  payload,
})

export const setRateStatusInitial = () => (dispatch, getState) => {
  const {
    tradeIn: { ownerCarPrice, ownerCarPriceEnabled, behaviourByUser },
  } = getState()

  dispatch({
    type: TRADE_IN_TYPES.SET_RATE_STATUS,
    payload: TRADE_IN_RATE_STATUS.INITIAL,
  })

  if (behaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED) {
    dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.STARTED))
  }

  if (ownerCarPrice > 0 && ownerCarPriceEnabled) {
    dispatch(setTradeInOwnerCarPrice(0))
  }
}

export const setZeroRateWithStatus = status => dispatch => {
  const data = {
    estimated: { from: 0, to: 0 },
    possible_car_price: 0,
    trade_in: { from: 0, to: 0 },
  }

  dispatch({
    type: TRADE_IN_TYPES.SET_RATE_STATUS,
    payload: status,
  })

  dispatch({
    type: TRADE_IN_TYPES.SET_RATE,
    payload: data,
  })

  dispatch(setTradeInStandalonePrice(data.possible_car_price))
}

export const setStandaloneZeroRateWithStatus = status => dispatch => {
  const data = {
    estimated: { from: 0, to: 0 },
    possible_car_price: 0,
    trade_in: { from: 0, to: 0 },
  }

  dispatch({
    type: TRADE_IN_TYPES.SET_STANDALONE_RATE_STATUS,
    payload: status,
  })

  dispatch({
    type: TRADE_IN_TYPES.SET_STANDALONE_RATE,
    payload: data,
  })

  dispatch(setTradeInStandalonePrice(data.possible_car_price))
}

// рассчитываем ТИ
export const rateTradeIn =
  (checkForAuthRequired = false, isFromStandalone = false) =>
  (dispatch, getState) => {
    const {
      tradeIn,
      settings: {
        widget_id,
        visual: { fields },
        listVersion,
      },
    } = getState()

    dispatch(startLoading())

    // если поведение виджета = показать ТИ без расчета или партнер = мобайл_де, то устанавливаем статус сразу, без запроса
    if (
      tradeIn.behaviour === TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS ||
      tradeIn.partner === TI_PARTNER.MOBILE_DE
    ) {
      if (isFromStandalone) {
        dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
      } else {
        dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
      }
      dispatch(stopLoading())
    } else {
      calculateTradeIn(
        widget_id,
        tradeIn.values.modification.id,
        tradeIn.values.city.id,
        tradeIn.values.year.value,
        tradeIn.values.owner.id,
        tradeIn.values.mileage.value,
        tradeIn.values.color.id,
        tradeIn.values.brand.id,
        tradeIn.values.model.id,
      )
        .then(({ data }) => {
          dispatch(dataLayer({ event: 'tradeIn', status: 'calculate', buttonName: i18n.t('tradeIn.rate') }))

          const isTradeInRateReceived = data && data.trade_in && data.trade_in.from > 0 && data.trade_in.to > 0

          if (isTradeInRateReceived) {
            dispatch(setTradeInStandalonePrice(data.possible_car_price))

            if (isFromStandalone) {
              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE_STATUS,
                payload: TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE,
              })

              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE,
                payload: data,
              })
            } else {
              dispatch({
                type: TRADE_IN_TYPES.SET_RATE_STATUS,
                payload: TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE,
              })

              dispatch({
                type: TRADE_IN_TYPES.SET_RATE,
                payload: data,
              })

              if (listVersion === '2') {
                dispatch(updateCarPrices())
              }
            }
          } else {
            if (isFromStandalone) {
              dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE_HASH,
                payload: data.hash,
              })
            } else {
              dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
              dispatch(setTradeInHash(data.hash))
            }
          }
        })
        .catch(e => {
          console.error('Unable to calculate Trade In due to', e)

          dispatch(dataLayer({ event: 'tradeIn', status: 'error', buttonName: i18n.t('tradeIn.rate') }))

          if (isFromStandalone) {
            dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.FAILED))
          } else {
            dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.FAILED))
          }
        })
        .finally(() => {
          dispatch(stopLoading())

          // редирект (если указан) для новой формы
          if (checkForAuthRequired && formType() === FORM_TYPES.NEW_TYPE) {
            const personalDataErrors = checkForAuth(fields)
            if (personalDataErrors.length) {
              dispatch(
                goToAuthScreen({
                  action: ACTION_TYPES.RATE_TRADE_IN,
                  screen: SCREEN_TRADE_IN,
                  additionalData: {},
                }),
              )
            }
          }
        })
    }
  }

// рассчитываем ТИ
export const rateTradeInByIdentity =
  (isFromStandalone = false) =>
  (dispatch, getState) => {
    const {
      tradeIn,
      settings: { widget_id, listVersion },
    } = getState()

    dispatch(startLoading())
    dispatch(setIsCarIdentityValid(true))

    const identity = tradeIn.values.identity.value.toString()
    const isValid = isCarIdentityValid(identity)
    if (!isValid) {
      dispatch(setIsCarIdentityValid(false))
      dispatch(stopLoading())
      return
    }

    // если поведение виджета = показать ТИ без расчета, то устанавливаем статус сразу, без запроса
    if (tradeIn.behaviour === TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
      if (isFromStandalone) {
        dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
      } else {
        dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
      }
      dispatch(stopLoading())
    } else {
      const payload = {
        data: {
          identity: tradeIn.values.identity.value.toString(),
          mileage: tradeIn.values.mileage.value.toString(),
          cityId: tradeIn.values.city.id.toString(),
        },
      }

      calculateTradeInByIdentity(widget_id, payload)
        .then(({ data }) => {
          dispatch(dataLayer({ event: 'tradeIn', status: 'calculate', buttonName: i18n.t('tradeIn.rate') }))

          const isTradeInRateReceived = data && data.trade_in && data.trade_in.from > 0 && data.trade_in.to > 0

          if (isTradeInRateReceived) {
            dispatch(setTradeInStandalonePrice(data.possible_car_price))
            dispatch(setTradeInBrand({ id: data.brand_name, name: data.brand_name }))
            dispatch(setTradeInModel({ id: data.model_name, name: data.model_name }))
            dispatch(setTradeInYear({ value: data.year.toString() }))
            dispatch(setTradeInColor({ id: data.color, name: data.color }))
            dispatch(setTradeInOwner({ id: data.owners_count.toString(), name: data.owners_count.toString() }))

            if (isFromStandalone) {
              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE_STATUS,
                payload: TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE,
              })

              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE,
                payload: data,
              })
            } else {
              dispatch({
                type: TRADE_IN_TYPES.SET_RATE_STATUS,
                payload: TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE,
              })

              dispatch({
                type: TRADE_IN_TYPES.SET_RATE,
                payload: data,
              })

              if (listVersion === '2') {
                dispatch(updateCarPrices())
              }
            }
          } else {
            if (isFromStandalone) {
              dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
              dispatch({
                type: TRADE_IN_TYPES.SET_STANDALONE_RATE_HASH,
                payload: data.hash,
              })
            } else {
              dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE))
              dispatch(setTradeInHash(data.hash))
            }
          }
        })
        .catch(e => {
          console.error('Unable to calculate Trade In due to', e)

          dispatch(dataLayer({ event: 'tradeIn', status: 'error', buttonName: i18n.t('tradeIn.rate') }))

          if (isFromStandalone) {
            dispatch(setStandaloneZeroRateWithStatus(TRADE_IN_RATE_STATUS.FAILED))
          } else {
            dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.FAILED))
          }
        })
        .finally(() => {
          dispatch(stopLoading())
        })
    }
  }

// включаем ТИ
export const applyTradeIn =
  (redirect = false, { useCustomPrice = false, customPrice = 0, openedFromApi = false, openedFromMainScreen = true }) =>
  (dispatch, getState) => {
    // в виджете своя цена за ТИ применяется только но нажатию по кнопке ПРИМЕНИТЬ
    if (useCustomPrice) {
      dispatch(setTradeInOwnerCarPrice(customPrice))
    }

    dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.APPLIED))

    const {
      benefits: { selected },
      tradeIn: {
        ownerCarPrice,
        ownerCarPriceEnabled,
        behaviourByUser: tradeInBehaviourByUser,
        benefitValue: tradeInBenefitAmount,
        benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
        rate: {
          result: { possibleCarPrice },
        },
      },
      credit: { behaviour, behaviourByUser, active_program, benefitValue: creditBenefitAmount },
      car: { price: carPrice },
      accessories: { selected: selectedAccessories },
      settings: { listVersion },
    } = getState()

    const creditEnabled =
      (behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED && behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED) ||
      (behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS &&
        behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) ||
      active_program.id

    const realTradeInCarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

    const amount = creditAmount({
      price: carPrice,
      selectedBenefits: selected,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviourByUser,
      tradeInBenefitAmount: tradeInBenefitAmount,
      tradeInBenefitAmountWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
      creditBenefitAmount: creditBenefitAmount,
    })

    const isRealCarPriceMoreThenCarPrice = realTradeInCarPrice > carPrice
    const isRealCarPriceMoreThen095CreditAmount = Math.ceil((realTradeInCarPrice * 100) / amount) > 95

    // если цена авто в ТИ > цены покупки, то отключаем КК
    if (creditEnabled && isRealCarPriceMoreThenCarPrice) {
      dispatch(switchCredit())
    }

    // если цена авто в ТИ > 0, то пересчитываем данные в КК
    if (realTradeInCarPrice > 0) {
      dispatch(adjustInitialFee(realTradeInCarPrice, true))
      // dispatch(adjustCreditBuybackPayment(realTradeInCarPrice, true))

      if (creditEnabled && isRealCarPriceMoreThen095CreditAmount) {
        dispatch(switchCredit())
      }
    }

    if (openedFromApi) {
      dispatch(setOpenedFromApi(false))
      dispatch(hideWidget())
      dispatch({ type: 'GO_TO', screen: SCREEN_INDEX })

      // dispatch(updateCarPrices())

      const creditData = createCreditCalculateProgramsPayload(getState())
      dispatch(calculateCreditPrograms(creditData))
    }

    if (redirect) {
      dispatch({
        type: 'GO_TO',
        screen: SCREEN_INDEX,
      })
    }

    if (listVersion === '2') {
      dispatch(updateCarPrices())
    }

    // если включили трейдин нажанием на "применить" внутри экрана трейдина, то apply, иначе on при включении с главного экрана
    if (!openedFromMainScreen || openedFromApi) {
      dispatch(dataLayer({ event: 'tradeIn', status: 'apply', buttonName: i18n.t('tradeIn.apply') }))
    } else {
      dispatch(dataLayer({ event: 'tradeIn', status: 'on' }))
    }
  }

export const firstOpenTradeIn =
  (preventBack = false) =>
  (dispatch, getState) => {
    const {
      settings: { widget_id },
    } = getState()

    dispatch(startLoading())

    getBrands(widget_id)
      .then(({ data }) => {
        dispatch(tradeInOpened())

        dispatch({
          type: TRADE_IN_TYPES.SET_DICTIONARY,
          dictionary: 'brands',
          payload: data.map(brand => ({
            id: brand.id,
            name: brand.name,
            search: brand.cyrillic_name,
            base: brand.popular,
          })),
        })

        dispatch(goToTradeIn())

        if (preventBack) {
          dispatch(preventBackFromTradeIn())
        }
      })
      .catch(() =>
        dispatch({
          // TODO: Possible show error, or store intention of trade in
          type: 'GO_TO',
          screen: SCREEN_INDEX,
        }),
      )
      .finally(() => {
        dispatch(stopLoading())
      })
  }

export const goToTradeIn = () => dispatch => {
  dispatch({ type: 'GO_TO', screen: SCREEN_TRADE_IN })
  dispatch(dataLayer({ event: 'tradeIn', status: 'open' }))
}

// ---------

export const toggleTradeInStep = payload => (dispatch, getState) => {
  const {
    tradeIn: { step: currentStep },
  } = getState()

  const p = currentStep === payload ? '' : payload

  dispatch({
    type: TRADE_IN_TYPES.SET_STEP,
    payload: p,
  })
}

export const setTradeInStep = payload => (dispatch, getState) => {
  dispatch({
    type: TRADE_IN_TYPES.SET_STEP,
    payload,
  })
}

export const setTradeInBrand =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { brand },
        partner,
        estimationType,
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

    // устнавливаем бренд
    dispatch({
      type: TRADE_IN_TYPES.SET_BRAND,
      id,
      name,
    })

    dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.STARTED))

    // сбраысваем статус после смены бренда
    dispatch(setRateStatusInitial())

    // сбрасываем модель (только если новое значение не равно старому)
    const canResetModel = estimationByIdentity ? false : id !== brand.id
    if (canResetModel) {
      dispatch(resetModel(partner))
    }

    // если есть ид, то загружаем модели
    const canLoadModels = estimationByIdentity ? false : id
    if (canLoadModels) {
      dispatch(loadModels(id))
    }
  }

export const setTradeInModel =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        partner,
        estimationType,
        values: {
          brand: { id: brandId },
          model,
        },
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

    // устнавливаем модель
    dispatch({
      type: TRADE_IN_TYPES.SET_MODEL,
      id,
      name,
    })

    // сбраысваем статус после смены модели
    dispatch(setRateStatusInitial())
    const canResetGeneration = estimationByIdentity ? false : id !== model.id
    const canLoadGenerations = estimationByIdentity ? false : id && brandId

    switch (partner) {
      case TI_PARTNER.AUTO_RU:
      case TI_PARTNER.ONE_PLATFORM:
      case TI_PARTNER.MAX_POSTER:
        // сбрасываем следующий шаг - если это авто_ру, то сбрасываем поколения (только если новое значение не равно старому)
        if (canResetGeneration) {
          dispatch(resetGeneration(partner))
        }
        // если есть данные, то загружаем поколения (для авто_ру)
        if (canLoadGenerations) {
          dispatch(loadGenerations(brandId, id))
        }
        break

      case TI_PARTNER.MOBILE_DE:
        // если это mobile_de, то сбрасываем кузова
        if (id !== model.id) {
          dispatch(resetBody(partner))
        }
        // если есть данные, то загружаем кузова (для mobile_de)
        if (id && brandId) {
          dispatch(loadBodies(brandId, id))
        }
        break

      default:
        console.error('TI partner is not defined', partner)
    }
  }

// только для авто ру
export const setTradeInGeneration =
  ({ id, name, external: { year_from, year_to } }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: {
          brand: { id: brandId },
          model: { id: modelId },
          generation,
        },
        partner,
      },
    } = getState()

    // устанавливаем поколения
    dispatch({
      type: TRADE_IN_TYPES.SET_GENERATION,
      id,
      name,
    })

    // если есть года, то устанавливаем года
    const years = []

    if (year_from && year_to) {
      for (let year = year_from; year <= year_to; year++) {
        years.push({
          id: String(year),
          name: String(year),
        })
      }
    }

    dispatch({
      type: TRADE_IN_TYPES.SET_DICTIONARY,
      dictionary: 'years',
      payload: years,
    })

    // сбрасываем статус после смены поколения
    dispatch(setRateStatusInitial())

    // сбрасываем кузова (только если новое значение не равно старому)
    if (id !== generation.id) {
      dispatch(resetBody(partner))
    }

    // если есть данные, то загружаем кузова
    if (id && brandId && modelId) {
      dispatch(loadBodies(brandId, modelId, id))
    }
  }

export const setTradeInBody =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        partner,
        values: {
          brand: { id: brandId },
          model: { id: modelId },
          generation: { id: generationId },
          body,
        },
      },
    } = getState()

    // устанавливаем кузова
    dispatch({
      type: TRADE_IN_TYPES.SET_BODY,
      id,
      name,
    })

    // сбраысваем статус после смены кузова
    dispatch(setRateStatusInitial())

    switch (partner) {
      case TI_PARTNER.AUTO_RU:
      case TI_PARTNER.ONE_PLATFORM:
      case TI_PARTNER.MAX_POSTER:
        // сбрасываем модификации (только если новое значение не равно старому)
        if (id !== body.id) {
          dispatch(resetModification(partner))
        }

        // если есть данные, то загружаем модификации
        if (id && brandId && modelId && generationId) {
          dispatch(loadModifications(brandId, modelId, generationId, id))
        }
        break

      case TI_PARTNER.MOBILE_DE:
        // сбрасываем года
        if (id !== body.id) {
          dispatch(resetYear(partner))
        }
        // если есть данные, то загружаем года
        dispatch({
          type: TRADE_IN_TYPES.SET_DICTIONARY,
          dictionary: 'years',
          payload: [],
        })

        dispatch(setStepWeight('year'))
        dispatch(toggleTradeInStep('year'))
        break

      default:
        console.error('TI partner is not defined', partner)
    }
  }

// только для авто_ру
export const setTradeInModification =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        dictionaries: { years },
        values: { modification },
        partner,
      },
    } = getState()

    // устанавливаем модификацию
    dispatch({
      type: TRADE_IN_TYPES.SET_MODIFICATION,
      id,
      name,
    })

    // сбрасываем год (только если новое значение не равно старому)
    if (id !== modification.id) {
      dispatch(resetYear(partner))
    }

    // сбраысваем статус после смены модификации
    dispatch(setRateStatusInitial())

    if (years.length === 1) {
      dispatch(
        setTradeInYear({
          value: years[0].id,
        }),
      )
    } else {
      dispatch(setStepWeight('year'))
      dispatch(toggleTradeInStep('year'))
    }
  }

export const setTradeInYear =
  ({ value }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { year },
        partner,
        estimationType,
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

    // устанавливаем год
    dispatch({
      type: TRADE_IN_TYPES.SET_YEAR,
      payload: value,
    })

    // сбрасываем цвет (только если новое значение не равно старому)
    const canResetColor = estimationByIdentity ? false : value !== year.value
    if (canResetColor) {
      dispatch(resetColor(partner))
    }

    // сбраысваем статус после смены года
    dispatch(setRateStatusInitial())

    // загружаем цвета
    if (!estimationByIdentity) {
      dispatch(loadColors())
    }
  }

export const setTradeInColor =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { color },
        partner,
        estimationType,
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

    // устанавливаем цвет
    dispatch({
      type: TRADE_IN_TYPES.SET_COLOR,
      id,
      name,
    })

    // сбрасываем владельца (только если новое значение не равно старому)
    const canResetOwner = estimationByIdentity ? false : id !== color.id
    if (canResetOwner) {
      dispatch(resetOwner(partner))
    }

    // сбраысваем статус после смены цвета
    dispatch(setRateStatusInitial())

    if (!estimationByIdentity) {
      dispatch(setStepWeight('owner'))
      dispatch(toggleTradeInStep('owner'))
    }
  }

export const setTradeInOwner =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        partner,
        values: { owner },
        estimationType,
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    // устанавливаем владельца
    dispatch({
      type: TRADE_IN_TYPES.SET_OWNER,
      id,
      name,
    })

    // сбраысваем статус после смены владельца
    dispatch(setRateStatusInitial())

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ
    const canResetCity = estimationByIdentity ? false : id !== owner.id

    switch (partner) {
      case TI_PARTNER.AUTO_RU:
      case TI_PARTNER.ONE_PLATFORM:
      case TI_PARTNER.MAX_POSTER:
        // сбрасываем город (только если новое значение не равно старому)
        if (canResetCity) {
          dispatch(resetCity(partner))
          dispatch(setStepWeight('city'))
          dispatch(toggleTradeInStep('city'))
        }

        break

      case TI_PARTNER.MOBILE_DE:
        // сбрасываем двигатель (только если новое значение не равно старому)
        if (id !== owner.id) {
          dispatch(resetEngine(partner))
        }

        // загружаем двигатели
        dispatch(loadEngines())

        break

      default:
        console.error('TI partner is not defined', partner)
    }
  }

export const setTradeInCity =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { city },
        estimationType,
      },
      tradeInStandalone: { estimationType: estimationTypeFromTIS },
    } = getState()

    // устанавливаем город
    dispatch({
      type: TRADE_IN_TYPES.SET_CITY,
      id,
      name,
    })

    const estimationByIdentity =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ||
      estimationTypeFromTIS === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

    // сбрасываем пробег (только если новое значение не равно старому)
    const canResetMileage = estimationByIdentity ? false : id !== city.id

    if (canResetMileage) {
      dispatch(resetMileage())
    }

    // сбраысваем статус после смены пробега
    dispatch(setRateStatusInitial())

    dispatch(setStepWeight('mileage'))
    dispatch(toggleTradeInStep('mileage'))
  }

export const setTradeInEngine =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { engine },
        partner,
      },
    } = getState()

    // устанавливаем двигатель
    dispatch({
      type: TRADE_IN_TYPES.SET_ENGINE,
      id,
      name,
    })

    // сбрасываем коробку передач (только если новое значение не равно старому)
    if (id !== engine.id) {
      dispatch(resetGearBox(partner))
    }

    // сбраысваем статус после смены двигателя
    dispatch(setRateStatusInitial())

    // загружаем коробки передач
    dispatch(loadGearboxes())
  }

export const setTradeInGearBox =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { gearbox },
        partner,
      },
    } = getState()

    // устанавливаем двигатель
    dispatch({
      type: TRADE_IN_TYPES.SET_GEARBOX,
      id,
      name,
    })

    // сбрасываем цвет интерьера (только если новое значение не равно старому)
    if (id !== gearbox.id) {
      dispatch(resetInteriorColor(partner))
    }

    // сбраысваем статус после смены двигателя
    dispatch(setRateStatusInitial())

    // загружаем цвета интерьера
    dispatch(loadInteriorColors())
  }

export const setTradeInInteriorColor =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { interiorColor },
        partner,
      },
    } = getState()

    // устанавливаем двигатель
    dispatch({
      type: TRADE_IN_TYPES.SET_INTERIOR_COLOR,
      id,
      name,
    })

    // сбрасываем тип интерьера (только если новое значение не равно старому)
    if (id !== interiorColor.id) {
      dispatch(resetInteriorType(partner))
    }

    // сбраысваем статус после смены двигателя
    dispatch(setRateStatusInitial())

    // загружаем типы интерьера
    dispatch(loadInteriorTypes())
  }

export const setTradeInInteriorType =
  ({ id, name }) =>
  (dispatch, getState) => {
    const {
      tradeIn: {
        values: { interiorType },
      },
    } = getState()

    // устанавливаем двигатель
    dispatch({
      type: TRADE_IN_TYPES.SET_INTERIOR_TYPE,
      id,
      name,
    })

    // сбрасываем тип интерьера (только если новое значение не равно старому)
    if (id !== interiorType.id) {
      dispatch(resetMileage())
    }

    // сбраысваем статус после смены двигателя
    dispatch(setRateStatusInitial())

    // переходим на пробег
    dispatch(setStepWeight('mileage'))
    dispatch(toggleTradeInStep('mileage'))
  }

export const setTradeInMileage =
  ({ payload }) =>
  dispatch => {
    dispatch({
      type: TRADE_IN_TYPES.SET_MILEAGE,
      payload: payload,
    })

    // сбраысваем статус после смены пробега
    dispatch(setRateStatusInitial())

    // нужно для актуализации строки ввода пробега и радиобатонов со значениями по умолчанию
    if (DEFAULT_MILEAGES.includes(payload)) {
      // надо выбрать радиобатон с таким значением и его включить
      let radiobutton = document.getElementById(`mileage-id-${payload.toString()}`)
      if (radiobutton) {
        radiobutton.checked = true
      }
    } else {
      // выключаем все радиобатоны, тк введенного значения нет среди значений по умолчанию
      DEFAULT_MILEAGES.forEach(m => {
        let radiobutton = document.getElementById(`mileage-id-${m.toString()}`)
        if (radiobutton) {
          radiobutton.checked = false
        }
      })
    }

    // сделать дальше для МОБАЙЛ ДЕ
  }

export const setTradeInCarIdentity =
  ({ payload }) =>
  dispatch => {
    dispatch({
      type: TRADE_IN_TYPES.SET_CAR_IDENTITY_BY_VIN,
      payload: payload,
    })

    // сбраысваем статус после смены вин/грз
    dispatch(setRateStatusInitial())
  }

// ---------

export const loadModels = brandId => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getModels(widget_id, brandId)
    .then(({ data }) => {
      const models = data.map(model => ({
        id: model.id,
        name: model.name,
        search: model.cyrillic_name,
        base: model.popular,
      }))

      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'models',
        payload: models,
      })

      if (models.length === 1) {
        dispatch(setTradeInModel(models[0]))
      } else {
        dispatch(setStepWeight('model'))
        dispatch(toggleTradeInStep('model'))
      }
    })
    .catch(e => console.error(e))
    .finally(() => {
      // TODO: Как-то обработать по умному. Что если чего-то не удалось. Всё равно даём заполнить.
      dispatch(stopLoading())
    })
}

export const loadGenerations = (brandId, modelId) => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getGenerations(widget_id, brandId, modelId)
    .then(({ data }) => {
      const generations = data.map(generation => {
        const nameBeginning = generation.name ? generation.name + ' ' : ''
        const nameEnding =
          generation.year_to && generation.year_from ? ` (${generation.year_from} - ${generation.year_to})` : ''
        let name = nameBeginning
        if (nameEnding) {
          name += nameEnding
        }

        return {
          id: generation.id,
          name,
          external: {
            year_from: generation.year_from,
            year_to: generation.year_to,
          },
        }
      })

      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'generations',
        payload: generations,
      })

      if (generations.length === 1) {
        dispatch(setTradeInGeneration(generations[0]))
      } else {
        dispatch(setStepWeight('generation'))
        dispatch(toggleTradeInStep('generation'))
      }
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadBodies = (brandId, modelId, generationId) => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getBodies(widget_id, brandId, modelId, generationId)
    .then(({ data }) => {
      const bodies = data.map(body => ({
        id: body.id,
        name: body.name,
      }))

      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'bodies',
        payload: bodies,
      })

      if (bodies.length === 1) {
        dispatch(setTradeInBody(bodies[0]))
      } else {
        dispatch(setStepWeight('body'))
        dispatch(toggleTradeInStep('body'))
      }
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadModifications = (brandId, modelId, generationId, bodyId) => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getModifications(widget_id, brandId, modelId, generationId, bodyId)
    .then(({ data }) => {
      const modifications = data.map(modification => ({
        id: modification.id,
        name: modification.name,
      }))

      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'modifications',
        payload: modifications,
      })

      if (modifications.length === 1) {
        dispatch(setTradeInModification(modifications[0]))
      } else {
        dispatch(setStepWeight('modification'))
        dispatch(toggleTradeInStep('modification'))
      }
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadColors = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
    tradeIn: {
      values: {
        brand: { id: brandId },
      },
    },
  } = getState()

  dispatch(startLoading())

  getColors(widget_id, brandId)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'colors',
        payload: data,
      })

      dispatch(setStepWeight('color'))
      dispatch(toggleTradeInStep('color'))
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadEngines = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getEngines(widget_id)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'engines',
        payload: data,
      })

      dispatch(setStepWeight('engine'))
      dispatch(toggleTradeInStep('engine'))
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadGearboxes = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getGearBoxes(widget_id)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'gearboxes',
        payload: data,
      })

      dispatch(setStepWeight('gearbox'))
      dispatch(toggleTradeInStep('gearbox'))
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadInteriorColors = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getInteriorColors(widget_id)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'interiorColors',
        payload: data,
      })

      dispatch(setStepWeight('interiorColor'))
      dispatch(toggleTradeInStep('interiorColor'))
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const loadInteriorTypes = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getInteriorTypes(widget_id)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'interiorTypes',
        payload: data,
      })

      dispatch(setStepWeight('interiorType'))
      dispatch(toggleTradeInStep('interiorType'))
    })
    .catch(e => console.error(e))
    .finally(() => {
      dispatch(stopLoading())
    })
}

// для ОП
// функция нигде не исопльзуется?
export function setPreloadedTradeInState() {
  return (dispatch, getState) => {
    const state = getState()

    const preloadedTradeInState = loadState(TRADE_IN_STORAGE_KEY)

    if (
      !preloadedTradeInState ||
      !preloadedTradeInState.domainData ||
      !preloadedTradeInState.domainData.values ||
      !preloadedTradeInState.domainData.values.brand ||
      !preloadedTradeInState.domainData.values.brand.id
    )
      return

    const initialState = mergeTradeInStates(state.tradeIn, preloadedTradeInState)

    dispatch(setTradeInState(initialState)) // установка начального значения ТИ

    if (initialState.rate.status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE) {
      // Если есть оценка, посчитаем на всякий случай ещё раз
      dispatch(rateTradeIn())
    }
  }
}

export function setTradeInStateFromOP(newState) {
  return (dispatch, getState) => {
    const state = getState()
    const oldPrice = state.tradeIn.rate.result.possibleCarPrice
    const mergedStates = mergeTradeInStates(state.tradeIn, newState)

    dispatch(setTradeInState(mergedStates)) // установка начального значения ТИ

    const newPrice = mergedStates.rate.result.possibleCarPrice

    if (mergedStates.rate.status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && oldPrice !== newPrice) {
      // Если есть оценка и она отлична от предыдущей, посчитаем на всякий случай ещё раз
      dispatch(rateTradeIn())
    }

    dispatch(applyTradeIn(false, {}))
  }
}
// ----------- своя цена начало

export const setTradeInOwnerCarPrice = p => dispatch => {
  let payload = makeNumber(p)

  if (!Number.isInteger(payload)) {
    return
  }

  dispatch({
    type: TRADE_IN_TYPES.SET_OWNER_CAR_PRICE,
    payload,
  })
}

export const setTradeInOwnerCarPriceEnabled = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_OWNER_CAR_PRICE_ENABLED,
    payload,
  })
}

export const setTradeInOwnerCarPriceHintText = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_OWNER_CAR_PRICE_HINT_TEXT,
    payload,
  })
}

// --------- своя цена конец

// -------------- STANDALONE

export const loadBrands = () => (dispatch, getState) => {
  const {
    settings: { widget_id },
  } = getState()

  dispatch(startLoading())

  getBrands(widget_id)
    .then(({ data }) => {
      dispatch({
        type: TRADE_IN_TYPES.SET_DICTIONARY,
        dictionary: 'brands',
        payload: data.map(brand => ({
          id: brand.id,
          name: brand.name,
          search: brand.cyrillic_name,
          base: brand.popular,
        })),
      })
    })
    .catch() // тут нвдо сделать обработку ошибки
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const setTradeInStandaloneTypedValue =
  ({ name, value }) =>
  dispatch => {
    dispatch({
      type: TRADE_IN_TYPES.SET_STANDALONE_TYPED_VALUE,
      name,
      value,
    })
  }

export const setTradeInStandaloneScreen = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_STANDALONE_SCREEN,
    payload,
  })
}

export const setTradeInStandaloneCar = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_STANDALONE_CAR,
    payload,
  })
}

export const setTradeInStandalonePrice = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_STANDALONE_PRICE,
    payload,
  })
}
// ------------------ resets
export const resetCarIdentity = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_CAR_IDENTITY,
    value: '',
  })
  dispatch(
    setTradeInStandaloneTypedValue({
      name: 'identity',
      value: '',
    }),
  )
  dispatch(resetCity(partner))
}

export const resetBrand = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_BRAND,
    id: '',
    name: '',
  })
  dispatch(
    setTradeInStandaloneTypedValue({
      name: 'brand',
      value: '',
    }),
  )
  dispatch(resetModel(partner))
}

export const resetModel = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_MODEL,
    id: '',
    name: '',
  })
  dispatch(
    setTradeInStandaloneTypedValue({
      name: 'model',
      value: '',
    }),
  )

  switch (partner) {
    case TI_PARTNER.AUTO_RU:
    case TI_PARTNER.ONE_PLATFORM:
    case TI_PARTNER.MAX_POSTER:
      dispatch(resetGeneration(partner))
      break

    case TI_PARTNER.MOBILE_DE:
      dispatch(resetBody(partner))
      break

    default:
      console.error('TI partner is not defined', partner)
  }
}

export const resetGeneration = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_GENERATION,
    id: '',
    name: '',
  })
  dispatch(resetBody(partner))
}

export const resetBody = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_BODY,
    id: '',
    name: '',
  })

  switch (partner) {
    case TI_PARTNER.AUTO_RU:
    case TI_PARTNER.ONE_PLATFORM:
    case TI_PARTNER.MAX_POSTER:
      dispatch(resetModification(partner))
      break

    case TI_PARTNER.MOBILE_DE:
      dispatch(resetYear(partner))
      break

    default:
      console.error('TI partner is not defined', partner)
  }
}

export const resetModification = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_MODIFICATION,
    id: '',
    name: '',
  })
  dispatch(resetYear(partner))
}

export const resetYear = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_YEAR,
    payload: '',
  })
  dispatch(resetColor(partner))
}

export const resetColor = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_COLOR,
    id: '',
    name: '',
  })

  dispatch(resetOwner(partner))
}

export const resetOwner = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_OWNER,
    id: '',
    name: '',
  })
  dispatch(
    setTradeInStandaloneTypedValue({
      name: 'city',
      value: '',
    }),
  )

  switch (partner) {
    case TI_PARTNER.AUTO_RU:
    case TI_PARTNER.ONE_PLATFORM:
    case TI_PARTNER.MAX_POSTER:
      dispatch(resetCity(partner))
      break

    case TI_PARTNER.MOBILE_DE:
      dispatch(resetEngine(partner))
      break

    default:
      console.error('TI partner is not defined', partner)
  }
}

export const resetCity = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_CITY,
    id: '',
    name: '',
  })
  dispatch(resetMileage(partner))
}

export const resetEngine = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_ENGINE,
    id: '',
    name: '',
  })
  dispatch(resetGearBox(partner))
}

export const resetGearBox = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_GEARBOX,
    id: '',
    name: '',
  })
  dispatch(resetInteriorColor(partner))
}

export const resetInteriorColor = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_INTERIOR_COLOR,
    id: '',
    name: '',
  })
  dispatch(resetInteriorType(partner))
}

export const resetInteriorType = partner => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_INTERIOR_TYPE,
    id: '',
    name: '',
  })
  dispatch(resetMileage(partner))
}

export const resetMileage = () => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_MILEAGE,
    payload: '',
  })
}

export const setStepWeight = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_STEP_WEIGHT,
    payload,
  })
}

export const setTradeInHash = hash => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_HASH,
    payload: hash,
  })
}

export const setTradeInCondition = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_CONDITION,
    payload,
  })
}

export const setTradeInPartner = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_PARTNER,
    payload,
  })
}

export const setTradeInDictionary = (dictionary, payload) => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_DICTIONARY,
    dictionary,
    payload,
  })
}

export const setHidePartnerInTexts = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_HIDE_PARTNER_IN_TEXTS,
    payload,
  })
}

export const setUseCityAsString = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_USE_CITY_AS_STRING,
    payload,
  })
}

export const setTradeInDefaultCities = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_TRADE_IN_DEFAULT_CITIES,
    payload,
  })
}

export const setTradeInCityType = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_TRADE_IN_CITY_TYPE,
    payload,
  })
}

export const setTradeInEstimationType = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_TRADE_IN_ESTIMATION_TYPE,
    payload,
  })
}

export const setIsCarIdentityValid = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_IS_CAR_IDENTITY_VALID,
    payload,
  })
}

export const setIsTradeInIdentityEnabled = payload => dispatch => {
  dispatch({
    type: TRADE_IN_TYPES.SET_IS_TRADE_IN_BY_IDENTITY_ENABLED,
    payload,
  })
}
