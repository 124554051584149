import { LEASING_RATE_STATUS, LEASING_SETTINGS_BEHAVIOR } from '../../constants'
import { LEASING_TYPES } from '../types'

const INIT_STATE = {
  behaviour: LEASING_SETTINGS_BEHAVIOR.DISABLED,

  // behaviourByUser - тут не нужно, оно в модуле кредита - надо вынести в отдельный стор
  benefitValue: 0,

  partner: '',

  condition: '',

  parameters: {
    initialFee: 0,
    initialFeePercentage: 0,

    buybackPayment: 0,
    buybackPaymentPercentage: 0,

    period: 0,
  },

  activeProgram: {
    id: '',
  },

  status: LEASING_RATE_STATUS.INITIAL,

  programs: [],

  periods: [],
}

const leasing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEASING_TYPES.SET_LEASING_STATIC_INFO:
      return {
        ...state,
        behaviour: action.behaviour,
        benefitValue: action.benefitValue,
        partner: action.partner,
        condition: action.condition,
      }

    case LEASING_TYPES.BOOT_LEASING_PARAMETERS:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          initialFee: action.initialFee,
          initialFeePercentage: action.initialFeePercentage,
          buybackPaymentPercentage: action.buybackPaymentPercentage,
          period: action.period,
        },
      }

    case LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          buybackPayment: action.buybackPayment,
          buybackPaymentPercentage: action.buybackPaymentPercentage,
        },
      }

    case LEASING_TYPES.SET_ACTIVE_LEASING_PROGRAM:
      return {
        ...state,
        activeProgram: action.payload,
      }

    case LEASING_TYPES.SET_LEASING_PROGRAMS:
      return {
        ...state,
        programs: action.programs,
      }

    case LEASING_TYPES.SET_LEASING_STATUS:
      return {
        ...state,
        status: action.payload,
      }

    case LEASING_TYPES.SET_LEASING_INITIAL_FEE:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          initialFee: action.initialFee,
          initialFeePercentage: action.initialFeePercentage,
        },
      }

    case LEASING_TYPES.SET_LEASING_PERIOD:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          period: action.period,
        },
      }

    case LEASING_TYPES.SET_LEASING_PERIODS:
      return {
        ...state,
        periods: action.payload,
      }

    default:
      return state
  }
}

export default leasing
