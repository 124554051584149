import { connect, useDispatch } from 'react-redux'

import { setTradeInStandaloneTypedValue } from '../../../../store/actions'
import TradeInStandalonePartsInput from '../../parts/input'

function TradeInStandaloneBrandInput({ typedValue, focus }) {
  const dispatch = useDispatch()

  const onSet = value => {
    dispatch(
      setTradeInStandaloneTypedValue({
        name: 'brand',
        value,
      }),
    )
  }
  return <TradeInStandalonePartsInput name="brand" onSetValue={onSet} value={typedValue} focus={focus} />
}

function mapStateToProps({
  tradeIn: {
    values: {
      brand: { name: dictionaryValue },
    },
    typedValues,
  },
}) {
  // чтобы не было бага в локалсторадж
  const typedValue = () => {
    if (typedValues && typedValues.brand) {
      return typedValues.brand
    } else {
      return ''
    }
  }

  return {
    dictionaryValue,
    typedValue: typedValue(),
  }
}

export default connect(mapStateToProps)(TradeInStandaloneBrandInput)
