import { TI_PARTNER, TRADE_IN_DATA_FILLED } from '../constants'

export const isTradeInDataFilled = (values, partner = TI_PARTNER.AUTO_RU) => {
  let isFilledAllValues = false
  let isFilledSomeValues = false

  switch (partner) {
    case TI_PARTNER.ONE_PLATFORM:
    case TI_PARTNER.AUTO_RU:
    case TI_PARTNER.MAX_POSTER:
      isFilledAllValues =
        values?.brand?.id &&
        values?.model?.id &&
        values?.generation?.id &&
        values?.body?.id &&
        values?.modification?.id &&
        values?.year?.value &&
        values?.color?.id &&
        values?.owner?.id &&
        values?.city?.id &&
        !!Number(values?.mileage?.value)

      isFilledSomeValues =
        values?.brand?.id ||
        values?.model?.id ||
        values?.generation?.id ||
        values?.body?.id ||
        values?.modification?.id ||
        values?.year?.value ||
        values?.color?.id ||
        values?.owner?.id ||
        values?.city?.id ||
        !!Number(values?.mileage?.value)

      break

    case TI_PARTNER.MOBILE_DE:
      isFilledAllValues =
        values?.brand?.id &&
        values?.model?.id &&
        values?.body?.id &&
        values?.year?.value &&
        values?.color?.id &&
        values?.owner?.id &&
        values?.engine?.id &&
        values?.gearbox?.id &&
        values?.interiorColor?.id &&
        values?.interiorType?.id &&
        !!Number(values?.mileage?.value)

      isFilledSomeValues =
        values?.brand?.id ||
        values?.model?.id ||
        values?.body?.id ||
        values?.year?.value ||
        values?.color?.id ||
        values?.owner?.id ||
        values?.engine?.id ||
        values?.gearbox?.id ||
        values?.interiorColor?.id ||
        values?.interiorType?.id ||
        !!Number(values?.mileage?.value)
      break

    default:
      console.error('TI partner is not defined', partner)
  }

  if (isFilledAllValues) {
    return TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED
  } else if (isFilledSomeValues) {
    return TRADE_IN_DATA_FILLED.SOME_VALUES_FILLED
  } else {
    return TRADE_IN_DATA_FILLED.NO_VALUES_FILLED
  }
}

export const isTradeInByIdentityDataFilled = values => {
  const isFilledAllValues = values?.identity?.value && values?.city?.id && !!Number(values?.mileage?.value)
  const isFilledSomeValues = values?.identity?.value || values?.city?.id || !!Number(values?.mileage?.value)

  if (isFilledAllValues) {
    return TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED
  } else if (isFilledSomeValues) {
    return TRADE_IN_DATA_FILLED.SOME_VALUES_FILLED
  } else {
    return TRADE_IN_DATA_FILLED.NO_VALUES_FILLED
  }
}
