import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectPictureHelper = (preset, detail, listVersion) => {
  if (listVersion === '2') {
    return detail.picture
  }

  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.picture()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.picture) {
    if (typeof detail.picture === 'string') {
      return detail.picture
    } else if (typeof detail.picture === 'function') {
      return detail.picture()
    }
  }

  return ''
}
