export const formID = {
  name: 'kdxcc_name',
  lastName: 'kdxcc_lastName',
  phone: 'kdxcc_phone',
  email: 'kdxcc_email',
  agreement: 'kdxcc_agreement',
  communicationProcessingAccepted: 'kdxcc_communicationProcessingAccepted',
  communicationProcessingPhone: 'kdxcc_communicationProcessingPhone',
  communicationProcessingPost: 'kdxcc_communicationProcessingPost',
  communicationProcessingSms: 'kdxcc_communicationProcessingSms',
  communicationProcessingEmail: 'kdxcc_communicationProcessingEmail',
  smsCode: 'kdxcc_sms_code',
  captcha: 'kdxcc_captcha',
}
