import { connect, useDispatch } from 'react-redux'

import { TI_PARTNER, TRADE_IN_ESTIMATION_TYPE, TRADE_IN_STANDALONE_SCREEN } from '../../constants'
import { eventName } from '../../helpers'
import {
  resetBody,
  resetBrand,
  resetCarIdentity,
  resetCity,
  resetColor,
  resetGeneration,
  resetMileage,
  resetModel,
  resetModification,
  resetOwner,
  resetSavedSmartContract,
  resetYear,
  setStepWeight,
  setTradeInStandaloneScreen,
  setTradeInStep,
} from '../../store/actions'
import s from './styles/filter.module.scss'

function TradeInStandaloneFilter({
  value,
  isActive,
  willBeDeleted,
  idSC,
  step,
  standaloneScreen,
  stepKey,
  index,
  userWantsToResetStep,
  userWantsToSaveStep,
  partner,
  isEstimationByIdentity,
}) {
  const dispatch = useDispatch()

  const bubbleClickEvent = stepKey => new CustomEvent(eventName('bubbleClick'), { detail: stepKey })

  // установка активного шага ТИ
  const setActiveTradeInStep = e => {
    e.preventDefault()
    e.stopPropagation()

    if ([TRADE_IN_STANDALONE_SCREEN.SAVE, TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM].includes(standaloneScreen)) {
      dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.RATE))
    }
    if (stepKey === step && standaloneScreen === TRADE_IN_STANDALONE_SCREEN.RATE) {
      return
    }

    const inputsToReset = ['brand', 'model', 'city', 'mileage']

    if (inputsToReset.includes(stepKey) && !isEstimationByIdentity) {
      document.dispatchEvent(bubbleClickEvent(stepKey))
    }

    dispatch(setTradeInStep(stepKey))
  }

  // сброс значений ТИ
  const resetTradeInStep = e => {
    e.preventDefault()
    e.stopPropagation()

    if (idSC) {
      dispatch(resetSavedSmartContract())
    }

    if ([TRADE_IN_STANDALONE_SCREEN.SAVE, TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM].includes(standaloneScreen)) {
      dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.RATE))
    }

    if (isEstimationByIdentity) {
      switch (stepKey) {
        case 'identity':
          dispatch(resetCarIdentity(partner))
          break
        case 'city':
          dispatch(resetCity(partner))
          break
        case 'mileage':
          dispatch(resetMileage(partner))
          break
        default:
          break
      }
    } else {
      switch (stepKey) {
        case 'brand':
          dispatch(resetBrand(partner))
          break
        case 'model':
          dispatch(resetModel(partner))
          break
        case 'generation':
          dispatch(resetGeneration(partner))
          break
        case 'body':
          dispatch(resetBody(partner))
          break
        case 'modification':
          dispatch(resetModification(partner))
          break
        case 'year':
          dispatch(resetYear(partner))
          break
        case 'color':
          dispatch(resetColor(partner))
          break
        case 'owner':
          dispatch(resetOwner(partner))
          break
        case 'city':
          dispatch(resetCity(partner))
          break
        case 'mileage':
          dispatch(resetMileage(partner))
          break
        default:
          break
      }
    }

    dispatch(setStepWeight(stepKey))
    dispatch(setTradeInStep(stepKey))
  }

  // делаем прозрачными шаги, которые будут сброшены при клике на крестик
  const userWantsToResetStepHandler = index => {
    if (!isEstimationByIdentity) {
      userWantsToResetStep(index)
    }
  }

  // убрали курсор с крестика
  const userWantsToSaveStepHandler = () => {
    if (!isEstimationByIdentity) {
      userWantsToSaveStep()
    }
  }

  // классы элемента
  const classes = () => {
    let c = [s.filter]

    if (isActive && TRADE_IN_STANDALONE_SCREEN.RATE === standaloneScreen) {
      c.push(s.filter_active)
    }

    if (willBeDeleted && !isEstimationByIdentity) {
      c.push(s.filter_deleted)
    }

    c.push(isEstimationByIdentity ? s.filter_symmetrical : s.filter_deletable)

    return c.join(' ')
  }

  return (
    <div className={classes()} onClick={e => setActiveTradeInStep(e)}>
      <div className={s.filter__text}>{value}</div>
      {!isEstimationByIdentity && (
        <div className={s.filter__action}>
          <div
            className={s.action}
            onMouseEnter={() => userWantsToResetStepHandler(index)}
            onMouseLeave={() => userWantsToSaveStepHandler()}
            onClick={e => resetTradeInStep(e)}
          >
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.50011 4.82166L9.62511 0.696655L10.8034 1.87499L6.67844 5.99999L10.8034 10.125L9.62511 11.3033L5.50011 7.17832L1.37511 11.3033L0.196777 10.125L4.32178 5.99999L0.196777 1.87499L1.37511 0.696655L5.50011 4.82166Z"
                fill="#1C69D4"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  tradeIn: { step, values, standaloneScreen, partner, estimationType },
  saved_sc: { id: idSC },
}) {
  const isEstimationByIdentity =
    partner === TI_PARTNER.AUTO_RU && estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

  return {
    step,
    values,
    standaloneScreen,
    idSC,
    partner,
    isEstimationByIdentity,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneFilter)
