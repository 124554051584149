import { combineReducers } from 'redux'

import accessories from './accessories'
import api from './api'
import benefits from './benefits'
import bullet from './bullet'
import buyModelStandalone from './buy_model_standalone'
import car from './car'
import cars from './cars'
import clientModels from './client_models'
import credit from './credit'
import credit_standalone from './credit_standalone'
import data_before_auth from './data_before_auth'
import form from './form'
import leasing from './leasing'
import onlinePayment from './online_payment'
import saved_sc from './saved_sc'
import screen from './screen'
import settings from './settings'
import simpleForm from './simple_form'
import tradeIn from './trade_in'
import tradeInStandalone from './trade_in_standalone'

export default combineReducers({
  api,
  screen,
  settings,
  car,
  cars,
  tradeIn,
  benefits,
  accessories,
  credit,
  onlinePayment,
  saved_sc,
  data_before_auth,
  form,
  credit_standalone,
  clientModels,
  bullet,
  leasing,
  tradeInStandalone,
  buyModelStandalone,
  simpleForm,
})
