import { MODEL_DATA_RAW_BRAND, MODEL_DATA_RAW_MODEL } from '../constants'
import store from '../store/store'
import { modelDataToObject } from './model_data_parser'

export const callHavalEventListeners = savedFrom => {
  const state = store.getState()
  const listButtonText = state?.settings?.visual?.list?.button?.text?.toLowerCase()
  const detailButtonText = state?.settings?.visual?.buttons?.openWidget?.text?.toLowerCase()

  const carName = state?.car?.name
  const modelData = state?.car?.modelData
  const modelDataObj = modelDataToObject(modelData)
  const creditBehaviourByUser = state?.credit?.behaviourByUser

  const model = modelDataObj[MODEL_DATA_RAW_MODEL]
    ? `${modelDataObj[MODEL_DATA_RAW_BRAND]} ${modelDataObj[MODEL_DATA_RAW_MODEL]}`
    : carName

  return {
    model,
    listButtonText,
    detailButtonText,
    creditBehaviourByUser,
    savedFrom,
  }
}
