import { load } from 'redux-localstorage-simple'

import { isEmailValid, isPhoneValid } from './validations'

export const checkForAuth = fields => {
  //собираем обязательные и не скрытые поля на базовой форме
  let requiredOnBaseFormFields = []

  for (let field in fields) {
    if (fields[field].requiredOnBaseForm && !fields[field].hideFromBaseForm) {
      requiredOnBaseFormFields.push(field)
    }
  }

  if (!requiredOnBaseFormFields.includes('phone')) {
    requiredOnBaseFormFields.push('phone')
  }

  // если они не заполнены, то переходим на экран заполнения
  const dataFromLocalStorage = load({
    states: ['form'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const personalDataErrors = []

  requiredOnBaseFormFields.forEach(field => {
    if (
      field === 'phone' &&
      !isPhoneValid(dataFromLocalStorage.form.values['phone'], dataFromLocalStorage.form.phoneSelection['country'])
    ) {
      personalDataErrors.push('phone')
    } else if (field === 'email' && !isEmailValid(dataFromLocalStorage.form.values['email'])) {
      personalDataErrors.push('email')
    } else if (!dataFromLocalStorage.form.values[field]) {
      personalDataErrors.push(field)
    }
  })

  return personalDataErrors
}
