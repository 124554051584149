import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { connect, useDispatch } from 'react-redux'

import { formatMileage } from '../../../helpers'
import { setTradeInMileage, toggleTradeInStep } from '../../../store/actions'
import s from '../../Ui/input/input.module.scss'

function TradeInMileage({ step, value, forceOpened }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'mileage' || forceOpened ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('mileage'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.mileage.title')}
          <div className="kdxsc-accordion-item__subtitle">
            {formatMileage(value, t('tradeInS.kilometers')) || t('tradeIn.mileage.empty')}
          </div>
        </div>
        {!forceOpened && <span className="kdxsc-accordion-item__icon" />}
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <div className="kdxsc-tradein-item">
            <div className="kdxsc-tradein-item__input mb0">
              <div className={s.wrapper}>
                <div className={s.wrapper__input}>
                  <NumberFormat
                    className={s.input}
                    name="tradein-mileage"
                    value={value}
                    onChange={e =>
                      dispatch(setTradeInMileage({ payload: Number(e.target.value.replace(/[^\d]/g, '')) }))
                    }
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={' '}
                    decimalScale={0}
                    placeholder={`0 ${t('tradeInS.kilometers')}`}
                    isNumericString={false}
                    suffix={` ${t('tradeInS.kilometers')}`}
                    maxLength={10}
                    defaultValue={''}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      mileage: { value },
    },
  },
}) {
  return {
    step,
    value,
  }
}

export default connect(mapStateToProps)(TradeInMileage)
