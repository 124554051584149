export const deprecatedVisualHelper = (header_text, form_agreement_text) => {
  const deprecated = {}

  if (header_text) {
    if (typeof header_text == 'string') {
      deprecated['headerText'] = header_text
    } else if (typeof header_text == 'function') {
      deprecated['headerText'] = header_text()
    }
  }

  if (form_agreement_text) {
    if (typeof form_agreement_text == 'string') {
      deprecated['formAgreementText'] = form_agreement_text
    } else if (typeof form_agreement_text == 'function') {
      deprecated['formAgreementText'] = form_agreement_text()
    }
  }

  return deprecated
}
