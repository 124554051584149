module.exports = {
  trade_in: {
    top: 'calculate',
    bottom: 'the trade-in',
  },

  credit: {
    top: 'calculate',
    bottom: 'the loan',
  },

  cars: {
    top: 'cars',
    bottom: 'in stock',
  },

  open: 'open',
  sc: 'smart contract',
  my: 'my',
  yourCar: 'your car',
  inTradeIn: 'in trade-in',
}
