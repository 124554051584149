export const setAccessories = accessories => ({
  type: 'SET_ACCESSORIES',
  accessories,
})

export const clearSelectedAccessories = () => ({
  type: 'CLEAR_SELECTED_ACCESSORIES',
})

export const enableAccessory = accessory => dispatch => {
  dispatch({
    type: 'ENABLE_ACCESSORY',
    accessory,
  })
}

export const disableAccessory = accessory => dispatch => {
  dispatch({
    type: 'DISABLE_ACCESSORY',
    accessory,
  })
}
