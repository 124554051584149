import i18n from 'i18next'

import { FORM_SOURCE } from '../../constants'

/**
 * Имя формы - нужно для правильного получения данных из стора,
 * event listener'ов
 * и правильного отображения данных на странице
 */
export const formName = config => {
  let res = {
    widgetType: 'unknownNewFormWidgetType',
    formKey: 'unknownNewFormKey',
    placement: 'unknownPlacement',
    clickOutsideForm: 'unknownListenerForClickOutsideFrom',
    clickInsideForm: 'unknownListenerForClickInsideFrom',
  }

  switch (config.source) {
    // может быть 2 формы - на странице и в попапе
    case FORM_SOURCE.CAR_ORDER:
      return {
        widgetType: `${config.placement}CarOrderForm`,
        formKey: `${config.placement}CarOrderForm`,
        placement: `${config.placement}CarOrderForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutside${config.placement}CarOrderForm`,
        clickInsideForm: `kdxsc${config.placement}CarOrderFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.BUY_MODEL:
      return {
        widgetType: 'buy_model',
        formKey: `buyModelForm`,
        placement: `buyModelForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideBuyModelForm`,
        clickInsideForm: `kdxscBuyModelFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.SIMPLE_FORM:
      return {
        widgetType: 'popup_form', // название для БЭ
        formKey: `simpleForm`,
        placement: `simpleForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideSimpleForm`,
        clickInsideForm: `kdxscSimpleFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.WIDGET:
      return {
        widgetType: 'common',
        formKey: `widgetForm`,
        placement: `widgetForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideWidgetForm`,
        clickInsideForm: `kdxscWidgetFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.WIDGET_AUTH:
      return {
        widgetType: 'common',
        formKey: `widgetAuthScreenForm`,
        placement: `widgetAuthScreenForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideWidgetAuthForm`,
        clickInsideForm: `kdxscWidgetAuthFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.ONLINE_PAYMENT:
      return {
        widgetType: 'common',
        formKey: `widgetOnlinePaymentForm`,
        placement: `widgetOnlinePaymentForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideWidgetOnlinePaymentForm`,
        clickInsideForm: `kdxscWidgetOnlinePaymentFormHasBeenSubmitted`,
      }

    case FORM_SOURCE.TRADE_IN_STANDALONE:
      return {
        widgetType: 'TradeInStandalone',
        formKey: `tradeInStandaloneForm`,
        placement: `tradeInStandaloneForm`,
        clickOutsideForm: `kdxscClickOnSubmitButtonOutsideTradeInStandaloneForm`,
        clickInsideForm: `kdxscTradeInStandaloneFormHasBeenSubmitted`,
      }

    default:
      return res
  }
}

/**
 * Проверяет размер экрана и месторасположение формы
 * Если экран маленький (примерно макБук про 13), то убираем названия категорий
 * На вертикальных сотавляем
 * @param config
 * @returns {string|boolean}
 */
export const screenSizeAndFormTypeFilter = config => {
  if (config.source === FORM_SOURCE.CAR_ORDER && config.placement !== 'page') {
    const w = window.innerWidth
    const h = window.innerHeight

    const isHorizontal = h < w

    if (isHorizontal && w < 1500 && h < 800) {
      return false
    }
  }

  return true
}

/**
 * Название блока ДЦ и модель
 * @param dealerships
 * @param config
 * @returns {string}
 */
export const carBlockTitle = (dealerships, config) => {
  const isTitleDisplayed = screenSizeAndFormTypeFilter(config)

  if (!isTitleDisplayed) {
    return ''
  }

  if (config.source !== FORM_SOURCE.BUY_MODEL) {
    return i18n.t('carOrderForm.dealership')
  }

  if (dealerships?.length === 1) {
    return i18n.t('carOrderForm.car')
  }

  return dealerships?.length > 1 && canModelsBeShown(dealerships, config)
    ? i18n.t('carOrderForm.car')
    : i18n.t('carOrderForm.dealership')
}

/**
 * Можно ли показывать поле выбора модели
 * @param dealerships
 * @param config
 * @returns {boolean}
 */
export const canModelsBeShown = (dealerships, config) => {
  // если модель можно выбрать, то показываем ее всегда
  if (config.disableModelSelection === false) {
    return true
  }

  // если модель можно показать, то ее показываем
  if (config.displayModelField === true) {
    return true
  }

  // если предустановленная модель найдена во всех ДЦ (1 или много) сразу, то не показываем
  // иначе показываем
  return !isDefaultModelExistsInEachDealership(dealerships, config)
}

/**
 * Проверяет, что предустанолвенная модель из конфига InitSmartBuyModelForm defaultModel
 * есть в каждом из дилеских центров
 * @param dealerships
 * @param config
 * @returns {boolean}
 */
export const isDefaultModelExistsInEachDealership = (dealerships, config) => {
  let res = 0

  if (!dealerships || !config.defaultModel) {
    return false
  }

  dealerships.forEach(d => {
    const modelFromList = findModelFromList(d?.forBuyModel?.clientModels, config)
    if (modelFromList) {
      res++
    }
  })

  return res === dealerships.length
}

/**
 * Находит предустановленную модель из списка моделей
 * @param models
 * @param config
 * @returns {boolean|*}
 */
export const findModelFromList = (models, config) => {
  if (!models || !Array.isArray(models)) {
    return false
  }
  return models.find(m => {
    const names = []

    if (typeof m === 'string') {
      names.push(m.toLowerCase())
    } else if (m?.attributes?.name) {
      names.push(m?.attributes?.name?.toLowerCase())
    }

    if (m.attributes && m.attributes.modelData) {
      const concat = []

      if (m.attributes.modelData.brand) {
        concat.push(m.attributes.modelData.brand)
      }

      if (m.attributes.modelData.model) {
        concat.push(m.attributes.modelData.model)
      }

      if (concat.length) {
        names.push(concat.join(' ').toLowerCase())
      }
    }

    if (typeof config.defaultModel === 'string') {
      return names.includes(config.defaultModel.toLowerCase())
    }

    if (typeof config.defaultModel === 'object') {
      if (config.defaultModel.text) {
        if (names.includes(config.defaultModel.text.toLowerCase())) {
          return true
        }
      }

      if (config.defaultModel.alternate && Array.isArray(config.defaultModel.alternate)) {
        for (const variant of config.defaultModel.alternate) {
          if (names.includes(variant.toLowerCase())) {
            return true
          }
        }
      }

      if (config.defaultModel.modelData && config.defaultModel.modelData.onePlatformModelId) {
        return m?.attributes?.modelData?.one_platform_model_id === config.defaultModel.modelData.onePlatformModelId
      }
    }

    return false
  })
}
