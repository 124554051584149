import { connect, useDispatch } from 'react-redux'

import { formatMileage, makeNumber } from '../../../../helpers'
import { setTradeInMileage } from '../../../../store/actions'
import TradeInStandalonePartsInput from '../../parts/input'
import s from './mileage.module.scss'

function TradeInStandaloneMileageInput({ value, focus }) {
  const dispatch = useDispatch()

  const onSet = p => {
    let payload = makeNumber(p)

    if (!Number.isInteger(payload)) {
      payload = 0
    }

    if (payload > 999999) {
      payload = 999999
    }

    Promise.resolve(dispatch(setTradeInMileage({ payload }))).then(() => {
      const evt = new CustomEvent('tradeInMileageWasSet')
      window.dispatchEvent(evt)
    })
  }

  return (
    <div className={s.wrapper}>
      <div className={s.wrapper__input}>
        <TradeInStandalonePartsInput onSetValue={onSet} name="mileage" value={value} focus={focus} />
      </div>
      {/*<div className={s.wrapper__range}>*/}
      {/*  <input*/}
      {/*    name="kdxsc-tis-mileage-range"*/}
      {/*    type="range"*/}
      {/*    min="1"*/}
      {/*    max="1000000"*/}
      {/*    className="kdxsc-range"*/}
      {/*    step="10000"*/}
      {/*    value={value}*/}
      {/*    onChange={e => onSet(e.target.value)}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      mileage: { value },
    },
  },
}) {
  return {
    step,
    value,
    formattedValue: value ? formatMileage(value) : '',
  }
}

export default connect(mapStateToProps)(TradeInStandaloneMileageInput)
