import { CREDIT_STANDALONE_SCREEN } from '../../constants'
import {
  getCarList,
  getLocale,
  getPersonalFilled,
  getSavedCreditParams,
  getSavedSelectedProgram,
  isLeasingType,
  mapPrograms,
} from '../helpers/credit_standalone'
import { CREDIT_STANDALONE } from '../types'

const INIT_STATE = {
  screen: CREDIT_STANDALONE_SCREEN.INITIAL,
  loading: false,
  error: null,
  module: {
    type: 'credit',
    isLeasing: false,
    locale: 'c', // c = credit; l = leasing
  },
  config: {
    widgetId: '',
    currency: 'RUB',
    locale: 'ru',
    agreements: {
      type: '',
      personalDataProcessing: {
        baseFormText: '',
        onlinePaymentFormText: '',
        default_setting: true,
      },
      communicationProcessing: {
        baseFormText: '',
        onlinePaymentFormText: '',
        default_setting: true,
        phone: true,
        sms: true,
        email: true,
        post: true,
      },
    },
    useCustomDisclaimer: false,
    customDisclaimerText: '',
    agreementText: 'Я даю согласие на обработку персональных данных и дальнейшую коммуникацию',
    createSmartContractText: 'Открыть смарт контракт',
    lifeInsurance: false,
    kaskoInsurance: false,
    gapInsurance: false,
    videoUrl: '',
    splitCarSelect: false,
    hideProgramBankName: false,
    hideProgramPercentage: false,
    defaultSettingsAvailable: false,
    disableBuybackPayment: false,
    hideBuybackPaymentForAllWidgets: undefined,
    fields: {
      name: {
        hide: true,
        required: false,
      },
      lastName: {
        hide: true,
        required: false,
      },
      email: {
        hide: true,
        required: false,
      },
    },
    isPriceHidden: false,
    palette: {
      button_color: '#000000FF',
      button_hover_color: '#212121',
      button_hover_text_color: '#ffffff',
      button_text_color: '#ffffff',
    },
    smartContract: {
      genericId: '',
      id: '',
      smartContractUrl: '',
      token: '',
      createdFromBackground: false,
    },
    condition: null,
    creditOnlineApprove: false,
    showProgramsBeforePersonalData: false,
  },
  conditions: {
    cars: [],
    selectCars: [],
    // rename to carModelName
    carModel: '',
    carModelId: '',
    carModelData: '',
    carPrice: '',
    carPriceInitial: 0,
    initFee: '0',
    initFeePercent: undefined,
    buyback: '',
    buybackPercent: undefined,
    term: undefined,
    benefitValue: 0,
    possibleTerms: [],
    defaultConditions: {
      12: null,
      24: null,
      36: null,
      48: null,
      60: null,
      72: null,
      84: null,
    },
  },
  personal: {
    filled: false,
  },
  programs: {
    selected: undefined,
    list: [],
    loadedOnce: false,
  },

  dealerships: {
    credit: [],
    leasing: [],
  },
  city: {
    credit: [],
  },
  showComplectations: false,
  showBrandInModelSelect: false,

  activeDealership: {
    credit: {},
    leasing: {},
  },
}

const credit_standalone = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREDIT_STANDALONE.SET_TYPE: {
      return {
        ...state,
        module: {
          type: action.payload,
          isLeasing: isLeasingType(action.payload),
          locale: getLocale(action.payload),
        },
      }
    }
    case CREDIT_STANDALONE.SET_INIT_CONFIG: {
      const savedParams = getSavedCreditParams()

      let initFeePercent = savedParams?.initFeePercent || action.payload.initFeePercent
      if (state.module.isLeasing && initFeePercent > 50) {
        initFeePercent = 50
      }

      let term = action.payload.term
      if (action.payload.possiblePeriods.numericPeriods.includes(savedParams?.term)) {
        term = savedParams?.term
      }

      const hideBuybackPaymentForAllWidgets =
        state.config.hideBuybackPaymentForAllWidgets === undefined
          ? action.payload.hideBuybackPaymentForAllWidgets
          : state.config.hideBuybackPaymentForAllWidgets

      return {
        ...state,

        screen: getCarList(action.payload.cars).length
          ? CREDIT_STANDALONE_SCREEN.CREDIT_CONDITIONS
          : CREDIT_STANDALONE_SCREEN.CARS_NOT_FOUND,

        config: {
          ...state.config,
          widgetId: action.payload.widgetId,
          currency: action.payload.currency,
          locale: action.payload.locale,
          useCustomDisclaimer: action.payload.useCustomDisclaimer,
          customDisclaimerText: action.payload.customDisclaimerText,
          agreements: action.payload.agreements,
          agreementText: action.payload.agreementText,
          createSmartContractText: action.payload.createSmartContractText,
          lifeInsurance: action.payload.lifeInsurance,
          kaskoInsurance: action.payload.kaskoInsurance,
          gapInsurance: action.payload.gapInsurance,
          creditOnlineApprove: action.payload.creditOnlineApprove,
          videoUrl: action.payload.videoUrl,
          palette: action.payload.palette,
          isPriceHidden: action.payload.isPriceHidden,
          splitCarSelect: action.payload?.splitCarSelect || false,
          hideProgramBankName: !!action.payload.hideProgramBankName,
          hideProgramPercentage: !!action.payload.hideProgramPercentage,
          showRemainingPaymentInProgram: action.payload.showRemainingPaymentInProgram,
          priceToolTipEnabled: !!action.payload.priceToolTipEnabled,
          defaultSettingsAvailable: !!action.payload.defaultSettingsAvailable,
          disableBuybackPayment: !!action.payload.disableBuybackPayment,
          hideBuybackPaymentForAllWidgets,
          fields: {
            name: {
              hide: action.payload.fields.name.hide_from_base_form,
              required: action.payload.fields.name.required_on_base_form,
            },
            lastName: {
              hide: action.payload.fields.last_name.hide_from_base_form,
              required: action.payload.fields.last_name.required_on_base_form,
            },
            email: {
              hide: action.payload.fields.email.hide_from_base_form,
              required: action.payload.fields.email.required_on_base_form,
            },
          },
          condition: action.payload.condition,
          showProgramsBeforePersonalData: action.payload.showProgramsBeforePersonalData,
        },

        conditions: {
          ...state.conditions,
          initFeePercent,
          possibleTerms: action.payload.possiblePeriods,
          buybackPercent: savedParams?.buybackPercent || action.payload.buybackPercent,
          term,
          cars: getCarList(action.payload.cars),
        },

        personal: {
          ...state.personal,
          filled: getPersonalFilled(action.payload.fields),
        },
      }
    }

    case CREDIT_STANDALONE.SET_SCREEN:
      return {
        ...state,
        screen: action.payload,
        error: null,
      }

    case CREDIT_STANDALONE.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case CREDIT_STANDALONE.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case CREDIT_STANDALONE.SET_CAR_BENEFITS:
      return {
        ...state,
        conditions: { ...state.conditions, benefitValue: action.payload.benefitValue },
        config: { ...state.config, condition: action.payload.condition },
      }

    case CREDIT_STANDALONE.SET_CAR_MODEL: {
      if (action.payload === null) {
        return {
          ...state,
          conditions: {
            ...state.conditions,
            carModel: '',
            carModelId: '',
            carModelData: '',
            benefitValue: 0,
            carPriceInitial: 0,
          },
          programs: {
            ...state.programs,
            selected: undefined,
          },
          config: {
            ...state.config,
            smartContract: {
              genericId: '',
              id: '',
              smartContractUrl: '',
            },
          },
        }
      }

      return {
        ...state,
        conditions: {
          ...state.conditions,
          carModel: action.payload.name,
          carModelId: action.payload.id,
          carPrice: action.payload.price,
          carPriceInitial: action.payload.price,
          carModelData: action.payload.modelData,
        },
        programs: {
          ...state.programs,
          selected: undefined,
        },
        config: {
          ...state.config,
          smartContract: {
            genericId: '',
            id: '',
            smartContractUrl: '',
          },
        },
      }
    }

    case CREDIT_STANDALONE.SET_DEALERSHIPS:
      return {
        ...state,
        dealerships: {
          ...state.dealerships,
          [action.calcType]: action.dealerships,
        },
      }
    case CREDIT_STANDALONE.SET_DEALERSHIPS_CITY:
      return {
        ...state,
        city: {
          ...state.city,
          [action.calcType]: action.city,
        },
      }
    case CREDIT_STANDALONE.SET_SHOW_COMPLECTATIONS:
      return {
        ...state,
        showComplectations: action.payload,
      }
    case CREDIT_STANDALONE.SET_SHOW_BRAND_IN_MODEL_SELECT:
      return {
        ...state,
        showBrandInModelSelect: action.payload,
      }
    case CREDIT_STANDALONE.SET_SELECT_CARS:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          selectCars: action.payload,
        },
      }

    case CREDIT_STANDALONE.SET_ACTIVE_DEALERSHIP:
      return {
        ...state,
        activeDealership: {
          ...state.activeDealership,
          [action.calcType]: action.dealership,
        },
      }

    case CREDIT_STANDALONE.SET_CAR_PRICE: {
      return {
        ...state,
        conditions: action.payload,
      }
    }

    case CREDIT_STANDALONE.SET_INITFEE: {
      return {
        ...state,
        conditions: action.payload,
      }
    }

    case CREDIT_STANDALONE.SET_INITFEE_PERCENT: {
      return {
        ...state,
        conditions: action.payload,
      }
    }

    case CREDIT_STANDALONE.SET_BUYBACK: {
      return {
        ...state,
        conditions: action.payload,
      }
    }

    case CREDIT_STANDALONE.SET_BUYBACK_PERCENT: {
      return {
        ...state,
        conditions: action.payload,
      }
    }

    case CREDIT_STANDALONE.SET_TERM:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          term: action.payload,
        },
      }

    case CREDIT_STANDALONE.SET_PERSONAL_DATA_FILLED:
      return {
        ...state,
        personal: {
          ...state.personal,
          filled: action.payload,
        },
      }

    case CREDIT_STANDALONE.SET_PROGRAMS_LOADED_ONCE: {
      return {
        ...state,
        programs: {
          ...state.programs,
          loadedOnce: true,
        },
      }
    }

    case CREDIT_STANDALONE.SET_PROGRAMS: {
      const programList = mapPrograms(action.payload, state.module.isLeasing)

      let savedSelectedProgram = getSavedSelectedProgram()

      if (savedSelectedProgram !== undefined) {
        savedSelectedProgram = programList.find(p => p.id === savedSelectedProgram.id)
      }

      return {
        ...state,
        programs: {
          ...state.programs,
          list: programList,
          selected: savedSelectedProgram,
        },
      }
    }

    case CREDIT_STANDALONE.SELECT_PROGRAM:
      return {
        ...state,
        programs: {
          ...state.programs,
          selected: action.payload,
        },
        config: {
          ...state.config,
          smartContract: {
            genericId: '',
            id: '',
            smartContractUrl: '',
            createdFromBackground: false,
          },
        },
        error: null,
      }

    case CREDIT_STANDALONE.SET_SMART_CONTRACT:
      if (action.payload === null) {
        return {
          ...state,
          config: {
            ...state.config,
            smartContract: {
              genericId: '',
              id: '',
              smartContractUrl: '',
              token: '',
              createdFromBackground: false,
            },
          },
        }
      }

      return {
        ...state,
        config: {
          ...state.config,
          smartContract: {
            ...action.payload,
          },
        },
      }

    case CREDIT_STANDALONE.SET_DEFAULT_CONDITIONS:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          defaultConditions: action.payload,
        },
      }

    case CREDIT_STANDALONE.SET_POSSIBLE_TERMS:
      return {
        ...state,
        conditions: {
          ...state.conditions,
          possibleTerms: action.payload,
        },
      }

    default:
      return state
  }
}

export default credit_standalone
