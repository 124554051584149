import { useState } from 'react'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './blockAccordion.module.scss'

function BlockAccordion({ children, title }) {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className={s.block}>
      <div className={s.block__header} onClick={() => setIsOpened(!isOpened)}>
        <div className={s.header}>
          <div className={s.header__title}>{title}</div>
          <div className={s.header__action}>
            {getProjectName() === 'generic_v2' ? (
              <BlockGenericV2Icons isOpened={isOpened} />
            ) : (
              <BlockDefaultIcons isOpened={isOpened} />
            )}
          </div>
        </div>
      </div>
      {isOpened && <div className={s.block__content}>{children}</div>}
    </div>
  )
}

export default BlockAccordion

const BlockDefaultIcons = ({ isOpened }) => {
  return (
    <>
      {!isOpened && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 14.172L16.95 9.22205L18.364 10.636L12 17L5.63599 10.636L7.04999 9.22205L12 14.172Z"
            fill="#222222"
          />
        </svg>
      )}
      {isOpened && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8.828L7.04999 13.778L5.63599 12.364L12 6L18.364 12.364L16.95 13.778L12 8.828Z"
            fill="#222222"
          />
        </svg>
      )}
    </>
  )
}

const BlockGenericV2Icons = ({ isOpened }) => {
  console.log(isOpened)

  return (
    <>
      {!isOpened && (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
          <path d="M0.5 9L8.5 1L16.5 9" stroke-linecap="round" stroke="#D9D9D9" />
        </svg>
      )}
      {isOpened && (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11" viewBox="0 0 19 11" fill="none">
          <path d="M1.5 1.5L9.5 9.5L17.5 1.5" stroke="#D9D9D9" stroke-width="0.730769" stroke-linecap="round" />
        </svg>
      )}
    </>
  )
}
