import { FORM_SOURCE } from '../../constants'

const captchaSettings = {
  visible: false,
  captchaSrc: '',
  ticketId: '',
}

const smsCodeSettings = {
  visible: false,
  timeToResend: '',
  ticketId: '',
}

export const FORM_INIT_STATE = {
  values: {
    name: '',
    lastName: '',
    secondName: '',
    email: '',
    phone: '',
    promoCode: '',
    captcha: '',
    smsCode: '',
    agreement: false,
  },
  agreements: {
    communication: {
      accepted: false,
      phone: false,
      sms: false,
      email: false,
      post: false,
    },
  },
  phoneSelection: {
    country: '',
    phoneRawValue: '',
  },
  defaultCountryCode: '',
  possibleCountryCodes: [],
  errors: [],
  scroll: false,

  captchaSettings: {}, // объект с ключом тип формы FORM_SOURCE + credit и значением captchaSettings
  smsCodeSettings: {}, // объект с ключом тип формы FORM_SOURCE + credit и значением smsCodeSettings
  captchaError: {}, // объект с ключом тип формы FORM_SOURCE + credit и значением true/false
  smsCodeError: {}, // объект с ключом тип формы FORM_SOURCE + credit и значением true/false
}

const form = (state = FORM_INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUE':
      // eslint-disable-next-line
      let formattedValue = action.value

      if (typeof action.value == 'string') {
        formattedValue = formattedValue.trim()
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.key]: formattedValue,
        },
      }

    case 'SET_COMMUNICATION_PROCESSING':
      return {
        ...state,
        agreements: {
          ...state.agreements,
          communication: {
            ...state.agreements.communication,
            [action.key]: action.value,
          },
        },
      }

    case 'STASH_ERRORS':
      return {
        ...state,
        errors: [],
      }

    case 'SET_SCROLL':
      return {
        ...state,
        scroll: action.payload,
      }

    case 'PUSH_ERROR': {
      const errors = JSON.parse(JSON.stringify(state.errors))
      errors.push(action.field)

      return {
        ...state,
        errors,
      }
    }

    case 'SET_DEFAULT_COUNTRY_CODE':
      return {
        ...state,
        phoneSelection: {
          ...state.phoneSelection,
          country: state.phoneSelection.country || action.payload,
        },
      }

    case 'SET_PHONE_COUNTRY':
      return {
        ...state,
        phoneSelection: {
          ...state.phoneSelection,
          country: action.payload,
        },
      }

    case 'SET_PHONE_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          phone: action.payload.phone,
        },
        phoneSelection: {
          ...state.phoneSelection,
          phoneRawValue: action.payload.phoneRawValue,
        },
      }

    case 'SET_DEFAULT_COUNTRY_CODES_DATA':
      return {
        ...state,
        defaultCountryCode: action.defaultCountryCode,
        possibleCountryCodes: action.possibleCountryCodes,
      }

    case 'SET_CAPTCHA_SETTINGS':
      return {
        ...state,
        captchaSettings: {
          ...state.captchaSettings,
          [action.key]: { ...action.value },
        },
      }

    case 'SET_SMS_CODE_SETTINGS':
      return {
        ...state,
        smsCodeSettings: {
          ...state.smsCodeSettings,
          [action.key]: { ...action.value },
        },
      }

    case 'CLEAR_SMS_CODE_TIME':
      return {
        ...state,
        smsCodeSettings: {
          ...state.smsCodeSettings,
          [action.key]: {
            ...state.smsCodeSettings[action.key],
            visible: true,
            timeToResend: 0,
          },
        },
      }

    case 'SET_CAPTCHA_ERROR':
      return {
        ...state,
        captchaError: {
          ...state.captchaError,
          [action.key]: action.value,
        },
      }

    case 'SET_SMS_CODE_ERROR':
      return {
        ...state,
        smsCodeError: {
          ...state.smsCodeError,
          [action.key]: action.value,
        },
      }
    default:
      return state
  }
}

export default form
