import { useTranslation } from 'react-i18next'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './paymentVariant.module.scss'

function PaymentVariant({ isActive, variant, isWhite }) {
  const { t } = useTranslation()

  const activeClass = () => {
    if (!isActive) {
      return ''
    }
    return isWhite ? s.borderedActive : s.active
  }

  const disabledHint = () => {
    let msg

    if (variant.isDisabled) {
      msg = t(`onlinePayment.fullPayment.disabledTexts.beginning`)

      let thingsToTurnOff = []

      if (variant.isTradeInEnabledByUser) {
        thingsToTurnOff.push(t(`onlinePayment.fullPayment.disabledTexts.parts.tradeIn`))
      }

      if (variant.isCreditEnabledByUser) {
        thingsToTurnOff.push(t(`onlinePayment.fullPayment.disabledTexts.parts.credit`))
      }

      if (variant.isLeasingEnabledByUser) {
        thingsToTurnOff.push(t(`onlinePayment.fullPayment.disabledTexts.parts.leasing`))
      }

      if (thingsToTurnOff.length) {
        const and = ` ${t('onlinePayment.fullPayment.disabledTexts.and')} `
        return `${msg} ${t(`onlinePayment.fullPayment.disabledTexts.turnOff`)} ${thingsToTurnOff.join(and)}`
      }

      return msg
    }
  }

  return (
    <div
      className={[s.wrapper, activeClass(), variant.isDisabled ? s.disabled : ''].join(' ')}
      data-project-name={getProjectName()}
    >
      <div className={s.wrapper__row}>
        <div className={s.row}>
          <div className={s.row__col}>
            <div className={s.title}>{variant.title}</div>
          </div>
          <div className={s.row__col}>
            <div className={s.payment}>{variant.formattedPrice}</div>
          </div>
        </div>
      </div>
      <div className={s.wrapper__row}>
        <div className={s.description}>{variant.description}</div>
      </div>
      {variant.isDisabled && (
        <div className={s.wrapper__row}>
          <div className={s.descriptionDisabled}>{disabledHint()}</div>
        </div>
      )}
    </div>
  )
}

export default PaymentVariant
