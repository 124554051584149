import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { FORM_SOURCE } from '../../../constants'
import DealershipsList from '../DealershipsList/DealershipList'
import Dropdown from '../FormNew/dropdown/Dropdown'

function DealershipsDropdown({
  dealerships,
  activeDealership,
  areCarsReal,
  config,
  selectActiveDealership,
  errors,
  errorsText,
}) {
  const { t } = useTranslation()

  const dealershipsText = () => {
    if (activeDealership.dealershipName) {
      return activeDealership.dealershipName.length < 45
        ? activeDealership.dealershipName
        : `${activeDealership.dealershipName.substr(0, 44)}...`
    }

    if (activeDealership.clientName) {
      return activeDealership.clientName.length < 45
        ? activeDealership.clientName
        : `${activeDealership.clientName.substr(0, 44)}...`
    }

    return ''
  }

  return (
    <>
      <Dropdown
        id={`${config.source}Dealerships`}
        name={`${config.source}Dealerships`}
        label={t('dealerships.choose')}
        value={dealershipsText()}
        variants={
          <DealershipsList
            dealerships={dealerships}
            activeDealership={activeDealership}
            areCarsReal={areCarsReal}
            handleClick={d => selectActiveDealership(d)}
            useWidgetStyles={config.source === FORM_SOURCE.WIDGET}
          />
        }
        required={true}
        errors={errors}
        errorsText={errorsText}
        useWidgetStyles={config.source === FORM_SOURCE.WIDGET}
      />
    </>
  )
}

function mapStateToProps(state, { config }) {
  let areCarsReal, dealerships, activeDealership, isWidgetLoadingFailed

  if ([FORM_SOURCE.CAR_ORDER, FORM_SOURCE.WIDGET].includes(config.source)) {
    const {
      settings: {
        dealerships: coDealerships,
        activeDealership: coActiveDealership,
        isWidgetLoadingFailed: coIsWidgetLoadingFailed,
      },
    } = state

    dealerships = coDealerships
    activeDealership = coActiveDealership
    isWidgetLoadingFailed = coIsWidgetLoadingFailed
    areCarsReal = true
  } else if (config.source === FORM_SOURCE.BUY_MODEL) {
    const {
      buyModelStandalone: {
        dealerships: bmDealerships,
        activeDealership: bmActiveDealership,
        isWidgetLoadingFailed: bmIsWidgetLoadingFailed,
      },
    } = state

    dealerships = bmDealerships
    activeDealership = bmActiveDealership
    isWidgetLoadingFailed = bmIsWidgetLoadingFailed
    areCarsReal = false
  } else if (config.source === FORM_SOURCE.TRADE_IN_STANDALONE) {
    const {
      tradeInStandalone: {
        dealerships: tiDealerships,
        activeDealership: tiActiveDealership,
        // isWidgetLoadingFailed: bmIsWidgetLoadingFailed,
      },
    } = state

    dealerships = tiDealerships
    activeDealership = tiActiveDealership
    isWidgetLoadingFailed = false
    areCarsReal = false
  }

  dealerships.sort((a, b) => {
    const first = a.type === 'dealership' ? a.dealershipName : a.clientName
    const second = b.type === 'dealership' ? b.dealershipName : b.clientName

    if (first === second) return 0

    if (first < second) return -1
    if (second > first) return 1

    return 0
  })

  return {
    areCarsReal,
    dealerships,
    activeDealership,
    isWidgetLoadingFailed,
  }
}

export default connect(mapStateToProps)(DealershipsDropdown)
