module.exports = {
  choose: 'Um fortzufahren, bitte Händler auswählen',
  available: 'Verfügbar',
  booked: 'Choose an analog', // !!!
  errorMessage:
    'Leider ist beim Abrufen der Informationen von Ihrem ausgewählten Händler ein Fehler aufgetreten. Bitte versuchen Sie es mit einem anderen Händler oder nutzen Sie das Widget später',
  selected: 'Selected', // !!!
  yourDealership: 'Ihr Händler',
  unavailableHint: {
    auto: 'This car is {carStatus}.<br />You can save the deal and manager will pick up a similar car for you.', // !!!
    moto: 'This bike is {carStatus}.<br />You can save the deal and manager will pick up a similar bike for you.', // !!!
    other:
      'This vehicle is {carStatus}.<br />You can save the deal and manager will pick up a similar vehicle for you.', // !!!
  },
}
