// сумма лизинга =  стоимость авто - все выгоды - выгода за лизинг
// но считаем со знаком + тк числа отрицательные

export const leasingAmount = (price, selectedBenefits, selectedAccessories, leasingBenefitAmount) => {
  let benefitsAmount = 0
  selectedBenefits.forEach(b => {
    benefitsAmount += b.attributes.value
  })

  if (selectedAccessories) {
    selectedAccessories.forEach(b => {
      benefitsAmount += b.attributes.price
    })
  }

  if (leasingBenefitAmount < 0) {
    benefitsAmount += leasingBenefitAmount
  }

  return price + benefitsAmount
}
