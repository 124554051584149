export const setFormValue = (key, value) => ({
  type: 'SET_FORM_VALUE',
  key,
  value,
})

export const setCommunicationProcessing = (key, value) => ({
  type: 'SET_COMMUNICATION_PROCESSING',
  key,
  value,
})

export const setCaptchaSettings = (key, value) => ({
  type: 'SET_CAPTCHA_SETTINGS',
  key,
  value,
})

export const setSmsCodeSettings = (key, value) => ({
  type: 'SET_SMS_CODE_SETTINGS',
  key,
  value,
})

export const clearSmsCodeTime = key => ({
  type: 'CLEAR_SMS_CODE_TIME',
  key,
})

export const setCaptchaError = (key, value) => ({
  type: 'SET_CAPTCHA_ERROR',
  key,
  value,
})

export const setSmsCodeError = (key, value) => ({
  type: 'SET_SMS_CODE_ERROR',
  key,
  value,
})

export const pushFormError = field => ({
  type: 'PUSH_ERROR',
  field,
})

export const stashFormErrors = () => ({
  type: 'STASH_ERRORS',
})

export const setScroll = payload => ({
  type: 'SET_SCROLL',
  payload,
})

export const setPhoneCountry = payload => ({
  type: 'SET_PHONE_COUNTRY',
  payload,
})

export const setPhoneValue = payload => (dispatch, getState) => {
  dispatch({
    type: 'SET_PHONE_VALUE',
    payload,
  })

  if (payload?.phoneRawValue?.length > 7 || payload?.phoneRawValue?.length === 0) {
    // если ошибся номером, то надо чистить поле кода из СМС
    const {
      form: { smsCodeSettings },
    } = getState()

    if (smsCodeSettings && Object.keys(smsCodeSettings).length) {
      for (let s in smsCodeSettings) {
        if (smsCodeSettings.hasOwnProperty(s)) {
          if (smsCodeSettings[s].visible) {
            dispatch(setSmsCodeSettings(s, { visible: false, timeToResend: 0, ticketId: '' }))
          }
        }
      }
    }
  }
}

export const setDefaultCountryCodesData = ({ defaultCountryCode, possibleCountryCodes }) => dispatch => {
  dispatch({
    type: 'SET_DEFAULT_COUNTRY_CODES_DATA',
    defaultCountryCode,
    possibleCountryCodes,
  })
}
