export const localeEn = {
  car: require('./car'),
  trade_in: require('./trade_in'),
  bullet: require('./bullet'),

  tradeInS: require('./trade_in_standalone'),
  ccS: require('./credit_standalone'),
  leasing: require('./leasing'),
  dealerships: require('./dealerships'),
  accessories: require('./accessories'),

  carOrderForm: require('./carOrderForm'),

  calculatePurchase: 'Calculate purchase',

  loading: 'Loading ...',
  generalError: 'Unfortunately, an error has been occurred while loading',
  free: 'Free',
  edit: 'Edit',
  from: 'from',
  months: 'mo.',
  terms: 'Terms',
  benefits: {
    title: 'Additional benefits',
    additionalOptions: 'Additional equipment',
    additionalOptionsWithoutList: 'The cost of the installed additional equipment',
  },

  auth: {
    enterPersonalDataBeforeContinue: 'Please fill the data to save conditions of the deal',
    continue: 'Continue',
  },

  benefitsList: {
    saveTermsUntil48Hours: 'Saving terms for 48 hours',
    personalManager: 'Personal manager',
    smsAndStatus: {
      auto: 'SMS-link to this car and tracking its status',
      moto: 'SMS-link to this motorcycle and tracking its status',
      other: 'SMS-link to this vehicle and tracking its status',
    },
    saveTermsUntilTneEnd: 'Saving terms until the end of the deal',
    carBooking: {
      auto: '100% car booking',
      moto: '100% motorcycle booking',
      other: '100% vehicle booking',
    },
    returnOnlinePaymentEasily: 'Easy online prepayment refund',
  },

  credit: {
    ...require('./credit'),
    creditForAmount: 'A credit of { amount }',
    creditWithBenefit: 'Your credit benefit of { value } has already been taken into account',
    creditWithTradeInDescription: {
      auto: 'The cost of your car in Trade-in has already been included into initial payment',
      moto: 'The cost of your motorcycle in Trade-in has already been included into initial payment',
      other: 'The cost of your vehicle in Trade-in has already been included into initial payment',
    },
    initialFee: 'Initial fee',
    buybackPayment: 'Residual payment',
    buybackPaymentHint:
      'The residual payment is the amount as a percentage of the value of the vehicle, the payment of which is carried over to the end of the loan term',
    creditPeriod: 'Credit duration',

    enabledWithoutCalculations: {
      screenHeader: 'You can choose the best credit parameters for you',
      screenText:
        'Unfortunately, the automatic calculation of credit programs is disabled, but the data you entered will be transferred to the manager to form a credit offer',
    },

    periods: {
      oneYear: '1 year',
      twoYears: '2 years',
      threeYears: '3 years',
      fourYears: '4 years',
      fiveYears: '5 years',
      sixYears: '6 years',
      sevenYears: '7 years',
      eightYears: '8 years',
    },

    additionalOptions: 'Additional options',
    lifeInsurance: 'All payments are protected',
    withoutKasko: 'Credit without CASCO',

    searchDisabledHeader: 'Automatic credit programs search disabled',
    searchDisabledContent:
      'You can save your credit parameters and manager will pick up credit programs for you manually.',

    searchNoProgramsHeader: 'Unfortunately, no credit programs were found',
    searchNoProgramsContent: 'Please, select other credit parameters',

    searchFailedHeader: 'Unfortunately, an error occurs during credit programs search',
    searchFailedContent: 'Please, select other credit parameters or try again in several minutes',

    saveParameters: 'Save',

    chooseCreditProgram: 'Choose credit program ({ count } were found)',
    pricePerMonth: '{ value }/mo.',
    choose: 'Choose',
    onlineApproval: 'Online approval',
    onlineApprovalHint:
      'First, select the deposit for the loan,<br/>the loan term and the program of the bank<br/>that you are interested in',
    redemptionPrice: 'Redemption price',
    moreAboutProgram: 'More about the program',
  },

  error: {
    description: 'Whoops! An error occurred while saving conditions of the deal',
    retry: 'Try again',
    limit: "Sorry, that's enough for today, let's try again tomorrow",
  },

  success: {
    description:
      'Thank you! Our manager will contact you shortly to clarify the terms of the deal. We also saved your calculation and sent a link to your phone. Using this link, you can always return to this deal, make changes or see the changes made by your manager.',
    descriptionWithoutLink: 'Thank you! Our manager will contact you shortly to clarify the terms of the deal.',
    goToSmartContract: 'Go to your saved conditions',
    backToSite: 'Return back on site',
  },

  paymentStart: {
    description:
      'Thank you! Our manager will contact you shortly to clarify the terms of the deal. We also saved your calculation and sent a link to your phone. Using this link, you can always return to this deal, make changes or see the changes made by your manager.',
    redirectDescription:
      'Через {count, plural, one {# секунду} few {# секунды} other {# секунд}} вы будете перенаправлены на страницу онлайн оплаты.', //Исправить на английский
    redirectDescriptionFail: 'If the redirect did not happen, click on the button.',
    goToOnlinePayment: 'Go to online payment',
  },

  paymentFailed: {
    description: 'Whoops! An error occurred while generating the payment link. Follow the link below to try again.',
    goToSmartContract: 'Continue making an online deal',
  },

  onlinePayment: {
    whatHappensIfMyOpinionChanged: 'Refund issued within 5 days after payment takes 2 business days to proceed',
    benefitText:
      'Also, you will receive a benefit of <span class="kdxsc-widget-state-payment-benefit__highlight">{ value }</span>, if you pay online',
    goToOnlinePaymentButtonText: 'Online prepayment { value }',
    chooseOnlinePaymentType: 'Choose online prepayment type',

    formTitleWithFillPayment: {
      auto: 'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a car.',
      moto: 'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a motorcycle.',
      other:
        'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a vehicle.',
    },
    formTitleWithAdvancePayment: 'Please fill out all the fields of the form, this data is needed for online payment.',
    fullPaymentTitle: 'Booking or buying?',
    fullPayment: {
      partial: {
        title: 'Advance Payment',
        description: {
          auto: 'Online payment for car booking with an advance payment agreement',
          moto: 'Online payment for motorcycle booking with an advance payment agreement',
          other: 'Online payment for vehicle booking with an advance payment agreement',
        },
      },
      full: {
        title: 'Full payment',
        description: {
          auto: 'Online payment of the full cost of the car with a purchase and sale agreement',
          moto: 'Online payment of the full cost of the motorcycle with a purchase and sale agreement',
          other: 'Online payment of the full cost of the vehicle with a purchase and sale agreement',
        },
      },
      disabledTexts: {
        beginning: 'This payment option is possible only if you pay in full in cash.',
        parts: {
          tradeIn: 'Trade-in',
          credit: 'Credit',
          leasing: 'Leasing',
        },
        turnOff: 'Turn off',
        and: 'and',
      },
    },
  },

  header: {
    mainTitle: 'Configuration of the deal',
    tradeInTitle: 'Rate your vehicle',
    onlinePaymentTitle: 'Online prepayment',
    creditTitle: 'Calculate the credit terms',
    authTitle: 'Authorization',
    leasingTitle: 'Leasing',
    promoProgram: 'Promotion',
  },

  footer: {
    createSmartContractButtonText: 'Save',
    goToOnlinePaymentButtonText: 'Online prepayment { value }',
    madeBy: 'Made by<br/>UDP Auto',
  },

  form: {
    yourName: 'First name',
    yourLastName: 'Last name',
    yourSecondName: 'Patronymic',
    phoneNumber: 'Phone',
    email: 'Email',
    promoCode: 'Promo code',
    defaultAgreementText:
      'By clicking on the button, you consent to the processing of your personal data and further communication.',
    captcha: 'Captcha',
    smsCode: 'SMS code',
    captchaHint: 'Type numbers from the image and click on the save button',
    smsCodeHint: 'Type code from SMS and click save button',
    resendCaptcha: 'Reload image',
    resendSmsCode: 'Resend code',
    captchaError: 'You have typed wrong captcha',
    smsCodeError: 'You have typed wrong SMS code',
    sendIn: 'Send in',
  },

  personalData: {
    fullName: 'Name',
    phone: 'Phone',
    yourPersonalData: 'Your personal data',
    storeDescription:
      'To save the selected configuration of the deal, fill in the data for the subsequent entry into your personal account.',
    phoneDescription: 'The phone will be used as a login and a password will be sent to your phone number.',
  },

  tooltip: {
    defaultTitle: 'Calculate the exact terms of purchase and get a personal offer',
    tradeInWithCredit: 'Choose Trade-in and credit terms',
    tradeInWithoutCredit: 'Choose Trade-in terms',
    creditWithoutTradeIn: 'Choose credit terms',
    benefits: 'Choose benefits and additional services',
    onlinePayment: {
      auto: 'Make an online prepayment and book your car',
      moto: 'Make an online prepayment and book your motorcycle',
      other: 'Make an online prepayment and book your vehicle',
    },
    closeButtonText: 'OK',
    watchVideo: 'Watch video',
  },

  summary: {
    ...require('./summary'),
    onlinePaymentBenefit: 'Online payment benefit',
    yourBenefit: 'Your benefit',
    selectedServices: 'Selected services',
    selectedAccessories: 'Selected accessories',
    selectedGifts: 'Selected gifts',
    tradeInPriceAmount: {
      auto: 'The cost of your car in Trade-in',
      moto: 'The cost of your motorcycle in Trade-in',
      other: 'The cost of your vehicle in Trade-in',
    },
    tradeInBenefit: 'Trade-in benefit',
    tradeInBenefitWithoutTradeIn: 'Benefit without Trade-in',
    tradeInBenefitWithoutTradeInHint: 'Your benefit when buying without Trade-In',
    creditBenefit: 'Credit purchase benefit',
    leasingBenefit: 'Leasing purchase benefit',
    kasko: 'Kasko insurance benefit',
    carPrice: {
      auto: 'Initial car price',
      moto: 'Initial motorcycle price',
      other: 'Initial vehicle price',
    },
    discounts: 'Benefits',
    services: 'Services',
    initialFeeAmount: 'Initial fee',
    initialFeeAmountCredit: 'Initial fee',
    needToPay: 'Total payment',
    needToPayIfCredit: 'Total initial payment',
    needToPayIfLeasing: 'Total advance payment',
    needToPayZero: 'Your payment',
    dealerNeedToPay: 'Dealer surcharge',
    byCredit: 'In credit',
    byLeasing: 'In leasing',
    monthlyPayment: 'Monthly payment',
    carPriceWithBenefits: {
      auto: 'Car price',
      moto: 'Motorcycle price',
      other: 'Vehicle price',
    },
  },

  tradeIn: {
    notSelected: 'Not selected',
    rate: 'Evaluate',
    fillAllFields: 'Fill in all the fields',
    apply: 'Apply',
    choose: 'Choose',
    rateResult: 'Evaluation result',
    rateResultFailed:
      'Unfortunately, we were not able to automatically calculate the cost of your car in the Trade-in. Use a different calculation method or save the conditions so that the manager makes an estimate based on your data.',
    rateResultZeroPrice:
      'Unfortunately, we were not able to automatically calculate the cost of your car in the Trade-in. Use a different calculation method or save the conditions so that the manager makes an estimate based on your data.',
    rateResultSuccessDescription: 'The rating system is based on vehicles sold in the last year on auto.ru',
    rateResultSuccessDescriptionWithoutRF: 'The rating system is based on vehicles sold in the last year',
    rateResultPriceFromTo: 'from { from } to { to }',
    rateResultSuccessDescription2: 'The calculation is for informational purposes only.',
    rateResultSuccessDescription2WithoutRF: 'The calculation is for informational purposes only.',

    ownerCarPriceText: {
      auto: 'Disagree with the estimate? <br> You can specify the desired price of your car',
      moto: 'Disagree with the estimate? <br> You can specify the desired price of your motorcycle',
      other: 'Disagree with the estimate? <br> You can specify the desired price of your vehicle',
    },

    body: {
      title: 'Body',
    },

    brand: {
      title: 'Brand',
      notSelected: 'Not selected',
    },

    city: {
      title: 'City',
      noData: 'No data',
      loading: 'Loading',
    },

    color: {
      title: 'Color',

      names: {
        white: 'White',
        silver: 'Silver',
        beige: 'Beige',
        yellow: 'Yellow',
        golden: 'Gold',
        orange: 'Orange',
        pink: 'Pink',
        red: 'Red',
        purple: 'Purple',
        brown: 'Brown',
        lightBlue: 'Lightblue',
        blue: 'Darkblue',
        violet: 'Violet',
        green: 'Green',
        gray: 'Grey',
        black: 'Black',
      },
    },

    generation: {
      title: 'Generation',
      notSelected: 'Not selected',
    },

    mileage: {
      title: 'Mileage',
      empty: 'Not selected',
    },

    identity: {
      title: 'VIN or State Number',
      empty: 'Not selected',
    },

    model: {
      title: 'Model',
      notSelected: 'Not selected',
    },

    modification: {
      title: 'Modification',
      notSelected: 'Not selected',
    },

    engine: {
      title: 'Engine',
      notSelected: 'Not selected',
    },

    gearbox: {
      title: 'Transmission',
      notSelected: 'Not selected',
    },

    interiorType: {
      title: 'Interior type',
      notSelected: 'Not selected',
    },

    interiorColor: {
      title: 'Interior color',
      notSelected: 'Not selected',
    },

    owner: {
      title: 'Owner',

      names: {
        first: 'First',
        second: 'Second',
        thirdAndMore: 'Third and more',
      },
    },

    year: {
      title: 'Year',
      notSelected: 'Not selected',
    },

    helpers: {
      showAll: 'Show all',
      showPopular: 'Show popular',
    },

    steps: {
      elevenStepsRemain: '11 steps left before evaluation',
      tenStepsRemain: '10 steps left before evaluation',
      nineStepsRemain: '9 steps left before evaluation',
      eightStepsRemain: '8 steps left before evaluation',
      sevenStepsRemain: '7 steps left before evaluation',
      sixStepsRemain: '6 steps left before evaluation',
      fiveStepsRemain: '5 steps left before evaluation',
      fourStepsRemain: '4 steps left before evaluation',
      threeStepsRemain: '3 steps left before evaluation',
      goToCity: 'Choose city',
      twoStepsRemain: '2 steps left before evaluation',
      homeStretch: 'Home stretch',
    },

    vinOrGrzErrorText: 'Field "VIN or State Number" is filled incorrectly',
  },

  car_statuses: {
    wait: {
      list_text: 'Booked',
      detail_text: {
        auto: 'The car is booked',
        moto: 'The motorcycle is booked',
        other: 'The vehicle is booked',
      },
    },
    reserved: {
      list_text: 'Booked',
      detail_text: {
        auto: 'The car is booked',
        moto: 'The motorcycle is booked',
        other: 'The vehicle is booked',
      },
    },
    sold: {
      list_text: 'Sold',
      detail_text: {
        auto: 'The car is sold',
        moto: 'The motorcycle is sold',
        other: 'The vehicle is sold',
      },
    },
    available: {
      list_text: 'Get your benefit',
      detail_text: {
        auto: 'Book online',
        moto: 'Book online',
        other: 'Book online',
      },
    },
  },
}
