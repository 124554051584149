const INIT_STATE = {
  enabled: false,
  amount: 0,
  benefitAmount: 0,
  enableFullPayment: false,
  variants: {},
  activePayment: {}, // partial or ull
}

const onlinePayment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_ONLINE_PAYMENT':
      return {
        ...state,
        amount: action.onlinePayment.amount,
        benefitAmount: action.onlinePayment.benefitAmount,
        enableFullPayment: action.onlinePayment.enableFullPayment,
        enabled: action.onlinePayment.enabled,
        status: action.onlinePayment.status,
        variants: action.onlinePayment.variants,
      }

    case 'SET_ONLINE_PAYMENT_ACTIVE_VARIANT':
      return {
        ...state,
        activePayment: action.payload,
      }

    case 'SET_ONLINE_PAYMENT_NEW_FULL_PAYMENT':
      if (state?.activePayment?.id === 'full') {
        return {
          ...state,
          variants: {
            ...state.variants,
            full: action.price,
          },
          activePayment: {
            ...state.activePayment,
            price: action.price,
            formattedPrice: action.formattedPrice,
          },
        }
      } else {
        return {
          ...state,
          variants: {
            ...state.variants,
            full: action.price,
          },
        }
      }

    default:
      return state
  }
}

export default onlinePayment
