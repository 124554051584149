import { TRADE_IN_STANDALONE_TYPES } from '../types'

export const setTradeInStandaloneSetDealerships = dealerships => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_DEALERSHIPS,
    payload: dealerships,
  })
}

export const setTradeInStandaloneActiveDealership = dealership => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_ACTIVE_DEALERSHIP,
    payload: dealership,
  })
}

export const setTradeInStandaloneIsWidgetLoaded = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADED,
    payload,
  })
}

export const setTradeInStandaloneIsWidgetLoadingFailed = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADING_FAILED,
    payload,
  })
}

export const setTradeInStandaloneLocale = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_LOCALE,
    payload,
  })
}

export const setTradeInPriceTooltipEnabled = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_PRICE_TOOLTIP_ENABLED,
    payload,
  })
}

export const setTradeInVisual = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_VISUAL,
    payload,
  })
}

export const setTradeInStandaloneDefaultCities = payload => dispatch => {
  dispatch({
    type: TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_DEFAULT_CITIES,
    payload,
  })
}
