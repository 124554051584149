import './_theme_generic_v2.scss'

import { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  CREDIT_SETTINGS_BEHAVIOR,
  FORM_SOURCE,
  FORM_TYPE_BASE,
  LEASING_SETTINGS_BEHAVIOR,
  TRADE_IN_SETTINGS_BEHAVIOR,
} from '../../../constants'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  isSelectedDealershipIsNotActive,
  setActiveDealershipFromMainStore,
} from '../../../helpers/form/activeDealershipSetter'
import DealershipCarIcon from '../../../svgComponents/dealershipCar'
import Accessories from '../../Parts/Accessories/State'
import Benefits from '../../Parts/Benefits/Benefits'
import CreditState from '../../Parts/Credit/State'
import DealershipsDropdown from '../../Parts/DealershipsDropdown/DealershipsDropdown'
import Form from '../../Parts/FormNew/Form'
import PromoProgram from '../../Parts/PromoProgram/PromoProgram'
import Summary from '../../Parts/Summary/Summary'
import TradeInState from '../../Parts/TradeIn/State'
import Advantages from '../../Ui/advantages'
import s from './index.module.scss'

function Index({
  tradeInEnabledBySettings,
  financeEnabledBySettings,
  showBenefitsSection,
  panelTop,
  activeDealership,
  isWidgetLoaded,
  isWidgetLoadingFailed,
  dealerships,
  config,
  showAdvantages,
  showAccessoriesSection,
  promoProgram,
  carStatus,
  vehicleType,
  creditBehaviorBySettings,
  isPriceHidden,
}) {
  const panelContent = createRef()

  const { t } = useTranslation()

  const isBlurred = () => {
    if (dealerships.length < 2) {
      return false
    } else {
      return !(activeDealership.widgetId && isWidgetLoaded)
    }
  }

  const selectActiveDealership = d => {
    let condition = isSelectedDealershipIsNotActive(activeDealership, d)

    if (condition) {
      setActiveDealershipFromMainStore(d, config)
    }
  }

  const translatedCarStatus = t(`car_statuses.${carStatus}.list_text`).toLowerCase()

  return (
    <div
      className={[s.panel, isBlurred() ? s.panel_blurred : ''].join(' ')}
      ref={panelContent}
      data-project-name={getProjectName()}
    >
      <div className={s.panel__item}>
        {dealerships.length > 1 && (
          <div className={[s.panel__section, s.panel__calculator].join(' ')}>
            <div className={s.dealerships}>
              <div className={s.dealerships__icon}>
                <DealershipCarIcon />
              </div>
              <div className={s.dealerships__list}>
                <DealershipsDropdown
                  config={{ ...config, source: FORM_SOURCE.WIDGET }}
                  selectActiveDealership={selectActiveDealership}
                  errors={isWidgetLoadingFailed}
                  errorsText={isWidgetLoadingFailed ? t('dealerships.errorMessage') : ''}
                />
              </div>
            </div>
          </div>
        )}

        {['reserved', 'sold', 'wait'].includes(carStatus) && (
          <div
            className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}
            dangerouslySetInnerHTML={{
              __html: t(`dealerships.unavailableHint.${vehicleType}`, { carStatus: translatedCarStatus }),
            }}
          />
        )}

        {promoProgram?.enabled && creditBehaviorBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED && !isPriceHidden && (
          <div className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}>
            <PromoProgram />
          </div>
        )}

        {tradeInEnabledBySettings && (
          <div className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}>
            <TradeInState />
          </div>
        )}
        {financeEnabledBySettings && !isPriceHidden && (
          <div className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}>
            <CreditState />
          </div>
        )}
        {showBenefitsSection && !isPriceHidden && (
          <div className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}>
            <Benefits />
          </div>
        )}
        {showAccessoriesSection && (
          <div className={[s.panel__section, s.panel__calculator, isBlurred() ? s.blurred : ''].join(' ')}>
            <Accessories />
          </div>
        )}
        {!isPriceHidden && (
          <div className={[s.panel__section, s.panel__summary, isBlurred() ? s.blurred : ''].join(' ')}>
            <Summary />
          </div>
        )}
      </div>

      <div className={s.panel__item}>
        <div className={[s.panel__section, s.panel__form, isBlurred() ? s.blurred : ''].join(' ')}>
          <Form
            key={'widgetForm'}
            type={FORM_TYPE_BASE}
            config={{ ...config, source: FORM_SOURCE.WIDGET }}
            panelContent={panelContent}
            panelTop={panelTop}
          />
        </div>
        {showAdvantages && !isPriceHidden && (
          <div className={[s.panel__section, s.panel__advantages, isBlurred() ? s.blurred : ''].join(' ')}>
            <Advantages />
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  benefits: { benefits },
  accessories: { accessories },
  car: { additionalOptions },
  tradeIn: { behaviour: tradeInBehaviorBySettings },
  credit: { behaviour: creditBehaviorBySettings, promoProgram },
  leasing: { behaviour: leasingBehaviorBySettings },
  onlinePayment: { status: carStatus },
  settings: {
    activeDealership,
    isWidgetLoaded,
    isWidgetLoadingFailed,
    dealerships,
    priceToolTipEnabled,
    vehicleType,
    visual: {
      isPriceHidden,
      advantages: { hideUsual, hideOnlinePayment },
    },
  },
}) {
  const tradeInEnabledBySettings = () => {
    return [TRADE_IN_SETTINGS_BEHAVIOR.ENABLED, TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS].includes(
      tradeInBehaviorBySettings,
    )
  }

  const financeEnabledBySettings = () => {
    const creditCondition = [
      CREDIT_SETTINGS_BEHAVIOR.ENABLED,
      CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
    ].includes(creditBehaviorBySettings)

    const leasingCondition = [
      LEASING_SETTINGS_BEHAVIOR.ENABLED,
      LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
    ].includes(leasingBehaviorBySettings)

    return creditCondition || leasingCondition
  }

  const showBenefitsSection = () => {
    return Boolean(benefits?.length > 0 || additionalOptions?.cost > 0 || additionalOptions?.list?.length > 0)
  }

  return {
    tradeInEnabledBySettings: tradeInEnabledBySettings(),
    financeEnabledBySettings: financeEnabledBySettings(),
    showBenefitsSection: showBenefitsSection(),
    creditBehaviorBySettings,
    showAccessoriesSection: Boolean(accessories?.length),
    showAdvantages: priceToolTipEnabled || !hideUsual || !hideOnlinePayment,
    activeDealership,
    isWidgetLoaded,
    isWidgetLoadingFailed,
    dealerships,
    promoProgram,
    carStatus,
    vehicleType,
    isPriceHidden,
  }
}

export default connect(mapStateToProps)(Index)
