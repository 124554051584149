export const setClientModels = payload => dispatch => {
  dispatch({
    type: 'SET_CLIENT_MODELS',
    payload,
  })
}

export const setActiveClientModel = payload => dispatch => {
  dispatch({
    type: 'SET_ACTIVE_CLIENT_MODEL',
    payload,
  })
}
