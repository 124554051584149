import { useState } from 'react'

import s                from './styles/common.module.scss'
import {getProjectName} from "../../../helpers/app/detectors/project_name";

export const Subtitle = ({ children }) => {
  return (
    <div className={s.subtitle_box} data-project-name={getProjectName()}>
      <h3 className={s.subtitle}>{children}</h3>
    </div>
  )
}

export const Button = ({ children, vertical }) => {
  return (
    <div className={s.button_box} data-vertical={vertical} data-project-name={getProjectName()}>
      <button className={s.button}>{children}</button>
    </div>
  )
}

export const Label = ({ labelText, id }) => {
  return (
    <label className={s.label} data-project-name={getProjectName()} htmlFor={id}>
      {labelText}
    </label>
  )
}

export const Tip = ({ tip }) => {
  const [show, setShow] = useState(false)

  if (tip && tip === '*') {
    return '*'
  }

  const showTip = () => setShow(true)
  const hideTip = () => setShow(false)

  const element = (
    <>
      <span className={s.tip} onMouseEnter={showTip} onMouseLeave={hideTip} />
      {show && <div className={s.tip_box}>{tip}</div>}
    </>
  )

  return tip ? element : null
}

export const Input = ({ labelText, id, tip, onChange, value, register, vertical }) => {
  return (
    <InputBox data-project-name={getProjectName()} labelText={labelText} id={id} tip={tip} vertical={vertical}>
      <input
        data-project-name={getProjectName()}
        className={s.input}
        id={id}
        name={id}
        onChange={e => onChange(e.target.value)}
        defaultValue={value}
        ref={register}
      />
    </InputBox>
  )
}

export const InputBox = ({ labelText, id, tip, children, vertical, isLeasing, wide, isOnlyPercent }) => {
  return (
    <div
      data-project-name={getProjectName()}
      className={s.input_box}
      data-vertical={vertical}
      data-leasing={isLeasing}
      data-wide={wide}
      data-only-percent={isOnlyPercent}
    >
      <div className={s.label_box} data-project-name={getProjectName()}>
        <Label labelText={labelText} id={id} />
        <Tip tip={tip} />
      </div>
      {children}
    </div>
  )
}

export const InputGroup = ({ children, vertical, options }) => {
  return (
    <div
      data-project-name={getProjectName()}
      className={s.input_group}
      data-vertical={vertical}
      data-leasing={options?.isLeasing}
      data-split={options?.splitCarSelect}
    >
      {children}
    </div>
  )
}
