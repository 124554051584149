import s from './blockState.module.scss'

function BlockState({ children, title, number, isActive }) {
  return (
    <div className={[s.block, isActive ? s.active : ''].join(' ')}>
      <div className={s.block__label} />
      <div className={s.block__number}>{number}</div>
      <div className={s.block__body}>
        <div className={s.block__title}>{title}</div>
        <div className={s.block__content}>{children}</div>
      </div>
    </div>
  )
}

export default BlockState
