module.exports = {
  state: {
    title: 'Inzahlungnahme',
    subtitleWithBenefit: 'Inzahlungnahme mit dem Vorteil',
    subtitleWithoutBenefit: 'Inzahlungnahme',
    initialAdditionalText: 'Bewerten Sie Ihr Fahrzeug Online',
    additionalTextWithCarData: 'Abzugebendes Fahrzeug { brand } { model }, { year }',
    autoEstimationIsImpossible: 'Automatische Bewertung ist nicht möglich.',
    withoutTradeIn: 'Ohne Inzahlungnahme',
    benefitHint: {
      auto: 'Dieser Vorteil gilt beim Kauf dieses Fahrzeugs bei einer Inzahlungnahme',
      moto: 'Dieser Vorteil gilt beim Kauf dieses Fahrzeugs bei einer Inzahlungnahme',
      other: 'Dieser Vorteil gilt beim Kauf dieses Fahrzeugs bei einer Inzahlungnahme',
    },
  },
  screen: {},
  estimations: {
    goToTradeInList: 'Evaluate by parameters from the catalog', // !!!
    reset: 'Your current score and entered data will be reset', // !!!
    goToTradeInByIdentity: 'Evaluate by VIN or State number', // !!!
  },
}
