import { BULLET_TYPE } from '../../Bullet/constants'

export const isPointVisible = (type, link, dataFromLocalStorage) => {
  const isLinkExists = link && link.length

  const isSavedSCExists = dataFromLocalStorage?.saved_sc?.url

  if (type === BULLET_TYPE.CARS) {
    let necessaryDataForOpeningWidget = false

    if (dataFromLocalStorage?.car) {
      const {
        car: { identity, price, modelData, productType },
      } = dataFromLocalStorage

      necessaryDataForOpeningWidget = identity && price && modelData && productType
    }

    return Boolean(isLinkExists || isSavedSCExists || necessaryDataForOpeningWidget)
  }

  return Boolean(isLinkExists || isSavedSCExists)
}
