export const totalPayment = data => {
  let payment = data.price

  const selectedAccessoriesAmount = () => {
    let res = 0
    if (data.selectedAccessories) {
      for (const accessory of data.selectedAccessories) {
        res += accessory.attributes.price
      }
    }
    return res
  }

  // const isCredit = data.creditEnabled && data.creditAllowed && data.creditResolutionEnabled && data.creditProgramId;
  const isTradeIn = data.tradeInEnabledByUser
  const isCredit = data.creditEnabledByUser
  const isLeasing = data.leasingEnabledByUser

  if (isCredit && !isTradeIn) {
    payment = data.creditParameters.initialFee
  } else if (!isCredit && isTradeIn) {
    for (const benefit of data.selected) {
      payment += benefit.attributes.value
    }
    payment += selectedAccessoriesAmount()
    payment -= Math.abs(data.possibleCarPrice)
    payment -= Math.abs(data.tradeInBenefitAmount)
  } else if (isCredit && isTradeIn) {
    payment = Math.abs(data.creditParameters.initialFee) - Math.abs(data.possibleCarPrice)
  } else if (isLeasing) {
    payment = data.leasingParameters.initialFee
  } else {
    for (const benefit of data.selected) {
      payment += benefit.attributes.value
    }
    payment += selectedAccessoriesAmount()
    if (data.tradeInDisabledByUser && data?.tradeInBenefitAmountWithoutTradeIn < 0) {
      payment += data.tradeInBenefitAmountWithoutTradeIn
    }
  }

  if (!isCredit && !isLeasing) {
    const final = payment

    for (const tax of data.taxes) {
      payment += final * (tax.percent / 100)
    }
  }

  let text = 'summary.needToPay'

  if (!isLeasing && !isCredit) {
    if (payment < 0) {
      text = 'summary.dealerNeedToPay'
    } else if (payment === 0) {
      text = 'summary.needToPayZero'
    } else {
      text = 'summary.needToPay' // итого к оплате
    }
  }

  if (isCredit) {
    text = 'summary.needToPayIfCredit'
  }

  if (isLeasing) {
    text = 'summary.needToPayIfLeasing'
  }

  return [payment, text]
}
