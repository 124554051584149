import tinycolor from 'tinycolor2'

export const paletteSetter = palette => {
  for (const key in palette) {
    if (palette.hasOwnProperty(key)) {
      const colorObj = tinycolor(palette[key]).toRgb()
      const color = `${colorObj.r}, ${colorObj.g}, ${colorObj.b}`

      if (palette[key]) {
        document.documentElement.style.setProperty(`--${key}`, color)
      }
    }
  }
}
