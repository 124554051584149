import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { FINANCE_USER_BEHAVIOUR, TRADE_IN_USER_BEHAVIOUR } from '../../../constants'
import { formatCurrency, formatPrice, fullPaymentValue } from '../../../helpers'
import { setOnlinePaymentActiveVariant } from '../../../store/actions'
import s from './fullPayment.module.scss'
import PaymentVariant from './PaymentVariant'

function FullPayment({
  activePayment,
  isWhite,
  clearVariantsData,
  isTradeInEnabledByUser,
  isCreditEnabledByUser,
  isLeasingEnabledByUser,
  isFullPaymentVariantDisabled,
  currency,
  vehicleType,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const variants = () => {
    let res = []

    for (let item in clearVariantsData) {
      if (clearVariantsData.hasOwnProperty(item) && item !== 'initialFull') {
        let price = clearVariantsData[item]
        let formattedPrice = `${formatPrice(clearVariantsData[item], currency)}`

        if (item === 'full') {
          let p = fullPaymentValue()

          price = p
          formattedPrice = `${formatPrice(p, currency)}`
        }

        let variant = {
          id: item,
          title: t(`onlinePayment.fullPayment.${item}.title`),
          price,
          formattedPrice,
          description: t(`onlinePayment.fullPayment.${item}.description.${vehicleType}`),
          isDisabled: item === 'full' ? isFullPaymentVariantDisabled : false,
          isCreditEnabledByUser: isCreditEnabledByUser,
          isLeasingEnabledByUser: isLeasingEnabledByUser,
          isTradeInEnabledByUser: isTradeInEnabledByUser,
        }

        res.push(variant)
      }
    }
    return res
  }

  const setVariant = variant => {
    if (!variant || !variant.id || variant.isDisabled) {
      return
    }

    dispatch(setOnlinePaymentActiveVariant(variant))
  }

  return (
    <div className={s.wrapper}>
      {variants().map(variant => {
        return (
          <div className={s.wrapper__variant} key={variant.id} onClick={() => setVariant(variant)}>
            <PaymentVariant variant={variant} isActive={activePayment.id === variant.id} isWhite={isWhite} />
          </div>
        )
      })}
    </div>
  )
}

function mapStateToProps({
  onlinePayment: { variants: clearVariantsData, activePayment },
  settings: { currency, vehicleType },
  tradeIn: { behaviourByUser: tradeInBehaviourByUser },
  credit: { behaviourByUser: financeBehaviourByUser },
}) {
  // можно ли выбрать полную оплату (только если нет трейд-ина и финансирования)
  const isTradeInEnabledByUser = TRADE_IN_USER_BEHAVIOUR.APPLIED === tradeInBehaviourByUser
  const isCreditEnabledByUser = FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED === financeBehaviourByUser
  const isLeasingEnabledByUser = FINANCE_USER_BEHAVIOUR.LEASING_APPLIED === financeBehaviourByUser

  const isFullPaymentVariantDisabled = isTradeInEnabledByUser || isCreditEnabledByUser || isLeasingEnabledByUser

  return {
    activePayment,
    clearVariantsData,
    isTradeInEnabledByUser,
    isCreditEnabledByUser,
    isLeasingEnabledByUser,
    isFullPaymentVariantDisabled,
    currency,
    vehicleType,
  }
}

export default connect(mapStateToProps)(FullPayment)
