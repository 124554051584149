const loadState = storageKey => {
  try {
    const serializedState = localStorage.getItem(storageKey)
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (storageKey, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(storageKey, serializedState)
  } catch (err) {
    // ignore write errors
  }
}

export { loadState, saveState }
