export const closestTerm = (defaultConditions, term) => {
  let allTerms = Object.keys(defaultConditions)
  let closest = term

  let j = 0

  for (let i = 0; i <= allTerms[allTerms.length - 1]; i += 12) {
    if (term > i && term <= i + 12) {
      closest = allTerms[j]
    }
    j++
  }

  return closest
}
