import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import * as yup from 'yup'

import {
  communicationProcessingLabel,
  isCommunicationProcessingAcceptedCheckboxVisible,
  isCommunicationProcessingCheckboxVisible,
} from '../../Components/Parts/FormNew/helpers'
import Timer from '../../Components/Parts/FormNew/timer/Timer'
import { COUNTRY_CODES, CREDIT_STANDALONE_SCREEN, FORM_SOURCE } from '../../constants'
import { getTimeToResend, initializeErrorsProcessing, refreshProtection } from '../../helpers'
import { wrapLinksInAgreementText } from '../../helpers/normalize_visual'
import { emailRegex } from '../../helpers/validations'
import { startLoading, stopLoading } from '../../store/actions'
import {
  createSmartContractBackground,
  getPrograms,
  goToOnlineApproval,
  goToSmartContract,
  setError,
  setPeronalDataFilled,
  setScreen,
} from '../../store/actions/credit_standalone'
import {
  clearSmsCodeTime,
  setCaptchaSettings,
  setCommunicationProcessing,
  setFormValue,
  setSmsCodeSettings,
} from '../../store/actions/form'
import { getCreditProgramEnding, getYourSuitableProgramsText } from '../Programs/endingUtil'
import List from '../Programs/List'
import { Button, Input, InputGroup, Subtitle } from '../UI/Common'
import Infoblock from '../UI/Infoblock'
import Phone from '../UI/Phone'
import { formID } from './formID'
import s          from './styles/personal.module.scss'
import {getProjectName} from "../../helpers/app/detectors/project_name";

const Personal = ({
  agreements,
  communicationValues,
  agreementText,
  name,
  lastName,
  phone,
  phoneRawValue,
  email,
  agreement,
  programs,
  fields,
  country,
  carModelData,
  setScreen,
  getPrograms,
  setPeronalDataFilled,
  setFormValue,
  setCommunicationProcessing,
  conditionsRef,
  selectModelRef,
  loading,
  selectedProgram,
  programsLoadedOnce,
  createSmartContractBackground,
  moduleLocale,
  showProgramsBeforePersonalData,
  goToSmartContract,
  goToOnlineApproval,
  smartContract,
  creditOnlineApprove,
  disableSCLink,
  widgetId,
  isLeasing,
  error,
  locale,
  priceToolTipEnabled,
  smsCode,
  captcha,
  smsCodeSettings,
  captchaSettings,
  captchaError,
  smsCodeError,
  filled,
  vehicleType,
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const element = conditionsRef.current.getBoundingClientRect().bottom + window.scrollY + 1
    window.scroll({
      top: element,
      behavior: 'smooth',
    })
  }, [conditionsRef])

  const [showOnlineApprovalOnce, setShowOnlineApprovalOnce] = useState(true)
  const [timerCountdown, setTimerCountdown] = useState(0)

  useEffect(() => {
    let currentDate = new Date()

    if (
      !timerCountdown &&
      smsCodeSettings?.timeToResend &&
      smsCodeSettings.timeToResend.getTime() >= currentDate.getTime()
    ) {
      setTimerCountdown(smsCodeSettings.timeToResend.getTime() - currentDate.getTime())
    }
  }, [timerCountdown, smsCodeSettings])

  const handleSetAgreementKeyDown = e => {
    if (e.key === 'Enter') {
      e.target.checked = !e.target.checked
      e.preventDefault()
    }
  }

  const handleSetAgreementClick = e => setFormValue('agreement', e.target.checked)
  const handleSetCommunicationProcessingAcceptedClick = e => setCommunicationProcessing('accepted', e.target.checked)
  const handleSetCommunicationProcessingPhoneClick = e => setCommunicationProcessing('phone', e.target.checked)
  const handleSetCommunicationProcessingSmsClick = e => setCommunicationProcessing('sms', e.target.checked)
  const handleSetCommunicationProcessingPostClick = e => setCommunicationProcessing('post', e.target.checked)
  const handleSetCommunicationProcessingEmailClick = e => setCommunicationProcessing('email', e.target.checked)

  const handleSetName = useCallback(payload => setFormValue('name', payload.trim()), [setFormValue])
  const handleSetLastName = useCallback(payload => setFormValue('lastName', payload.trim()), [setFormValue])
  const handleSetEmail = useCallback(payload => setFormValue('email', payload.trim()), [setFormValue])
  const handleSetSmsCode = useCallback(payload => setFormValue('smsCode', payload.trim()), [setFormValue])
  const handleSetCaptcha = useCallback(payload => setFormValue('captcha', payload.trim()), [setFormValue])

  const phoneRef = useRef()

  const namesValidation = (yup, type) => {
    const pattern = /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ\s]+$/

    let valueToValidate
    if (type === 'name') valueToValidate = name
    if (type === 'lastName') valueToValidate = lastName

    if (!fields[type].hide && fields[type].required) {
      return locale === 'Ru'
        ? yup.string().matches(pattern, t('carOrderForm.incorrectFiled')).required(t('carOrderForm.requiredFiled'))
        : yup.string().required(t('carOrderForm.requiredFiled'))
    }

    if (!fields[type].hide && !fields[type].required && valueToValidate) {
      return locale === 'Ru' ? yup.string().matches(pattern, t('carOrderForm.incorrectFiled')) : null
    }

    return null
  }

  const protectionValidation = (yup, type) => {
    if (type && type.visible) {
      return yup.string().required(t('carOrderForm.requiredFiled'))
    }
    return null
  }

  const checkboxValidation = yup => {
    if (isCommunicationProcessingAcceptedCheckboxVisible(agreements, true)) {
      return yup.bool().oneOf([true], t('carOrderForm.requiredFiled')).required(t('carOrderForm.requiredFiled'))
    }
    return null
  }

  const schema = yup.object().shape({
    kdxcc_name: namesValidation(yup, 'name'),
    kdxcc_lastName: namesValidation(yup, 'lastName'),
    kdxcc_phone: yup
      .string()
      .test('isPhone', 'incorrect phone', value => COUNTRY_CODES[country || 'russia'].regex.test(value))
      .required(),
    kdxcc_email: fields.email.required
      ? yup.string().matches(emailRegex).required()
      : yup.string().matches(emailRegex, { excludeEmptyString: true }),
    kdxcc_agreement: yup.bool().oneOf([true]).required(),
    kdxcc_captcha: protectionValidation(yup, captchaSettings),
    kdxcc_sms_code: protectionValidation(yup, smsCodeSettings),
  })

  const { register, handleSubmit, control, setValue, errors } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!errors[formID.name] && !errors[formID.lastName] && errors[formID.phone]) {
      phoneRef.current.focus()
    }
  }, [errors])

  const onSubmit = data => {
    if (!carModelData.length) {
      selectModelRef.current.focus()
      return
    }

    if (loading) {
      return
    }

    schema.isValid(data).then(() => {
      setPeronalDataFilled(true)

      const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

      if (!programsLoadedOnce) {
        createSmartContractBackground({
          buttonText: programsLoadedOnce ? t('ccS.personal.goBackButton') : t('ccS.personal.showProgramsButton'),
        })
          .then(() => {
            if (programs.length) {
              setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS)
              return
            }
            getPrograms()
          })
          .catch(e => {
            initializeErrorsProcessing({
              e,
              formSource,
              additional: { errorText: t('error.limit') },
            })
          })
      }

      if (showProgramsBeforePersonalData && smartContract.smartContractUrl) {
        window.open(smartContract.smartContractUrl, '_blank')

        window.dataLayer &&
          window.dataLayer.push({
            event: 'SmartContract_OnlineDeal',
            Status: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
            buttonName: t('ccS.programs.continue'),
            widget_id: widgetId,
            deal_id: `${smartContract.genericId}-${smartContract.id}`,
            eventValue: '',
          })

        window.dataLayer &&
          window.dataLayer.push({
            event: 'calc_choose_credit',
            ec: 'step2',
            ea: window.location.href,
          })
        window.ym && window.ym(93074980, 'reachGoal', 'calc_choose_credit_step2', { URL: window.location.href })

        return
      }

      if (showProgramsBeforePersonalData && !smartContract.smartContractUrl) {
        createSmartContractBackground({ buttonText: t('ccS.programs.continue') })
          .then(() => {
            if (programs.length) {
              setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS)
              return
            }
            getPrograms()
          })
          .catch(e => {
            initializeErrorsProcessing({
              e,
              formSource,
              additional: { errorText: t('error.limit') },
            })
          })
      }

      if (
        (smsCodeSettings.visible || captchaSettings.visible) &&
        !smartContract.smartContractUrl &&
        programsLoadedOnce &&
        !showProgramsBeforePersonalData
      ) {
        createSmartContractBackground({ buttonText: t('ccS.programs.continue') })
          .then(() => {
            if (programs.length) {
              setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS)
              return
            }
            getPrograms()
          })
          .catch(e => {
            initializeErrorsProcessing({
              e,
              formSource,
              additional: { errorText: t('error.limit') },
            })
          })
      }
    })
  }

  const agreementTextHtml = agreementText ? wrapLinksInAgreementText(agreementText) : t('form.defaultAgreementText')
  const communicationProcessingHtml = wrapLinksInAgreementText(
    communicationProcessingLabel(agreements, 'form_type_base', true),
  )

  const suitableCreditProgramsText = () => {
    if (i18n.language !== 'ru-RU') {
      return `${programs.length} ${
        programs.length === 1
          ? t(`ccS.personal.yourSuitableProgramsP1V2.${moduleLocale}`)
          : t(`ccS.personal.yourSuitableProgramsP1V1.${moduleLocale}`)
      }`
    } else {
      return `${getYourSuitableProgramsText(programs.length)} ${programs.length} ${getCreditProgramEnding(
        programs.length,
        moduleLocale,
      )}`
    }
  }

  const verticalFieldsLayout =
    (fields.name.hide && !fields.lastName.hide) || (!fields.name.hide && fields.lastName.hide) || fields.email.hide

  const banks = [...new Set(programs.map(program => program.bank.name))]

  const programList = banks.map((bank, ix) => {
    const bankPrograms = programs.filter(p => p.bank.name === bank)

    return (
      <List
        bankName={bank}
        key={bank}
        programs={bankPrograms}
        isPreview={true}
        opened={ix === 0}
        selectedProgram={selectedProgram}
        onlyOneBank={banks.length === 1}
      />
    )
  })

  const approveCreditOnlineHandler = e => {
    e.preventDefault()

    if (smartContract.genericId && smartContract.id && smartContract.token) {
      goToOnlineApproval({
        uniqueId: smartContract.genericId.toUpperCase() + '-' + smartContract.id,
        token: smartContract.token,
        smartContractUrl: smartContract?.smartContractUrl,
      })
    } else {
      const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

      goToSmartContract(true)
        .then(() => {
          setShowOnlineApprovalOnce(false)
        })
        .catch(e => {
          initializeErrorsProcessing({
            e,
            formSource,
            additional: {
              creditStandaloneStep: 'programs',
              errorText: t('error.limit'),
            },
          })
        })
    }
  }

  const showButtons =
    smartContract.smartContractUrl ||
    !smartContract.id ||
    (smartContract.id && smartContract.createdFromBackground && !showProgramsBeforePersonalData) ||
    !error

  const handleGoBackButton = e => {
    if (showProgramsBeforePersonalData) {
      e.preventDefault()
      setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS)
    }
  }

  const onClearCountdown = () => {
    const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE
    clearSmsCodeTime(formSource)
    setTimerCountdown(0)
  }

  const sendProtectionOnceAgain = protectionType => {
    const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

    switch (protectionType) {
      case 'smsCode':
        startLoading()
        dispatch(setFormValue('smsCode', ''))
        refreshProtection(smsCodeSettings.ticketId)
          .then(r => {
            dispatch(
              setSmsCodeSettings(formSource, {
                visible: true,
                timeToResend: getTimeToResend(r?.data?.attributes),
                ticketId: r?.data?.id,
              }),
            )
          })
          .catch(e => {
            console.error('unable to send sms code', e)
            setError(t('ccS.actions.error'))
          })
          .finally(() => {
            stopLoading()
          })
        break

      case 'captcha':
        startLoading()
        dispatch(setFormValue('captcha', ''))
        refreshProtection(captchaSettings.ticketId)
          .then(r => {
            dispatch(
              setCaptchaSettings(formSource, {
                visible: true,
                captchaSrc: r?.data?.attributes?.question,
                ticketId: r?.data?.id,
              }),
            )
          })
          .catch(e => {
            console.error('unable receive captcha', e)
            setError(t('ccS.actions.error'))
          })
          .finally(() => {
            stopLoading()
          })
        break

      default:
        console.error('unknown protection type')
    }
  }

  return (
    <section className={s.personal} data-project-name={getProjectName()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Subtitle>{t(`ccS.personal.title.${moduleLocale}`)}</Subtitle>
        <InputGroup vertical={verticalFieldsLayout}>
          {!fields.name.hide && (
            <Input
              vertical={verticalFieldsLayout}
              labelText={t('form.yourName')}
              id={formID.name}
              value={name}
              tip={fields.name.required && '*'}
              onChange={handleSetName}
              register={register}
            />
          )}
          {!fields.lastName.hide && (
            <Input
              vertical={verticalFieldsLayout}
              labelText={t('form.yourLastName')}
              id={formID.lastName}
              value={lastName}
              tip={fields.lastName.required && '*'}
              onChange={handleSetLastName}
              register={register}
            />
          )}
        </InputGroup>
        <InputGroup vertical={verticalFieldsLayout}>
          <Phone
            vertical={verticalFieldsLayout}
            labelText={t('form.phoneNumber')}
            id={formID.phone}
            value={phoneRawValue}
            formattedValue={phone}
            tip={'*'}
            control={control}
            register={register}
            setValue={setValue}
            inputRef={phoneRef}
          />
          {!fields.email.hide && (
            <Input
              vertical={verticalFieldsLayout}
              labelText={t('form.email')}
              id={formID.email}
              value={email}
              tip={fields.email.required && '*'}
              onChange={handleSetEmail}
              register={register}
            />
          )}
        </InputGroup>
        <InputGroup vertical={verticalFieldsLayout}>
          {smsCodeSettings.visible && (
            <div className={s.captchaWrapper}>
              {smsCodeError && <span className={s.errorText}>{t('form.smsCodeError')}</span>}
              <Input
                vertical={verticalFieldsLayout}
                labelText={t('form.smsCode')}
                id={formID.smsCode}
                value={smsCode}
                tip={'*'}
                onChange={handleSetSmsCode}
                register={register}
              />
              <div className={[s.captchaRefresh, timerCountdown ? '' : s.active].join(' ')}>
                {timerCountdown > 0 ? (
                  <Timer countdown={timerCountdown} onClearCountdown={onClearCountdown.bind(this)} />
                ) : (
                  <span onClick={sendProtectionOnceAgain.bind(this, 'smsCode')}>{t('form.resendSmsCode')}</span>
                )}
              </div>
            </div>
          )}
          {captchaSettings.visible && (
            <div className={s.captchaWrapper}>
              {captchaError && <span className={s.errorText}>{t('form.captchaError')}</span>}
              <img src={captchaSettings.captchaSrc} alt="" width="240px" />
              <Input
                vertical={verticalFieldsLayout}
                labelText={t('form.captcha')}
                id={formID.captcha}
                value={captcha}
                tip={'*'}
                onChange={handleSetCaptcha}
                register={register}
              />
              <div
                className={[s.captchaRefresh, s.active].join(' ')}
                onClick={sendProtectionOnceAgain.bind(this, 'captcha')}
              >
                {t('form.resendCaptcha')}
              </div>
            </div>
          )}
        </InputGroup>
        <div className={s.agreements_wrapper} data-project-name={getProjectName()}>
          <div className={s.agreements_container}>
            <div className={s.agreement_box}>
              <div className={s.agreement}>
                <div className={s.checkbox_box}>
                  <input
                    className={s.checkbox}
                    id={formID.agreement}
                    name={formID.agreement}
                    type="checkbox"
                    checked={agreement}
                    onKeyDown={handleSetAgreementKeyDown}
                    onChange={handleSetAgreementClick}
                    ref={register}
                  />
                </div>
                <label
                  className={s.label}
                  htmlFor={formID.agreement}
                  dangerouslySetInnerHTML={{ __html: agreementTextHtml + '*' }}
                />
              </div>
            </div>
            {isCommunicationProcessingAcceptedCheckboxVisible(agreements, true) && (
              <div className={s.agreement_box}>
                <div className={s.agreement}>
                  <div className={s.checkbox_box}>
                    <input
                      className={s.checkbox}
                      id={formID.communicationProcessingAccepted}
                      name={formID.communicationProcessingAccepted}
                      type="checkbox"
                      checked={communicationValues.accepted}
                      onKeyDown={handleSetAgreementKeyDown}
                      onChange={handleSetCommunicationProcessingAcceptedClick}
                      ref={register}
                    />
                  </div>
                  <label
                    className={s.label}
                    htmlFor={formID.communicationProcessingAccepted}
                    dangerouslySetInnerHTML={{
                      __html: communicationProcessingHtml,
                    }}
                  />
                </div>
              </div>
            )}
            {isCommunicationProcessingCheckboxVisible(agreements, 'email', true) && (
              <div className={s.agreement_box}>
                <div className={s.agreement}>
                  <div className={s.checkbox_box}>
                    <input
                      className={s.checkbox}
                      id={formID.communicationProcessingEmail}
                      name={formID.communicationProcessingEmail}
                      type="checkbox"
                      checked={communicationValues.email}
                      onKeyDown={handleSetAgreementKeyDown}
                      onChange={handleSetCommunicationProcessingEmailClick}
                      ref={register}
                    />
                  </div>
                  <label
                    className={s.label}
                    htmlFor={formID.communicationProcessingEmail}
                    dangerouslySetInnerHTML={{ __html: 'email' }}
                  />
                </div>
              </div>
            )}
            {isCommunicationProcessingCheckboxVisible(agreements, 'phone', true) && (
              <div className={s.agreement_box}>
                <div className={s.agreement}>
                  <div className={s.checkbox_box}>
                    <input
                      className={s.checkbox}
                      id={formID.communicationProcessingPhone}
                      name={formID.communicationProcessingPhone}
                      type="checkbox"
                      checked={communicationValues.phone}
                      onKeyDown={handleSetAgreementKeyDown}
                      onChange={handleSetCommunicationProcessingPhoneClick}
                      ref={register}
                    />
                  </div>
                  <label
                    className={s.label}
                    htmlFor={formID.communicationProcessingPhone}
                    dangerouslySetInnerHTML={{ __html: 'phone' }}
                  />
                </div>
              </div>
            )}
            {isCommunicationProcessingCheckboxVisible(agreements, 'post', true) && (
              <div className={s.agreement_box}>
                <div className={s.agreement}>
                  <div className={s.checkbox_box}>
                    <input
                      className={s.checkbox}
                      id={formID.communicationProcessingPost}
                      name={formID.communicationProcessingPost}
                      type="checkbox"
                      checked={communicationValues.post}
                      onKeyDown={handleSetAgreementKeyDown}
                      onChange={handleSetCommunicationProcessingPostClick}
                      ref={register}
                    />
                  </div>
                  <label
                    className={s.label}
                    htmlFor={formID.communicationProcessingPost}
                    dangerouslySetInnerHTML={{ __html: 'post' }}
                  />
                </div>
              </div>
            )}
            {isCommunicationProcessingCheckboxVisible(agreements, 'sms', true) && (
              <div className={s.agreement_box}>
                <div className={s.agreement}>
                  <div className={s.checkbox_box}>
                    <input
                      className={s.checkbox}
                      id={formID.communicationProcessingSms}
                      name={formID.communicationProcessingSms}
                      type="checkbox"
                      checked={communicationValues.sms}
                      onKeyDown={handleSetAgreementKeyDown}
                      onChange={handleSetCommunicationProcessingSmsClick}
                      ref={register}
                    />
                  </div>
                  <label
                    className={s.label}
                    htmlFor={formID.communicationProcessingSms}
                    dangerouslySetInnerHTML={{ __html: 'sms' }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {priceToolTipEnabled && <div className={s.priceToolTipEnabled_box}>{t(`car.tooltipAlert.${vehicleType}`)}</div>}
        {showButtons && showProgramsBeforePersonalData && (
          <div>
            <Button vertical={showProgramsBeforePersonalData ? true : false}>{t('ccS.programs.continue')}</Button>

            {creditOnlineApprove && showOnlineApprovalOnce && !disableSCLink && (
              <div onClick={approveCreditOnlineHandler}>
                <Button vertical={showProgramsBeforePersonalData ? true : false} onClick={approveCreditOnlineHandler}>
                  {t('credit.onlineApproval')}
                </Button>
              </div>
            )}
          </div>
        )}
        <div onClick={handleGoBackButton}>
          <Button vertical={showProgramsBeforePersonalData ? true : false} onClick={handleGoBackButton}>
            {programsLoadedOnce ? t('ccS.personal.goBackButton') : t('ccS.personal.showProgramsButton')}
          </Button>
        </div>
        {programs.length > 0 && !showProgramsBeforePersonalData && (
          <div className={s.hidden_programs} data-project-name={getProjectName()}>
            <Subtitle>{suitableCreditProgramsText()}</Subtitle>
            <div className={s.hidden_programs_box}>
              <div className={s.hidden_programs_list}>{programList}</div>
              <div className={s.hidden_programs_infobox}>
                <Infoblock stickRight={true} text={t(`ccS.personal.noDataText.${moduleLocale}`)} />
              </div>
            </div>
          </div>
        )}
      </form>
    </section>
  )
}

const mapStateToProps = state => {
  const isLeasing = state.credit_standalone.module.isLeasing
  const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

  return {
    screen: state.credit_standalone.screen,
    carModelData: state.credit_standalone.conditions.carModelData,
    locale: state.credit_standalone.config.locale,
    priceToolTipEnabled: state.credit_standalone.config.priceToolTipEnabled,
    agreementText: state.credit_standalone.config.agreementText,
    agreements: state.credit_standalone.config.agreements,
    name: state.form.values.name,
    lastName: state.form.values.lastName,
    phoneRawValue: state.form.phoneSelection.phoneRawValue,
    phone: state.form.values.phone,
    email: state.form.values.email,
    agreement: state.form.values.agreement,
    communicationValues: state.form.agreements.communication,
    smsCode: state.form.values.smsCode,
    captcha: state.form.values.captcha,
    smsCodeSettings: state.form.smsCodeSettings[formSource],
    captchaSettings: state.form.captchaSettings[formSource],
    captchaError: state.form.captchaError[formSource],
    smsCodeError: state.form.smsCodeError[formSource],
    limitForSaving: state.settings.limitForSaving[formSource],
    programs: state.credit_standalone.programs.list,
    fields: state.credit_standalone.config.fields,
    filled: state.credit_standalone.personal.filled,
    country: state.form.phoneSelection.country,
    error: state.credit_standalone.error,
    loading: state.credit_standalone.loading,
    selectedProgram: state.credit_standalone.programs.selected,
    programsLoadedOnce: state.credit_standalone.programs.loadedOnce,
    moduleLocale: state.credit_standalone.module.locale,
    showProgramsBeforePersonalData: state.credit_standalone.config.showProgramsBeforePersonalData,
    smartContract: state.credit_standalone.config.smartContract,
    creditOnlineApprove: state.credit_standalone.config.creditOnlineApprove,
    disableSCLink: state.settings.disableSmartContractLink,
    widgetId: state.credit_standalone.config.widgetId,
    vehicleType: state.settings.vehicleType,
    isLeasing,
  }
}

const mapDispatchToProps = {
  setScreen,
  getPrograms,
  setFormValue,
  setCommunicationProcessing,
  clearSmsCodeTime,
  setPeronalDataFilled,
  createSmartContractBackground,
  goToSmartContract,
  goToOnlineApproval,
  stopLoading,
  startLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(Personal)
