import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import {
  CREDIT_SETTINGS_BEHAVIOR,
  LEASING_SETTINGS_BEHAVIOR,
  SCREEN_ACCESSORIES,
  TRADE_IN_SETTINGS_BEHAVIOR,
} from '../../../constants'
import { goToScreen } from '../../../store/actions'
import BlockState from '../../Ui/blockState'
import Accessory from './Accessory'
import AccessorySm from './AccessorySm'
import AccessoryCategory from './Category'
import { categoriesForPreview, formattedWelcomeMessage, getBlockNumber } from './helpers'
import s from './styles/state.module.scss'

function Accessories({ accessories, selected, carName, blockNumber, vehicleType, currency }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const welcomeMessage = formattedWelcomeMessage({ selected, carName, vehicleType })
  const categories = categoriesForPreview(accessories)

  const initialState = () => {
    return (
      <>
        <div className={s.welcome}>
          <div className={s.welcome__title}>{welcomeMessage}</div>
          <div className={s.welcome__filters}>
            <div className={s.filters}>
              {categories.map((c, i) => {
                return (
                  <div key={i} className={s.filters__item} onClick={() => dispatch(goToScreen(SCREEN_ACCESSORIES))}>
                    {c.length > 30 ? c.substr(0, 27) + ' ...' : c}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className={s.categories}>
          <div className={s.hiddenCategory}>
            <div className={s.filters}>
              <div className={s.filters__item} onClick={() => dispatch(goToScreen(SCREEN_ACCESSORIES))}>
                {t('accessories.showAll')}
              </div>
            </div>
          </div>
          <AccessoryCategory title={accessories[0].attributes.subGroup}>
            <div className={s.accessories}>
              <div className={s.accessories__item}>
                <Accessory props={{ ...accessories[0], currency }} firstScreen={true} />
              </div>
            </div>
          </AccessoryCategory>
        </div>
      </>
    )
  }

  const selectedState = () => {
    return (
      <>
        <div className={s.welcome}>
          <div className={s.welcome__title}>{welcomeMessage}</div>
          <div className={s.welcome__filters}>
            <div className={s.filters}>
              <div className={s.filters__item} onClick={() => dispatch(goToScreen(SCREEN_ACCESSORIES))}>
                {t('accessories.chooseMore')} &#10095;
              </div>
            </div>
          </div>
        </div>

        <div className={s.accessories}>
          {selected.map((accessory, i) => {
            return (
              <div className={s.accessories__item_sm} key={i}>
                <AccessorySm props={{ ...accessory, currency }} category={accessory.attributes.subGroup} />
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <BlockState title={t('accessories.accessories')} isActive={selected?.length} number={blockNumber}>
      {selected?.length ? selectedState() : initialState()}
    </BlockState>
  )
}

function mapStateToProps({
  settings: {
    vehicleType,
    currency,
    visual: { isPriceHidden },
  },
  accessories: { accessories, selected },
  car: { name: carName, additionalOptions },
  benefits: { benefits },
  tradeIn: { behaviour: tradeInBehaviourBySettings },
  credit: { behaviour: creditBehaviourBySettings },
  leasing: { behaviour: leasingBehaviourBySettings },
}) {
  const isTradeInSection = tradeInBehaviourBySettings !== TRADE_IN_SETTINGS_BEHAVIOR.DISABLED
  const isFinanceSection =
    leasingBehaviourBySettings !== LEASING_SETTINGS_BEHAVIOR.DISABLED ||
    creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.DISABLED
  const isBenefitsSection = Boolean(benefits?.length) || Boolean(additionalOptions?.length)

  return {
    accessories,
    selected,
    carName,
    currency,
    blockNumber: isPriceHidden ? 2 : getBlockNumber({ isTradeInSection, isFinanceSection, isBenefitsSection }),
    vehicleType,
  }
}
export default connect(mapStateToProps)(Accessories)
