export const bootBatch2PayloadCreator = data => {
  let p = {
    payload: {},
  }

  for (let i in data) {
    if (data.hasOwnProperty(i)) {
      let item = data[i]

      let carsInWidget = {}

      if (item.dealerships) {
        item.dealerships.forEach(d => {
          if (carsInWidget[d.widgetId]) {
            carsInWidget[d.widgetId].cars.push({
              identity: d.identity,
              price: item.price,
              modelData: item.model_data,
              productType: item.productType,
            })
          } else {
            carsInWidget[d.widgetId] = {
              cars: [
                {
                  identity: d.identity,
                  price: item.price,
                  modelData: item.model_data,
                  productType: item.productType,
                },
              ],
            }
          }
        })
      }

      p.payload[item.card_identity] = carsInWidget
    }
  }

  return p
}
