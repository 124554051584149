import { connect, useDispatch } from 'react-redux'

import { setTradeInModel, setTradeInStandaloneTypedValue } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneModelDropdown({ models, id, typedValue }) {
  const dispatch = useDispatch()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInModel({ id, name }))
    dispatch(
      setTradeInStandaloneTypedValue({
        name: 'model',
        value: name,
      }),
    )
  }

  return (
    <TradeInStandalonePartsRadiobuttons name="model" variants={models} checked={id} onSet={onSet} search={typedValue} />
  )
}

function mapStateToProps({
  tradeIn: {
    typedValues: { model: typedValue },
    values: {
      model: { id },
    },
    dictionaries: { models },
  },
}) {
  return {
    id,
    models,
    typedValue,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneModelDropdown)
