import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TRADE_IN_ESTIMATION_TYPE, TRADE_IN_STEP_WEIGHTS_BY_IDENTITY } from '../../../constants'
import TradeInStandaloneDropdown from '../dropdown'
import TradeInStandaloneCityDropdown from '../dropdowns/city'
import TradeInStandaloneMileageDropdown from '../dropdowns/mileage'
import TradeInStandaloneCityInput from '../inputs/city'
import TradeInStandaloneIdentityInput from '../inputs/identity'
import TradeInStandaloneMileageInput from '../inputs/mileage'
import TradeInStandaloneListOfSteps from '../listOfSteps'
import s from './styles/steps.module.scss'

function TradeInStandaloneStepsByIdentity({ step, behaviourByUser }) {
  const { t } = useTranslation()

  let focus = useRef('')

  const listen = useCallback(e => {
    focus.current = e.detail
  }, [])

  useEffect(() => {
    document.addEventListener('kodix:smartContract:bubbleClick', listen)
    return () => {
      document.removeEventListener('kodix:smartContract:bubbleClick', listen)
    }
  }, [listen])

  const inputSelection = () => {
    switch (step) {
      case 'identity':
        return <TradeInStandaloneIdentityInput focus={focus.current} />
      case 'city':
        return <TradeInStandaloneCityInput focus={focus.current} />
      case 'mileage':
        return <TradeInStandaloneMileageInput focus={focus.current} />
      default:
        return <div>no step</div>
    }
  }

  const inputDropdown = () => {
    switch (step) {
      case 'identity':
        return <div />
      case 'city':
        return <TradeInStandaloneCityDropdown />
      case 'mileage':
        return <TradeInStandaloneMileageDropdown />
      default:
        return <div>no step</div>
    }
  }

  const openOnMount = behaviourByUser !== 'none'

  return (
    <div className={s.steps}>
      <div className={s.steps__counter}>
        <div className={s.stepsCounter}>
          <div className={s.stepsCounter__current}>{TRADE_IN_STEP_WEIGHTS_BY_IDENTITY[step] / 100}</div>
          <div className={s.stepsCounter__total}>/ 3</div>
        </div>
      </div>
      <div className={s.steps__field}>
        <div className={s.stepsField}>
          <div className={s.stepsField__select}>
            <TradeInStandaloneDropdown
              type="select"
              selectionContent={t(`tradeIn.${step}.title`)}
              dropdownContent={
                <TradeInStandaloneListOfSteps estimationType={TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ} />
              }
            />
          </div>

          <div className={s.stepsField__input}>
            <TradeInStandaloneDropdown
              type="input"
              step={step}
              openOnMount={openOnMount}
              selectionContent={inputSelection()}
              dropdownContent={inputDropdown()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ tradeIn: { step, behaviourByUser } }) {
  return {
    step: step ? step : 'identity',
    behaviourByUser,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneStepsByIdentity)
