import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatMileage } from '../../../../helpers'
import RadioButton from '../../../ui/radioButton'
import s           from './styles/radio.module.scss'

function TradeInStandalonePartsRadiobuttons({ variants, name, checked, onSet, search, additionalClasses, radiosType }) {
  const [all, setAll] = useState(false)

  const { t } = useTranslation()

  const havePopular = !!variants.find(variant => variant.base)

  const flexClass = () => {
    if (['brand', 'model'].includes(name)) {
      return s.radios__item4
    } else if (['modification', 'year', 'color', 'mileage'].includes(name)) {
      return s.radios__item2
    } else {
      return s.radios__item1
    }
  }

  const handleSet = event => {
    const {
      target: { value },
    } = event

    let variant = ''

    if (name === 'cities') {
      variant = variants.find(variant => String(variant.id) === value)
    } else if (name === 'mileage') {
      onSet(value)
      return
    } else {
      variant = variants.find(variant => variant.id === value)
    }

    onSet({
      id: variant.id,
      name: variant.name,
      external: variant.external,
    })
  }

  const filter = variant => {
    if (search !== '' && variant) {
      if (variant.name) {
        return variant.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      } else if (variant.search) {
        return variant.search.toLowerCase().indexOf(search.toLowerCase()) >= 0
      } else {
        return false
      }
    }

    if (!havePopular) {
      return true
    }

    if (all === false) {
      return variant.base
    }

    return true
  }

  const nameFormatter = v => {
    if (name === 'cities') {
      return `${v.name} ${v.external}`
    } else if (name === 'mileage') {
      return formatMileage(v.id, t('tradeInS.kilometers'))
    } else {
      return v.name
    }
  }

  const isChecked = variant => {
    if (name === 'mileage') {
      return checked === String(variant.id)
    } else {
      return checked === variant.id
    }
  }

  const filteredVariants = variants.filter(filter).map(variant => {
    return (
      <div className={`${s.radios__item} ${flexClass()}`} key={`${name}-id-${variant.id}`}>
        <RadioButton
          radiosType={radiosType}
          isChecked={isChecked(variant)}
          name={name}
          id={`${name}-id-${variant.id}`}
          value={variant.id}
          onChangeHandler={handleSet}
          text={nameFormatter(variant)}
        />
      </div>
    )
  })

  const content = () => {
    if (filteredVariants.length || name === 'cities') {
      return filteredVariants
    } else {
      return <div className={s.noData}>{t('tradeInS.noData')}</div>
    }
  }

  const classes = () => {
    let c = [s.more__icon]

    if (all) {
      c.push(s.more__icon_all)
    }
    return c.join(' ')
  }

  const wrapperClasses = () => {
    let c = s.radiosWrapper

    if (additionalClasses) {
      c += ` ${additionalClasses}`
    }
    return c
  }

  return (
    <div className={wrapperClasses()}>
      <div className={s.radiosWrapper__items}>
        <div className={s.radios}>{content()}</div>
      </div>
      {search === '' && havePopular && (
        <div className={s.radiosWrapper__more}>
          <div className={s.more} onClick={() => setAll(!all)}>
            <div className={classes()}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.5C12.14 0.5 15.5 3.86 15.5 8C15.5 12.14 12.14 15.5 8 15.5C3.86 15.5 0.5 12.14 0.5 8C0.5 3.86 3.86 0.5 8 0.5ZM8 14C11.315 14 14 11.315 14 8C14 4.685 11.315 2 8 2C4.685 2 2 4.685 2 8C2 11.315 4.685 14 8 14ZM8.75 8H11L8 11L5 8H7.25V5H8.75V8Z"
                  fill="#222"
                />
              </svg>
            </div>
            <div className={s.more__text}>{!all ? t('tradeIn.helpers.showAll') : t('tradeIn.helpers.showPopular')}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TradeInStandalonePartsRadiobuttons
