import { TRADE_IN_USER_BEHAVIOUR } from '../constants'
import store from '../store/store'
import { calculateTaxes } from './calculate_taxes'

export const fullPaymentValue = (initialFullValue = null) => {
  // initialFullValue - нужно при первоначальной загрузке виджета

  let enableFullPayment = store?.getState()?.onlinePayment?.enableFullPayment

  if (enableFullPayment) {
    let selectedBenefits = store.getState().benefits.selected
    let selectedAccessories = store.getState().accessories.selected
    let full = initialFullValue || store?.getState()?.onlinePayment?.variants?.initialFull || 0
    let benefitsAmount = 0

    if (selectedBenefits) {
      benefitsAmount = selectedBenefits.reduce(
        (accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue,
        0,
      )
    }

    if (selectedAccessories) {
      benefitsAmount += selectedAccessories.reduce(
        (accumulator, { attributes: { price: currentValue } }) => accumulator + currentValue,
        0,
      )
    }

    let tradeIn = store.getState().tradeIn
    if (tradeIn.behaviourByUser === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED && tradeIn.benefitValueWithoutTradeIn < 0) {
      full += tradeIn.benefitValueWithoutTradeIn
    }

    let taxes = store.getState().settings.taxes
    let calculatedTaxes = 0
    if (taxes) {
      let carPrice = store.getState().car.price
      calculatedTaxes = calculateTaxes(carPrice, taxes)
    }

    return full + benefitsAmount + calculatedTaxes
  }

  return 0
}
