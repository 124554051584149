/* eslint-disable no-template-curly-in-string */

import i18n from 'i18next'

import {
  bootBatch2,
  bootBatch2PayloadCreator,
  dealershipsFormatter,
  formatPrice,
  languageSetter,
  modelDataParser,
} from './helpers'
import { bootBatch2ResponseToBootBatch1ResponseFormatter } from './on_list'
import {
  bootedList,
  setCarList,
  setListVersion,
  setPreset,
  setRenderButtons,
  setWidgetId,
  updateCarPrices,
} from './store/actions'
import store from './store/store'

function getModelData(car) {
  return modelDataParser({
    brand: car.brand_name,
    model: car.model_name,
    model_year: car.year,
    engine_power_horse_power: car.engine_power_horse_power,
    generation_name: car.generation_name,
    color: car.base_color_name,
    one_platform_restyling_id: car.restyling_id,
    one_platform_marketing_complectation_id: car.marketing_complectation_id,
    one_platform_marketing_complectation_code: car.marketing_complectation_code,
    one_platform_brand_id: car.brand_id,
    one_platform_model_id: car.model_id,
    one_platform_complectation_id: car.complectation_id,
  })
}

function getDiscounts(car) {
  return {
    credit: car?.credit_discount || 0,
    tradein: car?.tradein_discount || 0,
    credit_tradein: car?.credit_tradein_discount || 0,
    insurance: car?.insurance_discount || 0,
  }
}

function getCarId(preset, car) {
  return preset === 'url' || car.vin === '' ? car.link : car.vin
}

function carIdsAndWidgetIds(car, dealersMap, carId) {
  let res = []

  car.dealers_id.forEach(c => {
    const widgetId = dealersMap[c]

    if (widgetId) {
      res.push({ identity: carId, widgetId })
    }
  })

  return res
}

export function InitSmartContractOnListV2(config) {
  const dealersMap =
    window['@kodix']?.['smartContract']?.['util']?.['formatEcomCars']?.(config.cars) ||
    window['dealersMap'] ||
    config?.dealersMap ||
    {}

  window.scLogger.log('[=======================]')
  window.scLogger.log('[config]', config)
  window.scLogger.log('[config.cars]', config.cars)
  window.scLogger.log('[dealersMap]', dealersMap)

  if (!store.getState().settings.listInitialized && !config.cars.length) {
    dispatchBootedListEvent([], config, {})
    return
  }

  const cars = config.cars.map(car => {
    const carId = getCarId(config.preset, car)

    return {
      additionalOptions: { cost: car.additional_options_cost, list: [] },
      cars: car.cars,
      cardId: car.card_id,
      dealerships: carIdsAndWidgetIds(car, dealersMap, carId),
      discounts: getDiscounts(car),
      id: carId,
      img: car.default_image_url,
      link: car.link,
      modelData: getModelData(car),
      price: car.price,
      productType: car.is_new || 'unknown',
      title: car.model_full_name.replace(car.brand_name, ''),
    }
  })

  window.scLogger.log('[cars]', cars)

  let data = {}

  cars.forEach(car => {
    const item = {
      additionalOptions: car.additionalOptions,
      card_identity: car.cardId,
      dealerships: car.dealerships,
      discounts: car.discounts,
      model_data: car.modelData,
      price: car.price,
      productType: car.productType,
    }

    data[car.cardId] = item
  })

  if (Object.keys(data).length === 0) {
    return
  }

  window.scLogger.log('[data]', data)

  const payload = bootBatch2PayloadCreator(data)

  window.scLogger.log('[payload]', payload)

  bootBatch2('list', JSON.stringify({ data: payload }))
    .then(({ data }) => {
      const bb2ToBb1Data = bootBatch2ResponseToBootBatch1ResponseFormatter(data)
      handleBootBatch(cars, config, bb2ToBb1Data)
      dispatchBootedListEvent(cars, config, bb2ToBb1Data)
    })
    .catch(error => {
      dispatchBootedListEvent(cars, config, {})
      console.error(error)
    })
}

function handleBootBatch(cars, config, data) {
  const cards = document.querySelectorAll('.avn105-00__card')

  window.scLogger.log('[boot_batch response]', data)

  const backgroundBootedOnList = store.getState().settings.backgroundBootedOnList

  cards.forEach((card, index) => {
    const cardId = card.id

    const car = cars.find(car => car.cardId === cardId)

    const carData = data[cardId]

    if (carData?.cars) {
      car.cars = carData.cars
    } else {
      return
    }

    const dealershipData = {
      dealerships: carData.dealerships,
      cars: carData.cars,
    }

    if (config.renderButtons) {
      createButtonOnCard(
        card,
        carData.visual.list,
        carData.status,
        carData.placeholders,
        carData.currency,
        carData.locale,
        dealershipData,
        config.preset,
        car,
      )
    }

    if (index === 0 && backgroundBootedOnList === false) {
      store.dispatch(setWidgetId(Object.keys(car.cars)?.[0]))

      const openSmartContractConfig = {
        car: car,
        preset: config.preset,
        currency: carData.currency,
        buttonText: null,
        dealershipData: dealershipData,
        dontOpenWidget: true,
      }

      openSmartContract(openSmartContractConfig)
    }
  })
}

function createButtonOnCard(
  card,
  buttonSettingsVisual,
  status,
  placeholders,
  currency,
  locale,
  dealershipData,
  preset,
  car,
) {
  let isLocked // открывать ли смарт контракт (зависит от статуса)

  card.style.position = 'relative'

  let button = document.createElement('div')
  button.classList.add('kdx-smart-contract-list-btn')

  // проверка на то, что такой кнопки нет на карточке
  if (card.querySelector('.kdx-smart-contract-list-btn')) {
    return null
  }

  button.style.position = 'absolute'
  button.style.cursor = 'pointer'
  button.style.display = 'flex'
  button.style.alignItems = 'center'
  button.style.zIndex = '99'
  button.dataset.placement = 'list'

  // задает стили и текст с БЭ
  const btnVisual = buttonSettingsVisual.button

  const buttonStyles = btnVisual.styles

  const TEXT_COLOR = buttonStyles.color ? buttonStyles.color : '#000'
  const HOVERED_TEXT_COLOR = buttonStyles.color_hover ? buttonStyles.color_hover : '#000'
  const BACKGROUND_COLOR = buttonStyles.background ? buttonStyles.background : '#00cccc'
  const HOVERED_BACKGROUND_COLOR = buttonStyles.background_hover ? buttonStyles.background_hover : '#12e8d2'
  const FONT_SIZE = buttonStyles.font_size ? buttonStyles.font_size : '14px'
  const FONT_WEIGHT = buttonStyles.font_weight ? buttonStyles.font_weight : '300'
  const PADDING = buttonStyles.padding ? buttonStyles.padding : '8px 12px'
  const BORDER_RADIUS = buttonStyles.border_radius ? buttonStyles.border_radius : '4px'

  const DEFAULT_SVG = `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.172 12L8.22205 7.04999L9.63605 5.63599L16 12L9.63605 18.364L8.22205 16.95L13.172 12Z" fill="${TEXT_COLOR}"/></svg>`
  const HOVERED_SVG = `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.172 12L8.22205 7.04999L9.63605 5.63599L16 12L9.63605 18.364L8.22205 16.95L13.172 12Z" fill="${HOVERED_TEXT_COLOR}"/></svg>`

  button.style.background = BACKGROUND_COLOR
  button.style.color = TEXT_COLOR
  button.style.fontSize = FONT_SIZE
  button.style.fontWeight = FONT_WEIGHT
  button.style.padding = PADDING
  button.style.borderRadius = BORDER_RADIUS

  const buttonPosition = btnVisual.position

  if (buttonPosition.top || buttonPosition.right || buttonPosition.left || buttonPosition.bottom) {
    button.style.top = buttonPosition.top
    button.style.right = buttonPosition.right
    button.style.left = buttonPosition.left
    button.style.bottom = buttonPosition.bottom
  } else {
    button.style.right = '10px'
    button.style.top = '10px'
  }

  let buttonImg = document.createElement('span')

  buttonImg.style.display = 'flex'
  buttonImg.style.alignItems = 'center'
  buttonImg.innerHTML = DEFAULT_SVG
  buttonImg.dataset.placement = 'list'
  buttonImg.style.height = FONT_SIZE

  let buttonText = document.createElement('span')
  buttonText.style.paddingBottom = '3px'
  buttonText.style.paddingTop = '1px'
  buttonText.style.lineHeight = '10px'

  function replacePlaceholders(text) {
    const c = '${credit}'

    // если в тексте нет плейсхолдера, то возвращаем как есть
    if (!text.includes(c)) {
      return text
    }

    // если в тексте есть плейсхолдер, то возвращаем форматированный текст
    if (placeholders[c]) {
      return text.replace(c, formatPrice(placeholders[c], currency) + `/${i18n.t('months')}`)
    }

    return ''
  }

  languageSetter(locale)

  switch (status) {
    case 'wait':
      buttonText.innerText = replacePlaceholders(btnVisual.text_wait) || i18n.t('car_statuses.wait.list_text')
      isLocked = true
      break

    case 'reserved':
      buttonText.innerText = replacePlaceholders(btnVisual.text_reserved) || i18n.t('car_statuses.reserved.list_text')
      isLocked = true
      break

    case 'sold':
      buttonText.innerText = replacePlaceholders(btnVisual.text_sold) || i18n.t('car_statuses.sold.list_text')
      isLocked = true
      break

    case 'available':
      buttonText.innerText = replacePlaceholders(btnVisual.text) || i18n.t('car_statuses.available.list_text')
      isLocked = false
      break

    default:
      buttonText.innerText = replacePlaceholders(btnVisual.text) || i18n.t('car_statuses.available.list_text')
      isLocked = false
      break
  }

  buttonText.dataset.placement = 'list'

  button.append(buttonText)

  // добавляем стелочку только авто а наличи
  if (!isLocked) {
    button.append(buttonImg)
  }

  // если авто нельзя купить, то кнопка прозрачная
  // на десктопе кнопка видна только при наведении на карточку - если есть настройка
  if (!btnVisual.show_always && window.innerWidth > window.innerHeight) {
    button.style.opacity = '0'
  } else if (isLocked) {
    button.style.opacity = '0.5'
  }

  if (!isLocked) {
    button.onmouseover = () => {
      button.style.background = HOVERED_BACKGROUND_COLOR
      button.style.color = HOVERED_TEXT_COLOR
      button.style.transition = 'all ease-in-out 0.1s'
      buttonImg.innerHTML = HOVERED_SVG
    }

    button.onmouseout = () => {
      button.style.background = BACKGROUND_COLOR
      button.style.color = TEXT_COLOR
      button.style.transition = 'all ease-in-out 0.1s'
      buttonImg.innerHTML = DEFAULT_SVG
    }
  }

  function handleOpenSmartContract(e) {
    e.preventDefault()

    const openSmartContractConfig = {
      car: car,
      preset: preset,
      currency: currency,
      buttonText: e.target.innerText,
      dealershipData: dealershipData,
      dontOpenWidget: false,
    }

    openSmartContract(openSmartContractConfig)
  }

  if (!isLocked) {
    // класс с анимацией для привлечения внимания
    button.classList.add('kdxsc-animate')
    button.addEventListener('click', handleOpenSmartContract, false)
  }

  // добавляет кнопку на карточку
  card.append(button)

  // на десктопе кнопка видна только по наведению на карточку - если есть настройка
  if (!btnVisual.show_always && window.innerWidth > window.innerHeight) {
    card.addEventListener('mouseover', () => (button.style.opacity = isLocked ? '0.5' : '1'))
    card.addEventListener('mouseleave', () => (button.style.opacity = '0'))
  }
}

export function openSmartContract(config) {
  const { car, preset, currency, buttonText, dealershipData, dontOpenWidget } = config

  const availableDealerships = dealershipsFormatter(dealershipData)

  const widgetId = Object.keys(dealershipData.dealerships)[0]

  function getCarLink() {
    if (!car?.link) {
      return window.location.href
    }

    if (car.link.includes('http')) {
      return car.link
    } else if (car.link[0] === '/') {
      return window.location.origin + car.link
    }

    return window.location.origin + '/' + car.link
  }

  const options = {
    detail: {
      additionalOptions: car.additionalOptions,
      dealerships: availableDealerships,
      discounts: car.discounts,
      identity: preset === 'url' ? car.link : car.id,
      link: getCarLink(),
      modelData: car.modelData,
      name: car.title,
      picture: car.img,
      price: Number(car.price),
      productType: car.productType,
    },
    calledFromList: true,
    dontOpenWidget: dontOpenWidget,
    listVersion: '2',
    widget_id: widgetId,
  }

  if (!dontOpenWidget) {
    window.dataLayer &&
      window.dataLayer.push({
        event: 'SmartContract_Open',
        Status: '',
        buttonName: buttonText || 'Не удалось определить текст на кнопке на списке',
        carDetail: {
          identity: car.id,
          name: car.title,
          modelData: car.modelData,
          productType: car.productType,
        },
        widget_id: options.widget_id,
        deal_id: '',
        eventValue: car.price,
        currency,
      })
  }

  window.InitSmartContract(options)
}

function dispatchBootedListEvent(cars, config, response) {
  const carsInfo = []
  let shouldEmitEvent = true

  for (const car of cars) {
    const cardId = car.cardId

    if (!cardId) {
      continue
    }

    if (!response[cardId]) {
      carsInfo.push({ carData: car })
      continue
    }

    if (response[cardId].restrictions) {
      for (const restrictions of Object.values(response[cardId].restrictions)) {
        if (restrictions.sc_widget_events_api_available === false) {
          shouldEmitEvent = false
          break
        }
      }

      if (!shouldEmitEvent) {
        break
      }
    }

    const dealershipData = {
      dealerships: response[cardId].dealerships,
      cars: response[cardId].cars,
    }

    carsInfo.push({
      carData: car,
      currency: response[cardId].currency,
      dealershipData: dealershipData,
      visual: response[cardId].visual.list.button,
      status: response[cardId].status,
      placeholders: response[cardId].placeholders,
      locale: response[cardId].locale,
    })
  }

  if (!shouldEmitEvent) {
    return
  }

  if (cars.length) {
    store.dispatch(setCarList(carsInfo))
    store.dispatch(updateCarPrices())
  }

  if (!store.getState().settings.listInitialized) {
    store.dispatch(bootedList(config.widgetIds))
    store.dispatch(setPreset(config.preset))
    store.dispatch(setListVersion(config.listVersion))
    store.dispatch(setRenderButtons(config.renderButtons))
  }
}
