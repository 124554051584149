import { connect } from 'react-redux'

function TradeInStandaloneBodyInput({ dictionaryValue }) {
  return <div>{dictionaryValue}</div>
}

function mapStateToProps({
  tradeIn: {
    values: {
      body: { name: dictionaryValue },
    },
  },
}) {
  return {
    dictionaryValue,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneBodyInput)
