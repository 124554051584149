import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { setCarModel, setCreditOrLeasingStandaloneActiveDealership } from '../../../store/actions/credit_standalone'
import { CREDIT_STANDALONE } from '../../../store/types'
import { InputBox } from '../Common'
import s from './styles/selectcity.module.scss'
import {getProjectName}                                              from "../../../helpers/app/detectors/project_name";

const SelectCity = ({
  wide,
  disabled,
  data,
  labelText,
  placeholder,
  fieldId,
  tip,
  onChange,
  carModelId,
  register,
  setValue,
  nextRef,
  currentRef,
  isLeasing,
}) => {
  const [open, setOpen] = useState(false)
  const [valuesData, setValuesData] = useState(data.filter(item => item.dealershipCity !== ''))
  const select = useRef()
  const dropdown = useRef()
  const hiddenInputRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleInputChange = e => {
    filterValues(e.target.value)
    if (!open) {
      setOpen(true)
    }
  }

  const handleInputFocus = e => {
    setOpen(true)

    if (carModelId) {
      e.target.value = ''
      hiddenInputRef.current.value = ''
      filterValues('')
      onChange(null)
    }
  }

  const handleInputChangeKey = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()

      if (!open) {
        setOpen(true)
      }

      const dropdownList = select.current.lastElementChild
      const dropdownItem = dropdownList.firstElementChild

      if (!dropdownItem) {
        return
      }

      dropdownItem.focus()
    }
  }

  const handleSelectChange = e => {
    setOpen(false)

    const calcType = isLeasing ? 'leasing' : 'credit';

    if (e.target.textContent === t('ccS.dealershipsCity.any') || e.target.textContent === 'Любой') {
      dispatch({ type: CREDIT_STANDALONE.SET_DEALERSHIPS, dealerships: data, calcType })
      currentRef.current.value = e.target.textContent
    } else {
      const value = valuesData.find(item => item?.dealershipCity === e.target.dataset.id)
      const actualArrDealerships = valuesData.filter(dealership => dealership.dealershipCity === value.dealershipCity)
      dispatch({ type: CREDIT_STANDALONE.SET_DEALERSHIPS, dealerships: actualArrDealerships, calcType})
      setValue(fieldId, value.id)
      onChange(value)
      currentRef.current.value = value?.dealershipCity
    }

    dispatch(setCreditOrLeasingStandaloneActiveDealership({ calcType, dealership: {} }))
    dispatch(setCarModel(null))

    nextRef.current.value = ''
    setTimeout(() => nextRef.current.focus(), 150)
  }

  const handleSelectChangeKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSelectChange(e)
    }

    // выделить следующий элемент
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'Tab') {
      e.preventDefault()

      const nextElement = e.target?.nextElementSibling

      if (!nextElement) {
        const firstElement = e.target?.parentElement.firstElementChild
        firstElement.focus()
        return
      }

      nextElement.focus()
    }

    // выделить предыдущий элемент
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()

      const previousElement = e.target?.previousElementSibling

      if (!previousElement) {
        const lastElement = e.target?.parentElement.lastElementChild
        lastElement.focus()
        return
      }

      previousElement.focus()
    }
  }

  const filterValues = useCallback(
    newValue => {
      const filteredValues = data.filter(value => value?.name.toLowerCase().includes(newValue.toLowerCase().trim()))
      setValuesData(filteredValues)
    },
    [data],
  )

  // TODO: remove
  const handleSelectOpen = () => setOpen(open => !open)

  const handleOutsideClick = event => {
    if (select.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleOutsideKey = useCallback(
    event => {
      if (event.key === 'Escape') {
        setOpen(false)
        currentRef.current.blur()
      }
    },
    [currentRef],
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [open, handleOutsideKey])

  useEffect(() => {
    if (open) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [open])

  const renderValuesList = () => {
    if (!valuesData.length) {
      return (
        <li className={s.item} data-status="not-found">
          {t('ccS.conditions.notFound') || 'Ничего не найдено'}
        </li>
      )
    }

    let cities = valuesData.map(value => value.dealershipCity).filter((v, i, a) => a.indexOf(v) === i)

    return cities.map(value => {
      return (
        <li
          className={s.item}
          onClick={handleSelectChange}
          onKeyDown={handleSelectChangeKey}
          key={value?.id || value?.widgetId || value}
          tabIndex={0}
          data-id={value}
        >
          {value}
        </li>
      )
    })
  }

  return (
    <InputBox labelText={labelText} id={fieldId} tip={tip} wide={wide}>
      <div className={s.box} ref={select} data-project-name={getProjectName()}>
        <input
          autoComplete="off"
          className={s.hidden_input}
          name={fieldId}
          ref={e => {
            hiddenInputRef.current = e
            register(e)
          }}
          onFocus={() => {
            currentRef.current.focus()
            setOpen(true)
          }}
        />
        <input
          ref={currentRef}
          className={s.input}
          id={fieldId}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyDown={handleInputChangeKey}
          onFocus={handleInputFocus}
          placeholder={placeholder}
          data-open={open}
          data-wide={wide}
        />
        <div
          className={s.arrow}
          onClick={disabled ? null : handleSelectOpen}
          data-wide={wide}
          data-disabled={disabled}
        />
        <ul className={s.list} data-open={open} ref={dropdown}>
          {renderValuesList()}
          <li className={s.item} onClick={handleSelectChange} onKeyDown={handleSelectChangeKey} tabIndex={0}>
            {t('ccS.dealershipsCity.any') || 'Любой'}
          </li>
        </ul>
      </div>
    </InputBox>
  )
}

function mapStateToProps(state, ownProps) {
  const { dataType } = ownProps

  if (dataType === 'dealershipsCity')
    return {
      data: ownProps.isLeasing ? state.credit_standalone.city.leasing : state.credit_standalone.city.credit,
    }
}

export default connect(mapStateToProps)(SelectCity)
