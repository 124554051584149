export const detectUrlHelper = (preset, detail, listVersion) => {
  if (listVersion === '2') {
    return detail.link
  }

  if (detail?.link) {
    if (typeof detail.link === 'string') {
      return detail.link
    } else if (typeof detail.link === 'function') {
      return detail.link()
    }
  }

  if (detail?.url) {
    if (typeof detail.url === 'string') {
      return detail.url
    } else if (typeof detail.url === 'function') {
      return detail.url()
    }
  }

  return window.location.href
}
