import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { setTradeInGearBox, toggleTradeInStep } from '../../../store/actions'
import TradeInRadio from './Helpers/Radio'

function TradeInGearBox({ step, name, gearboxes, id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInGearBox({ id, name }))
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'gearbox' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('gearbox'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.gearbox.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.gearbox.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInRadio name="gearbox" variants={gearboxes} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      gearbox: { name, id },
    },
    dictionaries: { gearboxes },
  },
}) {
  return {
    step,
    name,
    id,
    gearboxes,
  }
}

export default connect(mapStateToProps)(TradeInGearBox)
