import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formID } from '../../Conditions/formID'
import { InputBox } from '../Common'
import s from './select.module.scss'

const Select = ({
  dependency,
  disabled,
  cars,
  labelText,
  placeholder,
  fieldId,
  onChange,
  selectCar,
  register,
  setValue,
  inputRef,
  nextRef,
}) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [carsData, setCarsData] = useState(cars)

  const select = useRef()
  const dropdown = useRef()
  const hiddenInputRef = useRef()

  useEffect(() => {
    if (!dependency?.id) {
      inputRef.current.value = ''
      onChange(null)
    }
  }, [dependency, inputRef, onChange])

  const filter = newValue => {
    const key = fieldId.substring(6)
    const filteredCars = cars.filter(car => car[key].toLowerCase().includes(newValue.toLowerCase().trim()))
    setCarsData(filteredCars)
  }

  const handleInputChange = e => {
    filter(e.target.value)

    if (!open) {
      setOpen(true)
    }
  }

  const handleInputFocus = e => {
    setOpen(true)

    e.target.value = ''
    hiddenInputRef.current.value = ''
    filter('')
    onChange(null)
    selectCar(null)
  }

  const handleInputChangeKey = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()

      if (!open) {
        setOpen(true)
      }

      const dropdownList = select.current.lastElementChild
      const dropdownItem = dropdownList.firstElementChild

      if (!dropdownItem) {
        return
      }

      dropdownItem.focus()
    }
  }

  const handleSelectChange = e => {
    setOpen(false)

    const car = carsData.find(car => car.id === e.target.dataset.id)
    setValue(fieldId, e.target.dataset.id)
    onChange(car)

    if (fieldId === formID.brand) {
      inputRef.current.value = car.brand
    }

    if (fieldId === formID.model) {
      inputRef.current.value = car.model
    }

    setTimeout(() => nextRef.current.focus(), 150)
  }

  const handleSelectChangeKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()

      setOpen(false)

      const car = carsData.find(car => car.id === e.target.dataset.id)

      setValue(fieldId, e.target.dataset.id)
      onChange(car)

      if (fieldId === formID.brand) {
        inputRef.current.value = car.brand
      }

      if (fieldId === formID.model) {
        inputRef.current.value = car.model
      }

      setTimeout(() => nextRef.current.focus(), 150)
    }

    // выделить следующий элемент
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'Tab') {
      e.preventDefault()

      const nextElement = e.target?.nextElementSibling

      if (!nextElement) {
        const firstElement = e.target?.parentElement.firstElementChild
        firstElement.focus()
        return
      }

      nextElement.focus()
    }

    // выделить предыдущий элемент
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()

      const previousElement = e.target?.previousElementSibling

      if (!previousElement) {
        const lastElement = e.target?.parentElement.lastElementChild
        lastElement.focus()
        return
      }

      previousElement.focus()
    }
  }

  const handleSelectOpen = () => setOpen(open => !open)

  const handleOutsideClick = event => {
    if (select.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleOutsideKey = useCallback(
    event => {
      if (event.key === 'Escape') {
        setOpen(false)
        inputRef.current.blur()
      }
    },
    [inputRef],
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [open, handleOutsideKey])

  useEffect(() => {
    if (open) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [open])

  const renderCarList = () => {
    if (!carsData.length) {
      return (
        <li className={s.item} data-status="not-found">
          {t('ccS.conditions.notFound')}
        </li>
      )
    }

    const renderText = car => {
      if (fieldId === formID.brand) return car.brand
      if (fieldId === formID.model) return car.model
      return ''
    }

    let brandFlags = {}

    const handleBrands = car => {
      if (brandFlags[car.brand]) return false
      brandFlags[car.brand] = true
      return true
    }

    const handleModels = car => {
      if (car.brand === dependency?.brand) return true
      return false
    }

    const carsToShow = carsData.filter(car => {
      if (fieldId === formID.brand) return handleBrands(car)
      if (fieldId === formID.model) return handleModels(car)
      return false
    })

    return carsToShow.map(car => {
      return (
        <li
          className={s.item}
          onClick={handleSelectChange}
          onKeyDown={handleSelectChangeKey}
          key={car.id}
          tabIndex={0}
          data-id={car.id}
        >
          {renderText(car)}
        </li>
      )
    })
  }

  return (
    <InputBox labelText={labelText} id={fieldId}>
      <div className={s.box} ref={select}>
        <input
          className={s.hidden_input}
          name={fieldId}
          ref={e => {
            hiddenInputRef.current = e
            register(e)
          }}
          onFocus={() => inputRef.current.focus()}
        />
        <input
          ref={inputRef}
          className={s.input}
          id={fieldId}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyDown={handleInputChangeKey}
          onFocus={handleInputFocus}
          placeholder={placeholder}
          disabled={disabled || (fieldId === formID.model && dependency === null)}
        />
        <div className={s.arrow} onClick={disabled ? null : handleSelectOpen} data-disabled={disabled} />
        <ul className={s.list} data-open={open} ref={dropdown}>
          {renderCarList()}
        </ul>
      </div>
    </InputBox>
  )
}

export default Select
