import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { setTradeInModel, toggleTradeInStep } from '../../../store/actions'
import TradeInInputWithRadio from './Helpers/InputWithRadio'

function TradeInModel({ step, name, models, id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInModel({ id, name }))
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'model' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('model'))
        }}
        data-project-name={getProjectName()}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.model.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.model.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInInputWithRadio name="model" variants={models} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      model: { name, id },
    },
    dictionaries: { models },
  },
}) {
  return {
    step,
    name,
    id,
    models,
  }
}

export default connect(mapStateToProps)(TradeInModel)
