import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { setTradeInColor, toggleTradeInStep } from '../../../store/actions'
import TradeInRadio from './Helpers/Radio'

function TradeInColor({ step, id, name, colors }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInColor({ id, name }))
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'color' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('color'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.color.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInRadio name="color" variants={colors} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      color: { id, name },
    },
    dictionaries: { colors },
  },
}) {
  return {
    step,
    id,
    name,
    colors,
  }
}

export default connect(mapStateToProps)(TradeInColor)
