export const periodsFormatter = data => {
  let periodsForStore = []
  let numericPeriods = []
  if (data.oneYear) {
    let value = data.oneYear && typeof data.oneYear !== "boolean"
    periodsForStore.push({
      text: 'oneYear',
      value: value ? Number(data.oneYear) : 12,
    })
    numericPeriods.push(value ? Number(data.oneYear) : 12)
  }
  if (data.twoYears) {
    periodsForStore.push({
      text: 'twoYears',
      value: 24,
    })
    numericPeriods.push(24)
  }
  if (data.threeYears) {
    periodsForStore.push({
      text: 'threeYears',
      value: 36,
    })
    numericPeriods.push(36)
  }
  if (data.fourYears) {
    periodsForStore.push({
      text: 'fourYears',
      value: 48,
    })
    numericPeriods.push(48)
  }
  if (data.fiveYears) {
    periodsForStore.push({
      text: 'fiveYears',
      value: 60,
    })
    numericPeriods.push(60)
  }
  if (data.sixYears) {
    periodsForStore.push({
      text: 'sixYears',
      value: 72,
    })
    numericPeriods.push(72)
  }
  if (data.sevenYears) {
    periodsForStore.push({
      text: 'sevenYears',
      value: 84,
    })
    numericPeriods.push(84)
  }
  if (data.eightYears) {
    periodsForStore.push({
      text: 'eightYears',
      value: 96,
    })
    numericPeriods.push(96)
  }

  return {
    periodsForStore,
    numericPeriods,
  }
}
