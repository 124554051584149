import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { formID } from '../../Conditions/formID'
import Select from './Select'

const DoubleSelect = ({ cars, selectCar, register, setValue, nextRef, modelRef, brandRef, disabled }) => {
  const { t } = useTranslation()

  const [brandData, setBrandData] = useState(null)
  const [modelData, setModelData] = useState(null)

  useEffect(() => {
    if (brandData !== null && modelData !== null) {
      const selectedCar = cars.find(car => car.id === modelData.id)
      selectCar(selectedCar)
    }
  }, [brandData, modelData, cars, selectCar])

  return (
    <>
      <Select
        onChange={setBrandData}
        selectCar={selectCar}
        cars={cars}
        labelText={t('ccS.conditions.brand')}
        placeholder={t('ccS.conditions.chooseBrand')}
        fieldId={formID.brand}
        register={register}
        setValue={setValue}
        inputRef={brandRef}
        nextRef={modelRef}
        disabled={disabled}
      />
      <Select
        onChange={setModelData}
        selectCar={selectCar}
        cars={cars}
        labelText={t('ccS.conditions.model')}
        placeholder={t('ccS.conditions.chooseModel')}
        fieldId={formID.model}
        register={register}
        setValue={setValue}
        inputRef={modelRef}
        nextRef={nextRef}
        dependency={brandData}
        disabled={disabled}
      />
    </>
  )
}

const mapStateToProps = state => ({ cars: state.credit_standalone.conditions.cars })

export default connect(mapStateToProps)(DoubleSelect)
