function DropdownArrow() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 14.172L16.95 9.22205L18.364 10.636L12 17L5.63599 10.636L7.04999 9.22205L12 14.172Z"
            fill="#222222"
        />
      </svg>
    </>
  )
}

export default DropdownArrow
