import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { FINANCE_USER_BEHAVIOUR, TRADE_IN_USER_BEHAVIOUR } from '../../../constants'
import { carPriceFromTI, formatCurrency, totalPayment } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import CarPrice from '../../Ui/carPrice'
import s from './car.module.scss'

function Car({
  price,
  name,
  image,
  paymentAmount,
  paymentTitle,
  areTwoPricesHidden,
  currency,
  priceToolTipEnabled,
  vehicleType,
  isPriceHidden,
}) {
  const { t } = useTranslation()

  const prices = () => {
    if (price === paymentAmount || areTwoPricesHidden) {
      return (
        <div className={s.prices}>
          <div className={s.prices__item}>
            <CarPrice
              tooltipContent={priceToolTipEnabled ? t(`car.tooltipAlert.${vehicleType}`) : false}
              type="bordered"
              text={t(`car.initialPrice.${vehicleType}`)}
              price={price}
              currency={currency}
              strikethrough={false}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={s.prices}>
          <div className={s.prices__item}>
            <CarPrice
              tooltipContent={priceToolTipEnabled ? t(`car.tooltipAlert.${vehicleType}`) : false}
              type="bordered"
              text={t(`car.initialPrice.${vehicleType}`)}
              price={price}
              currency={currency}
              strikethrough={true}
            />
          </div>
          <div className={s.prices__item}>
            <CarPrice
              tooltipContent={false}
              type="filled"
              text={t(paymentTitle)}
              price={paymentAmount}
              currency={currency}
              strikethrough={false}
            />
          </div>
        </div>
      )
    }
  }

  const formattedName = () => {
    if (name) {
      return name.length > 57 ? `${name.substr(0, 57)}...` : name
    }

    return t(`car.title.${vehicleType}`)
  }

  const defaultCarImg = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 499.2 167.9">
      <path
        fill="#C9C9C9"
        d="M417,120.7c7.5,0,13.6,6.1,13.6,13.6s-6.1,13.6-13.6,13.6s-13.6-6.1-13.6-13.6S409.5,120.7,417,120.7
		 M417,100.7c-18.5,0-33.6,15-33.6,33.6s15,33.6,33.6,33.6s33.6-15,33.6-33.6S435.5,100.7,417,100.7L417,100.7z"
      />
      <path
        fill="#C9C9C9"
        d="M91,120.8c7.5,0,13.6,6.1,13.6,13.6c0,7.5-6.1,13.6-13.6,13.6s-13.6-6.1-13.6-13.6
		C77.4,126.9,83.5,120.8,91,120.8 M91,100.8c-18.5,0-33.6,15-33.6,33.6s15,33.6,33.6,33.6s33.6-15,33.6-33.6S109.5,100.8,91,100.8
		L91,100.8z"
      />
      <path
        fill="#C9C9C9"
        d="M43.6,143.7c-0.2-3.8-0.8-20.9,11.7-35.5c2.5-2.9,15-17.6,35.6-17.2c1.9,0,22.7,0.8,35.2,18.8
	c10.4,15,7.4,31.1,6.8,34l242.8,0.5c-0.5-1.9-5.4-22.5,8.7-38.5C396.8,91.8,414.1,92,417.8,92c4.1,0,20.6,0.5,32.6,14
	c13.8,15.6,9.9,35.4,9.4,37.6H491c0,0,15.1-11.3,4.3-57.1c0,0-18.3,2.7-13.5-25.3c0,0-93.1-54.9-193.3-60.8c0,0-86.7-8.6-155.1,47.4
	c0,0-103.9-5.4-133,37.7c0,0,7,16.2-0.5,52.2L43.6,143.7z M326.9,22.6c0,0,65.1,11.6,101.6,31.3H326.9V22.6z M151.7,53.9
	c0,0,54.5-45.6,153.4-35l-0.3,35H151.7z M3,86.9c0,0,9.2-17.4,40.1-26.6C43.2,60.3,47.4,87.1,3,86.9z"
      />
    </svg>
  )

  return (
    <div className={s.wrapper} data-project-name={getProjectName()}>
      {image && (
        <div className={s.wrapper__img}>
          <div className={s.main__name}>{formattedName()}</div>
          {image ? <img src={image} alt="SmartContract" title="SmartContract" className={s.carImage} /> : defaultCarImg}
        </div>
      )}
      <div className={[s.wrapper__info, s.info].join(' ')}>
        <div className={s.info__main}>
          <div className={s.main}>
            <div className={s.main__name}>{formattedName()}</div>
          </div>
        </div>
        {!isPriceHidden && <div className={s.info__prices}>{prices()}</div>}
      </div>
    </div>
  )
}

function mapStateToProps({
  car: { price, name, image },
  tradeIn: {
    ownerCarPriceEnabled,
    ownerCarPrice,
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    behaviourByUser: tradeInBehaviourByUser,
    rate: {
      result: { possibleCarPrice },
    },
  },
  credit: { behaviourByUser: creditBehaviourByUser, benefitValue: creditBenefitAmount, parameters: creditParameters },
  leasing: { benefitValue: leasingBenefitAmount, parameters: leasingParameters },
  benefits: { selected },
  accessories: { selected: selectedAccessories },
  settings: {
    currency,
    activeDealership,
    isWidgetLoaded,
    dealerships,
    priceToolTipEnabled,
    vehicleType,
    taxes,
    visual: { isPriceHidden },
  },
}) {
  const tradeInEnabledByUser = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const creditEnabledByUser = creditBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED
  const leasingEnabledByUser = creditBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_APPLIED

  const [paymentAmount, paymentTitle] = totalPayment({
    price,
    taxes,
    creditEnabledByUser,
    creditBenefitAmount,
    possibleCarPrice: carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled),
    creditParameters,
    selected,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    tradeInEnabledByUser,
    tradeInDisabledByUser: tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED,
    leasingEnabledByUser,
    leasingBenefitAmount,
    leasingParameters,
    selectedAccessories,
  })

  const areTwoPricesHidden = () => {
    if (dealerships.length < 2) {
      return false
    } else {
      return !(activeDealership.widgetId && isWidgetLoaded)
    }
  }

  return {
    paymentTitle,
    name,
    price,
    paymentAmount,
    image,
    currency: currency,
    areTwoPricesHidden: areTwoPricesHidden(),
    priceToolTipEnabled,
    vehicleType,
    isPriceHidden,
  }
}

export default connect(mapStateToProps)(Car)
