import _cloneDeep from 'lodash/cloneDeep'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TRADE_IN_OWNERS } from '../../../../constants'
import { setTradeInOwner } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneOwnerDropdown({ id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInOwner({ id, name }))
  }

  const translatedOwner = () => {
    const original = _cloneDeep(TRADE_IN_OWNERS)

    for (const key in original) {
      if (!original.hasOwnProperty(key)) {
        continue
      }

      original[key].name = t(`tradeIn.owner.names.${original[key].name}`)
    }

    return original
  }

  return (
    <TradeInStandalonePartsRadiobuttons
      name="owner"
      variants={translatedOwner()}
      checked={id}
      onSet={onSet}
      search={''}
    />
  )
}

function mapStateToProps({
  tradeIn: {
    values: {
      owner: { id },
    },
  },
}) {
  return {
    id,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneOwnerDropdown)
