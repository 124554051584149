import { COUNTRY_CODES } from '../constants'
import { setPhoneCountry } from '../store/actions'
import store from '../store/store'

export const formatPossibleCountryCodes = ({ defaultCountryCode, possibleCountryCodes }) => {
  // если с БЭ пришли кривые данные для номеров телефона то ставим российский номер

  let formattedDefaultCountryCode, formattedPossibleCountryCodes

  if (defaultCountryCode && possibleCountryCodes && possibleCountryCodes.includes(defaultCountryCode)) {
    formattedDefaultCountryCode = defaultCountryCode
    formattedPossibleCountryCodes = possibleCountryCodes
  }

  if (!formattedPossibleCountryCodes || formattedPossibleCountryCodes?.length === 0) {
    formattedPossibleCountryCodes = Object.keys(COUNTRY_CODES)
  }

  if (!formattedDefaultCountryCode) {
    formattedDefaultCountryCode = 'russia'
  }

  return {
    formattedDefaultCountryCode,
    formattedPossibleCountryCodes,
  }
}

// проверка, что данные в локалсторадже вообще нормальные
export const isCountryCodeAvailableToAdd = countryLS => {
  const allPossibleCountryCodes = Object.keys(COUNTRY_CODES)
  return allPossibleCountryCodes.includes(countryLS)
}

// при переключении ДЦ добавлять текущий код в новый дц, если его там нет
export const countryCodesComparison = (dealershipData, country) => {
  if (isCountryCodeAvailableToAdd(country)) {
    dealershipData.possibleCountryCodes.push(country)
  } else {
    let { formattedDefaultCountryCode, formattedPossibleCountryCodes } = formatPossibleCountryCodes({
      defaultCountryCode: dealershipData?.defaultCountryCode,
      possibleCountryCodes: dealershipData?.possibleCountryCodes,
    })

    store.dispatch(setPhoneCountry(formattedDefaultCountryCode))
  }

  return dealershipData
}
