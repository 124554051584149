import i18n from '../../../i18n'
import { deprecatedButtonTextHelper } from '../..'
import { formatPrice } from '../../format_price'

export const buttonTextHelper = (visual, currency, status, config, vehicleType) => {
  const {
    buttons: {
      openWidget: { placeholders, strict, text, text_reserved, text_sold, text_wait },
    },
  } = visual

  window.scLogger.log('[buttonTextHelper::placeholders]', placeholders)

  const replacePlaceholders = (text, status, vehicleType, isPriceHidden) => {
    if (isPriceHidden && placeholders) {
      return i18n.t(`car_statuses.${status}.detail_text.${vehicleType}`)
    }

    if (placeholders) {
      // eslint-disable-next-line no-template-curly-in-string
      return text.replace('${credit}', formatPrice(placeholders['${credit}'], currency) + `/${i18n.t('months')}`)
    } else {
      // eslint-disable-next-line no-template-curly-in-string
      return text.includes('${credit}') ? i18n.t(`car_statuses.${status}.detail_text.${vehicleType}`) : i18n.t(text)
    }
  }

  switch (status) {
    case 'wait':
      return replacePlaceholders(text_wait, status, vehicleType, visual.isPriceHidden)

    case 'reserved':
      return replacePlaceholders(text_reserved, status, vehicleType, visual.isPriceHidden)

    case 'sold':
      return replacePlaceholders(text_sold, status, vehicleType, visual.isPriceHidden)

    case 'available':
      if (strict) {
        return replacePlaceholders(text, status, vehicleType, visual.isPriceHidden)
      }

      return replacePlaceholders(deprecatedButtonTextHelper(config) || text, status, vehicleType, visual.isPriceHidden)

    default:
      return replacePlaceholders(text, status, vehicleType, visual.isPriceHidden)
  }
}
