import { connect, useDispatch } from 'react-redux'

import { setTradeInCityType, setTradeInStandaloneTypedValue } from '../../../../store/actions'
import TradeInStandalonePartsInput from '../../parts/input'

function TradeInStandaloneCityInput({ typedValue, focus, cityType }) {
  const dispatch = useDispatch()

  const onSet = value => {
    if (cityType !== '') {
      dispatch(setTradeInCityType(''))
    }
    dispatch(
      setTradeInStandaloneTypedValue({
        name: 'city',
        value,
      }),
    )
  }

  return <TradeInStandalonePartsInput name="city" onSetValue={onSet} value={typedValue} focus={focus} />
}

function mapStateToProps({
  tradeIn: {
    values: {
      city: { name: dictionaryValue },
    },
    typedValues,
    cityType,
  },
}) {
  // чтобы не было бага в локалсторадж
  const typedValue = () => {
    if (typedValues && typedValues.city) {
      return typedValues.city
    } else {
      return ''
    }
  }

  return {
    dictionaryValue,
    typedValue: typedValue(),
    cityType,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneCityInput)
