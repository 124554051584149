import { useTranslation } from 'react-i18next'

import { availableCarsCountInDealership } from '../../../helpers'
import s from './list.module.scss'

function DealershipsList({ dealerships, handleClick, activeDealership, areCarsReal, useWidgetStyles }) {
  const { t } = useTranslation()

  const labelData = item => {
    let res = {
      text: '',
      isDisabled: '',
    }

    if (areCarsReal) {
      if (activeDealership.widgetId === item.widgetId) {
        return {
          text: t('dealerships.selected'),
          isDisabled: false,
        }
      }

      if (availableCarsCountInDealership(item)) {
        return {
          text: `${t('dealerships.available')} ${availableCarsCountInDealership(item)}`,
          isDisabled: false,
        }
      } else {
        return {
          text: t('dealerships.booked'),
          isDisabled: false,
        }
      }
    } else {
      if (activeDealership.widgetId === item.widgetId) {
        return {
          text: t('dealerships.selected'),
          isDisabled: false,
        }
      }
    }

    return res
  }

  return (
    <div className={s.list}>
      {dealerships.map(item => {
        return (
          <div
            className={[
              s.list__item,
              activeDealership?.widgetId === item.widgetId ? s.list__item_active : '',
              labelData(item)?.isDisabled ? s.list__item_disabled : '',
            ].join(' ')}
            key={item.widgetId}
            onClick={() => handleClick(item)}
          >
            <div className={[s.dealership, useWidgetStyles ? s.dealershipInWidget : ''].join(' ')}>
              <div className={s.dealership__info}>
                <div className={s.name}>{item.type === 'dealership' ? item.dealershipName : item.clientName}</div>
                <div className={s.address}>{item.type === 'dealership' ? item.dealershipAddress : item.widgetName}</div>
              </div>
              <div className={s.dealership__label}>
                {labelData(item)?.text && (
                  <div
                    className={[s.label, activeDealership?.widgetId === item.widgetId ? s.label_active : ''].join(' ')}
                  >
                    {labelData(item)?.text}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DealershipsList
