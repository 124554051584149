import { SCREEN_INDEX } from '../../constants'

const INIT_STATE = {
  screen: SCREEN_INDEX,
  visible: false,
  loading: false,

  preventBack: {
    onlinePayment: false,
    tradeIn: false,
    credit: false,
  },

  carOrderPopupVisibility: false,
  formState: 'form', // loading, error, success,
  widgetOpenStart: 0,
}

const screen = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GO_TO':
      return {
        ...state,
        screen: action.screen,
        preventBack: {
          onlinePayment: false,
          tradeIn: false,
          credit: false,
        },
      }
    case 'PREVENT_BACK_FROM_ONLINE_PAYMENT':
      return {
        ...state,
        preventBack: {
          ...state.preventBack,
          onlinePayment: true,
        },
      }

    case 'PREVENT_BACK_FROM_TRADE_IN':
      return {
        ...state,
        preventBack: {
          ...state.preventBack,
          tradeIn: true,
        },
      }

    case 'PREVENT_BACK_FROM_CREDIT':
      return {
        ...state,
        preventBack: {
          ...state.preventBack,
          credit: true,
        },
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      }
    case 'SET_WIDGET_VISIBILITY':
      return {
        ...state,
        visible: action.visibility,
      }

    case 'SET_WIDGET_OPEN_START':
      return {
        ...state,
        widgetOpenStart: action.payload,
      }

    case 'SET_CAR_ORDER_POPUP_VISIBILITY':
      return {
        ...state,
        carOrderPopupVisibility: action.visibility,
      }

    case 'SET_FORM_STATE':
      return {
        ...state,
        formState: action.payload,
      }

    default:
      return state
  }
}

export default screen
