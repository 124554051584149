import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { formName } from '../../carOrderForm/main/helpers'
import { ACTION_TYPES, FORM_SOURCE, FORM_TYPE_BASE, SCREEN_ERROR, SCREEN_INDEX, SCREEN_SUCCESS } from '../../constants'
import {
  approveCreditOnline,
  clearProtections,
  clearSmsAndCaptcha,
  createdEventPayload,
  createSmartContractWithProtection,
  initializeErrorsProcessing,
} from '../../helpers'
import {
  goToScreen,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  smartContractCreated,
  startLoading,
  stopLoading,
} from '../../store/actions'
import { createSmartContractData } from '../../store/helpers/smart_contract'
import Form from '../Parts/FormNew/Form'
import Button from '../Ui/button'

function AuthScreen({ widget_id, form, fields, data_before_auth }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const config = {
    source: FORM_SOURCE.WIDGET_AUTH,
  }

  const dataToSave = useSelector(state => createSmartContractData(state))

  /**
   * Нажатие по кнопке submit создание СК
   */
  const submitForm = () => {
    window.dispatchEvent(new CustomEvent(formName(config).clickOutsideForm))
  }

  const listener = useCallback(
    e => {
      if (!e?.detail?.isFromDataValid) {
        return false
      }

      switch (data_before_auth.previousAction) {
        case ACTION_TYPES.CREATE_SMART_CONTRACT:
          dispatch(startLoading())

          createSmartContractWithProtection(
            widget_id,
            {
              ticket_answer: form.values.captcha || form.values.smsCode,
              ticket_id:
                form.captchaSettings[FORM_SOURCE.WIDGET].ticketId || form.smsCodeSettings[FORM_SOURCE.WIDGET].ticketId,
            },
            JSON.stringify({ data: dataToSave }),
          )
            .then(({ data }) => {
              if (data.id) {
                dispatch(setSmartContractUrl(data.smartContractUrl))
                dispatch(setSmartContractId(data.id))
                dispatch(setSmartContractGenericId(data.genericId))
                dispatch(setSmartContractToken(data.token))
                dispatch(setSmartContractType('widget'))
                dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSave, data)))
                clearSmsAndCaptcha()
                clearProtections(FORM_SOURCE.WIDGET)

                dispatch(goToScreen(SCREEN_SUCCESS))
              } else {
                dispatch(goToScreen(SCREEN_ERROR))
              }
            })
            .catch(e => {
              clearProtections(FORM_SOURCE.WIDGET)
              initializeErrorsProcessing({ e: e, formSource: FORM_SOURCE.WIDGET })
            })
            .finally(() => {
              dispatch(stopLoading())
            })

          break

        case ACTION_TYPES.SET_PERSONAL_DATA:
          dispatch(goToScreen(data_before_auth.previousScreen))
          break

        case ACTION_TYPES.SET_CREDIT_PROGRAM:
          dispatch({
            type: 'SET_ACTIVE_PROGRAM',
            active_program: data_before_auth.additionalData,
          })
          dispatch(goToScreen(data_before_auth.previousScreen))
          break

        case ACTION_TYPES.RATE_TRADE_IN:
          dispatch(goToScreen(data_before_auth.previousScreen))
          break

        case ACTION_TYPES.ENABLE_BENEFIT:
          dispatch({
            type: 'ENABLE_BENEFIT',
            benefit: data_before_auth.additionalData,
          })
          dispatch(goToScreen(data_before_auth.previousScreen))
          break

        case ACTION_TYPES.ONLINE_APPROVAL:
          approveCreditOnline(dispatch, widget_id, dataToSave)

          break

        default:
          dispatch(goToScreen(SCREEN_INDEX))
          break
      }
    },
    [dataToSave, data_before_auth, dispatch, widget_id],
  )

  /**
   * Слушаем данные из формы
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickInsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickInsideForm, listener)
    }
  }, [listener])

  return (
    <div className="kdxsc-widget-state-auth-wrapper">
      <div className="kdxsc-widget-state-auth">
        <div className="kdxsc-widget-state-auth__text">{t('auth.enterPersonalDataBeforeContinue')}</div>

        <div className="kdxsc-widget-state-auth__form">
          <Form key={'widgetAuthScreenForm'} type={FORM_TYPE_BASE} config={config} />
        </div>

        <div className="kdxsc-widget-state-auth__button kdxsc-mt-24">
          <Button handleClick={submitForm} text={t('auth.continue')} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  form,
  settings: {
    widget_id,
    visual: { fields },
  },
  data_before_auth,
}) {
  return {
    widget_id,
    form,
    fields,
    data_before_auth,
  }
}

export default connect(mapStateToProps)(AuthScreen)
