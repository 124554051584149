module.exports = {
  state: {
    subtitleWithBenefit: 'В лизинг с преимуществом',
    subtitleWithoutBenefit: 'Покупка в лизинг',
    initialAdditionalText: 'Выберите лизинговую программу',
    initialAdditionalTextWithoutEstimation: 'Выберите параметры лизинга',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } мес.',
    additionalTextWithCreditDataWithoutRate: '{ bank }. { program }, { period } мес.',
    additionalTextWithCreditDataWithoutRateAndBankName: '{ program }, { period } мес.',
    additionalTextWithCreditDataWithoutBankName: '{ program }, { rate }%, { period } мес.',
    additionalTextWithCreditParameters: 'Вы выбрали лизинг с авансовым платежом { initialFee } на срок { period } мес.',
    autoEstimationIsImpossible: 'Автоматическая оценка невозможна',
    unavailable: 'Невозможно',
    benefitHint: {
      auto: 'Это преимущество применяется в случае покупки данного автомобиля в лизинг',
      moto: 'Это преимущество применяется в случае покупки данного мотоцикла в лизинг',
      other: 'Это преимущество применяется в случае покупки данного ТС в лизинг',
    },
  },

  screen: {
    title: 'Параметры лизинга на сумму { amount } ',
    subtitle: 'Ваша выгода за лизинг { value } уже учтена',
    chooseCreditProgram: 'Выберите лизинговую программу (найдено { count })',
    term: 'Срок лизинга',
    carPrice: {
      auto: 'Стоимость автомобиля с учетом выгод и услуг',
      moto: 'Стоимость мотоцикла с учетом выгод и услуг',
      other: 'Стоимость ТС с учетом выгод и услуг',
    },
    initialFee: 'Аванс',
    buybackPayment: 'Стоимость выкупа',

    withoutCalculations: {
      title: 'Вы можете выбрать оптимальные для вас условия лизинга',
      button: 'Применить',
      body: 'К сожалению, автоматический расчет программ лизинга недоступен, но введенные вами данные будут переданы менеджеру для формирования предложения',
    },

    noPrograms: {
      title: 'Пожалуйста, выберите другие параметры кредитной программы для расчета',
      body: '',
    },

    error: {
      title: 'Пожалуйста, выберите другие параметры кредитной программы для расчета',
      body: '',
    },
  },
}
