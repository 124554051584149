import _flattenDeep from 'lodash/flattenDeep'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { findModelFromList } from '../../../carOrderForm/main/helpers'
import { FORM_SOURCE } from '../../../constants'
import Dropdown from '../FormNew/dropdown/Dropdown'
import Models from '../Models/Models'

function ModelsDropdown({ activeModel, models, canModelBeChosen, selectActiveModel, config, errors, errorsText }) {
  const { t } = useTranslation()

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false)
  const [availableModels, setAvailableModels] = useState(models)

  /**
   * Устнавливаем модель по умолчанию, если она есть
   * Проверяем, можно ли дизэйблить инпут
   */
  useEffect(() => {
    if (!canModelBeChosen) {
      setIsDropdownDisabled(true)
    } else {
      setIsDropdownDisabled(false)
    }

    // если есть в конфиге availableModels (модели, которые могут быть показы в форме)
    // по умолчанию - все модели с БЭ
    let filteredAvailableModels = models
    if (config?.availableModels?.length) {
      const flatAvailableModels = _flattenDeep(config.availableModels)

      // модель по умолчанию есть и она есть в доступных моделях (1)
      const isDefaultModelExistsAndIsPresentedInAvailableModels =
        config.defaultModel && findModelFromList(flatAvailableModels, config)
      //  модели по умолчанию нет (2)
      const noDefaultModel = !config.defaultModel

      // можно отфильтровать модели только если выполняются условия (1) или (2)
      if (isDefaultModelExistsAndIsPresentedInAvailableModels || noDefaultModel) {
        filteredAvailableModels = []
        flatAvailableModels.forEach(flatAvailableModel => {
          // имитация config.defaultModel,чтоб можно было пользоваться той же функцией поиска
          let c = {
            defaultModel: flatAvailableModel,
          }
          // находим допустимые модели
          const availableModel = findModelFromList(models, c)
          // добавляем в массив
          if (availableModel) {
            filteredAvailableModels.push(availableModel)
          }
        })
        // если массив допустимых моделей есть, то в стэйт его
        if (filteredAvailableModels?.length) {
          setAvailableModels(filteredAvailableModels)
        }
      }
    }

    if (config.defaultModel && canModelBeChosen) {
      const modelFromList = findModelFromList(filteredAvailableModels, config)

      if (modelFromList) {
        selectActiveModel(modelFromList)
      }

      // нельзя запретить выбор модели, если ее нет в списке
      if (config.disableModelSelection) {
        setIsDropdownDisabled(Boolean(modelFromList))
      }
    }
    // selectActiveModel не добавлять в зависимости
    // availableModels обращаться тут нельзя и добавлять в зависимости тоже нельзя
  }, [canModelBeChosen, config, models])

  return (
    <>
      <Dropdown
        id={`${config.source}Models`}
        name={`${config.source}Models`}
        label={t('carOrderForm.model')}
        value={activeModel?.attributes?.name}
        variants={<Models models={availableModels} handleClick={selectActiveModel} activeModel={activeModel} />}
        required={true}
        errors={errors}
        errorsText={errorsText}
        disabled={isDropdownDisabled}
      />
    </>
  )
}

function mapStateToProps(state, { config }) {
  let models, activeModel

  if (config.source === FORM_SOURCE.BUY_MODEL) {
    const {
      buyModelStandalone: { models: bmModels, activeModel: bmActiveModel },
    } = state

    models = bmModels
    activeModel = bmActiveModel
  }

  return {
    models,
    activeModel,
  }
}

export default connect(mapStateToProps)(ModelsDropdown)
