export const dealershipsFormatter = (cardData) => {

  let res = []

  for (let d in cardData.dealerships) {
    if (cardData.dealerships.hasOwnProperty(d)) {
      res.push({
        dealership: cardData.dealerships[d].dealership,
        clientName: cardData.dealerships[d].clientName,
        widget_name: cardData.dealerships[d].widgetName,
        widget_id: d,
        cars: cardData.cars[d],
      })
    }
  }

  return res
}
