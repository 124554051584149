import { useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'

import { COUNTRY_CODES } from '../../../constants'
import { setPhoneCountry, setPhoneValue } from '../../../store/actions/form'
import { InputBox }     from '../Common'
import s                from './styles/phone.module.scss'
import {getProjectName} from "../../../helpers/app/detectors/project_name";

const Phone = ({
  country,
  labelText,
  id,
  tip,
  value,
  formattedValue,
  control,
  register,
  inputRef,
  setValue,
  setPhoneCountry,
  setPhoneValue,
  vertical,
  possibleCountryCodes,
}) => {
  const projectName = getProjectName();
  useEffect(() => setValue(id, formattedValue), [id, setValue, formattedValue])

  // добавление в старый локалсторадж новых данных phoneSelection
  useEffect(() => {
    if (!value) {
      const localFormData = JSON.parse(localStorage.getItem('@kodix/smartContract/form'))
      const phone = localFormData?.values?.phone
      const oldData = /^(\+7)\(/.test(phone) || null

      if (oldData) {
        const initPhoneRawValue = phone.replace('+7', '').replace(/\D/g, '')
        const initPhoneValue = phone.replace('(', ' (').replace(')', ') ')
        setPhoneValue({
          phoneRawValue: initPhoneRawValue,
          phone: initPhoneValue,
        })
      }
    }
  }, [value, setPhoneValue])

  const changePhoneCountry = country => setPhoneCountry(country)

  const handleChangePhone = values => {
    const { formattedValue, value } = values
    setPhoneValue({
      phone: formattedValue,
      phoneRawValue: value,
    })
  }

  const [isOpen, setIsOpen] = useState(false)

  const select = useRef()
  const dropdown = useRef()

  const handleSelectClick = () => setIsOpen(isOpen => !isOpen)

  // закрывать дропдаун на клики за его пределами
  const handleOutsideClick = event => {
    if (dropdown.current.contains(event.target)) {
      return
    }

    if (select.current.contains(event.target)) {
      return
    }

    setIsOpen(false)
  }

  // открыть или закрыть дропдаун
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      setIsOpen(isOpen => !isOpen)
      select.current.focus()
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setIsOpen(false)
      select.current.focus()
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      event.preventDefault()

      if (!isOpen) {
        setIsOpen(true)
      }

      const dropdownList = dropdown.current.firstElementChild
      const dropdownItem = dropdownList.firstElementChild

      if (!dropdownItem) {
        return
      }

      dropdownItem.focus()
    }
  }

  const handleOutsideKey = event => {
    if (event.key === 'Escape') {
      setIsOpen(false)
      select.current.focus()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [isOpen])

  const handleDropdownClick = event => {
    changePhoneCountry(event.currentTarget.dataset.value)
    setIsOpen(false)
    select.current.focus()
  }

  // взаимодействие с элементами дропдауна
  const handleDropdownKey = event => {
    if (event.key === 'Enter') {
      changePhoneCountry(event.target.dataset.value)
      setIsOpen(false)
      select.current.focus()
    }

    // выделить следующий элемент
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight' || event.key === 'Tab') {
      event.preventDefault()

      const nextElement = event.target?.nextElementSibling

      if (!nextElement) {
        const firstElement = event.target?.parentElement.firstElementChild
        firstElement.focus()
        return
      }

      nextElement.focus()
    }

    // выделить предыдущий элемент
    if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      event.preventDefault()

      const previousElement = event.target?.previousElementSibling

      if (!previousElement) {
        const lastElement = event.target?.parentElement.lastElementChild
        lastElement.focus()
        return
      }

      previousElement.focus()
    }
  }

  useEffect(() => {
    if (isOpen) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isOpen])

  const CountryList = Object.entries(COUNTRY_CODES).map(item => {
    if (possibleCountryCodes.includes(item[0])) {
      return (
        <li
          tabIndex={0}
          key={item[1].id}
          data-value={item[0]}
          className={`${s.item} ${isOpen && s.item_open}`}
          onClick={handleDropdownClick}
          onKeyDown={handleDropdownKey}
        >
          <img className={s.flag} src={item[1].icon} alt="" />
          <span className={s.country}>{item[1].name}</span>
          <span className={s.code}>{item[1].code}</span>
        </li>
      )
    }
  })

  return (
    <InputBox labelText={labelText} id={id} tip={tip} vertical={vertical}>
      <div className={s.container} data-project-name={getProjectName()}>
        <div className={s.select_box}>
          <section className={s.select} tabIndex={0} onClick={handleSelectClick} onKeyDown={handleKeyDown} ref={select}>
            <img className={s.flag} src={COUNTRY_CODES[country || 'russia'].icon} alt="" />
            <span className={s.abbr}>{COUNTRY_CODES[country || 'russia'].abbr3}</span>
            <i className={s.arrow}></i>
          </section>
        </div>
        <div className={`${s.dropdown_box} ${isOpen && s.dropdown_box_open}`} ref={dropdown}>
          <ul className={s.dropdown}>{CountryList}</ul>
        </div>

        <div className={s.input_box}>
          <input
            className={s.hidden_input}
            name={id}
            ref={register}
            defaultValue={formattedValue}
            onFocus={() => inputRef.current.focus()}
          />
          <NumberFormat
            getInputRef={el => (inputRef.current = el)}
            className={s.input}
            id={id}
            value={value}
            defaultValue={value}
            onValueChange={handleChangePhone}
            allowEmptyFormatting={true}
            format={COUNTRY_CODES[country || 'russia'].mask}
            mask="_"
            autoComplete="off"
            type="tel"
          />
        </div>
      </div>
    </InputBox>
  )
}

const mapStateToProps = state => {
  return {
    country: state.form.phoneSelection.country,
    possibleCountryCodes: state.form.possibleCountryCodes,
  }
}
const mapDispatchToProps = { setPhoneCountry, setPhoneValue }

export default connect(mapStateToProps, mapDispatchToProps)(Phone)
