import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './summaryRow.module.scss'

function SummaryRowInline({ children, title }) {
  return (
    <div className={s.row} data-project-name={getProjectName()}>
      <div className={s.row__title}>{title}:</div>
      <div className={s.row__text}>{children}</div>
    </div>
  )
}

export default SummaryRowInline
