import i18next from 'i18next'

import { screenSizeAndFormTypeFilter } from '../../../carOrderForm/main/helpers'
import { FORM_SOURCE, FORM_TYPE_ONLINE_PAYMENT } from '../../../constants'
import { formatCurrency, formatPrice, online_payment_button_text_formatter } from '../../../helpers'
import { setPhoneValue } from '../../../store/actions'
import store from '../../../store/store'

/**
 * Id поля - placement, чтобы не было дублирующихся полей
 */
export const fieldIdentityFormatter = (placement, identity) => {
  return 'kdxsc_' + placement + '_' + identity
}

/**
 * Текст чекбокса с согласием
 */
export const agreementText = (visual, type, deprecated) => {
  if (!visual) {
    return i18next.t('form.defaultAgreementText')
  }

  if (visual.agreementText) {
    if (type === FORM_TYPE_ONLINE_PAYMENT) {
      if (visual.agreementText.onlinePaymentForm) {
        return visual.agreementText.onlinePaymentForm
      }
    }

    if (visual.agreementText.baseForm) {
      return visual.agreementText.baseForm
    }
  }

  if (deprecated.formAgreementText) {
    return deprecated.formAgreementText
  }

  return i18next.t('form.defaultAgreementText')
}

/**
 * Текст чекбокса с согласием
 */
export const personalDataLabel = (visual, type) => {
  const agreements = visual?.agreements?.personalDataProcessing
  const formType = type

  let res = ''

  if (formType === 'form_type_base') {
    res = agreements?.baseFormText
  } else {
    res = agreements?.onlinePaymentFormText
  }

  return res ? res : i18next.t('form.defaultAgreementText')
}

export const communicationProcessingLabel = (visual, type, calledFromCreditCalcStandalone = false) => {
  const agreements = calledFromCreditCalcStandalone
    ? visual?.communicationProcessing
    : visual?.agreements?.communicationProcessing
  const formType = type

  let res = ''

  if (formType === 'form_type_base') {
    res = agreements?.baseFormText
  } else {
    res = agreements?.onlinePaymentFormText
  }

  return res ? res : i18next.t('form.defaultAgreementText')
}

/**
 * Отображать ли поле
 */
export const visibilityHelper = (visual, key, type) => {
  if (!visual || !Object.keys(visual).length) {
    return false
  }

  if (!visual.fields[key]) {
    return false
  }

  const field = visual.fields[key]

  if (type === FORM_TYPE_ONLINE_PAYMENT) {
    return !field.hideFromOnlinePaymentForm
  }

  return !field.hideFromBaseForm
}

/**
 * Обязательное ли поле
 */
export const requirementHelper = (visual, key, type) => {
  if (!visual || !Object.keys(visual).length) {
    return false
  }

  if (!visual.fields[key]) {
    return false
  }

  const field = visual.fields[key]

  if (type === FORM_TYPE_ONLINE_PAYMENT) {
    return field.requiredOnOnlinePaymentForm
  }

  return field.requiredOnBaseForm
}

/**
 * Подскролл к полю с ошибкой в виджете
 */
export const scrollToFieldWithErrorInWidgetForm = (panelContent, panelTop) => {
  if (panelTop?.current && panelContent?.current) {
    setTimeout(() => {
      let mainSection = panelContent.current

      let firstErrorInput, firstErrorCheckbox

      let inputs = mainSection.querySelectorAll('input')
      for (let key in inputs) {
        if (!inputs.hasOwnProperty(key)) {
          break
        }

        let classes = inputs[key]?.className

        if (classes.includes('Errors')) {
          firstErrorInput = inputs[key]
          break
        }
        if (classes.includes('formCheckbox')) {
          firstErrorCheckbox = inputs[key]
          break
        }
      }

      if (firstErrorInput) {
        mainSection.scrollTo({
          top: firstErrorInput.parentElement.offsetTop - panelTop.current.clientHeight - 19,
          behavior: 'smooth',
        })
      } else if (firstErrorCheckbox) {
        mainSection.scrollTo({
          top: mainSection.scrollHeight,
          behavior: 'smooth',
        })
      }
    }, 1)
  }
}

/**
 * Добавление в старый локалсторадж новых данных phoneSelection
 * (перенесено из старого компонента формы)
 */
export const checkOldPhone = (phoneRawValue, dispatch) => {
  if (!phoneRawValue) {
    const localFormData = JSON.parse(localStorage.getItem('@kodix/smartContract/form'))
    const phone = localFormData?.values?.phone
    const oldData = /^(\+7)\(/.test(phone) || null

    if (oldData) {
      const initPhoneRawValue = phone.replace('+7', '').replace(/\D/g, '')

      const initPhoneValue = phone.replace('(', ' (').replace(')', ') ')

      dispatch(
        setPhoneValue({
          phoneRawValue: initPhoneRawValue,
          phone: initPhoneValue,
        }),
      )
    }
  }
}

/**
 * Название секции с персональными данными
 * @param config
 * @returns {string}
 */
export const personalSectionTitle = config => {
  const isTitleDisplayed = screenSizeAndFormTypeFilter(config)

  if (!isTitleDisplayed) {
    return ''
  }

  return ![FORM_SOURCE.WIDGET, FORM_SOURCE.ONLINE_PAYMENT, FORM_SOURCE.WIDGET_AUTH].includes(config.source)
    ? i18next.t('carOrderForm.personalData')
    : ''
}

/**
 * Название секции с контактными данными
 * @param config
 * @returns {string}
 */
export const contactsSectionTitle = config => {
  const isTitleDisplayed = screenSizeAndFormTypeFilter(config)

  if (!isTitleDisplayed) {
    return ''
  }

  return ![FORM_SOURCE.WIDGET, FORM_SOURCE.ONLINE_PAYMENT, FORM_SOURCE.WIDGET_AUTH].includes(config.source)
    ? i18next.t('carOrderForm.contacts')
    : ''
}

/**
 * Текст на кнопке создания СК - в боковом виджете в футере
 * Нужен, чтоб корректно отправлялось событие нажатия по кнопке из формы (тк до футера оно не доходит)
 * @returns {string}
 */
export const createSmartContractButtonText = () => {
  const buttons = store.getState().settings.visual.buttons

  if (buttons.createSmartContract && buttons.createSmartContract.text) {
    return buttons.createSmartContract.text
  }

  return i18next.t('footer.createSmartContractButtonText')
}

/**
 * Текст на кнопке онлайн оплаты на экране ОО - в боковом виджете в футере
 * Нужен, чтоб корректно отправлялось событие нажатия по кнопке из формы (тк до ОО оно не доходит)
 * @returns {string|*}
 */
export const onlinePaymentButtonText = () => {
  const onlinePayment = store.getState().onlinePayment
  const buttons = store.getState().settings.visual.buttons
  const currency = store.getState().settings.currency

  const onlinePaymentFormattedValue = `${formatPrice(onlinePayment.amount, currency)}`

  if (onlinePayment.enableFullPayment) {
    if (onlinePayment?.activePayment?.id) {
      return i18next.t('onlinePayment.goToOnlinePaymentButtonText', {
        value: onlinePayment.activePayment.formattedPrice,
      })
    } else {
      return i18next.t('onlinePayment.chooseOnlinePaymentType')
    }
  }

  if (buttons.goToOnlinePayment && buttons.goToOnlinePayment.text) {
    return online_payment_button_text_formatter(buttons.goToOnlinePayment.text, `${onlinePaymentFormattedValue}`)
  }

  return i18next.t('onlinePayment.goToOnlinePaymentButtonText', {
    value: `${onlinePaymentFormattedValue}`,
  })
}

export const isCommunicationProcessingAcceptedCheckboxVisible = (visual, calledFromCreditCalcStandalone = false) => {
  if (calledFromCreditCalcStandalone) {
    return ['detailed_with_communication', 'detailed_with_communication_detailed'].includes(visual.type)
  }
  return ['detailed_with_communication', 'detailed_with_communication_detailed'].includes(visual?.agreements?.type)
}

export const isCommunicationProcessingCheckboxVisible = (
  visual,
  checkboxType,
  calledFromCreditCalcStandalone = false,
) => {
  if (calledFromCreditCalcStandalone) {
    return visual?.type === 'detailed_with_communication_detailed' && visual?.communicationProcessing?.[checkboxType]
  }

  return (
    visual?.agreements?.type === 'detailed_with_communication_detailed' &&
    visual?.agreements?.communicationProcessing?.[checkboxType]
  )
}
