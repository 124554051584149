import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectCustomDataHelper = (preset, detail, calledFromList) => {
  if (calledFromList && typeof detail?.customData === 'function') {
    return detail.customData()
  }

  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.custom_data(detail)
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  return onePlatform().detail.custom_data(detail)
}
