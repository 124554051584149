import './_theme_generic_v2.scss'

import _debounce from 'lodash/debounce'
import { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { connect, useDispatch } from 'react-redux'

import {
  TI_PARTNER,
  TRADE_IN_DATA_FILLED,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_STEP_WEIGHTS,
} from '../../../constants'
import { formatCurrency, formatMileage, formatPrice, isTradeInDataFilled, tradeInStepRemains } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { applyTradeIn, rateTradeIn, setRateStatusInitial, setTradeInEstimationType } from '../../../store/actions'
import TradeInBody from '../../Parts/TradeIn/Body'
import TradeInBrand from '../../Parts/TradeIn/Brand'
import TradeInCity from '../../Parts/TradeIn/City'
import TradeInColor from '../../Parts/TradeIn/Color'
import TradeInEngine from '../../Parts/TradeIn/Engine'
import TradeInGearBox from '../../Parts/TradeIn/GearBox'
import TradeInGeneration from '../../Parts/TradeIn/Generation'
import TradeInInteriorColor from '../../Parts/TradeIn/InteriorColor'
import TradeInInteriorType from '../../Parts/TradeIn/InteriorType'
import TradeInMileage from '../../Parts/TradeIn/Mileage'
import TradeInModel from '../../Parts/TradeIn/Model'
import TradeInModification from '../../Parts/TradeIn/Modification'
import TradeInOwner from '../../Parts/TradeIn/Owner'
import TradeInYear from '../../Parts/TradeIn/Year'
import Button from '../../Ui/button'
import si from '../../Ui/input/input.module.scss'
import s from './TradeInList.module.scss'

function TradeInList({
  weight,
  complete,
  stepsRemain,
  status,
  title,
  subTitle,
  possibleCarPriceFormatted,
  possibleCarPriceFromFormatted,
  possibleCarPriceToFormatted,
  ownerCarPrice,
  currency,
  ownerCarPriceEnabled,
  ownerCarPriceOwnerHintText,
  partner,
  openedFromApi,
  vehicleType,
  hidePartnerInTexts,
  showTiCalculationModeSwitcher,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [customPrice, setCustomPrice] = useState(ownerCarPrice)

  const tradeInContent = createRef()

  const onRateTradeIn = () => {
    const tradeInContentCurrent = tradeInContent.current
    setCustomPrice(0)

    Promise.resolve(dispatch(rateTradeIn(true))).then(() => {
      setTimeout(() => {
        tradeInContentCurrent.scrollTo({
          top: tradeInContentCurrent.scrollHeight,
          behavior: 'smooth',
        })
      }, 300)
    })
  }

  const isMobileDe = partner === TI_PARTNER.MOBILE_DE
  const isAutoRu = partner === TI_PARTNER.AUTO_RU
  const isOnePlatform = partner === TI_PARTNER.ONE_PLATFORM
  const isMaxPoster = partner === TI_PARTNER.MAX_POSTER

  const resultSubtitleText = () => {
    if (status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && isAutoRu) {
      return hidePartnerInTexts
        ? t('tradeIn.rateResultSuccessDescriptionWithoutRF')
        : t('tradeIn.rateResultSuccessDescription')
    } else if (status === TRADE_IN_RATE_STATUS.FAILED) {
      return t('tradeIn.rateResultFailed')
    } else if (status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE) {
      return t('tradeIn.rateResultZeroPrice')
    } else {
      return ''
    }
  }

  const customPriceText = () => {
    if (ownerCarPriceOwnerHintText) {
      return ownerCarPriceOwnerHintText
    } else {
      return t(`tradeIn.ownerCarPriceText.${vehicleType}`)
    }
  }

  const handleApplyButton = () => {
    if (openedFromApi) {
      dispatch(
        applyTradeIn(false, {
          useCustomPrice: true,
          customPrice: customPrice,
          openedFromMainScreen: false,
          openedFromApi: openedFromApi,
        }),
      )
    } else {
      dispatch(
        applyTradeIn(true, {
          useCustomPrice: true,
          customPrice: customPrice,
          openedFromMainScreen: false,
        }),
      )
    }
  }

  const goToTradeInByIdentity = () => {
    dispatch(setRateStatusInitial())
    dispatch(setTradeInEstimationType(TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ))
  }

  return (
    <div className={s.wrapper} ref={tradeInContent} data-project-name={getProjectName()}>
      <div className={s.wrapper__top}>
        <div className="kdxsc-accordion-list" data-project-name={getProjectName()}>
          <div className="kdxsc-accordion-list__item">
            <TradeInBrand />
          </div>
          {weight >= TRADE_IN_STEP_WEIGHTS['model'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInModel />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['generation'] && (isAutoRu || isMaxPoster || isOnePlatform) && (
            <div className="kdxsc-accordion-list__item">
              <TradeInGeneration />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['body'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInBody />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['modification'] && (isAutoRu || isMaxPoster || isOnePlatform) && (
            <div className="kdxsc-accordion-list__item">
              <TradeInModification />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['year'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInYear />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['color'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInColor />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['owner'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInOwner />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['engine'] && isMobileDe && (
            <div className="kdxsc-accordion-list__item">
              <TradeInEngine />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['gearbox'] && isMobileDe && (
            <div className="kdxsc-accordion-list__item">
              <TradeInGearBox />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['interiorColor'] && isMobileDe && (
            <div className="kdxsc-accordion-list__item">
              <TradeInInteriorColor />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['interiorType'] && isMobileDe && (
            <div className="kdxsc-accordion-list__item">
              <TradeInInteriorType />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['city'] && (isAutoRu || isMaxPoster || isOnePlatform) && (
            <div className="kdxsc-accordion-list__item">
              <TradeInCity />
            </div>
          )}
          {weight >= TRADE_IN_STEP_WEIGHTS['mileage'] && (
            <div className="kdxsc-accordion-list__item">
              <TradeInMileage />
            </div>
          )}
        </div>
        {showTiCalculationModeSwitcher && (
          <div className="kdxsc-tradein-estimation-type-block" data-project-name={getProjectName()}>
            <div onClick={goToTradeInByIdentity} className="kdxsc-tradein-estimation-type-switcher">
              {t('trade_in.estimations.goToTradeInByIdentity')}
            </div>
            <div className="kdxsc-tradein-estimation-type-hint">{t('trade_in.estimations.reset')}</div>
          </div>
        )}
      </div>
      <div className={s.wrapper__bottom}>
        {status === TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <Button
              handleClick={onRateTradeIn}
              isDisabled={!complete}
              text={complete ? t('tradeIn.rate') : t(`${stepsRemain}`)}
            />
          </div>
        )}
        {status !== TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <div className={s.result}>
              <div className={s.result__title}>{t('tradeIn.rateResult')}</div>
              {resultSubtitleText() && (
                <div className={s.result__subtitle} dangerouslySetInnerHTML={{ __html: resultSubtitleText() }}></div>
              )}

              {[TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE, TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE].includes(
                status,
              ) && (
                <div className={s.result__content}>
                  <div className={s.resultContent}>
                    <div className={s.resultContent__title}>{title}</div>
                    <div className={s.resultContent__info}>{subTitle}</div>
                    {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                      <div className={s.resultContent__price}>{possibleCarPriceFormatted}</div>
                    )}
                    {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                      <div className={s.resultContent__price_range}>
                        {t('tradeIn.rateResultPriceFromTo', {
                          from: possibleCarPriceFromFormatted,
                          to: possibleCarPriceToFormatted,
                        })}
                      </div>
                    )}
                    {ownerCarPriceEnabled && (
                      <div className={s.resultContent__price_custom}>
                        <div className={s.customPrice}>
                          <div
                            className={s.customPrice__title}
                            dangerouslySetInnerHTML={{ __html: customPriceText() }}
                          />

                          <div className={s.customPrice__input}>
                            <div className={si.wrapper}>
                              <div className={si.wrapper__input}>
                                <NumberFormat
                                  className={si.input}
                                  value={customPrice}
                                  onChange={_debounce(e => setCustomPrice(e.target.value), 250)}
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  thousandSeparator={' '}
                                  decimalScale={0}
                                  placeholder={t('tradeInS.ownerCarPricePlaceholder')}
                                  isNumericString={false}
                                  suffix={` ${formatCurrency(currency)}`}
                                  maxLength={15}
                                  defaultValue={''}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                <div className={s.result__info}>
                  {hidePartnerInTexts
                    ? t('tradeIn.rateResultSuccessDescription2WithoutRF')
                    : t('tradeIn.rateResultSuccessDescription2')}
                </div>
              )}
            </div>
          </div>
        )}

        {status !== TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <Button handleClick={handleApplyButton} text={t('tradeIn.apply')} />
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  settings: { currency, vehicleType },
  tradeIn: {
    partner,
    ownerCarPrice,
    ownerCarPriceEnabled,
    ownerCarPriceOwnerHintText,
    weight,
    values,
    hidePartnerInTexts,
    isTradeInByIdentityEnabled,
    rate: {
      status,
      result: {
        possibleCarPrice,
        tradeIn: { from, to },
      },
    },
  },
  api: { openedFromApi },
}) {
  return {
    weight,
    status,

    title: [values.brand.name, values.model.name].filter(v => v !== '').join(' '),
    subTitle: [
      values.modification.name,
      values.year.value,
      values.body.name,
      values.owner.name,
      formatMileage(values.mileage.value),
    ]
      .filter(v => v !== '')
      .join(', '),

    complete: isTradeInDataFilled(values, partner) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED,
    stepsRemain: tradeInStepRemains(values, partner),

    possibleCarPriceFormatted: formatPrice(possibleCarPrice, currency),
    possibleCarPriceFromFormatted: formatPrice(from, currency),
    possibleCarPriceToFormatted: formatPrice(to, currency),
    currency,
    ownerCarPrice,
    ownerCarPriceEnabled,
    ownerCarPriceOwnerHintText,
    partner,
    openedFromApi,
    vehicleType,
    hidePartnerInTexts,
    showTiCalculationModeSwitcher: partner === TI_PARTNER.AUTO_RU && isTradeInByIdentityEnabled,
  }
}

export default connect(mapStateToProps)(TradeInList)
