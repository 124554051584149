import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { showCarOrderPopup } from '../../store/actions'
import s from './main.module.scss'

function ButtonForPopup({ config }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openPopup = () => {
    window.InitSmartContractCarOrderPopup({ ...config, placement: 'popup', show: config.type })
    dispatch(showCarOrderPopup())
  }

  return (
    <div className={s.button_wrapper}>
      <div className={s.button} onClick={openPopup} style={{ ...config.styles }}>
        {config.text || t('calculatePurchase')}
      </div>
    </div>
  )
}

export default ButtonForPopup
