export const normalizeTradeInRateResult = result => {
  return {
    estimated: {
      from: result.estimated.from,
      to: result.estimated.to,
    },
    tradeIn: {
      from: result.trade_in.from,
      to: result.trade_in.to,
    },
    possibleCarPrice: result.possible_car_price,
    hash: result.hash,
    condition: result.condition,
  }
}
