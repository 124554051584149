import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { load } from 'redux-localstorage-simple'

import CarOrderForm from './carOrderForm/main/main'
import { FORM_SOURCE } from './constants'
import { bootSimpleForm, clearProtections, formSetter, languageSetter, normalizeVisual, paletteSetter } from './helpers'
import { setSimpleFormConfig } from './store/actions/simple_form'

function SimpleFormApp({ config }) {
  const [canBoot, setCanBoot] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const loadData = useCallback(widget_id => {
    setIsLoading(true)
    const dataFromLocalStorage = load({
      states: ['form'],
      namespace: '@kodix/smartContract',
      namespaceSeparator: '/',
    })

    bootSimpleForm(widget_id)
      .then(r => {
        if (r.data) {
          paletteSetter(r.data.visual.palette)
          languageSetter(r.data.locale)

          formSetter(r.data.visual.fields, r.data.locale, dataFromLocalStorage)
          clearProtections(FORM_SOURCE.SIMPLE_FORM)

          let data = r.data
          data.visual = normalizeVisual(r.data.visual)
          data.widgetId = widget_id

          dispatch(setSimpleFormConfig(data))
          setCanBoot(true)
        }
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!config.widget_id) {
      return
    }
    loadData(config.widget_id)
  }, [config, loadData])

  const content = () => {
    if (isLoading) {
      return config.showFailedLoadingComponent ? <p>{t('loading')}</p> : null
    }

    if (!canBoot) {
      return config.showFailedLoadingComponent ? <p>{t('generalError')}</p> : null
    }

    return <CarOrderForm config={{ ...config, source: FORM_SOURCE.SIMPLE_FORM }} />
  }

  return content()
}

export default SimpleFormApp
