import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { closestTerm } from '../../../helpers'
import { formID } from '../../Conditions/formID'
import s          from './styles/line.module.scss'
import {getProjectName} from "../../../helpers/app/detectors/project_name";

const Line = ({
  id,
  carPrice,
  dividedNumber,
  onChange,
  value,
  register,
  currentRef,
  isLeasing,
  disabled,
  defaultConditions,
  leasingConditions,
  term,
}) => {
  const [width, setWidth] = useState('100%')

  function getInputMinMax() {
    let defaults = defaultConditions[term]

    let min = 0
    let max = 95

    if (isLeasing && leasingConditions.defaultSettingsAvailable) {
      const defaultConditions = leasingConditions.defaultConditions
      let closest = closestTerm(defaultConditions, term)
      defaults = defaultConditions[closest]
    }

    if (isLeasing && !(id === formID.initFee)) {
      max = 100
      return [min, max]
    }

    if (id === formID.initFee && !isNaN(defaults?.initialFeePercentMin) && !isNaN(defaults?.initialFeePercentMax)) {
      min = defaults.initialFeePercentMin
      max = defaults.initialFeePercentMax
    }

    if (
      id === formID.buyback &&
      !isNaN(defaults?.buybackPaymentPercentMin) &&
      !isNaN(defaults?.buybackPaymentPercentMax)
    ) {
      min = defaults.buybackPaymentPercentMin
      max = defaults.buybackPaymentPercentMax
    }

    return [min, max]
  }

  const [min, max] = getInputMinMax()

  const rangeFill = useRef()

  const carPriceRange = id === formID.carPrice

  const handleChange = e => {
    if (!carPrice && !isLeasing) {
      return
    }

    if (isLeasing && carPriceRange) {
      onChange((dividedNumber / 100) * Number(e.target.value))
      return
    }

    let closest = closestTerm(defaultConditions, term)

    if (isLeasing && e.target.value > (leasingConditions?.defaultConditions[closest]?.initialFeePercentMax || 49)) {
      return
    }

    if (isLeasing && e.target.value < (leasingConditions?.defaultConditions[closest]?.initialFeePercentMin || 0)) {
      return
    }

    onChange(e.target.value)
  }

  useEffect(() => {
    rangeFill.current.style.width = `${((Number(value) - min) / (max - min)) * 100}%`
    setWidth('100%')
  }, [value, isLeasing, carPriceRange, min, max])

  useEffect(() => {
    if (value > max) {
      onChange(max)
    }

    if (value < min) {
      onChange(min)
    }
  }, [value, max, min, onChange])

  const handleClickOnPercent = () => currentRef.current.focus()

  return (
    <section className={s.range_container} data-project-name={getProjectName()}>
      <div className={s.range_fill} ref={rangeFill} />
      <input
        style={{ width: width }}
        className={s.range}
        type="range"
        min={min}
        max={max}
        step="1"
        name={`${id}Percent`}
        tabIndex={-1}
        onChange={handleChange}
        value={value}
        ref={register}
        disabled={disabled}
      />
      {isLeasing && !carPriceRange && (
        <div className={s.range_percent} onClick={handleClickOnPercent}>
          {value} %
        </div>
      )}
    </section>
  )
}

const mapStateToProps = state => {
  return {
    leasingConditions: {
      defaultConditions: state.credit_standalone.conditions.defaultConditions,
      defaultSettingsAvailable: state.credit_standalone.config.defaultSettingsAvailable,
    },
    defaultConditions: state.credit_standalone.conditions.defaultConditions,
    term: state.credit_standalone.conditions.term,
  }
}

export default connect(mapStateToProps)(Line)
