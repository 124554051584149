import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { currencyScale, formatCurrency } from '../../../helpers'
import { InputBox } from '../Common'
import s            from './styles/masked.module.scss'
import {getProjectName}                  from "../../../helpers/app/detectors/project_name";

const Masked = ({ labelText, id, tip, onChange, value, control, currentRef, setValue, currency, disabled, wide }) => {
  useEffect(() => setValue(id, value), [id, setValue, value])

  return (
    <InputBox labelText={labelText} id={id} tip={tip} wide={wide}>
      <Controller
        data-project-name={getProjectName()}
        as={<NumberFormat getInputRef={el => (currentRef.current = el)} />}
        name={id}
        control={control}
        value={value}
        onValueChange={values => onChange(values.value)}
        onFocus={() => currentRef.current.focus()}
        allowNegative={false}
        allowLeadingZeros={false}
        className={s.input}
        id={id}
        thousandSeparator={' '}
        decimalScale={currencyScale(currency)}
        placeholder={`0 ${formatCurrency(currency)}`}
        isNumericString={false}
        suffix={` ${formatCurrency(currency)}`}
        maxLength={12}
        defaultValue={''}
        autoComplete="off"
        disabled={disabled}
      />
    </InputBox>
  )
}

export default Masked
