import _cloneDeep from 'lodash/cloneDeep'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TRADE_IN_COLORS } from '../../../../constants'
import { setTradeInColor } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneColorDropdown({ id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInColor({ id, name }))
  }

  const translatedColors = () => {
    const original = _cloneDeep(TRADE_IN_COLORS)

    for (const key in original) {
      if (!original.hasOwnProperty(key)) {
        continue
      }

      original[key].name = t(`tradeIn.color.names.${original[key].name}`)
    }

    return original
  }

  return (
    <TradeInStandalonePartsRadiobuttons
      name="color"
      variants={translatedColors()}
      checked={id}
      onSet={onSet}
      search={''}
    />
  )
}

function mapStateToProps({
  tradeIn: {
    values: {
      color: { id },
    },
  },
}) {
  return {
    id,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneColorDropdown)
