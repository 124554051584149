import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { TRADE_IN_CITY_TYPE_BE, TRADE_IN_CITY_TYPE_CATALOG, TRADE_IN_DEFAULT_CITY_PREFIX } from '../../../../constants'
import { getCities } from '../../../../helpers'
import { setTradeInCity, setTradeInCityType, setTradeInStandaloneTypedValue } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneCityDropdown({ id, typedValue, widgetId, defaultCities, cityType }) {
  const dispatch = useDispatch()
  const [variants, setVariants] = useState([])

  const debouncedCallback = useDebouncedCallback(() => {
    setVariants([])

    if (typedValue !== '') {
      getCities(widgetId, typedValue).then(({ data }) => {
        let v = []
        if (data) {
          v = data.map(city => {
            let external = ''

            if (city.parents.length > 2) {
              external = ' (' + city.parents[2] + ')'
            }

            if (city.parents.length > 3) {
              external = ' (' + city.parents[2] + ', ' + city.parents[3] + ')'
            }

            return {
              id: city.id,
              name: city.name,
              external,
            }
          })
          setVariants(v)
        }
        if (v && v.length === 1 && cityType === TRADE_IN_CITY_TYPE_BE) {
          const id = v[0].id
          const name = v[0].name
          dispatch(setTradeInCity({ id, name }))
        }
      })
    }
  }, 1000)

  useEffect(() => {
    debouncedCallback(typedValue)
  }, [debouncedCallback, typedValue])

  const onSet = ({ id, name }) => {
    const weNeedToRunQuery = id && typeof id === 'string' && id.includes(TRADE_IN_DEFAULT_CITY_PREFIX)
    if (weNeedToRunQuery) {
      dispatch(
        setTradeInStandaloneTypedValue({
          name: 'city',
          value: name,
        }),
      )
      dispatch(setTradeInCityType(TRADE_IN_CITY_TYPE_BE))
    } else {
      dispatch(setTradeInCityType(TRADE_IN_CITY_TYPE_CATALOG))
      dispatch(setTradeInCity({ id, name }))
      dispatch(
        setTradeInStandaloneTypedValue({
          name: 'city',
          value: name,
        }),
      )
    }
  }

  const content = () => {
    if (variants.length) {
      return (
        <TradeInStandalonePartsRadiobuttons
          name="cities"
          variants={variants}
          checked={id}
          onSet={onSet}
          search={typedValue}
          additionalClasses={''}
        />
      )
    } else if (defaultCities) {
      return (
        <TradeInStandalonePartsRadiobuttons
          name="cities"
          variants={defaultCities}
          checked={id}
          onSet={onSet}
          search={''}
          additionalClasses={''}
          radiosType={'highlighted'}
        />
      )
    } else {
      return null
    }
  }

  return content()
}

function mapStateToProps({
  settings: { widget_id: widgetId },
  tradeIn: {
    typedValues: { city: typedValue },
    values: {
      city: { id },
    },
    cityType,
  },
  tradeInStandalone: { defaultCities },
}) {
  return {
    widgetId,
    id,
    typedValue: typedValue.trim(),
    defaultCities,
    cityType,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneCityDropdown)
