import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'

import { FINANCE_USER_BEHAVIOUR, TRADE_IN_USER_BEHAVIOUR } from '../../../constants'
import { carPriceFromTI, formatCurrency, formatPrice, totalPayment } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { calculateTaxes } from '../../../helpers/calculate_taxes'
import SummaryRow from '../../Ui/summaryRow'
import SummaryRowInline from '../../Ui/summaryRowInline'
import s from './summary.module.scss'

function Summary({
  paymentTitle,
  paymentAmount,
  tradeInEnabledByUser,
  tradeInDisabledByUser,
  tradeInPossibleCarPrice,
  haveAnyServices,
  haveAnyDiscounts,
  servicesValue,
  discountsValue,
  tradeInBenefitAmount,
  tradeInBenefitAmountWithoutTradeIn,
  creditBenefitAmount,
  price,
  creditParameters,
  creditActiveProgram,
  creditEnabledByUser,
  leasingActiveProgram,
  leasingEnabledByUser,
  leasingBenefitAmount,
  leasingParameters,
  getCarValue,
  finalCarPrice,
  currency,
  vehicleType,
  taxes,
  taxesAmount,
  selectedAccessoriesValue,
}) {
  const { t } = useTranslation()

  const hideProgramPercentage = useSelector(state => state.settings.visual.leasing.hideProgramPercentage)
  const hideProgramBankName = useSelector(state => state.settings.visual.leasing.hideProgramBankName)

  const formattedCarPrice = baseText => {
    let text = t(baseText)

    if (taxesAmount > 0) {
      text += ` (${t('summary.withTax')})`
    }

    return text
  }

  const priceComponent = (price, showMonths) => {
    return (
      <div className={s.price} data-project-name={getProjectName()}>
        <div className={s.price__value}>
          {formatPrice(price, currency)} {showMonths && '/ ' + t('months')}
        </div>
      </div>
    )
  }

  const leasingProgramNameRow = () => {
    const parts = []

    if (!hideProgramBankName) {
      parts.push(leasingActiveProgram?.bank?.name)
    }

    if (hideProgramPercentage) {
      parts.push(leasingActiveProgram?.name)
    } else {
      parts.push(leasingActiveProgram?.name + ' ' + leasingActiveProgram?.percentage + '%')
      parts.push(' ')
    }

    parts.push(leasingParameters?.period + ' ' + t('months'))

    return parts.join(', ')
  }

  const formattedTaxes =
    taxes.length > 0
      ? taxes.map((t, index) => {
          return (
            <div className={s.rows__item} key={index}>
              <SummaryRow
                price={priceComponent(finalCarPrice * (t.percent / 100), false)}
              >{`${t.name} (${t.percent}%)`}</SummaryRow>
            </div>
          )
        })
      : ''

  return (
    <div className={s.rows} data-project-name={getProjectName()}>
      <div className={s.rows__item}>
        <SummaryRow price={priceComponent(price, false)}>{t(`summary.carPrice.${vehicleType}`)}</SummaryRow>
      </div>
      {tradeInEnabledByUser && tradeInBenefitAmount < 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(tradeInBenefitAmount, false)}>{t('summary.tradeInBenefit')}</SummaryRow>
        </div>
      )}
      {tradeInDisabledByUser && tradeInBenefitAmountWithoutTradeIn < 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(tradeInBenefitAmountWithoutTradeIn, false)}>
            {t('summary.tradeInBenefitWithoutTradeIn')}
          </SummaryRow>
        </div>
      )}
      {creditEnabledByUser && creditBenefitAmount < 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(creditBenefitAmount, false)}>{t('summary.creditBenefit')}</SummaryRow>
        </div>
      )}
      {leasingEnabledByUser && leasingBenefitAmount < 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(leasingBenefitAmount, false)}>{t('summary.leasingBenefit')}</SummaryRow>
        </div>
      )}
      {haveAnyDiscounts && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(discountsValue, false)}>{t('summary.yourBenefit')}</SummaryRow>
        </div>
      )}
      {haveAnyServices && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(servicesValue, false)}>{t('summary.selectedServices')}</SummaryRow>
        </div>
      )}

      {selectedAccessoriesValue > 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(selectedAccessoriesValue, false)}>
            {t('summary.selectedAccessories')}
          </SummaryRow>
        </div>
      )}

      {formattedTaxes}

      {getCarValue !== price && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(getCarValue, false)}>
            {formattedCarPrice(t(`summary.carPriceWithBenefits.${vehicleType}`))}
          </SummaryRow>
        </div>
      )}

      {creditEnabledByUser && creditActiveProgram?.id && creditActiveProgram?.bank && (
        <div className={s.rows__item}>
          <SummaryRowInline title={t('summary.byCredit')}>
            {creditActiveProgram?.bank?.name}, {creditActiveProgram?.name} {creditActiveProgram?.rate}%,{' '}
            {creditParameters?.period} {t('months')}
          </SummaryRowInline>
        </div>
      )}

      {creditEnabledByUser && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(creditActiveProgram?.payment, true)}>
            {t('summary.monthlyPayment')}
          </SummaryRow>
        </div>
      )}
      {creditEnabledByUser && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(creditParameters?.initialFee, false)}>
            {t('summary.initialFeeAmountCredit')} {creditParameters?.initialFeePercentage}%
          </SummaryRow>
        </div>
      )}

      {leasingEnabledByUser && leasingActiveProgram?.bank && leasingActiveProgram?.id && (
        <div className={s.rows__item}>
          <SummaryRowInline title={t('summary.byLeasing')}>{leasingProgramNameRow()}</SummaryRowInline>
        </div>
      )}

      {leasingEnabledByUser && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(leasingActiveProgram?.paymentPerMonth, true)}>
            {t('summary.monthlyPayment')}
          </SummaryRow>
        </div>
      )}
      {leasingEnabledByUser && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(leasingParameters?.initialFee, false)}>
            {t('summary.initialFeeAmount')} {leasingParameters?.initialFeePercentage}%
          </SummaryRow>
        </div>
      )}

      {tradeInEnabledByUser && tradeInPossibleCarPrice > 0 && (
        <div className={s.rows__item}>
          <SummaryRow price={priceComponent(tradeInPossibleCarPrice, false)}>
            {t(`summary.tradeInPriceAmount.${vehicleType}`)}
          </SummaryRow>
        </div>
      )}

      <div className={s.rows__item}>
        <SummaryRow price={priceComponent(paymentAmount, false)}>
          <span className={s.totals} data-project-name={getProjectName()}>
            <span className={s.totals__first}>{t(paymentTitle)}</span>
          </span>
        </SummaryRow>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    behaviourByUser: tradeInBehaviourByUser,
    ownerCarPriceEnabled,
    ownerCarPrice,
    rate: {
      result: { possibleCarPrice },
    },
  },
  credit: {
    behaviourByUser: creditBehaviourByUser,
    active_program: creditActiveProgram,
    benefitValue: creditBenefitAmount,
    parameters: creditParameters,
  },
  leasing: { benefitValue: leasingBenefitAmount, parameters: leasingParameters, activeProgram: leasingActiveProgram },
  car: { price },
  benefits: { selected },
  settings: { currency, vehicleType, taxes },
  accessories: { selected: selectedAccessories },
}) {
  const tradeInEnabledByUser = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const tradeInDisabledByUser = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED
  const creditEnabledByUser = creditBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED
  const leasingEnabledByUser = creditBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_APPLIED

  const tradeInPossibleCarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  const [paymentAmount, paymentTitle] = totalPayment({
    price,
    taxes,
    creditEnabledByUser,
    creditBenefitAmount,
    possibleCarPrice: tradeInPossibleCarPrice,
    creditParameters,
    selected,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    tradeInEnabledByUser,
    tradeInDisabledByUser,
    leasingEnabledByUser,
    leasingBenefitAmount,
    leasingParameters,
    selectedAccessories,
  })

  const discountsValue = () => {
    return selected
      .filter(benefit => benefit.attributes.value < 0)
      .reduce((accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue, 0)
  }
  const servicesValue = () => {
    return selected
      .filter(benefit => benefit.attributes.value > 0)
      .reduce((accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue, 0)
  }
  const accessoriesValue = () => {
    return selectedAccessories.reduce(
      (accumulator, { attributes: { price: currentValue } }) => accumulator + currentValue,
      0,
    )
  }

  const finalCarPrice = () => {
    let totalOutcome = price

    if (tradeInEnabledByUser && tradeInBenefitAmount < 0) {
      totalOutcome += tradeInBenefitAmount
    }
    if (tradeInDisabledByUser && tradeInBenefitAmountWithoutTradeIn < 0) {
      totalOutcome += tradeInBenefitAmountWithoutTradeIn
    }
    if (creditEnabledByUser && creditBenefitAmount < 0) {
      totalOutcome += creditBenefitAmount
    }
    if (leasingEnabledByUser && leasingBenefitAmount < 0) {
      totalOutcome += leasingBenefitAmount
    }
    totalOutcome += discountsValue()
    totalOutcome += servicesValue()
    totalOutcome += accessoriesValue()

    return totalOutcome
  }

  const taxesAmount = calculateTaxes(finalCarPrice(), taxes)

  const getCarValue = () => {
    return finalCarPrice() + taxesAmount
  }

  return {
    creditActiveProgram,
    tradeInPossibleCarPrice,
    paymentAmount,
    currency,
    paymentTitle,
    tradeInEnabledByUser,
    tradeInDisabledByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
    creditParameters,
    creditEnabledByUser,
    haveAnyDiscounts: selected.filter(benefit => benefit.attributes.value < 0).length > 0,
    haveAnyServices: selected.filter(benefit => benefit.attributes.value > 0).length > 0,
    discountsValue: discountsValue(),
    servicesValue: servicesValue(),
    price,
    leasingActiveProgram,
    leasingParameters,
    leasingEnabledByUser,
    leasingBenefitAmount,
    getCarValue: getCarValue(),
    finalCarPrice: finalCarPrice(),
    formattedCurrency: formatCurrency(currency),
    vehicleType,
    taxes,
    taxesAmount,
    selectedAccessoriesValue: accessoriesValue(),
  }
}

export default connect(mapStateToProps)(Summary)
