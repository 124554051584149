import { COUNTRY_CODES } from '../../../constants'
import { setPhoneCountry } from '../../../store/actions'
import {
  setBuyModelStandaloneIsWidgetLoaded,
  setBuyModelStandaloneIsWidgetLoadingFailed,
  setBuyModelStandaloneLocale,
  setBuyModelStandaloneMainSettings,
  setBuyModelStandaloneModels,
} from '../../../store/actions/buy_model_standalone'
import store from '../../../store/store'
import { formatPossibleCountryCodes, isCountryCodeAvailableToAdd } from '../../formatPossibleCountryCodes'
import { normalizeVisual } from '../../normalize_visual'
import { formSetter } from './form'
import { languageSetter } from './language'
import { paletteSetter } from './palette'

export const buyModelStandaloneLoader = ({
  visual,
  locale,
  currency,
  widgetId,
  clientModels,
  disableSmartContractLink,
  defaultCountryCode,
  possibleCountryCodes,
  initialBoot,
  dataFromLocalStorage,
}) => {
  let success = true

  if (!initialBoot) {
    widgetLoadingWasFailed(false)
  }

  if (!clientModels || !clientModels.length) {
    if (!initialBoot) {
      widgetLoadingWasFailed(true)
    }

    console.error('No models in buy model standalone')
    return false
  }

  try {
    paletteSetter(visual.palette)

    languageSetter(locale)
    store.dispatch(setBuyModelStandaloneLocale(locale))

    // + проверка заполенени полей формы:
    // если поле было заполнено неверно и сохранено в ЛС,
    // но потом его убрали из видимых,
    // то СК никогда не сохранится
    formSetter(visual.fields, locale, dataFromLocalStorage)
    // - проверка заполенени полей формы

    if (!possibleCountryCodes || possibleCountryCodes?.length === 0) {
      possibleCountryCodes = Object.keys(COUNTRY_CODES)
    }

    if (initialBoot && dataFromLocalStorage?.form) {
      const formLS = dataFromLocalStorage.form
      const countryLS = formLS?.phoneSelection?.country

      if (isCountryCodeAvailableToAdd(countryLS)) {
        if (countryLS !== defaultCountryCode) {
          defaultCountryCode = countryLS
        }

        if (!possibleCountryCodes.includes(countryLS)) {
          possibleCountryCodes.push(countryLS)
        }
      }
    }

    let { formattedDefaultCountryCode, formattedPossibleCountryCodes } = formatPossibleCountryCodes({
      defaultCountryCode,
      possibleCountryCodes,
    })

    store.dispatch(setPhoneCountry(formattedDefaultCountryCode))

    store.dispatch(
      setBuyModelStandaloneMainSettings({
        widgetId,
        visual: normalizeVisual(visual),
        currency,
        disableSmartContractLink,
        defaultCountryCode: formattedDefaultCountryCode,
        possibleCountryCodes: formattedPossibleCountryCodes,
      }),
    )

    store.dispatch(setBuyModelStandaloneModels(clientModels))
  } catch (e) {
    widgetLoadingWasFailed(true)
    success = false
    console.error('[smart.contract] unable to boot buy model', e)
  }

  return initialBoot ? success : true
}

const widgetLoadingWasFailed = state => {
  store.dispatch(setBuyModelStandaloneIsWidgetLoaded(state))
  store.dispatch(setBuyModelStandaloneIsWidgetLoadingFailed(state))
}
