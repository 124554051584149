/**
 * Нажатие на кнопку онлайн-оплаты на экране онлайн-оплаты (1 раз, до проверки ошибок и создания СК)
 * @param buttonName - текст на кнопке
 * @param car - данные о машине
 * @param widget_id
 * @param currency
 * @constructor
 */
export const SmartContract_Prepay2Submit = ({ buttonName, car, widget_id, currency }) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Prepay2',
      Status: 'Submit',
      buttonName: buttonName || 'Не удалось определить текст на кнопке онлайн оплаты',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id,
      deal_id: '',
      eventValue: car.price,
      currency,
    })
}

/**
 * Нажатие на кнопку онлайн-оплаты на экране онлайн-оплаты (2 раз, после создания СК)
 * @param buttonName - текст на кнопке
 * @param car - данные о машине
 * @param widget_id
 * @param currency
 * @param contractResponse - сохраненный СК
 * @constructor
 */
export const SmartContract_Prepay2Success = ({ buttonName, car, widget_id, currency, contractResponse }) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Prepay2',
      Status: 'Success',
      buttonName: buttonName || 'Не удалось определить текст на кнопке онлайн оплаты',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: `${contractResponse.data.genericId.toUpperCase()}-${contractResponse.data.id}`,
      eventValue: car.price,
      currency,
    })
}

/**
 * Нажатие на кнопку сохранения в футере виджета (перед сохранением)
 * @param createSmartContractButtonText
 * @param car
 * @param widget_id
 * @param currency
 * @constructor
 */
export const SmartContract_Save_Submit = ({ createSmartContractButtonText, car, widget_id, currency }) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Save',
      Status: 'Submit',
      buttonName: createSmartContractButtonText || 'Не удалось определить текст на кнопке сохранения СК',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: '',
      eventValue: car.price,
      currency,
    })
}

/**
 * Нажатие на кнопку сохранения в футере виджета (после сохранением)
 * @param createSmartContractButtonText
 * @param car
 * @param widget_id
 * @param currency
 * @param smartContract
 * @constructor
 */
export const SmartContract_Save_Success = ({
  createSmartContractButtonText,
  car,
  widget_id,
  currency,
  smartContract,
}) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Save',
      Status: 'Success',
      buttonName: createSmartContractButtonText || 'Не удалось определить текст на кнопке сохранения СК',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: `${smartContract.genericId.toUpperCase()}-${smartContract.id}`,
      eventValue: car.price,
      currency,
    })
}

/**
 * Нажатие на кнопку перехода на экран оналйн оплаты в футере виджета (до перехода)
 * @param buttonText
 * @param car
 * @param widget_id
 * @param currency
 * @constructor
 */
export const SmartContract_Prepay1_Submit = ({ buttonText, car, widget_id, currency }) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Prepay1',
      Status: 'Submit',
      buttonName: buttonText || 'Не удалось определить текст на перехода на онлайн оплату',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: '',
      eventValue: car.price,
      currency,
    })
}

/**
 * Нажатие на кнопку перехода на экран оналйн оплаты в футере виджета (после перехода)
 * @param buttonText
 * @param car
 * @param widget_id
 * @param currency
 * @constructor
 */
export const SmartContract_Prepay1_Success = ({ buttonText, car, widget_id, currency }) => {
  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Prepay1',
      Status: 'Success',
      buttonName: buttonText || 'Не удалось определить текст на перехода на онлайн оплату',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: '',
      eventValue: car.price,
      currency,
    })
}
