module.exports = {
  title: 'Trade-in calculator',

  shortYear: 'y.',
  kilometers: 'km',

  yourCar: 'Your car',

  rateCar: 'Rate your car',
  showCarEstimation: 'Show trade-in estimation',

  averageCarPriceText: 'The average cost of your car (± 5%)',
  averageCarZeroPriceText: 'Enter your car expected estimation',
  averageCarZeroPriceTextDisabled:
    'We can not show estimation of the car right now, but we send all your car data to the manager',
  desiredCarText: 'What car do you want to buy?',

  openSCStep1: 'Continue',
  openSCStep2: 'Visit',
  openSCStep3: 'Apply price',

  SCDisclaimerText:
    'Smart Contract is an online tool for selecting favorable terms for a credit, trade-in, leasing, as well as booking a car from an authorized dealer.',
  SCDisclaimerVideo: 'Watch video about Smart Contract',

  noData: 'Nothing was found',

  inputPlaceholder: 'Start typing here ...',

  selectCarPlaceholder: 'Select the car',
  selectCarPlaceholderEmpty: "You haven't chosen a car",

  successText: 'We have saved your calculations',
  successTextWithoutLink: 'Thank you! Our manager will contact you shortly.',

  enterMileage: 'Select or enter the mileage',

  ownerCarPricePlaceholder: 'Enter the cost of your car',
  ownerCarPriceButton: 'Disagree with the estimation?',

  generalError: 'Unfortunately, an error has been occurred while loading the Trade-in Calculator',
}
