import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './summaryRow.module.scss'

function SummaryRow({ children, price, color }) {
  return (
    <div className={s.row} data-project-name={getProjectName()}>
      <div className={s.row__text}>{children}:</div>
      <div className={s.row__price}>{price}</div>
    </div>
  )
}

export default SummaryRow
