import i18next from 'i18next'

import { CREDIT_SETTINGS_BEHAVIOR, FINANCE_USER_BEHAVIOUR, LEASING_SETTINGS_BEHAVIOR } from '../../../constants'
import { formatPrice } from '../../../helpers'

export const creditText = creditBenefitAmount => {
  if (creditBenefitAmount < 0) {
    return i18next.t('credit.state.subtitleWithBenefit')
  } else {
    return i18next.t('credit.state.subtitleWithoutBenefit')
  }
}

export const creditAdditionalText = (
  isYourCarIsTooExpensive,
  creditBehaviourBySettings,
  activeCreditProgram,
  creditPeriod,
  financeBehaviourByUser,
  creditInitialFee,
  currency,
) => {
  if (isYourCarIsTooExpensive) {
    return i18next.t('credit.state.unavailable')
  }
  if (CREDIT_SETTINGS_BEHAVIOR.ENABLED === creditBehaviourBySettings) {
    if (activeCreditProgram?.id) {
      return i18next
        .t('credit.state.additionalTextWithCreditData', {
          bank: activeCreditProgram?.bank?.name,
          program: activeCreditProgram?.name,
          rate: activeCreditProgram?.rate,
          period: creditPeriod,
        })
        .replace(/\&quot\;/g, '"') // TODO: Кажется как4-то странно. Но разбираться нет желания. interpolation: { escapeValue: false } <- Должно сработать но надо проверять
    } else {
      return i18next.t('credit.state.initialAdditionalText')
    }
  }
  if (CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS === creditBehaviourBySettings) {
    if (FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS === financeBehaviourByUser) {
      return i18next.t('credit.state.additionalTextWithCreditParameters', {
        initialFee: formatPrice(creditInitialFee, currency),
        period: creditPeriod,
      })
    } else {
      return i18next.t('credit.state.initialAdditionalTextWithoutEstimation')
    }
  }
}

export const showCreditEditLink = (
  activeCreditProgram,
  isYourCarIsTooExpensive,
  creditBehaviourBySettings,
  financeBehaviourByUser,
) => {
  return (
    (activeCreditProgram?.id && !isYourCarIsTooExpensive) ||
    (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS &&
      financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS)
  )
}

export const creditSecondPrice = (
  creditBehaviourBySettings,
  activeCreditProgram,
  formatCreditMinimalPrice,
  formatCreditMinimalPriceFormatted,
  financeBehaviourByUser,
  currency,
) => {
  let res = null
  let from = false
  if (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED) {
    if (!activeCreditProgram?.id && formatCreditMinimalPrice !== 0) {
      res = formatPrice(formatCreditMinimalPrice, currency)
      from = true
    } else if (activeCreditProgram?.id) {
      res = formatPrice(activeCreditProgram?.payment, currency)
    }
  } else if (
    creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS &&
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS
  ) {
    res = i18next.t('credit.state.autoEstimationIsImpossible')
  }
  return { res, from }
}

export const leasingText = leasingBenefitAmount => {
  if (leasingBenefitAmount < 0) {
    return i18next.t('leasing.state.subtitleWithBenefit')
  } else {
    return i18next.t('leasing.state.subtitleWithoutBenefit')
  }
}

export const leasingAdditionalText = (
  leasingBehaviourBySettings,
  activeLeasingProgram,
  leasingPeriod,
  financeBehaviourByUser,
  leasingInitialFee,
  currency,
  hideProgramPercentage,
  hideProgramBankName,
) => {
  if (LEASING_SETTINGS_BEHAVIOR.ENABLED === leasingBehaviourBySettings) {
    if (activeLeasingProgram?.id) {
      if (hideProgramPercentage && hideProgramBankName) {
        return i18next.t('leasing.state.additionalTextWithCreditDataWithoutRateAndBankName', {
          program: activeLeasingProgram?.name,
          period: leasingPeriod,
        })
      }

      if (hideProgramPercentage) {
        return i18next.t('leasing.state.additionalTextWithCreditDataWithoutRate', {
          bank: activeLeasingProgram?.bank?.name,
          program: activeLeasingProgram?.name,
          period: leasingPeriod,
        })
      }

      if (hideProgramBankName) {
        return i18next.t('leasing.state.additionalTextWithCreditDataWithoutBankName', {
          program: activeLeasingProgram?.name,
          rate: activeLeasingProgram?.percentage,
          period: leasingPeriod,
        })
      }

      return i18next.t('leasing.state.additionalTextWithCreditData', {
        bank: activeLeasingProgram?.bank?.name,
        program: activeLeasingProgram?.name,
        rate: activeLeasingProgram?.percentage,
        period: leasingPeriod,
      })
    } else {
      return i18next.t('leasing.state.initialAdditionalText')
    }
  }
  if (LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS === leasingBehaviourBySettings) {
    if (FINANCE_USER_BEHAVIOUR.NONE !== financeBehaviourByUser) {
      return i18next.t('leasing.state.additionalTextWithCreditParameters', {
        initialFee: formatPrice(leasingInitialFee, currency),
        period: leasingPeriod,
      })
    } else {
      return i18next.t('leasing.state.initialAdditionalTextWithoutEstimation')
    }
  }
}

export const leasingSecondPrice = (
  leasingBehaviourBySettings,
  activeLeasingProgram,
  formatLeasingMinimalPrice,
  formatLeasingMinimalPriceFormatted,
  financeBehaviourByUser,
  currency,
) => {
  let res = null
  let from = false
  if (leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED) {
    if (!activeLeasingProgram?.id && formatLeasingMinimalPrice !== 0) {
      res = formatPrice(formatLeasingMinimalPrice, currency)
      from = true
    } else if (activeLeasingProgram?.id) {
      res = formatPrice(activeLeasingProgram.paymentPerMonth, currency)
    }
  } else if (
    leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS &&
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS
  ) {
    res = i18next.t('leasing.state.autoEstimationIsImpossible')
  }
  return { res, from }
}

export const showLeasingEditLink = (activeLeasingProgram, leasingBehaviourBySettings, financeBehaviourByUser) => {
  if (activeLeasingProgram?.id && leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED) {
    return true
  }

  if (
    leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS &&
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS
  ) {
    return true
  }

  return false
}

export const creditProgramHintText = ({ creditInitialFee, creditBuybackPayment, creditPeriod, currency }) => {
  const initialFee = `${formatPrice(creditInitialFee, currency)}`
  const buybackPayment = `${formatPrice(creditBuybackPayment, currency)}`

  if (creditBuybackPayment) {
    return i18next.t('credit.state.defaultProgramHint', {
      initialFee,
      buybackPayment,
      period: creditPeriod,
    })
  } else {
    return i18next.t('credit.state.defaultProgramNoBuybackPaymentHint', {
      initialFee,
      period: creditPeriod,
    })
  }
}
