import { formatPrice } from '../../../helpers'
import ICircleIcon from '../../../svgComponents/iCircle'
import Tooltip from '../../Ui/tooltip'
import s from './styles/accessory_sm.module.scss'

function AccessorySm({ props, category }) {
  return (
    <div className={[s.wrapper].join(' ')}>
      <div className={s.wrapper__item}>
        <img src={props.attributes?.image?.url || '../assets/no-img.png'} alt="" width="100%" />
      </div>
      <div className={s.wrapper__item}>
        <Tooltip position="top" fullHeight={false} tooltipContent={props.attributes.description}>
          <div className={s.info}>
            <div className={s.info__category}>{category}</div>
            <div className={s.info__title}>
              {props.attributes.name}
              <span>
                <ICircleIcon />
              </span>
            </div>
            {props.attributes.price && (
              <div className={s.info__price}>{formatPrice(props.attributes.price, props.currency)}</div>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default AccessorySm
