import { debouncedEmitUpdatedEvent, emitUpdatedEvent, getAdditionalCarPrices } from '../../helpers'
import { API_ACTIONS } from '../types'

export function updatedEventEmitter(store) {
  return function wrapDispatch(next) {
    return function handleAction(action) {
      const nextState = next(action)

      const state = store.getState()

      if (state.api.widgetBooted) {
        const { settings, car, cars, tradeIn, credit, benefits, form, onlinePayment, saved_sc } = state

        const data = {
          settings,
          car: { ...car, ...getAdditionalCarPrices(state) },
          cars: cars.list,
          tradeIn,
          credit,
          benefits,
          form,
          onlinePayment,
          savedSc: saved_sc,
        }

        const apiAction = action.type === API_ACTIONS.LOG_LAST_METHOD

        const payload = { data, type: apiAction ? action.payload : 'updated' }

        if (settings.restrictions.sc_widget_events_api_available) {
          if (apiAction) {
            emitUpdatedEvent(payload)
          } else {
            debouncedEmitUpdatedEvent(payload)
          }
        }
      }

      return nextState
    }
  }
}
