import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  CREDIT_SETTINGS_BEHAVIOR,
  FINANCE_USER_BEHAVIOUR,
  LEASING_SETTINGS_BEHAVIOR,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import { carPriceFromTI, creditAmount, formatCurrency, formatPrice } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  calculateCreditPrograms,
  calculateLeasingPrograms,
  goToCredit,
  goToLeasing,
  switchCredit,
  switchLeasing,
} from '../../../store/actions'
import { createCreditCalculateProgramsPayload } from '../../../store/helpers/credit'
import { createLeasingCalculateProgramsPayload } from '../../../store/helpers/leasing'
import BlockState from '../../Ui/blockState'
import BlockVariant from '../../Ui/blockVariant'
import {
  creditAdditionalText,
  creditProgramHintText,
  creditSecondPrice,
  creditText,
  leasingAdditionalText,
  leasingSecondPrice,
  leasingText,
  showCreditEditLink,
  showLeasingEditLink,
} from './helpers'
import s from './state.module.scss'
import { useDebouncedEffect } from './useDebouncedEffect'

function CreditState({
  currency,
  tradeInBehaviourBySettings,
  financeBehaviourByUser,

  creditResolutionEnabled,
  creditBehaviourBySettings,
  isItAllowedToCalculateCredit,
  creditBenefitAmount,
  activeCreditProgram,
  formatCreditMinimalPriceFormatted,
  formatCreditMinimalPrice,
  isYourCarIsTooExpensive,
  creditPeriod,
  creditInitialFee,
  creditBuybackPayment,

  leasingResolutionEnabled,
  isItAllowedToCalculateLeasing,
  leasingBenefitAmount,
  activeLeasingProgram,
  leasingInitialFee,
  leasingPeriod,
  leasingBehaviourBySettings,
  formatLeasingMinimalPrice,
  formatLeasingMinimalPriceFormatted,

  formattedCurrency,
  vehicleType,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const creditData = useSelector(createCreditCalculateProgramsPayload)
  const leasingData = useSelector(createLeasingCalculateProgramsPayload)

  const hideProgramPercentage = useSelector(state => state.settings.visual.leasing.hideProgramPercentage)
  const disableCash = useSelector(state => state.settings.disableCash)
  const hideProgramBankName = useSelector(state => state.settings.visual.leasing.hideProgramBankName)

  useDebouncedEffect(
    () => {
      if (isItAllowedToCalculateLeasing) {
        dispatch(calculateLeasingPrograms(leasingData))
      }
    },
    [leasingData, dispatch, isItAllowedToCalculateLeasing],
    500,
  )

  useDebouncedEffect(
    () => {
      if (isItAllowedToCalculateCredit) {
        dispatch(calculateCreditPrograms(creditData))
      }
    },
    [creditData, dispatch, isItAllowedToCalculateCredit],
    500,
  )

  // чекбокс кредита
  const onChangeCreditCheckbox = event => {
    // если КП не выбрана, то переходим на окно кредита
    // иначе включаем/выключаем КП

    if (isYourCarIsTooExpensive) {
      return
    }

    if (
      disableCash &&
      activeCreditProgram?.id &&
      [FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS, FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED].includes(
        financeBehaviourByUser,
      )
    ) {
      return
    }

    const {
      target: { checked },
    } = event

    if (
      !checked &&
      [FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED, FINANCE_USER_BEHAVIOUR.LEASING_APPLIED].includes(financeBehaviourByUser)
    ) {
      return
    }

    if (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED) {
      if (activeCreditProgram?.id) {
        if (checked && isYourCarIsTooExpensive) {
          return false
        }

        dispatch(switchCredit(checked))
      } else {
        dispatch(goToCredit())
      }
    }

    if (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
      if (financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.NONE) {
        dispatch(goToCredit())
      } else {
        dispatch(switchCredit(checked))
      }
    }
  }

  // чекбокс лизинга
  const onChangeLeasingCheckbox = event => {
    // если КП не выбрана, то переходим на окно кредита
    // иначе включаем/выключаем КП

    const {
      target: { checked },
    } = event

    if (
      disableCash &&
      activeLeasingProgram?.id &&
      [FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS, FINANCE_USER_BEHAVIOUR.LEASING_APPLIED].includes(
        financeBehaviourByUser,
      )
    ) {
      return
    }

    if (
      !checked &&
      [FINANCE_USER_BEHAVIOUR.LEASING_APPLIED, FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS].includes(
        financeBehaviourByUser,
      )
    ) {
      return
    }

    if (leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED) {
      if (activeLeasingProgram?.id) {
        dispatch(switchLeasing(checked))
      } else {
        dispatch(goToLeasing())
      }
    }

    if (leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
      if (financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.NONE) {
        dispatch(goToLeasing())
      } else {
        dispatch(switchLeasing(checked))
      }
    }
  }

  const buyCarWithCashHandler = event => {
    const {
      target: { checked },
    } = event

    if (
      !checked &&
      [
        FINANCE_USER_BEHAVIOUR.NONE,
        FINANCE_USER_BEHAVIOUR.CASH,
        FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS,
        FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS,
      ].includes(financeBehaviourByUser)
    ) {
      return
    }

    if (
      [
        FINANCE_USER_BEHAVIOUR.NONE,
        FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED,
        FINANCE_USER_BEHAVIOUR.LEASING_APPLIED,
      ].includes(financeBehaviourByUser)
    ) {
      dispatch(switchLeasing(!checked))
    }
  }

  // проверка на включение чекбокса налики - нужно упростить
  const isCashRadioChecked = () => {
    const isCreditEnabledBySettings = creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED
    const isCreditEnabledWithoutCalculationsBySettings =
      creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS

    const isCreditEnabledByUser = [FINANCE_USER_BEHAVIOUR.CASH, FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS].includes(
      financeBehaviourByUser,
    )
    const isCreditEnabledWithoutCalculationsByUser = [
      FINANCE_USER_BEHAVIOUR.CASH,
      FINANCE_USER_BEHAVIOUR.NONE,
    ].includes(financeBehaviourByUser)

    const isLeasingEnabledBySettings = leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED
    const isLeasingEnabledWithoutCalculationsBySettings =
      leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS

    const isLeasingEnabledByUser = [FINANCE_USER_BEHAVIOUR.CASH, FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS].includes(
      financeBehaviourByUser,
    )
    const isLeasingEnabledWithoutCalculationsByUser = [
      FINANCE_USER_BEHAVIOUR.CASH,
      FINANCE_USER_BEHAVIOUR.NONE,
    ].includes(financeBehaviourByUser)

    if (financeBehaviourByUser?.includes('credit')) {
      if (isCreditEnabledBySettings) {
        return isCreditEnabledByUser || isYourCarIsTooExpensive
      }

      if (isCreditEnabledWithoutCalculationsBySettings) {
        return isCreditEnabledWithoutCalculationsByUser || isYourCarIsTooExpensive
      }
    } else if (financeBehaviourByUser?.includes('leasing')) {
      if (isLeasingEnabledBySettings) {
        return isLeasingEnabledByUser
      }

      if (isLeasingEnabledWithoutCalculationsBySettings) {
        return isLeasingEnabledWithoutCalculationsByUser
      }
    } else if (financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.CASH) {
      return true
    }

    return false
  }

  return (
    <BlockState
      title={t('credit.state.title')}
      isActive={creditResolutionEnabled || leasingResolutionEnabled}
      number={tradeInBehaviourBySettings === TRADE_IN_SETTINGS_BEHAVIOR.DISABLED ? 1 : 2}
    >
      <div className={s.variants} data-project-name={getProjectName()}>
        <div className={s.variants__item}>
          {isYourCarIsTooExpensive && (
            <div className={s.warning}>{t(`credit.state.yourCarCostsMoreThanCarYouWant.${vehicleType}`)}</div>
          )}
        </div>
        {creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.DISABLED && (
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={creditText(creditBenefitAmount)}
              secondRow={creditAdditionalText(
                isYourCarIsTooExpensive,
                creditBehaviourBySettings,
                activeCreditProgram,
                creditPeriod,
                financeBehaviourByUser,
                creditInitialFee,
                currency,
              )}
              firstPrice={
                creditBenefitAmount < 0 && !isYourCarIsTooExpensive ? formatPrice(creditBenefitAmount, currency) : null
              }
              secondPrice={
                creditSecondPrice(
                  creditBehaviourBySettings,
                  activeCreditProgram,
                  formatCreditMinimalPrice,
                  formatCreditMinimalPriceFormatted,
                  financeBehaviourByUser,
                  currency,
                ).res
              }
              tooltipContent={t(`credit.state.benefitHint.${vehicleType}`)}
              tooltipProgramContent={
                activeCreditProgram?.id
                  ? t('credit.state.activeProgramHint')
                  : creditProgramHintText({ creditInitialFee, creditBuybackPayment, creditPeriod, currency })
              }
              isWithoutCalculation={creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS}
              isActive={creditResolutionEnabled}
              isDisabled={isYourCarIsTooExpensive}
              showLink={showCreditEditLink(
                activeCreditProgram,
                isYourCarIsTooExpensive,
                creditBehaviourBySettings,
                financeBehaviourByUser,
              )}
              linkBehaviour={() =>
                dispatch(goToCredit(financeBehaviourByUser !== FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED))
              }
              linkText={t('edit')}
              checkboxBehaviour={e => onChangeCreditCheckbox(e)}
              id="buyCarWithCredit"
              type="calculation"
              currency={formattedCurrency}
              isCredit={true}
              from={
                creditSecondPrice(
                  creditBehaviourBySettings,
                  activeCreditProgram,
                  formatCreditMinimalPrice,
                  formatCreditMinimalPriceFormatted,
                  financeBehaviourByUser,
                  currency,
                ).from
              }
            />
          </div>
        )}
        {leasingBehaviourBySettings !== LEASING_SETTINGS_BEHAVIOR.DISABLED && (
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={leasingText(leasingBenefitAmount)}
              secondRow={leasingAdditionalText(
                leasingBehaviourBySettings,
                activeLeasingProgram,
                leasingPeriod,
                financeBehaviourByUser,
                leasingInitialFee,
                currency,
                hideProgramPercentage,
                hideProgramBankName,
              )}
              firstPrice={leasingBenefitAmount !== 0 ? formatPrice(leasingBenefitAmount, currency) : null}
              secondPrice={
                leasingSecondPrice(
                  leasingBehaviourBySettings,
                  activeLeasingProgram,
                  formatLeasingMinimalPrice,
                  formatLeasingMinimalPriceFormatted,
                  financeBehaviourByUser,
                  currency,
                ).res
              }
              tooltipContent={t(`leasing.state.benefitHint.${vehicleType}`)}
              isWithoutCalculation={
                leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS
              }
              isActive={leasingResolutionEnabled}
              isDisabled={false}
              showLink={showLeasingEditLink(activeLeasingProgram, leasingBehaviourBySettings, financeBehaviourByUser)}
              linkBehaviour={() =>
                dispatch(goToLeasing(financeBehaviourByUser !== FINANCE_USER_BEHAVIOUR.LEASING_APPLIED))
              }
              linkText={t('edit')}
              checkboxBehaviour={e => onChangeLeasingCheckbox(e)}
              id="buyCarWithLeasing"
              type="calculation"
              currency={currency}
              isCredit={true}
              from={
                leasingSecondPrice(
                  leasingBehaviourBySettings,
                  activeLeasingProgram,
                  formatLeasingMinimalPrice,
                  formatLeasingMinimalPriceFormatted,
                  financeBehaviourByUser,
                  currency,
                ).from
              }
            />
          </div>
        )}
        {!disableCash && (
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={t('credit.state.buyWithCash')}
              secondRow=""
              firstPrice=""
              secondPrice=""
              isWithoutCalculation={false}
              isActive={isCashRadioChecked()}
              isDisabled={false}
              showLink={false}
              linkBehaviour=""
              linkText={t('edit')}
              checkboxBehaviour={e => buyCarWithCashHandler(e)}
              id="buyCarWithCash"
              type="calculation"
            />
          </div>
        )}
      </div>
    </BlockState>
  )
}

function mapStateToProps({
  settings: { currency, disableCash, vehicleType },
  car: { price },
  benefits: { selected },
  accessories: { selected: selectedAccessories },
  tradeIn: {
    behaviour: tradeInBehaviourBySettings,
    behaviourByUser: tradeInBehaviourByUser,
    rate: {
      result: { possibleCarPrice },
    },
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    ownerCarPriceEnabled,
    ownerCarPrice,
  },
  credit: {
    programs: creditPrograms,
    active_program: activeCreditProgram,
    parameters: { initialFee: creditInitialFee, buybackPayment: creditBuybackPayment, period: creditPeriod },
    benefitValue: creditBenefitAmount,
    behaviourByUser: financeBehaviourByUser,
    behaviour: creditBehaviourBySettings,
  },
  leasing: {
    programs: leasingPrograms,
    activeProgram: activeLeasingProgram,
    parameters: { initialFee: leasingInitialFee, period: leasingPeriod },
    benefitValue: leasingBenefitAmount,
    behaviour: leasingBehaviourBySettings,
  },
}) {
  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  const isItAllowedToCalculateCredit = CREDIT_SETTINGS_BEHAVIOR.ENABLED === creditBehaviourBySettings
  const isItAllowedToCalculateLeasing = LEASING_SETTINGS_BEHAVIOR.ENABLED === leasingBehaviourBySettings

  const carPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount: tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount: creditBenefitAmount,
  })

  const isYourCarIsTooExpensive = () => {
    const isTICarPriceMoreThan095StockCarPrice = carPrice > price * 0.95
    const realTradeInCarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)
    const isRealCarPriceMoreThen095CreditAmount = Math.ceil((realTradeInCarPrice * 100) / amount) > 95

    return (isTICarPriceMoreThan095StockCarPrice || isRealCarPriceMoreThen095CreditAmount) && tradeInEnabled
  }

  const creditResolutionEnabledCondition1 =
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED &&
    creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED

  const creditResolutionEnabledCondition2 =
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS &&
    creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS

  const isOnlyCredit =
    [CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS, CREDIT_SETTINGS_BEHAVIOR.ENABLED].includes(
      creditBehaviourBySettings,
    ) && leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.DISABLED

  const creditResolutionEnabledCondition3 =
    disableCash &&
    isOnlyCredit &&
    financeBehaviourByUser !== FINANCE_USER_BEHAVIOUR.NONE &&
    [FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS, FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED].includes(
      financeBehaviourByUser,
    ) &&
    activeCreditProgram?.id

  const creditResolutionEnabled =
    (creditResolutionEnabledCondition1 || creditResolutionEnabledCondition2 || creditResolutionEnabledCondition3) &&
    !isYourCarIsTooExpensive()

  const leasingResolutionEnabledCondition1 =
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_APPLIED &&
    leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED

  const leasingResolutionEnabledCondition2 =
    financeBehaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS &&
    leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS

  const isOnlyLeasing =
    [LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS, LEASING_SETTINGS_BEHAVIOR.ENABLED].includes(
      leasingBehaviourBySettings,
    ) && creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.DISABLED

  const leasingResolutionEnabledCondition3 =
    disableCash &&
    isOnlyLeasing &&
    financeBehaviourByUser !== FINANCE_USER_BEHAVIOUR.NONE && [
      FINANCE_USER_BEHAVIOUR.LEASING_APPLIED,
      FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS,
    ] &&
    leasingBehaviourBySettings === LEASING_SETTINGS_BEHAVIOR.ENABLED &&
    activeLeasingProgram?.id

  const leasingResolutionEnabled =
    leasingResolutionEnabledCondition1 || leasingResolutionEnabledCondition2 || leasingResolutionEnabledCondition3

  const formatCreditMinimalPrice = () => {
    let res = 0
    if (creditPrograms && creditPrograms.length > 0) {
      res = creditPrograms[0].payment
    }

    return Math.ceil(res)
  }

  const formatCreditMinimalPriceFormatted = () => {
    return formatPrice(formatCreditMinimalPrice(), currency)
  }

  const formatLeasingMinimalPrice = () => {
    let res = 0
    if (leasingPrograms && leasingPrograms.length > 0) {
      res = leasingPrograms[0].paymentPerMonth
    }

    return Math.ceil(res)
  }

  const formatLeasingMinimalPriceFormatted = () => {
    return formatPrice(formatLeasingMinimalPrice(), currency)
  }

  return {
    creditBehaviourBySettings,
    currency,
    possibleCarPrice,
    creditResolutionEnabled,
    leasingResolutionEnabled,
    creditBenefitAmount,
    creditPeriod,
    creditInitialFee,
    creditBuybackPayment,
    financeBehaviourByUser,
    activeCreditProgram,
    price,
    isYourCarIsTooExpensive: isYourCarIsTooExpensive(),
    creditAmount: amount,

    formatCreditMinimalPrice: formatCreditMinimalPrice(),
    formatCreditMinimalPriceFormatted: formatCreditMinimalPriceFormatted(),

    tradeInBehaviourBySettings,

    leasingPrograms,
    activeLeasingProgram,
    leasingInitialFee,
    leasingPeriod,
    leasingBehaviourBySettings,
    leasingBenefitAmount,

    isItAllowedToCalculateCredit,
    isItAllowedToCalculateLeasing,

    formatLeasingMinimalPrice: formatLeasingMinimalPrice(),
    formatLeasingMinimalPriceFormatted: formatLeasingMinimalPriceFormatted(),
    formattedCurrency: formatCurrency(currency),
    vehicleType,
  }
}

export default connect(mapStateToProps)(CreditState)
