/* eslint-disable no-console */
// window.scLogger = Logger

class Logger {
  constructor() {
    this.registry = []
    window.scLogger = this
  }

  log(...args) {
    this.registry.push({
      time: Date.now(),
      type: 'log',
      messages: [...args],
    })
  }

  error(message) {
    this.registry.push({
      time: Date.now(),
      type: 'error',
      messages: [message],
    })
  }

  getLog() {
    return this.registry
  }

  printLog() {
    this.registry.forEach(item => {
      if (item.type === 'log') {
        console.log(...item.messages)
      }

      if (item.type === 'error') {
        console.error(item.messages[0])
      }
    })
  }
}

export const logger = new Logger()
