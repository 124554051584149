import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TRADE_IN_USER_BEHAVIOUR } from '../../../constants'
import { creditAmount, formatPrice, leasingAmount } from '../../../helpers'
import s from './tooltipTable.module.scss'

function TooltipTable({
  type,
  price,
  currency,
  selected,
  tradeInEnabled,
  tradeInWithoutTradeIn,
  tradeInBenefitAmount,
  tradeInBenefitAmountWithoutTradeIn,
  creditBenefitAmount,
  creditAmount,
  leasingAmount,
  leasingBenefitAmount,
  vehicleType,
  selectedAccessories,
}) {
  const { t } = useTranslation()

  const content = () => {
    if (type === 'creditAmount') {
      return (
        <div className={s.wrapper}>
          <div className={s.wrapper__title}>{t(`leasing.screen.carPrice.${vehicleType}`)}</div>
          <div className={[s.wrapper__body, s.body].join(' ')}>
            <div className={[s.body__row, s.row].join(' ')}>
              <div className={s.row__text}>{t(`summary.carPrice.${vehicleType}`)}</div>
              <div className={s.row__value}>{formatPrice(price, currency)}</div>
            </div>
            {selected.map((b, i) => {
              return (
                <div className={[s.body__row, s.row].join(' ')} key={i}>
                  <div className={s.row__text}>{b.attributes.name}</div>
                  <div className={s.row__value}>{formatPrice(b.attributes.value, currency)}</div>
                </div>
              )
            })}
            {selectedAccessories.map((b, i) => {
              return (
                <div className={[s.body__row, s.row].join(' ')} key={i}>
                  <div className={s.row__text}>
                    {b.attributes.name.length > 20 ? `${b.attributes.name.substring(0, 17)} ...` : b.attributes.name}
                  </div>
                  <div className={s.row__value}>{formatPrice(b.attributes.price, currency)}</div>
                </div>
              )
            })}
            {tradeInEnabled && tradeInBenefitAmount < 0 && (
              <div className={[s.body__row, s.row].join(' ')}>
                <div className={s.row__text}>{t('summary.tradeInBenefit')}</div>
                <div className={s.row__value}>{formatPrice(tradeInBenefitAmount, currency)}</div>
              </div>
            )}
            {tradeInWithoutTradeIn && tradeInBenefitAmountWithoutTradeIn < 0 && (
              <div className={[s.body__row, s.row].join(' ')}>
                <div className={s.row__text}>{t('summary.tradeInBenefitWithoutTradeIn')}</div>
                <div className={s.row__value}>{formatPrice(tradeInBenefitAmountWithoutTradeIn, currency)}</div>
              </div>
            )}
            {creditBenefitAmount < 0 && (
              <div className={[s.body__row, s.row].join(' ')}>
                <div className={s.row__text}>{t('summary.creditBenefit')}</div>
                <div className={s.row__value}>{formatPrice(creditBenefitAmount, currency)}</div>
              </div>
            )}
            <div className={[s.body__row, s.row].join(' ')}>
              <div className={s.row__text} />
              <div className={[s.row__value, s.fwExtraBold].join(' ')}>{formatPrice(creditAmount, currency)}</div>
            </div>
          </div>
        </div>
      )
    }

    if (type === 'leasingAmount') {
      return (
        <div className={s.wrapper}>
          <div className={s.wrapper__title}>{t(`leasing.screen.carPrice.${vehicleType}`)}</div>
          <div className={[s.wrapper__body, s.body].join(' ')}>
            <div className={[s.body__row, s.row].join(' ')}>
              <div className={s.row__text}>{t(`summary.carPrice.${vehicleType}`)}</div>
              <div className={s.row__value}>{formatPrice(price, currency)}</div>
            </div>
            {selected.map((b, i) => {
              return (
                <div className={[s.body__row, s.row].join(' ')} key={i}>
                  <div className={s.row__text}>{b.attributes.name}</div>
                  <div className={s.row__value}>{formatPrice(b.attributes.value, currency)}</div>
                </div>
              )
            })}
            {selectedAccessories.map((b, i) => {
              return (
                <div className={[s.body__row, s.row].join(' ')} key={i}>
                  <div className={s.row__text}>
                    {b.attributes.name.length > 20 ? `${b.attributes.name.substring(0, 17)} ...` : b.attributes.name}
                  </div>{' '}
                  <div className={s.row__value}>{formatPrice(b.attributes.price, currency)}</div>
                </div>
              )
            })}
            {leasingBenefitAmount < 0 && (
              <div className={[s.body__row, s.row].join(' ')}>
                <div className={s.row__text}>{t('summary.leasingBenefit')}</div>
                <div className={s.row__value}>{formatPrice(leasingBenefitAmount, currency)}</div>
              </div>
            )}
            <div className={[s.body__row, s.row].join(' ')}>
              <div className={s.row__text} />
              <div className={[s.row__value, s.fwExtraBold].join(' ')}>{formatPrice(leasingAmount, currency)}</div>
            </div>
          </div>
        </div>
      )
    }

    return <div>...</div>
  }

  return <div>{content()}</div>
}

function mapStateToProps({
  settings: { currency, vehicleType },
  car: { price },
  benefits: { selected },
  accessories: { selected: selectedAccessories },

  credit: { benefitValue: creditBenefitAmount },
  leasing: { benefitValue: leasingBenefitAmount },
  tradeIn: {
    behaviourByUser: tradeInBehaviorByUser,
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
  },
}) {
  const tradeInEnabled = tradeInBehaviorByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const tradeInWithoutTradeIn = tradeInBehaviorByUser === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED

  return {
    selected: selected.filter(s => {
      return s.attributes.value
    }),
    price,
    currency,
    creditBenefitAmount,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    leasingBenefitAmount,
    creditAmount: creditAmount({
      price,
      selectedBenefits: selected,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviorByUser,
      tradeInBenefitAmount,
      tradeInBenefitAmountWithoutTradeIn,
      creditBenefitAmount,
    }),
    leasingAmount: leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount),
    tradeInEnabled,
    tradeInWithoutTradeIn,
    vehicleType,
    selectedAccessories,
  }
}

export default connect(mapStateToProps)(TooltipTable)
