import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  TI_PARTNER,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_STANDALONE_SCREEN,
  TRADE_IN_STEP_WEIGHTS,
  TRADE_IN_STEP_WEIGHTS_BY_IDENTITY,
} from '../../constants'
import { formatMileage } from '../../helpers'
import TradeInStandaloneFilter from '../filter'
import s from './styles/filters.module.scss'

function TradeInStandaloneFilters({ step, values, standaloneScreen, isEstimationByIdentity }) {
  const { t } = useTranslation()

  const [hoveredStepDeletionButton, setHoveredStepDeletionButton] = useState(-1)

  // текущий номер шага, по нему определяем, какие шаги после него подходят под удаление
  let activeStepNumber = () => {
    let index = 0
    Object.keys(values)
      .sort((a, b) => {
        const weightA = isEstimationByIdentity ? TRADE_IN_STEP_WEIGHTS_BY_IDENTITY?.[a] : TRADE_IN_STEP_WEIGHTS?.[a]
        const weightB = isEstimationByIdentity ? TRADE_IN_STEP_WEIGHTS_BY_IDENTITY?.[b] : TRADE_IN_STEP_WEIGHTS?.[b]
        return weightA - weightB
      })
      .forEach((key, i) => {
        if (key === step) {
          return (index = i)
        }
      })
    return index
  }

  // делаем прозрачными шаги, которые будут сброшены при клике на крестик
  const userWantsToResetStep = v => {
    if (!isEstimationByIdentity) {
      setHoveredStepDeletionButton(v)
    }
  }

  // убрали курсор с крестика
  const userWantsToSaveStep = () => {
    if (!isEstimationByIdentity) {
      setHoveredStepDeletionButton(-1)
    }
  }

  // формирование шагов
  const items = Object.keys(values)
    .sort((a, b) => {
      const weightA = isEstimationByIdentity ? TRADE_IN_STEP_WEIGHTS_BY_IDENTITY?.[a] : TRADE_IN_STEP_WEIGHTS?.[a]
      const weightB = isEstimationByIdentity ? TRADE_IN_STEP_WEIGHTS_BY_IDENTITY?.[b] : TRADE_IN_STEP_WEIGHTS?.[b]
      return weightA - weightB
    })
    .map((key, i) => {
      let text = ''
      if (key === 'year' && values[key].value) {
        text = values[key].value + ` ${t('tradeInS.shortYear')}`
      } else if (key === 'identity' && values[key].value) {
        text = values[key].value
      } else if (key === 'mileage' && values[key].value) {
        text = formatMileage(values[key].value, t('tradeInS.kilometers'))
      } else {
        text = values[key].name
      }

      let willBeDeleted = () => {
        if (hoveredStepDeletionButton !== -1) {
          return i > hoveredStepDeletionButton
        } else {
          return i > activeStepNumber() && standaloneScreen === TRADE_IN_STANDALONE_SCREEN.RATE
        }
      }

      if (text) {
        return (
          <div className={s.filters__item} key={key}>
            <TradeInStandaloneFilter
              userWantsToResetStep={v => userWantsToResetStep(v)}
              userWantsToSaveStep={() => userWantsToSaveStep()}
              index={i}
              stepKey={key}
              value={text}
              isActive={key === step}
              willBeDeleted={willBeDeleted()}
            />
          </div>
        )
      } else {
        return null
      }
    })

  return (
    <div className={s.filters}>
      <div className={`${s.filters__item} ${s.filters__title}`}>
        <div className={s.title}>{t('tradeInS.yourCar')}</div>
      </div>
      {items}
    </div>
  )
}

function mapStateToProps({ tradeIn: { step, values, standaloneScreen, partner, estimationType } }) {
  const isEstimationByIdentity =
    partner === TI_PARTNER.AUTO_RU && estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ
  const availableValues = () => {
    let res = {}
    if (isEstimationByIdentity) {
      for (const key in values) {
        if (values.hasOwnProperty(key) && ['identity', 'city', 'mileage'].includes(key)) {
          res[key] = values[key]
        }
      }
    } else {
      res = values
    }
    return res
  }
  return {
    step,
    values: availableValues(),
    standaloneScreen,
    isEstimationByIdentity,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneFilters)
