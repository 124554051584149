/* eslint-disable no-template-curly-in-string */
import i18n from 'i18next'

import {
  bootBatch2,
  bootBatch2PayloadCreator,
  bootListUrl,
  dealershipsFormatter,
  // debug,
  formatPrice,
  presetParams,
} from './helpers'
import { languageSetter } from './helpers/app/boot/language'
import { bootedListEvent, setBootBatchListType, setCarList } from './store/actions'
import store from './store/store'

/**
 * Определена ли функция получения виджет_ид на списке для мультидилера
 */
const isWindowFunctionListWidgetIdDefined = () => {
  return (
    window['@kodix'] &&
    window['@kodix']['smartContract'] &&
    window['@kodix']['smartContract']['list'] &&
    window['@kodix']['smartContract']['list']['widgetId'] &&
    typeof window['@kodix']['smartContract']['list']['widgetId'] === 'function'
  )
}

/**
 * Экспортируемая функция
 * @param config - конфигурация скрипта
 * @returns {VoidFunction}
 */
export function SmartContactOnList(config) {
  if (process.env.NODE_ENV === 'development') {
    // console.log('init on list')
  }

  observerWrapper(config) // Сначала начинаем слушать изменения

  const cards = getInitialCarsOnPage(config) // И только затем работаем с карточками

  if (cards) {
    processListCards(cards, config)
  }
}

/**
 * Получает ифнормацию по тем карточкам, которые изначально были на странице
 * Должен вернуть список начальных карточек
 */
const getInitialCarsOnPage = config => {
  if (typeof config.car_card !== 'function') {
    return false
  }

  return config.car_card()
}

/**
 * Получает информацию по указанным карточкам
 * Должен вернуть объект в формате индентификатор_автомобиля: цена_автомобиля {'car_id': Number(car_price)}
 * @param specifiedCards - список карточек
 * @param config - конфигурация из настроек скрипта
 * @returns {Object}
 */
const getCarsInformation = (specifiedCards, config) => {
  const result = {}

  const isMultiDealer = isWindowFunctionListWidgetIdDefined()

  for (let card of specifiedCards) {
    const {
      id,
      link,
      price,
      widgetId,
      modelData,
      productType,
      discounts,
      dealerships,
      card_identity,
      additionalOptions,
    } = getCarInformation(card, config)

    // если определена isWindowFunctionListWidgetIdDefined для мультидилера то берем widget_id тольео из нее
    // чтобы не было дефолтных виджетов везде

    let widget_id = ''

    if (isMultiDealer) {
      if (widgetId) {
        widget_id = widgetId
      }
    } else {
      widget_id = config.widget_id
    }

    if (!widget_id && dealerships && dealerships.length) {
      const dealershipWithWidgetId = dealerships.find(d => !!d.widgetId)

      if (dealershipWithWidgetId) {
        widget_id = dealershipWithWidgetId.widgetId
      }
    }

    if (widget_id && widget_id.length) {
      if (config.preset) {
        const [platform, term] = presetParams(config.preset)
        if (platform === 'one_platform' && term === 'url') {
          result[link] = {
            widget_id,
            price,
            model_data: modelData,
            productType,
            discounts,
            dealerships,
            card_identity,
            additionalOptions,
          }
        } else {
          result[id] = {
            widget_id,
            price,
            model_data: modelData,
            productType,
            discounts,
            dealerships,
            card_identity,
            additionalOptions,
          }
        }
      } else {
        result[id] = {
          widget_id,
          price,
          model_data: modelData,
          productType,
          discounts,
          dealerships,
          card_identity,
          additionalOptions,
        }
      }
    }
  }

  return result
}

/**
 * Получает информацию по одной карточке
 * Должен вернуть объект в с ссылкой на изображение, название, цену и идентификатор автомобиля
 * @param card - список карточек
 * @param config - конфигурация из настроек скрипта
 * @returns {Object}
 */
const getCarInformation = (card, config) => {
  return config.parse_car_card(card)
}

/**
 * Создает кнопку на указанных карточках
 * @param cards - список карточек
 * @param buttonSettings - настройки кнопки с БЭ
 * @param config - конфигурация из настроек скрипта
 * @returns {VoidFunction}
 */
const findValidCards = (cards, buttonSettings, config) => {
  const bootBatchListType = store.getState().settings.bootBatchListType
  const isMulti = bootBatchListType === 2

  let firstCardId = null

  for (let card of cards) {
    let cars = buttonSettings.cars

    if (isMulti) {
      const cardIdentity = config.card_identity(card)
      cars = buttonSettings[cardIdentity]?.cars
    }

    if (!cars) {
      continue
    }

    if (!isCardValid(card, cars, config, isMulti)) {
      continue
    }

    if (!firstCardId) {
      firstCardId = config.car_id(card)
    }

    if (!isMulti) {
      const identity = config.car_id(card)

      if (buttonSettings.visual[buttonSettings.cars[identity]]) {
        const visual = buttonSettings.visual[buttonSettings.cars[identity]].list
        const status = buttonSettings.statuses[identity] || ''
        const isPriceHidden = buttonSettings.visual[buttonSettings.cars[identity]].isPriceHidden

        let placeholders = {}

        if (buttonSettings.placeholders && buttonSettings.placeholders[identity]) {
          placeholders = buttonSettings.placeholders[identity]
        }

        let currency = 'RUB'
        if (buttonSettings.currencies && buttonSettings.currencies[identity]) {
          currency = buttonSettings.currencies[identity]
        }

        let locale = 'Ru'
        if (buttonSettings.locales && buttonSettings.locales[identity]) {
          locale = buttonSettings.locales[identity]
        }

        createButtonOnCard(card, visual, status, placeholders, currency, locale, {}, config, firstCardId, isPriceHidden)
      }
    } else {
      const identity = config.card_identity(card)

      createButtonOnCard(
        card,
        buttonSettings[identity].visual.list,
        buttonSettings[identity].status,
        buttonSettings[identity].placeholders,
        buttonSettings[identity].currency,
        buttonSettings[identity].locale,
        {
          dealerships: buttonSettings[identity].dealerships,
          cars: buttonSettings[identity].cars,
        },
        config,
        firstCardId,
        buttonSettings[identity].visual.isPriceHidden,
      )
    }
  }
}

/**
 * Делает проверку - можно ли на указанную карточку добавлять кнопку
 * @param card - карточка
 * @param cars - Автомобили, полученные из BE роута /api/v2/batch_boot
 * @param config - конфигурация из настроек скрипта
 * @param isMulti - = true если список работает на бут батч 2,
 * тогда валидность карточки определяется не по основному идентификатору карточки, а по входлению в массив car_dealerships с карточки
 * @returns boolean - Можно ли на данной карточке отображать кнопку SC
 */
const isCardValid = (card, cars, config, isMulti = false) => {
  if (isMulti) {
    return Boolean(Object.values(cars).length)
  } else {
    let identity = ''
    if (config.preset) {
      const [, term] = presetParams(config.preset)
      identity = config.car_id(card, term)
    } else {
      identity = config.car_id(card)
    }

    return !!cars[identity]
  }
}

/**
 * Создает кнопку на указанной карточке
 * @param card - карточка
 * @param buttonSettingsVisual - настройки кнопки с БЭ
 * @param status - статус автомобиля с БЭ
 * wait - Создана онлайн оплата, но не зарезервирована, не открываем СК
 * reserved - Зарезервировано (клиент оплатил денег, но там двухфакторное), не открываем СК
 * sold - Продано (деньги сняли с клиента), не открываем СК
 * available - Нет никаких резервов, открываем СК
 * @param placeholders - плейсхолдеры с бэка
 * @param currency - валюта с бэка
 * @param locale - язык с бэка
 * @param dataForMultiDealership - дц и машины с БЭ
 * @param userConfig - конфигурация из настроек скрипта
 * @param firstCardId - идентификатор первой карточки, нужен для интеграции с ТИ (???)
 * @param isPriceHidden - убрать плйесхолдеры
 * @returns {VoidFunction}
 */
const createButtonOnCard = (
  card,
  buttonSettingsVisual,
  status,
  placeholders,
  currency,
  locale,
  dataForMultiDealership,
  userConfig,
  firstCardId,
  isPriceHidden,
) => {
  const widgetId = createButtonOnCardHelpers.widgetId({ userConfig, card })
  const cardData = createButtonOnCardHelpers.cardData({ userConfig, widgetId, dataForMultiDealership, card })

  let isLocked // открывать ли смарт контракт (зависит от статуса)

  window.scLogger.log('[on_list::createButtonOnCard::userConfig]', userConfig)

  // проверка на то, что такой кнопки нет на карточке
  if (card.querySelector('.kdx-smart-contract-list-btn')) {
    return null
  }

  // задает стиль карточке
  card.style.position = 'relative'

  // если передан класс кастомной кнопки, то ищем кнопку и вешаем на неё событие открытия виджета
  if (userConfig?.customButtonClass) {
    const button = card.querySelector(userConfig.customButtonClass)

    button.addEventListener(
      'click',
      e => {
        e.preventDefault()
        openSmartContract(e, card, cardData, userConfig, currency)
      },
      false,
    )

    return
  }

  // задает стили и текст с БЭ
  const TEXT_COLOR = createButtonOnCardHelpers.TEXT_COLOR(buttonSettingsVisual)
  const HOVERED_TEXT_COLOR = createButtonOnCardHelpers.HOVERED_TEXT_COLOR(buttonSettingsVisual)
  const BACKGROUND_COLOR = createButtonOnCardHelpers.BACKGROUND_COLOR(buttonSettingsVisual)
  const HOVERED_BACKGROUND_COLOR = createButtonOnCardHelpers.HOVERED_BACKGROUND_COLOR(buttonSettingsVisual)
  const FONT_SIZE = createButtonOnCardHelpers.FONT_SIZE(buttonSettingsVisual)
  const FONT_WEIGHT = createButtonOnCardHelpers.FONT_WEIGHT(buttonSettingsVisual)
  const PADDING = createButtonOnCardHelpers.PADDING(buttonSettingsVisual)
  const BORDER_RADIUS = createButtonOnCardHelpers.BORDER_RADIUS(buttonSettingsVisual)
  const DEFAULT_SVG = createButtonOnCardHelpers.DEFAULT_SVG(TEXT_COLOR)
  const HOVERED_SVG = createButtonOnCardHelpers.HOVERED_SVG(HOVERED_TEXT_COLOR)

  let button = createButtonOnCardHelpers.buttonCreator({
    buttonSettingsVisual,
    TEXT_COLOR,
    BACKGROUND_COLOR,
    FONT_SIZE,
    FONT_WEIGHT,
    PADDING,
    BORDER_RADIUS,
  })

  let buttonImg = createButtonOnCardHelpers.buttonImgCreator({ DEFAULT_SVG, FONT_SIZE })
  let buttonText = createButtonOnCardHelpers.buttonTextCreator()

  languageSetter(locale)

  switch (status) {
    case 'wait':
      buttonText.innerText =
        createButtonOnCardHelpers.replacePlaceholders({
          text: buttonSettingsVisual.button.text_wait,
          placeholders,
          currency,
          isPriceHidden,
        }) || i18n.t('car_statuses.wait.list_text')
      isLocked = false
      break

    case 'reserved':
      buttonText.innerText =
        createButtonOnCardHelpers.replacePlaceholders({
          text: buttonSettingsVisual.button.text_reserved,
          placeholders,
          currency,
          isPriceHidden,
        }) || i18n.t('car_statuses.reserved.list_text')
      isLocked = false
      break

    case 'sold':
      buttonText.innerText =
        createButtonOnCardHelpers.replacePlaceholders({
          text: buttonSettingsVisual.button.text_sold,
          placeholders,
          currency,
          isPriceHidden,
        }) || i18n.t('car_statuses.sold.list_text')
      isLocked = false
      break

    case 'available':
      buttonText.innerText =
        createButtonOnCardHelpers.replacePlaceholders({
          text: buttonSettingsVisual.button.text,
          placeholders,
          currency,
          isPriceHidden,
        }) || i18n.t('car_statuses.available.list_text')
      isLocked = false
      break

    default:
      buttonText.innerText =
        createButtonOnCardHelpers.replacePlaceholders({
          text: buttonSettingsVisual.button.text,
          placeholders,
          currency,
          isPriceHidden,
        }) || i18n.t('car_statuses.available.list_text')
      isLocked = false
      break
  }

  buttonText.dataset.placement = 'list'

  button.append(buttonText)
  // добавляем стелочку только авто а наличи
  if (!isLocked) {
    button.append(buttonImg)
  }

  // если авто нельзя купить, то кнопка прозрачная
  // на десктопе кнопка видна только при наведении на карточку - если есть настройка
  if (!buttonSettingsVisual.button.show_always) {
    if (window.innerWidth > window.innerHeight) {
      button.style.opacity = '0'
    }
  } else {
    if (isLocked) {
      button.style.opacity = '0.5'
    }
  }

  if (!isLocked) {
    button.onmouseover = () => {
      button.style.background = HOVERED_BACKGROUND_COLOR
      button.style.color = HOVERED_TEXT_COLOR
      button.style.transition = 'all ease-in-out 0.1s'
      buttonImg.innerHTML = HOVERED_SVG
    }
    button.onmouseleave = () => {
      button.style.background = BACKGROUND_COLOR
      button.style.color = TEXT_COLOR
      button.style.transition = 'all ease-in-out 0.1s'
      buttonImg.innerHTML = DEFAULT_SVG
    }
  }

  if (typeof userConfig.listCardButtonGenerator === 'function') {
    const buttons = userConfig.listCardButtonGenerator(card, buttonText.innerText, isLocked, status)

    if (buttons.length > 0) {
      for (const button of buttons) {
        button.classList.add('kdx-smart-contract-list-btn') // Добавляем класс для превента прогрузки карточки заново.

        if (!isLocked) {
          // Не добавляем событие открытия, если заблокирован автомобиль
          button.addEventListener(
            'click',
            e => {
              e.preventDefault()
              openSmartContract(e, card, cardData, userConfig, currency)
            },
            false,
          )
        }
      }

      return
    }
  }

  if (!isLocked) {
    // класс с анимацией для привлечения внимания
    button.classList.add('kdxsc-animate')
    // const modelData =
    //  '00:Honda,01:Civic Type R,03:330,04:XI,07:Красный металлик,10:6411965ab3ecdfd49d938408,11:6411965ab3ecdfd49d93840a,12:23364123__23364282,13:5ce7dad4d999da000129d91f,14:6114d0be9759d30001d92bbb,15:6411965ab3ecdfd49d938409,02:2023'
    button.addEventListener(
      'click',
      e => {
        e.preventDefault()
        openSmartContract(e, card, cardData, userConfig, currency)
      },
      false,
    )
  }

  // добавляет кнопку на карточку
  card.append(button)

  // на десктопе кнопка видна только по наведению на карточку - если есть настройка
  if (!buttonSettingsVisual.button.show_always) {
    if (window.innerWidth > window.innerHeight) {
      card.addEventListener('mouseover', function () {
        button.style.opacity = isLocked ? '0.5' : '1'
      })

      card.addEventListener('mouseleave', function () {
        button.style.opacity = '0'
      })
    }
  }
}

/**
 * Объект с функциями-хэлперами для функции createButtonOnCard
 */
const createButtonOnCardHelpers = {
  widgetId: function ({ userConfig, card }) {
    let widgetId = ''
    if (userConfig.widgetIdList) {
      if (typeof userConfig.widgetIdList === 'string') {
        widgetId = userConfig.widgetIdList
      } else if (typeof userConfig.widgetIdList === 'function') {
        widgetId = userConfig.widgetIdList(card)
      }
    }
    return widgetId
  },

  cardData: function ({ userConfig, widgetId, dataForMultiDealership, card }) {
    return {
      id: userConfig.car_id(card),
      price: userConfig.car_price(card),
      image: userConfig.car_image(card),
      link: userConfig.car_link(card),
      title: userConfig.car_title(card),
      dealerships: dataForMultiDealership.dealerships,
      cars: dataForMultiDealership.cars,
      modelData: userConfig.modelDataList ? userConfig.modelDataList(card) : '',
      productType: userConfig.productTypeList ? userConfig.productTypeList(card) : 'unknown',
      additionalOptions: userConfig.additionalOptionsList ? userConfig.additionalOptionsList(card) : false,
      discounts: userConfig?.car_discounts
        ? userConfig.car_discounts(card)
        : {
            credit: 0,
            tradein: 0,
            credit_tradein: 0,
            insurance: 0,
          },
      widgetId: widgetId,
    }
  },

  TEXT_COLOR: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.color ? buttonSettingsVisual.button.styles.color : '#000',

  HOVERED_TEXT_COLOR: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.color_hover ? buttonSettingsVisual.button.styles.color_hover : '#000',

  BACKGROUND_COLOR: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.background ? buttonSettingsVisual.button.styles.background : '#00cccc',

  HOVERED_BACKGROUND_COLOR: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.background_hover
      ? buttonSettingsVisual.button.styles.background_hover
      : '#12e8d2',

  FONT_SIZE: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.font_size ? buttonSettingsVisual.button.styles.font_size : '14px',

  FONT_WEIGHT: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.font_weight ? buttonSettingsVisual.button.styles.font_weight : '300',

  PADDING: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.padding ? buttonSettingsVisual.button.styles.padding : '8px 12px',

  BORDER_RADIUS: buttonSettingsVisual =>
    buttonSettingsVisual.button.styles.border_radius ? buttonSettingsVisual.button.styles.border_radius : '4px',

  DEFAULT_SVG: TEXT_COLOR =>
    `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.172 12L8.22205 7.04999L9.63605 5.63599L16 12L9.63605 18.364L8.22205 16.95L13.172 12Z" fill="${TEXT_COLOR}"/></svg>`,

  HOVERED_SVG: HOVERED_TEXT_COLOR =>
    `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.172 12L8.22205 7.04999L9.63605 5.63599L16 12L9.63605 18.364L8.22205 16.95L13.172 12Z" fill="${HOVERED_TEXT_COLOR}"/></svg>`,

  buttonCreator: function ({
    buttonSettingsVisual,
    TEXT_COLOR,
    BACKGROUND_COLOR,
    FONT_SIZE,
    FONT_WEIGHT,
    PADDING,
    BORDER_RADIUS,
  }) {
    //  создает кнопку
    let button = document.createElement('div')
    button.classList.add('kdx-smart-contract-list-btn')

    button.style.position = 'absolute'
    button.style.cursor = 'pointer'
    button.style.display = 'flex'
    button.style.alignItems = 'center'
    button.style.zIndex = '99'
    button.dataset.placement = 'list'

    button.style.background = BACKGROUND_COLOR
    button.style.color = TEXT_COLOR
    button.style.fontSize = FONT_SIZE
    button.style.fontWeight = FONT_WEIGHT
    button.style.padding = PADDING
    button.style.borderRadius = BORDER_RADIUS

    if (
      buttonSettingsVisual.button.position.top ||
      buttonSettingsVisual.button.position.right ||
      buttonSettingsVisual.button.position.left ||
      buttonSettingsVisual.button.position.bottom
    ) {
      button.style.top = buttonSettingsVisual.button.position.top
      button.style.right = buttonSettingsVisual.button.position.right
      button.style.left = buttonSettingsVisual.button.position.left
      button.style.bottom = buttonSettingsVisual.button.position.bottom
    } else {
      button.style.right = '10px'
      button.style.top = '10px'
    }

    return button
  },

  buttonImgCreator: function ({ DEFAULT_SVG, FONT_SIZE }) {
    let buttonImg = document.createElement('span')

    buttonImg.style.display = 'flex'
    buttonImg.style.alignItems = 'center'
    buttonImg.innerHTML = DEFAULT_SVG
    buttonImg.dataset.placement = 'list'
    buttonImg.style.height = FONT_SIZE

    return buttonImg
  },

  buttonTextCreator: function () {
    let buttonText = document.createElement('span')
    buttonText.style.paddingBottom = '3px'
    buttonText.style.paddingTop = '1px'
    buttonText.style.lineHeight = '10px'

    return buttonText
  },

  replacePlaceholders: function ({ text, placeholders, currency, isPriceHidden }) {
    // eslint-disable-next-line no-template-curly-in-string
    const c = '${credit}'

    // если скрыты цены и есть плейсхолдер, то возвращаем пустоту, чтобы было значение по умолчанию
    if (isPriceHidden && text.includes(c)) {
      return ''
    }

    // если в тексте нет плейсхолдера, то возвращаем как есть
    if (!text.includes(c)) {
      return text
    }

    // если в тексте есть плейсхолдер, то возвращаем форматированный текст
    if (placeholders[c]) {
      return text.replace(c, formatPrice(placeholders[c], currency) + `/${i18n.t('months')}`)
    }

    return ''
  },
}

/**
 * Открывает виджет СК
 * @param e - e
 * @param currency - валюта
 * @param cardData - данные с карточки
 * @param config - конфигурация из настроек скрипта
 * @returns {VoidFunction}
 */
const openSmartContract = (e, card, cardData, config, currency, dontOpenWidget = false) => {
  // если определена isWindowFunctionListWidgetIdDefined для мультидилера то берем widget_id тольео из нее
  // чтобы не было дефолтных виджетов везде
  const isMultiDealer = isWindowFunctionListWidgetIdDefined()

  let widget_id = ''

  if (isMultiDealer) {
    if (cardData.widgetId) {
      widget_id = cardData.widgetId
    }
  } else {
    widget_id = config.widget_id
  }

  let availableDealerships = dealershipsFormatter(cardData)

  if (!widget_id && availableDealerships) {
    const dealershipWithWidgetId = availableDealerships.find(d => !!d.widget_id)

    if (dealershipWithWidgetId) {
      widget_id = dealershipWithWidgetId.widget_id
    }
  }

  const options = {
    widget_id,

    detail: {
      identity: function () {
        if (config.preset) {
          const [platform, term] = presetParams(config.preset)

          if (platform === 'one_platform' && term === 'url') {
            return this.link()
          } else {
            return cardData.id
          }
        }
        return cardData.id
      },

      price: function () {
        return Number(cardData.price)
      },

      picture: function () {
        return cardData.image
      },

      name: function () {
        return cardData.title
      },

      modelData: function () {
        return cardData.modelData
      },

      productType: function () {
        return cardData.productType
      },

      discounts: function () {
        return cardData.discounts
      },

      dealerships: function () {
        return availableDealerships
      },

      additionalOptions() {
        return cardData.additionalOptions
      },

      link: function () {
        if (!cardData && !cardData.link) {
          return window.location.href
        }

        if (cardData.link.includes('http')) {
          return cardData.link
        } else {
          if (cardData.link[0] === '/') {
            return window.location.origin + cardData.link
          } else {
            return window.location.origin + '/' + cardData.link
          }
        }
      },
      customData() {
        if (typeof config?.list?.customData === 'function') {
          return config.list.customData(card)
        } else {
          return {}
        }
      },
    },
    calledFromList: true,
    dontOpenWidget: dontOpenWidget,
  }

  const deprecated = () => {
    if (config.form_agreement_text) {
      options['form_agreement_text'] = config.form_agreement_text
    }

    if (config.header_text) {
      options['header_text'] = config.header_text
    }
  }

  deprecated()

  if (!dontOpenWidget) {
    window.dataLayer &&
      window.dataLayer.push({
        event: 'SmartContract_Open',
        Status: '',
        buttonName: e?.target?.innerText || 'Не удалось определить текст на кнопке на списке',
        carDetail: {
          identity: cardData.id,
          name: cardData.title,
          modelData: cardData.modelData,
          productType: cardData.productType,
        },
        widget_id: options.widget_id,
        deal_id: '',
        eventValue: cardData.price,
        currency,
      })
  }

  window.InitSmartContract(options)

  /*
  // если хотите упрощенную форму на кнопке, то раскомментируйте
  // будет открываться, если config.carOrderFormType === 'page'
  if (store.getState().settings.bootBatchListType === 2 && config.carOrderFormType === 'page') {
    store.dispatch(showCarOrderPopup())
    if (store.getState().screen.carOrderPopupVisibility) {
      window.InitSmartContractCarOrderPopup(options)
    }
  } else {
    window.InitSmartContract(options)
  }
   */
}

/**
 * Следит за подгрузкой новых карточек
 * @param config - конфигурация из настроек скрипта
 * @returns {VoidFunction}
 */
const observerWrapper = config => {
  const handler = mutationRecords => {
    const newCards = []

    const wrapper = node => {
      if (typeof config.listNodeWrapper === 'function') {
        return config.listNodeWrapper(node)
      }

      return node
    }

    for (const mutationRecord of mutationRecords) {
      for (const addedNode of mutationRecord.addedNodes) {
        const cardClasses = Array.isArray(config.card_class) ? config.card_class : [config.card_class] // Данные могут быть строкой или массивом

        for (const c of cardClasses) {
          if (addedNode.classList && addedNode.classList.contains(c)) {
            newCards.push(wrapper(addedNode))

            break
          }
        }
      }
    }

    processListCards(newCards, config)
  }

  if (typeof config.cars_container !== 'function') {
    return
  }

  const elementToWatch = config.cars_container()
  const elementsToWatch = Array.isArray(elementToWatch) ? elementToWatch : [elementToWatch]

  for (const target of elementsToWatch) {
    if (!target) {
      continue
    }

    try {
      const observer = new MutationObserver(handler)

      const options = {
        childList: true,
      }

      if (config && config.deepObserve === true) {
        options['subtree'] = true
      }

      observer.observe(target, options)
    } catch (e) {
      console.error(target)
      console.error(e)
    }
  }

  if (config.scanOnChangeElement && typeof config.scanOnChangeElement === 'function') {
    const scanHandler = () => {
      const wrapper = node => {
        if (typeof config.listNodeWrapper === 'function') {
          return config.listNodeWrapper(node)
        }

        return node
      }

      const cards = config
        .cars_container()
        .getElementsByClassName(Array.isArray(config.card_class) ? config.card_class.join(' ') : config.card_class)

      let newCards = []

      for (const card of cards) {
        if (card && !card.querySelector('.kdx-smart-contract-list-btn')) {
          newCards.push(wrapper(card))
        }
      }

      processListCards(newCards, config)
    }

    const scanElement = config.scanOnChangeElement()
    const scanElements = Array.isArray(scanElement) ? scanElement : [scanElement]

    for (const scanTarget of scanElements) {
      try {
        const scanObserver = new MutationObserver(scanHandler)

        scanObserver.observe(scanTarget, {
          childList: true,
          subtree: true,
        })
      } catch (e) {
        console.error(scanTarget)
        console.error(e)
      }
    }
  }
}

const processListCards = (cards, config) => {
  if (cards.length === 0) {
    return
  }

  const information = getCarsInformation(cards, config)

  if (Object.keys(information).length === 0) {
    return
  }

  const allDealershipsFromCards = getAllDealershipsFromCards(information)
  const bootBatchListType = detectAndSetBootBatchListType(allDealershipsFromCards)

  if (bootBatchListType === 1) {
    bootListUrl(JSON.stringify({ data: information }))
      .then(response => {
        findValidCards(cards, response.data, config)
        carsInfoEvent(cards, response.data, config)
      })
      .catch(console.error)

    return
  }

  if (!Array.isArray(allDealershipsFromCards) || allDealershipsFromCards.length === 0) {
    return
  }

  const data = bootBatch2PayloadCreator(information)

  bootBatch2('list', JSON.stringify({ data }))
    .then(({ data }) => {
      const bb2ToBb1Data = bootBatch2ResponseToBootBatch1ResponseFormatter(data)

      findValidCards(cards, bb2ToBb1Data, config)
      carsInfoEventV2(cards, data, config)
    })
    .catch(console.error)
}

const detectAndSetBootBatchListType = allDealershipsFromCards => {
  const fromStore = store.getState().settings.bootBatchListType

  if (fromStore !== 0) {
    return fromStore
  }

  if (Array.isArray(allDealershipsFromCards) && allDealershipsFromCards.length > 0) {
    store.dispatch(setBootBatchListType(2))

    return 2
  }

  store.dispatch(setBootBatchListType(1))

  return 1
}

/**
 * Вызывает событие, содержащее информцию об авто при загрузке данных карточек
 * @param cards - список карточек
 * @param buttonSettings - настройки кнопки с БЭ
 * @param config - конфигурация из настроек скрипта
 * @returns {VoidFunction}
 */
const carsInfoEvent = (cards, buttonSettings, config) => {
  const carsInfo = []

  let shouldEmitEvent = true

  for (const card of cards) {
    const carInfo = config.parse_car_card(card)

    if (!carInfo) {
      continue
    }

    carInfo.creditPerMonth = buttonSettings?.placeholders?.[carInfo.id]?.['${credit}'] || 'unknown'
    carInfo.currency = buttonSettings?.currencies[carInfo.id] || 'unknown'
    carInfo.locale = buttonSettings?.locales[carInfo.id] || 'unknown'
    carInfo.status = buttonSettings?.statuses[carInfo.id] || 'unknown'

    if (
      buttonSettings &&
      buttonSettings.restrictions &&
      buttonSettings.restrictions[carInfo.id] &&
      !buttonSettings.restrictions[carInfo.id].sc_widget_events_api_available
    ) {
      shouldEmitEvent = false
      break
    }

    carsInfo.push(carInfo)
  }

  if (shouldEmitEvent) {
    store.dispatch(bootedListEvent())
  }
}

const carsInfoEventV2 = (cards, response, config) => {
  const carsInfo = []
  let shouldEmitEvent = true

  for (const card of cards) {
    const cardIdElement = card.querySelector('.kdxsc_card_identity')
    if (!cardIdElement) {
      continue
    }

    const cardId = cardIdElement.innerText

    if (!response[cardId]) {
      continue
    }

    if (response[cardId].restrictions) {
      for (const restrictions of Object.values(response[cardId].restrictions)) {
        if (restrictions.sc_widget_events_api_available === false) {
          shouldEmitEvent = false

          break
        }
      }

      if (!shouldEmitEvent) {
        break
      }
    }

    const carInfo = config.parse_car_card(card)

    carsInfo.push({
      price: carInfo.price,
      id: carInfo.id,
    })
  }

  if (shouldEmitEvent) {
    store.dispatch(setCarList(carsInfo))
    store.dispatch(bootedListEvent())
  }
}

/**
 * Возвращает все уникальные пары ид_машины: ид_виджета со всех карточек
 * @param information - все данные с карточек
 * @returns {[]}
 */
const getAllDealershipsFromCards = information => {
  let allDealerships = []

  for (let i in information) {
    if (information.hasOwnProperty(i)) {
      let currentDealerships = information[i].dealerships

      if (currentDealerships) {
        for (const cd of currentDealerships) {
          let areTheSameIdentityAndWidgetIdExist = allDealerships.find(
            a => a.identity === cd.identity && a.widgetId === cd.widgetId,
          )

          if (!areTheSameIdentityAndWidgetIdExist && cd.widgetId) {
            allDealerships.push(cd)
          }
        }
      }
    }
  }

  return allDealerships
}

/**
 * приводит ответ с БутБатч2 к ответу с БутБатч1
 * @param bb2 - ответ с БутБатч2
 * @returns {{}}
 */
export const bootBatch2ResponseToBootBatch1ResponseFormatter = bb2 => {
  let data = {}

  for (let bb2Item in bb2) {
    if (bb2.hasOwnProperty(bb2Item)) {
      data[bb2Item] = {
        cars: bb2[bb2Item].cars,
        currency: bb2[bb2Item].currency,
        locale: bb2[bb2Item].locale,
        placeholders: bb2[bb2Item].placeholders,
        status: getMainCardStatus(bb2[bb2Item].cars),
        dealerships: bb2[bb2Item].dealerships,
        visual: bb2[bb2Item].visual,
        restrictions: bb2[bb2Item].restrictions,
      }
    }
  }
  return data
}

/**
 * Возвращает общий статус карточки по машинам с карточки
 * @param cars - машины на карточке
 * @returns {string}
 */
const getMainCardStatus = cars => {
  let uniqueStatuses = []

  for (let car in cars) {
    if (cars.hasOwnProperty(car)) {
      cars[car].forEach(c => {
        if (!uniqueStatuses.includes(c.status)) {
          uniqueStatuses.push(c.status)
        }
      })
    }
  }

  if (uniqueStatuses.includes('available')) {
    return 'available'
  } else if (uniqueStatuses.includes('wait')) {
    return 'wait'
  } else if (uniqueStatuses.includes('reserved')) {
    return 'reserved'
  } else if (uniqueStatuses.includes('sold')) {
    return 'sold'
  } else {
    return ''
  }
}
