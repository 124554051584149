function ICircleIconBig() {
  return (
    <>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 21.6666C5.10884 21.6666 0.333374 16.8912 0.333374 11C0.333374 5.10878 5.10884 0.333313 11 0.333313C16.8912 0.333313 21.6667 5.10878 21.6667 11C21.6667 16.8912 16.8912 21.6666 11 21.6666ZM11 19.5333C15.7129 19.5333 19.5334 15.7128 19.5334 11C19.5334 6.28715 15.7129 2.46665 11 2.46665C6.28721 2.46665 2.46671 6.28715 2.46671 11C2.46671 15.7128 6.28721 19.5333 11 19.5333ZM9.93337 5.66665H12.0667V7.79998H9.93337V5.66665ZM9.93337 9.93331H12.0667V16.3333H9.93337V9.93331Z"
          fill="#222222"
        />
      </svg>
    </>
  )
}

export default ICircleIconBig
