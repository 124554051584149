import { clearSelectedAccessories, enableAccessory, setAccessories } from '../../../store/actions'
import store from '../../../store/store'

export const accessoriesSetter = (accessories, dataFromLocalStorage) => {
  const idsOfSelectedAccessoriesFromLocalStorage = []

  dataFromLocalStorage?.accessories?.selected.forEach(accessory => {
    idsOfSelectedAccessoriesFromLocalStorage.push(accessory.id)
  })

  store.dispatch(setAccessories(accessories))
  store.dispatch(clearSelectedAccessories())

  let restrictedIds = []

  accessories.forEach(accessory => {
    if (idsOfSelectedAccessoriesFromLocalStorage.includes(accessory.id)) {
      if (accessory.attributes.restrictedIds) {
        restrictedIds.push(...accessory.attributes.restrictedIds)
      }
      if (!restrictedIds.includes(accessory.attributes.accessoryId)) {
        store.dispatch(enableAccessory(accessory))
      }
    }
  })
}
