import _cloneDeep from 'lodash/cloneDeep'

import { BULLET_TYPES } from '../types'

const INIT_STATE = {
  links: {},
  texts: {},
  sizes: {},
  filledType: 'bordered',
  useBackground: false,
}

const bullet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BULLET_TYPES.SET_LINKS:
      return {
        ...state,
        links: _cloneDeep(action.links),
      }

    case BULLET_TYPES.SET_TEXTS:
      return {
        ...state,
        texts: _cloneDeep(action.texts),
      }

    case BULLET_TYPES.SET_SIZES:
      return {
        ...state,
        sizes: {
          desktop: action.sizes.sizeDesktop,
          tablet: action.sizes.sizeTablet,
          mobile: action.sizes.sizeMobile,
        },
      }

    case BULLET_TYPES.SET_FILLED_TYPE:
      return {
        ...state,
        filledType: action.filledType,
      }

    case BULLET_TYPES.SET_USE_BACKGROUND:
      return {
        ...state,
        useBackground: action.useBackground,
      }

    default:
      return state
  }
}

export default bullet
