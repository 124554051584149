import { TRADE_IN_USER_BEHAVIOUR } from '../constants'
import store from '../store/store'
import { calculateTaxes } from './calculate_taxes'

export const creditAmount = ({
  price,
  selectedBenefits,
  selectedAccessories,
  tradeInUserBehaviour,
  tradeInBenefitAmount,
  tradeInBenefitAmountWithoutTradeIn,
  creditBenefitAmount,
}) => {
  let benefitsAmount = selectedBenefits.reduce(
    (accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue,
    0,
  )

  let accessoriesAmount = 0
  if (selectedAccessories) {
    accessoriesAmount = selectedAccessories.reduce(
      (accumulator, { attributes: { price: currentValue } }) => accumulator + currentValue,
      0,
    )
  }

  benefitsAmount += accessoriesAmount

  const tradeInEnabled = tradeInUserBehaviour === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const withoutTradeIn = tradeInUserBehaviour === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED

  if (tradeInEnabled && tradeInBenefitAmount < 0) {
    benefitsAmount += tradeInBenefitAmount
  }

  if (withoutTradeIn && tradeInBenefitAmountWithoutTradeIn < 0) {
    benefitsAmount += tradeInBenefitAmountWithoutTradeIn
  }

  if (creditBenefitAmount < 0) {
    benefitsAmount += creditBenefitAmount
  }

  const taxes = store.getState().settings.taxes

  if (taxes) {
    const finalPrice = price + benefitsAmount

    price += calculateTaxes(finalPrice, taxes)
  }

  return price + benefitsAmount
}
