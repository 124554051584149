import debounce from 'lodash/debounce'

import { eventName } from './eventName'

function createEventDetail(name, payload) {
  const detail = {
    type: payload?.type || name,
    api: { v1: payload },
  }

  if (name === 'booted') {
    detail.openWidget = () => payload.methods.openWidget()
  }

  return detail
}

function createEvent(name, detail) {
  const event = new CustomEvent(eventName(name), { detail: createEventDetail(name, detail) })
  return event
}

export function emitBootedListEvent(detail) {
  document.dispatchEvent(createEvent('bootedList', detail))
}

export function emitBootedEvent(detail) {
  document.dispatchEvent(createEvent('booted', detail))
}

export function emitUpdatedEvent(detail) {
  document.dispatchEvent(createEvent('updated', detail))
}

export function emitCreatedEvent(detail) {
  document.dispatchEvent(createEvent('created', detail))
}

export const debouncedEmitUpdatedEvent = debounce(detail => emitUpdatedEvent(detail), 150)

export const debouncedEmitCreatedEvent = debounce(detail => emitCreatedEvent(detail), 150)
