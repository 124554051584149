import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import {
  SCREEN_ACCESSORIES,
  SCREEN_AUTH,
  SCREEN_CREDIT,
  SCREEN_LEASING,
  SCREEN_ONLINE_PAYMENT,
  SCREEN_PROMO_PROGRAM,
  SCREEN_TRADE_IN,
} from '../../constants'
import { getProjectName } from '../../helpers/app/detectors/project_name'
import { goToIndex, hideWidget } from '../../store/actions'

function Header({ showBackButton, visualDeprecated, texts, screen }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const text = () => {
    const { strict, main, tradeIn, onlinePayment, credit } = texts

    const deprecated = () => {
      if (strict) {
        return main || t('header.mainTitle')
      }

      return visualDeprecated.headerText || main || t('header.mainTitle')
    }

    switch (screen) {
      case SCREEN_TRADE_IN:
        return tradeIn || t('header.tradeInTitle')
      case SCREEN_ONLINE_PAYMENT:
        return onlinePayment || t('header.onlinePaymentTitle')
      case SCREEN_CREDIT:
        return credit || t('header.creditTitle')
      case SCREEN_AUTH:
        return t('header.authTitle')
      case SCREEN_LEASING:
        return t('header.leasingTitle')
      case SCREEN_ACCESSORIES:
        return t('accessories.accessories')
      case SCREEN_PROMO_PROGRAM:
        return t('header.promoProgram')
      default:
        return deprecated()
    }
  }

  const onBackButtonClick = () => {
    dispatch(goToIndex())
  }

  return (
    <div className="kdxsc-header" data-project-name={getProjectName()}>
      {showBackButton ? (
        <div className="kdxsc-header__back" onClick={() => onBackButtonClick()} />
      ) : (
        <div className="kdxsc-header__plug" />
      )}
      <div className="kdxsc-header__text">{text()}</div>
      <div className="kdxsc-header__action" onClick={() => dispatch(hideWidget())} />
    </div>
  )
}

function mapStateToProps({
  screen: { screen, preventBack },
  settings: {
    deprecated: visualDeprecated,
    visual: {
      header: { texts },
    },
  },
}) {
  const backButtonAvailable = [
    SCREEN_TRADE_IN,
    SCREEN_ONLINE_PAYMENT,
    SCREEN_CREDIT,
    SCREEN_LEASING,
    SCREEN_ACCESSORIES,
    SCREEN_PROMO_PROGRAM,
  ].includes(screen)
  const backButtonPrevented =
    (screen === SCREEN_ONLINE_PAYMENT && preventBack.onlinePayment) ||
    (screen === SCREEN_TRADE_IN && preventBack.tradeIn) ||
    ((screen === SCREEN_CREDIT || screen === SCREEN_LEASING) && preventBack.credit)

  return {
    visualDeprecated,
    texts,
    screen,
    showBackButton: backButtonAvailable && !backButtonPrevented,
  }
}

export default connect(mapStateToProps)(Header)
