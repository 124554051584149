import i18next from 'i18next'

const getProgramEnding = length => {
  // 1 программа
  if (length - 1 === 1) {
    return i18next.t('ccS.personal.yourSuitableProgramsP3V1')
  }
  // 2, 3, 4 программы
  if (length - 1 > 1 && length - 1 < 5) {
    return i18next.t('ccS.personal.yourSuitableProgramsP3V2')
  }
  // 5 - 20 программ ; 0 программ
  if (length - 1 === 0 || (length - 1 > 4 && length - 1 <= 20)) {
    return i18next.t('ccS.personal.yourSuitableProgramsP3V3')
  }
  // 21, 31, 41, ... программа
  if (length - 1 > 20 && (length - 1) % 10 === 1) {
    return i18next.t('ccS.personal.yourSuitableProgramsP3V1')
  }
  // 22, 23, 24, 32, 33, 34, ... программы
  if (length - 1 > 21 && [2, 3, 4].includes((length - 1) % 10)) {
    return i18next.t('ccS.personal.yourSuitableProgramsP3V2')
  }

  return i18next.t('ccS.personal.yourSuitableProgramsP3V3')
}

export const getCreditProgramEnding = (length, calcType) => {
  if (length === 1) {
    return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V1.${calcType}`)} ${getProgramEnding(length + 1)}`
  }
  if (length > 1 && length < 5) {
    return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V2.${calcType}`)} ${getProgramEnding(length + 1)}`
  }
  if (length === 0 || (length > 4 && length <= 20)) {
    return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V3.${calcType}`)} ${getProgramEnding(length + 1)}`
  }
  if (length > 20 && length % 10 === 1) {
    return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V1.${calcType}`)} ${getProgramEnding(length + 1)}`
  }
  if (length > 20 && [2, 3, 4].includes(length % 10)) {
    return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V2.${calcType}`)} ${getProgramEnding(length + 1)}`
  }

  return `${i18next.t(`ccS.personal.yourSuitableProgramsP2V3.${calcType}`)} ${getProgramEnding(length + 1)}`
}

export const getYourSuitableProgramsText = length => {
  if (length === 1) {
    return i18next.t(`ccS.personal.yourSuitableProgramsP1V1`)
  }
  if (length > 1 && length < 5) {
    return i18next.t(`ccS.personal.yourSuitableProgramsP1V2`)
  }
  if (length === 0 || (length > 4 && length <= 20)) {
    return i18next.t(`ccS.personal.yourSuitableProgramsP1V1`)
  }
  if (length > 20 && length % 10 === 1) {
    return i18next.t(`ccS.personal.yourSuitableProgramsP1V1`)
  }
  if (length > 20 && [2, 3, 4].includes(length % 10)) {
    return i18next.t(`ccS.personal.yourSuitableProgramsP1V2`)
  }

  return i18next.t(`ccS.personal.yourSuitableProgramsP1V2`)
}
