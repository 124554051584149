import {
  FINANCE_USER_BEHAVIOUR,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
  MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
  MODEL_DATA_RAW_BRAND,
  MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
  MODEL_DATA_RAW_GENERATION,
  MODEL_DATA_RAW_MILEAGE,
  MODEL_DATA_RAW_MODEL,
  MODEL_DATA_RAW_MODEL_YEAR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import { isEmailValid, isPhoneValid } from '../../helpers/validations'
import { CREDIT_STANDALONE, CREDIT_TYPES } from '../types'
import { loadState, saveState } from '../utils/localStorage'

// сохраняет изменения параметров из standalone кредита чтобы их подхватил кредит в виджете
export const tempSyncCreditMiddleware = store => next => action => {
  const loadedState = loadState('@kodix/SC/credit_SA')

  if (action.type === CREDIT_STANDALONE.SELECT_PROGRAM || action.type === CREDIT_TYPES.SET_ACTIVE_PROGRAM) {
    const updatedState = { ...loadedState, selectedProgram: action.payload || action.active_program }
    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  if (action.type === CREDIT_STANDALONE.SET_TERM || action.type === CREDIT_TYPES.SET_CREDIT_PERIOD) {
    const updatedState = { ...loadedState, term: action.payload || action.period }
    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  if (action.type === CREDIT_STANDALONE.SET_SMART_CONTRACT) {
    const updatedState = { ...loadedState, smartContractUrl: action?.payload?.smartContractUrl }
    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  if (
    [
      CREDIT_STANDALONE.SET_CAR_PRICE,
      CREDIT_STANDALONE.SET_INITFEE,
      CREDIT_STANDALONE.SET_INITFEE_PERCENT,
      CREDIT_STANDALONE.SET_BUYBACK,
      CREDIT_STANDALONE.SET_BUYBACK_PERCENT,
    ].includes(action.type)
  ) {
    const updatedState = {
      ...loadedState,
      initFeePercent: action.payload.initFeePercent,
      buybackPercent: action.payload.buybackPercent,
    }

    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  if (action.type === CREDIT_TYPES.SET_CREDIT_INITIAL_FEE) {
    const updatedState = { ...loadedState, initFeePercent: action.initialFeePercentage }
    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  if (action.type === CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT) {
    const updatedState = { ...loadedState, buybackPercent: action.buybackPaymentPercentage }
    saveState('@kodix/SC/credit_SA', updatedState)
    return next(action)
  }

  return next(action)
}

export const getSavedSelectedProgram = () => {
  const loadedState = loadState('@kodix/SC/credit_SA') || undefined
  return loadedState?.selectedProgram
}

export const getSavedCreditParams = () => {
  const loadedState = loadState('@kodix/SC/credit_SA')

  if (!loadedState?.initFeePercent || !loadedState?.buybackPercent) {
    return undefined
  }

  const params = {
    initFeePercent: String(loadedState.initFeePercent) === '0' ? '0' : loadedState?.initFeePercent,
    buybackPercent: String(loadedState.buybackPercent) === '0' ? '0' : loadedState?.buybackPercent,
    term: loadedState?.term,
  }

  return params
}

export const getPersonalFilled = fields => {
  const loadedState = loadState('@kodix/smartContract/form')

  const requiredFields = {
    name: fields.name.required_on_base_form ? true : false,
    lastName: fields.last_name.required_on_base_form ? true : false,
    email: fields.email.required_on_base_form ? true : false,
    phone: true,
    agreement: true,
  }

  let filled = true

  if (loadedState?.values) {
    for (const [key, value] of Object.entries(loadedState.values)) {
      if (requiredFields[key] === true) {
        if (!value) {
          filled = false
        }

        if (key === 'email' && !isEmailValid(value)) {
          filled = false
        }

        if (key === 'phone' && !isPhoneValid(value, loadedState.phoneSelection.country)) {
          filled = false
        }
      }
    }
  }

  return filled
}

export const getCarList = cars => {
  const list = cars
    ? cars.map(car => {
        return {
          id: car.id,
          name: car.attributes.name,
          brand: car.attributes.modelData.brand,
          model: car.attributes.modelData.model,
          price: car.attributes.price,
          modelData: car.attributes.modelData,
        }
      })
    : []

  return list
}

export const getCarModelData = car => {
  const map = {
    brand: MODEL_DATA_RAW_BRAND,
    model: MODEL_DATA_RAW_MODEL,
    model_year: MODEL_DATA_RAW_MODEL_YEAR,
    generation_name: MODEL_DATA_RAW_GENERATION,
    engine_power_horse_power: MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
    mileage: MODEL_DATA_RAW_MILEAGE,
    one_platform_restyling_id: MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
    one_platform_marketing_complectation_id: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
    one_platform_marketing_complectation_code: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,
  }

  const modelData = []

  if (!car.modelData['brand'] || !car.modelData['model']) {
    modelData.push(`${map['brand']}:${car.name}`)
  }

  for (const key of Object.keys(map)) {
    const exists = !!car.modelData[key]

    if (!exists) {
      continue
    }

    modelData.push(`${map[key]}:${car.modelData[key]}`)
  }

  return modelData.join(',')
}

export const createSmartContractData = state => {
  const { conditions, config, programs } = state.credit_standalone
  const { values, agreements } = state.form

  const isLeasing = state.credit_standalone.module.isLeasing

  const data = {
    widgetSource: isLeasing ? 'standalone_leasing' : 'standalone_credit',

    benefits: [],
    accessories: [],
    pixel: window['_KDX_UID'] || '',

    personalData: {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      phoneCountry: state.form.phoneSelection.country,
    },
    agreements: {
      personalData: {
        accepted: values.agreement,
      },
      communication: {
        accepted: agreements.communication.accepted,
        phone: agreements.communication.phone,
        sms: agreements.communication.sms,
        email: agreements.communication.email,
        post: agreements.communication.post,
      },
    },
    car: {
      name: conditions.carModel,
      model_data: conditions.carModelData,
      price: Number(conditions.carPrice),
      productType: 'unknown',
      url: '',
      image: '',
    },
    currency: config.currency,
    finance: {
      status: FINANCE_USER_BEHAVIOUR.NONE,
    },
    tradeIn: {
      resolution: {
        status: TRADE_IN_USER_BEHAVIOUR.NONE,
      },
    },
  }

  if (isLeasing) {
    data.finance.leasing = {
      condition: {
        ...config.condition,
        benefitValue: conditions.benefitValue,
      },
      benefitValue: conditions.benefitValue,
      initialFee: Number(conditions.initFee) || 0,
      initialFeePercentage: Number(conditions.initFeePercent) || 0,
      buybackPayment: Number(conditions.buyback) || 0,
      buybackPaymentPercentage: Number(conditions.buybackPercent) || 0,
      period: Number(conditions.term) || 0,
      customBenefit: 0,
    }
  }

  if (!isLeasing) {
    data.finance.credit = {
      initialFee: Number(conditions.initFee) || 0,
      initialFeePercentage: Number(conditions.initFeePercent) || 0,
      buybackPayment: Number(conditions.buyback) || 0,
      buybackPaymentPercentage: Number(conditions.buybackPercent) || 0,
      benefitValue: conditions.benefitValue,
      lifeInsurance: config.lifeInsurance,
      kaskoInsurance: config.kaskoInsurance,
      // partner: programs.selected.partner,
      period: Number(conditions.term) || 0,
      condition: config.condition,
      customBenefit: 0,
      resolution: {
        current_benefit: conditions.benefitValue,
        possible_benefit: conditions.benefitValue,
      },
    }
  }

  if (programs?.selected) {
    if (isLeasing) {
      data.finance.status = FINANCE_USER_BEHAVIOUR.LEASING_APPLIED
      data.finance.leasing.program = programs.selected
    }

    if (!isLeasing) {
      data.finance.status = FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED
      data.finance.credit.program = programs.selected
      data.finance.credit.resolution.current_payment = programs.selected.payment
      data.finance.credit.resolution.possible_payment = programs.selected.payment
    }
  }

  return data
}

export const createGetProgramsPayload = (state, isLeasing) => {
  const {
    carModelData,
    carPrice,
    initFee,
    initFeePercent,
    buyback,
    buybackPercent,
    term,
    benefitValue,
  } = state.conditions


  const { lifeInsurance, kaskoInsurance, gapInsurance, disableBuybackPayment } = state.config

  let payload = {}

  if (isLeasing) {
    payload = {
      modelData: carModelData,
      price: Number(carPrice) + benefitValue,
      initialFeeAmount: Number(initFee) || 0,
      initialFeePercentage: Number(initFeePercent) || 0,
      period: Number(term) || 0,
      productType: 'unknown',
      buybackPaymentAmount: Number(buyback) || 0,
      buybackPaymentPercentage: Number(buybackPercent) || 0,
    }
  }

  if (!isLeasing) {
    const buyback_payment_amount = disableBuybackPayment ? 0 : Number(buyback) || 0
    const buyback_payment_percentage = disableBuybackPayment ? 0 : Number(buybackPercent) || 0
    payload = {
      model_data: carModelData,
      price: Number(carPrice) + benefitValue,
      initial_fee_amount: Number(initFee) || 0,
      initial_fee_percentage: Number(initFeePercent) || 0,
      period: Number(term) || 0,
      productType: 'unknown',
      buyback_payment_amount,
      buyback_payment_percentage,
      life_insurance: lifeInsurance,
      kasko_insurance: kaskoInsurance,
      gap_insurance: gapInsurance,
    }
  }

  return payload
}

export const mapPrograms = (programs, isLeasing) => {
  return programs.map(p => {
    const rate = isLeasing ? String(p.percentage) : p.rate

    let program = {
      ...p,
      rate: rate.replace('.', ','),
      payment: isLeasing ? p.paymentPerMonth : p.payment,
    }

    if (isLeasing) {
      program.totalAmount = p.totalAmount
    }

    return program
  })
}

export const getLocale = type => {
  if (type === 'leasing') {
    return 'l'
  }

  if (type === 'credit') {
    return 'c'
  }
}

export const isLeasingType = type => {
  if (type === 'leasing') {
    return true
  } else {
    return false
  }
}
