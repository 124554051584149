import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { polyfill } from 'seamless-scroll-polyfill'

import DealershipsDropdown from '../../Components/Parts/DealershipsDropdown/DealershipsDropdown'
import FinalState from '../../Components/Parts/FormNew/finalStatus/FinalStatus'
import Form from '../../Components/Parts/FormNew/Form'
import FormBlock from '../../Components/Parts/FormNew/FormBlock'
import ModelsDropdown from '../../Components/Parts/ModelsDropdown/ModelsDropdown'
import Button from '../../Components/Ui/button'
import { FORM_SOURCE, FORM_STATE, FORM_TYPE_BASE } from '../../constants'
import {
  clearProtections,
  clearSmsAndCaptcha,
  countryCodesComparison,
  createdEventPayload,
  createSmartContractWithProtection,
  initializeErrorsProcessing,
} from '../../helpers'
import {
  dealershipsErrors,
  isDealershipValid,
  isSelectedDealershipIsNotActive,
  setActiveDealershipFromBuyModelStore,
  setActiveDealershipFromMainStore,
} from '../../helpers/form/activeDealershipSetter'
import {
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  smartContractCreated,
} from '../../store/actions'
import {
  setBuyModelStandaloneActiveModel,
  setBuyModelStandaloneSavedSC,
} from '../../store/actions/buy_model_standalone'
import { setSimpleFormSmartContract } from '../../store/actions/simple_form'
import {
  createBuyModelSmartContractData,
  createSimpleFromSmartContractData,
  createSmartContractData,
} from '../../store/helpers/smart_contract'
import Loader from '../../TradeInStandalone/ui/loader'
import { canModelsBeShown, carBlockTitle, formName } from './helpers'
import s from './main.module.scss'

polyfill()

function CarOrderForm({
  config,
  dealerships,
  activeDealership,
  isWidgetLoadingFailed,
  saved_sc,
  widget_id,
  models,
  activeModel,
  isModelValid,
  isDealershipValid,
  country,
  formValues,
  limitForSaving,
  captchaSettings,
  smsCodeSettings,
}) {
  const [canCarBlockBeShown, setCanCarBlockBeShown] = useState(false)
  const [hasDealershipError, setHasDealershipError] = useState(false)
  const [hasModelError, setHasModelError] = useState(false)
  const [formHeight, setFormHeight] = useState(0)
  const [formState, setFormState] = useState(FORM_STATE.FORM)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formBody = useRef()

  const dataToSave = useSelector(state => createSmartContractData(state, formName(config).widgetType))
  const dataToSaveBuyModal = useSelector(state => createBuyModelSmartContractData(state, formName(config).widgetType))
  const dataToSaveSimpleForm = useSelector(state =>
    createSimpleFromSmartContractData(state, formName(config).widgetType),
  )

  /**
   * Есть ли модели в форме
   */
  const ARE_MODELS_SHOWN = config.source === FORM_SOURCE.BUY_MODEL

  /**
   * Нажатие по кнопке submit вне формы
   * Валидация заполненных данных, ДЦ, модели
   */
  const submitForm = () => {
    // нажали на кнопку submit вне формы
    window.dispatchEvent(new CustomEvent(formName(config).clickOutsideForm))

    if (hasDealershipError) {
      setHasDealershipError(false)
    }

    // надо проверить ДЦ, тк он лежит вне формы
    if (!isDealershipValid) {
      setHasDealershipError(true)
    }

    // если может быть модель, то надо проверить ее тоже
    if (ARE_MODELS_SHOWN) {
      if (hasModelError) {
        setHasModelError(false)
      }

      if (!isModelValid) {
        setHasModelError(true)
      }
    }
  }

  /**
   * Если форма заполнена верно, то получаем данные из нее в e.detail и сохраняем СК
   * В зависимости от config.source валидируем данные, сохраняем СК и записывем данные в стор
   */
  const listener = useCallback(
    e => {
      e.preventDefault()

      if (!e?.detail?.isFromDataValid) {
        return false
      }

      switch (config.source) {
        case FORM_SOURCE.SIMPLE_FORM:
          setFormState(FORM_STATE.LOADING)

          createSmartContractWithProtection(
            widget_id,
            {
              ticket_answer: formValues.captcha || formValues.smsCode,
              ticket_id: captchaSettings.ticketId || smsCodeSettings.ticketId,
            },
            JSON.stringify({ data: dataToSaveSimpleForm }),
          )
            .then(({ data }) => {
              if (data && data.id) {
                dispatch(setSimpleFormSmartContract(data))
                dispatch(setSmartContractType(formName(config).widgetType))
                dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSaveSimpleForm, data)))
                clearSmsAndCaptcha()
                clearProtections(config.source)

                setFormState(FORM_STATE.SUCCESS)
              } else {
                setFormState(FORM_STATE.ERROR)
              }
            })
            .catch(e => {
              initializeErrorsProcessing({
                e: e,
                formSource: config.source,
                callbackAction: setFormState,
              })
            })

          break
        case FORM_SOURCE.CAR_ORDER:
          if (!isDealershipValid) {
            return false
          }

          setFormState(FORM_STATE.LOADING)

          createSmartContractWithProtection(
            widget_id,
            {
              ticket_answer: formValues.captcha || formValues.smsCode,
              ticket_id: captchaSettings.ticketId || smsCodeSettings.ticketId,
            },
            JSON.stringify({ data: dataToSave }),
          )
            .then(({ data }) => {
              if (data && data.id) {
                dispatch(setSmartContractUrl(data.smartContractUrl))
                dispatch(setSmartContractId(data.id))
                dispatch(setSmartContractGenericId(data.genericId))
                dispatch(setSmartContractToken(data.token))
                dispatch(setSmartContractType(formName(config).widgetType))
                dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSave, data)))
                clearSmsAndCaptcha()
                clearProtections(config.source)

                setFormState(FORM_STATE.SUCCESS)

                window.dataLayer &&
                  window.dataLayer.push({
                    event: 'SmartContract_Create',
                    Status: 'avn_form',
                    buttonName: t('carOrderForm.send') || 'Кнопка сохдания СК из avn_form',
                    carDetail: {
                      identity: dataToSave.car.identity,
                      name: dataToSave.car.name,
                      modelData: dataToSave.car.model_data,
                      productType: dataToSave.car.productType,
                    },
                    widget_id: widget_id,
                    deal_id: `${data.genericId.toUpperCase()}-${data.id}`,
                    eventValue: dataToSave.car.price,
                    currency: dataToSave.currency,
                  })
              } else {
                setFormState(FORM_STATE.ERROR)
              }
            })
            .catch(e => {
              initializeErrorsProcessing({
                e: e,
                formSource: config.source,
                callbackAction: setFormState,
              })
            })

          break

        case FORM_SOURCE.BUY_MODEL:
          if (!isDealershipValid || !isModelValid) {
            return false
          }

          setFormState(FORM_STATE.LOADING)

          createSmartContractWithProtection(
            widget_id,
            {
              ticket_answer: formValues.captcha || formValues.smsCode,
              ticket_id: captchaSettings.ticketId || smsCodeSettings.ticketId,
            },
            JSON.stringify({ data: dataToSaveBuyModal }),
          )
            .then(({ data }) => {
              if (data && data.id) {
                dispatch(setBuyModelStandaloneSavedSC(data))
                dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSaveBuyModal, data)))

                clearSmsAndCaptcha()
                clearProtections(config.source)
                setFormState(FORM_STATE.SUCCESS)

                window.dataLayer &&
                  window.dataLayer.push({
                    event: 'SmartContract_Create',
                    Status: 'buy_model_form',
                    buttonName: t('carOrderForm.send') || 'Кнопка сохдания СК из buy_model_form',
                    carDetail: {
                      name: dataToSaveBuyModal.car.name,
                      modelData: dataToSaveBuyModal.car.model_data,
                      productType: dataToSaveBuyModal.car.productType,
                    },
                    widget_id: widget_id,
                    deal_id: `${data.genericId.toUpperCase()}-${data.id}`,
                    eventValue: dataToSaveBuyModal.car.price,
                    currency: dataToSaveBuyModal.currency,
                  })
              } else {
                setFormState(FORM_STATE.ERROR)
              }
            })
            .catch(e => {
              initializeErrorsProcessing({
                e: e,
                formSource: config.source,
                callbackAction: setFormState,
              })
            })
          break

        default:
          console.error('unknown form source')
          setFormState(FORM_STATE.LOADING)
      }
    },
    [
      config,
      isDealershipValid,
      widget_id,
      dataToSave,
      isModelValid,
      dataToSaveBuyModal,
      dataToSaveSimpleForm,
      dispatch,
      formValues,
      captchaSettings,
    ],
  )

  /**
   * Установка модели
   */
  const setActiveModel = (m, e) => {
    if (m?.id === activeModel?.id) {
      return false
    }

    dispatch(setBuyModelStandaloneActiveModel(m))

    window.dispatchEvent(new CustomEvent('kdxscCloseDropdown'))
  }

  /**
   * Установка дилерского центра
   */
  const selectActiveDealership = d => {
    isSelectedDealershipIsNotActive(activeDealership, d)

    switch (config.source) {
      case FORM_SOURCE.CAR_ORDER:
        setFormState(FORM_STATE.LOADING)
        setActiveDealershipFromMainStore(d, config)
        setFormState(FORM_STATE.FORM)
        break

      case FORM_SOURCE.BUY_MODEL:
        setFormState(FORM_STATE.LOADING)

        // тут делать сброс модели, если ее нет в новом ДЦ
        // eslint-disable-next-line no-case-declarations
        const isModelAvailableInSelectedDealership = d?.forBuyModel?.clientModels?.find(m => m.id === activeModel.id)
        if (!isModelAvailableInSelectedDealership) {
          dispatch(setBuyModelStandaloneActiveModel({}))
        }

        // тут добавлять страну телефона, если ее нет в доступности в новом ДЦ
        // eslint-disable-next-line no-case-declarations
        const isPhoneCountryAvailableInSelectedDealership = d?.forBuyModel?.possibleCountryCodes?.includes(country)
        if (!isPhoneCountryAvailableInSelectedDealership) {
          d.forBuyModel = { ...d.forBuyModel, ...countryCodesComparison(d.forBuyModel, country) }
        }

        setActiveDealershipFromBuyModelStore(d)

        setFormState(FORM_STATE.FORM)
        break

      default:
        console.error('unknown form source')
        setFormState(FORM_STATE.LOADING)
    }
  }

  /**
   * Переход в СК
   */
  const openSmartContract = e => {
    let Status, buttonName, carDetail, eventValue, currency

    // eslint-disable-next-line default-case
    switch (config.source) {
      case FORM_SOURCE.CAR_ORDER:
        Status = 'avn_form'
        buttonName = e?.target?.innerText || `Кнопка перехода в большой СК из avn_form`
        carDetail = {
          identity: dataToSave.car.identity,
          name: dataToSave.car.name,
          modelData: dataToSave.car.model_data,
          productType: dataToSave.car.productType,
        }
        eventValue = dataToSave.car.price
        currency = dataToSave.currency
        break

      case FORM_SOURCE.BUY_MODEL:
        Status = 'buy_model_form'
        buttonName = e?.target?.innerText || `Кнопка перехода в большой СК из buy_model_form`
        carDetail = {
          name: dataToSaveBuyModal.car.name,
          modelData: dataToSaveBuyModal.car.model_data,
          productType: dataToSaveBuyModal.car.productType,
        }
        eventValue = dataToSaveBuyModal.car.price
        currency = dataToSaveBuyModal.currency
        break
    }

    if ([FORM_SOURCE.BUY_MODEL, FORM_SOURCE.CAR_ORDER].includes(config.source)) {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_OnlineDeal',
          Status,
          buttonName,
          carDetail,
          widget_id,
          deal_id: `${saved_sc.genericId.toUpperCase()}-${saved_sc.id}`,
          eventValue,
          currency,
        })
    }

    window.open(saved_sc.url, '_blank')
  }

  /**
   * Слушаем события клика внутри формы (скрытая кнопка)
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickInsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickInsideForm, listener)
    }
  }, [config, listener])

  /**
   * Сбрасываем ошибки при перещелкивании
   */
  useEffect(() => {
    if (isDealershipValid) {
      setHasDealershipError(false)
    }

    if (isModelValid) {
      setHasModelError(false)
    }
  }, [isDealershipValid, isModelValid])

  /**
   * Следим за высотой формы, чтоб страница не скакала
   */
  useEffect(() => {
    if (formBody?.current) {
      setFormHeight(formBody.current.clientHeight)
    }
  }, [])

  /**
   * Можно ли показывать блок с ДЦ и/или моделями
   */
  useEffect(() => {
    switch (config.source) {
      case FORM_SOURCE.CAR_ORDER:
        setCanCarBlockBeShown(dealerships?.length > 1)
        break
      case FORM_SOURCE.BUY_MODEL:
        if (dealerships?.length > 1) {
          setCanCarBlockBeShown(true)
        } else {
          setCanCarBlockBeShown(models?.length && canModelsBeShown(dealerships, config))
        }
        break
      default:
        setCanCarBlockBeShown(false)
        break
    }
  }, [config, dealerships, models])

  return (
    <div className={s.block}>
      {[FORM_STATE.FORM, FORM_STATE.LOADING].includes(formState) && (
        <div className={formState === 'loading' ? s.block_loading : ''} ref={formBody}>
          <div className={[s.block__item, s.blockTitles].join(' ')}>
            <h2 className={[s.header, config.placement !== 'page' ? s.header_sm : ''].join(' ')}>
              {config?.title || t('carOrderForm.title')}
            </h2>
            {config.subtitle && (
              <h3 className={[s.subtitle, config.placement !== 'page' ? s.subtitle_sm : ''].join(' ')}>
                {config.subtitle}
              </h3>
            )}
          </div>

          <div className={s.block__item} style={{ display: canCarBlockBeShown ? 'block' : 'none' }}>
            <FormBlock title={carBlockTitle(dealerships, config)}>
              {dealerships?.length > 1 && (
                <div
                  className={s.block__item}
                  style={{ marginBottom: !canModelsBeShown(dealerships, config) && '0px' }}
                >
                  <DealershipsDropdown
                    selectActiveDealership={selectActiveDealership}
                    config={config}
                    errors={dealershipsErrors(hasDealershipError, isWidgetLoadingFailed).hasErrors}
                    errorsText={dealershipsErrors(hasDealershipError, isWidgetLoadingFailed).errorsText}
                  />
                </div>
              )}
              {models?.length && (
                <div
                  className={s.block__item}
                  style={{ display: canModelsBeShown(dealerships, config) ? 'block' : 'none' }}
                >
                  <ModelsDropdown
                    canModelBeChosen={dealerships?.length > 1 ? activeDealership.widgetId : true}
                    config={config}
                    selectActiveModel={setActiveModel}
                    errors={hasModelError}
                    errorsText={hasModelError ? t('carOrderForm.requiredFiled') : ''}
                  />
                </div>
              )}
            </FormBlock>
          </div>

          <div className={s.block__item}>
            <Form key={formName(config).formKey} type={FORM_TYPE_BASE} config={config} />
          </div>
          <div className={s.block__item}>
            <div className={s.button}>
              <Button
                isDisabled={isWidgetLoadingFailed}
                handleClick={submitForm}
                text={config.buttonText || t('carOrderForm.send')}
                type={'filled'}
                behaviourType="submit"
                placement="carOrderForm"
              />
            </div>
          </div>
        </div>
      )}
      {formState === FORM_STATE.SUCCESS && (
        <FinalState
          text={saved_sc.url ? t('success.description') : t('success.descriptionWithoutLink')}
          icon="success"
          height={config.placement === 'page' ? formHeight : null}
        >
          {saved_sc.url && (
            <Button
              handleClick={openSmartContract}
              text={t('success.goToSmartContract')}
              type={'filled'}
              behaviourType="button"
              placement="carOrderForm"
            />
          )}
        </FinalState>
      )}
      {formState === FORM_STATE.ERROR && (
        <FinalState
          text={limitForSaving ? t('error.limit') : t('error.description')}
          icon="error"
          height={config.placement === 'page' ? formHeight : null}
        >
          {!limitForSaving && (
            <Button
              handleClick={() => setFormState(FORM_STATE.FORM)}
              text={t('error.retry')}
              type={'filled'}
              behaviourType="button"
              placement="carOrderForm"
            />
          )}
        </FinalState>
      )}
      {formState === FORM_STATE.LOADING && (
        <div className="loading">
          <Loader />
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  let dealerships,
    activeDealership,
    isWidgetLoadingFailed,
    saved_sc,
    widget_id,
    models,
    activeModel,
    carInfo,
    calledFromList
  const {
    form: { phoneSelection, values: formValues, captchaSettings, smsCodeSettings },
  } = state

  const { country } = phoneSelection

  const {
    settings: { limitForSaving },
  } = state

  if (ownProps.config.source === FORM_SOURCE.CAR_ORDER) {
    const {
      settings: {
        dealerships: coDealerships,
        activeDealership: coActiveDealership,
        isWidgetLoadingFailed: coIsWidgetLoadingFailed,
        widget_id: coWidgetId,
        calledFromList: coCalledFromList,
      },
      saved_sc: coSavedSc,
      car: { modelData, discounts, productType },
    } = state

    dealerships = coDealerships
    activeDealership = coActiveDealership
    isWidgetLoadingFailed = coIsWidgetLoadingFailed
    widget_id = coWidgetId
    saved_sc = coSavedSc
    calledFromList = coCalledFromList
    carInfo = {
      modelData,
      discounts,
      productType,
    }
  } else if (ownProps.config.source === FORM_SOURCE.BUY_MODEL) {
    const {
      buyModelStandalone: {
        dealerships: bmDealerships,
        activeDealership: bmActiveDealership,
        isWidgetLoadingFailed: bmIsWidgetLoadingFailed,
        widgetId: bmWidgetId,
        savedSC: bmSavedSc,
        models: bmModels,
        activeModel: bmActiveModel,
      },
    } = state

    dealerships = bmDealerships
    activeDealership = bmActiveDealership
    isWidgetLoadingFailed = bmIsWidgetLoadingFailed
    widget_id = bmWidgetId
    saved_sc = bmSavedSc
    models = bmModels
    activeModel = bmActiveModel
  } else if (ownProps.config.source === FORM_SOURCE.SIMPLE_FORM) {
    const {
      simpleForm: {
        config: { widgetId: sfWidgetId },
        smartContact: sfSavedSc,
      },
    } = state

    widget_id = sfWidgetId
    saved_sc = { ...sfSavedSc, url: sfSavedSc?.smartContractUrl }
  }

  return {
    dealerships,
    activeDealership,
    isWidgetLoadingFailed,
    saved_sc,
    widget_id,
    models,
    activeModel,
    calledFromList,
    carInfo,
    country,
    formValues,
    limitForSaving: limitForSaving[ownProps.config.source],
    captchaSettings: captchaSettings[ownProps.config.source],
    smsCodeSettings: smsCodeSettings[ownProps.config.source],
    isModelValid: Boolean(activeModel?.id),
    isDealershipValid: isDealershipValid(dealerships, activeDealership, isWidgetLoadingFailed),
  }
}

export default connect(mapStateToProps)(CarOrderForm)
