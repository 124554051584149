import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect, useDispatch, useSelector } from 'react-redux'

import { formName } from '../../../carOrderForm/main/helpers'
import {
  FORM_SOURCE,
  FORM_TYPE_ONLINE_PAYMENT,
  SCREEN_ERROR,
  SCREEN_ONLINE_PAYMENT_FAILED,
  SCREEN_ONLINE_PAYMENT_START,
  SCREEN_SUCCESS,
} from '../../../constants'
import {
  clearProtections,
  clearSmsAndCaptcha,
  createdEventPayload,
  createSmartContractWithProtection,
  formatCurrency,
  formatPrice,
  formValidation,
  goToFromLink,
  goToPaymentFullUrl,
  goToPaymentUrl,
  initializeErrorsProcessing,
  online_payment_button_text_formatter,
  SmartContract_Prepay2Submit,
  SmartContract_Prepay2Success,
} from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  goToScreen,
  setSmartContactPaymentUrl,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  smartContractCreated,
  startLoading,
  stashFormErrors,
  stopLoading,
} from '../../../store/actions'
import { createSmartContractData } from '../../../store/helpers/smart_contract'
import ICircleIconBig from '../../../svgComponents/iCircleBig'
import Form from '../../Parts/FormNew/Form'
import { onlinePaymentButtonText } from '../../Parts/FormNew/helpers'
import FullPayment from '../../Parts/FullPayment/FullPayment'
import Button from '../../Ui/button'
import s from './onlinePayment.module.scss'
import OnlinePaymentHint from './OnlinePaymentHint'

function OnlinePayment({
  currency,
  showOnlinePayment,
  form,
  fields,
  widget_id,
  onlinePaymentBenefitAmount,
  car,
  priceToolTipEnabled,
  hideHeaderTooltip,
  enableFullPayment,
  activePayment,
  locale,
  vehicleType,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isBottom, setIsBottom] = useState(false)

  const config = {
    source: FORM_SOURCE.ONLINE_PAYMENT,
  }

  // типо того, getCreditData маппит данные из стора как тебе нужно
  const dataToSave = useSelector(state => createSmartContractData(state))

  const paymentRevertDisclaimer = !hideHeaderTooltip
  const benefitDisclaimer = onlinePaymentBenefitAmount !== 0
  const showDisclaimers = Boolean(paymentRevertDisclaimer || benefitDisclaimer)
  // const showDisclaimers = false

  const btnText = onlinePaymentButtonText()

  // скролл вверх
  const up = () => {
    if (isBottom) {
      setIsBottom(false)
    }
  }

  // если элемент проскроллен вниз
  const end = () => {
    if (!isBottom) {
      setIsBottom(true)
    }
  }

  // подсказка к цене
  const priceDisclaimer = () => {
    return (
      <div className={s.priceDisclaimer}>
        <OnlinePaymentHint icon={<ICircleIconBig />}>{t(`car.tooltipAlert.${vehicleType}`)}</OnlinePaymentHint>
      </div>
    )
  }

  /**
   * Нажатие по кнопке submit создание СК
   */
  const submitForm = () => {
    window.dispatchEvent(new CustomEvent(formName(config).clickOutsideForm))
  }

  /**
   * Слушаем событие из формы и сохранеям СК
   */
  const listener = useCallback(
    e => {
      // отправка нажатия на кнопку в gtm теперь в компоненте формы

      dispatch(stashFormErrors())

      const errors = formValidation(form, fields, FORM_TYPE_ONLINE_PAYMENT, locale)

      if (enableFullPayment && !activePayment.id) {
        return false
      }

      const isFull = activePayment.id === 'full'

      if (!errors.length) {
        dispatch(startLoading())

        createSmartContractWithProtection(
          widget_id,
          {
            ticket_answer: form.values.captcha || form.values.smsCode,
            ticket_id:
              form.captchaSettings[FORM_SOURCE.ONLINE_PAYMENT].ticketId ||
              form.smsCodeSettings[FORM_SOURCE.ONLINE_PAYMENT].ticketId,
            payments: true,
          },
          JSON.stringify({ data: dataToSave }),
        )
          .then(contractResponse => {
            if (contractResponse?.data?.genericId && contractResponse?.data?.id) {
              const hasUrl = contractResponse?.data?.smartContractUrl?.length

              if (hasUrl) {
                dispatch(setSmartContractUrl(contractResponse.data.smartContractUrl))
              }

              dispatch(setSmartContractId(contractResponse.data.id))
              dispatch(setSmartContractGenericId(contractResponse.data.genericId))
              dispatch(setSmartContractType('widget'))
              dispatch(setSmartContractToken(contractResponse.data.token))
              dispatch(
                smartContractCreated(
                  createdEventPayload(widget_id, dataToSave, contractResponse.data, 'onlinePayment'),
                ),
              )
              clearSmsAndCaptcha()
              clearProtections(FORM_SOURCE.ONLINE_PAYMENT)

              if (isFull) {
                // goToPaymentFullUrl надо отправить токен POSTом JSON str{data: token} token=contractResponse.data.token
                // придет урл, его нужно открыть в новой вкладке
                // data.url
                goToPaymentFullUrl(
                  contractResponse.data.genericId,
                  contractResponse.data.id,
                  JSON.stringify({ data: { token: contractResponse.data.token } }),
                )
                  .then(fullPaymentResponse => {
                    try {
                      const paymentUrl = fullPaymentResponse.data.url

                      // отправка в window.dataLayer
                      SmartContract_Prepay2Success({
                        buttonName: btnText,
                        car,
                        widget_id,
                        currency,
                        contractResponse,
                      })

                      dispatch(goToScreen(SCREEN_SUCCESS))

                      goToFromLink(paymentUrl)
                    } catch (e) {
                      dispatch(goToScreen(SCREEN_ONLINE_PAYMENT_FAILED))
                    }
                  })
                  .catch(e => {
                    dispatch(goToScreen(SCREEN_ONLINE_PAYMENT_FAILED))
                  })
                dispatch(goToScreen(SCREEN_SUCCESS))
              } else {
                goToPaymentUrl(contractResponse.data.genericId, contractResponse.data.id).then(paymentResponse => {
                  try {
                    const paymentUrl = paymentResponse.data.attributes.url

                    dispatch(setSmartContactPaymentUrl(paymentUrl))
                    dispatch(goToScreen(SCREEN_ONLINE_PAYMENT_START))

                    // отправка в window.dataLayer
                    SmartContract_Prepay2Success({
                      buttonName: btnText,
                      car,
                      widget_id,
                      currency,
                      contractResponse,
                    })
                  } catch (e) {
                    dispatch(goToScreen(SCREEN_ONLINE_PAYMENT_FAILED))
                  }
                })
              }
            } else {
              dispatch(goToScreen(SCREEN_ERROR))
            }
          })
          .catch(e => {
            initializeErrorsProcessing({ e: e, formSource: FORM_SOURCE.ONLINE_PAYMENT })
          })
          .finally(() => {
            dispatch(stopLoading())
          })
      }
    },
    [car, currency, dataToSave, dispatch, fields, form, widget_id],
  )
  /**
   * Слушаем данные из формы
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickInsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickInsideForm, listener)
    }
  }, [listener])

  return (
    <div className={s.wrapper} data-project-name={getProjectName()}>
      <PerfectScrollbar
        className={s.wrapper__content}
        options={{
          maxScrollbarLength: 100,
        }}
        onYReachEnd={container => up()}
        onScrollUp={container => end()}
      >
        <div className={s.content}>
          {showDisclaimers && (
            <div className={s.content__disclaimers}>
              <div className={s.disclaimers}>
                {paymentRevertDisclaimer && (
                  <div className={s.disclaimers__item}>
                    <OnlinePaymentHint icon={<ICircleIconBig />}>
                      {t('onlinePayment.whatHappensIfMyOpinionChanged')}
                    </OnlinePaymentHint>
                  </div>
                )}

                {benefitDisclaimer && (
                  <div className={s.disclaimers__item}>
                    <OnlinePaymentHint icon={<ICircleIconBig />}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('onlinePayment.benefitText', {
                            value: `${formatPrice(onlinePaymentBenefitAmount, currency)}`,
                          }),
                        }}
                      />
                    </OnlinePaymentHint>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={[s.content__form, showDisclaimers ? s.withAttention : ''].join(' ')}>
            <div className={s.form}>
              <div className={s.form__title}>
                {enableFullPayment
                  ? t(`onlinePayment.formTitleWithFillPayment.${vehicleType}`)
                  : t('onlinePayment.formTitleWithAdvancePayment')}
              </div>
              <div className={s.form__row}>
                <Form key={'widgetOnlinePaymentForm'} type={FORM_TYPE_ONLINE_PAYMENT} config={config} />
              </div>
              {enableFullPayment && (
                <div className={s.form__row}>
                  <div className={s.fullPayment}>
                    <div className={s.fullPayment__title}>{t('onlinePayment.fullPaymentTitle')}</div>
                    <div className={s.fullPayment__body}>
                      <FullPayment isWhite={!showDisclaimers} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {priceToolTipEnabled && <div className={s.content__priceDisclaimer}>{priceDisclaimer()}</div>}
        </div>
      </PerfectScrollbar>

      <div className={[s.wrapper__footer, isBottom ? s.shadowed : ''].join(' ')}>
        <div className={s.footer}>
          {priceToolTipEnabled && <div className={s.footer__disclaimer}>{priceDisclaimer()}</div>}
          {showOnlinePayment && (
            <div className={s.footer__button}>
              <Button
                handleClick={submitForm}
                text={btnText}
                id="kdxsc_prepay2"
                isDisabled={enableFullPayment && !activePayment?.id}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  onlinePayment: { enabled, amount, benefitAmount: onlinePaymentBenefitAmount, enableFullPayment, activePayment },
  settings: {
    currency,
    widget_id,
    locale,
    visual: { buttons, fields, onlinePayment },
    priceToolTipEnabled,
    vehicleType,
  },
  form,
  car,
}) {
  const onlinePaymentFormattedValue = `${formatPrice(amount, currency)}`

  return {
    priceToolTipEnabled,
    currency,
    hideHeaderTooltip: onlinePayment && onlinePayment.hideHeaderTooltip,
    onlinePaymentFormattedValue,
    showOnlinePayment: enabled,
    form,
    fields,
    widget_id,
    onlinePaymentBenefitAmount,
    buttons,
    car,
    enableFullPayment,
    activePayment,
    locale,
    vehicleType,
  }
}

export default connect(mapStateToProps)(OnlinePayment)
