import s from './styles/category.module.scss'

function AccessoryCategory({ children, title }) {
  return (
    <div className={s.category}>
      <div className={s.category__title}>{title}</div>
      <div className={s.category__body}>{children}</div>
    </div>
  )
}

export default AccessoryCategory
