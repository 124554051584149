import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'

import { localeDe } from './locales/de'
import { localeEn } from './locales/en'
import { localeRu } from './locales/ru'

const resources = {
  en: {
    translation: localeEn,
  },
  ru: {
    translation: localeRu,
  },
  de: {
    translation: localeDe,
  },
}

i18n.use(ICU).use(initReactI18next).init({
  resources,
  lng: 'ru',
  fallbackLng: 'en',
})

export default i18n
