import { isFirefox, isIOS } from 'react-device-detect'

export const goToFromLink = link => {
  let a = document.createElement('a')
  a.href = link

  if (isIOS || isFirefox) {
    a.target = '_self'
  } else {
    a.target = '_blank'
  }

  a.click()
  a.remove()
}
