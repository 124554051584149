import { availableCarsCountInDealership } from './availableCarsCountInDealership'

export const normalCarAndWidgetForBoot = availableDealerships => {
  let normalDealership = availableDealerships.find(d => availableCarsCountInDealership(d))
  if (normalDealership) {
    let normalCar = normalDealership.cars.find(normalCar => normalCar.status === 'available')

    return {
      widgetId: normalDealership.widget_id,
      price: normalCar.price,
      identity: normalCar.identity,
    }
  } else {
    let normalDealership = availableDealerships[0]

    if (normalDealership) {
      let normalCar = normalDealership.cars[0]

      return {
        widgetId: normalDealership.widget_id,
        price: normalCar.price,
        identity: normalCar.identity,
      }
    } else {
      return {
        widgetId: '',
        price: 0,
        identity: '',
      }
    }
  }
}
