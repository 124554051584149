import { API_ACTIONS } from '../types'

const INIT_STATE = {
  widgetBooted: false,
  lastMethod: 'booted',
  openedFromApi: false,
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_ACTIONS.BOOTED_EVENT:
      return {
        ...state,
        widgetBooted: true,
      }

    // case API_ACTIONS.BOOTED_LIST_EVENT:
    //   return {
    //     ...state,
    //     widgetBooted: true,
    //   }

    case API_ACTIONS.LOG_LAST_METHOD:
      return {
        ...state,
        lastMethod: action.payload,
      }

    case API_ACTIONS.OPENED_FROM_API:
      return {
        ...state,
        openedFromApi: action.payload,
      }

    default:
      return state
  }
}

export default reducer
