import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import s from './models.module.scss'

function Models({ models, handleClick, activeModel }) {
  const { t } = useTranslation()
  const [word, setWord] = useState('')

  const suitableModels = models.filter(m => m.attributes.name.toLowerCase().includes(word.toLowerCase()))

  return (
    <div className={s.wrapper}>
      <div className={s.search}>
        <input
          type="text"
          className={s.input}
          placeholder={`${t('carOrderForm.search')}...`}
          onChange={e => setWord(e.target.value)}
          value={word}
        />
      </div>

      {suitableModels && suitableModels.length ? (
        <div className={s.list}>
          {suitableModels.map((item, id) => {
            return (
              <div
                className={[s.list__item, activeModel?.id === item.id ? s.list__item_active : ''].join(' ')}
                key={id}
                onClick={e => {
                  e.preventDefault()
                  handleClick(item)
                }}
              >
                {item.attributes.name}
              </div>
            )
          })}
        </div>
      ) : (
        <div className={s.noResults}>{t('carOrderForm.nothingFound')}</div>
      )}
    </div>
  )
}

export default Models
