export const formID = {
  dealerships: 'kdxcc_dealerships',
  buyback: 'kdxcc_buyback',
  buybackPercent: 'kdxcc_buybackPercent',
  carPrice: 'kdxcc_carPrice',
  initFee: 'kdxcc_initFee',
  initFeePercent: 'kdxcc_initFeePercent',
  carModel: 'kdxcc_carModel',
  term: 'kdxcc_term',
  model: 'kdxcc_model',
  brand: 'kdxcc_brand',
}
