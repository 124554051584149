import {useTranslation} from 'react-i18next';
import {connect}        from 'react-redux';

import {TRADE_IN_STANDALONE_SCREEN} from '../../constants';
import TradeInStandaloneFilters     from '../filters';
import PersonalDataForm             from '../screens/personalDataForm';
import TradeInStandaloneFirstScreen from '../screens/rateScreen';
import TradeInStandaloneFinalScreen from '../screens/saveScreen';
import Loader                       from '../ui/loader';
import s                            from './styles/main.module.scss';
import {getProjectName}             from "../../helpers/app/detectors/project_name";

function TradeInStandalone({
	                           loading,
	                           isWidgetLoaded,
	                           standaloneScreen,
	                           image
                           }) {
	const {t} = useTranslation();

	const loadingStyles = loading || isWidgetLoaded ? {filter: 'blur(2px)'} : {};

	const screen = () => {
		switch (standaloneScreen) {
			case TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM:
				return <PersonalDataForm/>;
			case TRADE_IN_STANDALONE_SCREEN.SAVE:
				return <TradeInStandaloneFinalScreen/>;
			default:
				return <TradeInStandaloneFirstScreen/>;
		}
	};

	console.log("getProjectName()", getProjectName());

	return (
		<div className={s.wrapper} data-project-name={getProjectName()}>
			<div className={s.inner}>
				<div className={s.inner__title}>
					<div className={s.title} id="kdx_sc_tradein_calc_title">{t('tradeInS.title')}</div>
				</div>
				<div className={s.inner__content}>
					{loading && <Loader/>}

					<div className={s.inner__filters} style={loadingStyles}>
						<TradeInStandaloneFilters/>
					</div>
					<div className={s.inner__content} style={loadingStyles}>
						{screen()}
					</div>
				</div>
			</div>
			{getProjectName() === 'generic_v2' && image && (
				<div className={s.image__container}>
					<div className={s.image__gradient}></div>
					<div className={s.image__image}>
						<img src={image} alt=""/>
					</div>
				</div>
			)}
		</div>
	);
}

function mapStateToProps({
	                         screen:            {loading},
	                         tradeIn:           {standaloneScreen},
	                         tradeInStandalone: {isWidgetLoaded}
                         }) {
	return {
		standaloneScreen,
		loading,
		isWidgetLoaded
	};
}

export default connect(mapStateToProps)(TradeInStandalone);
