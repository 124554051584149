import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import {
  FORM_TYPE_BASE,
  TI_PARTNER,
  TRADE_IN_DATA_FILLED,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_STANDALONE_SCREEN,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import { formValidation, isTradeInByIdentityDataFilled, isTradeInDataFilled } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { isCarIdentityValid } from '../../../helpers/validations'
import car from '../../../icons/car.svg'
import {
  rateTradeIn,
  rateTradeInByIdentity,
  resetBrand,
  resetCarIdentity,
  resetCity,
  resetMileage,
  resetSavedSmartContract,
  setIsCarIdentityValid,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  setStepWeight,
  setTradeInBehaviourByUser,
  setTradeInCarIdentity,
  setTradeInEstimationType,
  setTradeInStandaloneScreen,
  setTradeInStep,
  stashFormErrors,
  toggleTradeInStep,
} from '../../../store/actions'
import TradeInStandaloneSteps from '../../firstScreenElements/steps/index.js'
import TradeInStandaloneStepsByIdentity from '../../firstScreenElements/steps/indexByIdentity.js'
import BorderedBlock from '../../ui/borderedBlock'
import Button from '../../ui/button'
import s from './styles/screen.module.scss'

function TradeInStandaloneFirstScreen({
  values,
  stepsRemain,
  form,
  fields,
  dealerships,
  disableSmartContractLink,
  idSC,
  typeSC,
  behaviourByUser,
  locale,
  showTiCalculationModeSwitcher,
  showEstimationByIdentityScreen,
  partner,
  step,
  isCarIdentityValueValid,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const autoRuText = (
    <div
      className={s.autoRuText}
      dangerouslySetInnerHTML={{
        __html: t(
          getProjectName() !== 'generic_v2'
            ? 'tradeIn.rateResultSuccessDescription'
            : 'tradeIn.rateResultSuccessDescriptionGenericV2',
        ),
      }}
    />
  )

  const complete = () => {
    if (showEstimationByIdentityScreen) {
      return isTradeInByIdentityDataFilled(values) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED || false
    } else {
      return isTradeInDataFilled(values) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED || false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    /*
    Если расчет по идентификатору, то
    1. всегда проверяем валидность, если не норм, то возаращем на шаг ввода и пишем сообщение об ошибке
    2. если goToCity, то идем на город
    3. вызываем rateTradeInByIdentity
     */

    if (showEstimationByIdentityScreen) {
      const identity = values?.identity?.value?.toString()
      const isValid = isCarIdentityValid(identity)
      if (!isValid) {
        dispatch(setStepWeight('identity'))
        dispatch(setTradeInStep('identity'))
        dispatch(setIsCarIdentityValid(false))
        return
      }

      dispatch(setIsCarIdentityValid(true))

      const bp = buttonProps()
      if (bp.action === 'goToCity') {
        dispatch(setStepWeight('city'))
        dispatch(toggleTradeInStep('city'))
        return
      }

      handleSubmitByIdentity()
      pushToDataLayer()
    } else {
      /* Если расчет по обычный, то даем как обычно */
      handleSubmitByDefault()
      pushToDataLayer()
    }
  }

  const handleSubmitByIdentity = () => {
    handleSubmitByDefault(true)
  }

  const handleSubmitByDefault = (rateByVin = false) => {
    const isSCExistsButWithNoLink = disableSmartContractLink && idSC && typeSC === 'tradeInStandalone'
    const hasSomeTIDataChanged = behaviourByUser === TRADE_IN_USER_BEHAVIOUR.STARTED

    // если СК уже создан (без ссылки) и что-то изменилось в данных,
    // то надо стереть все данные и пересоздать СК на шаге 3
    if (isSCExistsButWithNoLink && hasSomeTIDataChanged) {
      dispatch(setSmartContractUrl(''))
      dispatch(setSmartContractId(''))
      dispatch(setSmartContractGenericId(''))
      dispatch(setSmartContractToken(''))
      dispatch(setSmartContractType(''))
    }

    if (rateByVin) {
      dispatch(rateTradeInByIdentity(true))
    } else {
      dispatch(rateTradeIn(false, true))
    }

    dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.APPLIED))

    dispatch(stashFormErrors())
    const errors = formValidation(form, fields, FORM_TYPE_BASE, locale)

    const isPersonalDataNextScreen = errors.length > 0 || dealerships?.length > 1

    if (isPersonalDataNextScreen) {
      dispatch(stashFormErrors())
      dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM))
    } else {
      dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.SAVE))
    }

    dispatch(toggleTradeInStep(''))
  }

  const pushToDataLayer = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: 'tradeInStandalone',
        Status: 'complete',
        buttonName: t('tradeInS.rateCar'),
        widget_id: '',
        deal_id: '',
        eventValue: '',
      })
  }

  const changeTradeInEstimationMode = () => {
    if (idSC) {
      dispatch(resetSavedSmartContract())
    }

    if (showEstimationByIdentityScreen) {
      // переключаемся на лист и делаем его "свежим"
      dispatch(setTradeInCarIdentity({ payload: '' }))
      dispatch(resetBrand(partner))
      dispatch(setStepWeight('brand'))
      dispatch(setTradeInStep('brand'))
      dispatch(setTradeInEstimationType(TRADE_IN_ESTIMATION_TYPE.LIST))
    } else {
      // переключаемся на идентификатор и делаем его "свежим"
      dispatch(resetCarIdentity(partner))
      dispatch(resetCity(partner))
      dispatch(resetMileage(partner))
      dispatch(setStepWeight('identity'))
      dispatch(setTradeInStep('identity'))
      dispatch(setIsCarIdentityValid(true))
      dispatch(setTradeInEstimationType(TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ))
    }
  }

  const buttonProps = () => {
    let action, disabled, text
    const c = complete()
    if (showEstimationByIdentityScreen) {
      const isStepIdentity = step === 'identity'
      const isCarIdentityExist = values?.identity?.value
      action = isStepIdentity ? 'goToCity' : 'calculateTradeIn'
      disabled = isStepIdentity ? !isCarIdentityExist : !c
      text = isStepIdentity ? t('tradeIn.steps.goToCity') : c ? t('tradeInS.rateCar') : t(`${stepsRemain}`)
    } else {
      action = 'calculateTradeIn'
      disabled = !c
      text = c ? t('tradeInS.rateCar') : t(`${stepsRemain}`)
    }
    return { action, disabled, text }
  }

  return (
    <form className={s.content} onSubmit={handleSubmit}>
      <div className={s.content__steps}>
        {showEstimationByIdentityScreen ? <TradeInStandaloneStepsByIdentity /> : <TradeInStandaloneSteps />}

        {showEstimationByIdentityScreen && !isCarIdentityValueValid && (
          <div className={s.content__error}>{t('tradeIn.vinOrGrzErrorText')}</div>
        )}
      </div>

      {!buttonProps().disabled && getProjectName() === 'generic_v2' && (
        <div className={s.content__rate}>
          <div className={s.rate}>
            <Button isDisabled={buttonProps().disabled} text={buttonProps().text} />
          </div>
        </div>
      )}
      {getProjectName() !== 'generic_v2' && (
        <div className={s.content__rate}>
          <div className={s.rate}>
            <Button isDisabled={buttonProps().disabled} text={buttonProps().text} />
          </div>
        </div>
      )}
      <div className={s.content__disclaimer}>
        <div className={s.disclaimer}>
          <BorderedBlock icon={car} inner={autoRuText} />
        </div>
      </div>
      {showTiCalculationModeSwitcher && (
        <div className={s.content__disclaimer}>
          <div className="kdxsc-tradein-estimation-type-block centered">
            <div onClick={changeTradeInEstimationMode} className="kdxsc-tradein-estimation-type-switcher">
              {showEstimationByIdentityScreen
                ? t('trade_in.estimations.goToTradeInList')
                : t('trade_in.estimations.goToTradeInByIdentity')}
            </div>
            <div className="kdxsc-tradein-estimation-type-hint">{t('trade_in.estimations.reset')}</div>
          </div>
        </div>
      )}
    </form>
  )
}

function mapStateToProps({
  settings: {
    dealerships,
    visual: { fields },
    disableSmartContractLink,
  },
  form,
  tradeIn: {
    values,
    behaviourByUser,
    locale,
    partner,
    step,
    isTradeInByIdentityEnabled,
    estimationType,
    isCarIdentityValid: isCarIdentityValueValid,
  },
  saved_sc: { id: idSC, type: typeSC },
}) {
  const { brand, model, generation, body, modification, city, year, color, owner, mileage, identity } = values

  const stepsRemain = () => {
    switch (false) {
      case !!brand.id:
        return 'tradeIn.steps.tenStepsRemain'
      case !!model.id:
        return 'tradeIn.steps.nineStepsRemain'
      case !!generation.id:
        return 'tradeIn.steps.eightStepsRemain'
      case !!body.id:
        return 'tradeIn.steps.sevenStepsRemain'
      case !!modification.id:
        return 'tradeIn.steps.sixStepsRemain'
      case !!year.value:
        return 'tradeIn.steps.fiveStepsRemain'
      case !!color.id:
        return 'tradeIn.steps.fourStepsRemain'
      case !!owner.id:
        return 'tradeIn.steps.threeStepsRemain'
      case !!city.id:
        return 'tradeIn.steps.twoStepsRemain'
      case !!mileage.value:
        return 'tradeIn.steps.homeStretch'
      default:
        return 'tradeIn.steps.tenStepsRemain'
    }
  }

  const stepsRemainForEstimationByIdentity = () => {
    switch (false) {
      case !!identity.value:
        return 'tradeIn.steps.goToCity'
      case !!city.id:
        return 'tradeIn.steps.twoStepsRemain'
      case !!mileage.value:
        return 'tradeIn.steps.homeStretch'
      default:
        return 'tradeIn.steps.threeStepsRemain'
    }
  }

  const { errors } = form

  const showEstimationByIdentityScreen =
    partner === TI_PARTNER.AUTO_RU && estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ

  return {
    values,
    stepsRemain: showEstimationByIdentityScreen ? stepsRemainForEstimationByIdentity() : stepsRemain(),

    errors,
    form,
    fields,
    dealerships,

    disableSmartContractLink,
    idSC,
    typeSC,
    behaviourByUser,
    locale,
    partner,

    showTiCalculationModeSwitcher: partner === TI_PARTNER.AUTO_RU && isTradeInByIdentityEnabled,
    showEstimationByIdentityScreen,
    step,
    isCarIdentityValueValid,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneFirstScreen)
