module.exports = {
  choose: 'Select a dealership to continue',
  available: 'Available',
  booked: 'Choose an analog',
  errorMessage:
    'Unfortunately, there was an error occurred while getting information from your chosen dealership. Please try another dealership or use the widget later.',
  yourDealership: 'Your dealership',
  selected: 'Selected',
  unavailableHint: {
    auto: 'This car is {carStatus}.<br />You can save the deal and manager will pick up a similar car for you.',
    moto: 'This bike is {carStatus}.<br />You can save the deal and manager will pick up a similar bike for you.',
    other:
      'This vehicle is {carStatus}.<br />You can save the deal and manager will pick up a similar vehicle for you.',
  },
}
