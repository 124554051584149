import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'

import { formatPrice } from '../../helpers'
import s               from './styles/card.module.scss'
import {getProjectName} from "../../helpers/app/detectors/project_name";

const Card = ({
  type,
  program,
  selectedProgramId,
  handleSelect,
  fields,
  firstName,
  lastName,
  phone,
  email,
  isPreview,
  currency,
  showDescription,
}) => {
  const { t } = useTranslation()

  const buyback = useSelector(state => state.credit_standalone.conditions.buyback, shallowEqual)
  const hideProgramPercentage = useSelector(state => state.credit_standalone.config.hideProgramPercentage, shallowEqual)

  const { name, rate, id, description } = program || ''

  const handleSelectClick = () => handleSelect && handleSelect(program)

  const handleSelectKeyDown = e => {
    if (e.key === 'Enter') {
      handleSelect && handleSelect(program)
    }
  }

  const isProgram = type === 'program'

  const userName = `${!fields?.name?.hide ? firstName : ''} ${!fields?.lastName?.hide ? lastName : ''}`

  return (
    <article
      data-project-name={getProjectName()}
      className={`${s.container} ${s[type]}`}
      data-is-preview={isPreview || false}
      tabIndex={isProgram ? 0 : ''}
      data-selected={isProgram && id === selectedProgramId}
      onClick={isProgram ? handleSelectClick : null}
      onKeyDown={isProgram ? handleSelectKeyDown : null}
    >
      {isProgram && (
        <div className={s.radio_box}>
          <div className={s.radio} />
        </div>
      )}

      <div className={s.box}>
        <div className={s.body} data-no-margin={isProgram || !userName.trim().length} data-is-preview={isPreview}>
          <div className={s.name} data-no-margin={!isProgram || !userName.trim().length} data-is-preview={isPreview}>
            {isProgram ? name : userName}
          </div>
          {!isPreview && (
            <div className={s.money_box}>
              {isProgram && !hideProgramPercentage && <div className={s.rate}>{rate} %</div>}
              {isProgram && (
                <div className={s.payment}>
                  {t('credit.pricePerMonth', { value: formatPrice(program.payment, currency) })}
                </div>
              )}
            </div>
          )}
        </div>
        {program?.redemptionValue > 0 && buyback > 0 && (
          <div className={s.money_box} data-additional-info={true}>
            <div className={s.rate}>{t('credit.redemptionPrice')}</div>
            <div className={s.payment} data-additional-info={true}>
              {formatPrice(program?.redemptionValue, currency)}
            </div>
          </div>
        )}

        {showDescription && description?.length && (
          <div className={s.footer} data-personal={!isProgram}>
            <span className={s.text}>{description}</span>
          </div>
        )}

        {program?.buybackPayment && (
          <div className={s.footer}>
            <span className={s.text}>
              {t('leasing.screen.buybackPayment')}: {formatPrice(program?.buybackPayment)}
            </span>
          </div>
        )}

        {!isProgram && (
          <div className={s.footer} data-personal={!isProgram}>
            <span className={s.text}>{phone}</span>
            <span className={s.text}>{email}</span>
          </div>
        )}
      </div>
    </article>
  )
}

export default Card
