const INIT_STATE = {
  url: '',
  payment_url: '',
  id: '',
  genericId: '',
  type: '', // откуда был сохранен - из обычного виджета или TradeInStandalone,
  token: '',
}

const saved_sc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_URL':
      return {
        ...state,
        url: action.url,
      }
    case 'SET_PAYMENT_URL':
      return {
        ...state,
        payment_url: action.payment_url,
      }
    case 'SET_ID':
      return {
        ...state,
        id: action.id,
      }
    case 'SET_GENERIC_ID':
      return {
        ...state,
        genericId: action.payload,
      }
    case 'SET_TYPE':
      return {
        ...state,
        type: action.payload,
      }
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      }
    case 'RESET_SAVED_SMART_CONTRACT':
      return INIT_STATE

    default:
      return state
  }
}

export default saved_sc
