import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { TRADE_IN_CITY_TYPE_BE, TRADE_IN_CITY_TYPE_CATALOG, TRADE_IN_DEFAULT_CITY_PREFIX } from '../../../constants'
import { getCities } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { setTradeInCity, setTradeInCityType, toggleTradeInStep } from '../../../store/actions'
import Button from '../../Ui/button'
import Input from '../../Ui/input'

function TradeInCity({ widgetId, step, id, name, useCityAsString, defaultCities, cityType, forceOpened }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [search, setSearch] = useState(name || '')
  const [loading, setLoading] = useState(false)
  const [variants, setVariants] = useState([])
  const [debouncedSearch] = useDebounce(search, 500)

  const setCityFromString = () => {
    if (!search) {
      return
    }

    const variant = {
      id: search,
      name: search,
    }
    dispatch(setTradeInCity(variant))
  }

  const onSet = event => {
    const {
      target: { value },
    } = event

    const weNeedToRunQuery = value && typeof value === 'string' && value.includes(TRADE_IN_DEFAULT_CITY_PREFIX)
    if (weNeedToRunQuery) {
      const city = defaultCities.find(c => c.id === value)
      setSearch(city?.name)
      dispatch(setTradeInCityType(TRADE_IN_CITY_TYPE_BE))
    } else {
      dispatch(setTradeInCityType(TRADE_IN_CITY_TYPE_CATALOG))
      const variant = variants.find(variant => String(variant.id) === value)
      if (variant?.id && variant?.name) {
        dispatch(setTradeInCity(variant))
      }
    }
  }

  useEffect(() => {
    if (useCityAsString) {
      return
    }

    setVariants([])

    if (debouncedSearch !== '') {
      setLoading(true)

      getCities(widgetId, debouncedSearch)
        .then(({ data }) => {
          let v = []
          if (data) {
            v = data.map(city => {
              let external = ''

              if (city.parents.length > 2) {
                external = ' (' + city.parents[2] + ')'
              }

              if (city.parents.length > 3) {
                external = ' (' + city.parents[2] + ', ' + city.parents[3] + ')'
              }

              return {
                id: city.id,
                name: city.name,
                external,
              }
            })
            setVariants(v)
          }
          if (v && v.length === 1 && cityType === TRADE_IN_CITY_TYPE_BE) {
            dispatch(setTradeInCity(v[0]))
          }
        })
        .finally(() => setLoading(false))
    }
  }, [debouncedSearch, widgetId])

  const content = () => {
    if (variants.length) {
      return <div className="kdxsc-row">{variants.map(variant => radioButtonTemplate(variant))}</div>
    } else if (defaultCities && !loading) {
      return <div className="kdxsc-row">{defaultCities.map(city => radioButtonTemplate(city, 'defaultCity'))}</div>
    } else {
      return null
    }
  }

  const radioButtonTemplate = (variant, type = 'variant') => {
    const labelClassName = type === 'defaultCity' ? 'kdxsc-default-radio-label' : 'kdxsc-pl-5'
    const columnClassName = type === 'defaultCity' ? 'kdxsc-row__item-one kdxsc-py-4' : 'kdxsc-row__item-one'

    return (
      <div className={columnClassName} key={`city-item-id-${variant.id}`} data-project-name={getProjectName()}>
        <input
          className="kdxsc-radio"
          name="city"
          type="radio"
          checked={String(id) === String(variant.id)}
          id={`city-id-${variant.id}`}
          value={variant.id}
          onChange={onSet}
        />
        <label htmlFor={`city-id-${variant.id}`} className={labelClassName}>
          {variant.name} {variant.external}
        </label>
      </div>
    )
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'city' || forceOpened ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('city'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.city.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.notSelected')}</div>
        </div>
        {!forceOpened && <span className="kdxsc-accordion-item__icon" />}
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          {!useCityAsString ? (
            <div className="kdxsc-tradein-item">
              <div className="kdxsc-tradein-item__input">
                <Input label={''} disabled={false} handleChange={e => setSearch(e.target.value)} value={search} />
              </div>
              <div className="kdxsc-tradein-item__items">
                {loading && <div className="kdxsc-input-hint">{t('tradeIn.city.loading')}</div>}
                {content()}
              </div>
            </div>
          ) : (
            <div className="kdxsc-tradein-item">
              <div className="kdxsc-tradein-item__input">
                <div className="kdxsc-input-with-bth">
                  <div className="kdxsc-input-with-bth__input">
                    <Input label={''} disabled={false} handleChange={e => setSearch(e.target.value)} value={search} />
                  </div>
                  <div className="kdxsc-input-with-bth__btn">
                    <Button
                      text={t('tradeIn.choose')}
                      handleClick={setCityFromString}
                      isDisabled={!search?.length}
                      type={'bordered'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  settings: { widget_id },
  tradeIn: {
    step,
    useCityAsString,
    values: {
      city: { id, name },
    },
    defaultCities,
    cityType,
  },
}) {
  return {
    widgetId: widget_id,
    step,
    id,
    name,
    useCityAsString,
    defaultCities,
    cityType,
  }
}

export default connect(mapStateToProps)(TradeInCity)
