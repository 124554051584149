import { CREDIT_SETTINGS_BEHAVIOR, FINANCE_USER_BEHAVIOUR, TRADE_IN_USER_BEHAVIOUR } from '../../constants'
import { carPriceFromTI, creditAmount, getCookie, modelDataParser, parseNumber } from '../../helpers'

export const createSmartContractData = (state, widgetType = '') => {
  const isCalledFromTradeInStandaloneWidget = widgetType === 'TradeInStandalone'
  const isPriceHidden = state?.settings?.visual?.isPriceHidden

  const widgetSource = () => {
    if (state.settings.customSource) {
      return state.settings.customSource
    }

    if (widgetType === 'TradeInStandalone') {
      return 'standalone_trade_in'
    }
    if (!widgetType || widgetType === '') {
      return 'common'
    }
    return widgetType
  }

  const {
    form: { values, agreements },
    settings: { currency, customTags },
    car: { identity, price, url, image, name, modelData, productType, additionalOptions },
    credit,
    tradeIn,
    leasing,
    benefits: { selected },
    accessories: { selected: selectedAccessories },
  } = state

  // + базовые данные
  let base = {
    currency,
    pixel: window['_KDX_UID'] || '',
    promoCode: values.promoCode,
    personalData: {
      name: values.name,
      lastName: values.lastName,
      secondName: values.secondName,
      email: values.email,
      phone: values.phone,
      phoneCountry: state.form.phoneSelection.country,
    },
    analytics: analytics(customTags),
    additionalData: state.settings?.customData || {},
    agreements: {
      personalData: {
        accepted: values.agreement,
      },
      communication: {
        accepted: agreements.communication.accepted,
        phone: agreements.communication.phone,
        sms: agreements.communication.sms,
        email: agreements.communication.email,
        post: agreements.communication.post,
      },
    },
  }

  if (widgetSource()) {
    base.widgetSource = widgetSource()
  }

  // если это обычный виджет
  if (!isCalledFromTradeInStandaloneWidget) {
    base.benefits = isPriceHidden ? [] : selected
    base.accessories = selectedAccessories
    base.car = {
      url,
      image,
      name,
      model_data: modelData,
      productType,
      identity,
      price,
    }

    if (additionalOptions) {
      base.car['additionalOptions'] = additionalOptions
    }
  }

  // если это TradeInStandalone виджет
  if (isCalledFromTradeInStandaloneWidget) {
    const { activeModel } = state.clientModels

    if (activeModel.id && !isPriceHidden) {
      base.car = {
        url: '',
        image: '',
        name: activeModel.attributes.name,
        model_data: modelDataParser(activeModel.attributes.modelData),
        productType: undefined,
        identity: '',
        price: activeModel.attributes.price,
      }
    }
  }

  const customBenefit = () => {
    if (isCalledFromTradeInStandaloneWidget) {
      return 0
    } else {
      return state.settings.isCustomBenefitsOn ? state.car.discounts.tradein : 0
    }
  }

  // передаем ТИ статус всегда
  base.tradeIn = {
    customBenefit: isPriceHidden ? 0 : customBenefit(),
    valuesSourcePartner: tradeIn.partner,
    resolution: {
      status: tradeIn.behaviourByUser,
    },
  }

  const doWeSendTradeInData = [
    TRADE_IN_USER_BEHAVIOUR.STARTED,
    TRADE_IN_USER_BEHAVIOUR.APPLIED,
    TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED,
  ].includes(tradeIn.behaviourByUser)

  if (doWeSendTradeInData) {
    base.tradeIn = {
      ...base.tradeIn,
      resolution: {
        ...base.tradeIn.resolution,
        current_car_price:
          tradeIn.behaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED ? tradeIn.rate.result.possibleCarPrice : 0,
        possible_car_price: tradeIn.rate.result.possibleCarPrice,
        current_benefit: tradeIn.benefitValue,
        possible_benefit: tradeIn.benefitValue,
        ownerCarPrice: tradeIn.ownerCarPriceEnabled ? tradeIn.ownerCarPrice : 0,
      },
      result: isCalledFromTradeInStandaloneWidget ? tradeIn.standaloneRate.result : tradeIn.rate.result,
      values: tradeIn.values,
    }

    base.tradeIn = {
      ...base.tradeIn,
      condition: {
        ...tradeIn.condition,
        customBenefitAmount: isPriceHidden ? 0 : customBenefit(),
      },
    }

    base.tradeIn.values.city.id = String(tradeIn.values.city.id)
    base.tradeIn.values.mileage.value = String(tradeIn.values.mileage.value)

    // перевод данных из camelCase в under_score
    if (base.tradeIn.result.tradeIn && base.tradeIn.result.tradeIn.from !== 0) {
      base.tradeIn.result.trade_in = {
        from: base.tradeIn.result.tradeIn.from,
      }
    }

    if (base.tradeIn.result.tradeIn && base.tradeIn.result.tradeIn.to !== 0) {
      base.tradeIn.result.trade_in = {
        ...base.tradeIn.result.trade_in,
        to: base.tradeIn.result.tradeIn.to,
      }
    }

    if (base.tradeIn.result.possibleCarPrice) {
      base.tradeIn.result.possible_car_price = base.tradeIn.result.possibleCarPrice
    }

    // НЕ УДАЛЯТЬ
    // delete base.trade_in.result.possibleCarPrice
    // delete base.trade_in.result.tradeIn
  }

  // кредит
  let isEverythingOkWithCredit = true
  let creditCustomBenefit

  if (credit.parameters.kasko_insurance) {
    creditCustomBenefit = state.car.discounts.credit + state.car.discounts.insurance
  } else {
    creditCustomBenefit = state.car.discounts.credit
  }

  if (!isCalledFromTradeInStandaloneWidget) {
    // проверяет наличие примененного кредита при автоматичском расчете
    const caseIfCreditEnabled =
      credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED &&
      credit.behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED

    // проверяет наличие примененного кредита при отключенном автоматичском расчете
    const caseIfCreditEnabledWithoutCalculations =
      credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS &&
      credit.behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS

    // проверяет наличие программы и партнера
    const isPartnerAndProgramDefined = credit.partner && credit.active_program && credit.active_program.id

    let financeStatus = credit.behaviourByUser

    // если юзер выбрал кредит, но программы или партнера нет, то статус = в процессе
    if (caseIfCreditEnabled && !isPartnerAndProgramDefined) {
      isEverythingOkWithCredit = false
    }

    // проверка на отрицательный ПВ или ПВ меньше стоимости авто в ТИ
    // если мы отправляем ТИ, но что-то не так с ПВ, то не посылаем кредит
    if (doWeSendTradeInData) {
      const realTradeInCarPrice = carPriceFromTI(
        tradeIn.rate.result.possibleCarPrice,
        tradeIn.ownerCarPrice,
        tradeIn.ownerCarPriceEnabled,
      )
      if (parseNumber(credit.parameters.initialFee) < realTradeInCarPrice) {
        isEverythingOkWithCredit = false
      }
    }

    // если ПВ < 0 или Ост Платеж < 0, то не посылаем кредит
    if (parseNumber(credit.parameters.initialFee) < 0 || parseNumber(credit.parameters.buybackPayment) < 0) {
      isEverythingOkWithCredit = false
    }

    // сумма кредита
    const amount = creditAmount({
      price,
      selectedBenefits: state.benefits.selected,
      selectedAccessories: state.accessories.selected,
      tradeInUserBehaviour: tradeIn.behaviourByUser,
      tradeInBenefitAmount: tradeIn.benefitValue,
      tradeInBenefitAmountWithoutTradeIn: tradeIn.benefitValueWithoutTradeIn,
      creditBenefitAmount: credit.benefitValue,
    })
    // если ПВ + Ост Платеж > суммы кредита, то не посылаем кредит
    if (parseNumber(credit.parameters.initialFee) + parseNumber(credit.parameters.buybackPayment) > amount) {
      isEverythingOkWithCredit = false
    }

    const creditCustomBenefitToBE = state.settings.isCustomBenefitsOn ? creditCustomBenefit : 0

    if (!isEverythingOkWithCredit || isPriceHidden) {
      financeStatus = FINANCE_USER_BEHAVIOUR.NONE
    }

    // базовый payload, даже если status = none
    base.finance = {
      status: financeStatus || FINANCE_USER_BEHAVIOUR.NONE,
      credit: {
        resolution: {
          current_benefit: credit.benefitValue,
          possible_benefit: credit.benefitValue,
        },
        partner: credit.partner,
        benefitValue: credit.benefitValue,
        initialFee: parseNumber(credit.parameters.initialFee) > 0 ? parseNumber(credit.parameters.initialFee) : 0,
        initialFeePercentage:
          parseInt(credit.parameters.initialFeePercentage) > 0 ? parseInt(credit.parameters.initialFeePercentage) : 0,
        buybackPayment:
          parseNumber(credit.parameters.buybackPayment) > 0 ? parseNumber(credit.parameters.buybackPayment) : 0,
        buybackPaymentPercentage:
          parseInt(credit.parameters.buybackPaymentPercentage) > 0
            ? parseInt(credit.parameters.buybackPaymentPercentage)
            : 0,
        period: parseInt(credit.parameters.period) || 0,
        lifeInsurance: credit.parameters.life_insurance,
        kaskoInsurance: credit.parameters.kasko_insurance,
        customBenefit: creditCustomBenefitToBE,
        condition: { ...credit.condition, customBenefitAmount: creditCustomBenefitToBE },
      },
      leasing: {
        condition: { ...leasing.condition, customBenefitAmount: 0 },
        benefitValue: leasing.benefitValue,
        initialFee: parseNumber(leasing.parameters.initialFee),
        initialFeePercentage: parseInt(leasing.parameters.initialFeePercentage) || 0,
        period: parseInt(leasing.parameters.period) || 0,
        customBenefit: 0,
        program: leasing.activeProgram,
      },
    }

    // если статус не равен none
    if ((caseIfCreditEnabled || caseIfCreditEnabledWithoutCalculations) && isEverythingOkWithCredit && !isPriceHidden) {
      base.finance.credit.program = credit.active_program
      base.finance.credit.resolution.current_payment = credit.active_program.payment
      base.finance.credit.resolution.possible_payment = credit.active_program.payment
    }
  }

  return base
}

export const createBuyModelSmartContractData = (state, widgetType = '') => {
  const {
    form: { values, agreements },
    buyModelStandalone: { currency, activeModel },
  } = state

  return {
    currency,
    pixel: window['_KDX_UID'] || '',
    widgetSource: widgetType || 'buy_model',
    additionalData: state.settings?.customData || {},
    personalData: {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      phoneCountry: state.form.phoneSelection.country,
    },
    car: {
      url: window.location.href.split('#').shift(),
      name: activeModel?.attributes?.name,
      model_data: modelDataParser(activeModel?.attributes?.modelData),
      productType: 'new',
      price: activeModel?.attributes?.price,
    },
    agreements: {
      personalData: {
        accepted: values.agreement,
      },
      communication: {
        accepted: agreements.communication.accepted,
        phone: agreements.communication.phone,
        sms: agreements.communication.sms,
        email: agreements.communication.email,
        post: agreements.communication.post,
      },
    },
    analytics: analytics(),
  }
}

export const createSimpleFromSmartContractData = (state, widgetType = '') => {
  const {
    form: { values, agreements },
    simpleForm: {
      config: { currency },
    },
  } = state

  return {
    currency,
    pixel: window['_KDX_UID'] || '',
    widgetSource: widgetType || 'popup_form',
    personalData: {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      phoneCountry: state.form.phoneSelection.country,
    },
    agreements: {
      personalData: {
        accepted: values.agreement,
      },
      communication: {
        accepted: agreements.communication.accepted,
        phone: agreements.communication.phone,
        sms: agreements.communication.sms,
        email: agreements.communication.email,
        post: agreements.communication.post,
      },
    },
    analytics: analytics(),
  }
}

const analytics = (customTags = null) => {
  const utm1 = 'utm_campaign'
  const utm2 = 'utm_content'
  const utm3 = 'utm_medium'
  const utm4 = 'utm_source'
  const utm5 = 'utm_term'

  const searchToObject = () => {
    let pairs = window.location.search.substring(1).split('&'),
      obj = {},
      pair,
      i

    for (i in pairs) {
      if (!pairs.hasOwnProperty(i)) continue
      if (pairs[i] === '') continue

      pair = pairs[i].split('=')
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }

    return obj
  }

  const search = searchToObject()

  const result = {}

  if (search[utm1] || search[utm2] || search[utm3] || search[utm4] || search[utm5]) {
    const utms = {}

    for (const label of [utm1, utm2, utm3, utm4, utm5]) {
      if (search[label] && String(search[label]).trim() !== '') {
        utms[label] = String(search[label]).trim()
      }
    }

    if (Object.keys(utms).length > 0) {
      result['autoTags'] = utms
    }
  }

  if (customTags && Object.keys(customTags)?.length > 0) {
    result['customTags'] = customTags
  }

  // получение данных из куки
  const cookieTags = {}

  for (const cookieName of [utm1, utm2, utm3, utm4, utm5]) {
    const cookieValue = getCookie(cookieName)
    if (cookieValue) {
      cookieTags[cookieName] = cookieValue
    }
  }

  if (Object.keys(cookieTags)?.length) {
    result['cookieTags'] = cookieTags
  }

  return result
}
