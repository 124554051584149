import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { parseNumber } from '../../parse_number'
import { presetParams } from '../../preset_params'

export const detectPriceHelper = (preset, detail, config) => {
  // preset
  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.price()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  // detail
  if (detail?.price) {
    if (typeof detail.price === 'function') {
      return parseNumber(detail.price())
    } else {
      return parseNumber(detail.price)
    }
  }

  // old function - config
  if (config.price) {
    if (typeof config.price === 'function') {
      return parseNumber(config.price())
    } else {
      return parseNumber(config.price)
    }
  }

  return 0
}
