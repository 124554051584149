import { callHavalEventListeners, debouncedEmitCreatedEvent } from '../../helpers'

export const setSmartContractUrl = url => (dispatch, getState) => {
  dispatch({
    type: 'SET_URL',
    url,
  })
}

export const setSmartContactPaymentUrl = payment_url => (dispatch, getState) => {
  dispatch({
    type: 'SET_PAYMENT_URL',
    payment_url,
  })
}

export const setSmartContractId = id => (dispatch, getState) => {
  dispatch({
    type: 'SET_ID',
    id,
  })
}

export const setSmartContractGenericId = payload => (dispatch, getState) => {
  dispatch({
    type: 'SET_GENERIC_ID',
    payload,
  })
}

export const smartContractCreated = ({ widgetId, source, personalData, smartContract, car, savedFrom }) => (
  dispatch,
  getState,
) => {
  let onlineStockForm = callHavalEventListeners(savedFrom)

  debouncedEmitCreatedEvent({
    data: {
      widgetId,
      source,
      smartContract,
      personalData,
      car,
      onlineStockForm,
    },
  })
}

export const setSmartContractType = payload => (dispatch, getState) => {
  dispatch({
    type: 'SET_TYPE',
    payload,
  })
}

export const setSmartContractToken = payload => (dispatch, getState) => {
  dispatch({
    type: 'SET_TOKEN',
    payload,
  })
}

export const resetSavedSmartContract = _ => (dispatch, getState) => {
  dispatch({
    type: 'RESET_SAVED_SMART_CONTRACT',
  })
}
