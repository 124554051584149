import { BULLET_TYPES } from '../types'

export const setBulletLinks = links => dispatch => {
  dispatch({
    type: BULLET_TYPES.SET_LINKS,
    links,
  })
}

export const setBulletTexts = texts => dispatch => {
  dispatch({
    type: BULLET_TYPES.SET_TEXTS,
    texts,
  })
}

export const setBulletSizes = sizes => dispatch => {
  dispatch({
    type: BULLET_TYPES.SET_SIZES,
    sizes,
  })
}

export const setBulletFilledType = filledType => dispatch => {
  dispatch({
    type: BULLET_TYPES.SET_FILLED_TYPE,
    filledType,
  })
}

export const setBulletUseBackground = useBackground => dispatch => {
  dispatch({
    type: BULLET_TYPES.SET_USE_BACKGROUND,
    useBackground,
  })
}
