import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './onlinePaymentHint.module.scss'

function OnlinePaymentHint({ icon, children }) {
  return (
    <div className={s.wrapper} data-project-name={getProjectName()}>
      <div className={s.wrapper__icon}>{icon}</div>
      <div className={s.wrapper__text}>{children}</div>
    </div>
  )
}

export default OnlinePaymentHint
