import { useEffect, useRef, useState } from 'react'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './styles/dropdown.module.scss'

function TradeInStandaloneDropdown({ type, step, openOnMount, selectionContent, dropdownContent, handleSelectOpened }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (type === 'input' && openOnMount && getProjectName() !== 'generic_v2') {
      setIsOpen(true)
    }
  }, [step, type, openOnMount])

  const select = useRef(null)
  const dropdown = useRef(null)

  const changeFooterZIndexFunction = zIndexValue => {
    let footer = document.getElementsByClassName('main-footer')[0]
    if (footer) {
      footer.style.zIndex = zIndexValue
    }
  }

  const handleSelectClick = () => {
    changeFooterZIndexFunction('auto')
    setIsOpen(true)
  }

  // закрывать дропдаун на клики за его пределами
  const handleOutsideClick = event => {
    if (dropdown && dropdown.current && dropdown.current.contains(event.target)) return
    if (select.current.contains(event.target)) return
    changeFooterZIndexFunction('2')
    setIsOpen(false)
  }

  const handleOutsideKey = event => {
    if (event.key === 'Escape') {
      changeFooterZIndexFunction('2')
      setIsOpen(false)
      select.current.focus()
    }
  }

  const closeAfterTradeInStepWasSet = event => {
    changeFooterZIndexFunction('2')
    setIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('tradeInStepWasSet', closeAfterTradeInStepWasSet)
    window.addEventListener('tradeInMileageWasSet', closeAfterTradeInStepWasSet)

    if (handleSelectOpened) {
      handleSelectOpened(isOpen)
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
      window.removeEventListener('tradeInStepWasSet', closeAfterTradeInStepWasSet)
      window.removeEventListener('tradeInMileageWasSet', closeAfterTradeInStepWasSet)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const selectClasses = () => {
    let c = [s.dropdown__trigger]

    if (isOpen) {
      c.push(s.dropdown__trigger_opened)
    }

    return c.join(' ')
  }

  const iconClasses = () => {
    let c = [s.dropdownTrigger__icon]

    if (isOpen) {
      c.push(s.dropdownTrigger__icon_opened)
    }

    return c.join(' ')
  }

  const dropdownClasses = () => {
    let c = [s.dropdown__box]

    if (isOpen) {
      c.push(s.dropdown__box_opened)
    }

    if (type === 'select') {
      c.push(s.dropdown__box_select)
    } else {
      c.push(c.push(s.dropdown__box_input))
    }

    return c.join(' ')
  }

  const handleIconCLick = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
    if (isOpen) {
      changeFooterZIndexFunction('2')
    } else {
      changeFooterZIndexFunction('auto')
    }
  }

  return (
    <div className={`${s.dropdown} ${isOpen ? s.dropdown_isOpened : ''}`} data-type-field={type}>
      <div className={selectClasses()}>
        <div className={s.dropdownTrigger} onClick={handleSelectClick} ref={select}>
          <div className={s.dropdownTrigger__content}>{selectionContent}</div>
          {type === 'select' && <div className={iconClasses()} onClick={e => handleIconCLick(e)} />}
        </div>
      </div>
      {dropdownContent && (
        <div className={dropdownClasses()} ref={dropdown}>
          <div className={s.dropdownBox}>{dropdownContent}</div>
        </div>
      )}
    </div>
  )
}

export default TradeInStandaloneDropdown
