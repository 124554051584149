import _cloneDeep from 'lodash/cloneDeep'

const INIT_STATE = {
  config: {},
  smartContact: {},
}

const simpleForm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_SIMPLE_FORM_CONFIG':
      return {
        ...state,
        config: _cloneDeep(action.payload),
      }

    case 'SET_SIMPLE_FORM_SMART_CONTRACT':
      return {
        ...state,
        smartContact: _cloneDeep(action.payload),
      }

    default:
      return state
  }
}

export default simpleForm
