import { connect } from 'react-redux'

function TradeInStandaloneModificationInput({ dictionaryValue }) {
  return <div>{dictionaryValue}</div>
}

function mapStateToProps({
  tradeIn: {
    values: {
      modification: { name: dictionaryValue },
    },
  },
}) {
  return {
    dictionaryValue,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneModificationInput)
