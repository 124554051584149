module.exports = {
  title: 'Angebot bekommen',
  send: 'Senden',
  dealership: 'Händler',
  dealershipError: 'Um fortzufahren, bitte Händler auswählen',

  requiredFiled: 'Dieses Feld ist ein Pflichtfeld',
  incorrectFiled: 'Dieses Feld ist nicht korrekt ausgefüllt',
  promoCodeError: 'You can use numbers and Latin letters, maximum 15 characters', // !!!

  car: 'Car', // !!!
  model: 'Model', // !!!

  personalData: 'Persönliche Daten',
  contacts: 'Kontaktinformation',

  search: 'Search', // !!!
  nothingFound: 'Nothing found', // !!!
}
