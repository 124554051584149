import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { parseNumber } from '../../parse_number'
import { presetParams } from '../../preset_params'

export const detectAdditionalOptionsHelper = (preset, detail) => {
  const detect = () => {
    if (preset) {
      const [platform] = presetParams(preset)

      switch (platform) {
        case PLATFORM_ONE_PLATFORM:
          return onePlatform().detail.additionalOptions()
        default:
          throw new Error('Unexpected preset [' + preset + ']')
      }
    }

    if (detail?.additionalOptions) {
      if (typeof detail.additionalOptions === 'function') {
        return detail.additionalOptions()
      } else if (typeof detail.additionalOptions === 'object') {
        return detail.additionalOptions
      }
    }

    return false
  }

  const additional = detect()

  if (!additional || typeof additional !== 'object') {
    return false
  }

  if (additional.cost === undefined && additional.list === undefined) {
    return false
  }

  const result = {}

  additional.cost = parseNumber(additional.cost)
  if (additional.cost >= 0) {
    result['cost'] = additional.cost
  }

  if (Array.isArray(additional.list) && additional.list.length) {
    result['list'] = additional.list
      .filter(v => !!v)
      .map(v => String(v).trim())
      .filter(v => v !== '')
  }

  return result
}
