import {
  TRADE_IN_DEFAULT_CITY_PREFIX,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_STANDALONE_SCREEN,
  TRADE_IN_STEP_WEIGHTS,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import { normalizeTradeInRateResult, parseNumber } from '../../helpers'
import { TRADE_IN_TYPES } from '../types'

export const TRADE_IN_INIT_STATE = {
  // поведение ТИ - можно ли показывать или нет (из настроек виджета)
  behaviour: TRADE_IN_SETTINGS_BEHAVIOR.DISABLED,

  // бонус при сдаче авто в ТИ (из настроек виджета)
  benefitValue: 0,

  // бонус при сдаче авто без ТИ (из настроек виджета)
  benefitValueWithoutTradeIn: 0,

  // статус, описывающий поведение пользователя (пропустил, начал/закончил расчеты, отказался от ТИ)
  behaviourByUser: TRADE_IN_USER_BEHAVIOUR.NONE,

  // партнер - auto_ru, mobile_de или max_poster
  partner: '',

  hidePartnerInTexts: false,
  useCityAsString: false,
  defaultCities: [],
  // если с БЭ, то мы в запросе городов при ответе проверяем 1 ли город вернулся
  // и если вернулся только 1 то ставим сразу его
  cityType: '',
  // тип оценки - из каталога (старый тип) или по вин автору
  estimationType: '',
  // можно ли считать ТИ по идентификатору авто ру
  isTradeInByIdentityEnabled: false,

  // итоги расчета
  rate: {
    status: TRADE_IN_RATE_STATUS.INITIAL,

    result: {
      estimated: {
        from: 0,
        to: 0,
      },
      tradeIn: {
        from: 0,
        to: 0,
      },

      possibleCarPrice: 0,
    },
  },

  // для расчетов в виджете
  step: 'brand',
  weight: TRADE_IN_STEP_WEIGHTS['brand'],
  previousTradeInValue: 0,

  dictionaries: {
    brands: [],
    models: [],
    generations: [],
    bodies: [],
    modifications: [],
    years: [],
    colors: [],
    engines: [],
    gearboxes: [],
    interiorColors: [],
    interiorTypes: [],
  },

  // данные по выбраному автомобилю - на БЭ
  values: {
    brand: {
      name: '',
      id: '',
    },
    model: {
      name: '',
      id: '',
    },
    generation: {
      name: '',
      id: '',
    },
    body: {
      name: '',
      id: '',
    },
    modification: {
      name: '',
      id: '',
    },
    year: {
      value: '',
    },
    color: {
      name: '',
      id: '',
    },
    owner: {
      name: '',
      id: '',
    },
    engine: {
      name: '',
      id: '',
    },
    gearbox: {
      name: '',
      id: '',
    },
    interiorColor: {
      name: '',
      id: '',
    },
    interiorType: {
      name: '',
      id: '',
    },
    city: {
      name: '',
      id: '',
    },
    mileage: {
      value: '',
    },
    identity: {
      value: '',
    },
  },

  // то, что пользователь вводит в инпуте для Standalone режима
  typedValues: {
    brand: '',
    model: '',
    city: '',
  },

  standaloneScreen: TRADE_IN_STANDALONE_SCREEN.RATE,

  standaloneCar: '',
  standalonePrice: 0,

  standaloneRate: {
    status: TRADE_IN_RATE_STATUS.INITIAL,

    result: {
      estimated: {
        from: 0,
        to: 0,
      },
      tradeIn: {
        from: 0,
        to: 0,
      },

      possibleCarPrice: 0,
    },
  },

  // своя цена за автомобиль
  ownerCarPrice: 0,
  ownerCarPriceEnabled: true,
  ownerCarPriceOwnerHintText: '',

  isCarIdentityValid: true,
}

const tradeIn = (state = TRADE_IN_INIT_STATE, action) => {
  switch (action.type) {
    case TRADE_IN_TYPES.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case TRADE_IN_TYPES.SET_BEHAVIOR_BY_SETTINGS:
      return {
        ...state,
        behaviour: action.payload,
      }

    case TRADE_IN_TYPES.SET_TRADEIN_BENEFIT_VALUE:
      return {
        ...state,
        benefitValue: action.payload,
      }

    case TRADE_IN_TYPES.SET_TRADEIN_BENEFIT_VALUE_WITHOUT_TRADE_IN:
      return {
        ...state,
        benefitValueWithoutTradeIn: action.payload,
      }

    case TRADE_IN_TYPES.SET_BEHAVIOR_BY_USER:
      return {
        ...state,
        behaviourByUser: action.payload,
      }

    case TRADE_IN_TYPES.SET_RATE: {
      const result = normalizeTradeInRateResult(action.payload)

      return {
        ...state,
        previousTradeInValue: parseNumber(state.rate.result.possibleCarPrice),
        rate: {
          ...state.rate,
          result,
        },
      }
    }

    case TRADE_IN_TYPES.SET_RATE_STATUS:
      return {
        ...state,
        rate: {
          ...state.rate,
          status: action.payload,
        },
      }

    case TRADE_IN_TYPES.SET_HASH: {
      return {
        ...state,
        rate: {
          ...state.rate,
          hash: action.payload,
        },
      }
    }

    case TRADE_IN_TYPES.SET_STANDALONE_RATE: {
      const result = normalizeTradeInRateResult(action.payload)

      return {
        ...state,
        previousTradeInValue: parseNumber(state.standaloneRate.result.possibleCarPrice) || 0,
        standaloneRate: {
          ...state.standaloneRate,
          result,
        },
      }
    }

    case TRADE_IN_TYPES.SET_STANDALONE_RATE_STATUS:
      return {
        ...state,
        standaloneRate: {
          ...state.standaloneRate,
          status: action.payload,
        },
      }

    case TRADE_IN_TYPES.SET_STANDALONE_RATE_HASH: {
      return {
        ...state,
        standaloneRate: {
          ...state.standaloneRate,
          hash: action.payload,
        },
      }
    }

    // ----------

    case TRADE_IN_TYPES.SET_STEP_WEIGHT:
      return {
        ...state,
        weight: TRADE_IN_STEP_WEIGHTS[action.payload] || 0,
      }

    case TRADE_IN_TYPES.SET_STEP:
      return {
        ...state,
        step: action.payload,
      }

    case TRADE_IN_TYPES.SET_DICTIONARY:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          [action.dictionary]: action.payload,
        },
      }

    case TRADE_IN_TYPES.SET_TRADE_IN_DEFAULT_CITIES:
      // eslint-disable-next-line no-case-declarations
      const isPayloadArray = action.payload && Array.isArray(action.payload) && action.payload.length
      // eslint-disable-next-line no-case-declarations
      const defaultCities = isPayloadArray
        ? action.payload.map((p, index) => {
            return {
              id: TRADE_IN_DEFAULT_CITY_PREFIX + index,
              name: p,
              external: '',
            }
          })
        : null

      return {
        ...state,
        defaultCities,
      }

    case TRADE_IN_TYPES.SET_TRADE_IN_CITY_TYPE:
      return {
        ...state,
        cityType: action.payload,
      }

    case TRADE_IN_TYPES.SET_TRADE_IN_ESTIMATION_TYPE:
      return {
        ...state,
        estimationType: action.payload,
      }

    case TRADE_IN_TYPES.SET_IS_TRADE_IN_BY_IDENTITY_ENABLED:
      return {
        ...state,
        isTradeInByIdentityEnabled: action.payload,
      }

    // ----------

    case TRADE_IN_TYPES.SET_BRAND:
      return {
        ...state,
        values: {
          ...state.values,
          brand: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_MODEL:
      return {
        ...state,
        values: {
          ...state.values,
          model: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_GENERATION:
      return {
        ...state,
        values: {
          ...state.values,
          generation: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_BODY:
      return {
        ...state,
        values: {
          ...state.values,
          body: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_MODIFICATION:
      return {
        ...state,
        values: {
          ...state.values,
          modification: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_YEAR:
      return {
        ...state,
        values: {
          ...state.values,
          year: {
            value: action.payload,
          },
        },
      }

    case TRADE_IN_TYPES.SET_COLOR:
      return {
        ...state,
        values: {
          ...state.values,
          color: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_OWNER:
      return {
        ...state,
        values: {
          ...state.values,
          owner: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_ENGINE:
      return {
        ...state,
        values: {
          ...state.values,
          engine: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_GEARBOX:
      return {
        ...state,
        values: {
          ...state.values,
          gearbox: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_INTERIOR_COLOR:
      return {
        ...state,
        values: {
          ...state.values,
          interiorColor: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_INTERIOR_TYPE:
      return {
        ...state,
        values: {
          ...state.values,
          interiorType: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_CITY:
      return {
        ...state,
        values: {
          ...state.values,
          city: {
            id: action.id,
            name: action.name,
          },
        },
      }

    case TRADE_IN_TYPES.SET_MILEAGE:
      return {
        ...state,
        values: {
          ...state.values,
          mileage: {
            value: action.payload,
          },
        },
      }

    case TRADE_IN_TYPES.SET_CAR_IDENTITY_BY_VIN:
      return {
        ...state,
        values: {
          ...state.values,
          identity: {
            value: action.payload,
          },
        },
      }

    // STANDALONE
    case TRADE_IN_TYPES.SET_STANDALONE_TYPED_VALUE:
      return {
        ...state,
        typedValues: {
          ...state.typedValues,
          [action.name]: action.value,
        },
      }

    case TRADE_IN_TYPES.SET_STANDALONE_SCREEN:
      return {
        ...state,
        standaloneScreen: action.payload,
      }

    case TRADE_IN_TYPES.SET_STANDALONE_CAR:
      return {
        ...state,
        standaloneCar: action.payload,
      }

    case TRADE_IN_TYPES.SET_STANDALONE_PRICE:
      return {
        ...state,
        standalonePrice: action.payload,
      }

    case TRADE_IN_TYPES.SET_OWNER_CAR_PRICE:
      return {
        ...state,
        ownerCarPrice: action.payload,
      }

    case TRADE_IN_TYPES.SET_OWNER_CAR_PRICE_ENABLED:
      return {
        ...state,
        ownerCarPriceEnabled: action.payload,
      }

    case TRADE_IN_TYPES.SET_OWNER_CAR_PRICE_HINT_TEXT:
      return {
        ...state,
        ownerCarPriceOwnerHintText: action.payload,
      }

    case TRADE_IN_TYPES.SET_CONDITION:
      return {
        ...state,
        condition: action.payload,
      }

    case TRADE_IN_TYPES.SET_PARTNER:
      return {
        ...state,
        partner: action.payload,
      }

    case TRADE_IN_TYPES.SET_HIDE_PARTNER_IN_TEXTS:
      return {
        ...state,
        hidePartnerInTexts: action.payload,
      }

    case TRADE_IN_TYPES.SET_USE_CITY_AS_STRING:
      return {
        ...state,
        useCityAsString: action.payload,
      }

    case TRADE_IN_TYPES.SET_IS_CAR_IDENTITY_VALID:
      return {
        ...state,
        isCarIdentityValid: action.payload,
      }

    default:
      return state
  }
}

export default tradeIn
