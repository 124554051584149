import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Timer({ countdown, onClearCountdown }) {
  const [minutes, setMinutes] = useState(Math.ceil(countdown / 60000))
  const [seconds, setSeconds] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
          onClearCountdown()
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <div>
          {t('form.sendIn')} {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </div>
      )}
    </div>
  )
}

export default Timer
