import s from './finalStatus.module.scss'

function FinalState({ children, text, icon, height }) {
  const iconContent = () => {
    if (icon === 'success') {
      return (
        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.8497 1.95482L5.40075 9.40216C5.20111 9.601 4.87789 9.601 4.67904 9.40216L0.149132 4.84327C-0.0497107 4.64362 -0.0497107 4.31919 0.149132 4.12075L1.2315 3.03919C1.43114 2.83995 1.75436 2.83995 1.95321 3.03919L5.04372 6.15024L11.0444 0.149132C11.2433 -0.0497107 11.5665 -0.0497107 11.7669 0.149132L12.8493 1.2323C13.0502 1.43155 13.0502 1.75597 12.8497 1.95482Z"
            fill="#222"
          />
        </svg>
      )
    }

    if (icon === 'error') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
            fill="#222"
          />
        </svg>
      )
    }

    return null
  }

  return (
    <div className={s.block} style={{ minHeight: height || 'auto' }}>
      {icon && iconContent() && <div className={s.block__icon}>{iconContent()}</div>}
      {text && <div className={s.block__text}>{text}</div>}
      <div className={s.block__buttons}>{children}</div>
    </div>
  )
}

export default FinalState
