import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatPrice } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './styles/accessory.module.scss'

function Accessory({ props, firstScreen, onToggleAccessory }) {
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [showMobile, setShowMobile] = useState(window.innerWidth < 600)
  const descriptionRef = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const resizeHandler = () => {
    const w = window.innerWidth

    if (w < 600) {
      setShowMobile(true)
    } else {
      setShowMobile(false)
    }
  }

  /**
   * Секция описания
   * В зависимости от длины строки показываем по-разному
   * @param description
   * @returns {*}
   */
  const descriptionSection = description => {
    if (!description) {
      return description
    }

    if (firstScreen) {
      const d = description.length > 250 ? `${description.substring(0, 247)} ...` : description

      return (
        <div className={s.description}>
          <div>{d}</div>
        </div>
      )
    }

    if (showFullDescription) {
      return (
        <div>
          <div className={s.description}>{description}</div>
          <div onClick={() => setShowFullDescription(false)} className={s.more}>
            {t('accessories.shortDescription')}
          </div>
        </div>
      )
    }

    if (description.length < 250) {
      return <div className={s.description}>{description}</div>
    }

    return (
      <div>
        <div className={s.description}>{description.substring(0, 247)}...</div>
        <div onClick={() => setShowFullDescription(true)} className={s.more}>
          {t('accessories.fullDescription')}
        </div>
      </div>
    )
  }

  /**
   * Подскролл при разворачивании
   */
  useEffect(() => {
    if (showFullDescription) {
      descriptionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [showFullDescription])

  const pcSection = () => {
    return (
      <>
        <div className={s.wrapper__item_pc} onClick={() => (!firstScreen ? onToggleAccessory(props) : false)}>
          <img src={props.attributes?.image?.url || '../assets/no-img.png'} alt="" width="100%" className={s.image} />
          {!firstScreen && (
            <div
              className={[
                s.checkbox,
                props.isChecked ? s.checked : '',
                props.hasOnlySelectedBtnBeenClicked ? s.hasOnlySelectedBtnBeenClicked : '',
              ].join(' ')}
            />
          )}
        </div>
        <div className={s.wrapper__item_pc}>
          <div className={s.info}>
            <div className={s.info__title}>{props.attributes.name}</div>
            <div ref={descriptionRef} className={s.info__description}>
              {descriptionSection(props.attributes.description)}
            </div>
            {props.attributes.price && (
              <div className={s.info__price}>
                <div className={`${s.price} accessory-price`}>
                  {formatPrice(props.attributes.price, props.currency)}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  const mobSection = () => {
    return (
      <>
        <div
          className={`${s.wrapper__item_mob} ${!firstScreen ? s.wrapper__item_mob_allAccessories__screen : ''}`}
          onClick={() => (!firstScreen ? onToggleAccessory(props) : false)}
        >
          <div className={s.topMobile}>
            <div className={s.topMobile__img}>
              <img src={props.attributes?.image?.url || '../assets/no-img.png'} alt="" width="100%" />
              {!firstScreen && (
                <div
                  className={[
                    s.checkbox,
                    props.isChecked ? s.checked : '',
                    props.hasOnlySelectedBtnBeenClicked ? s.hasOnlySelectedBtnBeenClicked : '',
                  ].join(' ')}
                />
              )}
            </div>
            {getProjectName() !== 'generic_v2' && <div className={s.topMobile__title}>{props.attributes.name}</div>}
            {getProjectName() === 'generic_v2' && !firstScreen && (
              <div className={s.topMobile__title}>{props.attributes.name}</div>
            )}
          </div>
        </div>
        <div>
          {getProjectName() === 'generic_v2' && firstScreen && (
            <div className={s.topMobile__title}>{props.attributes.name}</div>
          )}
          <div className={`${s.wrapper__item_mob} ${!firstScreen ? s.wrapper__item_mob_allAccessories__screen : ''}`}>
            <div ref={descriptionRef} className={s.description}>
              {descriptionSection(props.attributes.description)}
            </div>
          </div>
          {props.attributes.price && (
            <div className={s.wrapper__item_mob}>
              <div className={`${s.price} accessory-price`}>{formatPrice(props.attributes.price, props.currency)}</div>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {props.interferingAccessory && <div className={s.interferingWarning}>{props.interferingAccessory}</div>}
      <div
        className={[
          s.wrapper,
          props.isChecked && !firstScreen ? s.checked : '',
          !firstScreen ? s.bordered : '',
          props.interferingAccessory ? s.disabled : '',
          firstScreen ? s.wrapper__firstScreen : '',
        ].join(' ')}
      >
        {showMobile ? mobSection() : pcSection()}
      </div>
    </>
  )
}

export default Accessory
