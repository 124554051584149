import { connect, useDispatch } from 'react-redux'

import { setTradeInModification } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneModificationDropdown({ modifications, id }) {
  const dispatch = useDispatch()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInModification({ id, name }))
  }

  return (
    <TradeInStandalonePartsRadiobuttons
      name="modification"
      variants={modifications}
      checked={id}
      onSet={onSet}
      search={''}
    />
  )
}

function mapStateToProps({
  tradeIn: {
    values: {
      modification: { id },
    },
    dictionaries: { modifications },
  },
}) {
  return {
    id,
    modifications,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneModificationDropdown)
