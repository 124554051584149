import {
  FORM_SOURCE,
  TI_PARTNER,
  TRADE_IN_DATA_FILLED,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_STANDALONE_SCREEN,
} from '../../../constants'
import {
  rateTradeIn,
  resetBrand,
  resetCity,
  resetEngine,
  resetMileage,
  setClientModels,
  setCurrency,
  setDisableSmartContractLink,
  setIsTradeInIdentityEnabled,
  setStepWeight,
  setTradeInBehaviorBySettings,
  setTradeInBenefitValue,
  setTradeInCarIdentity,
  setTradeInCondition,
  setTradeInDictionary,
  setTradeInEstimationType,
  setTradeInOwnerCarPrice,
  setTradeInOwnerCarPriceEnabled,
  setTradeInOwnerCarPriceHintText,
  setTradeInPartner,
  setTradeInStandaloneScreen,
  setTradeInStep,
  setWidgetId,
  stashFormErrors,
} from '../../../store/actions'
import {
  setTradeInPriceTooltipEnabled,
  setTradeInStandaloneDefaultCities,
  setTradeInStandaloneLocale,
  setTradeInVisual,
} from '../../../store/actions/trade_in_standalone'
import store from '../../../store/store'
import { isTradeInDataFilled } from '../../is_trade_in_data_filled'
import { normalizeVisual } from '../../normalize_visual'
import { bootPhoneCountriesData, clearProtections } from './bootMainInfo'
import { formSetter } from './form'
import { languageSetter } from './language'
import { paletteSetter } from './palette'

export const tradeInStandaloneLoader = ({
  visual,
  locale,
  currency,
  widget_id,
  clientModels,
  disableSmartContractLink,
  ownerCarPriceDisable,
  condition,
  ownerCarPriceHintText,
  benefitAmount,
  dataFromLocalStorage,
  partner,
  defaultCountryCode,
  possibleCountryCodes,
  priceToolTipEnabled,
  defaultCities,
  calculateByIdentity,
}) => {
  let success = true

  try {
    paletteSetter(visual.palette)

    const normalizedVisual = normalizeVisual(visual)

    languageSetter(locale)

    const areCalculationsByIdFromLsAndFromBeDifferent =
      calculateByIdentity !== dataFromLocalStorage?.tradeIn?.isTradeInByIdentityEnabled

    const isTradeInByIdentityEnabled = calculateByIdentity

    // идентификатор автомобиля - не зависит от партнера и тд, поэтоум ставим его всегда
    const identity = dataFromLocalStorage?.tradeIn?.values?.identity?.value
    const stepFromLs = dataFromLocalStorage?.tradeIn?.step
    const et = dataFromLocalStorage?.tradeIn?.estimationType

    const etToSet =
      et === TRADE_IN_ESTIMATION_TYPE.LIST || !isTradeInByIdentityEnabled
        ? TRADE_IN_ESTIMATION_TYPE.LIST
        : TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ
    store.dispatch(setTradeInEstimationType(etToSet))

    if (!isTradeInByIdentityEnabled) {
      store.dispatch(setTradeInCarIdentity({ payload: '' }))
    }

    // + проверка заполенени полей формы:
    // если поле было заполнено неверно и сохранено в ЛС,
    // но потом его убрали из видимых,
    // то СК никогда не сохранится
    formSetter(visual.fields, locale, dataFromLocalStorage)
    clearProtections(FORM_SOURCE.TRADE_IN_STANDALONE)
    // - проверка заполенени полей формы

    store.dispatch(setTradeInStandaloneLocale(locale))
    store.dispatch(setTradeInPartner(partner))
    store.dispatch(setCurrency(currency))
    store.dispatch(setWidgetId(widget_id))
    store.dispatch(setTradeInVisual(normalizedVisual))
    store.dispatch(stashFormErrors())
    if (clientModels && clientModels[0]) {
      store.dispatch(setClientModels(clientModels))
    }
    store.dispatch(setDisableSmartContractLink(disableSmartContractLink))

    // tradeIn begins

    if (ownerCarPriceDisable) {
      store.dispatch(setTradeInOwnerCarPrice(0))
    }
    store.dispatch(setTradeInOwnerCarPriceEnabled(!ownerCarPriceDisable))
    store.dispatch(setTradeInOwnerCarPriceHintText(ownerCarPriceHintText))

    store.dispatch(setTradeInCondition(condition))

    store.dispatch(setTradeInStandaloneDefaultCities(defaultCities))

    store.dispatch(setTradeInBehaviorBySettings(TRADE_IN_SETTINGS_BEHAVIOR.ENABLED))
    store.dispatch(setTradeInBenefitValue(benefitAmount || 0))

    store.dispatch(setTradeInPriceTooltipEnabled(priceToolTipEnabled))

    bootPhoneCountriesData(dataFromLocalStorage, defaultCountryCode, possibleCountryCodes)

    // если есть данные с мобайл де, то чистим их
    if (
      dataFromLocalStorage?.tradeIn?.values?.engine?.id ||
      dataFromLocalStorage?.tradeIn?.values?.gearbox?.id ||
      dataFromLocalStorage?.tradeIn?.values?.interiorColor?.id ||
      dataFromLocalStorage?.tradeIn?.values?.interiorType?.id
    ) {
      store.dispatch(resetEngine(partner))
    }

    // делаем ТИ изначальным, если партнер в ЛС != партнеру с БЭ или тип расчета разный
    const arePartnersDiff = dataFromLocalStorage?.tradeIn?.partner !== partner
    if (arePartnersDiff || areCalculationsByIdFromLsAndFromBeDifferent) {
      const stepToSet = areCalculationsByIdFromLsAndFromBeDifferent
        ? 'brand'
        : isTradeInByIdentityEnabled
        ? 'identity'
        : 'brand'
      store.dispatch(setTradeInStep(stepToSet))
      store.dispatch(setStepWeight(stepToSet))

      const dictionariesToBeCleared = [
        'brands',
        'models',
        'generations',
        'bodies',
        'modifications',
        'years',
        'colors',
        'engines',
        'gearboxes',
        'interiorColors',
        'interiorTypes',
      ]

      dictionariesToBeCleared.forEach(dictionary => {
        if (
          dataFromLocalStorage?.tradeIn?.dictionaries[dictionary] &&
          dataFromLocalStorage?.tradeIn?.dictionaries[dictionary]?.length
        ) {
          store.dispatch(setTradeInDictionary(dictionary, []))
        }
      })

      // после сбрасывания бренда должны сброситься все остальные значения
      // если расчет не по ИД, там вообще нет бренда
      if (etToSet === TRADE_IN_ESTIMATION_TYPE.LIST) {
        store.dispatch(resetBrand(partner))
      }

      store.dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.RATE))
    } else if (dataFromLocalStorage && dataFromLocalStorage.tradeIn && dataFromLocalStorage.tradeIn.values) {
      const tradeInDataFilled = isTradeInDataFilled(dataFromLocalStorage.tradeIn.values, partner)
      if (tradeInDataFilled === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED) {
        store.dispatch(rateTradeIn(false, true))
      }
    }

    if (etToSet === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ && !['identity', 'city', 'mileage'].includes(stepFromLs)) {
      store.dispatch(setTradeInStep('identity'))
      store.dispatch(setStepWeight('identity'))
    }

    store.dispatch(setIsTradeInIdentityEnabled(isTradeInByIdentityEnabled))

    if (etToSet === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ) {
      if (identity) {
        store.dispatch(setTradeInCarIdentity({ payload: identity }))
      }
      if (!dataFromLocalStorage?.tradeIn?.values?.city?.id) {
        store.dispatch(resetMileage())
        store.dispatch(setTradeInStep('city'))
        store.dispatch(setStepWeight('city'))
      }
      if (!identity) {
        store.dispatch(resetCity(partner))
        store.dispatch(setTradeInStep('identity'))
        store.dispatch(setStepWeight('identity'))
      }
    }

    if (![TI_PARTNER.AUTO_RU, TI_PARTNER.MAX_POSTER, TI_PARTNER.ONE_PLATFORM].includes(partner)) {
      console.error('Trade-in standalone partner is not auto.ru or max poster')
      success = false
    }

    // tradeIn ends
  } catch (e) {
    success = false
    console.error('[smart.contract] unable to boot trade in', e)
  }

  return success
}
