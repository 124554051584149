import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { FORM_SOURCE } from '../../constants'
import { getProjectName } from '../../helpers/app/detectors/project_name'
import { goToIndex } from '../../store/actions'
import Button from '../Ui/button'

function ErrorScreen({ limitForSaving }) {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  return (
    <div className="kdxsc-error-screen" data-project-name={getProjectName()}>
      <div className="kdxsc-error-screen__icon">
        <div className="kdxsc-error-screen-icon" />
      </div>
      <div className="kdxsc-error-screen__text">{limitForSaving ? t('error.limit') : t('error.description')}</div>
      {!limitForSaving && (
        <div className="kdxsc-error-screen__buttons kdxsc-error-screen-buttons">
          <div className="kdxsc-error-screen-buttons__item">
            <Button handleClick={() => dispatch(goToIndex())} text={t('error.retry')} />
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ settings: { limitForSaving } }) {
  return {
    limitForSaving:
      limitForSaving[FORM_SOURCE.WIDGET] ||
      limitForSaving[FORM_SOURCE.ONLINE_PAYMENT] ||
      limitForSaving[FORM_SOURCE.WIDGET_AUTH],
  }
}

export default connect(mapStateToProps)(ErrorScreen)
