import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { polyfill } from 'seamless-scroll-polyfill'

import { CREDIT_STANDALONE_SCREEN } from '../constants'
import { getInitConfig, setLoading, setType } from '../store/actions/credit_standalone'
import Conditions from './Conditions'
import s          from './styles/main.module.scss'
import Personal   from './Personal'
import Programs from './Programs'
import FooterLinkLogo from './UI/assets/FooterLinkLogo'
import FooterSmartContractLogo from './UI/assets/FooterSmartContractLogo'
import Infoblock from './UI/Infoblock'
import {getProjectName} from "../helpers/app/detectors/project_name";

polyfill()

const CreditCalc = ({ config, screen, loading, error, videoUrl, locale, moduleLocale, isPriceHidden }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const { widget_id, type } = config

  const disableSCLink = useSelector(state => state.settings.disableSmartContractLink)
  const disableSCLogo = useSelector(state => state.settings.disableSmartContractLogo)

  let isErrorInPopup = false

  useEffect(() => {
    dispatch(setType(type))
  }, [type, dispatch])

  useEffect(() => {
    if (widget_id) {
      dispatch(getInitConfig(widget_id))
    }
  }, [config, dispatch, widget_id, type])

  useEffect(() => {
    if (locale === 'Ru') i18n.changeLanguage('ru-RU')
    if (locale === 'En') i18n.changeLanguage('en-US')
    if (locale === 'De') i18n.changeLanguage('de-DE')
  }, [i18n, locale])

  const conditionsRef = useRef()
  const selectModelRef = useRef()
  const selectBrandRef = useRef()
  const selectComplectationRef = useRef()
  const infoblockRef = useRef()

  useEffect(() => infoblockRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), [error])

  if (screen === CREDIT_STANDALONE_SCREEN.INITIAL) {
    if (config.showFailedLoadingComponent && !widget_id) {
      isErrorInPopup = true
    } else {
      return (
       <>
          {config.showFailedLoadingComponent && (
            <div className={s.kdxsc_loading_state}>
              <div className={s.kdxsc_loading_state__icon}>
                <div className={s.kdxsc_loading_state_icon} />
              </div>
            </div>
          )}
       </>
      )
    }
  }

  if (screen === CREDIT_STANDALONE_SCREEN.CARS_NOT_FOUND) {
    console.error(t(`ccS.generalError.${moduleLocale}`))
    return null
  }

  const conditions = () => {
    return (
      <Conditions
        projectName={config?.projectName}
        conditionsRef={conditionsRef}
        selectModelRef={selectModelRef}
        selectBrandRef={selectBrandRef}
        selectComplectationRef={selectComplectationRef}
      />
    )
  }

  const otherScreens = () => {
    return (
      <>
        {screen === CREDIT_STANDALONE_SCREEN.PERSONAL_DATA && (
          <Personal selectModelRef={selectModelRef} conditionsRef={conditionsRef} />
        )}
        {screen === CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS && (
          <Programs selectModelRef={selectModelRef} conditionsRef={conditionsRef} />
        )}

        {error && <Infoblock ref={infoblockRef} text={error} />}

        {screen === CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS && !loading && !error && !disableSCLink && !disableSCLogo && (
          <section className={s.scDescription} data-project-name={getProjectName()}>
            <span className={s.scDescriptionLogo}>
              <FooterSmartContractLogo />
            </span>
            <span className={s.scDescriptionText}>{t('tradeInS.SCDisclaimerText')}</span>
            {videoUrl && (
              <a className={s.scDescriptionLink} href={videoUrl} rel="noreferrer" target="_blank">
                <div className={s.scDescriptionLinkIcon}>
                  <FooterLinkLogo />
                </div>
                <span>{t('tradeInS.SCDisclaimerVideo')}</span>
              </a>
            )}
          </section>
        )}

        {loading && (
          <div className={s.kdxsc_loading_state}>
            <div className={s.kdxsc_loading_state__icon}>
              <div className={s.kdxsc_loading_state_icon} />
            </div>
          </div>
        )}
      </>
    )
  }

  const title = config.widgetTitle ? config.widgetTitle : t(`ccS.title.${moduleLocale}`)

  const content = () => {
    return (
      <>
        {conditions()}
        {otherScreens()}{' '}
      </>
    )
  }

  return (
    <main className={s.container} data-project-name={config.projectName} data-loading={loading}>
      <div className={s.title_box}>
        <h2 className={s.title}>{title}</h2>
      </div>

      {isErrorInPopup ? (
        <div className={s.centered}>{t(`ccS.popupCreditGeneralError`)}</div>
      ) : (
        <>
          {isPriceHidden && <div className={[s.title_box, s.centered].join(' ')}>{t(`ccS.isPriceHiddenHint`)}</div>}
          {isPriceHidden ? <div className={s.calcDisabled}>{content()}</div> : <>{content()}</>}
        </>
      )}
    </main>
  )
}

const mapStateToProps = state => ({
  screen: state.credit_standalone.screen,
  loading: state.credit_standalone.loading,
  error: state.credit_standalone.error,
  videoUrl: state.credit_standalone.config.videoUrl,
  locale: state.credit_standalone.config.locale,
  moduleLocale: state.credit_standalone.module.locale,
  isPriceHidden: state.credit_standalone.config.isPriceHidden,
})

export default connect(mapStateToProps)(CreditCalc)
