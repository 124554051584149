import { forwardRef } from 'react'

import s from './styles/infoblock.module.scss'
import {getProjectName} from "../../../helpers/app/detectors/project_name";

const Infoblock = forwardRef(({ text, stickRight }, ref) => {
  return (
    <section className={s.container} ref={ref} data-stick-right={stickRight} data-project-name={getProjectName()}>
      <span className={s.img}>
        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 0L22.956 2.43467C23.2521 2.50049 23.5169 2.66529 23.7067 2.90187C23.8965 3.13846 23.9999 3.43269 24 3.736V17.052C23.9999 18.369 23.6747 19.6656 23.0532 20.8267C22.4317 21.9878 21.5332 22.9775 20.4373 23.708L12 29.3333L3.56267 23.708C2.46703 22.9776 1.56861 21.9881 0.947131 20.8273C0.325648 19.6664 0.000315142 18.3701 0 17.0533V3.736C5.20504e-05 3.43269 0.103517 3.13846 0.293319 2.90187C0.483122 2.66529 0.747916 2.50049 1.044 2.43467L12 0ZM12 2.732L2.66667 4.80533V17.052C2.66668 17.9299 2.88343 18.7943 3.29768 19.5684C3.71193 20.3425 4.31087 21.0023 5.04133 21.4893L12 26.1293L18.9587 21.4893C19.6889 21.0024 20.2878 20.3428 20.702 19.569C21.1162 18.7952 21.3331 17.9311 21.3333 17.0533V4.80533L12 2.73333V2.732ZM12 8C12.5871 7.99973 13.1579 8.19323 13.6238 8.55048C14.0897 8.90773 14.4247 9.40876 14.5768 9.97583C14.7289 10.5429 14.6896 11.1443 14.465 11.6868C14.2405 12.2292 13.8431 12.6824 13.3347 12.976L13.3333 18.6667H10.6667V12.976C10.1583 12.6825 9.76103 12.2294 9.53641 11.6871C9.31179 11.1448 9.27239 10.5435 9.42432 9.97647C9.57625 9.40947 9.91102 8.90845 10.3767 8.55109C10.8424 8.19374 11.413 8.00003 12 8Z"
            fill="#222222"
          />
        </svg>
      </span>
      <span className={s.text}>{text}</span>
    </section>
  )
})

Infoblock.displayName = 'Infoblock'

export default Infoblock
