import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { load } from 'redux-localstorage-simple'

import { BULLET_TYPE } from './Bullet/constants'
import Bullet from './Bullet/main'
import { bootBullet, isPointVisible } from './helpers'
import { languageSetter } from './helpers/app/boot/language'
import { setCurrency, setLocale, setWidgetId } from './store/actions'
import {
  setBulletFilledType,
  setBulletLinks,
  setBulletSizes,
  setBulletTexts,
  setBulletUseBackground,
} from './store/actions/bullet'

function BulletApp({ config }) {
  const dispatch = useDispatch()
  const [areLinksFilled, setAreLinksFilled] = useState(false)
  const [isPriceHidden, setIsPriceHidden] = useState(false)
  const dataFromLocalStorage = load({
    states: ['tradeIn', 'credit', 'benefits', 'form', 'car', 'saved_sc', 'accessories'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const loadData = useCallback(
    widget_id => {
      bootBullet(widget_id)
        .then(({ data: { currency, locale, visual, isPriceHidden: isPriceHiddenFromBE } }) => {
          try {
            const VISUAL_VARIABLES = ['backgroundOne', 'backgroundTwo', 'color', 'borderRadius']
            const POSITIONS = ['positionDesktop', 'positionTablet', 'positionMobile']

            const kdxscBulletDiv = document.getElementById('kdxsc_bullet')

            // установка цветов и радиуса
            for (let key in visual.visual) {
              if (visual.visual.hasOwnProperty(key) && VISUAL_VARIABLES.includes(key)) {
                kdxscBulletDiv.style.setProperty(`--${key}Bullet`, visual.visual[key])
              }
            }

            // установка позиционирования
            for (let key in visual) {
              if (POSITIONS.includes(key)) {
                for (let position in visual[key]) {
                  if (visual[key].hasOwnProperty(position)) {
                    const formattedKey = key[0].toUpperCase() + key.slice(1)
                    kdxscBulletDiv.style.setProperty(`--${position}${formattedKey}Bullet`, visual[key][position])
                  }
                }
              }
            }

            languageSetter(locale)

            dispatch(setCurrency(currency))
            dispatch(setLocale(locale))
            //dispatch(setWidgetId(widget_id))
            dispatch(setBulletLinks(visual.links))
            dispatch(setBulletTexts(visual.texts))
            dispatch(
              setBulletSizes({
                sizeDesktop: visual.sizeDesktop,
                sizeTablet: visual.sizeTablet,
                sizeMobile: visual.sizeMobile,
              }),
            )
            dispatch(setBulletFilledType(visual.visual.filledType))
            dispatch(setBulletUseBackground(visual.visual.useBackground))

            let { cars, tradeIn, credit } = visual.links

            const isCarsPointVisible = isPointVisible(BULLET_TYPE.CARS, cars, dataFromLocalStorage)
            const isTradeInPointVisible = isPointVisible(BULLET_TYPE.TRADE_IN, tradeIn, dataFromLocalStorage)
            const isCreditPointVisible = isPointVisible(BULLET_TYPE.CREDIT, credit, dataFromLocalStorage)

            if (isCarsPointVisible || isTradeInPointVisible || isCreditPointVisible) {
              setAreLinksFilled(true)
            } else {
              console.error('[smart.contract] unable to boot bullet due to no links available')
            }

            setIsPriceHidden(isPriceHiddenFromBE)
          } catch (e) {
            console.error('[smart.contract] unable to boot bullet', e)
          }
        })
        .catch(e => console.error(e))
        .finally(() => {})
    },
    [dispatch, dataFromLocalStorage],
  )

  useEffect(() => {
    if (config.widget_id) {
      loadData(config.widget_id)
    }
  }, [config, loadData])

  return areLinksFilled && !isPriceHidden ? <Bullet /> : null
}

export default BulletApp
