import { FORM_TYPES } from '../constants'

export const formType = () => {
  if (
    window['@kodix'] &&
    window['@kodix']['smartContract'] &&
    window['@kodix']['smartContract']['showAuthForm'] === 1
  ) {
    return FORM_TYPES.NEW_TYPE
  } else {
    return FORM_TYPES.OLD_TYPE
  }
}
