import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'

import { CREDIT_STANDALONE_SCREEN, FORM_SOURCE } from '../../constants'
import { clearProtections, clearSmsAndCaptcha, initializeErrorsProcessing } from '../../helpers'
import {
  goToOnlineApproval,
  goToSmartContract,
  selectProgram,
  setError,
  setScreen,
} from '../../store/actions/credit_standalone'
import { Button, Label, Subtitle } from '../UI/Common'
import Card from './Card'
import { getCreditProgramEnding } from './endingUtil'
import List from './List'
import s from './styles/programs.module.scss'
import {getProjectName} from "../../helpers/app/detectors/project_name";

const Programs = ({
  widgetId,
  programs,
  selectedProgram,
  name,
  lastName,
  phone,
  email,
  carModelData,
  setScreen,
  selectProgram,
  goToSmartContract,
  conditionsRef,
  selectModelRef,
  loading,
  smartContract,
  setError,
  fields,
  creditOnlineApprove,
  currency,
  goToOnlineApproval,
  isLeasing,
  moduleLocale,
  isPersonalDataFilled,
  showProgramsBeforePersonalData,
  error,
}) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const element = conditionsRef.current.getBoundingClientRect().bottom + window.scrollY + 1
    window.scroll({
      top: element,
      behavior: 'smooth',
    })
  }, [conditionsRef])

  const disableSCLink = useSelector(state => state.settings.disableSmartContractLink)

  const handleSelect = useCallback(selected => selectProgram(selected), [selectProgram])

  const banks = [...new Set(programs.map(program => program.bank.name))]

  const [showOnlineApprovalOnce, setShowOnlineApprovalOnce] = useState(true)

  const programList = banks.map((bank, ix) => {
    const bankPrograms = programs.filter(p => p.bank.name === bank)
    return (
      <List
        currency={currency}
        bankName={bank}
        key={bank}
        programs={bankPrograms}
        selectedProgram={selectedProgram}
        handleSelect={handleSelect}
        opened={banks.length === 1 || ix === 0}
        moduleLocale={moduleLocale}
        isLeasing={isLeasing}
        onlyOneBank={banks.length === 1}
      />
    )
  })

  const buttonText = () => {
    if (smartContract.smartContractUrl && !showProgramsBeforePersonalData) {
      return t('ccS.programs.view')
    } else {
      return t('ccS.programs.continue')
    }
  }

  const handleSubmit = (withOnlineApprove = false, e) => {
    e.preventDefault()

    if (loading) {
      return
    }

    if (!carModelData.length) {
      selectModelRef.current.focus()
      return
    }

    setError(null)

    if (
      (!showProgramsBeforePersonalData && smartContract.smartContractUrl) ||
      (showProgramsBeforePersonalData && isPersonalDataFilled && smartContract.smartContractUrl)
    ) {
      window.open(smartContract.smartContractUrl, '_blank')

      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_OnlineDeal',
          Status: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
          buttonName: buttonText(),
          widget_id: widgetId,
          deal_id: `${smartContract.genericId}-${smartContract.id}`,
          eventValue: '',
        })

      window.dataLayer &&
        window.dataLayer.push({
          event: 'calc_choose_credit',
          ec: 'step3',
          ea: window.location.href,
        })
      window.ym && window.ym(93074980, 'reachGoal', 'calc_choose_credit_step3', { URL: window.location.href })

      return
    }

    if (!isPersonalDataFilled) {
      setScreen(CREDIT_STANDALONE_SCREEN.PERSONAL_DATA)
      return
    }

    if (withOnlineApprove) {
      setShowOnlineApprovalOnce(false)
    }

    const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE
    if (isLeasing) {
      clearProtections(FORM_SOURCE.LEASING_STANDALONE)
    } else {
      clearProtections(FORM_SOURCE.CREDIT_STANDALONE)
    }

    clearSmsAndCaptcha()

    goToSmartContract(withOnlineApprove).catch(e => {
      initializeErrorsProcessing({
        e,
        formSource,
        additional: {
          creditStandaloneStep: 'programs',
          errorText: t('error.limit'),
        },
      })
    })
  }

  const handleEditClick = () => setScreen(CREDIT_STANDALONE_SCREEN.PERSONAL_DATA)

  const handleEditKeyDown = e => {
    if (e.key === 'Enter') {
      setScreen(CREDIT_STANDALONE_SCREEN.PERSONAL_DATA)
    }
  }

  const suitableCreditProgramsText = () => {
    if (i18n.language === 'en-US') {
      return `${programs.length} ${
        programs.length === 1
          ? t(`ccS.personal.yourSuitableProgramsP1V2.${moduleLocale}`)
          : t(`ccS.personal.yourSuitableProgramsP1V1.${moduleLocale}`)
      }`
    } else {
      return `${t('ccS.personal.yourSuitableProgramsP1')} ${programs.length} ${getCreditProgramEnding(
        programs.length,
        moduleLocale,
      )}`
    }
  }

  const approveCreditOnlineHandler = e => {
    e.preventDefault()

    if (smartContract.genericId && smartContract.id && smartContract.token) {
      goToOnlineApproval({
        uniqueId: smartContract.genericId.toUpperCase() + '-' + smartContract.id,
        token: smartContract.token,
        smartContractUrl: smartContract?.smartContractUrl,
      })

      setShowOnlineApprovalOnce(false)
    } else {
      handleSubmit(true, e)
    }
  }

  const showButtons =
    smartContract.smartContractUrl ||
    !smartContract.id ||
    (smartContract.id && smartContract.createdFromBackground && !showProgramsBeforePersonalData) ||
    !error

  const showPersonalDataBox = () => {
    if (phone.length) {
      return true
    }

    const formValues = {
      name: name,
      lastName: lastName,
      email: email,
    }

    let show = false

    for (const [key, value] of Object.entries(fields)) {
      if (value.hide === false && formValues[key].length) {
        show = true
      }
    }

    return show
  }

  return (
    <section className={s.programs} data-project-name={getProjectName()}>
      <form onSubmit={e => handleSubmit(false, e)}>
        <Subtitle>{suitableCreditProgramsText()}</Subtitle>

        {programList}

        {showPersonalDataBox() && (
          <div className={s.personal_box} data-project-name={getProjectName()}>
            <div className={s.list_header}>
              <span className={s.bank_name}>{t('personalData.yourPersonalData')}</span>
              <div className={s.toggle_programs} tabIndex={0} onClick={handleEditClick} onKeyDown={handleEditKeyDown}>
                <Label labelText={t('edit')} />
                <div className={s.edit_icon}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.3322 5.92676L10.0732 4.66783L1.78067 12.9604V14.2193H3.0396L11.3322 5.92676ZM12.5911 4.66783L13.85 3.4089L12.5911 2.14997L11.3322 3.4089L12.5911 4.66783ZM3.7768 16H0V12.2223L11.9616 0.260678C12.3093 -0.0868926 12.8729 -0.0868926 13.2206 0.260678L15.7393 2.77943C16.0869 3.12711 16.0869 3.69069 15.7393 4.03836L3.77769 16H3.7768Z"
                      fill="#222222"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <Card firstName={name} lastName={lastName} phone={phone} email={email} fields={fields} type="personal" />
            </div>
          </div>
        )}

        {showButtons && (
          <div className={s.buttonsContainer}>
            <div className={s.buttonsContainer__item}>
              <Button>{buttonText()}</Button>
            </div>

            {creditOnlineApprove && showOnlineApprovalOnce && !disableSCLink && (
              <div onClick={approveCreditOnlineHandler} className={s.buttonsContainer__item}>
                <Button onClick={approveCreditOnlineHandler}>{t('credit.onlineApproval')}</Button>
              </div>
            )}
          </div>
        )}
      </form>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    widgetId: state.credit_standalone.config.widgetId,
    programs: state.credit_standalone.programs.list,
    selectedProgram: state.credit_standalone.programs.selected,
    name: state.form.values.name,
    lastName: state.form.values.lastName,
    phone: state.form.values.phone,
    email: state.form.values.email,
    carModelData: state.credit_standalone.conditions.carModelData,
    loading: state.credit_standalone.loading,
    error: state.credit_standalone.error,
    fields: state.credit_standalone.config.fields,
    smartContract: state.credit_standalone.config.smartContract,
    screen: state.credit_standalone.screen,
    creditOnlineApprove: state.credit_standalone.config.creditOnlineApprove,
    currency: state.credit_standalone.config.currency,
    isLeasing: state.credit_standalone.module.isLeasing,
    moduleLocale: state.credit_standalone.module.locale,
    isPersonalDataFilled: state.credit_standalone.personal.filled,
    showProgramsBeforePersonalData: state.credit_standalone.config.showProgramsBeforePersonalData,
  }
}

const mapDispatchToProps = { setScreen, selectProgram, goToSmartContract, goToOnlineApproval, setError }

export default connect(mapStateToProps, mapDispatchToProps)(Programs)
