import { useTranslation } from 'react-i18next'

import s from './terms.module.scss'

function Terms({ periods, period, setPeriodHandler, label }) {
  const { t } = useTranslation()

  const periodsData = () => {
    return periods.map(i => {
      return (
        <div key={i.value} className={s.terms__item}>
          <div
            className={[s.term, period === i.value ? s.active : ''].join(' ')}
            onClick={() => setPeriodHandler(i.value)}
          >
            {t(`credit.periods.${i.text}`)}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={s.wrapper}>
      <label className={s.wrapper__label}>{label}</label>
      <div className={s.wrapper__terms}>
        <div className={s.terms}>{periodsData()}</div>
      </div>
    </div>
  )
}

export default Terms
