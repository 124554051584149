export const localeRu = {
  car: require('./car'),
  trade_in: require('./trade_in'),
  bullet: require('./bullet'),
  leasing: require('./leasing'),
  dealerships: require('./dealerships'),
  accessories: require('./accessories'),

  tradeInS: require('./trade_in_standalone'),
  ccS: require('./credit_standalone'),
  carOrderForm: require('./carOrderForm'),

  calculatePurchase: 'Рассчитать покупку',

  loading: 'Ожидание ...',
  generalError: 'К сожалению, произошла ошибка при загрузке',
  free: 'бесплатно',
  edit: 'Редактировать',
  from: 'от',
  months: 'мес.',
  terms: 'Условия',
  benefits: {
    title: 'Дополнительные предложения',
    additionalOptions: 'Дополнительное оборудование',
    additionalOptionsWithoutList: 'Стоимость установленного доп. оборудования',
  },

  auth: {
    enterPersonalDataBeforeContinue: 'Прежде чем продолжить, введите свои данные для сохранения ваших условий',
    continue: 'Продолжить',
  },

  benefitsList: {
    saveTermsUntil48Hours: 'Сохранение условий на 48 часов',
    personalManager: 'Персональный менеджер',
    smsAndStatus: {
      auto: 'Смс-ссылка на авто и отслеживание статуса',
      moto: 'Смс-ссылка на мотоцикл и отслеживание статуса',
      other: 'Смс-ссылка на ТС и отслеживание статуса',
    },
    saveTermsUntilTneEnd: 'Сохранение условий до конца сделки',
    carBooking: {
      auto: '100% бронирование автомобиля',
      moto: '100% бронирование мотоцикла',
      other: '100% бронирование ТС',
    },
    returnOnlinePaymentEasily: 'Лёгкий возврат предоплаты',
  },

  credit: {
    ...require('./credit'),
    creditForAmount: 'Кредит на сумму { amount }',
    creditWithBenefit: 'Ваша выгода за кредит { value } уже учтена',
    creditWithTradeInDescription: {
      auto: 'В первоначальном взносе уже учтена стоимость автомобиля в Трейд-ин',
      moto: 'В первоначальном взносе уже учтена стоимость мотоцикла в Трейд-ин',
      other: 'В первоначальном взносе уже учтена стоимость ТС в Трейд-ин',
    },
    initialFee: 'Первоначальный взнос',
    buybackPayment: 'Остаточный платёж',
    buybackPaymentHint:
      'Остаточный платеж – это сумма в процентах от стоимости транспортного средства, выплата которой переносится на конец срока кредитования',
    creditPeriod: 'Срок кредитования',

    enabledWithoutCalculations: {
      screenHeader: 'Вы можете выбрать оптимальные для вас условия кредита',
      screenText:
        'К сожалению, автоматический расчет кредитных программ отключен, но введенные вами данные будут переданы менеджеру для формирования кредитного предложения',
    },

    periods: {
      oneYear: '1 год',
      twoYears: '2 года',
      threeYears: '3 года',
      fourYears: '4 года',
      fiveYears: '5 лет',
      sixYears: '6 лет',
      sevenYears: '7 лет',
      eightYears: '8 лет',
    },

    additionalOptions: 'Дополнительные условия',
    lifeInsurance: 'Все платежи защищены',
    withoutKasko: 'Кредит без КАСКО',

    searchDisabledHeader: 'Автоматический поиск кредитных програм недоступен',
    searchDisabledContent:
      'Вы можете сохранить кредитные параметры, и менеджер подберёт вам кредитные програмы вручную.',

    searchNoProgramsHeader: 'Пожалуйста, выберите другие параметры кредитной программы для расчета',
    searchNoProgramsContent: '',

    searchFailedHeader: 'Пожалуйста, выберите другие параметры кредитной программы для расчета',
    searchFailedContent: '',

    saveParameters: 'Сохранить параметры',

    chooseCreditProgram: 'Выберите кредитную программу (найдено { count })',
    pricePerMonth: '{ value }/мес.',
    choose: 'Выбрать',
    onlineApproval: 'Онлайн одобрение',
    onlineApprovalHint:
      'Сначала выберите первоначальный взнос<br/>по кредиту, срок кредита и программу<br/>банка, которая ваc заинтересовала',
    redemptionPrice: 'Выкупная стоимость',
    moreAboutProgram: 'Подробнее о программе',
  },

  error: {
    description: 'Упс! При отправке заявки произошла ошибка. Попробуйте еще раз.',
    retry: 'Попробовать еще раз',
    limit: 'Простите, на сегодня хватит, давайте еще раз завтра попробуем',
  },

  success: {
    description:
      'Спасибо! Ваш менеджер свяжется с вами в ближайшее время для уточнения условий сделки. Мы также сохранили ваш расчет и отправили вам ссылку на телефон. По этой ссылке вы всегда сможете вернуться к этой сделке, внести изменения или увидеть изменения, внесенные вашим менеджером.',
    descriptionWithoutLink: 'Спасибо! Ваш менеджер свяжется с вами в ближайшее время для уточнения условий сделки.',
    goToSmartContract: 'Перейти к онлайн сделке',
    backToSite: 'Вернуться на сайт',
  },

  paymentStart: {
    description:
      'Спасибо! Ваш менеджер свяжется с вами в ближайшее время для уточнения условий сделки. Мы также сохранили ваш расчет и отправили вам ссылку на телефон. По этой ссылке вы всегда сможете вернуться к этой сделке, внести изменения или увидеть изменения, внесенные вашим менеджером.',
    redirectDescription:
      'Через {count, plural, one {# секунду} few {# секунды} other {# секунд}} вы будете перенаправлены на страницу онлайн оплаты.',
    redirectDescriptionFail: 'Если перенаправления не произошло, нажмите на кнопку.',
    goToOnlinePayment: 'Перейти к онлайн оплате',
  },

  paymentFailed: {
    description:
      'Упс! При генерации ссылки на оплату произошла ошибка. Перейдите по ссылке ниже, что бы попробовать ещё раз.',
    goToSmartContract: 'Продолжить оформление онлайн сделки',
  },

  onlinePayment: {
    whatHappensIfMyOpinionChanged:
      'Возврат, оформленный в течение 5 дней после внесения оплаты, занимает 2 рабочих дня',
    benefitText:
      'Также вы получите выгоду в размере <span class="kdxsc-widget-state-payment-benefit__highlight">{ value }</span>, при условии онлайн оплаты',
    goToOnlinePaymentButtonText: 'Онлайн предоплата { value }',
    chooseOnlinePaymentType: 'Выберите тип предоплаты',

    formTitleWithFillPayment: {
      auto: 'Пожалуйста, заполните все поля формы, эти данные нужны для составления договора купли-продажи автомобиля.',
      moto: 'Пожалуйста, заполните все поля формы, эти данные нужны для составления договора купли-продажи мотоцикла.',
      other: 'Пожалуйста, заполните все поля формы, эти данные нужны для составления договора купли-продажи ТС.',
    },
    formTitleWithAdvancePayment: 'Пожалуйста, заполните все поля формы, эти данные нужны для онлайн предоплаты.',
    fullPaymentTitle: 'Бронирование или покупка?',
    fullPayment: {
      partial: {
        title: 'Авансовый платёж',
        description: {
          auto: 'Онлайн оплата бронирования автомобиля с договором авансового платежа',
          moto: 'Онлайн оплата бронирования мотоцикла с договором авансового платежа',
          other: 'Онлайн оплата бронирования ТС с договором авансового платежа',
        },
      },
      full: {
        title: 'Полная оплата',
        description: {
          auto: 'Онлайн оплата полной стоимости автомобиля с договором купли-продажи',
          moto: 'Онлайн оплата полной стоимости мотоцикла с договором купли-продажи',
          other: 'Онлайн оплата полной стоимости ТС с договором купли-продажи',
        },
      },
      disabledTexts: {
        beginning: 'Данный вариант оплаты возможен только при полной оплате за наличные деньги.',
        parts: {
          tradeIn: 'Трейд-ин',
          credit: 'Кредит',
          leasing: 'Лизинг',
        },
        turnOff: 'Выключите',
        and: 'и',
      },
    },
  },

  header: {
    mainTitle: 'Условия сделки',
    tradeInTitle: 'Оцените своё транспортное средство',
    onlinePaymentTitle: 'Онлайн предоплата',
    creditTitle: 'Рассчитайте кредитные условия',
    authTitle: 'Авторизация',
    leasingTitle: 'Покупка в лизинг',
    promoProgram: 'Акция',
  },

  footer: {
    createSmartContractButtonText: 'Сохранить условия',
    goToOnlinePaymentButtonText: 'Онлайн предоплата { value }',
    madeBy: 'Сделано в<br/>UDP Auto',
  },

  form: {
    yourName: 'Ваше имя',
    yourLastName: 'Ваша фамилия',
    yourSecondName: 'Ваше отчество',
    phoneNumber: 'Номер телефона',
    email: 'Email',
    promoCode: 'Промокод (если имеется)',
    defaultAgreementText:
      'Нажимая на кнопку, вы даете согласие на обработку ваших персональных данных и дальнейшую коммуникацию.',
    captcha: 'Капча',
    smsCode: 'Код из СМС',
    captchaHint: 'Введите цифры с картинки и нажмите на кнопку сохранения',
    smsCodeHint: 'Введите код из СМС и нажмите на кнопку сохранения',
    resendCaptcha: 'Обновить картинку',
    resendSmsCode: 'Отправить код повторно',
    captchaError: 'Вы ввели неверную капчу',
    smsCodeError: 'Вы ввели неверный код из СМС',
    sendIn: 'Отправить через',
  },

  personalData: {
    fullName: 'ФИО',
    phone: 'Телефон',
    yourPersonalData: 'Ваши персональные данные',
    storeDescription:
      'Для сохранения выбранной конфигурации сделки заполните данные для последующего входа в личный кабинет.',
    phoneDescription: 'Телефон будет использован как логин и на него вам будет прислан пароль.',
  },

  tooltip: {
    defaultTitle: 'Рассчитайте точные условия покупки и получите персональное предложение',
    tradeInWithCredit: 'Выберите условия Трейд-ин и Кредит',
    tradeInWithoutCredit: 'Выберите условия Трейд-ин',
    creditWithoutTradeIn: 'Рассчитайте Кредит',
    benefits: 'Выберите выгоды и услуги дилера',
    onlinePayment: {
      auto: 'Внесите предоплату онлайн и забронируйте авто',
      moto: 'Внесите предоплату онлайн и забронируйте мотоцикл',
      other: 'Внесите предоплату онлайн и забронируйте ТС',
    },
    closeButtonText: 'все понятно',
    watchVideo: 'Смотреть видео',
  },

  summary: {
    ...require('./summary'),
    onlinePaymentTooltip: 'Онлайн предоплата доступна после сохранения условий',
    onlinePaymentBenefit: 'Преимущество за онлайн оплату',
    yourBenefit: 'Ваше преимущество',
    selectedServices: 'Выбранные услуги',
    selectedAccessories: 'Выбранные аксессуары',
    selectedGifts: 'Выбранные подарки',
    tradeInPriceAmount: {
      auto: 'Ваш авто в трейд-ин',
      moto: 'Ваш мотоцикл в трейд-ин',
      other: 'Ваше ТС в трейд-ин',
    },
    tradeInBenefit: 'Преимущество за трейд-ин',
    tradeInBenefitWithoutTradeIn: 'Преимущество без трейд-ин',
    tradeInBenefitWithoutTradeInHint: 'Ваше преимущество при покупке без Трейд-ин',
    creditBenefit: 'Преимущество за кредит',
    leasingBenefit: 'Преимущество за лизинг',
    kasko: 'Преимущество за кредит с Каско',
    carPrice: {
      auto: 'Цена авто',
      moto: 'Цена мотоцикла',
      other: 'Цена ТС',
    },
    discounts: 'Скидки',
    services: 'Услуги',
    initialFeeAmountCredit: 'Итоговый первоначальный взнос по кредиту',
    initialFeeAmount: 'Первоначальный взнос',
    needToPay: 'Итого к оплате',
    needToPayIfCredit: 'Ваш первоначальный взнос',
    needToPayIfLeasing: 'Итого авансовый платеж',
    needToPayZero: 'Ваша оплата',
    dealerNeedToPay: 'Доплата от дилера',
    byCredit: 'Кредит',
    byLeasing: 'Лизинг',
    monthlyPayment: 'Платёж в месяц',
    carPriceWithBenefits: {
      auto: 'Стоимость авто',
      moto: 'Стоимость мотоцикла',
      other: 'Стоимость ТС',
    },
  },

  tradeIn: {
    notSelected: 'Не выбран',
    rate: 'Оценить',
    fillAllFields: 'Заполните все поля',
    apply: 'Применить',
    choose: 'Выбрать',
    rateResult: 'Результат оценки',
    rateResultFailed:
      'К сожалению, нам не удалось автоматически рассчитать стоимость вашего автомобиля в Трейд-ин. Воспользуйтесь другим методом расчета или сохраните условия, чтобы менеджер сделал оценку по вашим данным.',
    rateResultZeroPrice:
      'К сожалению, нам не удалось автоматически рассчитать стоимость вашего автомобиля в Трейд-ин. Воспользуйтесь другим методом расчета или сохраните условия, чтобы менеджер сделал оценку по вашим данным.',
    rateResultSuccessDescription:
      'Система оценки основана на ценах сделок, информация о которых находится в базе данных <b><a href="https://auto.ru/cars/evaluation/" target="_blank">Авто.ру</a></b>',
    rateResultSuccessDescriptionGenericV2:
      'Система оценки основана на ценах сделок, информация о которых находится в базе данных <a href="https://auto.ru/cars/evaluation/" target="_blank">Авто.ру</a>',
    rateResultSuccessDescriptionWithoutRF:
      'Система оценки построена на базе проданных за последний год транспортных средств',
    rateResultPriceFromTo: 'от { from } до { to }',
    rateResultSuccessDescription2:
      'Расчет носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 435 ГК РФ.',
    rateResultSuccessDescription2WithoutRF:
      'Расчет носит информационный характер и ни при каких условиях не является публичной офертой.',

    ownerCarPriceText: {
      auto: 'Не согласны с оценкой?<br>Вы можете указать желаемую стоимость вашего автомобиля',
      moto: 'Не согласны с оценкой?<br>Вы можете указать желаемую стоимость вашего мотоцикла',
      other: 'Не согласны с оценкой?<br>Вы можете указать желаемую стоимость вашего ТС',
    },

    body: {
      title: 'Кузов',
    },

    brand: {
      title: 'Марка',
      notSelected: 'Не выбрана',
    },

    city: {
      title: 'Город',
      noData: 'Нет данных',
      loading: 'Загрузка',
    },

    color: {
      title: 'Цвет',

      names: {
        white: 'Белый',
        silver: 'Серебристый',
        beige: 'Бежевый',
        yellow: 'Желтый',
        golden: 'Золотистый',
        orange: 'Оранжевый',
        pink: 'Розовый',
        red: 'Красный',
        purple: 'Пурпурный',
        brown: 'Коричневый',
        lightBlue: 'Голубой',
        blue: 'Синий',
        violet: 'Фиолетовый',
        green: 'Зелёный',
        gray: 'Серый',
        black: 'Черный',
      },
    },

    generation: {
      title: 'Поколение',
      notSelected: 'Не выбрано',
    },

    mileage: {
      title: 'Пробег',
      empty: 'Не указан',
    },

    identity: {
      title: 'VIN или Гос номер',
      empty: 'Не указан',
    },

    model: {
      title: 'Модель',
      notSelected: 'Не выбрана',
    },

    modification: {
      title: 'Модификация',
      notSelected: 'Не выбрана',
    },

    engine: {
      title: 'Двигатель',
      notSelected: 'Не выбрано',
    },

    gearbox: {
      title: 'Коробка передач',
      notSelected: 'Не выбрано',
    },

    interiorType: {
      title: 'Тип интерьера',
      notSelected: 'Не выбрано',
    },

    interiorColor: {
      title: 'Цвет интерьера',
      notSelected: 'Не выбрано',
    },

    owner: {
      title: 'Владелец',

      names: {
        first: 'Первый',
        second: 'Второй',
        thirdAndMore: 'Третий и более',
      },
    },

    year: {
      title: 'Год',
      notSelected: 'Не выбран',
    },

    helpers: {
      showAll: 'Все варианты',
      showPopular: 'Показать популярные',
    },

    steps: {
      elevenStepsRemain: 'До оценки осталось 11 шагов',
      tenStepsRemain: 'До оценки осталось 10 шагов',
      nineStepsRemain: 'До оценки осталось 9 шагов',
      eightStepsRemain: 'До оценки осталось 8 шагов',
      sevenStepsRemain: 'До оценки осталось 7 шагов',
      sixStepsRemain: 'До оценки осталось 6 шагов',
      fiveStepsRemain: 'До оценки осталось 5 шагов',
      fourStepsRemain: 'До оценки осталось 4 шага',
      threeStepsRemain: 'До оценки осталось 3 шага',
      goToCity: 'Выбрать город',
      twoStepsRemain: 'До оценки осталось 2 шага',
      homeStretch: 'Финишная прямая',
    },

    vinOrGrzErrorText: 'Проверьте правильность заполнения поля "VIN или Гос Номер"',
  },

  car_statuses: {
    wait: {
      list_text: 'Забронирован',
      detail_text: {
        auto: 'Автомобиль забронирован',
        moto: 'Мотоцикл забронирован',
        other: 'ТС забронировано',
      },
    },
    reserved: {
      list_text: 'Забронирован',
      detail_text: {
        auto: 'Автомобиль забронирован',
        moto: 'Мотоцикл забронирован',
        other: 'ТС забронировано',
      },
    },
    sold: {
      list_text: 'Продан',
      detail_text: {
        auto: 'Автомобиль продан',
        moto: 'Мотоцикл продан',
        other: 'ТС продано',
      },
    },
    available: {
      list_text: 'Расчет выгод',
      detail_text: {
        auto: 'Забронировать онлайн',
        moto: 'Забронировать онлайн',
        other: 'Забронировать онлайн',
      },
    },
  },
}
