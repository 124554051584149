import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { load } from 'redux-localstorage-simple'

import { TRADE_IN_SETTINGS_BEHAVIOR } from '../../constants'
import {
  accessoriesSetter,
  benefitsSetter,
  boot,
  bootPhoneCountriesData,
  deprecatedVisualHelper,
  formatPrice,
  isPointVisible,
  kodixUidSetter,
  normalizeVisual,
  paletteSetter,
  tradeInSetter,
} from '../../helpers'
import { financeSetter } from '../../helpers/app/boot/finance'
import { languageSetter } from '../../helpers/app/boot/language'
import {
  goToIndex,
  setCalledFromList,
  setCarIdentity,
  setCarImage,
  setCarName,
  setCarPrice,
  setCarUrl,
  setCurrency,
  setCustomBenefitSetting,
  setDisableCash,
  setFormValue,
  setLocale,
  setModelData,
  setOnlinePayment,
  setRestrictions,
  setScroll,
  setVisual,
  setWidgetId,
  setWidgetOpenStart,
  showWidget,
  stashFormErrors,
} from '../../store/actions'
import { BULLET_TYPE } from '../constants'
import Point from '../point'
import { dataForCarsPointHelper, dataForCreditPointHelper, dataForTradeInPointHelper } from './helpers'
import s from './main.module.scss'

// не стирать -  tradeIn, credit, car, saved_sc
function Bullet({
  bullet,
  widget_id,
  currency,
  //tradeIn,
  //credit,
  //car,
  //saved_sc,
  //credit_standalone,
  defaultCountryCode,
  possibleCountryCodes,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const dataFromLocalStorage = load({
    states: ['tradeIn', 'credit', 'benefits', 'form', 'car', 'saved_sc', 'accessories'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const dataFromLocalStorageCreditStandalone = load({
    states: ['credit_SA'],
    namespace: '@kodix/SC',
    namespaceSeparator: '/',
  })

  const isSCLinkExists = dataFromLocalStorage?.saved_sc?.url
  const isSCLinkExistsFromCCS = dataFromLocalStorageCreditStandalone?.credit_SA?.smartContractUrl

  const openSavedSC = () => {
    window.open(dataFromLocalStorage.saved_sc.url, '_blank')
  }

  const openSavedSCFromCCS = () => {
    window.open(dataFromLocalStorageCreditStandalone.credit_SA.smartContractUrl, '_blank')
  }

  const clickOnCars = () => {
    //если есть сохраненнный СК, то переходим в СК
    if (isSCLinkExists) {
      openSavedSC()
      return
    }

    //если есть сохраненнный СК, то переходим в СК из КК
    if (isSCLinkExistsFromCCS) {
      openSavedSCFromCCS()
      return
    }

    // если есть данные о покупаемой машине в локалсторадже, то открываем виджет
    const {
      car: { identity, price, modelData, productType, url, image, name, discounts },
    } = dataFromLocalStorage

    const necessaryDataForOpeningWidget = identity && price && modelData && productType

    if (widget_id && necessaryDataForOpeningWidget) {
      dispatch(setWidgetOpenStart(Date.now()))

      boot(widget_id, price, identity, modelData, productType)
        .then(
          ({
            data: {
              identity,
              locale,
              product,
              currency,
              visual,
              tradeIn,
              benefits,
              isCustomBenefitsOn,
              onlinePayment,
              restrictions,
              finance,
              accessories,
            },
          }) => {
            const { credit, leasing, disableCash } = finance

            paletteSetter(visual.palette)

            const finalPrice = product.price || price

            const normalizedVisual = normalizeVisual(visual)

            kodixUidSetter()

            if (finalPrice > 0) {
              try {
                languageSetter(locale)
                dispatch(setCurrency(currency))
                dispatch(setLocale(locale))
                dispatch(setCalledFromList(true))
                dispatch(setWidgetId(widget_id))
                dispatch(setCarPrice(price))
                dispatch(setModelData(modelData))
                dispatch(setCarIdentity(identity))
                dispatch(setVisual(normalizedVisual, deprecatedVisualHelper('', '')))
                dispatch(stashFormErrors())
                dispatch(setScroll(false))
                dispatch(setCustomBenefitSetting(isCustomBenefitsOn))
                dispatch(setFormValue('agreement', visual.agreement_text.default_setting))
                dispatch(setRestrictions(restrictions))
                dispatch(setDisableCash(disableCash))

                // benefits begin
                benefitsSetter(benefits, dataFromLocalStorage)
                // benefits end

                // tradeIn begins
                const isTradeInEnabledFromSettings = [
                  TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
                  TRADE_IN_SETTINGS_BEHAVIOR.ENABLED,
                ].includes(tradeIn.behaviour)

                tradeInSetter(
                  isTradeInEnabledFromSettings,
                  tradeIn,
                  discounts,
                  isCustomBenefitsOn,
                  dataFromLocalStorage,
                )

                // tradeIn ends

                // credit begins
                financeSetter({
                  finance,
                  discounts,
                  isCustomBenefitsOn,
                  price,
                  dataFromLocalStorage,
                })
                // credit ends

                bootPhoneCountriesData(dataFromLocalStorage, defaultCountryCode, possibleCountryCodes)

                // accessories begin
                accessoriesSetter(accessories, dataFromLocalStorage)
                // accessories end

                dispatch(setOnlinePayment(onlinePayment))

                dispatch(setCarImage(image))
                dispatch(setCarName(name))
                dispatch(setCarUrl(url))

                dispatch(showWidget())

                dispatch(goToIndex())
              } catch (e) {
                console.error('[smart.contract] unable to boot detail widget from bullet due', e)
              }
            }
          },
        )
        .catch(e => console.error(e))
      return
    }

    // иначе переходим по ссылке
    if (bullet?.links?.cars) {
      window.open(bullet.links.cars, '_self')
    }
  }

  const clickOnTradeIn = () => {
    // если есть сохраненнный СК, то переходим в СК
    if (isSCLinkExists) {
      openSavedSC()
      return
    }

    //если есть сохраненнный СК, то переходим в СК из КК
    if (isSCLinkExistsFromCCS) {
      openSavedSCFromCCS()
      return
    }

    // переходим по ссылке
    if (bullet?.links?.tradeIn) {
      window.open(bullet.links.tradeIn, '_self')
    }
  }

  const clickOnCredit = () => {
    // если есть сохраненнный СК, то переходим в СК
    if (isSCLinkExists) {
      openSavedSC()
      return
    }

    //если есть сохраненнный СК, то переходим в СК из КК
    if (isSCLinkExistsFromCCS) {
      openSavedSCFromCCS()
      return
    }

    // переходим по ссылке
    if (bullet?.links?.credit) {
      window.open(bullet.links.credit, '_self')
    }
  }

  const dataForCarsPoint = useCallback(() => {
    return dataForCarsPointHelper({
      dataFromLocalStorage,
      isSCLinkExists,
      isSCLinkExistsFromCCS,
      currency,
      textFromBe: bullet?.texts?.cars,
    })
  }, [currency, dataFromLocalStorage, isSCLinkExists, isSCLinkExistsFromCCS, bullet?.texts])

  const dataForTradeInPoint = useCallback(() => {
    return dataForTradeInPointHelper({
      dataFromLocalStorage,
      isSCLinkExists,
      isSCLinkExistsFromCCS,
      currency,
      textFromBe: bullet?.texts?.tradeIn,
    })
  }, [currency, dataFromLocalStorage, isSCLinkExists, isSCLinkExistsFromCCS, bullet?.texts])

  const dataForCreditPoint = useCallback(() => {
    return dataForCreditPointHelper({
      dataFromLocalStorage,
      dataFromLocalStorageCreditStandalone,
      isSCLinkExists,
      isSCLinkExistsFromCCS,
      currency,
      textFromBe: bullet?.texts?.credit,
    })
  }, [
    currency,
    dataFromLocalStorage,
    dataFromLocalStorageCreditStandalone,
    isSCLinkExists,
    isSCLinkExistsFromCCS,
    bullet?.texts,
  ])

  const isCarsPointVisible = isPointVisible(BULLET_TYPE.CARS, bullet.links.cars, dataFromLocalStorage)
  const isTradeInPointVisible = isPointVisible(BULLET_TYPE.TRADE_IN, bullet.links.tradeIn, dataFromLocalStorage)
  const isCreditPointVisible = isPointVisible(BULLET_TYPE.CREDIT, bullet.links.credit, dataFromLocalStorage)

  return (
    <div className={s.bullet_wrapper}>
      <div className={s.bullet}>
        {isCarsPointVisible && (
          <div className={s.bullet__item} onClick={clickOnCars}>
            <Point
              type={BULLET_TYPE.CARS}
              isFilled={dataForCarsPoint().isFilled}
              texts={{ top: dataForCarsPoint().top, bottom: dataForCarsPoint().bottom }}
            />
          </div>
        )}
        {isTradeInPointVisible && (
          <div className={s.bullet__item} onClick={clickOnTradeIn}>
            <Point
              type={BULLET_TYPE.TRADE_IN}
              isFilled={dataForTradeInPoint().isFilled}
              texts={{ top: dataForTradeInPoint().top, bottom: dataForTradeInPoint().bottom }}
            />
          </div>
        )}
        {isCreditPointVisible && (
          <div className={s.bullet__item} onClick={clickOnCredit}>
            <Point
              type={BULLET_TYPE.CREDIT}
              isFilled={dataForCreditPoint().isFilled}
              texts={{ top: dataForCreditPoint().top, bottom: dataForCreditPoint().bottom }}
            />
          </div>
        )}
        {bullet.useBackground && (
          <div className={s.bullet__attention}>
            <div className={s.loader}>
              <div className={s.circle} />
              <div className={s.circle} />
              <div className={s.circle} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  saved_sc,
  credit,
  car,
  tradeIn,
  credit_standalone,
  bullet,
  settings: { widget_id, currency },
  form: { defaultCountryCode, possibleCountryCodes },
}) {
  return {
    bullet,
    widget_id,
    currency,
    tradeIn,
    credit,
    car,
    saved_sc,
    credit_standalone,
    defaultCountryCode,
    possibleCountryCodes,
  }
}

export default connect(mapStateToProps)(Bullet)
