import _throttle from 'lodash/throttle'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { load, save } from 'redux-localstorage-simple'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { SMART_CONTRACT_TRADE_IN_UPDATED_EVENT } from '../constants'
import rootReducer from '../store/reducers'
import * as actionCreators from './actions/index'
import { updatedEventEmitter } from './helpers/api_middleware'
import { tempSyncCreditMiddleware } from './helpers/credit_standalone'
import { FORM_INIT_STATE } from './reducers/form'
import { TRADE_IN_INIT_STATE } from './reducers/trade_in'

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 })

// eslint-disable-next-line no-unused-vars
const logger = createLogger({
  collapsed: true,
  predicate: () => process.env.NODE_ENV !== 'production',
})

const enhancers = applyMiddleware(
  thunk,
  save({
    states: ['tradeIn', 'credit', 'form', 'benefits', 'car', 'saved_sc', 'leasing', 'accessories'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  }),
  tempSyncCreditMiddleware,
  updatedEventEmitter,
  // logger,
)

const createStoreWithMiddleware = composeEnhancers(enhancers)(createStore)

const store = createStoreWithMiddleware(
  rootReducer,
  load({
    states: ['tradeIn', 'credit', 'form', 'benefits', 'car', 'saved_sc', 'leasing', 'accessories'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
    disableWarnings: true,
    preloadedState: {
      form: { ...FORM_INIT_STATE },
      tradeIn: { ...TRADE_IN_INIT_STATE },
    },
  }),
)

// Отправляем данные в трейд-ин OP
let currentTradeInState
store.subscribe(
  _throttle(() => {
    let previousTradeInState = currentTradeInState

    currentTradeInState = store.getState().tradeIn

    if (previousTradeInState !== currentTradeInState && currentTradeInState !== rootReducer.tradeIn) {
      document.dispatchEvent(new CustomEvent(SMART_CONTRACT_TRADE_IN_UPDATED_EVENT, { detail: currentTradeInState }))
    }
  }, 300),
)

export default store
