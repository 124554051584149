import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectDealershipsHelper = (preset, detail) => {
  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.dealerships()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.dealerships) {
    if (typeof detail.dealerships === 'string') {
      return detail.dealerships
    } else if (typeof detail.dealerships === 'function') {
      return detail.dealerships()
    }
  }

  return ''
}
