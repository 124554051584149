import { CREDIT_SETTINGS_BEHAVIOR, CREDIT_STATUS_NONE, FINANCE_USER_BEHAVIOUR } from '../../constants'
import { CREDIT_TYPES } from '../types'

const INIT_STATE = {
  behaviour: CREDIT_SETTINGS_BEHAVIOR.DISABLED,
  behaviourByUser: FINANCE_USER_BEHAVIOUR.NONE,
  benefitValue: 0,
  disableBuybackPayment: undefined,

  partner: '',

  parameters: {
    initialFee: 0,
    initialFeePercentage: 0,

    buybackPayment: 0,
    buybackPaymentPercentage: 0,

    period: 0,

    life_insurance: null,
    kasko_insurance: null,
  },

  periods: [], // массив объектов значение + перевод

  active_program: {
    id: '',
  },

  status: CREDIT_STATUS_NONE,

  programs: [],

  condition: '',

  onlineApprove: false,

  placeholder: null,

  defaultConditions: {
    12: null,
    24: null,
    36: null,
    48: null,
    60: null,
    72: null,
    84: null,
  },

  defaultSettingsAvailable: false,

  promoProgram: {
    enabled: false,
    isPromoProgramExistsInAllPrograms: false,
    identity: '',
    parameters: {
      defaultInitialFeePercentage: 0,
      defaultBuybackPaymentPercentage: 0,
      defaultPeriod: 0,
    },
    screenSettings: {
      title: '',
      subtitle: '',
      text: '',
      link: '',
    },
  },
}

const credit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREDIT_TYPES.BOOT_CREDIT_PARAMETERS:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          initialFee: action.initialFee,
          initialFeePercentage: action.initialFeePercentage,
          buybackPayment: action.buybackPayment,
          buybackPaymentPercentage: action.buybackPaymentPercentage,
          period: action.period,
        },
      }

    case CREDIT_TYPES.SET_CREDIT_BEHAVIOUR_BY_USER:
      return {
        ...state,
        behaviourByUser: action.payload,
      }

    case CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          buybackPayment: action.buybackPayment,
          buybackPaymentPercentage: action.buybackPaymentPercentage,
        },
      }

    case CREDIT_TYPES.SET_LIFE_INSURANCE:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          life_insurance: action.value,
        },
      }

    case CREDIT_TYPES.SET_KASKO_INSURANCE:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          kasko_insurance: action.value,
        },
      }

    case CREDIT_TYPES.SET_CREDIT_INITIAL_FEE:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          initialFee: action.initialFee,
          initialFeePercentage: action.initialFeePercentage,
        },
      }

    case CREDIT_TYPES.SET_CREDIT_PERIOD:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          period: action.period,
        },
      }
    case CREDIT_TYPES.SET_CREDIT_PARAMS:
      return {
        ...state,
        partner: action.partner,
        behaviour: action.behaviour,
      }
    case CREDIT_TYPES.SET_ACTIVE_PROGRAM:
      return {
        ...state,
        active_program: action.active_program,
      }
    case CREDIT_TYPES.SET_CREDIT_STATUS:
      return {
        ...state,
        status: action.status,
      }
    case CREDIT_TYPES.SET_PROGRAMS:
      return {
        ...state,
        programs: action.programs,
      }
    case CREDIT_TYPES.SET_CREDIT_BENEFIT_VALUE:
      return {
        ...state,
        benefitValue: action.payload,
      }
    case CREDIT_TYPES.SET_CREDIT_DISABLE_BUYBACK_PAYMENT:
      return {
        ...state,
        disableBuybackPayment: action.payload,
      }
    case 'TEMP_INITFEE_PERCENT':
      return {
        ...state,
        parameters: {
          ...state.parameters,
          initialFeePercentage: action.payload,
        },
      }
    case 'TEMP_BUYBACK_PERCENT':
      return {
        ...state,
        parameters: {
          ...state.parameters,
          buybackPaymentPercentage: action.payload,
        },
      }

    case CREDIT_TYPES.SET_CONDITION:
      return {
        ...state,
        condition: action.payload,
      }

    case CREDIT_TYPES.SET_ONLINE_APPROVE:
      return {
        ...state,
        onlineApprove: action.payload,
      }

    case CREDIT_TYPES.SET_CREDIT_PLACEHOLDER:
      return {
        ...state,
        placeholder: action.payload,
      }

    case CREDIT_TYPES.SET_CREDIT_PERIODS:
      return {
        ...state,
        periods: action.payload,
      }

    case CREDIT_TYPES.SET_DEFAULT_CONDITIONS:
      return {
        ...state,
        defaultConditions: action.payload,
      }

    case CREDIT_TYPES.SET_PROMO_PROGRAM:
      return {
        ...state,
        promoProgram: action.payload,
      }

    case CREDIT_TYPES.SET_CREDIT_DEFAULT_SETTINGS_AVAILABLE:
      return {
        ...state,
        defaultSettingsAvailable: action.payload,
      }

    // case CREDIT_TYPES.SET_PROMO_PROGRAM_ENABLED:
    //   return {
    //     ...state,
    //     promoProgram: {
    //       ...state.promoProgram,
    //       enabled: action.payload,
    //     },
    //   }

    default:
      return state
  }
}

export default credit
