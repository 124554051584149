import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { CREDIT_SETTINGS_BEHAVIOR, LEASING_SETTINGS_BEHAVIOR, TRADE_IN_SETTINGS_BEHAVIOR } from '../../../constants'
import { formatCurrency, formatPrice } from '../../../helpers'
import { disableBenefit, enableBenefit } from '../../../store/actions'
import BlockState from '../../Ui/blockState'
import BlockVariant from '../../Ui/blockVariant'
import s from './benefits.module.scss'

function Benefits({
  benefits,
  tradeInBehaviourBySettings,
  creditBehaviourBySettings,
  leasingBehaviourBySettings,
  selected,
  formattedCurrency,
  additionalOptions,
  currency,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // цифра
  const blockNumber = () => {
    const isTradeIn = tradeInBehaviourBySettings !== TRADE_IN_SETTINGS_BEHAVIOR.DISABLED
    const isLeasing = leasingBehaviourBySettings !== LEASING_SETTINGS_BEHAVIOR.DISABLED
    const isCredit = creditBehaviourBySettings !== CREDIT_SETTINGS_BEHAVIOR.DISABLED
    const isFinance = isLeasing || isCredit

    if (isTradeIn && isFinance) {
      return 3
    } else if (!isTradeIn && !isFinance) {
      return 1
    } else {
      return 2
    }
  }

  // цена
  const price = item => {
    if (item.attributes.value === 0) {
      return t('free')
    }

    let prefix = ''

    if (item.attributes.value > 0) {
      prefix = '+'
    }

    return prefix + formatPrice(item.attributes.value, currency)
  }

  // включение и выключение выгоды
  const toggleBenefit = (event, benefit) => {
    const {
      target: { checked },
    } = event

    if (checked) {
      dispatch(enableBenefit(benefit, true, true, true))
    } else {
      dispatch(disableBenefit(benefit))
    }
  }

  // проверка на активность
  const isChecked = item => {
    for (const s of selected) {
      if (item.id === s.id && item.attributes.identity === s.attributes.identity) {
        return true
      }
    }

    return false
  }

  // переход по ссылке
  const goToBenefitUrl = item => {
    window.open(item.attributes.url, '_blank')
  }

  // текст ссылки
  const linkText = item => {
    return item.attributes.url_text ? item.attributes.url_text.substring(0, 20) : t('terms')
  }

  return (
    <BlockState title={t('benefits.title')} isActive={selected?.length} number={blockNumber()}>
      <div className={s.variants}>
        {benefits &&
          Array.isArray(benefits) &&
          benefits.map(benefit => {
            return (
              <div key={`benefit-${benefit.id}`} className={s.variants__item}>
                <BlockVariant
                  firstRow={benefit.attributes.name}
                  secondRow={benefit.attributes.description}
                  firstPrice={benefit.attributes.hidePrice ? false : price(benefit)}
                  secondPrice={null}
                  isWithoutCalculation={false}
                  isDisabled={false}
                  isActive={isChecked(benefit)}
                  showLink={benefit.attributes.url && benefit.attributes.url_text}
                  linkBehaviour={() => goToBenefitUrl(benefit)}
                  linkText={linkText(benefit)}
                  checkboxBehaviour={e => toggleBenefit(e, benefit)}
                  id={benefit.id}
                  type="benefit"
                />
              </div>
            )
          })}
        {Boolean(additionalOptions.cost > 0 || additionalOptions.list?.length > 0) && (
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={t('benefits.additionalOptions')}
              secondRow={
                additionalOptions.list && additionalOptions.list.length
                  ? additionalOptions.list.join(', ')
                  : t('benefits.additionalOptionsWithoutList')
              }
              firstPrice={additionalOptions.cost > 0 ? additionalOptions.cost.toLocaleString('ru-RU') : ''}
              secondPrice={null}
              isWithoutCalculation={false}
              isDisabled={true}
              isActive={true}
              showLink={false}
              linkBehaviour={() => {}}
              linkText={''}
              checkboxBehaviour={() => {}}
              id={'add-opt'}
              type="benefit"
            />
          </div>
        )}
      </div>
    </BlockState>
  )
}

function mapStateToProps({
  benefits: { benefits, selected },
  car: { additionalOptions },
  tradeIn: { behaviour: tradeInBehaviourBySettings },
  credit: { behaviour: creditBehaviourBySettings },
  leasing: { behaviour: leasingBehaviourBySettings },
  settings: { currency },
}) {
  return {
    benefits,
    selected,
    tradeInBehaviourBySettings,
    creditBehaviourBySettings,
    leasingBehaviourBySettings,
    currency,
    additionalOptions,
    formattedCurrency: formatCurrency(currency),
  }
}

export default connect(mapStateToProps)(Benefits)
