module.exports = {
  state: {
    title: 'Финансовые услуги',
    yourCarCostsMoreThanCarYouWant: {
      auto: 'Вы не можете применить кредитную программу, т.к. стоимость вашего автомобиля в Трейд-ин с учетом всех выгод и дополнительных предложений превышает стоимость приобретаемого автомобиля',
      moto: 'Вы не можете применить кредитную программу, т.к. стоимость вашего автомобиля в Трейд-ин с учетом всех выгод и дополнительных предложений превышает стоимость приобретаемого мотоцикла',
      other:
        'Вы не можете применить кредитную программу, т.к. стоимость вашего автомобиля в Трейд-ин с учетом всех выгод и дополнительных предложений превышает стоимость приобретаемого ТС',
    },
    subtitleWithBenefit: 'В кредит с преимуществом',
    subtitleWithoutBenefit: 'Покупка в кредит',
    initialAdditionalText: 'Выберите кредитную программу',
    initialAdditionalTextWithoutEstimation: 'Выберите параметры кредита',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } мес.',
    additionalTextWithCreditParameters:
      'Вы выбрали кредит с первоначальным взносом { initialFee } на срок { period } мес.',
    buyWithCash: 'Покупка за наличные',
    autoEstimationIsImpossible: 'Автоматическая оценка невозможна',
    unavailable: 'Невозможно',
    benefitHint: {
      auto: 'Это преимущество применяется в случае покупки данного автомобиля в кредит',
      moto: 'Это преимущество применяется в случае покупки данного мотоцикла в кредит',
      other: 'Это преимущество применяется в случае покупки данного ТС в кредит',
    },
    activeProgramHint: 'Кредитный платёж по выбранной вами программе',
    defaultProgramHint:
      'Минимальный кредитный платёж для программ с первоначальным взносом {initialFee}, остаточным платежом {buybackPayment} и периодом кредитования {period } мес.',
    defaultProgramNoBuybackPaymentHint:
      'Минимальный кредитный платёж для программ с первоначальным взносом {initialFee} и периодом кредитования {period } мес.',
  },
  screen: {},
  promoProgram: {
    choose: 'Выбрать',
    moreDetails: 'Подробнее',
  },
}
