import { TI_PARTNER } from '../constants'

export const tradeInStepRemains = (values, partner = TI_PARTNER.AUTO_RU) => {
  switch (partner) {
    case TI_PARTNER.AUTO_RU:
    case TI_PARTNER.MAX_POSTER:
    case TI_PARTNER.ONE_PLATFORM:
      switch (false) {
        case !!values?.brand?.id:
          return 'tradeIn.steps.tenStepsRemain'
        case !!values?.model?.id:
          return 'tradeIn.steps.nineStepsRemain'
        case !!values?.generation?.id:
          return 'tradeIn.steps.eightStepsRemain'
        case !!values?.body?.id:
          return 'tradeIn.steps.sevenStepsRemain'
        case !!values?.modification?.id:
          return 'tradeIn.steps.sixStepsRemain'
        case !!values?.year?.value:
          return 'tradeIn.steps.fiveStepsRemain'
        case !!values?.color?.id:
          return 'tradeIn.steps.fourStepsRemain'
        case !!values?.owner?.id:
          return 'tradeIn.steps.threeStepsRemain'
        case !!values?.city?.id:
          return 'tradeIn.steps.twoStepsRemain'
        case values?.mileage?.value > 0:
          return 'tradeIn.steps.homeStretch'
        default:
          return 'tradeIn.steps.tenStepsRemain'
      }

    case TI_PARTNER.MOBILE_DE:
      switch (false) {
        case !!values?.brand?.id:
          return 'tradeIn.steps.elevenStepsRemain'
        case !!values?.model?.id:
          return 'tradeIn.steps.tenStepsRemain'
        case !!values?.body?.id:
          return 'tradeIn.steps.nineStepsRemain'
        case !!values?.year?.value:
          return 'tradeIn.steps.eightStepsRemain'
        case !!values?.color?.id:
          return 'tradeIn.steps.sevenStepsRemain'
        case !!values?.owner?.id:
          return 'tradeIn.steps.sixStepsRemain'
        case !!values?.engine?.id:
          return 'tradeIn.steps.fiveStepsRemain'
        case !!values?.gearbox?.id:
          return 'tradeIn.steps.fourStepsRemain'
        case !!values?.interiorColor?.id:
          return 'tradeIn.steps.threeStepsRemain'
        case !!values?.interiorType?.id:
          return 'tradeIn.steps.twoStepsRemain'
        case values?.mileage?.value > 0:
          return 'tradeIn.steps.homeStretch'
        default:
          return 'tradeIn.steps.elevenStepsRemain'
      }

    default:
      console.error('TI partner is not defined', partner)
      return ''
  }
}
