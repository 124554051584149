import { clearSelectedBenefits, enableBenefit, setBenefits } from '../../../store/actions'
import store from '../../../store/store'

export const benefitsSetter = (benefits, dataFromLocalStorage) => {
  const idsOfSelectedBenefitsFromLocalStorage = []

  dataFromLocalStorage?.benefits?.selected.forEach(benefit => {
    idsOfSelectedBenefitsFromLocalStorage.push(benefit.id)
  })

  store.dispatch(setBenefits(benefits))
  store.dispatch(clearSelectedBenefits())

  benefits.forEach(benefit => {
    if (idsOfSelectedBenefitsFromLocalStorage.includes(benefit.id)) {
      store.dispatch(enableBenefit(benefit, false, false, false))
    }
  })
}
