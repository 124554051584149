const INIT_STATE = {
  list: [],
}

function cars(state = INIT_STATE, action) {
  switch (action.type) {
    case 'SET_CAR_LIST':
      return {
        ...state,
        list: action.payload,
      }

    case 'UPDATE_CAR_PRICES':
      return {
        ...state,
        list: action.payload,
      }

    default:
      return state
  }
}

export default cars
