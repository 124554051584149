import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import {
  TRADE_IN_DATA_FILLED,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_SETTINGS_BEHAVIOR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import {
  carPriceFromTI,
  formatCurrency,
  formatPrice,
  isTradeInByIdentityDataFilled,
  isTradeInDataFilled,
} from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { applyTradeIn, disableTradeIn, firstOpenTradeIn, goToTradeIn } from '../../../store/actions'
import BlockState from '../../Ui/blockState'
import BlockVariant from '../../Ui/blockVariant'
import s from './state.module.scss'

function TradeInState({
  tradeInBehaviourByUser,
  tradeInBehaviorBySettings,
  rate: { tradeInRateStatus },
  benefitValue,
  benefitValueWithoutTradeIn,
  values,
  partner,
  possibleCarPrice,
  currency,
  vehicleType,
  estimationType,
}) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const tradeInText = () => {
    return benefitValue < 0 ? t('trade_in.state.subtitleWithBenefit') : t('trade_in.state.subtitleWithoutBenefit')
  }

  const tradeInAdditionalText = () => {
    const { brand, model, year } = values

    const tradeInListCondition =
      estimationType === TRADE_IN_ESTIMATION_TYPE.LIST &&
      isTradeInDataFilled(values, partner) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED

    const tradeInByIdentityCondition =
      estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ &&
      isTradeInByIdentityDataFilled(values) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED

    if (tradeInListCondition) {
      return t('trade_in.state.additionalTextWithCarData', {
        brand: brand.name,
        model: model.name,
        year: year.value,
      })
    }

    if (tradeInByIdentityCondition) {
      return t('trade_in.state.additionalTextWithCarData', {
        brand: brand.name,
        model: model.name,
        year: year.value,
      })
    }

    return t('trade_in.state.initialAdditionalText')
  }

  const possibleCarPriceFormatted = () => {
    if (possibleCarPrice === 0) {
      if (
        isTradeInDataFilled(values, partner) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED &&
        tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
      ) {
        return t('trade_in.state.autoEstimationIsImpossible')
      } else {
        return ''
      }
    } else {
      return formatPrice(possibleCarPrice, currency)
    }
  }

  // TODO: Validate Trade In Amount lower than 0.95 * car.price
  // TODO: Set initial fee for credit = possible car price (if calculations success)

  const userWantsTradeInHandler = event => {
    // если нет данных для оценки, то переходим на экран ТИ
    // если есть данные для оценки, то включаем, выключаем ТИ

    const {
      target: { checked },
    } = event

    if (!checked && tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED) {
      return
    }

    if (checked && tradeInRateStatus === TRADE_IN_RATE_STATUS.INITIAL) {
      dispatch(firstOpenTradeIn())
      return
    }

    if (tradeInRateStatus !== TRADE_IN_RATE_STATUS.INITIAL) {
      if (checked) {
        dispatch(applyTradeIn(false, {}))
      } else {
        dispatch(disableTradeIn())
      }
    }
  }

  const userDoesNotWantTradeInHandler = event => {
    // устанавливаем статус, что пользователь не хочет сдать авто в ТИ
    // (если авто было оценено, то ставим статус ТИ_НАЧАТ)
    // выключаем ТИ
    const {
      target: { checked },
    } = event

    if (
      !checked &&
      [TRADE_IN_USER_BEHAVIOUR.NONE, TRADE_IN_USER_BEHAVIOUR.STARTED, TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED].includes(
        tradeInBehaviourByUser,
      )
    ) {
      return
    }

    if (checked) {
      dispatch(disableTradeIn())
    } else {
      if (tradeInRateStatus !== TRADE_IN_RATE_STATUS.INITIAL) {
        dispatch(applyTradeIn(false, {}))
      }
    }
  }

  return (
    <div>
      <BlockState
        title={t('trade_in.state.title')}
        isActive={tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED}
        number={1}
      >
        <div className={s.variants} data-project-name={getProjectName()}>
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={tradeInText()}
              secondRow={tradeInAdditionalText()}
              firstPrice={benefitValue < 0 ? formatPrice(benefitValue, currency) : null}
              secondPrice={possibleCarPriceFormatted()}
              tooltipContent={t(`trade_in.state.benefitHint.${vehicleType}`)}
              isWithoutCalculation={
                tradeInBehaviorBySettings === TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS
              }
              isActive={tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED}
              isDisabled={false}
              showLink={tradeInRateStatus !== TRADE_IN_RATE_STATUS.INITIAL}
              linkBehaviour={() => dispatch(goToTradeIn())}
              linkText={t('edit')}
              checkboxBehaviour={e => userWantsTradeInHandler(e)}
              id="buyCarWithTradeIn"
              type="calculation"
              currency={currency}
            />
          </div>
          <div className={s.variants__item}>
            <BlockVariant
              firstRow={t('trade_in.state.withoutTradeIn')}
              secondRow={null}
              firstPrice={benefitValueWithoutTradeIn < 0 ? formatPrice(benefitValueWithoutTradeIn, currency) : null}
              secondPrice={null}
              tooltipContent={t('summary.tradeInBenefitWithoutTradeInHint')}
              isWithoutCalculation={false}
              isActive={tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED}
              isDisabled={false}
              showLink={false}
              linkBehaviour={() => false}
              linkText={''}
              checkboxBehaviour={e => userDoesNotWantTradeInHandler(e)}
              id="userDoesNotWantTradeIn"
              type="calculation"
            />
          </div>
        </div>
      </BlockState>
    </div>
  )
}

function mapStateToProps(state) {
  const {
    tradeIn: {
      behaviour: tradeInBehaviorBySettings,
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue,
      benefitValueWithoutTradeIn,
      values,
      rate,
      ownerCarPriceEnabled,
      ownerCarPrice,
      partner,
      estimationType,
    },
    settings: { currency, vehicleType },
  } = state

  return {
    possibleCarPrice: carPriceFromTI(rate.result.possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled),
    values,
    currency,
    tradeInBehaviourByUser,
    tradeInBehaviorBySettings,
    benefitValue,
    benefitValueWithoutTradeIn,
    partner,
    estimationType,

    rate: {
      tradeInRateStatus: rate.status,
    },
    formattedCurrency: formatCurrency(currency),
    vehicleType,
  }
}

export default connect(mapStateToProps)(TradeInState)
