import { isMobile } from 'react-device-detect'

import {
  EVENT_TYPE_AVN_FORM_REPLACED,
  EVENT_TYPE_GO_TO_CREDIT_SCREEN_FROM_PROMO_PROGRAM_SCREEN,
  EVENT_TYPE_GO_TO_LINK_FROM_PROMO_PROGRAM_SCREEN,
  EVENT_TYPE_GO_TO_PROMO_PROGRAM_SCREEN,
  EVENT_TYPE_WIDGET_BENEFIT_SELECTED,
  EVENT_TYPE_WIDGET_BENEFIT_UNSELECTED,
  EVENT_TYPE_WIDGET_CLOSED,
  EVENT_TYPE_WIDGET_CREDIT_CALC_OPEN,
  EVENT_TYPE_WIDGET_GO_TO_ONLINE_PAYMENT,
  EVENT_TYPE_WIDGET_GO_TO_SMART_CONTRACT,
  EVENT_TYPE_WIDGET_LEASING_CALC_OPEN,
  EVENT_TYPE_WIDGET_OPEN,
  EVENT_TYPE_WIDGET_OPEN_TIME,
  EVENT_TYPE_WIDGET_TRADE_IN_OPEN,
} from '../../constants'
import { pushEvent } from '../../helpers/requests'
import { setWidgetOpenStart } from './index'

const handleWidgetOpen = data => {
  function createCustomData(start, end) {
    const customData = {
      isMobile,
      time: end - start,
    }

    data.customData = customData

    pushEvent(data)
    pushEvent({
      ...data,
      eventType: EVENT_TYPE_WIDGET_OPEN_TIME,
    })
  }

  const start = Date.now()

  const observer = new MutationObserver((mutations, instance) => {
    const panel = document.getElementById('kdxsc_panel')

    if (panel) {
      instance.disconnect()
      const end = Date.now()
      return createCustomData(start, end)
    }
  })

  observer.observe(document, {
    childList: true,
    subtree: true,
  })
}

const event = (eventType, benefitData) => (dispatch, getState) => {
  const state = getState()

  const data = {
    eventType,
    widgetId: state.settings.widget_id,
    uniqueCode: state.settings.uniqueCode,
    smartContractId: state.saved_sc.id,
    customTags: state.settings.customTags,
    benefitData,
  }

  if (eventType === EVENT_TYPE_WIDGET_OPEN) {
    const start = state.screen.widgetOpenStart

    if (start > 0) {
      data.customData = {
        isMobile,
        time: Date.now() - start,
      }

      dispatch(setWidgetOpenStart(0))
    } else {
      handleWidgetOpen(data)
      return
    }
  }

  if (eventType === EVENT_TYPE_WIDGET_OPEN) {
    pushEvent({
      ...data,
      eventType: EVENT_TYPE_WIDGET_OPEN_TIME,
    })
  }
  pushEvent(data)
}

export const goToOnlinePaymentFromWidget = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_GO_TO_ONLINE_PAYMENT))
export const goToSmartContractFromWidget = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_GO_TO_SMART_CONTRACT))
export const widgetClosed = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_CLOSED))
export const widgetOpened = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_OPEN))
export const tradeInOpened = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_TRADE_IN_OPEN))
export const creditOpened = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_CREDIT_CALC_OPEN))
export const leasingOpened = () => dispatch => dispatch(event(EVENT_TYPE_WIDGET_LEASING_CALC_OPEN))
export const benefitSelected = payload => dispatch => dispatch(event(EVENT_TYPE_WIDGET_BENEFIT_SELECTED, payload))
export const benefitUnselected = payload => dispatch => dispatch(event(EVENT_TYPE_WIDGET_BENEFIT_UNSELECTED, payload))
export const avnFormWasReplaced = payload => dispatch => dispatch(event(EVENT_TYPE_AVN_FORM_REPLACED, payload))

// промо программа
export const goToPromoProgramScreen = () => dispatch => dispatch(event(EVENT_TYPE_GO_TO_PROMO_PROGRAM_SCREEN))
export const goToCreditScreenFromPromoProgramScreen = () => dispatch =>
  dispatch(event(EVENT_TYPE_GO_TO_CREDIT_SCREEN_FROM_PROMO_PROGRAM_SCREEN))
export const goToLinkFromPromoProgramScreen = () => dispatch =>
  dispatch(event(EVENT_TYPE_GO_TO_LINK_FROM_PROMO_PROGRAM_SCREEN))
