import { formatPrice } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import Tooltip from '../tooltip'
import s from './carPrice.module.scss'

function CarPrice({ type, text, price, currency, strikethrough, tooltipContent }) {
  return (
    <div
      className={[s.wrapper, type === 'bordered' ? s.wrapper_bordered : s.wrapper_filled].join(' ')}
      id={strikethrough ? 'header_carPrice_strike' : 'header_carPrice'}
      data-project-name={getProjectName()}
    >
      <div className={s.wrapper__prices}>
        <div className={s.wrapper__text}>{text}</div>
        <div className={s.wrapper__price}>
          <div className={[s.price, strikethrough ? s.price_old : ''].join(' ')}>
            <div className={s.price__value}>{formatPrice(price, currency)}</div>
            {/*<div className={s.price__currency}>{currency}</div>*/}
          </div>
        </div>
      </div>
      {tooltipContent && (
        <div className={s.wrapper__tooltip}>
          <Tooltip position={strikethrough ? 'bottom' : 'left'} fullHeight={true} tooltipContent={tooltipContent}>
            <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
                fill={getProjectName() !== 'generic_v2' ? '#222222' : '#828282'}
              />
            </svg>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default CarPrice
