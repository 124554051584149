const INIT_STATE = {
  previousScreen: '',
  previousAction: '',
  additionalData: {},
}

const data_before_auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_PREVIOUS_SCREEN':
      return {
        ...state,
        previousScreen: action.screen,
      }
    case 'SET_PREVIOUS_ACTION':
      return {
        ...state,
        previousAction: action.action,
      }
    case 'SET_ADDITIONAL_DATA':
      return {
        ...state,
        additionalData: action.additionalData,
      }

    default:
      return state
  }
}

export default data_before_auth
