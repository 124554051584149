import { createRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

function Bubble({ clonedElement, tooltipData, vehicleType }) {
  const refTooltip = createRef()
  const refClose = createRef()

  const { t } = useTranslation()

  const handleResize = useCallback(() => {
    if (refTooltip && refTooltip.current) {
      refTooltip.current.style.width = clonedElement.clientWidth + 'px'
    }
  }, [refTooltip, clonedElement.clientWidth])

  useEffect(() => {
    refTooltip.current.style.width = clonedElement.clientWidth < 276 ? '276px' : `${clonedElement.clientWidth}px`

    window.addEventListener('resize', handleResize)

    refClose.current.style.background = tooltipData.palette.buttonColor
    refClose.current.style.color = tooltipData.palette.buttonTextColor
  }, [clonedElement.clientWidth, handleResize, refClose, refTooltip, tooltipData.palette])

  const closeTooltip = () => {
    refTooltip.current.remove()
  }

  const headerText = () => {
    return tooltipData.tooltip.header_text || t('tooltip.defaultTitle')
  }

  const showElements = () => {
    return automaticItems().length || customItems().length
  }

  const tradeInAndCreditText = () => {
    if (tooltipData.credit && tooltipData.trade_in) {
      return t('tooltip.tradeInWithCredit')
    }

    if (!tooltipData.credit && tooltipData.trade_in) {
      return t('tooltip.tradeInWithoutCredit')
    }

    if (tooltipData.credit && !tooltipData.trade_in) {
      return t('tooltip.creditWithoutTradeIn')
    }

    return ''
  }

  const automaticItems = () => {
    const result = []

    if (tooltipData.benefits) {
      result.push(t('tooltip.benefits'))
    }

    if (tradeInAndCreditText()) {
      result.push(tradeInAndCreditText())
    }

    if (tooltipData.online_payment) {
      result.push(t(`tooltip.onlinePayment.${vehicleType}`))
    }

    return result
  }

  const customItems = () => {
    return tooltipData.tooltip.body_text
  }

  const listGenerator = items => {
    return items.map((item, index) => {
      return (
        <li className="kdxsc-attention-body-list__item" key={index}>
          {item}
        </li>
      )
    })
  }

  const list = () => {
    if (tooltipData.tooltip.body_text_type === 'both') {
      const bothItems = [...customItems(), ...automaticItems()]

      return listGenerator(bothItems)
    }

    if (tooltipData.tooltip.body_text_type === 'custom' && customItems().length) {
      return listGenerator(customItems())
    }

    return listGenerator(automaticItems()) // Возврат автоматических строк происходит в любом случае, если не установлено или выбрано automatic
  }

  return (
    <div className="kdxsc-attention" ref={refTooltip}>
      <div className="kdxsc-attention__header">{headerText()}</div>
      <div className="kdxsc-attention__body kdxsc-attention-body">
        {showElements && <ul className="kdxsc-attention-body__list kdxsc-attention-body-list">{list()}</ul>}
      </div>
      <div className="kdxsc-attention__footer kdxsc-attention-footer">
        {tooltipData.tooltip && tooltipData.tooltip.video_url && (
          <a
            className="kdxsc-attention-footer__video kdxsc-attention-footer-video"
            href={tooltipData.tooltip.video_url}
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <div className="kdxsc-attention-footer-video__img" />
            <div className="kdxsc-attention-footer-video__text">{t('tooltip.watchVideo')}</div>
          </a>
        )}
        <div className="kdxsc-attention-footer__button" onClick={closeTooltip}>
          <div className="kdxsc-attention-close" ref={refClose}>
            {t('tooltip.closeButtonText')}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ settings: { vehicleType } }) {
  return { vehicleType }
}

export default connect(mapStateToProps)(Bubble)
