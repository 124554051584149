export const online_payment_button_text_formatter = (text, amount) => {
  const placeholders = {
    amount,
  }

  let result = text

  for (const key in placeholders) {
    result = result.replace(new RegExp('\\${' + key + '}', 'g'), placeholders[key])
  }

  return result
}
