module.exports = {
  state: {
    subtitleWithBenefit: 'Leasing purchase with benefit',
    subtitleWithoutBenefit: 'Leasing purchase with benefit',
    initialAdditionalText: 'Choose leasing program',
    initialAdditionalTextWithoutEstimation: 'Choose leasing parameters',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } mo.',
    additionalTextWithCreditDataWithoutRate: '{ bank }. { program }, { period } mo.',
    additionalTextWithCreditDataWithoutRateAndBankName: '{ program }, { period } mo.',
    additionalTextWithCreditDataWithoutBankName: '{ program }, { rate }%, { period } mo.',
    additionalTextWithCreditParameters:
      'You have chosen a leasing with a { initialFee } deposit for a term of agreement of { period } mo.',
    autoEstimationIsImpossible: 'Automatic estimation is not possible',
    unavailable: 'Unavailable',
    benefitHint: {
      auto: 'This advantage applies in the case of buying this car on leasing',
      moto: 'This advantage applies in the case of buying this motorcycle on leasing',
      other: 'This advantage applies in the case of buying this vehicle on leasing',
    },
  },

  screen: {
    title: 'Leasing parameters in the amount of {amount}',
    subtitle: 'Your leasing benefit of { value } has already been taken into account',
    chooseCreditProgram: 'Choose leasing program ({count} found)',
    carPrice: {
      auto: 'Car price including benefits and services',
      moto: 'Motorcycle price including benefits and services',
      other: 'Vehicle price including benefits and services',
    },
    initialFee: 'Advance payment',
    buybackPayment: 'Trade-in price',
    term: 'Leasing term',

    withoutCalculations: {
      title: 'You can choose the best leasing conditions for you',
      button: 'Apply',
      body: 'Unfortunately, the automatic calculation of leasing programs is not available, but the data you entered will be transferred to the manager to form an offer',
    },

    noPrograms: {
      title: 'Unfortunately, no programs were found',
      body: 'Please, select other parameters',
    },

    error: {
      title: 'Unfortunately, an error occurs during programs search',
      body: 'Please try later or select other options',
    },
  },
}
