import _cloneDeep from 'lodash/cloneDeep'
import { createSelector } from 'reselect'

import { creditAmount, parseNumber } from '../../helpers'

const carPriceSelector = state => state.car.price
const identitySelector = state => state.car.identity
const selectedBenefitsSelector = state => state.benefits.selected
const selectedAccessoriesSelector = state => state.accessories.selected
const tradeInSelector = state => state.tradeIn
const creditParamsSelector = state => state.credit.parameters
const creditBenefitSelector = state => state.credit.benefitValue
const carModelDataSelector = state => state.car.modelData
const carProductTypeSelector = state => state.car.productType

export const createCreditCalculateProgramsPayload = createSelector(
  [
    carPriceSelector,
    identitySelector,
    selectedBenefitsSelector,
    selectedAccessoriesSelector,
    tradeInSelector,
    creditParamsSelector,
    creditBenefitSelector,
    carModelDataSelector,
    carProductTypeSelector,
  ],
  (
    carPrice,
    identity,
    selectedBenefits,
    selectedAccessories,
    tradeIn,
    creditParams,
    creditBenefit,
    carModelData,
    carProductType,
  ) => {
    const amount = creditAmount({
      price: carPrice,
      selectedBenefits,
      selectedAccessories,
      tradeInUserBehaviour: tradeIn.behaviourByUser,
      tradeInBenefitAmount: tradeIn.benefitValue,
      tradeInBenefitAmountWithoutTradeIn: tradeIn.benefitValueWithoutTradeIn,
      creditBenefitAmount: creditBenefit,
    })
    const base = _cloneDeep(creditParams)

    base.price = amount
    base.period = parseInt(base.period) || 0
    base.initial_fee_amount = parseNumber(base.initialFee)
    base.initial_fee_percentage = parseInt(base.initialFeePercentage) || 0
    base.buyback_payment_amount = parseNumber(base.buybackPayment)
    base.buyback_payment_percentage = parseInt(base.buybackPaymentPercentage) || 0
    base.model_data = carModelData
    base.productType = carProductType
    base.identity = identity

    delete base.buybackPaymentPercentage
    delete base.initialFeePercentage
    delete base.initialFee
    delete base.buybackPayment

    return base
  },
)
