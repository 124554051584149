import i18next from 'i18next'

import {
  CREDIT_STANDALONE_SCREEN,
  FORM_SOURCE,
  FORM_STATE,
  SCREEN_ERROR,
  TRADE_IN_STANDALONE_SCREEN,
} from '../constants'
import {
  goToScreen,
  setCaptchaError,
  setCaptchaSettings,
  setFormValue,
  setLimitForSaving,
  setSmsCodeError,
  setSmsCodeSettings,
  setTradeInStandaloneScreen,
} from '../store/actions'
import { setError, setScreen } from '../store/actions/credit_standalone'
import store from '../store/store'
import { refreshProtection } from './requests'

/**
 * Следующее действие после ошибки
 * @param formSource
 * @param callbackAction
 * @param additional
 */
const errorProcessing = (formSource, callbackAction, additional) => {
  if ([FORM_SOURCE.WIDGET, FORM_SOURCE.ONLINE_PAYMENT, FORM_SOURCE.WIDGET_AUTH].includes(formSource)) {
    store.dispatch(goToScreen(SCREEN_ERROR))
  }

  if ([FORM_SOURCE.CAR_ORDER, FORM_SOURCE.BUY_MODEL].includes(formSource)) {
    callbackAction(FORM_STATE.ERROR)
  }

  if (formSource === FORM_SOURCE.TRADE_IN_STANDALONE) {
    callbackAction(true)
  }

  if ([FORM_SOURCE.CREDIT_STANDALONE, FORM_SOURCE.LEASING_STANDALONE].includes(formSource)) {
    store.dispatch(setError(additional?.errorText || i18next.t('ccS.actions.error')))
  }
}

/**
 * Что делать после капчи/смс для стэндэлонов покупки машин
 * @param callbackAction
 */
const nextActionForStandaloneCarForms = callbackAction => {
  callbackAction(FORM_STATE.FORM)
}

/**
 * Что делать после капчи/смс для ТИ стэндэлона
 * @param callbackAction
 * @param additional
 */
const nextActionForStandaloneTradeIn = ({ callbackAction, additional }) => {
  const savingStep = additional?.tradeInStandaloneStep

  if (savingStep === 'saveScreen') {
    store.dispatch(setFormValue('agreement', true))
    store.dispatch(setTradeInStandaloneScreen(TRADE_IN_STANDALONE_SCREEN.PERSONAL_DATA_FORM))
  }
}

/**
 * Что делать после капчи/смс для КК/ЛК стэндэлона
 */
const nextActionForStandaloneCreditAndLeasing = ({ additional }) => {
  const savingStep = additional?.creditStandaloneStep
  if (savingStep === 'programs') {
    store.dispatch(setScreen(CREDIT_STANDALONE_SCREEN.PERSONAL_DATA))
  }
}

/**
 * Обработка ошибок, связанных с капчей, смс, превышением лимита
 * @param e - ошибка с БЭ, обязательный параметр
 * @param formSource - источник формы, обязательный параметр
 * @param callbackAction - что делать в случае ошибки (не обязательный параметр)
 * @param additional - дополнительная информация (не обязательный параметр, для ТИ стэндэлона показывает шаг сохранения СК (2 или 3))
 */
export const initializeErrorsProcessing = ({ e, formSource, callbackAction, additional }) => {
  const errorStatus = e?.status

  const isStandaloneCarForm = [FORM_SOURCE.CAR_ORDER, FORM_SOURCE.BUY_MODEL].includes(formSource)
  const isTradeInStandalone = formSource === FORM_SOURCE.TRADE_IN_STANDALONE
  const isCreditOrLeasingStandalone = [FORM_SOURCE.CREDIT_STANDALONE, FORM_SOURCE.LEASING_STANDALONE].includes(
    formSource,
  )

  if (!formSource || !e || !errorStatus) {
    errorProcessing(formSource, callbackAction)
    return
  }

  if (errorStatus === 500) {
    errorProcessing(formSource, callbackAction)
    return
  }

  e.json().then(body => {
    if (!body?.errors) {
      errorProcessing(formSource, callbackAction)
      return
    }

    // clearSmsAndCaptcha()

    const errorCode = body?.errors[0]?.code
    const errorStatus = body?.errors[0]?.status
    const errorValidator = body?.errors[0]?.fields?.ticket?.data?.attributes?.verifyType

    const needCaptcha =
      errorCode === 'CORE_SMART_CONTRACT_NEED_VALIDATE' && errorValidator === 'captcha' && errorStatus === 403
    const needSms = errorCode === 'CORE_SMART_CONTRACT_NEED_VALIDATE' && errorValidator === 'sms' && errorStatus === 403
    const tooManySC = errorCode === 'CORE_SMART_CONTRACT_CREATING_LIMIT_EXCEEDED' && errorStatus === 403
    const captchaError =
      errorCode === 'CORE_SMART_CONTRACT_VERIFICATION_TICKET_VALIDATE_WRONG_ANSWER' &&
      body?.errors[0]?.fields?.validator === 'captcha' &&
      errorStatus === 403 // 403
    const smsError =
      errorCode === 'CORE_SMART_CONTRACT_VERIFICATION_TICKET_VALIDATE_WRONG_ANSWER' &&
      body?.errors[0]?.fields?.validator === 'sms' &&
      errorStatus === 403 // 403

    if (needSms) {
      store.dispatch(
        setSmsCodeSettings(formSource, {
          visible: true,
          timeToResend: getTimeToResend(body?.errors[0]?.fields?.ticket?.data.attributes),
          ticketId: body?.errors[0]?.fields?.ticket?.data?.id,
        }),
      )
      if (isStandaloneCarForm) {
        nextActionForStandaloneCarForms(callbackAction)
      }

      if (isTradeInStandalone) {
        nextActionForStandaloneTradeIn({ callbackAction, additional })
      }

      if (isCreditOrLeasingStandalone) {
        nextActionForStandaloneCreditAndLeasing({ additional })
      }

      return
    }

    if (needCaptcha) {
      store.dispatch(
        setCaptchaSettings(formSource, {
          visible: true,
          captchaSrc: body?.errors[0]?.fields?.ticket?.data?.attributes?.question,
          ticketId: body?.errors[0]?.fields?.ticket?.data?.id,
        }),
      )
      if (isStandaloneCarForm) {
        nextActionForStandaloneCarForms(callbackAction)
      }
      if (isTradeInStandalone) {
        nextActionForStandaloneTradeIn({ callbackAction, additional })
      }
      if (isCreditOrLeasingStandalone) {
        nextActionForStandaloneCreditAndLeasing({ additional })
      }
      return
    }

    if (smsError) {
      store.dispatch(setFormValue('smsCode', ''))
      store.dispatch(setSmsCodeError(formSource, true))

      refreshProtection(body?.errors[0]?.fields?.ticketId)
        .then(r => {
          store.dispatch(
            setSmsCodeSettings(formSource, {
              visible: true,
              timeToResend: getTimeToResend(body?.errors[0]?.fields?.ticket?.data.attributes),
              ticketId: r?.data?.id,
            }),
          )

          if (isStandaloneCarForm) {
            nextActionForStandaloneCarForms(callbackAction)
          }
          if (isTradeInStandalone) {
            nextActionForStandaloneTradeIn({ callbackAction, additional })
          }
          if (isCreditOrLeasingStandalone) {
            nextActionForStandaloneCreditAndLeasing({ additional })
          }
        })
        .catch(() => {
          errorProcessing(formSource, callbackAction)
        })
      return
    }

    if (captchaError) {
      store.dispatch(setFormValue('captcha', ''))
      store.dispatch(setCaptchaError(formSource, true))

      store.dispatch(
        setCaptchaSettings(formSource, {
          visible: true,
          captchaSrc: body?.errors[0]?.fields?.ticket?.data?.attributes?.question,
          ticketId: body?.errors[0]?.fields?.ticket?.data?.id,
        }),
      )
      if (isStandaloneCarForm) {
        nextActionForStandaloneCarForms(callbackAction)
      }
      if (isTradeInStandalone) {
        nextActionForStandaloneTradeIn({ callbackAction, additional })
      }
      if (isCreditOrLeasingStandalone) {
        nextActionForStandaloneCreditAndLeasing({ additional })
      }
      return
    }

    if (tooManySC) {
      store.dispatch(setLimitForSaving(formSource, 7))
      errorProcessing(formSource, callbackAction, additional)
      return
    }

    errorProcessing(formSource, callbackAction)
  })
}

/**
 * Когда можно обновить код из СМС
 * @param attributes
 * @returns {Date}
 */
export const getTimeToResend = attributes => {
  let d = attributes?.refreshAt
  if (d) {
    return new Date(d)
  }

  const currentDate = new Date()
  return new Date(currentDate.getTime() + 3 * 60000)
}
