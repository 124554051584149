module.exports = {
  isPriceHiddenHint: 'Unfortunately, the calculation is not possible at the moment',
  generalError: {
    c: 'Credit calculator initialization error: no cars data',
    l: 'Leasing calculator initialization error: no cars data',
  },

  title: {
    c: 'CREDIT CALCULATOR',
    l: 'LEASING CALCULATOR',
  },
  dealerships: {
    dealership: 'Dealership',
    chooseDealership: 'Choose dealership',
  },
  dealershipsCity: {
    dealershipCity: 'City',
    any: 'Any',
    chooseCity: 'Choose city',
  },
  conditions: {
    title: {
      c: 'Select credit terms',
      l: 'Select leasing terms',
    },
    model: 'Car model',
    brand: 'Car brand',
    chooseModel: 'Choose car model',
    chooseBrand: 'Choose car brand',
    complectation: 'Car complectation',
    chooseComplectation: 'Choose car complectation',
    notFound: 'Found nothing',
    price: 'Car price',
    initialFee: {
      c: 'Initial fee',
      l: 'Advance payment',
    },
    buybackPayment: 'Buyback payment',
    buybackPaymentHint:
      'The residual payment is the amount as a percentage of the value of the vehicle, the payment of which is carried over to the end of the loan term',
    pickACredit: {
      c: 'Calculate a credit',
      l: 'Calculate a leasing',
    },
    period: {
      c: 'Credit period',
      l: 'Leasing period',
    },
  },

  personal: {
    title: {
      c: 'Enter your personal data to show credit offer',
      l: 'Enter your personal data to show leasing offer',
    },
    goBackButton: 'Go back',
    showProgramsButton: 'Show suitable programs',
    noDataText: {
      c: 'To display a personal credit offer, we need your data and consent to communication',
      l: 'To display a personal leasing offer, we need your data and consent to communication',
    },
    yourSuitableProgramsP1: '',
    yourSuitableProgramsP2: '',
    yourSuitableProgramsP1V1: {
      c: 'credit programs are suitable for you',
      l: 'leasing programs are suitable for you',
    },
    yourSuitableProgramsP1V2: {
      c: 'credit program is suitable for you',
      l: 'leasing program is suitable for you',
    },
    yourSuitableProgramsP2V1: {
      c: 'more credit programs from this bank',
      l: 'more leasing programs from this bank',
    },
    yourSuitableProgramsP2V2: {
      c: 'more credit program from this bank',
      l: 'more leasing program from this bank',
    },
    yourSuitableProgramsP2V3: {
      c: 'more credit program from this bank',
      l: 'more leasing program from this bank',
    },
    yourSuitableProgramsP3V1: '',
    yourSuitableProgramsP3V2: '',
    yourSuitableProgramsP3V3: '',
  },

  programs: {
    error: 'You need to select a program',
    view: 'View',
    continue: 'Continue',
    hide: 'Hide',
    bankName: 'Bank name',
  },

  actions: {
    autoCalculationDisabled: 'Automatic calculation is possible. Try to change your selected conditions',
    autoCalculationDisabledTechError:
      'Automatic calculation is not possible at the moment. Please try to use the calculator later',
    weSavedYourConditions: 'We have saved your terms ✓',
    managerWillContactYou: 'Manager will contact you shortly',
    error: 'An error has been occurred.  Please try to use the calculator later',
  },

  popupCreditGeneralError: 'Unfortunately, an error has been occurred while loading',
}
