import { load } from 'redux-localstorage-simple'

import { FORM_SOURCE } from '../../../constants'
import { setDisableSmartContractLink, setDisableSmartContractLogo } from '../../../store/actions'
import { setInitConfig } from '../../../store/actions/credit_standalone'
import store from '../../../store/store'
import { bootPhoneCountriesData, clearProtections } from './bootMainInfo'
import { paletteSetter } from './palette'
import { periodsFormatter } from './periods'

export const creditStandaloneLoader = data => {
  try {
    const {
      buybackPaymentPercentage,
      clientModels,
      condition,
      currency,
      gapInsurance,
      initialFeePercentage,
      kaskoInsurance,
      lifeInsurance,
      locale,
      onlineApprove,
      period,
      splitCarSelect,
      showProgramsBeforePersonalData,
      visual,
      widgetId,
      possiblePeriods,
      disableSmartContractLink,
      disableSmartContractLogo,
      defaultCountryCode,
      possibleCountryCodes,
      priceToolTipEnabled,
      defaultSettingsAvailable,
      disableBuybackPayment,
      hideBuybackPaymentForAllWidgets,
    } = data

    const dataFromLocalStorage = load({
      states: ['form'],
      namespace: '@kodix/smartContract',
      namespaceSeparator: '/',
    })

    bootPhoneCountriesData(dataFromLocalStorage, defaultCountryCode, possibleCountryCodes)

    let payload = {
      widgetId: widgetId,
      currency: currency,
      locale: locale,
      initFeePercent: String(initialFeePercentage),
      term: String(period),
      cars: clientModels,
      useCustomDisclaimer: visual?.disclaimers?.use_custom_disclaimer,
      customDisclaimerText: visual?.disclaimers?.custom_disclaimer_text,
      agreementText: visual?.agreements?.personalDataProcessing?.baseFormText,
      agreements: visual.agreements,
      createSmartContractText: visual.buttons.create_smart_contract.text,
      palette: visual.palette,
      fields: visual.fields,
      videoUrl: visual.tooltip.video_url,
      isPriceHidden: visual.isPriceHidden,
      condition,
      splitCarSelect,
      showProgramsBeforePersonalData,
      possiblePeriods: periodsFormatter(possiblePeriods),
      priceToolTipEnabled,
      defaultSettingsAvailable,
      disableBuybackPayment,
      hideBuybackPaymentForAllWidgets,
    }

    const isLeasing = store.getState().credit_standalone.module.isLeasing

    if (!isLeasing) {
      payload = {
        ...payload,
        buybackPercent: String(buybackPaymentPercentage),
        lifeInsurance: lifeInsurance,
        kaskoInsurance: kaskoInsurance,
        gapInsurance: gapInsurance,
        creditOnlineApprove: onlineApprove,
      }
    } else {
      payload = {
        ...payload,
        hideProgramBankName: visual.leasing.hideProgramBankName,
        hideProgramPercentage: visual.leasing.hideProgramPercentage,
        showRemainingPaymentInProgram: visual.leasing.showRemainingPaymentInProgram,
      }
    }

    paletteSetter(visual.palette)
    store.dispatch(setInitConfig(payload))
    store.dispatch(setDisableSmartContractLink(disableSmartContractLink))
    store.dispatch(setDisableSmartContractLogo(disableSmartContractLogo))

    if (isLeasing) {
      clearProtections(FORM_SOURCE.LEASING_STANDALONE)
    } else {
      clearProtections(FORM_SOURCE.CREDIT_STANDALONE)
    }
  } catch (e) {
    console.error('[smart.contract] unable to calculator', e)
  }
}
