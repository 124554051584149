import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { bootBenefit, encodeQueryData, modelDataParser, updateTradeInCar } from '../../../helpers'
import {
  setActiveClientModel,
  setCarName,
  setTradeInBenefitValue,
  setTradeInCondition,
  setTradeInStandaloneCar,
  startLoading,
  stopLoading,
}        from '../../../store/actions'
import s from './styles/input.module.scss'

function LineSelect({ label, disabled, models, activeModel, widgetId, idSC, genericIdSC, typeSC, tokenSC }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const [, setHasErrorWhileSavingSC] = useState(false)

  const disableSCLink = useSelector(state => state.settings.disableSmartContractLink)
  const isSCSavedFromThisWidget = Boolean(idSC) && typeSC === 'tradeInStandalone'

  const select = useRef(null)
  const dropdown = useRef(null)

  const handleSelectClick = () => {
    setIsOpen(true)
  }

  // закрывать дропдаун на клики за его пределами
  const handleOutsideClick = event => {
    if (dropdown && dropdown.current && dropdown.current.contains(event.target)) return
    if (select.current.contains(event.target)) return
    setIsOpen(false)
  }

  const handleOutsideKey = event => {
    if (event.key === 'Escape') {
      setIsOpen(false)
      select.current.focus()
    }
  }

  const closeAfterCarWasSet = event => {
    setIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('CarWasSet', closeAfterCarWasSet)

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
      window.removeEventListener('CarWasSet', closeAfterCarWasSet)
    }
  }, [isOpen])

  const selectClasses = () => {
    let c = [s.dropdown__trigger]

    if (isOpen) {
      c.push(s.dropdown__trigger_opened)
    }

    return c.join(' ')
  }

  const iconClasses = () => {
    let c = [s.dropdownTrigger__icon]

    if (isOpen) {
      c.push(s.dropdownTrigger__icon_opened)
    }

    return c.join(' ')
  }

  const dropdownClasses = () => {
    let c = [s.dropdown__box, s.dropdown__box_select]

    if (isOpen) {
      c.push(s.dropdown__box_opened)
    }

    return c.join(' ')
  }

  const handleIconCLick = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const setActiveModel = model => {
    if (model.id === activeModel.id) {
      return
    }
    dispatch(setActiveClientModel(model))
    dispatch(setTradeInStandaloneCar(model.attributes.name))
    dispatch(setCarName(model.attributes.name))

    setHasErrorWhileSavingSC(false)

    if (isSCSavedFromThisWidget && !disableSCLink) {
      dispatch(startLoading())

      const carData = {
        modelData: modelDataParser(model.attributes.modelData),
        name: model.attributes.name,
        price: model.attributes.price,
      }

      updateTradeInCar(`${genericIdSC}-${idSC}`, tokenSC, { data: { car: carData } })
        .then(_ => {})
        .catch(e => {
          setHasErrorWhileSavingSC(true)
        })
        .finally(_ => {
          dispatch(stopLoading())
        })
    }

    let d = {
      model_data: modelDataParser(model.attributes.modelData),
      price: model.attributes.price,
    }

    bootBenefit(widgetId, encodeQueryData(d))
      .then(r => {
        dispatch(setTradeInBenefitValue(r.data.tradeInBenefitValue))
        dispatch(setTradeInCondition(r.data.tradeInCondition))
      })
      .catch(e => {
        dispatch(setTradeInBenefitValue(0))
        console.error(e)
      })
      .finally(() => setIsOpen(false))
  }

  const name = () => {
    if (activeModel && activeModel.attributes && activeModel.attributes.name) {
      return activeModel.attributes.name
    } else {
      return t('tradeInS.selectCarPlaceholder')
    }
  }

  const disabledName = () => {
    if (activeModel && activeModel.attributes && activeModel.attributes.name) {
      return activeModel.attributes.name
    } else {
      return t('tradeInS.selectCarPlaceholderEmpty')
    }
  }

  const modelsContent = models.map(model => {
    let classes = [s.models__item]
    if (activeModel.id === model.id) {
      classes.push(s.models__item_active)
    }

    return (
      <div className={classes.join(' ')} key={model.id} onClick={() => setActiveModel(model)}>
        {model.attributes.name}
      </div>
    )
  })

  const content = () => {
    if (disabled) {
      return (
        <div className={[s.dropdownTrigger, s.disabled].join(' ')}>
          <div className={s.dropdownTrigger__content}>{disabledName()}</div>
        </div>
      )
    } else {
      return (
        <div className={s.dropdown}>
          <div className={selectClasses()}>
            <div className={s.dropdownTrigger} onClick={handleSelectClick} ref={select}>
              <div className={s.dropdownTrigger__content}>{name()}</div>
              <div className={iconClasses()} onClick={e => handleIconCLick(e)} />
            </div>
          </div>
          <div className={dropdownClasses()} ref={dropdown}>
            <div className={s.dropdownBox}>
              <div className={s.models}>{modelsContent}</div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={s.lineInput}>
      <div className={s.lineInput__top}>{content()}</div>
      <div className={s.lineInput__bottom}>{label}</div>
    </div>
  )
}

function mapStateToProps({
  settings: { widget_id: widgetId },
  clientModels: { models, activeModel },
  saved_sc: { id: idSC, genericId: genericIdSC, type: typeSC, token: tokenSC },
}) {
  return {
    models,
    activeModel,
    widgetId,
    idSC,
    genericIdSC,
    typeSC,
    tokenSC,
  }
}

export default connect(mapStateToProps)(LineSelect)
