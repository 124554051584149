import { BUY_MODEL_STANDALONE_TYPES } from '../types'

export const setBuyModelStandaloneMainSettings = payload => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_MAIN_SETTINGS,
    widgetId: payload.widgetId,
    visual: payload.visual,
    currency: payload.currency,
    disableSmartContractLink: payload.disableSmartContractLink,
    defaultCountryCode: payload.defaultCountryCode,
    possibleCountryCodes: payload.possibleCountryCodes,
  })
}

export const setBuyModelStandaloneDealerships = dealerships => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_DEALERSHIPS,
    payload: dealerships,
  })
}

export const setBuyModelStandaloneActiveDealership = dealership => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_ACTIVE_DEALERSHIP,
    payload: dealership,
  })
}

export const setBuyModelStandaloneIsWidgetLoaded = payload => ({
  type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADED,
  payload,
})

export const setBuyModelStandaloneIsWidgetLoadingFailed = payload => ({
  type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADING_FAILED,
  payload,
})

export const setBuyModelStandaloneModels = models => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_MODELS,
    payload: models,
  })
}

export const setBuyModelStandaloneActiveModel = model => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_ACTIVE_MODEL,
    payload: model,
  })
}

export const setBuyModelStandaloneSavedSC = savedSC => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_SAVED_SC,
    payload: savedSC,
  })
}

export const setBuyModelStandaloneLocale = locale => dispatch => {
  dispatch({
    type: BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_LOCALE,
    payload: locale,
  })
}
