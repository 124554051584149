import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { getProjectName } from '../../helpers/app/detectors/project_name'
import Button from '../Ui/button'

function SuccessScreen({ saved_sc, car, currency, widget_id }) {
  const { t } = useTranslation()
  const timeoutId = useRef(null)
  const [showRedirectDescription, setShowRedirectDescription] = useState(true)
  const [countdown, setCountdown] = useState(10)

  const openPayment = useCallback(
    e => {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_PrepayFinal',
          Status: '',
          buttonName: e?.target?.innerText || 'Не удалось определить текст на кнопке перехода в платежный шлюз',
          carDetail: {
            identity: car.identity,
            name: car.name,
            modelData: car.modelData,
            productType: car.productType,
          },
          widget_id: widget_id,
          deal_id: `${saved_sc.genericId.toUpperCase()}-${saved_sc.id}`,
          eventValue: car.price,
          currency,
        })
      window.open(saved_sc.payment_url)
      clearTimeout(timeoutId.current)
      setShowRedirectDescription(false)
    },
    [
      car.identity,
      car.name,
      car.modelData,
      car.productType,
      widget_id,
      saved_sc.genericId,
      saved_sc.id,
      car.price,
      currency,
      saved_sc.payment_url,
    ],
  )

  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      openPayment()
    }, 10000)

    const intervalId = setInterval(() => {
      setCountdown(prev => prev - 1)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [openPayment])

  return (
    <div className="kdxsc-payment-start-screen" data-project-name={getProjectName()}>
      <div className="kdxsc-payment-start-screen__icon">
        <div className="kdxsc-payment-start-screen-icon" />
      </div>
      <div className="kdxsc-payment-start-screen__text">
        {t('paymentStart.description')}
        <br />
        <br />
        {showRedirectDescription && t('paymentStart.redirectDescription', { count: countdown })}
        {!showRedirectDescription && t('paymentStart.redirectDescriptionFail')}
      </div>
      <div className="kdxsc-payment-start-screen__buttons kdxsc-payment-start-screen-buttons">
        <div className="kdxsc-payment-start-screen-buttons__item">
          <Button handleClick={openPayment} text={t('paymentStart.goToOnlinePayment')} id="kdxsc_prepay_final" />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ saved_sc, car, settings: { currency, widget_id } }) {
  return {
    saved_sc,
    car,
    currency,
    widget_id,
  }
}

export default connect(mapStateToProps)(SuccessScreen)
