import _cloneDeep from 'lodash/cloneDeep'

const INIT_STATE = {
  models: [],
  activeModel: {},
}

const clientModels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_CLIENT_MODELS':
      return {
        ...state,
        models: _cloneDeep(action.payload),
      }
    case 'SET_ACTIVE_CLIENT_MODEL':
      return {
        ...state,
        activeModel: _cloneDeep(action.payload),
      }

    default:
      return state
  }
}

export default clientModels
