import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectProductTypeHelper = (preset, detail) => {
  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.productType()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.productType) {
    if (typeof detail.productType === 'string') {
      return detail.productType
    } else if (typeof detail.productType === 'function') {
      return detail.productType()
    }
  }

  return 'unknown'
}
