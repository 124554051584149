import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const supplementConfigHelper = (preset, config) => {
  if (preset) {
    const [platform, term] = presetParams(preset)
    // из пресета
    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return {
          ...config,
          cars_container: function () {
            return onePlatform().list.cars_container()
          },
          card_class: onePlatform().list.card_class,
          car_card: function () {
            return onePlatform().list.car_card()
          },
          car_id: function (card) {
            return onePlatform().list.car_id(card, term)
          },
          car_price: function (card) {
            return onePlatform().list.car_price(card)
          },
          car_image: function (card) {
            return onePlatform().list.car_image(card)
          },
          car_title: function (card) {
            return onePlatform().list.car_title(card)
          },
          car_link: function (card) {
            return onePlatform().list.car_link(card)
          },
          widgetIdList: function (card) {
            return onePlatform().list.widgetIdList(card)
          },
          modelDataList: function (card) {
            return onePlatform().list.modelDataList(card)
          },
          productTypeList: function (card) {
            return onePlatform().list.productTypeList(card)
          },
          car_discounts: function (card) {
            return onePlatform().list.car_discounts(card)
          },
          car_dealerships: function (card) {
            return onePlatform().list.car_dealerships(card)
          },
          card_identity: function (card) {
            return onePlatform().list.card_identity(card)
          },
          parse_car_card: function (card) {
            return onePlatform().list.parse_car_card(card)
          },
          additionalOptionsList: function (card) {
            if (onePlatform().list.additionalOptionsList) {
              return onePlatform().list.additionalOptionsList(card)
            }
          },
          listCardButtonGenerator: function (card, text, isLocked, status) {
            if (onePlatform().list.listCardButtonGenerator) {
              return onePlatform().list.listCardButtonGenerator(card, text, isLocked, status)
            }
          },
        }
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  // если в конфиге указан list
  if (config && config.list) {
    const userConfig = {
      ...config,
      listNodeWrapper: function (node) {
        if (typeof config.list.listNodeWrapper === 'function') {
          return config.list.listNodeWrapper(node)
        }

        return node
      },
      cars_container: function () {
        return config.list.cars_container()
      },
      card_class: config.list.card_class,
      car_card: function () {
        return config.list.car_card()
      },
      car_id: function (card) {
        return config.list.car_id(card)
      },
      car_price: function (card) {
        return config.list.car_price(card)
      },
      car_image: function (card) {
        return config.list.car_image(card)
      },
      car_title: function (card) {
        return config.list.car_title(card)
      },
      car_link: function (card) {
        return config.list.car_link(card)
      },
      modelDataList: function (card) {
        if (config.list?.modelDataList) {
          return config.list.modelDataList(card)
        }
      },
      productTypeList: function (card) {
        if (config.list?.productTypeList) {
          return config.list.productTypeList(card)
        }
      },
      additionalOptionsList: function (card) {
        if (config.list?.additionalOptionsList) {
          return config.list.additionalOptionsList(card)
        }
      },
      car_discounts: function (card) {
        if (config.list?.car_discounts) {
          return config.list.car_discounts(card)
        } else {
          return {
            credit: 0,
            tradein: 0,
            credit_tradein: 0,
            insurance: 0,
          }
        }
      },
      widgetIdList: function (card) {
        if (config.list.widgetIdList) {
          if (typeof config.list.widgetIdList === 'string') {
            return config.list.widgetIdList
          } else if (typeof config.list.widgetIdList === 'function') {
            return config.list.widgetIdList(card)
          }
        } else {
          return ''
        }
      },
      parse_car_card: function (card) {
        return config.list.parse_car_card(card)
      },
    }

    if (config.list.listCardButtonGenerator && typeof config.list.listCardButtonGenerator === 'function') {
      userConfig['listCardButtonGenerator'] = function (card, text, isLocked, status) {
        return config.list.listCardButtonGenerator(card, text, isLocked, status)
      }
    }

    return userConfig
  }

  return config
}
