export const deprecatedButtonTextHelper = config => {
  if (config.button_text) {
    if (typeof config.button_text === 'string') {
      return config.button_text
    } else if (typeof config.button_text === 'function') {
      return config.button_text()
    }
  }

  return ''
}
