import { SCREEN_AUTH, SCREEN_INDEX } from '../../constants'
import { getAdditionalCarPrices } from '../../helpers'
import { setAdditionalData, setPreviousAction, setPreviousScreen } from './data_before_auth'
import { widgetClosed, widgetOpened } from './events'

export const goToIndex = () => ({
  type: 'GO_TO',
  screen: SCREEN_INDEX,
})

export const goToScreen = screen => (dispatch, getState) => {
  dispatch({
    type: 'GO_TO',
    screen: screen,
  })
}

export const preventBackFromOnlinePayment = () => dispatch => {
  dispatch({
    type: 'PREVENT_BACK_FROM_ONLINE_PAYMENT',
  })
}

export const preventBackFromTradeIn = () => dispatch => {
  dispatch({
    type: 'PREVENT_BACK_FROM_TRADE_IN',
  })
}

export const preventBackFromCredit = () => dispatch => {
  dispatch({
    type: 'PREVENT_BACK_FROM_CREDIT',
  })
}

export const goToAuthScreen = previousData => (dispatch, getState) => {
  if (previousData.action) {
    dispatch(setPreviousAction(previousData.action))
  }

  if (previousData.screen) {
    dispatch(setPreviousScreen(previousData.screen))
  }

  if (previousData.additionalData) {
    dispatch(setAdditionalData(previousData.additionalData))
  }

  dispatch({
    type: 'GO_TO',
    screen: SCREEN_AUTH,
  })
}

export const hideWidget = () => dispatch => {
  document.body.setAttribute('style', 'overflow: auto; height: auto;')
  dispatch({ type: 'SET_WIDGET_VISIBILITY', visibility: false })
  dispatch(widgetClosed())
}

export const showWidget = () => dispatch => {
  document.body.setAttribute('style', 'overflow: hidden; height: 100%;')
  dispatch({ type: 'SET_WIDGET_VISIBILITY', visibility: true })
  dispatch(widgetOpened())
}

export const hideCarOrderPopup = () => dispatch => {
  document.body.setAttribute('style', 'overflow: auto; height: auto;')
  dispatch({ type: 'SET_CAR_ORDER_POPUP_VISIBILITY', visibility: false })
}

export const showCarOrderPopup = () => dispatch => {
  document.body.setAttribute('style', 'overflow: hidden; height: 100%;')
  dispatch({ type: 'SET_CAR_ORDER_POPUP_VISIBILITY', visibility: true })
}

export const setFormState = payload => ({
  type: 'SET_FORM_STATE',
  payload,
})

export const setWidgetOpenStart = payload => dispatch => {
  dispatch({ type: 'SET_WIDGET_OPEN_START', payload })
}

export const setWidgetId = widget_id => ({
  type: 'SET_WIDGET_ID',
  widget_id,
})

export const setModelData = modelData => ({
  type: 'SET_MODEL_DATA',
  modelData,
})

export const setProductType = productType => ({
  type: 'SET_PRODUCT_TYPE',
  productType,
})

export const setDiscounts = discounts => ({
  type: 'SET_DISCOUNTS',
  discounts,
})

export const setCustomBenefitSetting = isCustomBenefitsOn => ({
  type: 'SET_CUSTOM_BENEFITS_SETTING',
  isCustomBenefitsOn,
})

export const setCalledFromList = calledFromList => ({
  type: 'SET_CALLED_FROM_LIST',
  calledFromList,
})

export const setCarName = name => ({
  type: 'SET_CAR_NAME',
  name,
})

export const setCarPrice = price => ({
  type: 'SET_CAR_PRICE',
  price,
})

export const setAdditionalCarPrices = () => (dispatch, getState) => {
  const payload = getAdditionalCarPrices(getState())
  dispatch({ type: 'SET_ADDITIONAL_CAR_PRICES', payload })
}

export const setCarIdentity = identity => ({
  type: 'SET_CAR_IDENTITY',
  identity,
})

export const setCarAdditionalOptions = additionalOptions => ({
  type: 'SET_CAR_ADDITIONAL_OPTIONS',
  additionalOptions,
})

export const setCarUrl = url => ({
  type: 'SET_CAR_URL',
  url,
})

export const setCarImage = image => ({
  type: 'SET_CAR_IMAGE',
  image,
})

export const setRestrictions = payload => ({
  type: 'SET_RESTRICTIONS',
  payload,
})

export const setVisual = (visual, deprecated = null) => (dispatch, getState) => {
  dispatch({
    type: 'SET_VISUAL',
    visual,
  })

  if (deprecated) {
    dispatch({
      type: 'SET_DEPRECATED_VISUAL',
      deprecated,
    })
  }
}

export const startLoading = () => ({
  type: 'SET_LOADING',
  loading: true,
})

export const stopLoading = () => ({
  type: 'SET_LOADING',
  loading: false,
})

export const setOnlinePayment = onlinePayment => ({
  type: 'SET_ONLINE_PAYMENT',
  onlinePayment,
})

export const setOnlinePaymentActiveVariant = payload => ({
  type: 'SET_ONLINE_PAYMENT_ACTIVE_VARIANT',
  payload,
})

export const setOnlinePaymentNewFullPayment = ({ price, formattedPrice }) => ({
  type: 'SET_ONLINE_PAYMENT_NEW_FULL_PAYMENT',
  price,
  formattedPrice,
})

export const setPanelContent = panelContent => ({
  type: 'SET_PANEL_CONTENT',
  panelContent,
})

export const setCurrency = currency => ({
  type: 'SET_CURRENCY',
  currency,
})

export const setLocale = locale => ({
  type: 'SET_LOCALE',
  locale,
})

export const setDefaultCountryCode = countryCode => ({
  type: 'SET_DEFAULT_COUNTRY_CODE',
  payload: countryCode,
})

export const setPossibleCountryCodes = possibleCountryCodes => ({
  type: 'SET_POSSIBLE_COUNTRY_CODES',
  payload: possibleCountryCodes,
})

export const setDealerships = dealerships => dispatch => {
  dispatch({
    type: 'SET_DEALERSHIPS',
    payload: dealerships,
  })
}

export const setActiveDealership = dealership => dispatch => {
  dispatch({
    type: 'SET_ACTIVE_DEALERSHIP',
    payload: dealership,
  })
}

export const setIsWidgetLoaded = payload => ({
  type: 'SET_IS_WIDGET_LOADED',
  payload,
})

export const setIsWidgetLoadingFailed = payload => ({
  type: 'SET_IS_WIDGET_LOADING_FAILED',
  payload,
})

export const setBootBatchListType = payload => ({
  type: 'SET_BOOT_BATCH_LIST_TYPE',
  payload,
})

export * from './accessories'
export * from './api'
export * from './benefits'
export * from './cars'
export * from './client_models'
export * from './credit'
export * from './data_layer'
export * from './events'
export * from './form'
export * from './leasing'
export * from './saved_sc'
export * from './settings'
export * from './trade_in'
