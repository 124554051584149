import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { setTradeInGeneration, toggleTradeInStep } from '../../../store/actions'
import TradeInRadio from './Helpers/Radio'

function TradeInGeneration({ step, name, generations, id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name, external }) => {
    dispatch(setTradeInGeneration({ id, name, external }))
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'generation' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('generation'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.generation.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.generation.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInRadio name="generation" variants={generations} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      generation: { name, id },
    },
    dictionaries: { generations },
  },
}) {
  return {
    step,
    name,
    id,
    generations,
  }
}

export default connect(mapStateToProps)(TradeInGeneration)
