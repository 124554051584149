import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup'

import { CREDIT_STANDALONE_SCREEN } from '../../constants'
import {
  getPrograms,
  setBuyback,
  setBuybackPercent,
  setCarModel,
  setCarPrice,
  setCreditOrLeasingStandaloneActiveDealership,
  setError,
  setInitFee,
  setInitFeePercent,
  setProgramsLoadedOnce,
  setScreen,
  setSmartContract,
  setTerm,
} from '../../store/actions/credit_standalone'
import CityDealerships from '../UI/CityDealerships'
import { Button, InputGroup, Subtitle } from '../UI/Common'
import Dealerships from '../UI/Dealerships'
import DoubleSelect from '../UI/DoubleSelect'
import Masked from '../UI/Masked'
import Range from '../UI/Range'
import Select from '../UI/Select'
import SelectCarModel from '../UI/SelectCarModel'
import s              from './styles/conditions.module.scss'
import { formID }     from './formID'
import LeasingBuyBack from './LeasingBuyBack'
import RangeTerms from './RangeTerms'
import Term from './Term'

const Conditions = ({
                      projectName,
  widgetId,
  screen,
  carModelId,
  carPrice,
  initFee,
  initFeePercent,
  buyback,
  buybackPercent,
  term,
  city,
  showComplectations,
  possibleTerms,
  programs,
  setScreen,
  setCarModel,
  setCarPrice,
  setInitFee,
  setBuyback,
  setInitFeePercent,
  setBuybackPercent,
  setTerm,
  getPrograms,
  conditionsRef,
  selectModelRef,
  selectBrandRef,
  selectComplectationRef,
  setError,
  loading,
  setSmartContract,
  currency,
  isLeasing,
  moduleLocale,
  carPriceInitial,
  splitCarSelect,
  dealerships,
  activeDealership,
  priceToolTipEnabled,
  visual,
  vehicleType,
  defaultSettingsAvailable,
  rangeTermsData,
  isBuybackRangeAvailable,
  customDisclaimer,
}) => {
  let dispatch = useDispatch()

  const { t } = useTranslation()
  const handleSetDealership = payload =>
    dispatch(
      setCreditOrLeasingStandaloneActiveDealership({
        calcType: isLeasing ? 'leasing' : 'credit',
        dealership: payload,
      }),
    )
  const handleSetCarModel = useCallback(payload => setCarModel(payload), [setCarModel])
  const handleSetCarPrice = useCallback(payload => setCarPrice(payload), [setCarPrice])
  const handleSetInitFee = useCallback(payload => setInitFee(payload), [setInitFee])
  const handleSetBuyback = useCallback(payload => setBuyback(payload), [setBuyback])
  const handleSetInitFeePercent = useCallback(payload => setInitFeePercent(payload), [setInitFeePercent])
  const handleSetBuybackPercent = useCallback(payload => setBuybackPercent(payload), [setBuybackPercent])

  const dealershipsRef = useRef()
  const dealershipsCity = useRef()
  const initFeeRef = useRef()
  const leasingTermRef = useRef()
  const buybackRef = useRef()
  const priceRef = useRef()

  const schema = yup.object().shape({
    kdxcc_dealerships: dealerships.length ? yup.string().required() : null,
    kdxcc_carModel: !splitCarSelect ? yup.string().required() : null,
    kdxcc_model: splitCarSelect ? yup.string().required() : null,
    kdxcc_brand: splitCarSelect ? yup.string().required() : null,
    kdxcc_carPrice: yup.string().required(),
    kdxcc_initFee: yup.string().required(),
    kdxcc_initFeePercent: yup.string().required(),
    kdxcc_buyback: isLeasing || !isBuybackRangeAvailable ? null : yup.string().required(),
    kdxcc_buybackPercent: isLeasing || !isBuybackRangeAvailable ? null : yup.string().required(),
    kdxcc_term: yup.string().required(),
  })

  const { register, handleSubmit, control, setValue } = useForm({ resolver: yupResolver(schema) })
  const { showRemainingPaymentInProgram } = visual

  const onSubmit = data => {
    if (loading) {
      return
    }

    schema.isValid(data).then(() => {
      window.dataLayer &&
        window.dataLayer.push({
          event: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
          Status: 'apply',
          buttonName: t('ccS.conditions.pickACredit'),
          widget_id: widgetId,
          deal_id: '',
          eventValue: '',
        })

      window.dataLayer &&
        window.dataLayer.push({
          event: 'calc_choose_credit',
          ec: 'step1',
          ea: window.location.href,
        })
      window.ym && window.ym(93074980, 'reachGoal', 'calc_choose_credit_step1', { URL: window.location.href })

      if (programs?.length && !dealerships?.length) {
        setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS)
        return
      }

      getPrograms()
    })
  }

  const debouncedGetPrograms = useDebouncedCallback(() => getPrograms(), 500)

  useEffect(() => {
    if (screen !== CREDIT_STANDALONE_SCREEN.CREDIT_CONDITIONS) {
      setError(null)
      setSmartContract(null)
      debouncedGetPrograms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedGetPrograms, carPrice, initFee, initFeePercent, buyback, buybackPercent, term])

  const isCarSelected = !carModelId.length

  const disableCarSelect = dealerships.length
    ? Boolean(Object.keys(activeDealership).length === 0 && activeDealership.constructor === Object)
    : false

  const renderCarSelect = () => {
    if (showComplectations) {
      return (
        <Select
          onChange={handleSetCarModel}
          dataType={'carsSelect'}
          carModelId={carModelId}
          labelText={t('ccS.conditions.complectation')}
          placeholder={t('ccS.conditions.chooseComplectation')}
          showComplectations={showComplectations}
          fieldId={formID.carModel}
          register={register}
          setValue={setValue}
          currentRef={selectComplectationRef}
          nextRef={priceRef}
          disabled={disableCarSelect}
        />
      )
    }
    if (!splitCarSelect) {
      return (
        <Select
          onChange={handleSetCarModel}
          dataType={'cars'}
          carModelId={carModelId}
          labelText={t('ccS.conditions.model')}
          placeholder={t('ccS.conditions.chooseModel')}
          fieldId={formID.carModel}
          showComplectations={showComplectations}
          register={register}
          setValue={setValue}
          currentRef={selectBrandRef}
          nextRef={priceRef}
          disabled={disableCarSelect}
          wide={isLeasing}
        />
      )
    }

    if (splitCarSelect) {
      return (
        <DoubleSelect
          selectCar={handleSetCarModel}
          register={register}
          setValue={setValue}
          modelRef={selectModelRef}
          brandRef={selectBrandRef}
          nextRef={priceRef}
          disabled={disableCarSelect}
        />
      )
    }
  }

  const renderCarPrice = () => {
    if (!isLeasing) {
      return (
        <Masked
          onChange={handleSetCarPrice}
          value={carPrice}
          wide={showComplectations}
          labelText={t('ccS.conditions.price')}
          id={formID.carPrice}
          control={control}
          currentRef={priceRef}
          setValue={setValue}
          currency={currency}
          disabled={true}
        />
      )
    }

    if (isLeasing) {
      const largeCarPrice = carPriceInitial > 30000000
      const dividedNumber = largeCarPrice ? carPriceInitial : 30000000
      const valueRange = Number(carPrice) / (dividedNumber / 100)

      return (
        <Range
          carPrice={carPrice}
          dividedNumber={dividedNumber}
          onChange={handleSetCarPrice}
          value={carPrice}
          onChangeRange={handleSetCarPrice}
          valueRange={valueRange}
          labelText={t('ccS.conditions.price')}
          id={formID.carPrice}
          control={control}
          register={register}
          setValue={setValue}
          currentRef={priceRef}
          currency={currency}
          isLeasing={true}
          disabled={isCarSelected}
        />
      )
    }
  }

  const renderBuybackRange = () => {
    return (
      <Range
        carPrice={carPrice}
        onChange={handleSetBuyback}
        value={buyback}
        onChangeRange={handleSetBuybackPercent}
        valueRange={buybackPercent}
        labelText={t('ccS.conditions.buybackPayment')}
        tip={t('ccS.conditions.buybackPaymentHint')}
        id={formID.buyback}
        control={control}
        register={register}
        setValue={setValue}
        currentRef={buybackRef}
        currency={currency}
        disabled={isCarSelected}
      />
    )
  }

  const renderLeasingBuybackRange = () => {
    return (
      <LeasingBuyBack
        value={buybackPercent}
        buyback={buyback}
        handleSetBuyback={handleSetBuyback}
        onChange={handleSetBuybackPercent}
        setValue={setValue}
        moduleLocale={moduleLocale}
        labelText={t('ccS.conditions.buybackPayment')}
        tip={t('ccS.conditions.buybackPaymentHint')}
        id={formID.buyback}
        control={control}
        register={register}
        currency={currency}
        currentRef={buybackRef}
        disabled={isCarSelected}
      />
    )
  }
  const cityName = city.map(item => item.dealershipCity).filter(Boolean)
  return (
    <section className={s.conditions} data-project-name={projectName} ref={conditionsRef}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {dealerships.length !== 0 && (
          <>
            {cityName.length !== 0 && (
              <InputGroup>
                <CityDealerships
                  isLeasing={isLeasing}
                  currentRef={dealershipsCity}
                  nextRef={dealershipsRef}
                  register={register}
                  setValue={setValue}
                  onChange={handleSetDealership}
                  widgetId={widgetId}
                  activeDealershipWidgetId={activeDealership}
                />
              </InputGroup>
            )}
            <InputGroup>
              <Dealerships
                isLeasing={isLeasing}
                currentRef={dealershipsRef}
                nextRef={selectBrandRef}
                register={register}
                setValue={setValue}
                onChange={handleSetDealership}
                widgetId={widgetId}
                activeDealershipWidgetId={activeDealership}
              />
            </InputGroup>
          </>
        )}

        {!showComplectations && (
          <InputGroup options={{ splitCarSelect, isLeasing }}>
            {renderCarSelect()}
            {!isLeasing && renderCarPrice()}
          </InputGroup>
        )}

        {showComplectations && (
          <>
            <InputGroup options={{ splitCarSelect, isLeasing }}>
              {
                <SelectCarModel
                  dataType={'cars'}
                  carModelId={carModelId}
                  labelText={t('ccS.conditions.model')}
                  placeholder={t('ccS.conditions.chooseModel')}
                  fieldId={formID.carModel}
                  register={register}
                  setValue={setValue}
                  currentRef={selectBrandRef}
                  nextRef={selectComplectationRef}
                  disabled={disableCarSelect}
                />
              }
              {renderCarSelect()}
            </InputGroup>
            <InputGroup>{!isLeasing && renderCarPrice()}</InputGroup>
          </>
        )}
        <Subtitle>{t(`ccS.conditions.title.${moduleLocale}`)}</Subtitle>

        <InputGroup>
          {isLeasing && renderCarPrice()}
          <Range
            carPrice={carPrice}
            onChange={handleSetInitFee}
            value={initFee}
            onChangeRange={handleSetInitFeePercent}
            valueRange={initFeePercent}
            labelText={t(`ccS.conditions.initialFee.${moduleLocale}`)}
            id={formID.initFee}
            control={control}
            register={register}
            setValue={setValue}
            currentRef={initFeeRef}
            currency={currency}
            disabled={isCarSelected}
            isLeasing={isLeasing}
          />
          {isBuybackRangeAvailable && renderBuybackRange()}
        </InputGroup>

        {isLeasing && (
          <InputGroup>
            {showRemainingPaymentInProgram && (
              <RangeTerms
                value={term}
                onChange={setTerm}
                setValue={setValue}
                moduleLocale={moduleLocale}
                possibleTerms={possibleTerms.periodsForStore}
                labelText={t(`ccS.conditions.period.${moduleLocale}`)}
                id={formID.term}
                control={control}
                register={register}
                currentRef={leasingTermRef}
                disabled={isCarSelected}
                defaultSettingsAvailable={defaultSettingsAvailable}
                data={rangeTermsData}
              />
            )}

            {showRemainingPaymentInProgram && renderLeasingBuybackRange()}
          </InputGroup>
        )}

        {(!isLeasing || !showRemainingPaymentInProgram) && (
          <Term
            register={register}
            term={term}
            setTerm={setTerm}
            moduleLocale={moduleLocale}
            possibleTerms={possibleTerms.periodsForStore}
          />
        )}

        {screen === CREDIT_STANDALONE_SCREEN.CREDIT_CONDITIONS && (
          <Button>{t(`ccS.conditions.pickACredit.${moduleLocale}`)}</Button>
        )}

        {priceToolTipEnabled && screen !== CREDIT_STANDALONE_SCREEN.PERSONAL_DATA && (
          <div className={s.priceToolTipEnabled}>
            {customDisclaimer !== '' ? customDisclaimer : t(`car.tooltipAlert.${vehicleType}`)}
          </div>
        )}
      </form>
    </section>
  )
}

const mapStateToProps = state => {
  const isLeasing = state.credit_standalone.module.isLeasing

  const hideBuybackRangeForce = isLeasing
    ? true
    : state.credit_standalone.config.hideBuybackPaymentForAllWidgets === true

  const isBuybackRangeAvailable = hideBuybackRangeForce ? false : !state.credit_standalone.config.disableBuybackPayment

  return {
    widgetId: state.credit_standalone.config.widgetId,
    screen: state.credit_standalone.screen,
    carModel: state.credit_standalone.conditions.carModel,
    carModelData: state.credit_standalone.conditions.carModelData,
    carModelId: state.credit_standalone.conditions.carModelId,
    carPrice: state.credit_standalone.conditions.carPrice,
    carPriceInitial: state.credit_standalone.conditions.carPriceInitial,
    initFee: state.credit_standalone.conditions.initFee,
    initFeePercent: state.credit_standalone.conditions.initFeePercent,
    buyback: state.credit_standalone.conditions.buyback,
    buybackPercent: state.credit_standalone.conditions.buybackPercent,
    term: state.credit_standalone.conditions.term,
    possibleTerms: state.credit_standalone.conditions.possibleTerms,
    isPersonalDataFilled: state.credit_standalone.personal.filled,
    programs: state.credit_standalone.programs.list,
    programsLoadedOnce: state.credit_standalone.programs.loadedOnce,
    error: state.credit_standalone.error,
    loading: state.credit_standalone.loading,
    currency: state.credit_standalone.config.currency,
    priceToolTipEnabled: state.credit_standalone.config.priceToolTipEnabled,
    isLeasing,
    moduleLocale: state.credit_standalone.module.locale,
    splitCarSelect: state.credit_standalone.config.splitCarSelect,
    dealerships: isLeasing ? state.credit_standalone.dealerships.leasing : state.credit_standalone.dealerships.credit,
    city: isLeasing ? state.credit_standalone.dealerships.leasing : state.credit_standalone.dealerships.credit,
    showComplectations: state.credit_standalone.showComplectations,
    activeDealership:
      (isLeasing
        ? state.credit_standalone.activeDealership.leasing
        : state.credit_standalone.activeDealership.credit) || '',
    visual: {
      showRemainingPaymentInProgram: state.credit_standalone.config.showRemainingPaymentInProgram,
    },
    vehicleType: state.settings.vehicleType,
    defaultSettingsAvailable: state.credit_standalone.config.defaultSettingsAvailable,
    isBuybackRangeAvailable,
    rangeTermsData: state.credit_standalone.config.defaultSettingsAvailable
      ? { MONTH_DIVIDER: 1, MONTH_MINMAX: { min: 12, max: 72 } }
      : { MONTH_DIVIDER: 12, MONTH_MINMAX: { min: 1, max: 6 } },
    customDisclaimer: state.credit_standalone.config.useCustomDisclaimer? state.credit_standalone.config.customDisclaimerText : '',
  }
}

const mapDispatchToProps = {
  setScreen,
  setCarModel,
  setCarPrice,
  setInitFee,
  setBuyback,
  setInitFeePercent,
  setBuybackPercent,
  setTerm,
  getPrograms,
  setProgramsLoadedOnce,
  setError,
  setSmartContract,
  setCreditOrLeasingStandaloneActiveDealership,
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditions)
