module.exports = {
  trade_in: {
    top: 'рассчитайте',
    bottom: 'трейд-ин',
  },

  credit: {
    top: 'рассчитайте',
    bottom: 'кредит',
  },

  cars: {
    top: 'автомобили',
    bottom: 'в наличии',
  },

  open: 'открыть',
  sc: 'смарт контракт',
  my: 'мой',
  yourCar: 'ваш автомобиль',
  inTradeIn: 'в трейд-ин',
}
