import { BUY_MODEL_STANDALONE_TYPES } from '../types'

export const BUY_MODEL_STANDALONE_INIT_STATE = {
  widgetId: '',
  visual: {},
  currency: '',
  locale: '',
  disableSmartContractLink: '',

  defaultCountryCode: '',
  possibleCountryCodes: [],

  dealerships: [],
  activeDealership: {},
  isWidgetLoaded: true, // загрузился ли виджет при смене ДЦ с БЭ
  isWidgetLoadingFailed: false, // есть ли ошибка при загрузке виджета при смене ДЦ

  models: [],
  activeModel: {},

  savedSC: {
    url: '',
    id: '',
    genericId: '',
    token: '',
  },
}

const buyModelStandalone = (state = BUY_MODEL_STANDALONE_INIT_STATE, action) => {
  switch (action.type) {
    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_MAIN_SETTINGS:
      return {
        ...state,
        widgetId: action.widgetId,
        visual: action.visual,
        currency: action.currency,
        disableSmartContractLink: action.disableSmartContractLink,
        defaultCountryCode: action.defaultCountryCode,
        possibleCountryCodes: action.possibleCountryCodes,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_DEALERSHIPS:
      return {
        ...state,
        dealerships: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_ACTIVE_DEALERSHIP:
      return {
        ...state,
        activeDealership: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADED:
      return {
        ...state,
        isWidgetLoaded: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_IS_WIDGET_LOADING_FAILED:
      return {
        ...state,
        isWidgetLoadingFailed: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_MODELS:
      return {
        ...state,
        models: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_ACTIVE_MODEL:
      return {
        ...state,
        activeModel: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_LOCALE:
      return {
        ...state,
        locale: action.payload,
      }

    case BUY_MODEL_STANDALONE_TYPES.SET_BUY_MODEL_STANDALONE_SAVED_SC:
      return {
        ...state,
        savedSC: {
          url: action.payload.smartContractUrl,
          id: action.payload.id,
          genericId: action.payload.genericId,
          token: action.payload.token,
        },
      }

    default:
      return state
  }
}

export default buyModelStandalone
