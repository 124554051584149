import { FORM_TYPE_ONLINE_PAYMENT } from '../constants'
import { pushFormError, setScroll } from '../store/actions'
import store from '../store/store'
import { isEmailValid, isPhoneValid } from './validations'

const cyrillicPattern = /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ\s]+$/
const promoCodePattern = /^[a-zA-Z0-9]{0,15}$/

export const isNamesInFormValid = (value, isRequiredHere, locale) => {
  const trimmedName = value.trim()

  if (!trimmedName?.length && isRequiredHere) {
    return false
  } else if (locale === 'Ru' && trimmedName.length && !cyrillicPattern.test(trimmedName)) {
    return false
  }

  return true
}

export const isPromoCodeValid = value => {
  const trimmedValue = value.trim()

  if (!trimmedValue) {
    return true
  }

  return promoCodePattern.test(trimmedValue)
}

export const isEmailInFormValid = (value, isRequiredHere) => {
  if (!isEmailValid(value) && isRequiredHere) {
    return false
  }

  return true
}

export const formValidation = (form, fields, formType, locale) => {
  const errors = []

  let requiredType = 'requiredOnBaseForm'

  if (formType === FORM_TYPE_ONLINE_PAYMENT) {
    requiredType = 'requiredOnOnlinePaymentForm'
  }

  const { values, phoneSelection } = form

  for (const item in values) {
    if (values.hasOwnProperty(item)) {
      const isRequiredHere = fields[item] && fields[item][requiredType] === true

      switch (item) {
        case 'name':
          if (!isNamesInFormValid(values[item], isRequiredHere, locale)) {
            store.dispatch(pushFormError('name'))
            errors.push('name')
          }
          break

        case 'lastName':
          if (!isNamesInFormValid(values[item], isRequiredHere, locale)) {
            store.dispatch(pushFormError('lastName'))
            errors.push('lastName')
          }
          break

        case 'secondName':
          if (!isNamesInFormValid(values[item], isRequiredHere, locale)) {
            store.dispatch(pushFormError('secondName'))
            errors.push('secondName')
          }
          break

        case 'phone':
          if (!isPhoneValid(values['phone'], phoneSelection['country'])) {
            store.dispatch(pushFormError('phone'))
            errors.push('phone')
          }
          break

        case 'email':
          if (!isEmailInFormValid(values['email'], isRequiredHere)) {
            store.dispatch(pushFormError('email'))
            errors.push('email')
          }
          break

        case 'promoCode':
          if (!isPromoCodeValid(values['promoCode'])) {
            store.dispatch(pushFormError('promoCode'))
            errors.push('promoCode')
          }
          break

        case 'agreement':
          if (!values.agreement) {
            store.dispatch(pushFormError('agreement'))
            errors.push('agreement')
          }
          break

        // добавить проверки

        default:
          if (fields[item] && !values[item] && isRequiredHere) {
            store.dispatch(pushFormError(item))
            errors.push(item)
          }
      }
    }
  }

  if (errors.length) {
    store.dispatch(setScroll(true))
  }

  return errors
}
