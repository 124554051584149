import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { loadBrands, setTradeInBrand, setTradeInStandaloneTypedValue } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneBrandDropdown({ brands, id, typedValue, widgetId }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!brands.length && widgetId) {
      dispatch(loadBrands())
    }
  }, [widgetId, brands, dispatch])

  const onSet = ({ id, name }) => {
    dispatch(setTradeInBrand({ id, name }))
    dispatch(
      setTradeInStandaloneTypedValue({
        name: 'brand',
        value: name,
      }),
    )
    window.dataLayer &&
      window.dataLayer.push({
        event: 'tradeInStandalone',
        Status: 'start',
        buttonName: '',
        widget_id: '',
        deal_id: '',
        eventValue: '',
      })
  }

  return (
    <TradeInStandalonePartsRadiobuttons name="brand" variants={brands} checked={id} onSet={onSet} search={typedValue} />
  )
}

function mapStateToProps({
  settings: { widget_id: widgetId },
  tradeIn: {
    typedValues,
    values: {
      brand: { id },
    },
    dictionaries: { brands },
  },
}) {
  // чтобы не было бага в локалсторадж
  const typedValue = () => {
    if (typedValues && typedValues.brand) {
      return typedValues.brand
    } else {
      return ''
    }
  }
  return {
    id,
    brands,
    typedValue: typedValue(),
    widgetId,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneBrandDropdown)
