module.exports = {
  title: 'Трейд-ин калькулятор',

  shortYear: 'г.',
  kilometers: 'км',

  yourCar: 'Ваш автомобиль',

  rateCar: 'Оценить автомобиль в Трейд-Ин',
  showCarEstimation: 'Показать оценку вашего автомобиля',

  averageCarPriceText: 'Средняя стоимость вашего автомобиля (±5%)',
  averageCarZeroPriceText: 'Введите стоимость, за которую вы хотите сдать свой автомобиль',
  averageCarZeroPriceTextDisabled:
    'К сожалению мы не смогли автоматически расчитать стоимость вашего автомобиля, но мы передадим все введенные данные для оценки менеджеру',
  desiredCarText: 'Какой автомобиль хотите приобрести?',

  openSCStep1: 'Продолжить',
  openSCStep2: 'Посмотреть',
  openSCStep3: 'Применить цену',

  SCDisclaimerText:
    'Смарт Контракт – онлайн-инструмент для подбора выгодных условий по кредиту, трейд-ин, лизингу, а так же для бронирования автомобиля у официального дилера.',
  SCDisclaimerVideo: 'Видео ролик про Смарт Контракт',

  noData: 'Ничего не найдено',

  inputPlaceholder: 'Ввод данных ...',

  selectCarPlaceholder: 'Выберите автомобиль',
  selectCarPlaceholderEmpty: 'Вы не выбрали автомобиль',

  successText: 'Мы сохранили ваши условия. Вы можете их посмотреть, нажав на кнопку ниже',
  successTextWithoutLink: 'Спасибо! Менеджер свяжется с вами в ближайшее время.',

  enterMileage: 'Выберите или введите пробег',

  ownerCarPricePlaceholder: 'Укажите стоимость вашего автомобиля',
  ownerCarPriceButton: 'Не согласны с оценкой?',

  generalError: 'К сожалению, произошла ошибка при загрузке Трейд-ин калькулятора',
}
