module.exports = {
  initialPrice: {
    auto: 'Initial price',
    moto: 'Initial price',
    other: 'Initial price',
  },
  finalPrice: 'Final price',
  title: {
    auto: 'Car price',
    moto: 'Motorcycle price',
    other: 'Vehicle price',
  },
  tooltipAlert: {
    auto:
      'The price is recommended, to agree on the final cost of the car, taking into account the conditions of purchase, the manager of the official dealership will contact you',
    moto:
      'The price is recommended, to agree on the final cost of the motorcycle, taking into account the conditions of purchase, the manager of the official dealership will contact you',
    other:
      'The price is recommended, to agree on the final cost of the vehicle, taking into account the conditions of purchase, the manager of the official dealership will contact you',
  },
}
