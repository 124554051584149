module.exports = {
  state: {
    title: 'Finanzierung',
    // yourCarCostsMoreThanCarYouWant:
    //   'Keine Finanzierung möglich, da der Wert Ihres Fahrzeugs bei Inzahlungnahme mit Berücksichtigung aller Rahmenbedingungen den Preis des gewünschten Fahrzeugs übersteigt.',
    yourCarCostsMoreThanCarYouWant: {
      auto: 'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased car',
      moto: 'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased motorcycle',
      other:
        'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased vehicle',
    },
    subtitleWithBenefit: 'Finanzierungsvorteile',
    subtitleWithoutBenefit: 'Finanzierung',
    initialAdditionalText: 'Wählen Sie ein Finanzierungsprogramm',
    initialAdditionalTextWithoutEstimation: 'Wählen Sie die Finanzierungsoptionen',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } Monat ',
    additionalTextWithCreditParameters:
      'Sie haben eine Finanzierung mit der Anzahlung { initialFee } für einen Zeitraum von { period } Monaten ausgewählt.',
    buyWithCash: 'Barkauf',
    autoEstimationIsImpossible: 'Automatische Bewertung ist nicht möglich',
    unavailable: 'Nicht verfügbar',
    // benefitHint: 'Dieser Vorteil gilt beim Kauf dieses Fahrzeugs über Finanzierung',
    benefitHint: {
      auto: 'This advantage applies in the case of buying this car on credit',
      moto: 'This advantage applies in the case of buying this motorcycle on credit',
      other: 'This advantage applies in the case of buying this vehicle on credit',
    },
    activeProgramHint: 'Credit payment according to the program of your choice', // !!!
    defaultProgramHint:
      'Minimum credit payment for programs with an initial payment of {initialFee}, a buyback payment of {buybackPayment} and a credit period of {period} months.', // !!!
    defaultProgramNoBuybackPaymentHint:
      'Minimum credit payment for programs with an initial payment of {initialFee} and a credit period of {period} months.', // !!!
  },
  screen: {},
  promoProgram: {
    choose: 'Apply', // !!!
    moreDetails: 'More details', // !!!
  },
}
