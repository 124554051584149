module.exports = {
  isPriceHiddenHint: 'Unfortunately, the calculation is not possible at the moment', // !!!
  generalError: {
    c: 'Initialisierungsfehler des Finanzierungsrechners: keine Fahrzeugdaten',
    l: 'Initialisierungsfehler des Leasingrechners: keine Fahrzeugdaten',
  },

  title: {
    c: 'FINANZIERUNGSRECHNER',
    l: 'LEASINGRECHNER',
  },

  dealerships: {
    dealership: 'Dealership', // !!!
    chooseDealership: 'Choose dealership', // !!!
  },
  dealershipsCity: {
    // !!!
    dealershipCity: 'City', // !!!
    any: 'Any', // !!!
    chooseCity: 'Choose city', // !!!
  },

  conditions: {
    title: {
      c: 'Finanzierungsbedingungen auswählen',
      l: 'Leasingbedingungen auswählen',
    },
    model: 'Model des Fahrzeugs',
    brand: 'Car brand', // !!!
    chooseModel: 'Wählen Sie das Fahrzeugmodel',
    chooseBrand: 'Wählen Sie das Fahrzeugbrand',
    complectation: 'Car complectation', // !!!
    chooseComplectation: 'Choose car complectation', // !!!
    price: 'Fahrzeugpreis',
    initialFee: {
      c: 'Anzahlung',
      l: 'Vorauszahlung',
    },
    buybackPayment: 'Rückkaufzahlung',
    buybackPaymentHint:
      'The residual payment is the amount as a percentage of the value of the vehicle, the payment of which is carried over to the end of the loan term', // !!!
    pickACredit: {
      c: 'Finanzierung berechnen',
      l: 'Leasing berechnen',
    },
    period: {
      c: 'Finanzierungslaufzeit',
      l: 'Leasinglaufzeit',
    },
  },

  personal: {
    title: {
      c: 'Geben Sie Ihre persönlichen Daten ein, um das Finanzierungsangebot anzuzeigen',
      l: 'Geben Sie Ihre persönlichen Daten ein, um das Leasingangebot anzuzeigen',
    },
    goBackButton: 'Zurück',
    showProgramsButton: 'Geeignetes Finanzierungsprogramme anzeigen',
    noDataText: {
      c:
        'Zur Anzeige eines persönlichen Finanzierungsangebots benötigen wir Ihre Daten und die Einwilligung zur Kommunikation',
      l:
        'Zur Anzeige eines persönlichen Leasingangebots benötigen wir Ihre Daten und die Einwilligung zur Kommunikation',
    },
    yourSuitableProgramsP1V1: {
      c: 'Finanzierungsprogramme, die zu Ihnen passen',
      l: 'Leasingprogramme, die zu Ihnen passen',
    },
    yourSuitableProgramsP1V2: {
      c: 'Finanzierungsprogramm, das zu Ihnen passt',
      l: 'Leasingprogramm, das zu Ihnen passt',
    },
    yourSuitableProgramsP2V1: {
      c: 'weitere Finanzierungsprogramme dieser Bank',
      l: 'weitere Leasingprogramme dieser Bank',
    },
    yourSuitableProgramsP2V2: {
      c: 'weiteres Finanzierungsprogramm dieser Bank',
      l: 'weiteres Leasingprogramm dieser Bank',
    },
    yourSuitableProgramsP2V3: {
      c: 'weiteres Finanzierungsprogramm dieser Bank',
      l: 'weiteres Leasingprogramm dieser Bank',
    },
  },

  programs: {
    error: 'Wählen Sie ein Programm aus',
    view: 'Vorschau',
    continue: 'Weiter',
    hide: 'Minimieren',
    bankName: 'Name der Bank',
    fromThisBank: 'von dieser Bank',
  },

  actions: {
    autoCalculationDisabled:
      'Automatische Berechnung ist nicht möglich. Versuchen Sie Ihre ausgewählten Finanzierungskonditionen zu ändern',
    autoCalculationDisabledTechError:
      'Automatische Berechnung ist nicht möglich. Technische Arbeiten werden durchgeführt. Bitte versuchen Sie es später nochmal',
    weSavedYourConditions: 'Wir haben Ihre gewählten Finanzierungsoptionen abgespeichert ✓',
    managerWillContactYou: 'Ihrer Ansprechpartner setzt sich umgehend mit Ihnen in Verbindung',
    error:
      'Leider ist ein Fehler passiert. Technische Arbeiten werden durchgeführt. Bitte versuchen Sie es später nochmal.',
  },

  popupCreditGeneralError: 'Unfortunately, an error has been occurred while loading', // !!!
}
