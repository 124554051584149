module.exports = {
  onlinePaymentHintText: 'Online-Vorauszahlung ist nach der Speicherung der Bedingungen verfügbar',
  totalTooltip: {
    // firstZeroPaymentHintText:
    //   'Der Preis Ihres Fahrzeugs bei Inzahlungnahme<br/>werden als Anzahlung <br/>in die Finanzierung übernommen',
    firstZeroPaymentHintText: {
      auto: 'Your car cost in Trade-in<br/>will be taken as a credit<br/>initial fee',
      moto: 'Your motorcycle cost in Trade-in<br/>will be taken as a credit<br/>initial fee',
      other: 'Your vehicle cost in Trade-in<br/>will be taken as a credit<br/>initial fee',
    },
    // firstAdditionalPaymentHintText:
    //   'Bei der ausgewählten Anzahlung, müssen Sie zusätzlich, zu Ihrem Inzahlung genommenen Fahrzeug, {payment} bezahlen',
    firstAdditionalPaymentHintText: {
      auto: 'You need to pay extra {payment} to the cost<br/>of your car, handed over to Trade-in, in order to pay<br/>the initial payment you have chosen',
      moto: 'You need to pay extra {payment} to the cost<br/>of your motorcycle, handed over to Trade-in, in order to pay<br/>the initial payment you have chosen',
      other:
        'You need to pay extra {payment} to the cost<br/>of your vehicle, handed over to Trade-in, in order to pay<br/>the initial payment you have chosen',
    },
    creditAmount:
      'Finanzierungsbetrag, beinhaltet <span class="kdxsc-credit-total-header__price">{ payment }</span>, <br/>',
  },
  withTax: 'mit Steuer',
}
