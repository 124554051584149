import {
  isCommunicationProcessingAcceptedCheckboxVisible,
  isCommunicationProcessingCheckboxVisible,
} from '../../../Components/Parts/FormNew/helpers'
import {
  CAR_ORDER_FORM_PLACEMENT,
  COUNTRY_CODES,
  CREDIT_SETTINGS_BEHAVIOR,
  FINANCE_USER_BEHAVIOUR,
  FORM_SOURCE,
  LEASING_SETTINGS_BEHAVIOR,
  TRADE_IN_SETTINGS_BEHAVIOR,
} from '../../../constants'
import {
  avnFormWasReplaced,
  bootedEvent,
  goToIndex,
  setActiveDealership,
  setAdditionalCarPrices,
  setBackgroundBootedOnList,
  setCalledFromList,
  setCaptchaError,
  setCaptchaSettings,
  setCarAdditionalOptions,
  setCarIdentity,
  setCarImage,
  setCarName,
  setCarPrice,
  setCarUrl,
  setCommunicationProcessing,
  setCurrency,
  setCustomBenefitSetting,
  setCustomData,
  setCustomSource,
  setDefaultCountryCodesData,
  setDisableCash,
  setFinanceBehaviourByUser,
  setFormValue,
  setIsWidgetLoaded,
  setIsWidgetLoadingFailed,
  setLimitForSaving,
  setLocale,
  setModelData,
  setOnlinePayment,
  setOnlinePaymentActiveVariant,
  setPhoneCountry,
  setPriceTooltip,
  setRestrictions,
  setScroll,
  setSmsCodeError,
  setSmsCodeSettings,
  setTaxes,
  setVehicleType,
  setVisual,
  setWidgetId,
  setWidgetOpenStart,
  showWidget,
  stashFormErrors,
} from '../../../store/actions'
import store from '../../../store/store'
import {
  accessoriesSetter,
  additionalButtonGenerator,
  benefitsSetter,
  buttonTextHelper,
  createTooltipHelper,
  deprecatedButtonGeneratorHelper,
  deprecatedVisualHelper,
  detectNameHelper,
  detectPictureHelper,
  detectUrlHelper,
  formatPossibleCountryCodes,
  formSetter,
  fullPaymentValue,
  isCountryCodeAvailableToAdd,
  isItAvailableToReplaceAvnForm,
  leasingSetter,
  tradeInSetter,
} from '../..'
import { normalizeVisual } from '../../normalize_visual'
import { boot } from '../../requests'
import { financeSetter } from './finance'
import { languageSetter } from './language'
import { paletteSetter } from './palette'
import { kodixUidSetter } from './pixel'

const openWidget = () => {
  store.dispatch(setActiveDealership({}))
  store.dispatch(showWidget())
}

const renderDetailButton = (
  preset,
  visual,
  tooltipData,
  currency,
  status = '',
  price,
  carDetail,
  config,
  vehicleType,
) => {
  const additionalButton = additionalButtonGenerator(preset, config)
  if (additionalButton) {
    additionalButton.addEventListener('click', e => click(e, price, carDetail, currency, config), false)
  }

  let generated = []

  const text = buttonTextHelper(visual, currency, status, config, vehicleType)

  // TODO: Try To Render Button From New Methods

  if (generated.length === 0) {
    generated = deprecatedButtonGeneratorHelper(preset, tooltipData, text, config)
  }

  if (generated.length === 0) {
    return
  }

  let showTooltip = true
  if ((config.showTooltip !== undefined && config.showTooltip === false) || visual.isPriceHidden) {
    showTooltip = false
  }

  for (const button of generated) {
    button.addEventListener('click', e => click(e, price, carDetail, currency, config), false)
    button.classList.add('kdxsc-animate')
    button.classList.add('kdxsc-target')

    if (showTooltip) {
      createTooltipHelper(button, tooltipData)
    }
  }
}

const click = (e, price, carDetail, currency, config) => {
  store.dispatch(setWidgetOpenStart(Date.now()))

  if (e) {
    e.preventDefault()

    const h = window.location.host

    if (h.includes('kuntsevo') || h.includes('cena-auto')) {
      e.stopPropagation()
    }

    window.dataLayer &&
      window.dataLayer.push({
        event: 'SmartContract_Open',
        Status: '',
        buttonName: e?.target?.innerText || 'Не удалось определить текст на кнопке на детальной странице',
        carDetail,
        widget_id: config?.widget_id,
        deal_id: '',
        eventValue: price,
        currency,
      })
  }
  openWidget()
}

export const bootMainInfo = (data, config, dataFromLocalStorage, initialBoot) => {
  if (!initialBoot) {
    store.dispatch(setIsWidgetLoaded(false))
    store.dispatch(setIsWidgetLoadingFailed(false))
  }

  const {
    widget_id,
    pagePrice,
    safeIdentity,
    modelData,
    productType,
    calledFromList,
    dontOpenWidget,
    discounts,
    header_text,
    form_agreement_text,
    palette,
    detail,
    additionalOptions,
    customData,
  } = data

  window.scLogger.log('[bootMainInfo::widget_id]', widget_id)

  if (!widget_id) {
    return
  }

  store.dispatch(setWidgetOpenStart(Date.now()))

  boot(widget_id, pagePrice, safeIdentity, modelData, productType)
    .then(
      ({
        data: {
          identity,
          locale,
          product,
          currency,
          visual,
          tradeIn,
          benefits,
          accessories,
          isCustomBenefitsOn,
          onlinePayment,
          restrictions,
          finance,
          priceToolTipEnabled,
          defaultCountryCode,
          possibleCountryCodes,
          vehicleType,
          taxes,
        },
      }) => {
        const { credit, leasing, disableCash } = finance

        store.dispatch({ type: 'GENERATE_UNIQUE_CODE', payload: String(Math.random()).substring(2) })

        paletteSetter(visual.palette)

        store.dispatch(setPriceTooltip(priceToolTipEnabled))

        const price = product.price || pagePrice

        if (initialBoot) {
          kodixUidSetter()
        }

        if (price > 0 || visual?.isPriceHidden) {
          try {
            languageSetter(locale)

            if (config.customSource) {
              setCustomSource(config.customSource)
            }

            store.dispatch(setLocale(locale))
            store.dispatch(setCurrency(currency))
            store.dispatch(setVehicleType(vehicleType))
            store.dispatch(setTaxes(taxes))

            const normalizedVisual = normalizeVisual(visual, vehicleType || 'auto')

            store.dispatch(setWidgetId(widget_id))

            if (initialBoot) {
              store.dispatch(setCalledFromList(calledFromList))
              store.dispatch(setModelData(modelData))
              store.dispatch(setCarPrice(price))
              store.dispatch(setCustomBenefitSetting(isCustomBenefitsOn))
            }

            store.dispatch(setCarIdentity(identity))
            store.dispatch(setCarAdditionalOptions(additionalOptions))
            store.dispatch(setCustomData(customData))
            store.dispatch(setVisual(normalizedVisual, deprecatedVisualHelper(header_text, form_agreement_text)))
            store.dispatch(stashFormErrors())
            store.dispatch(setScroll(false))
            store.dispatch(setFormValue('agreement', visual.agreements.personalDataProcessing.default_setting))
            const isCommunicationProcessingBeChecked = !isCommunicationProcessingAcceptedCheckboxVisible(visual)
              ? false
              : visual.agreements.communicationProcessing.default_setting
            store.dispatch(setCommunicationProcessing('accepted', isCommunicationProcessingBeChecked))
            const isCommunicationProcessingPhoneBeChecked = isCommunicationProcessingCheckboxVisible(visual, 'phone')
            const isCommunicationProcessingSmsBeChecked = isCommunicationProcessingCheckboxVisible(visual, 'sms')
            const isCommunicationProcessingEmailBeChecked = isCommunicationProcessingCheckboxVisible(visual, 'email')
            const isCommunicationProcessingPostBeChecked = isCommunicationProcessingCheckboxVisible(visual, 'post')
            if (!isCommunicationProcessingPhoneBeChecked) {
              store.dispatch(setCommunicationProcessing('phone', false))
            }
            if (!isCommunicationProcessingSmsBeChecked) {
              store.dispatch(setCommunicationProcessing('sms', false))
            }
            if (!isCommunicationProcessingEmailBeChecked) {
              store.dispatch(setCommunicationProcessing('email', false))
            }
            if (!isCommunicationProcessingPostBeChecked) {
              store.dispatch(setCommunicationProcessing('post', false))
            }

            store.dispatch(setRestrictions(restrictions))
            store.dispatch(setDisableCash(disableCash))

            // benefits begin
            benefitsSetter(benefits, dataFromLocalStorage)
            // benefits end

            // accessories begin
            accessoriesSetter(accessories, dataFromLocalStorage)
            // accessories end

            // tradeIn begins +
            const isTradeInEnabledFromSettings = [
              TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
              TRADE_IN_SETTINGS_BEHAVIOR.ENABLED,
            ].includes(tradeIn.behaviour)

            tradeInSetter(
              isTradeInEnabledFromSettings,
              tradeIn,
              discounts,
              isCustomBenefitsOn,
              dataFromLocalStorage,
              visual.isPriceHidden,
            )
            // tradeIn ends

            // credit begins
            // are credit & leasing enabled
            const isCreditEnabledFromSettings = [
              CREDIT_SETTINGS_BEHAVIOR.ENABLED,
              CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
            ].includes(credit.behaviour)

            const isLeasingEnabledFromSettings = [
              LEASING_SETTINGS_BEHAVIOR.ENABLED,
              LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS,
            ].includes(leasing.condition.behaviour)

            financeSetter({
              finance,
              discounts,
              isCustomBenefitsOn,
              price,
              dataFromLocalStorage,
            })
            // credit ends

            // leasing begins
            leasingSetter(
              isLeasingEnabledFromSettings,
              isCreditEnabledFromSettings,
              leasing,
              discounts,
              isCustomBenefitsOn,
              price,
              dataFromLocalStorage,
            )
            // leasing ends

            // если disableCash и финанс NONE и только 1 финансирование возможно
            // то ставим либо leasing_in_progress, либо credit_in_progress
            if (disableCash && store.getState().credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.NONE) {
              if (isCreditEnabledFromSettings && !isLeasingEnabledFromSettings) {
                store.dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS))
              } else if (!isCreditEnabledFromSettings && isLeasingEnabledFromSettings) {
                store.dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS))
              }
            }

            // + проверка заполенени полей формы:
            // если поле было заполнено неверно и сохранено в ЛС,
            // но потом его убрали из видимых,
            // то СК никогда не сохранится
            formSetter(visual.fields, locale, dataFromLocalStorage)
            clearProtections(FORM_SOURCE.WIDGET)
            clearProtections(FORM_SOURCE.ONLINE_PAYMENT)
            clearProtections(FORM_SOURCE.CAR_ORDER)
            clearProtections(FORM_SOURCE.WIDGET_AUTH)
            // - проверка заполенени полей формы

            let initialFullValue = onlinePayment.variants.full

            onlinePayment = {
              ...onlinePayment,
              variants: {
                ...onlinePayment.variants,
                initialFull: initialFullValue,
                full: fullPaymentValue(initialFullValue),
              },
            }

            store.dispatch(setOnlinePayment(onlinePayment))
            if (onlinePayment.enableFullPayment) {
              store.dispatch(setOnlinePaymentActiveVariant({}))
            }

            store.dispatch(setAdditionalCarPrices())

            const tooltipData = {
              trade_in: isTradeInEnabledFromSettings,
              online_payment: onlinePayment.enabled,
              credit: isCreditEnabledFromSettings,
              benefits: Boolean(benefits.length),
              tooltip: visual.tooltip,
              palette: palette ? palette : {},
            }

            const picture = detectPictureHelper(config.preset, detail || config?.detail, config?.listVersion)
            const name = detectNameHelper(config.preset, detail || config?.detail, config?.listVersion)
            const url = detectUrlHelper(config.preset, detail || config?.detail, config?.listVersion)

            if (initialBoot) {
              store.dispatch(setCarImage(picture))
              store.dispatch(setCarName(name))
              store.dispatch(setCarUrl(url))
            }

            // + установка номера телефона с БЭ
            bootPhoneCountriesData(dataFromLocalStorage, defaultCountryCode, possibleCountryCodes)
            // - установка номера телефона с БЭ

            // + инициализая простой формы
            if (initialBoot && config.replaceCarOrderForm) {
              let dom = {
                id: 'avn_form',
                place: document.getElementById('avn_form'),
              }

              let areDealershipsAvailableToChoose = true

              const dealerships = store.getState().settings.dealerships

              if (dealerships?.length) {
                areDealershipsAvailableToChoose = isItAvailableToReplaceAvnForm(dealerships)
              } else {
                if (onlinePayment.status !== 'available') {
                  areDealershipsAvailableToChoose = false
                }
              }

              if (dom.id && dom.place && areDealershipsAvailableToChoose) {
                store.dispatch(avnFormWasReplaced())

                window.InitCarOrderForm(dom.id, {
                  ...config,
                  placement: CAR_ORDER_FORM_PLACEMENT.PAGE,
                  source: FORM_SOURCE.CAR_ORDER,
                })
              }

              /*
                  const targetName = 'kdxscCarOrder'

                  if (config.carOrderFormType === 'popup') {
                    let listeners = []

                    const elements = document.querySelectorAll(`[data-target="${targetName}"]`)

                    if (elements) {
                      window.InitSmartContractCarOrderPopup({ ...config, placement: 'popup' })

                      elements.forEach(el => {
                        const listener = el.addEventListener(
                          'click',
                          e => {
                            if (e) {
                              e.preventDefault()
                              store.dispatch(showCarOrderPopup())
                            }
                          },
                          false,
                        )
                        listeners.push(listener)
                      })
                    }
                  }

                  // + инициализация на странице
                  let domOne = {
                    id: 'avn_form',
                    place: document.getElementById('avn_form'),
                  }

                  let domTwo = {
                    id: targetName,
                    place: document.getElementById(targetName),
                  }

                  let dom = {}

                  if (domOne.place) {
                    dom = { ...domOne }
                  } else if (domTwo.place) {
                    dom = { ...domTwo }
                  }

                  if (dom.id && dom.place) {
                    window.InitCarOrderForm(dom.id, { ...config, placement: 'page' })
                  }
                   */
              // - инициализация на странице
            }
            // - инициализая простой формы

            // делаем boot в бэкграунде только для первой карточки на списке
            if (calledFromList && dontOpenWidget && !store.getState().settings.backgroundBootedOnList) {
              store.dispatch(setBackgroundBootedOnList())
            }

            // вставляем кнопки и переходим на индекс только если это первоначальная загрузка.
            // иначе при перевыборе ДЦ мы получим кучу кнопок

            if (initialBoot) {
              if (calledFromList && !dontOpenWidget) {
                openWidget()
              } else {
                renderDetailButton(
                  config.preset,
                  normalizedVisual,
                  tooltipData,
                  currency,
                  onlinePayment.status,
                  price,
                  {
                    identity,
                    name,
                    modelData,
                    productType,
                  },
                  config,
                  vehicleType,
                )
              }

              store.dispatch(bootedEvent(config))
              store.dispatch(goToIndex())
            } else {
              store.dispatch(setIsWidgetLoaded(true))
            }
          } catch (e) {
            console.error('[smart.contract] unable to boot detail widget due', e)
            store.dispatch(setIsWidgetLoadingFailed(true))
          }
        }
      },
    )
    .catch(e => {
      console.error(e)
      store.dispatch(setIsWidgetLoadingFailed(true))
    })
}

export const bootPhoneCountriesData = (dataFromLocalStorage, defaultCountryCode, possibleCountryCodes) => {
  if (!possibleCountryCodes || possibleCountryCodes?.length === 0) {
    possibleCountryCodes = Object.keys(COUNTRY_CODES)
  }

  // + установка номера телефона с БЭ
  if (dataFromLocalStorage?.form) {
    const formLS = dataFromLocalStorage.form
    const countryLS = formLS?.phoneSelection?.country

    if (isCountryCodeAvailableToAdd(countryLS)) {
      if (countryLS !== defaultCountryCode) {
        defaultCountryCode = countryLS
      }

      if (!possibleCountryCodes.includes(countryLS)) {
        possibleCountryCodes.push(countryLS)
      }
    }
  }

  let { formattedDefaultCountryCode, formattedPossibleCountryCodes } = formatPossibleCountryCodes({
    defaultCountryCode,
    possibleCountryCodes,
  })

  store.dispatch(setPhoneCountry(formattedDefaultCountryCode))

  store.dispatch(
    setDefaultCountryCodesData({
      defaultCountryCode: formattedDefaultCountryCode,
      possibleCountryCodes: formattedPossibleCountryCodes,
    }),
  )
  // - установка номера телефона с БЭ
}

export const clearProtections = formSource => {
  if (formSource) {
    store.dispatch(setSmsCodeError(formSource, false))
    store.dispatch(setCaptchaError(formSource, false))
    store.dispatch(
      setSmsCodeSettings(formSource, {
        visible: false,
        timeToResend: 0,
        ticketId: '',
      }),
    )
    store.dispatch(
      setCaptchaSettings(formSource, {
        visible: false,
        captchaSrc: '',
        ticketId: '',
      }),
    )
    store.dispatch(setLimitForSaving(formSource, 0))
  }
}

export const clearSmsAndCaptcha = () => {
  store.dispatch(setFormValue('captcha', ''))
  store.dispatch(setFormValue('smsCode', ''))
}
