import { connect, useDispatch } from 'react-redux'

import { setTradeInBody } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneBodyDropdown({ bodies, id }) {
  const dispatch = useDispatch()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInBody({ id, name }))
  }

  return <TradeInStandalonePartsRadiobuttons name="body" variants={bodies} checked={id} onSet={onSet} search={''} />
}

function mapStateToProps({
  tradeIn: {
    values: {
      body: { id },
    },
    dictionaries: { bodies },
  },
}) {
  return {
    id,
    bodies,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneBodyDropdown)
