import {
  MODEL_DATA_ONE_PLATFORM_BRAND_ID,
  MODEL_DATA_ONE_PLATFORM_COMPLECTATION_ID,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
  MODEL_DATA_ONE_PLATFORM_MODEL_ID,
  MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
  MODEL_DATA_RAW_BRAND, MODEL_DATA_RAW_COLOR,
  MODEL_DATA_RAW_COMPLECTATION,
  MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
  MODEL_DATA_RAW_GENERATION,
  MODEL_DATA_RAW_MILEAGE,
  MODEL_DATA_RAW_MODEL,
  MODEL_DATA_RAW_MODEL_YEAR,
} from '../constants'

export const modelDataParser = value => {
  if (!value) {
    return ''
  }

  const map = {
    brand: MODEL_DATA_RAW_BRAND,
    model: MODEL_DATA_RAW_MODEL,
    model_year: MODEL_DATA_RAW_MODEL_YEAR,
    year: MODEL_DATA_RAW_MODEL_YEAR,
    engine_power_horse_power: MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
    generation_name: MODEL_DATA_RAW_GENERATION,
    mileage: MODEL_DATA_RAW_MILEAGE,
    complectation: MODEL_DATA_RAW_COMPLECTATION,
    color: MODEL_DATA_RAW_COLOR,

    one_platform_restyling_id: MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
    one_platform_marketing_complectation_id: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
    one_platform_marketing_complectation_code: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,
    one_platform_brand_id: MODEL_DATA_ONE_PLATFORM_BRAND_ID,
    one_platform_model_id: MODEL_DATA_ONE_PLATFORM_MODEL_ID,
    one_platform_complectation_id: MODEL_DATA_ONE_PLATFORM_COMPLECTATION_ID,
  }

  const modelData = []

  for (const key of Object.keys(map)) {
    const exists = !!value[key]

    if (!exists) {
      continue
    }

    modelData.push(`${map[key]}:${value[key]}`)
  }

  return modelData.join(',')
}

export const modelDataToObject = modelDataStr => {
  let res = {}

  const parts = modelDataStr.split(',')
  if (!parts) {
    return res
  }

  for (let i = 0; i < parts.length; i++) {
    const [key, value] = parts[i].split(':')

    if (!key || !value) {
      continue
    }

    res[key] = value
  }

  return res
}
