import s from './styles/button.module.scss'

function Button({ handleClick, text, isDisabled, type }) {
  const classes = () => {
    let c = [s.button]

    if (type === 'bordered') {
      c.push(s.button_bordered)
    }

    if (!type || type === 'filled' || type === '') {
      c.push(s.button_filled)
    }

    return c.join(' ')
  }

  return (
    <button disabled={isDisabled} onClick={handleClick} className={classes()}>
      {text}
    </button>
  )
}

export default Button
