import {
  TI_PARTNER,
  TRADE_IN_DATA_FILLED,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import {
  applyTradeIn,
  disableTradeIn,
  rateTradeIn,
  resetBrand,
  setHidePartnerInTexts,
  setIsTradeInIdentityEnabled,
  setStepWeight,
  setTradeInBehaviorBySettings,
  setTradeInBehaviourByUser,
  setTradeInBenefitValue,
  setTradeInBenefitValueWithoutTradeIn,
  setTradeInCarIdentity,
  setTradeInCondition,
  setTradeInDefaultCities,
  setTradeInDictionary,
  setTradeInEstimationType,
  setTradeInOwnerCarPrice,
  setTradeInOwnerCarPriceEnabled,
  setTradeInOwnerCarPriceHintText,
  setTradeInPartner,
  setTradeInStep,
  setUseCityAsString,
  setZeroRateWithStatus,
} from '../../../store/actions'
import store from '../../../store/store'
import { isTradeInDataFilled } from '../../is_trade_in_data_filled'

export const tradeInSetter = (
  isTradeInEnabledFromSettings,
  tradeIn,
  discounts,
  isCustomBenefitsOn,
  dataFromLocalStorage,
  isPriceHidden,
) => {
  const isTradeInByIdentityEnabled = tradeIn?.calculateByIdentity

  // идентификатор автомобиля - не зависит от партнера и тд, поэтоум ставим его всегда
  const identity = dataFromLocalStorage?.tradeIn?.values?.identity?.value
  const stepFromLs = dataFromLocalStorage?.tradeIn?.step

  // установить данные с БЭ - behavior и benefitValue
  store.dispatch(setTradeInBehaviorBySettings(tradeIn.behaviour))

  let benefit = 0

  if (isCustomBenefitsOn) {
    benefit = tradeIn.benefitValue + discounts.tradein
  } else {
    benefit = tradeIn.benefitValue
  }

  if (isPriceHidden) {
    benefit = 0
  }

  store.dispatch(setTradeInBenefitValue(benefit))
  store.dispatch(setTradeInBenefitValueWithoutTradeIn(tradeIn?.condition?.benefitValueIfNotRequired || 0))

  store.dispatch(setTradeInPartner(tradeIn.partner))
  store.dispatch(setHidePartnerInTexts(tradeIn.hidePartnerInTexts))
  store.dispatch(setUseCityAsString(tradeIn.useCityAsString))
  store.dispatch(setTradeInDefaultCities(tradeIn.defaultCities))

  if (dataFromLocalStorage?.tradeIn?.partner !== tradeIn.partner) {
    // делаем ТИ изначальным, если партнер в ЛС != партнеру с БЭ

    store.dispatch(disableTradeIn())
    store.dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.NONE))
    store.dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.INITIAL))
    store.dispatch(setTradeInStep('brand'))
    store.dispatch(setStepWeight('brand'))

    const dictionariesToBeCleared = [
      'brands',
      'models',
      'generations',
      'bodies',
      'modifications',
      'years',
      'colors',
      'engines',
      'gearboxes',
      'interiorColors',
      'interiorTypes',
    ]

    dictionariesToBeCleared.forEach(dictionary => {
      if (
        dataFromLocalStorage?.tradeIn?.dictionaries[dictionary] &&
        dataFromLocalStorage?.tradeIn?.dictionaries[dictionary]?.length
      ) {
        store.dispatch(setTradeInDictionary(dictionary, []))
      }
    })

    // после сбрасывания бренда должны сброситься все остальные значения
    store.dispatch(resetBrand(tradeIn.partner))
  } else {
    /*
      установка данных из localStorage

      - если ТИ разрешен в виджете + выбран клиентом (applied) + есть все данные, то оцениваем его + статус = applied
      - если ТИ разрешен в виджете + есть некоторые данные, статус = started, оценка initial
      - если ТИ разрешен в виджете + нет данных, то ставим пришедший статус none || not_required
      - если ТИ запрещен в виджете, то снимаем выбор + статус = none
   */

    const tradeInDataFilled = isTradeInDataFilled(dataFromLocalStorage.tradeIn.values, tradeIn.partner)

    if (isTradeInEnabledFromSettings && tradeInDataFilled === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED) {
      store.dispatch(rateTradeIn())
      if (dataFromLocalStorage.tradeIn.behaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED) {
        store.dispatch(applyTradeIn(false, {}))
      } else {
        store.dispatch(
          setTradeInBehaviourByUser(dataFromLocalStorage.tradeIn.behaviourByUser || TRADE_IN_USER_BEHAVIOUR.NONE),
        )
      }
    } else {
      store.dispatch(setZeroRateWithStatus(TRADE_IN_RATE_STATUS.INITIAL))
      store.dispatch(disableTradeIn())

      if (!isTradeInEnabledFromSettings || tradeInDataFilled !== TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED) {
        store.dispatch(setTradeInBehaviourByUser(TRADE_IN_USER_BEHAVIOUR.NONE))
      } else {
        store.dispatch(
          setTradeInBehaviourByUser(dataFromLocalStorage.tradeIn.behaviourByUser || TRADE_IN_USER_BEHAVIOUR.NONE),
        )
      }
    }

    if (tradeIn.ownerCarPriceDisable) {
      store.dispatch(setTradeInOwnerCarPrice(0))
    } else {
      store.dispatch(setTradeInOwnerCarPrice(dataFromLocalStorage?.tradeIn?.ownerCarPrice || 0))
    }
  }

  store.dispatch(setTradeInOwnerCarPriceEnabled(!tradeIn.ownerCarPriceDisable))
  store.dispatch(setTradeInOwnerCarPriceHintText(tradeIn.ownerCarPriceHintText))

  store.dispatch(setTradeInCondition(tradeIn.condition))

  // возвращаем identity всегда, если он есть
  store.dispatch(setIsTradeInIdentityEnabled(isTradeInByIdentityEnabled))

  if (isTradeInByIdentityEnabled) {
    if (identity) {
      store.dispatch(setTradeInCarIdentity({ payload: identity }))
    }
    if (tradeIn.partner === TI_PARTNER.AUTO_RU) {
      const et = dataFromLocalStorage?.tradeIn?.estimationType
      const etToSet =
        et === TRADE_IN_ESTIMATION_TYPE.LIST ? TRADE_IN_ESTIMATION_TYPE.LIST : TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ
      store.dispatch(setTradeInEstimationType(etToSet))
    }
  } else {
    store.dispatch(setTradeInEstimationType(TRADE_IN_ESTIMATION_TYPE.LIST))
    // если в ЛС стоит шаг identity, то сбарысываем его на бренд, тк в общем списке identity нет
    if (stepFromLs === 'identity') {
      store.dispatch(setTradeInStep('brand'))
      store.dispatch(setStepWeight('brand'))
    }
  }
}
