import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TRADE_IN_ESTIMATION_TYPE, TRADE_IN_STEP_WEIGHTS } from '../../../constants'
import TradeInStandaloneDropdown from '../dropdown'
import TradeInStandaloneBodyDropdown from '../dropdowns/body'
import TradeInStandaloneBrandDropdown from '../dropdowns/brand'
import TradeInStandaloneCityDropdown from '../dropdowns/city'
import TradeInStandaloneColorDropdown from '../dropdowns/color'
import TradeInStandaloneGenerationDropdown from '../dropdowns/generation'
import TradeInStandaloneMileageDropdown from '../dropdowns/mileage'
import TradeInStandaloneModelDropdown from '../dropdowns/model'
import TradeInStandaloneModificationDropdown from '../dropdowns/modification'
import TradeInStandaloneOwnerDropdown from '../dropdowns/owner'
import TradeInStandaloneYearDropdown from '../dropdowns/year'
import TradeInStandaloneBodyInput from '../inputs/body'
import TradeInStandaloneBrandInput from '../inputs/brand'
import TradeInStandaloneCityInput from '../inputs/city'
import TradeInStandaloneColorInput from '../inputs/color'
import TradeInStandaloneGenerationInput from '../inputs/generation'
import TradeInStandaloneMileageInput from '../inputs/mileage'
import TradeInStandaloneModelInput from '../inputs/model'
import TradeInStandaloneModificationInput from '../inputs/modification'
import TradeInStandaloneOwnerInput from '../inputs/owner'
import TradeInStandaloneYearInput from '../inputs/year'
import TradeInStandaloneListOfSteps from '../listOfSteps'
import s from './styles/steps.module.scss'

function TradeInStandaloneSteps({ step, behaviourByUser }) {
  const { t } = useTranslation()

  let focus = useRef('')

  const listen = useCallback(e => {
    focus.current = e.detail
  }, [])

  useEffect(() => {
    document.addEventListener('kodix:smartContract:bubbleClick', listen)
    return () => {
      document.removeEventListener('kodix:smartContract:bubbleClick', listen)
    }
  }, [listen])

  const inputSelection = () => {
    switch (step) {
      case 'brand':
        return <TradeInStandaloneBrandInput focus={focus.current} />
      case 'model':
        return <TradeInStandaloneModelInput focus={focus.current} />
      case 'generation':
        return <TradeInStandaloneGenerationInput />
      case 'body':
        return <TradeInStandaloneBodyInput />
      case 'modification':
        return <TradeInStandaloneModificationInput />
      case 'year':
        return <TradeInStandaloneYearInput />
      case 'color':
        return <TradeInStandaloneColorInput />
      case 'owner':
        return <TradeInStandaloneOwnerInput />
      case 'city':
        return <TradeInStandaloneCityInput focus={focus.current} />
      case 'mileage':
        return <TradeInStandaloneMileageInput focus={focus.current} />
      default:
        return <div>no step</div>
    }
  }

  const inputDropdown = () => {
    switch (step) {
      case 'brand':
        return <TradeInStandaloneBrandDropdown />
      case 'model':
        return <TradeInStandaloneModelDropdown />
      case 'generation':
        return <TradeInStandaloneGenerationDropdown />
      case 'body':
        return <TradeInStandaloneBodyDropdown />
      case 'modification':
        return <TradeInStandaloneModificationDropdown />
      case 'year':
        return <TradeInStandaloneYearDropdown />
      case 'color':
        return <TradeInStandaloneColorDropdown />
      case 'owner':
        return <TradeInStandaloneOwnerDropdown />
      case 'city':
        return <TradeInStandaloneCityDropdown />
      case 'mileage':
        return <TradeInStandaloneMileageDropdown />
      default:
        return <div>no step</div>
    }
  }

  const openOnMount = behaviourByUser !== 'none'

  const [isSelectOpened, setSelectOpened] = useState(false)

  const handleSelectOpened = isOpened => {
    setSelectOpened(isOpened)
  }

  return (
    <div className={s.steps}>
      <div className={s.steps__counter}>
        <div className={s.stepsCounter}>
          <div className={s.stepsCounter__current}>{TRADE_IN_STEP_WEIGHTS[step] / 100}</div>
          <div className={s.stepsCounter__total}>/ 10</div>
        </div>
      </div>
      <div className={s.steps__field}>
        <div className={s.stepsField}>
          <div className={`${s.stepsField__select}  ${isSelectOpened ? s.stepsField_isOpened : ''}`}>
            <TradeInStandaloneDropdown
              type="select"
              selectionContent={t(`tradeIn.${step}.title`)}
              dropdownContent={<TradeInStandaloneListOfSteps estimationType={TRADE_IN_ESTIMATION_TYPE.LIST} />}
              handleSelectOpened={handleSelectOpened}
            />
          </div>

          <div className={s.stepsField__input}>
            <TradeInStandaloneDropdown
              type="input"
              step={step}
              openOnMount={openOnMount}
              selectionContent={inputSelection()}
              dropdownContent={inputDropdown()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ tradeIn: { step, behaviourByUser } }) {
  return {
    step,
    behaviourByUser,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneSteps)
