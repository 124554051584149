import { load } from 'redux-localstorage-simple'

import {
  CREDIT_SETTINGS_BEHAVIOR,
  FINANCE_USER_BEHAVIOUR,
  FORM_SOURCE,
  FORM_TYPE_BASE,
  SCREEN_ERROR,
  SCREEN_ONLINE_PAYMENT,
  SCREEN_SUCCESS,
  TRADE_IN_RATE_STATUS,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import {
  availableCarsCountInDealership,
  bootMainInfo,
  carPriceFromTI,
  clearProtections,
  clearSmsAndCaptcha,
  createdEventPayload,
  createSmartContractWithProtection as createSmartContractRequest,
  creditAmount,
  emitBootedEvent,
  emitBootedListEvent,
  formValidation,
  initializeErrorsProcessing,
} from '../../helpers'
import { InitSmartContractOnListV2, openSmartContract } from '../../on_list_v2'
import {
  applyTradeIn,
  calculateCreditPrograms,
  disableBenefit as disableBenefitAction,
  disableCredit as disableCreditAction,
  disableTradeIn as disableTradeInAction,
  enableBenefit as enableBenefitAction,
  firstOpenTradeIn,
  goToCredit,
  goToScreen,
  preventBackFromCredit,
  preventBackFromOnlinePayment,
  // preventBackFromTradeIn,
  pushCustomTag,
  setActiveDealership,
  setCustomTags,
  setFormValue,
  setPhoneCountry,
  setPhoneValue,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  setWidgetOpenStart,
  showCarOrderPopup,
  showWidget,
  smartContractCreated,
  startLoading,
  stashFormErrors,
  stopLoading,
  switchCredit,
} from '../actions'
import { createSmartContractData } from '../helpers/smart_contract'
import { API_ACTIONS } from '../types'

// деталка
export const bootedEvent = config => (dispatch, getState) => {
  const state = getState()

  const data = {
    settings: state.settings,
    car: state.car,
    tradeIn: state.tradeIn,
    credit: state.credit,
    benefits: state.benefits,
    form: state.form,
    onlinePayment: state.onlinePayment,
    savedSc: state.saved_sc,
  }

  const methods = {
    openWidget: (payload = {}) => dispatch(openWidget(payload, config)),
    setActiveWidgetId: widgetId => dispatch(setActiveWidgetId(widgetId)),
    openPersonalDataEntry: () => dispatch(openPersonalDataEntry()),
    showTradeIn: payload => dispatch(showTradeIn(payload)),
    enableTradeIn: payload => dispatch(enableTradeIn(payload)),
    disableTradeIn: () => dispatch(disableTradeIn()),
    showCredit: payload => dispatch(showCredit(payload)),
    enableCredit: payload => dispatch(enableCredit(payload)),
    disableCredit: () => dispatch(disableCredit()),
    enableBenefit: payload => dispatch(enableBenefit(payload)),
    disableBenefit: payload => dispatch(disableBenefit(payload)),
    changePersonalData: payload => dispatch(changePersonalData(payload)),
    changePhoneCountry: payload => dispatch(changePhoneCountry(payload)),
    createSmartContract: payload => dispatch(createSmartContract(payload)),
    showOnlinePayment: payload => dispatch(showOnlinePayment(payload)),
    setAnalyticsTags: payload => dispatch(setAnalyticsTags(payload)),
    pushAnalyticsTag: payload => dispatch(pushAnalyticsTag(payload)),
    setDealership: payload => dispatch(setActiveDealership(payload)),
  }

  if (state.settings.restrictions.sc_widget_events_api_available) {
    emitBootedEvent({ data, methods })
  }

  dispatch({ type: API_ACTIONS.BOOTED_EVENT })
}

// список
export const bootedListEvent = () => (dispatch, getState) => {
  const state = getState()

  const data = {
    settings: state.settings,
    car: state.car,
    cars: state.cars.list,
    tradeIn: state.tradeIn,
    credit: state.credit,
    benefits: state.benefits,
    form: state.form,
    onlinePayment: state.onlinePayment,
    savedSc: state.saved_sc,
  }

  const methods = {
    openWidget: config => dispatch(openWidget({}, config, true)),
    showTradeIn: payload => dispatch(showTradeIn(payload)),
    enableTradeIn: payload => dispatch(enableTradeIn(payload)),
    disableTradeIn: () => dispatch(disableTradeIn()),
    updateCarList: payload => dispatch(updateCarList(payload)),
  }

  if (state.settings.restrictions.sc_widget_events_api_available) {
    emitBootedListEvent({ data, methods })
  }

  // dispatch({ type: API_ACTIONS.BOOTED_LIST_EVENT })
}

const logLastMethod = payload => dispatch => dispatch({ type: API_ACTIONS.LOG_LAST_METHOD, payload })

export const setOpenedFromApi = payload => dispatch => dispatch({ type: API_ACTIONS.OPENED_FROM_API, payload })

const setActiveWidgetId = widgetId => (dispatch, getState) => {
  if (!widgetId) {
    return
  }

  const state = getState()
  const dealership = state.settings.dealerships.find(d => d.widgetId === widgetId)

  if (!dealership || availableCarsCountInDealership(dealership) === 0) {
    return
  }

  dispatch(setActiveDealership(dealership))

  const dataFromLocalStorage = load({
    states: ['tradeIn', 'credit', 'benefits', 'form', 'leasing'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const car = dealership.cars.find(car => car.status === 'available')

  bootMainInfo(
    {
      widget_id: dealership.widgetId,
      pagePrice: car.price,
      safeIdentity: car.identity,
      modelData: state.car.modelData,
      productType: state.car.productType,
      calledFromList: state.settings.calledFromList,
      discounts: state.car.discounts,
      header_text: null,
      form_agreement_text: null,
      palette: null,
      detail: null,
      additionalOptions: state.car.additionalOptions,
    },
    state.settings.config,
    dataFromLocalStorage,
    false,
  )
}

const openWidget = (payload = {}, config = {}, fromList) => (dispatch, getState) => {
  dispatch(setWidgetOpenStart(Date.now()))

  if (fromList) {
    openSmartContract(config)
    return
  }

  if (payload && payload.openInPopup) {
    window.InitSmartContractCarOrderPopup({ ...config, placement: 'popup' })
    dispatch(showCarOrderPopup())
  } else {
    dispatch(showWidget())
  }

  if (payload && payload.widgetId) {
    const state = getState()

    const dealership = state.settings.dealerships.find(d => d.widgetId === payload.widgetId)

    if (dealership && availableCarsCountInDealership(dealership) > 0) {
      // Если нашёлся дилерский центр, который мы хотим выбрать и в нём есть автомобили
      dispatch(setActiveDealership(dealership))

      const dataFromLocalStorage = load({
        states: ['tradeIn', 'credit', 'benefits', 'form', 'leasing'],
        namespace: '@kodix/smartContract',
        namespaceSeparator: '/',
      })

      const car = dealership.cars.find(car => car.status === 'available')

      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_Open',
          Status: '',
          buttonName: 'Не удалось определить текст на кнопке на списке',
          carDetail: {
            identity: car.identity,
            name: state.car.name,
            modelData: state.car.modelData,
            productType: state.car.productType,
          },
          widget_id: dealership.widgetId,
          deal_id: '',
          eventValue: car.price,
          currency: state.settings.currency,
        })

      bootMainInfo(
        {
          widget_id: dealership.widgetId,
          pagePrice: car.price,
          safeIdentity: car.identity,
          modelData: state.car.modelData,
          productType: state.car.productType,
          calledFromList: state.settings.calledFromList,
          discounts: state.car.discounts,
          header_text: null,
          form_agreement_text: null,
          palette: null,
          detail: null,
          additionalOptions: state.car.additionalOptions,
        },
        state.settings.config,
        dataFromLocalStorage,
        false,
      )
    } else {
      // Если не нашёлся
      dispatch(setActiveDealership({}))
    }
  }

  dispatch(logLastMethod(openWidget.name))
}

async function awaitElement(selector) {
  const element = document.querySelector(selector)
  while (element === null) await new Promise(resolve => requestAnimationFrame(resolve))
  return element
}

const openPersonalDataEntry = () => dispatch => {
  dispatch(showWidget())
  awaitElement('#kdxsc-phone').then(element => element.focus())
  dispatch(logLastMethod(openPersonalDataEntry.name))
}

// Analytics tags

const setAnalyticsTags = payload => dispatch => {
  dispatch(setCustomTags(payload))
  dispatch(logLastMethod(setAnalyticsTags.name))
}

const pushAnalyticsTag = payload => dispatch => {
  dispatch(pushCustomTag(payload))
  dispatch(logLastMethod(pushAnalyticsTag.name))
}

// TradeIn

const showTradeIn = options => dispatch => {
  dispatch(showWidget())
  dispatch(firstOpenTradeIn(options && options.preventBack))
  dispatch(setOpenedFromApi(true))

  if (options?.logging === false) {
    return
  }

  dispatch(logLastMethod(showTradeIn.name))
}

const enableTradeIn = options => (dispatch, getState) => {
  options = options ?? { open: true, preventBack: false }

  if (options?.open) {
    dispatch(showTradeIn({ logging: false }))
  }

  const state = getState()

  const tradeInRateStatus = state.tradeIn.rate.status

  if (tradeInRateStatus !== TRADE_IN_RATE_STATUS.INITIAL) {
    dispatch(applyTradeIn(false, {}))
    // dispatch(updateCarPrices())

    const creditData = createCreditData(getState())
    dispatch(calculateCreditPrograms(creditData))
  } else {
    dispatch(showTradeIn(options))
  }

  dispatch(logLastMethod(enableTradeIn.name))
}

const disableTradeIn = () => (dispatch, getState) => {
  dispatch(disableTradeInAction())
  // dispatch(updateCarPrices())

  const creditData = createCreditData(getState())
  dispatch(calculateCreditPrograms(creditData))

  dispatch(logLastMethod(disableTradeIn.name))
}

// Credit

const showCredit = options => dispatch => {
  dispatch(showWidget())
  dispatch(goToCredit(false))
  dispatch(setOpenedFromApi(true))

  if (options?.preventBack === true) {
    dispatch(preventBackFromCredit())
  }

  if (options?.logging === false) {
    return
  }

  dispatch(logLastMethod(showCredit.name))
}

const enableCredit = options => (dispatch, getState) => {
  options = options ?? { open: true, preventBack: false }

  if (options.open === undefined) {
    options.open = true
  }

  if (options?.open) {
    dispatch(showCredit({ logging: false, preventBack: options.preventBack === true }))
  }

  const state = getState()

  const ownerCarPrice = state.tradeIn.ownerCarPrice
  const ownerCarPriceEnabled = state.tradeIn.ownerCarPriceEnabled
  const tradeInBenefitAmount = state.tradeIn.benefitValue
  const tradeInBenefitAmountWithoutTradeIn = state.tradeIn.benefitValueWithoutTradeIn
  const tradeInBehaviourByUser = state.tradeIn.behaviourByUser
  const possibleCarPrice = state.tradeIn.rate.result.possibleCarPrice
  const creditBenefitAmount = state.credit.benefitValue
  const creditBehaviourBySettings = state.credit.behaviour
  const creditBehaviourByUser = state.credit.behaviourByUser
  const active_program = state.credit.active_program
  const price = state.car.price
  const selected = state.benefits.selected
  const selectedAccessories = state.accessories.selected

  const isYourCarIsTooExpensive = () => {
    const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
    const carPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)
    const amount = creditAmount({
      price,
      selectedBenefits: selected,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviourByUser,
      tradeInBenefitAmount,
      tradeInBenefitAmountWithoutTradeIn,
      creditBenefitAmount,
    })

    const isTICarPriceMoreThan095StockCarPrice = carPrice > price * 0.95
    const isRealCarPriceMoreThen095CreditAmount = Math.ceil((carPrice * 100) / amount) > 95

    return (isTICarPriceMoreThan095StockCarPrice || isRealCarPriceMoreThen095CreditAmount) && tradeInEnabled
  }

  if (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED) {
    if (active_program.id) {
      if (isYourCarIsTooExpensive()) {
        return false
      }

      dispatch(switchCredit(true))
    } else {
      dispatch(goToCredit())
    }
  }

  if (creditBehaviourBySettings === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
    if (creditBehaviourByUser === FINANCE_USER_BEHAVIOUR.NONE) {
      dispatch(goToCredit())
    } else {
      dispatch(switchCredit(true))
    }
  }

  if (options?.preventBack === true) {
    dispatch(preventBackFromCredit())
  }

  dispatch(logLastMethod(enableCredit.name))
}

const disableCredit = () => dispatch => {
  dispatch(disableCreditAction())
  dispatch(logLastMethod(disableCredit.name))
}

// Benefits

const checkBenefitPayload = payload => {
  if (typeof payload !== 'object') {
    return false
  }

  if (!payload?.id || !payload?.attributes || !payload?.type) {
    return false
  }

  return true
}

function createCreditData(state, benefit = null, type = null) {
  const price = state.car.price
  const benefits = state.benefits.selected
  const selectedAccessories = state.accessories.selected
  const tradeInBehaviourByUser = state.tradeIn.behaviourByUser
  const tradeInBenefitValue = state.tradeIn.benefitValue
  const benefitValueWithoutTradeIn = state.tradeIn.benefitValueWithoutTradeIn
  const creditBenefitValue = state.credit.benefitValue
  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  let selected

  if (!benefit && !type) {
    selected = benefits
  }

  if (benefit && type === 'enableBenefit') {
    selected = [...benefits]
    selected.push(benefit)
  } else if (benefit && type === 'disableBenefit') {
    selected = benefits.filter(b => b.id !== benefit.id || b.attributes.identity !== benefit.attributes.identity)
  }

  const carPrice = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount: tradeInBenefitValue,
    tradeInBenefitAmountWithoutTradeIn: benefitValueWithoutTradeIn,
    creditBenefitAmount: creditBenefitValue,
  })

  const parameters = state.credit.parameters

  const carModelData = state.car.modelData
  const carProductType = state.car.productType

  return {
    price: carPrice,
    period: Number(parameters.period) || 0,
    initial_fee_amount: Number(parameters.initialFee) || 0,
    initial_fee_percentage: Number(parameters.initialFeePercentage) || 0,
    buyback_payment_amount: Number(parameters.buybackPayment) || 0,
    buyback_payment_percentage: Number(parameters.buybackPaymentPercentage) || 0,
    model_data: carModelData,
    productType: carProductType,
    kasko_insurance: parameters.kasko_insurance,
    life_insurance: parameters.life_insurance,
  }
}

const enableBenefit = payload => (dispatch, getState) => {
  if (!checkBenefitPayload(payload)) {
    return
  }

  const creditData = createCreditData(getState(), payload, 'enableBenefit')

  dispatch(calculateCreditPrograms(creditData))
  dispatch(enableBenefitAction(payload))
  dispatch(logLastMethod('enableBenefit'))
}

const disableBenefit = payload => (dispatch, getState) => {
  if (!checkBenefitPayload(payload)) {
    return
  }

  const creditData = createCreditData(getState(), payload, 'disableBenefit')

  dispatch(calculateCreditPrograms(creditData))
  dispatch(disableBenefitAction(payload))
  dispatch(logLastMethod('disableBenefit'))
}

// Personal data

const changePersonalData = payload => dispatch => {
  if (payload.key === 'phone') {
    dispatch(setPhoneValue({ phone: payload.value, phoneRawValue: payload.rawValue }))
    return
  }

  dispatch(setFormValue(payload.key, payload.value))
  dispatch(logLastMethod(changePersonalData.name))
}

const changePhoneCountry = payload => dispatch => {
  dispatch(setPhoneCountry(payload))
  dispatch(logLastMethod(changePhoneCountry.name))
}

// Create SmartContract

const createSmartContract = payload => (dispatch, getState) => {
  const state = getState()

  const car = state.car
  const currency = state.settings.currency
  const form = state.form
  const fields = state.settings.visual.fields
  const widget_id = state.settings.widget_id
  const locale = state.settings.locale

  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Save',
      Status: 'Submit',
      buttonName: /* e?.target?.innerText || */ 'Не удалось определить текст на кнопке сохранения СК',
      carDetail: {
        identity: car.identity,
        name: car.name,
        modelData: car.modelData,
        productType: car.productType,
      },
      widget_id: widget_id,
      deal_id: '',
      eventValue: car.price,
      currency,
    })

  dispatch(stashFormErrors())
  const errors = formValidation(form, fields, FORM_TYPE_BASE, locale)
  if (errors.length) return

  dispatch(startLoading())

  const dataToSave = createSmartContractData(state)

  createSmartContractRequest(
    widget_id,
    {
      ticket_answer: form.values.captcha || form.values.smsCode,
      ticket_id: form.captchaSettings[FORM_SOURCE.WIDGET].ticketId || form.smsCodeSettings[FORM_SOURCE.WIDGET].ticketId,
    },
    JSON.stringify({ data: dataToSave }),
  )
    .then(({ data }) => {
      if (!data?.id) {
        dispatch(goToScreen(SCREEN_ERROR))
        return
      }

      dispatch(setSmartContractUrl(data.smartContractUrl))
      dispatch(setSmartContractId(data.id))
      dispatch(setSmartContractGenericId(data.genericId))
      dispatch(setSmartContractToken(data.token))
      dispatch(setSmartContractType('widget'))
      dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSave, data)))
      clearSmsAndCaptcha()
      clearProtections(FORM_SOURCE.WIDGET)

      dispatch(goToScreen(SCREEN_SUCCESS))

      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_Save',
          Status: 'Success',
          buttonName: /* e?.target?.innerText || */ 'Не удалось определить текст на кнопке сохранения СК',
          carDetail: {
            identity: car.identity,
            name: car.name,
            modelData: car.modelData,
            productType: car.productType,
          },
          widget_id: widget_id,
          deal_id: `${data.genericId.toUpperCase()}-${data.id}`,
          eventValue: car.price,
          currency,
        })
    })
    .catch(e => {
      clearProtections(FORM_SOURCE.WIDGET)
      initializeErrorsProcessing({ e: e, formSource: FORM_SOURCE.WIDGET })
    })
    .finally(() => dispatch(stopLoading()))

  dispatch(logLastMethod(createSmartContract.name))
}

const showOnlinePayment = payload => (dispatch, getState) => {
  const state = getState()

  window.dataLayer &&
    window.dataLayer.push({
      event: 'SmartContract_Open',
      Status: '',
      buttonName: 'Не удалось определить текст на кнопке на списке',
      carDetail: {
        identity: state.car.identity,
        name: state.car.name,
        modelData: state.car.modelData,
        productType: state.car.productType,
      },
      widget_id: state.settings.widget_id,
      deal_id: '',
      eventValue: state.car.price,
      currency: state.settings.currency,
    })

  dispatch(showWidget())
  dispatch(goToScreen(SCREEN_ONLINE_PAYMENT))
  dispatch(logLastMethod(openWidget.name))

  if (payload && payload.preventBack) {
    dispatch(preventBackFromOnlinePayment())
  }
}

const updateCarList = payload => (dispatch, getState) => {
  const state = getState()

  InitSmartContractOnListV2({
    cars: payload,
    preset: state.settings.preset,
    listVersion: state.settings.listVersion,
    renderButtons: state.settings.renderButtons,
  })
}
