import { FINANCE_USER_BEHAVIOUR, TRADE_IN_USER_BEHAVIOUR } from '../constants'
import { carPriceFromTI, totalPayment as getTotalPaymentData } from './index'

export function getAdditionalCarPrices(state) {
  const initialPrice = state.car.price

  const tradeInEnabledByUser = state.tradeIn.behaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const creditEnabledByUser = state.credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED
  const leasingEnabledByUser = state.leasing.behaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_APPLIED

  const tradeInBenefitValue = state.tradeIn.benefitValue
  const creditBenefitValue = state.credit.benefitValue
  const leasingBenefitValue = state.leasing.benefitValue

  const selectedBenefits = state.benefits.selected

  function getFinalPrice() {
    let finalPrice = initialPrice

    if (tradeInEnabledByUser && tradeInBenefitValue < 0) {
      finalPrice += tradeInBenefitValue
    }

    if (creditEnabledByUser && creditBenefitValue < 0) {
      finalPrice += creditBenefitValue
    }

    if (leasingEnabledByUser && leasingBenefitValue < 0) {
      finalPrice += leasingBenefitValue
    }

    const discountsValue = selectedBenefits
      .filter(benefit => benefit.attributes.value < 0)
      .reduce((accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue, 0)

    const servicesValue = selectedBenefits
      .filter(benefit => benefit.attributes.value > 0)
      .reduce((accumulator, { attributes: { value: currentValue } }) => accumulator + currentValue, 0)

    finalPrice += discountsValue
    finalPrice += servicesValue

    return finalPrice
  }

  function getTotalPayment() {
    const { ownerCarPriceEnabled, ownerCarPrice } = state.tradeIn
    const { possibleCarPrice } = state.tradeIn.rate.result

    const { taxes } = state.settings

    const tradeInPossibleCarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

    const [totalPayment] = getTotalPaymentData({
      price: initialPrice,
      taxes,
      possibleCarPrice: tradeInPossibleCarPrice,
      tradeInBenefitAmount: tradeInBenefitValue,
      tradeInEnabledByUser,
      creditEnabledByUser,
      creditBenefitAmount: creditBenefitValue,
      creditParameters: state.credit.parameters,
      leasingEnabledByUser,
      leasingBenefitAmount: leasingBenefitValue,
      leasingParameters: state.leasing.parameters,
      selected: selectedBenefits,
    })

    return totalPayment
  }

  function getPriceWithAllDiscounts() {
    let totalBenefit = 0

    for (const benefit of state.benefits.benefits) {
      if (benefit.attributes.value > 0) {
        continue
      }

      totalBenefit += benefit.attributes.value
    }

    let financeBenefit = 0

    if (creditBenefitValue < leasingBenefitValue) {
      financeBenefit = creditBenefitValue
    } else {
      financeBenefit = leasingBenefitValue
    }

    totalBenefit += tradeInBenefitValue
    totalBenefit += financeBenefit

    return initialPrice + totalBenefit
  }

  return {
    finalPrice: getFinalPrice(),
    totalPayment: getTotalPayment(),
    priceWithAllDiscounts: getPriceWithAllDiscounts(),
  }
}
