module.exports = {
  accessories: 'Аксессуары',

  showAll: 'Посмотреть все',
  chooseMore: 'Выбрать еще',

  selectedAccessoriesWithCarName: 'Аксессуары, выбранные к Вашему {carName}',
  selectedAccessoriesWithoutCarName: {
    auto: 'Аксессуары, выбранные к Вашему новому автомобилю',
    moto: 'Аксессуары, выбранные к Вашему новому мотоциклу',
    other: 'Аксессуары, выбранные к Вашему новому ТС',
  },

  selectAccessoriesWithCarName: 'Вы можете выбрать дополнительные аксессуары к Вашему {carName}',
  selectAccessoriesWithoutCarName: {
    auto: 'Вы можете выбрать дополнительные аксессуары к Вашему новому автомобилю',
    moto: 'Вы можете выбрать дополнительные аксессуары к Вашему новому мотоциклу',
    other: 'Вы можете выбрать дополнительные аксессуары к Вашему новому ТС',
  },

  addToPurchase: 'Добавить к покупке',

  selectedAccessories: 'Выбранные аксессуары',

  shortDescription: 'Краткое описание',
  fullDescription: 'Полное описание',

  showAllOnScreen: 'Показать все',
  showSelectedOnScreen: 'Показать выбранные',
  removeAllOnScreen: 'Снять выбор',

  whatDoYouNeedToRemove: 'Чтобы добавить данный аксессуар необходимо убрать аксессуар "{accessory}"',
}
