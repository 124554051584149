import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './button.module.scss'

function Button({ handleClick, text, isDisabled, type, id, behaviourType, placement }) {
  const classes = () => {
    let c = [s.button]

    if (type === 'bordered') {
      c.push(s.button_bordered)
    }

    if (!type || type === 'filled' || type === '') {
      c.push(s.button_filled)
    }

    if (placement && placement === 'carOrderForm') {
      c.push(s.button_inherit)
    }

    return c.join(' ')
  }

  return (
    <button
      disabled={isDisabled || false}
      onClick={handleClick}
      className={classes()}
      id={id || ''}
      type={behaviourType || 'button'}
      data-project-name={getProjectName()}
    >
      {text}
    </button>
  )
}

export default Button
