import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { setTradeInBrand, toggleTradeInStep } from '../../../store/actions'
import TradeInInputWithRadio from './Helpers/InputWithRadio'

function TradeInBrand({ step, name, brands, id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInBrand({ id, name }))
  }

  return (
    <div
      className={'kdxsc-accordion-item ' + (step === 'brand' ? 'kdxsc-accordion-item--opened' : '')}
      data-project-name={getProjectName()}
    >
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('brand'))
        }}
        data-project-name={getProjectName()}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.brand.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.brand.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInInputWithRadio name="brand" variants={brands} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      brand: { name, id },
    },
    dictionaries: { brands },
  },
}) {
  return {
    step,
    name,
    id,
    brands,
  }
}

export default connect(mapStateToProps)(TradeInBrand)
