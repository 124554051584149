import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectIdentityHelper = (preset, detail, config) => {
  if (preset) {
    const [platform, identity] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.identity(identity)
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  // detail identity
  if (detail?.identity) {
    if (typeof detail.identity === 'string') {
      return detail.identity
    } else if (typeof detail.identity === 'function') {
      return detail.identity()
    }
  }

  // old function - config identity
  if (config.identity) {
    if (typeof config.identity === 'string') {
      return config.identity
    } else if (typeof config.identity === 'function') {
      return config.identity()
    }
  }

  return ''
}
