const CURRENCY_RUSSIAN_RUBLES = 'RUB'
const LOCALE_RU_RU = 'ru-RU'

export const formatPrice = (price, currency) => {
  if (price === undefined) {
    return 0
  }

  const locale = () => {
    // https://github.com/supukarmin/navigator-languages#readme

    const getNavigatorLanguages = () => {
      if (typeof navigator === 'object') {
        let t = 'language',
          n = navigator,
          f
        f = n['l' + t + 's']

        // eslint-disable-next-line no-cond-assign
        return f && f.length ? f : (t = n['l' + t] || n['browserL' + t] || n['userL' + t]) ? [t] : t
      }
    }

    const locale = getNavigatorLanguages()

    if (Array.isArray(locale) && locale.length > 0) {
      return locale[0]
    }

    return locale || LOCALE_RU_RU
  }

  const options = {
    style: 'currency',
    currency: currency || CURRENCY_RUSSIAN_RUBLES,
    minimumFractionDigits: 2,
  }

  return price.toLocaleString(locale(), options)
}
