import { useCallback, useEffect, useRef, useState } from 'react'

import DropdownArrow from '../../../../svgComponents/dropdownArrow'
import s from './dropdown.module.scss'

function Dropdown(props) {
  const [isOpened, setIsOpened] = useState(false)

  const select = useRef(null)
  const dropdown = useRef(null)

  const handleSelectClick = e => {
    e.preventDefault()
    setIsOpened(isOpened => !isOpened)
  }

  // закрывать дропдаун на клики за его пределами
  const handleOutsideClick = event => {
    if (dropdown.current.contains(event.target)) {
      return
    }

    if (select.current.contains(event.target)) {
      return
    }

    setIsOpened(false)
  }

  // открыть или закрыть дропдаун
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      setIsOpened(isOpened => !isOpened)
      select.current.focus()
    }
  }

  const handleOutsideKey = event => {
    if (event.key === 'Escape') {
      setIsOpened(false)
      select.current.focus()
    }
  }

  const listener = useCallback(e => {
    setIsOpened(false)
  }, [])

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [isOpened])

  useEffect(() => {
    window.addEventListener(`kdxscCloseDropdown`, listener)

    return () => {
      window.removeEventListener(`kdxscCloseDropdown`, listener)
    }
  }, [listener])

  useEffect(() => {
    if (isOpened) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isOpened])

  return (
    <div className={[s.fieldBox, props.useWidgetStyles ? s.fieldBoxInWidget : ''].join(' ')}>
      <div className={[s.dropdownBox, props.disabled ? s.dropdownBox_disabled : ''].join(' ')}>
        <div ref={select} onClick={handleSelectClick} onKeyDown={handleKeyDown}>
          <input
            value={props.value || ''}
            className={[s.input, props.errors ? s.inputWithErrors : ''].join(' ')}
            id={props.id}
            name={props.name}
            placeholder=" "
            type="text"
            autoComplete="off"
            readOnly={true}
          />
          <label htmlFor={props.id} className={s.label}>
            {props.label} {props.required && '*'}
          </label>
          <div className={s.icon}>
            <div className={[s.icon__arrow, isOpened ? s.icon__arrow_opened : ''].join(' ')}>
              <DropdownArrow />
            </div>
          </div>
        </div>

        <div
          className={[s.dropdownList, isOpened ? s.dropdownList_opened : s.dropdownList_closed].join(' ')}
          ref={dropdown}
        >
          {props.variants}
        </div>
      </div>
      {props.errorsText && <div className={s.errorsText}>{props.errorsText}</div>}
    </div>
  )
}

export default Dropdown
