module.exports = {
  title: 'Получить предложение',
  send: 'Отправить',
  dealership: 'Дилерский центр',
  dealershipError: 'Выберите дилерский центр, чтобы продолжить',

  requiredFiled: 'Это поле обязательно для заполнения',
  incorrectFiled: 'Поле заполнено некорректно',
  promoCodeError: 'Можно использовать цифры и латинские буквы, максимум 15 символов',

  personalData: 'Личные данные',
  contacts: 'Контактная информация',

  car: 'Автомобиль',
  model: 'Модель',

  search: 'Поиск',
  nothingFound: 'Ничего не найдено',
}
