import _debounce from 'lodash/debounce'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { connect, useDispatch, useSelector } from 'react-redux'

import { TRADE_IN_SETTINGS_BEHAVIOR } from '../../../constants'
import { FORM_SOURCE } from '../../../constants'
import {
  clearProtections,
  createdEventPayload,
  createSmartContractWithProtection,
  formatCurrency,
  initializeErrorsProcessing,
  makeNumber,
  updateTradeInOwnerCarPrice,
} from '../../../helpers'
import logo from '../../../icons/logo.svg'
import tradein from '../../../icons/tradein.svg'
import {
  goToSmartContractFromWidget,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  setTradeInOwnerCarPrice,
  smartContractCreated,
  startLoading,
  stopLoading,
} from '../../../store/actions'
import { createSmartContractData } from '../../../store/helpers/smart_contract'
import BorderedBlock from '../../ui/borderedBlock'
import Button from '../../ui/button'
import DisclaimerWithVideo from '../../ui/disclaimerWithVideo'
import LineInput from '../../ui/lineInput'
import LineSelect from '../../ui/lineSelect'
import PersonalData from '../../ui/personalData'
import s            from './styles/screen.module.scss'

function TradeInStandaloneFinalScreen({
  models,
  standaloneCar,
  standalonePrice,
  widgetId,
  idSC,
  urlSC,
  typeSC,
  tokenSC,
  currency,
  ownerCarPrice,
  genericId,
  ownerCarPriceEnabled,
  ownerCarPriceOwnerHintText,
  priceToolTipEnabled,
  behaviour,
  limitForSaving,
  vehicleType,
  isPriceHidden,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // ошибки при сохранении СК
  const [hasErrorWhileSavingSC, setHasErrorWhileSavingSC] = useState(false)
  // хочет ли пользователь указать свою цену за авто в ТИ
  const [userWantsHisOwnCarPrice, setUserWantsHisOwnCarPrice] = useState(false)
  // есть ли ссылка в раб лист
  const disableSCLink = useSelector(state => state.settings.disableSmartContractLink)
  // есть ли ссылка перехода в СК
  const isUrlOk = disableSCLink ? true : Boolean(urlSC)
  // сохранен ли СК из этого виджета
  const isSCSavedFromThisWidget = Boolean(idSC) && isUrlOk && typeSC === 'tradeInStandalone'
  const dataToSave = useSelector(state => createSmartContractData(state, 'TradeInStandalone'))
  // можно ли показать кнопку перехода в СК
  const showButton = () => {
    if (limitForSaving) {
      return false
    }

    return isSCSavedFromThisWidget && disableSCLink
      ? false
      : !isSCSavedFromThisWidget || (isSCSavedFromThisWidget && urlSC)
  }
  // если доступ в СК запрещен, то нельзя менять авто и цену
  const isDisabledAfterSaving = isSCSavedFromThisWidget && disableSCLink

  /**
   * текст на основной кнопке, имеет 3 состояния
   * 1 - если СК еще не сохранен, 2 - СК сохранен, 3 - пользователь указывает свою цену за ТИ
   * @returns {string}
   */
  const buttonText = () => {
    if (userWantsHisOwnCarPrice && ownerCarPrice > 0) {
      return t('tradeInS.openSCStep3')
    } else {
      return isSCSavedFromThisWidget ? t('tradeInS.openSCStep2') : t('tradeInS.openSCStep1')
    }
  }

  /**
   * Текст под автоматической ценой за ТИ
   * @returns {string}
   */
  const averageCarPriceText = () => {
    if (standalonePrice) {
      return t('tradeInS.averageCarPriceText')
    } else if (behaviour === TRADE_IN_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
      return t('tradeIn.rateResultZeroPrice')
    } else {
      return t('tradeInS.averageCarZeroPriceTextDisabled')
    }
  }

  /**
   * Установка своей цены за ТИ - 0 передавать на БЭ нельзя
   * @param v - цена
   */
  const setTradeInOwnerCarPriceHandler = v => {
    let value = makeNumber(v)
    if (!Number.isInteger(value)) {
      return
    }
    if (value < 0) {
      return
    }

    dispatch(setTradeInOwnerCarPrice(v))

    setOwnCarPriceToBE(value)
  }

  /**
   * Нажатие на крестик в свой цене за ТИ
   * Цена = 0, на БЭ не уходит
   */
  const userDoesNotWantHisOwnCarPrice = () => {
    setUserWantsHisOwnCarPrice(false)
    dispatch(setTradeInOwnerCarPrice(0))
    setOwnCarPriceToBE(0)
  }

  /**
   * Отправляет свою цену на БЭ
   * @param value
   */
  const setOwnCarPriceToBE = value => {
    const uniqueId = `${genericId.toUpperCase()}-${idSC}`

    if (isSCSavedFromThisWidget && !disableSCLink) {
      updateTradeInOwnerCarPrice(uniqueId, tokenSC, value)
        .then(_ => {})
        .catch(e => {
          console.error(e)
        })
        .finally()
    }
  }

  /**
   * Создание СК
   */
  const createSC = () => {
    setHasErrorWhileSavingSC(false)
    clearProtections(FORM_SOURCE.TRADE_IN_STANDALONE)

    // если уже есть СК, созданный здесь, то открываем его
    if (idSC && isUrlOk && typeSC === 'tradeInStandalone') {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_OnlineDeal',
          Status: 'tradeInStandalone',
          buttonName: buttonText(),
          widget_id: widgetId,
          deal_id: `${genericId}-${idSC}`,
          eventValue: '',
        })

      // переходим в него только если есть ссылка
      if (!disableSCLink) {
        dispatch(goToSmartContractFromWidget())

        let a = document.createElement('a')
        a.href = urlSC
        a.target = '_blank'
        a.click()
        a.remove()
      }

      return
    }

    dispatch(startLoading())

    createSmartContractWithProtection(
      widgetId,
      { ticket_answer: '', ticket_id: '' },
      JSON.stringify({ data: dataToSave }),
    )
      .then(({ data }) => {
        if (data) {
          dispatch(setSmartContractUrl(data.smartContractUrl))
          dispatch(setSmartContractId(data.id))
          dispatch(setSmartContractGenericId(data.genericId))
          dispatch(setSmartContractToken(data.token))
          dispatch(setSmartContractType('tradeInStandalone'))
          dispatch(smartContractCreated(createdEventPayload(widgetId, dataToSave, data)))

          if (data.smartContractUrl) {
            dispatch(goToSmartContractFromWidget())

            window.dataLayer &&
              window.dataLayer.push({
                event: 'SmartContract_Create',
                Status: 'tradeInStandalone',
                buttonName: buttonText(),
                widget_id: widgetId,
                deal_id: `${data.genericId}-${data.id}`,
                eventValue: '',
              })

            let a = document.createElement('a')
            a.href = data.smartContractUrl
            a.target = '_blank'
            a.click()
            a.remove()
          }
        } else {
          setHasErrorWhileSavingSC(true)
        }
      })
      .catch(e => {
        initializeErrorsProcessing({
          e: e,
          formSource: FORM_SOURCE.TRADE_IN_STANDALONE,
          callbackAction: setHasErrorWhileSavingSC,
          additional: {
            tradeInStandaloneStep: 'saveScreen',
          },
        })
        // setHasErrorWhileSavingSC(true)
      })
      .finally(() => {
        dispatch(stopLoading())
      })
  }

  /**
   * Блок со своей ценой
   * @returns {*}
   */
  const ownCarPriceContent = () => {
    if (userWantsHisOwnCarPrice) {
      return (
        <div className={s.ownPrice}>
          <NumberFormat
            className={s.ownPrice__input}
            value={ownerCarPrice}
            onChange={_debounce(e => setTradeInOwnerCarPriceHandler(e.target.value), 250)}
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator={' '}
            decimalScale={0}
            placeholder={t('tradeInS.ownerCarPricePlaceholder')}
            isNumericString={false}
            suffix={` ${formatCurrency(currency)}`}
            maxLength={15}
            defaultValue={''}
            autoComplete="off"
          />
          <div className={s.ownPrice__close} onClick={() => userDoesNotWantHisOwnCarPrice()}>
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.50011 4.82166L9.62511 0.696655L10.8034 1.87499L6.67844 5.99999L10.8034 10.125L9.62511 11.3033L5.50011 7.17832L1.37511 11.3033L0.196777 10.125L4.32178 5.99999L0.196777 1.87499L1.37511 0.696655L5.50011 4.82166Z"
                fill="#222222"
              />
            </svg>
          </div>
        </div>
      )
    } else {
      return (
        <Button
          text={t('tradeInS.ownerCarPriceButton')}
          type={'bordered'}
          handleClick={() => setUserWantsHisOwnCarPrice(true)}
        />
      )
    }
  }

  return (
    <div className={s.finalScreen}>
      <div className={s.finalScreen__data}>
        <div className={s.data}>
          {isPriceHidden && <p className={s.centered}>{t('tradeInS.averageCarZeroPriceTextDisabled')}</p>}
          {!isPriceHidden && (
            <div className={s.data__item}>
              <LineInput
                label={averageCarPriceText()}
                value={standalonePrice}
                id="kdxscTiSYourCarPrice"
                disabled={true}
              />
            </div>
          )}
          {models.length > 0 && !isPriceHidden && (
            <div className={s.data__item}>
              <img src={tradein} alt="Trade In" title="Trade In" />
            </div>
          )}
          {models.length > 0 && !isPriceHidden && (
            <div className={s.data__item}>
              <LineSelect
                label={isDisabledAfterSaving ? ' ' : t('tradeInS.desiredCarText')}
                value={standaloneCar}
                id="kdxscTiSDesiredCar"
                disabled={(isSCSavedFromThisWidget && disableSCLink && isDisabledAfterSaving) || limitForSaving}
              />
            </div>
          )}
        </div>
      </div>
      {ownerCarPriceEnabled && !isDisabledAfterSaving && !limitForSaving && (
        <div className={s.finalScreen__ownPrice}>
          {ownerCarPriceOwnerHintText && <div className={s.ownPrice_hint}>{ownerCarPriceOwnerHintText}</div>}
          <div className={s.button}>{ownCarPriceContent()}</div>
        </div>
      )}
      {showButton() && (
        <div className={s.finalScreen__button}>
          <div className={s.button}>
            <Button handleClick={createSC} text={buttonText()} />
          </div>
        </div>
      )}
      {(hasErrorWhileSavingSC || Boolean(limitForSaving)) && (
        <div className={[s.finalScreen__hasError, priceToolTipEnabled ? 'kdxsc-mb-20' : 'kdxsc-mb-60'].join(' ')}>
          {limitForSaving ? t('error.limit') : t('ccS.actions.error')}
        </div>
      )}
      {isSCSavedFromThisWidget && (
        <div className={[s.finalScreen__hasSuccess, priceToolTipEnabled ? 'kdxsc-mb-20' : 'kdxsc-mb-60'].join(' ')}>
          {disableSCLink ? t('tradeInS.successTextWithoutLink') : t('tradeInS.successText')}
        </div>
      )}
      {priceToolTipEnabled && <div className={s.finalScreen__priceTooltip}>{t(`car.tooltipAlert.${vehicleType}`)}</div>}
      <div className={s.finalScreen__personalData}>
        <PersonalData disabled={Boolean(limitForSaving)} />
      </div>
      <div className={s.finalScreen__disclaimer}>
        <div className={s.disclaimer}>
          <BorderedBlock icon={logo} inner={<DisclaimerWithVideo />} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  settings: { currency, widget_id: widgetId, limitForSaving, vehicleType },
  tradeIn: {
    standaloneCar,
    standalonePrice,
    ownerCarPrice,
    ownerCarPriceEnabled,
    ownerCarPriceOwnerHintText,
    condition: { behaviour },
  },
  saved_sc: { id: idSC, url: urlSC, type: typeSC, genericId, token: tokenSC },
  clientModels: { models },
  tradeInStandalone: { priceToolTipEnabled, visual },
}) {
  return {
    standalonePrice,
    standaloneCar,
    currency,
    widgetId,
    idSC,
    genericId,
    tokenSC,
    urlSC,
    typeSC,
    models,
    ownerCarPrice,
    ownerCarPriceEnabled,
    ownerCarPriceOwnerHintText,
    priceToolTipEnabled,
    behaviour,
    limitForSaving: limitForSaving[FORM_SOURCE.TRADE_IN_STANDALONE],
    vehicleType,
    isPriceHidden: visual.isPriceHidden,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneFinalScreen)
