import { TRADE_IN_USER_BEHAVIOUR } from '../../constants'

// закомментированное - deprecated

/* const _calculateAveragePrice = result => {
  if (!result) return 0

  const { from, to } = result
  return from + (to - from) / 2
} */

// Функция, которая мержит состояние трейд-ин OP и Смарт-контрактов
const mergeStates = (smartState, tradeInState) => {
  if (!tradeInState) return

  const {
    domainData: { dictionaries, values, rate, enabled },
    appState: { currentStep, weight },
  } = tradeInState

  const newSmartState = { ...smartState }

  // const evaluationType = smartState.rate.evaluationType;
  // const evaluationType = 'trade_in' //

  newSmartState.behaviourByUser =
    rate.status === 'success' ? TRADE_IN_USER_BEHAVIOUR.APPLIED : TRADE_IN_USER_BEHAVIOUR.NOT_REQUIRED

  newSmartState.values = {
    ...values,
    city: {
      id: String(values.city.id),
      name: values.city.name,
    },
    mileage: {
      value: String(values.mileage.value),
    },
  }
  //newSmartState.dictionaries = dictionaries
  newSmartState.dictionaries = {
    ...dictionaries,
    colors: [],
  }
  newSmartState.step = currentStep
  newSmartState.weight = weight
  newSmartState.rate = {
    status: rate.status,
    // evaluationType,
    result: {
      estimated: rate.result.estimated,
      tradeIn: rate.result.tradein,
      possibleCarPrice: rate.price,
      // possibleCarPrice: _calculateAveragePrice(rate.result[evaluationType]),
    },
    enabled,
  }

  return newSmartState
}

export default mergeStates
