export const setPreviousScreen = screen => (dispatch, getState) => {
  dispatch({
    type: 'SET_PREVIOUS_SCREEN',
    screen,
  })
}

export const setPreviousAction = action => (dispatch, getState) => {
  dispatch({
    type: 'SET_PREVIOUS_ACTION',
    action,
  })
}

export const setAdditionalData = additionalData => (dispatch, getState) => {
  dispatch({
    type: 'SET_ADDITIONAL_DATA',
    additionalData,
  })
}
