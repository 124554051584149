import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'

import { goToIndex } from '../../store/actions'
import Button from '../Ui/button'

function PaymentFailed({ saved_sc }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (saved_sc.url) {
      window.open(saved_sc.url, '_blank')
    } else {
      dispatch(goToIndex())
    }
  }

  const buttonText = saved_sc.url ? t('paymentFailed.goToSmartContract') : t('error.retry')

  return (
    <div className="kdxsc-payment-failed-screen">
      <div className="kdxsc-payment-failed-screen__icon">
        <div className="kdxsc-payment-failed-screen-icon" />
      </div>
      <div className="kdxsc-payment-failed-screen__text">{t('paymentFailed.description')}</div>
      <div className="kdxsc-payment-failed-screen__buttons kdxsc-payment-failed-screen-buttons">
        <div className="kdxsc-payment-failed-screen-buttons__item">
          <Button handleClick={handleClick} text={buttonText} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ saved_sc }) {
  return {
    saved_sc,
  }
}

export default connect(mapStateToProps)(PaymentFailed)
