export const isItAvailableToReplaceAvnForm = dealerships => {
  let statuses = []

  dealerships.forEach(d => {
    d.cars.forEach(c => {
      if (!statuses.includes(c.status)) {
        statuses.push(c.status)
      }
    })
  })

  return statuses.includes('available')
}
