module.exports = {
  isPriceHiddenHint: 'К сожалению, на данный момент расчет невозможен',
  generalError: {
    c: 'Ошибка инициализации кредитного калькулятора: отсутствуют данные об автомобилях',
    l: 'Ошибка инициализации лизингового калькулятора: отсутствуют данные об автомобилях',
  },

  title: {
    c: 'КРЕДИТНЫЙ КАЛЬКУЛЯТОР',
    l: 'ЛИЗИНГОВЫЙ КАЛЬКУЛЯТОР',
  },

  dealerships: {
    dealership: 'Дилерский центр',
    chooseDealership: 'Выберите дилерский центр',
  },
  dealershipsCity: {
    dealershipCity: 'Город',
    any: 'Любой',
    chooseCity: 'Выберите город',
  },

  conditions: {
    title: {
      c: 'Выберите условия кредита',
      l: 'Выберите условия лизинга',
    },
    model: 'Модель автомобиля',
    brand: 'Бренд автомобиля',
    complectation: 'Комплектация автомобиля',
    chooseModel: 'Выберите модель автомобиля',
    chooseBrand: 'Выберите бренд автомобиля',
    chooseComplectation: 'Выберите комплектацию автомобиля',
    notFound: 'Ничего не найдено',
    price: 'Стоимость автомобиля',
    initialFee: {
      c: 'Первоначальный взнос',
      l: 'Аванс',
    },
    buybackPayment: 'Остаточный платеж',
    buybackPaymentHint:
      'Остаточный платеж – это сумма в процентах от стоимости транспортного средства, выплата которой переносится на конец срока кредитования',
    pickACredit: {
      c: 'Подобрать кредит',
      l: 'Подобрать лизинг',
    },
    period: {
      c: 'Срок кредитования',
      l: 'Срок лизинга',
    },
  },

  personal: {
    title: {
      c: 'Укажите свои данные для персонализации кредитного предложения',
      l: 'Укажите свои данные для персонализации лизингового предложения',
    },
    goBackButton: 'Вернуться',
    showProgramsButton: 'Показать подходящие программы',
    noDataText: {
      c: 'Для показа персонального кредитного предложения, нам необходимы ваши данные и согласие на коммуникацию',
      l: 'Для показа персонального лизингового предложения, нам необходимы ваши данные и согласие на коммуникацию',
    },
    yourSuitableProgramsP1V1: 'Вам подходит',
    yourSuitableProgramsP1V2: 'Вам подходят',
    yourSuitableProgramsP2: 'Еще',
    yourSuitableProgramsP2V1: {
      c: 'кредитная',
      l: 'лизинговая',
    },
    yourSuitableProgramsP2V2: {
      c: 'кредитные',
      l: 'лизинговые',
    },
    yourSuitableProgramsP2V3: {
      c: 'кредитных',
      l: 'лизинговых',
    },
    yourSuitableProgramsP3V1: 'программа',
    yourSuitableProgramsP3V2: 'программы',
    yourSuitableProgramsP3V3: 'программ',
  },

  programs: {
    error: 'Необходимо выбрать программу',
    view: 'Посмотреть',
    continue: 'Продолжить',
    hide: 'Свернуть',
    bankName: 'Название банка',
    fromThisBank: 'от этого банка',
  },

  actions: {
    autoCalculationDisabled: 'Автоматический расчет невозможен. Попробуйте поменять условия',
    autoCalculationDisabledTechError:
      'Автоматический расчет невозможен. Ведутся технические работы. Попробуйте воспользоваться калькулятором позже',
    weSavedYourConditions: 'Мы сохранили ваши условия ✓',
    managerWillContactYou: 'Менеджер свяжется с вами в ближайшее время',
    error: 'Произошла ошибка. Ведутся технические работы. Попробуйте воспользоваться калькулятором позже',
  },

  popupCreditGeneralError: 'К сожалению, произошла ошибка при загрузке калькулятора',
}
