import { TRADE_IN_DEFAULT_CITY_PREFIX } from '../../constants'
import { TRADE_IN_STANDALONE_TYPES } from '../types'

export const TRADE_IN_STANDALONE_INIT_STATE = {
  dealerships: [],
  activeDealership: {},
  visual: {},
  defaultCities: [],

  locale: '',

  isWidgetLoaded: false, // загрузился ли виджет при смене ДЦ с БЭ
  isWidgetLoadingFailed: false, // есть ли ошибка при загрузке виджета при смене ДЦ

  priceToolTipEnabled: false,
}

const tradeInStandalone = (state = TRADE_IN_STANDALONE_INIT_STATE, action) => {
  switch (action.type) {
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_DEALERSHIPS:
      return {
        ...state,
        dealerships: action.payload,
      }
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_ACTIVE_DEALERSHIP:
      return {
        ...state,
        activeDealership: action.payload,
      }
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADED:
      return {
        ...state,
        isWidgetLoaded: action.payload,
      }
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_IS_WIDGET_LOADING_FAILED:
      return {
        ...state,
        isWidgetLoadingFailed: action.payload,
      }
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_LOCALE:
      return {
        ...state,
        locale: action.payload,
      }
    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_PRICE_TOOLTIP_ENABLED:
      return {
        ...state,
        priceToolTipEnabled: action.payload,
      }

    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_VISUAL:
      return {
        ...state,
        visual: action.payload,
      }

    case TRADE_IN_STANDALONE_TYPES.SET_TRADE_IN_STANDALONE_DEFAULT_CITIES:
      // eslint-disable-next-line no-case-declarations
      const isPayloadArray = action.payload && Array.isArray(action.payload) && action.payload.length
      // eslint-disable-next-line no-case-declarations
      const defaultCities = isPayloadArray
        ? action.payload.map((p, index) => {
            return {
              id: TRADE_IN_DEFAULT_CITY_PREFIX + index,
              name: p,
              external: '',
            }
          })
        : null

      return {
        ...state,
        defaultCities,
      }
    default:
      return state
  }
}

export default tradeInStandalone
