import { setFormValue, setPhoneValue } from '../../../store/actions'
import store from '../../../store/store'
import { isEmailInFormValid, isNamesInFormValid } from '../../form_validation'

/**
 * Могут прийти данные из другого локалстораджа
 * Они приоритетнее
 * @returns {{lastName: string, phone: string, name: string, email: string, secondName: string}}
 */
export const dataFromInterlayerFormatted = () => {
  const res = {
    name: '',
    lastName: '',
    secondName: '',
    email: '',
    phone: '',
  }

  const dataFromInterlayer = localStorage.getItem('interlayer')
  if (!dataFromInterlayer) {
    return res
  }

  const userData = JSON.parse(dataFromInterlayer)?.userData?.user
  if (!userData) {
    return res
  }

  res['name'] = userData?.first_name || ''
  res['lastName'] = userData?.last_name || ''
  res['secondName'] = userData?.patronymic || ''
  res['phone'] = userData?.phone || ''
  res['email'] = userData?.email?.address || ''

  return res
}

export const formSetter = (fieldsSettings, locale, dataFromLocalStorage) => {
  const interlayerLS = dataFromInterlayerFormatted()
  const formLS = dataFromLocalStorage?.form?.values

  const mergedLSData = { ...formLS }
  for (const key in interlayerLS) {
    if (interlayerLS.hasOwnProperty(key)) {
      mergedLSData[key] = interlayerLS[key] || formLS[key]
      store.dispatch(setFormValue(key, mergedLSData[key]))
    }
  }

  if (!mergedLSData) {
    return
  }

  if (mergedLSData.phone) {
    const formattedPhone = mergedLSData?.phone[0] == '7' ? mergedLSData?.phone.substring(1) : mergedLSData.phone
    store.dispatch(setFormValue('phone', mergedLSData.phone))
    store.dispatch(
      setPhoneValue({
        phoneRawValue: formattedPhone,
        phone: mergedLSData.phone,
      }),
    )
  } else {
    store.dispatch(setFormValue('phone', ''))
    store.dispatch(
      setPhoneValue({
        phoneRawValue: '',
        phone: '',
      }),
    )
  }

  // check name and delete its value from store if it is not valid
  let nameLS = mergedLSData.name
  let nameSettings = fieldsSettings.name
  if (!isNamesInFormValid(nameLS, nameSettings?.required_on_base_form, locale)) {
    store.dispatch(setFormValue('name', ''))
  }

  // check lastName and delete its value from store if it is not valid
  let lastNameLS = mergedLSData.lastName
  let lastNameSettings = fieldsSettings.lastName
  if (!isNamesInFormValid(lastNameLS, lastNameSettings?.required_on_base_form, locale)) {
    store.dispatch(setFormValue('lastName', ''))
  }

  // check secondName and delete its value from store if it is not valid
  let secondNameLS = mergedLSData.secondName
  let secondNameSettings = fieldsSettings.secondName
  if (!isNamesInFormValid(secondNameLS, secondNameSettings?.required_on_base_form, locale)) {
    store.dispatch(setFormValue('secondName', ''))
  }

  // check email and delete its value from store if it is not valid
  let emailLS = mergedLSData.email
  let emailSettings = fieldsSettings.email
  if (!isEmailInFormValid(emailLS, emailSettings?.required_on_base_form)) {
    store.dispatch(setFormValue('email', ''))
  }

  store.dispatch(setFormValue('smsCode', ''))
  store.dispatch(setFormValue('captcha', ''))
  store.dispatch(setFormValue('promoCode', ''))
}
