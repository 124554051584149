import { TRADE_IN_USER_BEHAVIOUR } from '../../constants'

export function setCarList(payload) {
  return { type: 'SET_CAR_LIST', payload }
}

export function updateCarPrices() {
  return function (dispatch, getState) {
    const state = getState()

    const tradeInBehaviourByUser = state.tradeIn.behaviourByUser

    const tradeInApplied = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

    const payload = state.cars.list.map(car => {
      let finalPrice = car?.carData?.price || 0

      if (tradeInApplied) {
        finalPrice = finalPrice - state.tradeIn.rate.result.possibleCarPrice - car.carData.discounts?.tradein || 0
      }

      return {
        ...car,
        finalPrice,
      }
    })

    dispatch({ type: 'UPDATE_CAR_PRICES', payload })
  }
}
