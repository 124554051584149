export const localeDe = {
  car: require('./car'),
  trade_in: require('./trade_in'),
  bullet: require('./bullet'),

  tradeInS: require('./trade_in_standalone'),
  ccS: require('./credit_standalone'),
  leasing: require('./leasing'),
  dealerships: require('./dealerships'),
  accessories: require('./accessories'),

  carOrderForm: require('./carOrderForm'),

  calculatePurchase: 'Calculate purchase', // !!!

  loading: 'Warten ...',
  generalError: 'Unfortunately, an error has been occurred while loading', // !!!
  free: 'kostenlos',
  edit: 'Bearbeiten',
  from: 'vom',
  months: 'Mon.',
  terms: 'Bedingungen',
  benefits: {
    title: 'Zusätzliche Vorteile',
    additionalOptions: '!!!Additional equipment',
    additionalOptionsWithoutList: '!!!The cost of the installed additional equipment',
  },

  auth: {
    enterPersonalDataBeforeContinue: 'Um die Bedingungen zu speichern, bitte Formular ausfüllen.',
    continue: 'Weiter',
  },

  benefitsList: {
    saveTermsUntil48Hours: 'Bedingungen werden für 48 Stunden gespeichert',
    personalManager: 'Persönlicher Ansprechpartner',
    // smsAndStatus: 'SMS-link aufs Fahrzeug ermöglicht die Fahrzeugverfolgung',
    smsAndStatus: {
      auto: 'SMS-link to this car and tracking its status',
      moto: 'SMS-link to this motorcycle and tracking its status',
      other: 'SMS-link to this vehicle and tracking its status',
    },
    saveTermsUntilTneEnd: 'Speicherung der Bedingungen bis zum Abschluss der Finanzierung',
    // carBooking: '100% Buchung des Fahrzeugs',
    carBooking: {
      auto: '100% car booking',
      moto: '100% motorcycle booking',
      other: '100% vehicle booking',
    },
    returnOnlinePaymentEasily: 'Einfache Rückgabe der Vorauszahlung',
  },

  credit: {
    ...require('./credit'),
    creditForAmount: 'Finanzierungssumme { amount }',
    creditWithBenefit: 'Ihr Vorteil für die Finanzierung { value } ist schon berücksichtigt',
    creditWithTradeInDescription: {
      auto: 'The cost of your car in Trade-in has already been included into initial payment',
      moto: 'The cost of your motorcycle in Trade-in has already been included into initial payment',
      other: 'The cost of your vehicle in Trade-in has already been included into initial payment',
    },
    // creditWithTradeInDescription:
    //   'Die Kosten Ihres Autos bei Inzahlungnahme sind bereits in der Anfangszahlung enthalten',
    initialFee: 'Anzahlung',
    buybackPayment: 'Restzahlung',
    buybackPaymentHint:
      'Die Restzahlung ist der Betrag in Prozent des Fahrzeugwertes, dessen Zahlung auf das Ende der Finanzierungslaufzeit übertragen wird',
    creditPeriod: 'Finanzierungslaufzeit',

    enabledWithoutCalculations: {
      screenHeader: 'Sie können die für Sie besten Finanzierungsart auswählen',
      screenText:
        'Leider ist die automatische Berechnung eines Finanzierungsangebotes nicht möglich. Ihre eingegebenen Daten werden jedoch an Ihren Ansprechpartner übermittelt, der für Sie ein geeignetes Finanzierungsangebot ausarbeitet.',
    },

    periods: {
      oneYear: '1 Jahr',
      twoYears: '2 Jahre',
      threeYears: '3 Jahre',
      fourYears: '4 Jahre',
      fiveYears: '5 Jahre',
      sixYears: '6 Jahre',
      sevenYears: '7 Jahre',
      eightYears: '8 Jahre',
    },

    additionalOptions: 'Zusätzliche Möglichkeiten',
    lifeInsurance: 'Alle Zahlungen sind gesichert',
    withoutKasko: 'Finanzierung ohne Kasko',

    searchDisabledHeader: 'Automatische Suche der Finanzierungsprogrammen ist nicht verfügbar',
    searchDisabledContent:
      'Sie können Ihre Finanzierungsoptionen speichern. Ihr Ansprechpartner wählt für Sie das passende Finanzierungsangebot aus.',

    searchNoProgramsHeader: 'Es wurde leider keine Finanzierungsprogramme gefunden',
    searchNoProgramsContent: 'Versuchen Sie andere Optionen für die Finanzierung auszuwählen',

    searchFailedHeader: 'Leider ist ein Fehler passiert, während der Suche nach den Finanzierungsprogrammen',
    searchFailedContent:
      'Bitte wählen Sie andere Optionen für die Finanzierung aus, oder versuchen Sie es erneut später erneut.',

    saveParameters: 'Optionen speichern',

    chooseCreditProgram: 'Wählen Sie bitte eines der gefundenen Finanzierungsangebot aus { count })',
    pricePerMonth: '{ value }/Mon.',
    choose: 'Auswählen',
    onlineApproval: 'Online Genehmigung',
    onlineApprovalHint:
      'Wählen Sie, die für Sie interessanten Anzahlung<br/>, Laufzeit und Finanzierungsart <br/>, aus',
    redemptionPrice: 'Rücknahmepreis',
    moreAboutProgram: 'Mehr über das Programm',
  },

  error: {
    description: 'Leider ist ein Fehler beim Speichern passiert.',
    retry: 'Erneut versuchen',
    limit: "Sorry, that's enough for today, let's try again tomorrow", // !!!
  },

  success: {
    description:
      'Danke! Ihr Ansprechpartner setzt sich umgehend mit Ihnen in Verbindung, um die Bedingungen der Finanzierung zu klären. Wir haben Ihre Berechnung gespeichert und Ihnen einen Link aufs Handy geschickt. Mit diesem Link können Sie jeder Zeit das Finanzierungsangebot ändern oder die Änderungen, die von Ihrem Ansprechpartner eingetragen wurde, anschauen.',
    descriptionWithoutLink:
      'Danke! Ihr Ansprechpartner setz sich umgehend mit Ihnen in Verbindung, um die Bedingungen des Finanzierungsangebotes zu klären.',
    goToSmartContract: 'Vorschau Ihrer gespeicherten Konditionen',
    backToSite: 'Zurück zur Seite',
  },

  paymentStart: {
    description:
      'Danke! Ihr Ansprechpartner setz sich umgehend mit Ihnen in Verbindung, um die Bedingungen des Finanzierungsangebotes zu klären. Wir haben Ihre Berechnung gespeichert und Ihnen einen Link auf Ihr Handy geschickt. Mit diesem Link können Sie jeder Zeit das Finanzierungsangebot ändern oder die Änderungen, die von Ihrem Ansprechpartner eingetragen wurde, anschauen.',
    redirectDescription:
      'Через {count, plural, one {# секунду} few {# секунды} other {# секунд}} вы будете перенаправлены на страницу онлайн оплаты.', //Исправить на немецкий
    redirectDescriptionFail: 'Wenn die Weiterleitung nicht erfolgt ist, klicken Sie auf die Schaltfläche',
    goToOnlinePayment: 'Zur Online-Zahlung',
  },

  paymentFailed: {
    description:
      'Bei der Generierung des Zahlungslinks ist leider ein Fehler passiert. Bitte folgen Sie diesem Link, um es erneut zu versuchen.',
    goToSmartContract: 'Mit Bearbeitung fortfahren',
  },

  onlinePayment: {
    whatHappensIfMyOpinionChanged:
      'Die Bearbeitung dauert 2 Werktage, wenn eine Rückerstattung innerhalb von 5 Tagen nach der Zahlung angefordert wird.',
    benefitText:
      'Sie bekommen auch einen Vorteil im Wert von <span class="kdxsc-widget-state-payment-benefit__highlight">{ value }</span>, vorbehaltlich der Online-Zahlung',
    goToOnlinePaymentButtonText: 'Online-Vorauszahlung { value }',
    chooseOnlinePaymentType: 'Choose online prepayment type', // !!!

    formTitleWithFillPayment: {
      auto: 'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a car.',
      moto: 'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a motorcycle.',
      other:
        'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a vehicle.',
    },

    // formTitleWithFillPayment:
    //   'Please fill in all the fields of the form, these data are needed to draw up a contract for the sale of a car.', // !!!
    formTitleWithAdvancePayment: 'Please fill out all the fields of the form, this data is needed for online payment.', // !!!
    fullPaymentTitle: 'Booking or buying?', // !!!
    fullPayment: {
      partial: {
        title: 'Advance Payment',
        description: {
          auto: 'Online payment for car booking with an advance payment agreement',
          moto: 'Online payment for motorcycle booking with an advance payment agreement',
          other: 'Online payment for vehicle booking with an advance payment agreement',
        },
      },
      full: {
        title: 'Full payment',
        description: {
          auto: 'Online payment of the full cost of the car with a purchase and sale agreement',
          moto: 'Online payment of the full cost of the motorcycle with a purchase and sale agreement',
          other: 'Online payment of the full cost of the vehicle with a purchase and sale agreement',
        },
      },
      disabledTexts: {
        beginning: 'This payment option is possible only if you pay in full in cash.',
        parts: {
          tradeIn: 'Trade-in',
          credit: 'Credit',
          leasing: 'Leasing',
        },
        turnOff: 'Turn off',
        and: 'and',
      },
    },
    // fullPayment: {
    //   partial: {
    //     title: 'Advance Payment', // !!!
    //     description: 'Online payment for car booking with an advance payment agreement', // !!!
    //   },
    //   full: {
    //     title: 'Full payment', // !!!
    //     description: 'Online payment of the full cost of the car with a purchase and sale agreement', // !!!
    //   },
    //   disabledTexts: {
    //     beginning: 'This payment option is possible only if you pay in full in cash.', // !!!
    //     parts: {
    //       tradeIn: 'Trade-in', // !!!
    //       credit: 'Credit', // !!!
    //       leasing: 'Leasing', // !!!
    //     },
    //     turnOff: 'Turn off', // !!!
    //     and: 'and', // !!!
    //   },
    // },
  },

  header: {
    mainTitle: 'Finanzierungsbedingungen',
    tradeInTitle: 'Bewerten Sie Ihr Fahrzeug',
    onlinePaymentTitle: 'Online-Vorauszahlung',
    creditTitle: 'Finanzierungsangebot berechnen',
    authTitle: 'Autorisierung',
    leasingTitle: 'Leasing', // !!!
    promoProgram: 'Promotion', // !!!
  },

  footer: {
    createSmartContractButtonText: 'Bedingungen Speichern',
    goToOnlinePaymentButtonText: 'Online-Vorauszahlung { value }',
    madeBy: 'Hergestellt in <br/>UDP Auto',
  },

  form: {
    yourName: 'Ihr Vorname',
    yourLastName: 'Ihr Nachname',
    yourSecondName: 'Patronymic', // !!!
    phoneNumber: 'Ihre Handynummer',
    email: 'Email',
    promoCode: 'Promo code', // !!!
    defaultAgreementText:
      'Durch Klicken auf diesen Knopf geben Sie uns Ihr Eiverständnis zur Speicherung Ihrer persönlichen Daten sowie der Kontaktaufnahme mit Ihnen.',
    captcha: 'Captcha', // !!!
    smsCode: 'SMS code', // !!!
    captchaHint: 'Type numbers from the image and click on the save button', // !!!
    smsCodeHint: 'Type code from SMS and click save button', // !!!
    resendCaptcha: 'Reload image', // !!!
    resendSmsCode: 'Resend code', // !!!
    captchaError: 'You have typed wrong captcha', // !!!
    smsCodeError: 'You have typed wrong SMS code', // !!!
    sendIn: 'Send in', // !!!
  },

  personalData: {
    fullName: 'Vollständiger Name',
    phone: 'Handynummer',
    yourPersonalData: 'Ihre persönlichen Daten',
    storeDescription:
      'Um die Konfiguration des Finanzierungsangebotes zu speichern, füllen Sie bitte nachfolgenden Felder aus und melden Sie anschließend an Ihrem Account an.',
    phoneDescription: 'Ihre Handynummer wird als Benutzername verwendet, Ihr Password senden wir Ihnen auf das Handy.',
  },

  tooltip: {
    defaultTitle: 'Kalkulieren Sie die genauen Einkaufsbedingungen und erhalten Sie ein persönliches Angebot',
    tradeInWithCredit: 'Wählen Sie die Bedingungen der Inzahlungnahme und der Finanzierung aus',
    tradeInWithoutCredit: 'Wählen Sie die Bedingungen der Inzahlungnahme aus',
    creditWithoutTradeIn: 'Berechnen Sie die Finanzierung',
    benefits: 'Wählen Sie die Vorteile und zusätzlichen Serviceleistungen aus',
    onlinePayment: {
      auto: 'Make an online prepayment and book your car',
      moto: 'Make an online prepayment and book your motorcycle',
      other: 'Make an online prepayment and book your vehicle',
    },
    // onlinePayment: 'Machen Sie eine Online-Vorauszahlung und reservieren Sie ein Fahrzeug',
    closeButtonText: 'OK',
    watchVideo: 'Video anschauen',
  },

  summary: {
    ...require('./summary'),
    onlinePaymentTooltip: 'Online-Vorauszahlung verfügbar',
    onlinePaymentBenefit: 'Ihre Vorteile',
    yourBenefit: 'Ihr Vorteil',
    selectedServices: 'Ausgewählte Serviceleistungen',
    selectedAccessories: 'Selected accessories', // !!!
    selectedGifts: 'Ausgewählte Geschenke',
    // tradeInPriceAmount: 'Der Wert Ihres Fahrzeugs bei Inzahlungnahme',
    tradeInPriceAmount: {
      auto: 'The cost of your car in Trade-in',
      moto: 'The cost of your motorcycle in Trade-in',
      other: 'The cost of your vehicle in Trade-in',
    },
    tradeInBenefit: 'Vorteile der Inzahlungnahme',
    tradeInBenefitWithoutTradeIn: 'Benefit without Trade-in', // !!!
    tradeInBenefitWithoutTradeInHint: 'Your benefit when buying without Trade-In', // !!!
    creditBenefit: 'Vorteile der Finanzierung',
    leasingBenefit: 'Kaufvorteil bei Leasing',
    kasko: 'Vorteile der Finanzierung mit Kasko',
    // carPrice: 'Fahrzeugpreis',
    carPrice: {
      auto: 'Initial car price',
      moto: 'Initial motorcycle price',
      other: 'Initial vehicle price',
    },
    discounts: 'Rabatte',
    services: 'Serviceleistungen',
    initialFeeAmount: 'Anzahlung',
    needToPay: 'Gesamtzahlung',
    needToPayIfCredit: 'Gesamtbetrag der Anzahlung',
    needToPayIfLeasing: 'Gesamtbetrag der Vorauszahlung',
    needToPayZero: 'Ihre Zahlung',
    dealerNeedToPay: 'Zuschlag von dem Autohändler',
    byCredit: 'Auf Finanzierung',
    byLeasing: 'Im Leasing',
    monthlyPayment: 'monatliche Rate',
    // carPriceWithBenefits: 'Fahrzeugpreis',
    carPriceWithBenefits: {
      auto: 'Car price',
      moto: 'Motorcycle price',
      other: 'Vehicle price',
    },
  },

  tradeIn: {
    notSelected: 'Nicht ausgewählt',
    rate: 'Bewerten',
    fillAllFields: 'Fill in all the fields', // !!!
    apply: 'Anwenden',
    choose: 'Choose', // !!!
    rateResult: 'Bewertungsergebnis',
    rateResultFailed:
      'Leider ist es uns nicht gelungen, den Wert Ihres Fahrzeugs im Handel nicht automatisch zu berechnen. Verwenden Sie eine andere Berechnungsmethode oder speichern Sie die Bedingungen, damit der Manager eine Schätzung Ihrer Daten vornehmen kann.',
    rateResultZeroPrice:
      'Leider ist es uns nicht gelungen, den Wert Ihres Fahrzeugs im Handel nicht automatisch zu berechnen. Verwenden Sie eine andere Berechnungsmethode oder speichern Sie die Bedingungen, damit der Manager eine Schätzung Ihrer Daten vornehmen kann.',
    rateResultSuccessDescription:
      'Das Bewertungssystem basiert auf die Datenbank der Fahrzeuge, die im letzten Jahr auf auto.ru verkauft wurden.',
    rateResultSuccessDescriptionWithoutRF:
      'Das Bewertungssystem basiert auf die Datenbank der Fahrzeuge, die im letzten Jahr verkauft wurden.',
    rateResultPriceFromTo: 'vom { from } bis { to }',
    rateResultSuccessDescription2:
      'Die Kalkulation ist ausschließlich für Informationszwecken und hat keine rechtliche Bindung.',
    rateResultSuccessDescription2WithoutRF:
      'Die Kalkulation ist ausschließlich für Informationszwecken und hat keine rechtliche Bindung.',

    // ownerCarPriceText:
    //   'Sie sind mit der Schätzung nicht einverstanden? <br> Sie können auch einen Wunschpreis für Ihr Fahrzeug angeben',
    ownerCarPriceText: {
      auto: 'Disagree with the estimate? <br> You can specify the desired price of your car',
      moto: 'Disagree with the estimate? <br> You can specify the desired price of your motorcycle',
      other: 'Disagree with the estimate? <br> You can specify the desired price of your vehicle',
    },

    body: {
      title: 'Fahrzeugaufbau',
    },

    brand: {
      title: 'Marke',
      notSelected: 'Nicht ausgewählt',
    },

    city: {
      title: 'Stadt',
      noData: 'Keine Daten',
      loading: 'Wird geladen',
    },

    color: {
      title: 'Farbe',

      names: {
        white: 'Weiß',
        silver: 'Silber',
        beige: 'Beige',
        yellow: 'Gelb',
        golden: 'Gold',
        orange: 'Orange',
        pink: 'Pink',
        red: 'Rot',
        purple: 'Lila',
        brown: 'Braun',
        lightBlue: 'Hellblau',
        blue: 'Dunkelblau',
        violet: 'Violett',
        green: 'Grün',
        gray: 'Grau',
        black: 'Schwarz',
      },
    },

    generation: {
      title: 'Generation',
      notSelected: 'Nicht ausgewählt',
    },

    mileage: {
      title: 'Kilometer',
      empty: 'Nicht ausgewählt',
    },

    identity: {
      title: 'VIN or State Number', // !!!
      empty: 'Not selected', // !!!
    },

    model: {
      title: 'Modell',
      notSelected: 'Nicht ausgewählt',
    },

    modification: {
      title: 'Modifikation',
      notSelected: 'Nicht ausgewählt',
    },

    engine: {
      title: 'Motor',
      notSelected: 'Nicht ausgewählt',
    },

    gearbox: {
      title: 'Getriebe',
      notSelected: 'Nicht ausgewählt',
    },

    interiorType: {
      title: 'Material der Innenausstattung',
      notSelected: 'Nicht ausgewählt',
    },

    interiorColor: {
      title: 'Farbe der Innenausstattung',
      notSelected: 'Nicht ausgewählt',
    },

    owner: {
      title: 'Besitzer',

      names: {
        first: 'Erster',
        second: 'Zweiter',
        thirdAndMore: 'Dritter und mehr',
      },
    },

    year: {
      title: 'Jahr',
      notSelected: 'Nicht ausgewählt',
    },

    helpers: {
      showAll: 'Zeigen Alles',
      showPopular: 'Zeigen beliebt',
    },

    steps: {
      elevenStepsRemain: 'Noch 11 Schritte bis zur Bewertung',
      tenStepsRemain: 'Noch 10 Schritte bis zur Bewertung',
      nineStepsRemain: 'Noch 9 Schritte bis zur Bewertung',
      eightStepsRemain: 'Noch 8 Schritte bis zur Bewertung',
      sevenStepsRemain: 'Noch 7 Schritte bis zur Bewertung',
      sixStepsRemain: 'Noch 6 Schritte bis zur Bewertung',
      fiveStepsRemain: 'Noch 5 Schritte bis zur Bewertung',
      fourStepsRemain: 'Noch 4 Schritte bis zur Bewertung',
      threeStepsRemain: 'Noch 3 Schritte bis zur Bewertung',
      goToCity: 'Choose city', // !!!
      twoStepsRemain: 'Noch 2 Schritte bis zur Bewertung',
      homeStretch: 'der letzte Schritt',
    },

    vinOrGrzErrorText: 'Field "VIN or State Number" is filled incorrectly', // !!!
  },

  // car_statuses: {
  //   wait: {
  //     list_text: 'Reserviert',
  //     detail_text: 'Fahrzeug reserviert',
  //   },
  //   reserved: {
  //     list_text: 'Reserviert',
  //     detail_text: 'Fahrzeug reserviert',
  //   },
  //   sold: {
  //     list_text: 'Verkauft',
  //     detail_text: 'Fahrzeug verkauft',
  //   },
  //   available: {
  //     list_text: 'Vorteile berechnen',
  //     detail_text: 'Online reservieren',
  //   },
  // },
  car_statuses: {
    wait: {
      list_text: 'Booked',
      detail_text: {
        auto: 'The car is booked',
        moto: 'The motorcycle is booked',
        other: 'The vehicle is booked',
      },
    },
    reserved: {
      list_text: 'Booked',
      detail_text: {
        auto: 'The car is booked',
        moto: 'The motorcycle is booked',
        other: 'The vehicle is booked',
      },
    },
    sold: {
      list_text: 'Sold',
      detail_text: {
        auto: 'The car is sold',
        moto: 'The motorcycle is sold',
        other: 'The vehicle is sold',
      },
    },
    available: {
      list_text: 'Get your benefit',
      detail_text: {
        auto: 'Book online',
        moto: 'Book online',
        other: 'Book online',
      },
    },
  },
}
