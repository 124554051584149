import { connect, useDispatch } from 'react-redux'

import { DEFAULT_MILEAGES } from '../../../../constants'
import { makeNumber } from '../../../../helpers'
import { setTradeInMileage } from '../../../../store/actions'
import TradeInStandalonePartsRadiobuttons from '../../parts/radiobuttons'

function TradeInStandaloneMileageDropdown({ mileages, value }) {
  const dispatch = useDispatch()

  const onSet = p => {
    let payload = makeNumber(p)
    if (!Number.isInteger(payload)) {
      return
    }
    if (payload > 999999) {
      return
    }

    Promise.resolve(dispatch(setTradeInMileage({ payload }))).then(() => {
      const evt = new CustomEvent('tradeInMileageWasSet')
      window.dispatchEvent(evt)
    })
  }

  return (
    <TradeInStandalonePartsRadiobuttons name="mileage" variants={mileages} checked={value} onSet={onSet} search={''} />
  )
}
function mapStateToProps({
  tradeIn: {
    values: {
      mileage: { value },
    },
  },
}) {
  const mileages = () => {
    let res = []
    DEFAULT_MILEAGES.forEach(m => {
      res.push({
        id: m,
      })
    })
    return res
  }

  return {
    value,
    mileages: mileages(),
  }
}

export default connect(mapStateToProps)(TradeInStandaloneMileageDropdown)
