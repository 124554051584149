import _cloneDeep from 'lodash/cloneDeep'

const INIT_STATE = {
  benefits: [],
  selected: [],
}

const benefits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'DISABLE_BENEFIT':
      return {
        ...state,
        selected: state.selected.filter(benefit => {
          return benefit.id !== action.benefit.id || benefit.attributes.identity !== action.benefit.attributes.identity
        }),
      }

    case 'ENABLE_BENEFIT': {
      const selected = _cloneDeep(state.selected)
      selected.push(action.benefit)

      return {
        ...state,
        selected,
      }
    }

    case 'SET_BENEFITS':
      return {
        ...state,
        benefits: action.benefits,
      }

    case 'CLEAR_SELECTED_BENEFITS':
      return {
        ...state,
        selected: [],
      }

    default:
      return state
  }
}

export default benefits
