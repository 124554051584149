import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { formName } from '../../carOrderForm/main/helpers'
import {
  ACTION_TYPES,
  FORM_SOURCE,
  FORM_TYPE_BASE,
  FORM_TYPES,
  SCREEN_ERROR,
  SCREEN_INDEX,
  SCREEN_ONLINE_PAYMENT,
  SCREEN_SUCCESS,
} from '../../constants'
import {
  clearProtections,
  clearSmsAndCaptcha,
  createdEventPayload,
  createSmartContractWithProtection,
  formatCurrency,
  formatPrice,
  formType,
  formValidation,
  initializeErrorsProcessing,
  instanceIs,
  online_payment_button_text_formatter,
  SmartContract_Prepay1_Submit,
  SmartContract_Prepay1_Success,
  SmartContract_Save_Success,
} from '../../helpers'
import { getProjectName } from '../../helpers/app/detectors/project_name'
import {
  goToAuthScreen,
  goToOnlinePaymentFromWidget,
  goToScreen,
  setScroll,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  smartContractCreated,
  startLoading,
  stashFormErrors,
  stopLoading,
} from '../../store/actions'
import { createSmartContractData } from '../../store/helpers/smart_contract'
import SmartContactLogoIcon from '../../svgComponents/smartContactLogo'
import Advantages from '../Ui/advantages'
import Button from '../Ui/button'
import { createSmartContractButtonText } from './FormNew/helpers'

function Footer({
  showOnlinePayment,
  onlinePaymentFormattedValue,
  form,
  fields,
  widget_id,
  buttons,
  currency,
  car,
  config,
  activePayment,
  locale,
  limitForSaving,
  isPriceHidden,
}) {
  const { t } = useTranslation()

  /**
   * Скрываем копирайт в Европе
   */
  const showCopyright = !instanceIs('europe')

  const dispatch = useDispatch()
  const dataToSave = useSelector(state => createSmartContractData(state))

  const createBtnText = createSmartContractButtonText()

  /**
   * Текст на кнопке онлайн оплаты
   */
  const goToOnlinePaymentButtonText = () => {
    if (activePayment?.id) {
      return t('onlinePayment.goToOnlinePaymentButtonText', { value: activePayment.formattedPrice })
    }

    if (buttons.goToOnlinePayment && buttons.goToOnlinePayment.text) {
      return online_payment_button_text_formatter(buttons.goToOnlinePayment.text, `${onlinePaymentFormattedValue}`)
    }

    return t('footer.goToOnlinePaymentButtonText', { value: `${onlinePaymentFormattedValue}` })
  }

  /**
   * Нажатие по кнопке submit создание СК
   */
  const submitForm = () => {
    window.dispatchEvent(new CustomEvent(formName(config).clickOutsideForm))
  }

  /**
   * Переход на онлайн оплату
   */
  const goToOnlinePayment = e => {
    dispatch(goToOnlinePaymentFromWidget())

    const buttonText = e?.target?.innerText

    SmartContract_Prepay1_Submit({
      buttonText,
      car,
      widget_id,
      currency,
    })

    dispatch(goToScreen(SCREEN_ONLINE_PAYMENT))

    SmartContract_Prepay1_Success({
      buttonText,
      car,
      widget_id,
      currency,
    })
  }

  /**
   * Слушаем данные из формы
   */
  const listener = useCallback(
    e => {
      // отправка нажатия на кнопку в gtm теперь в компоненте формы

      console.log('creating sc')

      if (!e?.detail?.isFromDataValid) {
        return false
      }

      if (limitForSaving) {
        return false
      }

      dispatch(stashFormErrors())

      const errors = formValidation(form, fields, FORM_TYPE_BASE, locale)

      if (errors.length) {
        if (formType() === FORM_TYPES.NEW_TYPE) {
          dispatch(
            goToAuthScreen({
              action: ACTION_TYPES.CREATE_SMART_CONTRACT,
              screen: SCREEN_INDEX,
              additionalData: {},
            }),
          )
        } else {
          dispatch(setScroll(true))
        }
      } else {
        dispatch(startLoading())

        createSmartContractWithProtection(
          widget_id,
          {
            ticket_answer: form.values.captcha || form.values.smsCode,
            ticket_id:
              form.captchaSettings[FORM_SOURCE.WIDGET].ticketId || form.smsCodeSettings[FORM_SOURCE.WIDGET].ticketId,
          },
          JSON.stringify({ data: dataToSave }),
        )
          .then(({ data }) => {
            if (data && data.id) {
              dispatch(setSmartContractUrl(data.smartContractUrl))
              dispatch(setSmartContractId(data.id))
              dispatch(setSmartContractGenericId(data.genericId))
              dispatch(setSmartContractToken(data.token))
              dispatch(setSmartContractType('widget'))
              dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSave, data, 'footer')))
              clearSmsAndCaptcha()
              clearProtections(FORM_SOURCE.WIDGET)

              dispatch(goToScreen(SCREEN_SUCCESS))

              SmartContract_Save_Success({
                createSmartContractButtonText: createSmartContractButtonText(),
                car,
                widget_id,
                currency,
                smartContract: data,
              })
            } else {
              dispatch(goToScreen(SCREEN_ERROR))
            }
          })
          .catch(e => {
            clearProtections(FORM_SOURCE.WIDGET)
            initializeErrorsProcessing({ e: e, formSource: FORM_SOURCE.WIDGET })
          })
          .finally(() => {
            dispatch(stopLoading())
          })
      }
    },
    [car, currency, dataToSave, dispatch, fields, form, widget_id, limitForSaving],
  )

  /**
   * Слушаем данные из формы
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickInsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickInsideForm, listener)
    }
  }, [config, listener])

  return (
    <div className="kdxsc-footer" data-project-name={getProjectName()}>
      <div className="kdxsc-footer__buttons kdxsc-footer-row">
        <div className="kdxsc-footer-row__item">
          <Button id="kdxsc_save" handleClick={submitForm} text={createBtnText} type={'bordered'} />
        </div>
        {showOnlinePayment && !isPriceHidden && (
          <div className="kdxsc-footer-row__item">
            <Button
              id="kdxsc_prepay"
              className="kdxsc-footer-row__button-pay"
              handleClick={goToOnlinePayment}
              text={goToOnlinePaymentButtonText()}
            />
          </div>
        )}
      </div>
      {showCopyright && (
        <div className="kdxsc-footer__copy kdxsc-footer-copy">
          <div className="kdxsc-footer-copy__logo">
            <SmartContactLogoIcon />
          </div>
          <a
            dangerouslySetInnerHTML={{ __html: t('footer.madeBy') }}
            className="kdxsc-footer-copy__text"
            target="_blank"
            href="https://udpauto.ru/?utm_source=smart_contract"
            rel="nofollow noopener noreferrer"
          />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  onlinePayment: { enabled, amount, activePayment, status },
  settings: {
    widget_id,
    currency,
    locale,
    limitForSaving,
    visual: { buttons, fields, isPriceHidden },
  },
  form,
  car,
}) {
  const onlinePaymentFormattedValue = formatPrice(amount, currency)

  return {
    onlinePaymentFormattedValue,
    showOnlinePayment: enabled && !buttons.goToOnlinePayment.hideFromBaseForm && status === 'available',
    amount: formatPrice(amount, currency),
    form,
    fields,
    widget_id,
    buttons,
    currency,
    car,
    activePayment,
    locale,
    limitForSaving: limitForSaving[FORM_SOURCE.WIDGET],
    isPriceHidden,
  }
}

export default connect(mapStateToProps)(Footer)
