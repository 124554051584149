function getCalcDetail(event, status, state) {
  let calcDetail = ''

  if (status === 'open') {
    return calcDetail
  }

  if (event === 'tradeIn') {
    calcDetail = {
      benefitValue: state[event].benefitValue,
      partner: state[event].partner,
      rate: state[event].rate,
      values: state[event].values,
    }
  }

  if (event === 'credit' || event === 'leasing') {
    calcDetail = {
      benefitValue: state[event].benefitValue,
      partner: state[event].partner,
      activeProgram: state[event].activeProgram,
      parameters: state[event].parameters,
    }
  }

  return calcDetail
}

export function dataLayer(data) {
  return (dispatch, getState) => {
    const state = getState()

    const { event, status, buttonName } = data

    window.dataLayer?.push({
      event,
      sourceName: 'smart',
      Status: status,
      buttonName: buttonName || '',
      widget_id: state.settings.widget_id,
      currency: state.settings.currency,
      eventValue: state.car.price,
      carDetail: {
        identity: state.car.identity,
        name: state.car.name,
        modelData: state.car.modelData,
        productType: state.car.productType,
      },
      calcDetail: getCalcDetail(event, status, state),
    })
  }
}
