import { getBootSettings } from '../../helpers'
import { bootedListEvent } from '.'

export const setDisableSmartContractLink = payload => dispatch => {
  dispatch({
    type: 'SET_DISABLE_SMART_CONTRACT_LINK',
    payload,
  })
}

export const setDisableSmartContractLogo = payload => dispatch => {
  dispatch({
    type: 'SET_DISABLE_SMART_CONTRACT_LOGO',
    payload,
  })
}

export const setCustomTags = payload => dispatch => {
  dispatch({
    type: 'SET_CUSTOM_TAGS',
    payload,
  })
}

export const setCustomData = payload => dispatch => {
  dispatch({
    type: 'SET_CUSTOM_DATA',
    payload: payload || null,
  })
}

export const setCustomDataField = (key, value) => dispatch => {
  dispatch({
    type: 'SET_CUSTOM_DATA_FIELD',
    payload: {
      key,
      value
    },
  })
}

export const pushCustomTag = payload => dispatch => {
  dispatch({
    type: 'PUSH_CUSTOM_TAG',
    payload,
  })
}

export const setWidgetConfig = payload => dispatch => {
  dispatch({
    type: 'SET_WIDGET_CONFIG',
    payload,
  })
}

export const setDisableCash = payload => dispatch => {
  dispatch({
    type: 'SET_DISABLE_CASH',
    payload,
  })
}

export const setPriceTooltip = payload => dispatch => {
  dispatch({
    type: 'SET_PRICE_TOOLTIP',
    payload,
  })
}
export const setListTradeInBehaviour = payload => dispatch => dispatch({ type: 'SET_LIST_TRADE_IN_BEHAVIOUR', payload })

export const setListCreditBehaviour = payload => dispatch => dispatch({ type: 'SET_LIST_CREDIT_BEHAVIOUR', payload })

export const setBackgroundBootedOnList = () => dispatch => dispatch({ type: 'SET_BACKGROUND_BOOTED_ON_LIST' })

export const setListInitialized = () => dispatch => dispatch({ type: 'SET_LIST_INITIALIZED' })

export const setPreset = payload => dispatch => dispatch({ type: 'SET_PRESET', payload })

export const setListVersion = payload => dispatch => dispatch({ type: 'SET_LIST_VERSION', payload })

export const setRenderButtons = payload => dispatch => dispatch({ type: 'SET_RENDER_BUTTONS', payload })

export const setLimitForSaving = (key, value) => ({
  type: 'SET_LIMIT_FOR_SAVING',
  key,
  value,
})

export const bootedList = widgetIds => dispatch => {
  getBootSettings({ data: { widgetIds: widgetIds } })
    .then(response => {
      if (response?.data.widgets) {
        let tradeInAvailableOnList = true
        let creditAvailableOnList = true

        Object.values(response.data.widgets).forEach(widget => {
          if (!widget.tradeInEnabled) {
            tradeInAvailableOnList = false
          }

          if (!widget.creditEnabled) {
            creditAvailableOnList = false
          }

          if (!tradeInAvailableOnList && !creditAvailableOnList) {
            return
          }
        })

        dispatch(setListTradeInBehaviour(tradeInAvailableOnList))
        dispatch(setListCreditBehaviour(creditAvailableOnList))

        dispatch(bootedListEvent())
        dispatch(setListInitialized())
      }
    })
    .catch(error => {
      console.error(error)
      dispatch(bootedListEvent())
      dispatch(setListInitialized())
    })
}

export const setVehicleType = payload => dispatch => {
  if (payload?.length) {
    dispatch({ type: 'SET_VEHICLE_TYPE', payload })
  }
}

export const setTaxes = payload => dispatch => {
  dispatch({ type: 'SET_TAXES', payload })
}

export const setCustomSource = payload => dispatch => {
  dispatch({ type: 'SET_CUSTOM_SOURCE', payload })
}
