import { CREDIT_PARTNER, FORM_SOURCE, SCREEN_ERROR, SCREEN_SUCCESS } from '../constants'
import {
  goToScreen,
  setSmartContractGenericId,
  setSmartContractId,
  setSmartContractToken,
  setSmartContractType,
  setSmartContractUrl,
  smartContractCreated,
  startLoading,
  stopLoading,
} from '../store/actions'
import store from '../store/store'
import { clearProtections, clearSmsAndCaptcha } from './app/boot/bootMainInfo'
import { createdEventPayload } from './created_event_payload'
import { initializeErrorsProcessing } from './initializeErrorsProcessing'
import { createSmartContractWithProtection, creditOnlineApproval } from './requests'

export const approveCreditOnline = (dispatch, widget_id, dataToSave, source = 'widget') => {
  dispatch(startLoading())
  const state = store.getState()

  createSmartContractWithProtection(
    widget_id,
    {
      ticket_answer: state.form.values.captcha || state.form.values.smsCode,
      ticket_id:
        state.form?.captchaSettings[FORM_SOURCE.WIDGET_AUTH]?.ticketId ||
        state.form?.smsCodeSettings[FORM_SOURCE.WIDGET_AUTH]?.ticketId,
    },
    JSON.stringify({ data: dataToSave }),
  )
    .then(contractResponse => {
      if (contractResponse.data && contractResponse.data.genericId && contractResponse.data.id) {
        const hasUrl = contractResponse.data.smartContractUrl && contractResponse.data.smartContractUrl.length
        clearSmsAndCaptcha()
        clearProtections(FORM_SOURCE.WIDGET)

        if (hasUrl) {
          dispatch(setSmartContractUrl(contractResponse.data.smartContractUrl))
        }

        dispatch(setSmartContractId(contractResponse.data.id))
        dispatch(setSmartContractGenericId(contractResponse.data.genericId))
        dispatch(setSmartContractType(source))
        dispatch(setSmartContractToken(contractResponse.data.token))
        dispatch(smartContractCreated(createdEventPayload(widget_id, dataToSave, contractResponse.data)))

        const uniqueId = `${contractResponse.data.genericId}-${contractResponse.data.id}`

        creditOnlineApproval(uniqueId, contractResponse.data.token)
          .then(r => {
            const partner = r?.data?.attributes?.finance?.onlineApprove?.partner
            const onlineApprovalUrl = r?.data?.attributes?.finance?.onlineApprove?.url

            if (partner === CREDIT_PARTNER.E_CREDIT) {
              let url = `${contractResponse.data.smartContractUrl}&target=e_credit_online_approval&screen=credit`

              window.open(url, '_blank')

              if (source === 'widget') {
                dispatch(goToScreen(SCREEN_SUCCESS))
              }
            } else {
              if (onlineApprovalUrl) {
                window.open(onlineApprovalUrl, '_blank')

                if (source === 'widget') {
                  dispatch(goToScreen(SCREEN_SUCCESS))
                }
              }
            }
          })
          .finally(() => {
            dispatch(stopLoading())
          })
      } else {
        if (source === 'widget') {
          dispatch(goToScreen(SCREEN_ERROR))
        }
      }
    })
    .catch(e => {
      console.log('su', source)
      console.log('e', e)

      if (source === FORM_SOURCE.WIDGET) {
        clearProtections(source)
        initializeErrorsProcessing({ e: e, formSource: FORM_SOURCE.WIDGET_AUTH })

        // dispatch(goToScreen(SCREEN_ERROR))
      }
    })
    .finally(() => {
      dispatch(stopLoading())
    })
}
