import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { SCREEN_CREDIT } from '../../../constants'
import {
  goToCreditScreenFromPromoProgramScreen,
  goToLinkFromPromoProgramScreen,
  goToScreen,
  setCreditBuybackPaymentPercentage,
  setCreditInitialFeePercentage,
  setCreditPeriod,
} from '../../../store/actions'
import Button from '../../Ui/button'
import s from './promoProgram.module.scss'

function PromoProgram({ promoProgram }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const goToPromoProgramPage = () => {
    dispatch(goToLinkFromPromoProgramScreen())
    window.open(promoProgram?.screenSettings?.link, '_blank')
  }

  const goToCreditScreen = () => {
    // устанавливаю параметры из программы
    dispatch(setCreditInitialFeePercentage(promoProgram.parameters.defaultInitialFeePercentage))
    dispatch(setCreditBuybackPaymentPercentage(promoProgram.parameters.defaultBuybackPaymentPercentage))
    dispatch(setCreditPeriod(promoProgram.parameters.defaultPeriod))
    // перехожу на экран кредита
    dispatch(goToCreditScreenFromPromoProgramScreen())
    dispatch(goToScreen(SCREEN_CREDIT))
  }

  return (
    <div className={s.wrapper}>
      <div className={s.wrapper__content}>
        <div className={s.content}>
          {promoProgram?.screenSettings?.title && (
            <div className={s.content__title}>{promoProgram.screenSettings.title}</div>
          )}
          {promoProgram?.screenSettings?.subtitle && (
            <div className={s.content__subtitle}>{promoProgram.screenSettings.subtitle}</div>
          )}
          {promoProgram?.screenSettings?.text && (
            <div className={s.content__text}>{promoProgram.screenSettings.text}</div>
          )}
        </div>
      </div>
      <div className={s.wrapper__footer}>
        <div className={s.footer}>
          {promoProgram?.screenSettings?.link && (
            <div className={s.footer__action}>
              <Button
                text={t(`credit.promoProgram.moreDetails`)}
                type={'bordered'}
                handleClick={goToPromoProgramPage.bind(this)}
              />
            </div>
          )}
          <div className={s.footer__action}>
            <Button text={t(`credit.promoProgram.choose`)} handleClick={() => goToCreditScreen()} />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ credit: { promoProgram } }) {
  return {
    promoProgram,
  }
}

export default connect(mapStateToProps)(PromoProgram)
