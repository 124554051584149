import { useCallback, useEffect, useState } from 'react'

import InputWithRange from '../inputWithRange'

const MONTH_DIVIDER = 12
const MONTH_MINMAX = {
  min: 1,
  max: 6,
}

const RangeTerms = props => {
  const { label, onChange, value, disabled, periods } = props
  const [minmax, setMinmax] = useState(MONTH_MINMAX)

  useEffect(() => {
    setMinmax(findMinMaxTerms(periods))
  }, [periods])

  const onChangeHandler = useCallback(
    ({ target }) => {
      onChange(target.value * MONTH_DIVIDER)
    },
    [onChange],
  )

  const onBlurHandler = useCallback(() => {
    const val = Math.round(value / MONTH_DIVIDER)
    const res = Math.min(Math.max(val || 0, minmax.min), minmax.max)
    onChange(res * MONTH_DIVIDER)
  }, [minmax, onChange, value])

  return (
    <InputWithRange
      label={label}
      inputValue={monthToYear(value)}
      inputChangeHandler={onChangeHandler}
      inputBlurHandler={onBlurHandler}
      disabled={disabled}
      rangeValue={monthToYear(value)}
      rangeChangeHandler={onChangeHandler}
      rangeValues={minmax}
      isOnlyValues={true}
    />
  )
}

const monthToYear = month => Math.floor(month / 12)

function findMinMaxTerms(arrayTerms) {
  const [min, ...rest] = arrayTerms.sort((a, b) => a.value - b.value).map(obj => monthToYear(obj.value))
  return { min, max: rest[rest.length - 1] }
}

export default RangeTerms
