import i18n from 'i18next'

import {
  MODEL_DATA_ONE_PLATFORM_BRAND_ID,
  MODEL_DATA_ONE_PLATFORM_COMPLECTATION_ID,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,
  MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
  MODEL_DATA_ONE_PLATFORM_MODEL_ID,
  MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
  MODEL_DATA_RAW_BRAND, MODEL_DATA_RAW_COLOR,
  MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
  MODEL_DATA_RAW_GENERATION,
  MODEL_DATA_RAW_MILEAGE,
  MODEL_DATA_RAW_MODEL,
  MODEL_DATA_RAW_MODEL_YEAR,
} from '../constants'
import { debug, parseNumber } from '../helpers'

export const onePlatform = () => ({
  detail: {
    additionalOptions: () => {
      try {
        const cost = additionalOptionsCostDetector(document.getElementById('car_data_encoded'))

        return {
          cost: cost,
          list: [],
        }
      } catch (e) {
        console.error(e)
      }

      return {
        cost: 0,
        list: [],
      }
    },
    name: () => {
      const nameElement = document.getElementsByClassName('avn106-00-description')[0]

      if (nameElement) {
        try {
          return nameElement.firstElementChild.firstElementChild.firstElementChild.innerText
        } catch (err) {
          return ''
        }
      }

      const encodedDataElement = document.getElementById('car_data_encoded')

      if (encodedDataElement) {
        try {
          const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

          if (json && json.model_full_name) {
            return json.model_full_name
          }
        } catch (e) {
          return ''
        }
      }

      return ''
    },
    picture: () => {
      const imageElement = document.querySelectorAll('.avn106-00__container .gallery-item')[0]

      if (!imageElement) {
        const encodedDataElement = document.getElementById('car_data_encoded')

        if (encodedDataElement) {
          try {
            const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

            if (json && json.default_image_url) {
              return json.default_image_url
            }
          } catch (e) {
            return null
          }
        }

        return null
      }

      return imageElement.href
    },
    price: () => {
      const encodedDataElement = document.getElementById('car_data_encoded')

      if (encodedDataElement) {
        return priceDetector(encodedDataElement)
      }

      return 0
    },
    identity: term => {
      const encodedDataElement = document.getElementById('car_data_encoded')
      const encodedVinElement = document.getElementById('vin_encoded')

      switch (term) {
        case 'vin':
          if (encodedDataElement) {
            const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

            if (json.vin) {
              return json.vin
            }
          }

          if (encodedVinElement) {
            return atob(encodedVinElement.value.substr(3))
          }

          return window.location.href.split('#').shift()
        case 'url':
          return window.location.href.split('#').shift()
        default:
          return
      }
    },
    generator: text => {
      const original = document.querySelectorAll('.avn106-00-description__button .u107-00--full')[0]

      if (!original) {
        const core2button = document.querySelectorAll('.open_smart_contract')[0]

        if (core2button) {
          core2button.innerText = text
          core2button.style.display = null

          return [core2button]
        }

        return []
      }

      const clone = original.cloneNode(true)

      clone.lastChild.lastChild.innerText = text
      clone.style.marginBottom = '10px'

      original.before(clone)

      return clone
    },

    additionalButtonGenerator: () => {
      const avn116BenefitLink = document.querySelector('.avn106-00-description__benefits-link')
      if (avn116BenefitLink) {
        const clonedAvn116BenefitLink = avn116BenefitLink.cloneNode(true)
        avn116BenefitLink.parentNode.replaceChild(clonedAvn116BenefitLink, avn116BenefitLink)
        return clonedAvn116BenefitLink
      }
      return null
    },

    modelData: () => {
      const encodedDataElement = document.getElementById('car_data_encoded')
      const modelYearElement = document.querySelectorAll('.avn106-00-description__id')
      const idElement = document.querySelectorAll('.avn106-00-description__year')

      let modelYear = 0

      if (modelYearElement && modelYearElement.length) {
        modelYear = parseInt(modelYearElement[0].innerText) || 0
      }

      if (modelYear === 0 && idElement && idElement.length) {
        modelYear = parseInt(idElement[0].innerText) || 0
      }

      return modelDataParser(encodedDataElement, modelYear)
    },
    productType: () => {
      const encodedDataElement = document.getElementById('car_data_encoded')

      return productTypeParser(encodedDataElement)
    },
    discounts: () => {
      const encodedDataElement = document.getElementById('car_data_encoded')

      return discountsParser(encodedDataElement)
    },
    dealerships: () => {
      if (
        !window['@kodix'] ||
        !window['@kodix']['smartContract'] ||
        !window['@kodix']['smartContract']['detail'] ||
        !window['@kodix']['smartContract']['detail']['dealerships']
      ) {
        return []
      }

      return window['@kodix']['smartContract']['detail']['dealerships']
    },
    custom_data: detail => {
      if (detail?.customData && typeof detail?.customData === 'object') {
        return detail.customData
      } else {
        return null
      }
    },
  },

  list: {
    card_class: (function () {
      if (debug('cards')) {
        return ['avn117-00__card', 'avn114-00__card', 'avn105-00__card', 'avn102-00']
      }

      return ['avn105-00__card', 'avn117-00__card']
    })(),
    cars_container: function () {
      try {
        const avn105 = document.getElementsByClassName('avn105-00__row js-avn105-row')
        const avn117 = document.getElementsByClassName('avn117-00__row js-avn117-row')

        if (debug('cards')) {
          const avn105 = document.getElementsByClassName('avn105-00__row js-avn105-row')
          const avn117 = document.getElementsByClassName('avn117-00__row js-avn117-row')
          const avn114 = document.getElementsByClassName('avn114-00__row js-avn114-row')
          const avn109 = document.getElementsByClassName('avn109-00__container')

          return [...avn105, ...avn117, ...avn114, ...avn109]
        }

        return [...avn105, ...avn117]
      } catch (e) {
        console.error('[SC cars_container]', e)
        return []
      }
    },
    car_card: function () {
      try {
        const avn105 = document.getElementsByClassName('avn105-00__card')
        const avn117 = document.getElementsByClassName('avn117-00__card')
        const avn102 = document.getElementsByClassName('avn102-00')

        if (debug('cards')) {
          const out = []

          if (avn102 && avn102.length) out.push(...avn102)

          return out
        }

        if (avn105 && avn105.length) {
          return avn105
        }

        return avn117
      } catch (e) {
        console.error('[SC car_card]', e)
        return null
      }
    },
    car_id: function (card, term = null) {
      if (term === 'url') {
        return this.car_link(card)
      } else {
        const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')
        if (encodedDataElement) {
          const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))
          if (json.vin) {
            return json.vin
          }
        }
      }
      return this.car_link(card)
    },
    car_price: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      if (encodedDataElement) {
        return priceDetector(encodedDataElement)
      } else {
        return 0
      }
    },
    car_image: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      if (encodedDataElement) {
        const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))
        if (json && json.default_image_url) {
          return json.default_image_url
        }
      }

      return null
    },
    car_title: function (card) {
      try {
        return card.querySelector('.u105-00__title').innerText
      } catch (e) {
        return ''
      }
    },
    car_link: function (card) {
      try {
        return card.querySelector('.avn102-00__link').getAttribute('href')
      } catch (e) {
        return null
      }
    },
    car_dealerships: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      if (encodedDataElement) {
        const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

        if (json) {
          if (
            window['@kodix'] &&
            window['@kodix']['smartContract'] &&
            window['@kodix']['smartContract']['list'] &&
            window['@kodix']['smartContract']['list']['carIdsAndWidgetIds']
          ) {
            return window['@kodix']['smartContract']['list']['carIdsAndWidgetIds'](json, card)
          } else if (json.cars) {
            return json.cars
          }
        }
      }

      return []
    },
    widgetIdList: function (card) {
      if (
        !window['@kodix'] ||
        !window['@kodix']['smartContract'] ||
        !window['@kodix']['smartContract']['list'] ||
        !window['@kodix']['smartContract']['list']['widgetId']
      ) {
        return ''
      }

      const widgetId = window['@kodix']['smartContract']['list']['widgetId']

      if (typeof widgetId === 'string') {
        return widgetId
      } else if (typeof widgetId === 'function') {
        return widgetId(card)
      }

      return ''
    },
    modelDataList: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')
      const modelYearElement = card.querySelectorAll('.avn102-00__year')
      const idElement = card.querySelectorAll('.avn102-00__id')

      let modelYear = 0

      if (modelYearElement && modelYearElement.length) {
        modelYear = parseInt(modelYearElement[0].innerText) || 0
      }

      if (modelYear === 0 && idElement && idElement.length) {
        modelYear = parseInt(idElement[0].innerText) || 0
      }

      return modelDataParser(encodedDataElement, modelYear)
    },
    productTypeList: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      return productTypeParser(encodedDataElement)
    },
    additionalOptionsList: card => {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      try {
        const cost = additionalOptionsCostDetector(encodedDataElement)

        return {
          cost: cost,
          list: [],
        }
      } catch (e) {
        console.error(e)
      }

      return {
        cost: 0,
        list: [],
      }
    },
    car_discounts: function (card) {
      const encodedDataElement = card.querySelector('.js-avn102-car-data-encoded')

      return discountsParser(encodedDataElement)
    },

    card_identity: function (card) {
      const identityClass = 'kdxsc_card_identity'

      const currentIdentity = card.querySelector(`.${identityClass}`)
      if (!currentIdentity) {
        const cardIdentity = `card_${Math.round(new Date().getTime() * Math.random()).toString()}`
        let div = document.createElement('div')
        div.classList.add('kdxsc_card_identity')
        div.style.display = 'none'
        div.innerHTML = cardIdentity
        card.append(div)
        return cardIdentity
      }

      return currentIdentity.innerText
    },

    listCardButtonGenerator: function (card, text, isLocked, status) {
      const benefitsLinkElement = card.querySelector('.avn102-00__benefits-link')

      if (!benefitsLinkElement) {
        return false
      }

      // элемент-клон, чтоб убрать все eventListeners
      let clonedBenefitsLinkElement
      // доп текст на кнопке (если авто продан/ заблонирован)
      let additionalText

      // клонирование элемента
      // if (status === 'available') {
      clonedBenefitsLinkElement = benefitsLinkElement.cloneNode(true)
      benefitsLinkElement.parentNode.replaceChild(clonedBenefitsLinkElement, benefitsLinkElement)
      // }

      // доп текст на кнопке
      switch (status) {
        case 'wait':
          additionalText = i18n.t('car_statuses.wait.list_text')
          break
        case 'reserved':
          additionalText = i18n.t('car_statuses.reserved.list_text')
          break
        case 'sold':
          additionalText = i18n.t('car_statuses.sold.list_text')
          break
        default:
          additionalText = ''
          break
      }

      const replacedText = targetElement => {
        let textEl = targetElement.querySelector('.text')
        let textElInner = textEl.innerText
        textEl.innerText = textElInner + additionalText
      }

      // если есть доп текст, то добавляем его на оргинальную или клонированную ссылку
      if (additionalText) {
        additionalText = ` (${additionalText})`

        if (clonedBenefitsLinkElement) {
          replacedText(clonedBenefitsLinkElement)
        } else {
          replacedText(benefitsLinkElement)
        }
      }

      if (clonedBenefitsLinkElement) {
        return [clonedBenefitsLinkElement]
      }

      return [benefitsLinkElement]
    },

    parse_car_card: function (card) {
      if (!card) {
        return false
      }

      const nodes = card.children

      if (!nodes || !nodes.length) {
        return false
      }
      return {
        card_identity: this.card_identity(card),
        link: this.car_link(card),
        img: this.car_image(card),
        title: this.car_title(card),
        price: this.car_price(card),
        id: this.car_id(card),
        modelData: this.modelDataList(card),
        productType: this.productTypeList(card),
        discounts: this.car_discounts(card),
        dealerships: this.car_dealerships(card),
        widgetId: this.widgetIdList(card),
        additionalOptions: this.additionalOptionsList(card),
      }
    },
  },
})

const additionalOptionsCostDetector = encodedDataElement => {
  if (!encodedDataElement) {
    return 0
  }

  const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

  if (json.additional_options_cost) {
    return parseNumber(json.additional_options_cost)
  }

  return 0
}

const priceDetector = encodedDataElement => {
  if (!encodedDataElement) {
    return 0
  }

  const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

  let finalPrice = 0
  let priceWithoutDiscounts = 0

  if (json) {
    finalPrice = json.final_price
    priceWithoutDiscounts = json.price_without_discounts
  }

  return Math.max(priceWithoutDiscounts, finalPrice)
}

const modelDataParser = (encodedDataElement, modelYear) => {
  if (encodedDataElement) {
    try {
      const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

      const map = {
        brand_name: MODEL_DATA_RAW_BRAND,
        model_name: MODEL_DATA_RAW_MODEL,
        engine_power_horse_power: MODEL_DATA_RAW_ENGINE_POWER_HORSE_POWER,
        generation_name: MODEL_DATA_RAW_GENERATION,
        mileage: MODEL_DATA_RAW_MILEAGE,

        base_color_name: MODEL_DATA_RAW_COLOR,

        restyling_id: MODEL_DATA_ONE_PLATFORM_RESTYLING_ID,
        marketing_complectation_id: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_ID,
        marketing_complectation_code: MODEL_DATA_ONE_PLATFORM_MARKETING_COMPLECTATION_CODE,

        brand_id: MODEL_DATA_ONE_PLATFORM_BRAND_ID,
        model_id: MODEL_DATA_ONE_PLATFORM_MODEL_ID,
        complectation_id: MODEL_DATA_ONE_PLATFORM_COMPLECTATION_ID,

        year: MODEL_DATA_RAW_MODEL_YEAR,
      }

      const modelData = []

      for (const key of Object.keys(map)) {
        const exists = !!json[key]

        if (!exists) {
          continue
        }

        modelData.push(`${map[key]}:${json[key]}`)
      }

      if (modelYear > 0) {
        modelData.push(`${MODEL_DATA_RAW_MODEL_YEAR}:${modelYear}`)
      }

      return modelData.join(',')
    } catch (e) {
      console.error('[sc] one platform preset cannot extract model data due [' + e + ']')
    }
  } else {
    return ''
  }
}

const productTypeParser = encodedDataElement => {
  if (encodedDataElement) {
    try {
      const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

      if (json.is_new) {
        return json.is_new
      }

      return 'unknown'
    } catch (e) {
      console.error('[sc] one platform preset cannot extract product type due [' + e + ']')
    }
  } else {
    return 'unknown'
  }
}

const discountsParser = encodedDataElement => {
  let discounts = {
    credit: 0,
    tradein: 0,
    credit_tradein: 0,
    insurance: 0,
  }

  const map = {
    credit: 'credit_discount',
    tradein: 'tradein_discount',
    credit_tradein: 'credit_tradein_discount',
    insurance: 'insurance_discount',
  }

  if (encodedDataElement) {
    try {
      const json = JSON.parse(unescape(atob(encodedDataElement.value.substr(3))))

      for (const key of Object.keys(map)) {
        const exists = !!json[`${key}_discount`]

        if (!exists) {
          continue
        }

        if (typeof json[`${key}_discount`] === 'number') {
          discounts[key] = json[`${key}_discount`] * -1
        }
      }

      return discounts
    } catch (e) {
      console.error('[sc] one platform preset cannot extract discounts type due [' + e + ']')
    }
  } else {
    return discounts
  }
}
