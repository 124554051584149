import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'

import { formatPrice } from '../../../helpers'
import s               from './styles/input.module.scss'

function LineInput({ handleChange, label, value, id, disabled, currency, isPriceHidden }) {
  const content = () => {
    if (disabled) {
      return <div className={s.transparentInput}>{formatPrice(value, currency)}</div>
    } else {
      return (
        <NumberFormat
          className={s.transparentInput}
          name={id}
          value={value}
          onValueChange={handleChange}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={' '}
          decimalScale={0}
          placeholder={`0 ${currency}`}
          isNumericString={false}
          suffix={` ${currency}`}
          maxLength={15}
          defaultValue={''}
        />
      )
    }
  }

  return (
    <div className={s.lineInput}>
      {!isPriceHidden && <div className={s.lineInput__top}>{content()}</div>}
      <div className={s.lineInput__bottom}>{label}</div>
    </div>
  )
}

function mapStateToProps({ settings: { currency }, tradeInStandalone: { visual } }) {
  return {
    currency,
    isPriceHidden: visual.isPriceHidden,
  }
}

export default connect(mapStateToProps)(LineInput)
