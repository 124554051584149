import { connect, useDispatch } from 'react-redux'

import { setIsCarIdentityValid, setTradeInCarIdentity } from '../../../../store/actions'
import TradeInStandalonePartsInput from '../../parts/input'
import s from './identity.module.scss'

function TradeInStandaloneIdentityInput({ value, focus, isCarIdentityValid }) {
  const dispatch = useDispatch()

  const onSet = p => {
    const payload = p?.length >= 17 ? p?.substr(0, 17) : p
    if (!isCarIdentityValid) {
      dispatch(setIsCarIdentityValid(true))
    }
    Promise.resolve(dispatch(setTradeInCarIdentity({ payload }))).then(() => {
      const evt = new CustomEvent('tradeInCarIdentityWasSet')
      window.dispatchEvent(evt)
    })
  }

  return (
    <div className={s.wrapper}>
      <div className={s.wrapper__input}>
        <TradeInStandalonePartsInput onSetValue={onSet} name="identity" value={value} focus={focus} />
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      identity: { value },
    },
    isCarIdentityValid,
  },
}) {
  return {
    step,
    value,
    isCarIdentityValid,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneIdentityInput)
