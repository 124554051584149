module.exports = {
  state: {
    title: 'Трейд-ин',
    subtitleWithBenefit: 'Трейд-ин с преимуществом',
    subtitleWithoutBenefit: 'Трейд-ин',
    initialAdditionalText: 'Оцените своё ТС онлайн',
    additionalTextWithCarData: 'Сдаю своё ТС { brand } { model }, { year }',
    autoEstimationIsImpossible: 'Автоматическая оценка невозможна',
    withoutTradeIn: 'Без Трейд-ин',
    benefitHint: {
      auto: 'Это преимущество применяется в случае покупки данного автомобиля в трейд-ин',
      moto: 'Это преимущество применяется в случае покупки данного мотоцикла в трейд-ин',
      other: 'Это преимущество применяется в случае покупки данного ТС в трейд-ин',
    },
  },
  screen: {},
  estimations: {
    goToTradeInList: 'Оценить по параметрам из каталога',
    reset: 'Ваша текущая оценка и введенные данные будут сброшены',
    goToTradeInByIdentity: 'Оценить по VIN или Гос номеру',
  },
}
