import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectDiscountsHelper = (preset, detail) => {
  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.discounts()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.discounts) {
    return detail.discounts()
  }

  return {
    credit: 0,
    tradein: 0,
    credit_tradein: 0,
    insurance: 0,
  }
}
