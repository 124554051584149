import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Label }  from '../UI/Common'
import s          from './styles/conditions.module.scss'
import { formID } from './formID'

const Term = ({ term, possibleTerms, setTerm, register, moduleLocale }) => {
  const { t } = useTranslation()

  const [mobile, setMobile] = useState(false)

  const handleMobileWidthChange = e => {
    if (e.matches) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  //  убирает z-index с футера на ванплатформе
  const changeFooterZIndexFunction = zIndexValue => {
    let footer = document.getElementsByClassName('main-footer')[0]
    if (footer) {
      footer.style.zIndex = zIndexValue
    }
  }

  const mql = window.matchMedia('(max-width: 606px)')

  if (mql.addEventListener) {
    mql.addEventListener('change', e => handleMobileWidthChange(e))
  } else {
    // Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
    mql.addListener(handleMobileWidthChange)
  }

  useEffect(() => {
    if (mql.matches) {
      setMobile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    changeFooterZIndexFunction('auto')
    setOpen(true)
  }

  const dropdown = useRef()

  const handleTermKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setTerm(e.currentTarget.dataset.value)
    }
  }

  const handleTermClick = e => {
    e.preventDefault()
    setTerm(e.currentTarget.dataset.value)
    mobile && setOpen(false)
    changeFooterZIndexFunction('2')
  }

  useEffect(() => {
    if (open) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [open])

  const conditionTermsElements = possibleTerms.map(item => {
    return (
      <label
        className={s.term_btn}
        tabIndex={0}
        key={item.value}
        data-value={item.value}
        data-selected={String(item.value) === String(term)}
        data-mobile={mobile}
        data-open={mobile ? open : null}
        onKeyDown={handleTermKey}
        onClick={handleTermClick}
      >
        <input
          className={s.term_input}
          type="radio"
          name={formID.term}
          value={item.value}
          checked={String(term) === String(item.value)}
          onChange={e => e.preventDefault()}
          tabIndex={-1}
          ref={register}
        />
        <div className={s.term_checkmark}>
          <svg width="11" height="9" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8497 1.95482L5.40075 9.40216C5.20111 9.601 4.87789 9.601 4.67904 9.40216L0.149132 4.84327C-0.0497107 4.64362 -0.0497107 4.31919 0.149132 4.12075L1.2315 3.03919C1.43114 2.83995 1.75436 2.83995 1.95321 3.03919L5.04372 6.15024L11.0444 0.149132C11.2433 -0.0497107 11.5665 -0.0497107 11.7669 0.149132L12.8493 1.2323C13.0502 1.43155 13.0502 1.75597 12.8497 1.95482Z"
              fill="white"
            />
          </svg>
        </div>
        {t(`credit.periods.${item.text}`)}
      </label>
    )
  })

  return (
    <div className={s.terms_box} data-mobile={mobile}>
      <div className={s.label_box}>
        <Label labelText={t(`ccS.conditions.period.${moduleLocale}`)} />
      </div>
      <div className={s.term_btns} data-mobile={mobile} data-open={open} ref={dropdown}>
        {conditionTermsElements}
        {mobile && <div className={s.arrow} onClick={handleOpen} data-open={open} />}
      </div>
    </div>
  )
}

export default Term
