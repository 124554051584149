import i18n from 'i18next'

export const wrapLinksInAgreementText = text => {
  const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim
  const innerUrlPattern = /\[(.*?)\]/gim

  return text
    .replace(urlPattern, '<a href="$&" class="link" rel="nofollow noopener" target="_blank">$&</a>')
    .replace(innerUrlPattern, function (_, match) {
      let [text = '', url = ''] = match.split('|')
      if (url) {
        const parsedUrl = url.trim().match(/href="(.*?)"/)
        url = parsedUrl ? parsedUrl[1] || '' : url
      }
      if (text) {
        text.trim()
      }
      return '<a href="' + url + '" rel="nofollow noopener" target="_blank">' + text + '</a>'
    })
}

export const normalizeVisual = (visual, vehicleType) => {
  return {
    header: {
      texts: {
        strict: visual.header.texts.strict,
        main: visual.header.texts.main,
        tradeIn: visual.header.texts.trade_in,
        onlinePayment: visual.header.texts.online_payment,
        credit: visual.header.texts.credit,
      },
    },

    onlinePayment: {
      hideHeaderTooltip: visual?.onlinePayment?.hideHeaderTooltip || false,
    },

    palette: {
      buttonHoverColor: visual?.palette?.button_hover_color || '#212121',
      buttonHoverTextColor: visual?.palette?.button_hover_text_color || '#fff',
      buttonColor: visual?.palette?.button_color || '#000',
      buttonTextColor: visual?.palette?.button_text_color || '#ffffff',
      linkColor: visual?.palette?.link_color || '#07b400',
      linkHoverColor: visual?.palette?.link_hover_color || '#057400',
    },

    buttons: {
      goToOnlinePayment: {
        hideFromBaseForm: visual.buttons.go_to_online_payment.hide_from_base_form,
        text: visual.buttons.go_to_online_payment.text,
      },
      createSmartContract: {
        text: visual.buttons.create_smart_contract.text,
      },
      openWidget: {
        placeholders: visual.buttons.open_widget.placeholders,
        strict: visual.buttons.open_widget.strict,
        text: visual.buttons.open_widget.text || i18n.t(`car_statuses.available.detail_text.${vehicleType}`),
        text_reserved:
            visual.buttons.open_widget.text_reserved || i18n.t(`car_statuses.reserved.detail_text.${vehicleType}`),
        text_sold: visual.buttons.open_widget.text_sold || i18n.t(`car_statuses.sold.detail_text.${vehicleType}`),
        text_wait: visual.buttons.open_widget.text_wait || i18n.t(`car_statuses.wait.detail_text.${vehicleType}`),
      },
    },

    tooltip: {
      videoUrl: visual.tooltip.video_url,
    },

    list: {
      button: {
        position: {
          top: visual.list.button.position.top,
          left: visual.list.button.position.left,
          bottom: visual.list.button.position.bottom,
          right: visual.list.button.position.right,
        },
        text: visual.list.button.text,
      },
    },

    fields: {
      phone: {
        hideFromBaseForm: visual.fields.phone.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.phone.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.phone.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.phone.required_on_online_payment_form,
      },

      email: {
        hideFromBaseForm: visual.fields.email.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.email.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.email.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.email.required_on_online_payment_form,
      },

      name: {
        hideFromBaseForm: visual.fields.name.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.name.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.name.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.name.required_on_online_payment_form,
      },

      lastName: {
        hideFromBaseForm: visual.fields.last_name.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.last_name.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.last_name.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.last_name.required_on_online_payment_form,
      },

      secondName: {
        hideFromBaseForm: visual.fields.secondName.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.secondName.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.secondName.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.secondName.required_on_online_payment_form,
      },

      promoCode: {
        hideFromBaseForm: visual.fields.promoCode.hide_from_base_form,
        hideFromOnlinePaymentForm: visual.fields.promoCode.hide_from_online_payment_form,
        requiredOnBaseForm: visual.fields.promoCode.required_on_base_form,
        requiredOnOnlinePaymentForm: visual.fields.promoCode.required_on_online_payment_form,
      },
    },

    agreementText: {
      baseForm: '',
      onlinePaymentForm: '',
    },

    agreements: {
      communicationProcessing: {
        ...visual.agreements.communicationProcessing,
        baseFormText: wrapLinksInAgreementText(visual.agreements.communicationProcessing.baseFormText),
        onlinePaymentFormText: wrapLinksInAgreementText(visual.agreements.communicationProcessing.onlinePaymentFormText),
      },
      personalDataProcessing: {
        ...visual.agreements.personalDataProcessing,
        baseFormText: wrapLinksInAgreementText(visual.agreements.personalDataProcessing.baseFormText),
        onlinePaymentFormText: wrapLinksInAgreementText(visual.agreements.personalDataProcessing.onlinePaymentFormText),
      },
      type: visual.agreements.type,
    },

    leasing: {
      hideProgramPercentage: visual.leasing.hideProgramPercentage,
      hideProgramBankName: visual.leasing.hideProgramBankName,
      showRemainingPaymentInProgram: visual.leasing.showRemainingPaymentInProgram,
    },

    advantages: {
      hideUsual: visual.advantages.hideUsual,
      hideOnlinePayment: visual.advantages.hideOnlinePayment,

      usual: visual.advantages.usual,
      onlinePayment: visual.advantages.onlinePayment,
    },

    isPriceHidden: visual.isPriceHidden,
  }
}
