export const parseNumber = (s, round = false) =>
  round
    ? parseInt(
        String(s)
          .replace(/[^\d-,.]/g, '')
          .replace(',', '.'),
      ) || 0
    : Number(
        String(s)
          .replace(/[^\d-,.]/g, '')
          .replace(',', '.'),
      ) || 0
