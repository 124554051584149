import { COUNTRY_CODES } from '../../constants'

const INIT_STATE = {
  widget_id: '',
  modelData: '',
  currency: '',
  locale: '',

  // тултип олко цены
  priceToolTipEnabled: false,

  limitForSaving: {}, // объект с ключом тип формы FORM_SOURCE + credit и значением (число)

  defaultCountryCode: 'russia',
  possibleCountryCodes: Object.keys(COUNTRY_CODES),

  config: {},
  restrictions: {
    sc_widget_events_api_available: true,
  },

  calledFromList: false,
  bootBatchListType: 0, // 1 или 2, ставится при первой загрузке списка, не меняется, 0 по умолчанию

  customTags: {},

  customData: {},

  uniqueCode: 0,

  deprecated: {},

  isCustomBenefitsOn: false,

  disableSmartContractLink: false,

  disableSmartContractLogo: false,

  disableCash: false,

  dealerships: [],
  /*
  dealershipName - сторока или пустая строка,
  dealershipAddress - сторока или пустая строка,
  type: 'dealership' или 'client',
  clientName - строка,
  widgetName - сторока или пустая строка,
  widgetId - сторока,
   */
  activeDealership: {},
  isWidgetLoaded: true, // загрузился ли виджет при смене ДЦ с БЭ
  isWidgetLoadingFailed: false, // есть ли ошибка при загрузке виджета при смене ДЦ

  tradeInAvailableOnList: false, // true если во всех виджетах на списке включен трейд-ин
  creditAvailableOnList: false, // true если во всех виджетах на списке включен кредит
  backgroundBootedOnList: false, // true когда на списке выполнился boot первой карточки без открытия виджета
  listInitialized: false, // true когда на списке было отправлено событие bootedList и были вызван роут boot_settings

  visual: {
    header: {
      texts: {
        strict: false,
        main: '',
        tradeIn: '',
        onlinePayment: '',
        credit: '',
      },
    },

    onlinePayment: {
      hideHeaderTooltip: false,
    },

    buttons: {
      goToOnlinePayment: {
        hideFromBaseForm: false,
        text: '',
      },
      openWidget: {
        placeholders: {},
        strict: false,
        text: '',
      },
      createSmartContract: {
        text: '',
      },
    },

    advantages: {
      hideUsual: false,
      hideOnlinePayment: false,
      usual: [],
      onlinePayment: [],
    },

    tooltip: {
      videoUrl: '',
    },

    palette: {
      strict: false,
      buttonHoverColor: '',
      buttonHoverTextColor: '',
      buttonColor: '',
      buttonTextColor: '',
      linkColor: '',
      linkHoverColor: '',
    },

    list: {
      button: {
        position: {
          top: '',
          left: '',
          bottom: '',
          right: '',
        },
        text: '',
      },
    },

    leasing: {
      hideProgramPercentage: false,
      hideProgramBankName: false,
    },

    fields: {
      phone: {
        hideFromBaseForm: false,
        hideFromOnlinePaymentForm: false,
        requiredOnBaseForm: false,
        requiredOnOnlinePaymentForm: false,
      },

      email: {
        hideFromBaseForm: false,
        hideFromOnlinePaymentForm: false,
        requiredOnBaseForm: false,
        requiredOnOnlinePaymentForm: false,
      },

      name: {
        hideFromBaseForm: false,
        hideFromOnlinePaymentForm: false,
        requiredOnBaseForm: false,
        requiredOnOnlinePaymentForm: false,
      },

      last_name: {
        hideFromBaseForm: false,
        hideFromOnlinePaymentForm: false,
        requiredOnBaseForm: false,
        requiredOnOnlinePaymentForm: false,
      },
    },
  },

  taxes: [],

  listVersion: null, // для нового списка
  renderButtons: false, // для нового списка
  preset: null, // для нового списка
  vehicleType: 'auto',
  widgetOpenStart: 0,
  customSource: null,
}

const settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_DISABLE_CASH':
      return {
        ...state,
        disableCash: action.payload,
      }
    case 'SET_RESTRICTIONS':
      return {
        ...state,
        restrictions: action.payload,
      }
    case 'SET_WIDGET_CONFIG':
      return {
        ...state,
        config: action.payload,
      }
    case 'SET_CUSTOM_TAGS':
      return {
        ...state,
        customTags: action.payload,
      }

    case 'PUSH_CUSTOM_TAG':
      return {
        ...state,
        customTags: {
          ...state.customTags,
          [action.payload.key]: action.payload.value,
        },
      }

    case 'SET_CUSTOM_DATA':
      return {
        ...state,
        customData: action.payload,
      }
    case 'SET_CUSTOM_DATA_FIELD':
      return {
        ...state,
        customData: state.customData
          ? {
              ...state.customData,
              [action.payload.key]: action.payload.value,
            }
          : {
              [action.payload.key]: action.payload.value,
            },
      }

    case 'SET_DEFAULT_COUNTRY_CODE':
      return {
        ...state,
        defaultCountryCode: action.payload,
      }

    case 'SET_POSSIBLE_COUNTRY_CODES':
      return {
        ...state,
        possibleCountryCodes: action.payload,
      }

    case 'SET_CURRENCY':
      return {
        ...state,
        currency: action.currency,
      }

    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.locale,
      }

    case 'SET_WIDGET_ID':
      return {
        ...state,
        widget_id: action.widget_id,
      }

    case 'SET_MODEL_DATA':
      return {
        ...state,
        modelData: action.modelData,
      }

    case 'SET_CALLED_FROM_LIST':
      return {
        ...state,
        calledFromList: action.calledFromList,
      }

    case 'SET_DEPRECATED_VISUAL':
      return {
        ...state,
        deprecated: action.deprecated,
      }

    case 'SET_VISUAL':
      return {
        ...state,
        visual: action.visual,
      }

    case 'SET_CUSTOM_BENEFITS_SETTING':
      return {
        ...state,
        isCustomBenefitsOn: action.isCustomBenefitsOn,
      }

    case 'SET_DISABLE_SMART_CONTRACT_LINK':
      return {
        ...state,
        disableSmartContractLink: action.payload,
      }

    case 'SET_DISABLE_SMART_CONTRACT_LOGO':
      return {
        ...state,
        disableSmartContractLogo: action.payload,
      }

    case 'SET_DEALERSHIPS':
      return {
        ...state,
        dealerships: action.payload,
      }

    case 'SET_ACTIVE_DEALERSHIP':
      return {
        ...state,
        activeDealership: action.payload,
      }

    case 'SET_IS_WIDGET_LOADED':
      return {
        ...state,
        isWidgetLoaded: action.payload,
      }

    case 'SET_IS_WIDGET_LOADING_FAILED':
      return {
        ...state,
        isWidgetLoadingFailed: action.payload,
      }

    case 'SET_BOOT_BATCH_LIST_TYPE':
      return {
        ...state,
        bootBatchListType: action.payload,
      }

    case 'GENERATE_UNIQUE_CODE':
      return {
        ...state,
        uniqueCode: action.payload,
      }

    case 'SET_PRICE_TOOLTIP':
      return {
        ...state,
        priceToolTipEnabled: action.payload,
      }

    case 'SET_LIST_TRADE_IN_BEHAVIOUR':
      return {
        ...state,
        tradeInAvailableOnList: action.payload,
      }

    case 'SET_LIST_CREDIT_BEHAVIOUR':
      return {
        ...state,
        creditAvailableOnList: action.payload,
      }

    case 'SET_BACKGROUND_BOOTED_ON_LIST':
      return {
        ...state,
        backgroundBootedOnList: true,
      }

    case 'SET_LIST_INITIALIZED':
      return {
        ...state,
        listInitialized: true,
      }

    case 'SET_PRESET':
      return {
        ...state,
        preset: action.payload,
      }

    case 'SET_LIST_VERSION':
      return {
        ...state,
        listVersion: action.payload,
      }

    case 'SET_RENDER_BUTTONS':
      return {
        ...state,
        renderButtons: action.payload,
      }

    case 'SET_LIMIT_FOR_SAVING':
      return {
        ...state,
        limitForSaving: {
          ...state.limitForSaving,
          [action.key]: action.value,
        },
      }

    case 'SET_VEHICLE_TYPE':
      return {
        ...state,
        vehicleType: action.payload,
      }

    case 'SET_TAXES':
      return {
        ...state,
        taxes: action.payload,
      }

    case 'SET_CUSTOM_SOURCE':
      return {
        ...state,
        customSource: action.payload,
      }

    default:
      return state
  }
}

export default settings
