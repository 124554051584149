import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Label } from '../UI/Common'
import Card from './Card'
import { getCreditProgramEnding } from './endingUtil'
import s                          from './styles/list.module.scss'
import {getProjectName} from "../../helpers/app/detectors/project_name";

const List = ({
  bankName,
  programs,
  selectedProgram,
  handleSelect,
  opened,
  isPreview,
  currency,
  moduleLocale,
  isLeasing,
  onlyOneBank,
}) => {
  const { t, i18n } = useTranslation()

  const [open, setsOpen] = useState(false)

  const hideProgramBankName = useSelector(state => state.credit_standalone.config.hideProgramBankName)

  const handleToggleClick = () => setsOpen(open => !open)

  const handleToggleKeyDown = e => {
    if (e.key === 'Enter') {
      setsOpen(open => !open)
    }
  }

  useEffect(() => {
    if (opened) {
      setsOpen(true)
    } else {
      setsOpen(false)
    }
  }, [opened])

  const suitableCreditProgramsText = () => {
    if (i18n.language === 'en-US') {
      return `${programs.length - 1} ${
        programs.length - 1 === 1
          ? t(`ccS.personal.yourSuitableProgramsP2V2.${moduleLocale}`)
          : t(`ccS.personal.yourSuitableProgramsP2V1.${moduleLocale}`)
      }`
    } else {
      return `${t('ccS.personal.yourSuitableProgramsP2')} ${programs.length - 1} 
      ${getCreditProgramEnding(programs.length - 1, moduleLocale)} ${t('ccS.programs.fromThisBank')}`
    }
  }

  const labelText = open ? t('ccS.programs.hide') : suitableCreditProgramsText()

  const renderCards = () => {
    if (!open && selectedProgram) {
      const listHasSelected = programs.find(program => program.id === selectedProgram.id)

      if (listHasSelected) {
        return (
          <Card
            currency={currency}
            program={selectedProgram}
            type="program"
            showDescription={isLeasing}
            key={selectedProgram.id}
            selectedProgramId={selectedProgram.id}
            handleSelect={handleSelect}
            isPreview={isPreview}
          />
        )
      }
    }

    const cards = programs.map(program => {
      return (
        <Card
          currency={currency}
          program={program}
          type="program"
          showDescription={isLeasing}
          key={program.id}
          selectedProgramId={selectedProgram?.id}
          handleSelect={handleSelect}
          isPreview={isPreview}
        />
      )
    })

    return open ? cards : cards[0]
  }

  const showBankName = !(hideProgramBankName && onlyOneBank)

  return (
    <div className={s.list_box} data-project-name={getProjectName()}>
      <div className={s.list_header}>
        {showBankName && <span className={s.bank_name}>{bankName || t('ccS.programs.bankName')}</span>}
        {showBankName && programs.length > 1 && !isPreview && (
          <div
            className={s.toggle_programs}
            data-open={open}
            tabIndex={0}
            onKeyDown={handleToggleKeyDown}
            onClick={handleToggleClick}
          >
            <Label labelText={labelText} data-open={open} />
            <div className={s.toggle_icon}></div>
          </div>
        )}
      </div>
      <div className={s.list}>{renderCards()}</div>
    </div>
  )
}

export default List
