import './_theme_generic_v2.scss'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getProjectName } from '../../../../helpers/app/detectors/project_name'
import Input from '../../../Ui/input'

function TradeInInputWithRadio({ variants, name, checked, onSet }) {
  const [search, setSearch] = useState('')
  const [all, setAll] = useState(false)

  const { t } = useTranslation()

  const havePopular = !!variants.find(variant => variant.base)

  const handleSet = event => {
    const {
      target: { value },
    } = event

    const variant = variants.find(variant => variant.id === value)

    onSet({
      id: variant.id,
      name: variant.name,
      external: variant.external,
    })
  }

  const filter = variant => {
    if (search !== '') {
      return (
        variant.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        variant.search.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
    }

    if (!havePopular) {
      return true
    }

    if (all === false) {
      return variant.base
    }

    return true
  }

  return (
    <div className="kdxsc-tradein-item" data-project-name={getProjectName()}>
      <div className="kdxsc-tradein-item__input">
        <Input value={search} label={''} disabled={false} handleChange={e => setSearch(e.target.value)} />
      </div>
      <div className="kdxsc-tradein-item__items">
        <div className="kdxsc-row">
          {variants.filter(filter).map(variant => {
            return (
              <div className="kdxsc-row__item" key={`${name}-id-${variant.id}`}>
                <input
                  className="kdxsc-radio"
                  name={name}
                  type="radio"
                  checked={checked === variant.id}
                  id={`${name}-id-${variant.id}`}
                  value={variant.id}
                  onChange={handleSet}
                />
                <label htmlFor={`${name}-id-${variant.id}`}>{variant.name}</label>
              </div>
            )
          })}
        </div>
      </div>
      {search === '' && havePopular && (
        <div className="kdxsc-tradein-item__footer">
          <div className="kdxsc-action-link-wrapper" onClick={() => setAll(!all)}>
            <span className="kdxsc-action-link">
              {!all ? t('tradeIn.helpers.showAll') : t('tradeIn.helpers.showPopular')}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default TradeInInputWithRadio
