// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".identity_wrapper__3l-O1{box-sizing:border-box;height:100%;position:relative;width:100%}.identity_wrapper__input__3DqDh{height:100%;width:100%}.identity_wrapper__range__EzoWd{bottom:-14px;left:-21px;position:absolute;width:calc(100% + 34px);z-index:10000}\n", "",{"version":3,"sources":["webpack://src/TradeInStandalone/firstScreenElements/inputs/identity/identity.module.scss"],"names":[],"mappings":"AAAA,yBACE,qBAAsB,CACtB,WAAY,CACZ,iBAAkB,CAClB,UAAW,CAEX,gCACE,WAAY,CACZ,UAAW,CACZ,gCAGC,YAAa,CACb,UAAW,CACX,iBAAkB,CAClB,uBAAwB,CACxB,aAAc","sourcesContent":[".wrapper {\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n  width: 100%;\n\n  &__input {\n    height: 100%;\n    width: 100%;\n  }\n\n  &__range {\n    bottom: -14px;\n    left: -21px;\n    position: absolute;\n    width: calc(100% + 34px);\n    z-index: 10000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "identity_wrapper__3l-O1",
	"wrapper__input": "identity_wrapper__input__3DqDh",
	"wrapper__range": "identity_wrapper__range__EzoWd"
};
export default ___CSS_LOADER_EXPORT___;
