import i18n from 'i18next'
import { load } from 'redux-localstorage-simple'

import {
  FINANCE_USER_BEHAVIOUR,
  LEASING_RATE_STATUS,
  LEASING_SETTINGS_BEHAVIOR,
  SCREEN_INDEX,
  SCREEN_LEASING,
} from '../../constants'
import { leasingAmount, loadLeasingPrograms, makeNumber } from '../../helpers'
import { LEASING_TYPES } from '../types'
import { dataLayer, leasingOpened, setFinanceBehaviourByUser, startLoading, stopLoading } from './index'

export const setLeasingStaticInfo = payload => ({
  type: LEASING_TYPES.SET_LEASING_STATIC_INFO,
  ...payload,
})

export const bootLeasingParameters = (
  { initialFeePercentage, period, buybackPaymentPercentage },
  price,
) => dispatch => {
  dispatch({
    type: LEASING_TYPES.BOOT_LEASING_PARAMETERS,
    initialFee: (initialFeePercentage / 100) * price,
    initialFeePercentage: initialFeePercentage,
    buybackPaymentPercentage,
    period,
  })
}

export const setLeasingPeriods = payload => ({
  type: LEASING_TYPES.SET_LEASING_PERIODS,
  payload,
})

export const setLeasingActiveProgram = payload => ({
  type: LEASING_TYPES.SET_ACTIVE_LEASING_PROGRAM,
  payload,
})

export const setLeasingStatus = payload => ({
  type: LEASING_TYPES.SET_LEASING_STATUS,
  payload,
})

export const goToLeasing = (dropLeasingState = true) => dispatch => {
  dispatch(leasingOpened())

  if (dropLeasingState) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS))
  }

  dispatch({
    type: 'GO_TO',
    screen: SCREEN_LEASING,
  })

  dispatch(dataLayer({ event: 'leasing', status: 'open' }))
}

export const enableLeasing = (redirect = false, openedFromMainScreen = true) => (dispatch, getState) => {
  const state = getState()

  const {
    leasing: { behaviour },
  } = state

  if (behaviour === LEASING_SETTINGS_BEHAVIOR.ENABLED) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_APPLIED))
  }

  if (behaviour === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS))
  }

  if (redirect) {
    dispatch({
      type: 'GO_TO',
      screen: SCREEN_INDEX,
    })
  }

  // если включили лизинг нажанием на "выбрать" внутри экрана лизинга, то apply, иначе on при включении с главного экрана
  if (!openedFromMainScreen) {
    dispatch(dataLayer({ event: 'leasing', status: 'apply', buttonName: i18n.t('credit.choose') }))
  } else {
    dispatch(dataLayer({ event: 'leasing', status: 'on' }))

    if (state.credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED) {
      dispatch(dataLayer({ event: 'credit', status: 'off' }))
    }
  }
}

export const disableLeasing = () => dispatch => {
  dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CASH))
  dispatch(dataLayer({ event: 'leasing', status: 'off' }))
}

export const switchLeasing = value => dispatch => {
  if (value) {
    dispatch(enableLeasing(false))
  } else {
    dispatch(disableLeasing())
  }
}

export const setLeasingInitialFee = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    leasing: {
      benefitValue: leasingBenefitAmount,
      parameters: { buybackPayment },
    },
  } = getState()

  const amount = leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount) / 2

  if (value > amount) {
    value = amount
  }

  if (value < 0) {
    value = 0
  }

  dispatch({
    type: LEASING_TYPES.SET_LEASING_INITIAL_FEE,
    initialFee: value,
    initialFeePercentage: Math.round((value * 100) / amount),
  })

  if (value + buybackPayment > Math.round(amount * 0.5)) {
    let buybackPayment = Math.round(amount * 0.5) - value
    if (buybackPayment < 0) {
      buybackPayment = 0
    }

    dispatch({
      type: LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT,
      buybackPayment,
      buybackPaymentPercentage: Math.round((buybackPayment * 100) / amount),
    })
  }
}

export const setLeasingInitialFeePercentage = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    leasing: {
      benefitValue: leasingBenefitAmount,
      parameters: { buybackPayment },
    },
  } = getState()

  const amount = leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount)

  if (value > 50) {
    value = 50
  }

  if (value < 0) {
    value = 0
  }

  const initialFee = (amount * value) / 100

  dispatch({
    type: LEASING_TYPES.SET_LEASING_INITIAL_FEE,
    initialFee,
    initialFeePercentage: value,
  })

  // если сумма ПВ + ОП больше суммы кредита, то пересчитываем ОП
  if (initialFee + buybackPayment > Math.floor(amount * 0.5)) {
    let buybackPayment = Math.floor(amount * 0.5) - initialFee
    if (buybackPayment < 0) {
      buybackPayment = 0
    }

    dispatch({
      type: LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT,
      buybackPayment,
      buybackPaymentPercentage: Math.round((buybackPayment * 100) / amount),
    })
  }
}

export const setLeasingBuybackPaymentPercentage = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    leasing: {
      benefitValue: leasingBenefitAmount,
      parameters: { initialFee },
    },
  } = getState()

  const amount = leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount)
  const buybackPayment = (amount * value) / 100

  dispatch({
    type: LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT,
    buybackPayment,
    buybackPaymentPercentage: value,
  })

  if (buybackPayment + initialFee > Math.round(amount * 0.5)) {
    let initialFee = Math.floor(amount * 0.5) - buybackPayment

    if (initialFee < 0) {
      initialFee = 0
    }

    dispatch({
      type: LEASING_TYPES.SET_LEASING_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const setLeasingBuybackPayment = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    leasing: {
      benefitValue: leasingBenefitAmount,
      parameters: { initialFee },
    },
  } = getState()

  const amount = leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount)

  dispatch({
    type: LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT,
    buybackPayment: value,
    buybackPaymentPercentage: Math.round((value * 100) / amount),
  })

  if (value + initialFee > Math.floor(amount * 0.5)) {
    let initialFee = Math.floor(amount * 0.5) - value

    if (initialFee < 0) {
      initialFee = 0
    }

    dispatch({
      type: LEASING_TYPES.SET_LEASING_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const adjustLeasingBuybackPaymentPercentage = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)
  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    leasing: {
      benefitValue: leasingBenefitAmount,
      parameters: { initialFee },
    },
  } = getState()

  const amount = leasingAmount(price, selected, selectedAccessories, leasingBenefitAmount)
  let buybackPayment = (amount * value) / 100
  if (buybackPayment > Math.floor(amount * 0.5)) {
    buybackPayment = Math.floor(amount * 0.5)
  }

  dispatch({
    type: LEASING_TYPES.SET_LEASING_BUYBACK_PAYMENT,
    buybackPayment,
    buybackPaymentPercentage: value,
  })

  if (buybackPayment + initialFee > Math.floor(amount * 0.5)) {
    let initialFee = Math.floor(amount * 0.5) - value
    if (initialFee < 0) {
      initialFee = 0
    }

    dispatch({
      type: LEASING_TYPES.SET_LEASING_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const setLeasingPrograms = programs => (dispatch, getState) => {
  const uniqueBanks = []
  const combinedPrograms = []

  if (programs) {
    programs.forEach(program => {
      if (!uniqueBanks.includes(program.bank.id)) {
        uniqueBanks.push(program.bank.id)
        combinedPrograms.push(program)
      }
    })
  }

  // если в localStorage есть активная кредитная программа, надо проверить, чтоб она была не схлопнута

  const dataFromLocalStorage = load({
    states: ['leasing'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const activeProgramIdFromLocalStorage = dataFromLocalStorage?.leasing?.activeProgram?.id

  if (activeProgramIdFromLocalStorage) {
    const activeProgramInCombinedPrograms = combinedPrograms.find(cp => {
      return cp.id === activeProgramIdFromLocalStorage
    })

    // если ее нет, то допушим
    if (!activeProgramInCombinedPrograms) {
      combinedPrograms.push(dataFromLocalStorage.leasing.activeProgram)
    }
  }

  const activeProgram = getState().leasing.activeProgram

  if (activeProgram?.id) {
    const activeProgramsWithNewParams = programs.find(program => {
      return program.id === activeProgram.id
    })

    // если есть выбранная программа, то находим ее и снова выбираем
    // иначе если нет программы то удаляем данные кредитной программы
    if (activeProgramsWithNewParams) {
      dispatch(setLeasingActiveProgram(activeProgramsWithNewParams))
    } else {
      dispatch(clearLeasingActiveProgram)
    }
  }

  dispatch({
    type: LEASING_TYPES.SET_LEASING_PROGRAMS,
    programs: uniqueBanks.length > 1 ? combinedPrograms : programs,
  })
}

export const setLeasingPeriod = period => ({
  type: LEASING_TYPES.SET_LEASING_PERIOD,
  period,
})

export const clearLeasingActiveProgram = dispatch => {
  const activeProgram = {
    id: '',
  }
  dispatch({
    type: LEASING_TYPES.SET_ACTIVE_LEASING_PROGRAM,
    activeProgram,
  })

  dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS))
}

export const calculateLeasingPrograms = leasingData => (dispatch, getState) => {
  const leasingState = getState().leasing
  const widgetId = getState().settings.widget_id

  if (leasingState.behaviour !== LEASING_SETTINGS_BEHAVIOR.ENABLED) {
    return false
  }
  dispatch(startLoading())

  loadLeasingPrograms(widgetId, JSON.stringify({ data: leasingData }))
    .then(({ data }) => {
      if (data?.length) {
        dispatch(setLeasingStatus(LEASING_RATE_STATUS.PROGRAMS_RECEIVED))
        dispatch(setLeasingPrograms(data))

        // если есть данные из localStorage по активной кредитной программе

        const dataFromLocalStorage = load({
          states: ['leasing'],
          namespace: '@kodix/smartContract',
          namespaceSeparator: '/',
        })

        const activeProgramIdFromLocalStorage = dataFromLocalStorage?.leasing?.activeProgram?.id

        if (activeProgramIdFromLocalStorage) {
          const activeProgram = data.find(d => {
            return d.id === activeProgramIdFromLocalStorage
          })

          // если такой программы нет, то очистим активную кредитную программу и сбрасываем статусы
          if (!activeProgram) {
            dispatch(clearLeasingActiveProgram())
            dispatch(setLeasingStatus(LEASING_RATE_STATUS.INITIAL))
            // иначе - переустановим
          } else {
            dispatch(setLeasingActiveProgram(activeProgram))
          }
        }
      } else {
        dispatch(setLeasingStatus(LEASING_RATE_STATUS.NO_PROGRAMS_FOUND))
        dispatch(setLeasingPrograms([]))
      }
    })
    .catch(e => {
      dispatch(setLeasingStatus(LEASING_RATE_STATUS.ERROR))
    })
    .finally(dispatch(stopLoading()))
}
