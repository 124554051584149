import s from './styles/block.module.scss'

function BorderedBlock(props) {
  return (
    <div className={s.block}>
      <div className={s.block__icon}>
        <img src={props.icon} alt="SmartContract" title="SmartContract" />
      </div>
      <div className={s.block__inner}>{props.inner}</div>
    </div>
  )
}

export default BorderedBlock
