import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import s from './styles/disclaimer.module.scss'

function DisclaimerWithVideo({ videoUrl }) {
  const { t } = useTranslation()

  const openVideo = () => {
    if (!videoUrl) {
      return false
    }
    window.open(videoUrl, '_blank')
  }

  return (
    <div className={s.disclaimer}>
      <div className={s.disclaimer__text}>{t('tradeInS.SCDisclaimerText')}</div>
      {videoUrl && (
        <div className={s.disclaimer__video}>
          <div className={s.video} onClick={openVideo}>
            <div className={s.video__icon}>
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.606 2.995C17.53 2.697 17.314 2.472 17.067 2.403C16.63 2.28 14.5 2 10 2C5.5 2 3.372 2.28 2.931 2.403C2.687 2.471 2.471 2.696 2.394 2.995C2.285 3.419 2 5.196 2 8C2 10.804 2.285 12.58 2.394 13.006C2.47 13.303 2.686 13.528 2.932 13.596C3.372 13.72 5.5 14 10 14C14.5 14 16.629 13.72 17.069 13.597C17.313 13.529 17.529 13.304 17.606 13.005C17.715 12.581 18 10.8 18 8C18 5.2 17.715 3.42 17.606 2.995ZM19.543 2.498C20 4.28 20 8 20 8C20 8 20 11.72 19.543 13.502C19.289 14.487 18.546 15.262 17.605 15.524C15.896 16 10 16 10 16C10 16 4.107 16 2.395 15.524C1.45 15.258 0.708 14.484 0.457 13.502C2.98023e-08 11.72 0 8 0 8C0 8 2.98023e-08 4.28 0.457 2.498C0.711 1.513 1.454 0.738 2.395 0.476C4.107 -1.78814e-07 10 0 10 0C10 0 15.896 -1.78814e-07 17.605 0.476C18.55 0.742 19.292 1.516 19.543 2.498ZM8 11.5V4.5L14 8L8 11.5Z"
                  fill="#222222"
                />
              </svg>
            </div>
            <div className={s.video__link}>{t('tradeInS.SCDisclaimerVideo')}</div>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  settings: {
    visual: {
      tooltip: { videoUrl },
    },
  },
}) {
  return {
    videoUrl,
  }
}

export default connect(mapStateToProps)(DisclaimerWithVideo)
