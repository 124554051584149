import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TI_PARTNER } from '../../../constants'
import { setTradeInYear, toggleTradeInStep } from '../../../store/actions'
import Input from '../../Ui/input'
import TradeInRadio from './Helpers/Radio'

function TradeInYear({ step, value, years, partner }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [typedYear, setTypedYear] = useState(value)

  const onSet = e => {
    if ([TI_PARTNER.AUTO_RU, TI_PARTNER.MAX_POSTER].includes(partner)) {
      dispatch(setTradeInYear({ value: e.id }))
    } else {
      let replacedValue = e.target.value.replace(/\D/g, '')

      if (replacedValue.length > 4) {
        return
      }

      setTypedYear(replacedValue)

      if (Number(replacedValue) >= 1908 && Number(replacedValue) <= new Date().getFullYear()) {
        dispatch(setTradeInYear({ value: replacedValue }))
      }
    }
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'year' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('year'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.year.title')}
          <div className="kdxsc-accordion-item__subtitle">{value || t('tradeIn.year.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        {[TI_PARTNER.AUTO_RU, TI_PARTNER.MAX_POSTER].includes(partner) && (
          <div className="kdxsc-accordion-item__content">
            <TradeInRadio name="year" variants={years} checked={value} onSet={onSet} />
          </div>
        )}

        {[TI_PARTNER.MOBILE_DE, TI_PARTNER.ONE_PLATFORM].includes(partner) && (
          <div className="kdxsc-accordion-item__content">
            <Input disabled={false} label={''} handleChange={e => onSet(e)} value={typedYear} />
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    partner,
    values: {
      year: { value },
    },
    dictionaries: { years },
  },
}) {
  return {
    step,
    value,
    years,
    partner,
  }
}

export default connect(mapStateToProps)(TradeInYear)
