// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../fonts/Roboto-Light.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../fonts/Roboto-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../fonts/Roboto-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../../fonts/Roboto-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'Roboto Light';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:'Roboto Regular';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:'Roboto Medium';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:'Roboto Bold';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}\n", "",{"version":3,"sources":["webpack://src/styles/new/opensans.css"],"names":[],"mappings":"AAAA,WACE,0BAA2B,CAC3B,2CAAwC,CAG1C,WACE,4BAA6B,CAC7B,2CAA0C,CAG5C,WACE,2BAA4B,CAC5B,2CAAyC,CAG3C,WACE,yBAA0B,CAC1B,2CAAuC","sourcesContent":["@font-face {\n  font-family: 'Roboto Light';\n  src: url(\"../../fonts/Roboto-Light.ttf\");\n}\n\n@font-face {\n  font-family: 'Roboto Regular';\n  src: url(\"../../fonts/Roboto-Regular.ttf\");\n}\n\n@font-face {\n  font-family: 'Roboto Medium';\n  src: url(\"../../fonts/Roboto-Medium.ttf\");\n}\n\n@font-face {\n  font-family: 'Roboto Bold';\n  src: url(\"../../fonts/Roboto-Bold.ttf\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
