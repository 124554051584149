import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import * as yup from 'yup'

import { formName } from '../../../carOrderForm/main/helpers'
import { COUNTRY_CODES, FORM_SOURCE } from '../../../constants'
import {
  getTimeToResend,
  refreshProtection,
  SmartContract_Prepay2Submit,
  SmartContract_Save_Submit,
} from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  clearSmsCodeTime,
  setCaptchaSettings,
  setCommunicationProcessing,
  setFormValue,
  setPhoneCountry,
  setPhoneValue,
  setSmsCodeSettings,
  startLoading,
  stopLoading,
} from '../../../store/actions'
import Checkbox from './checkbox/Checkbox'
import s from './form.module.scss'
import FormBlock from './FormBlock'
import {
  checkOldPhone,
  communicationProcessingLabel,
  contactsSectionTitle,
  createSmartContractButtonText,
  fieldIdentityFormatter,
  isCommunicationProcessingAcceptedCheckboxVisible,
  isCommunicationProcessingCheckboxVisible,
  onlinePaymentButtonText,
  personalDataLabel,
  personalSectionTitle,
  requirementHelper,
  scrollToFieldWithErrorInWidgetForm,
  visibilityHelper,
} from './helpers'
import Input from './input/Input'
import PhoneInput from './phoneInput/PnoneInput'
import Timer from './timer/Timer'

function Form({
  isVisible,
  isRequired,
  name,
  lastName,
  secondName,
  promoCode,
  email,
  smsCode,
  captcha,
  phoneRawValue,
  defaultCountryCode,
  possibleCountryCodes,
  agreement,
  communicationAccepted,
  communicationPhone,
  communicationSms,
  communicationEmail,
  communicationPost,
  country,
  visual,
  type,
  config,
  panelContent,
  panelTop,
  locale,
  captchaSettings,
  smsCodeSettings,
  captchaError,
  smsCodeError,
  limitForSaving,
  car,
  widgetId,
  currency,
}) {
  const formRef = useRef()
  const submitButton = useRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [timerCountdown, setTimerCountdown] = useState(0)

  const useWidgetStyles = [FORM_SOURCE.WIDGET, FORM_SOURCE.ONLINE_PAYMENT, FORM_SOURCE.WIDGET_AUTH].includes(
    config.source,
  )
  const showPersonalDataSection = isVisible('name') || isVisible('lastName') || isVisible('secondName')

  const promoCodeValidation = yup => {
    const pattern = /^[a-zA-Z0-9]{0,15}$/

    const trimmedValue = promoCode.trim()
    if (!trimmedValue) {
      return null
    }

    if (isVisible('promoCode')) {
      return yup.string().matches(pattern, t('carOrderForm.promoCodeError'))
    }

    return null
  }

  const emailValidation = yup => {
    if (isVisible('email') && isRequired('email')) {
      return yup.string().email(t('carOrderForm.incorrectFiled')).required(t('carOrderForm.requiredFiled'))
    }

    if (isVisible('email') && !isRequired('email')) {
      return yup.string().email(t('carOrderForm.incorrectFiled'))
    }

    return null
  }

  const namesValidation = (yup, type) => {
    const pattern = /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ\s]+$/

    let valueToValidate
    if (type === 'name') valueToValidate = name
    if (type === 'lastName') valueToValidate = lastName
    if (type === 'secondName') valueToValidate = secondName

    if (isVisible(type) && isRequired(type)) {
      return locale === 'Ru'
        ? yup.string().matches(pattern, t('carOrderForm.incorrectFiled')).required(t('carOrderForm.requiredFiled'))
        : yup.string().required(t('carOrderForm.requiredFiled'))
    }

    if (isVisible(type) && !isRequired(type) && valueToValidate) {
      return locale === 'Ru' ? yup.string().matches(pattern, t('carOrderForm.incorrectFiled')) : null
    }

    return null
  }

  const protectionValidation = (yup, type) => {
    if (type && type.visible) {
      return yup.string().required(t('carOrderForm.requiredFiled'))
    }
    return null
  }

  const checkboxValidation = yup => {
    if (isCommunicationProcessingAcceptedCheckboxVisible(visual)) {
      return yup.bool().oneOf([true], t('carOrderForm.requiredFiled')).required(t('carOrderForm.requiredFiled'))
    }
    return null
  }

  const schema = yup.object().shape({
    name: namesValidation(yup, 'name'),
    lastName: namesValidation(yup, 'lastName'),
    secondName: namesValidation(yup, 'secondName'),
    captcha: protectionValidation(yup, captchaSettings),
    smsCode: protectionValidation(yup, smsCodeSettings),
    phone: yup
      .string()
      .matches(country ? COUNTRY_CODES[country].regex : true, t('carOrderForm.incorrectFiled'))
      .required(t('carOrderForm.requiredFiled')),
    email: emailValidation(yup),
    promoCode: promoCodeValidation(yup),
    agreement: yup.bool().oneOf([true], t('carOrderForm.requiredFiled')).required(t('carOrderForm.requiredFiled')),
  })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    shouldFocusError: config.source !== FORM_SOURCE.WIDGET,
  })

  /**
   * Нажатие на реальной невидимой кнопки submit в форме
   * Отправка события с данными наверх (если форма валидна)
   */
  const onSubmit = d => {
    window.dispatchEvent(
      new CustomEvent(formName(config).clickInsideForm, {
        detail: {
          isFromDataValid: true,
          formData: d,
        },
      }),
    )
  }

  /**
   * Ловим событие нажатие на кнопку НЕ из формы
   * Кликаем настоящую невидимую кнопку в форме
   * Если форма в виджете, то скроллим к ошибкам
   */
  const listener = useCallback(
    e => {
      e.preventDefault()

      // отправка в gtm
      if (config.source === FORM_SOURCE.WIDGET) {
        SmartContract_Save_Submit({
          createSmartContractButtonText: createSmartContractButtonText(),
          car,
          widget_id: widgetId,
          currency,
        })
      }

      // отправка в gtm
      if (config.source === FORM_SOURCE.ONLINE_PAYMENT) {
        SmartContract_Prepay2Submit({
          buttonName: onlinePaymentButtonText(),
          car,
          widget_id: widgetId,
          currency,
        })
      }

      let el = submitButton.current
      if (el) {
        if (el.disabled) {
          el.disabled = false
        }

        el.click()

        if (config.source === FORM_SOURCE.WIDGET) {
          scrollToFieldWithErrorInWidgetForm(panelContent, panelTop)
        }
      } else {
        console.error('el can not be clicked')
      }
    },
    [config.source, panelContent, panelTop],
  )

  /**
   * Добавление в старый локалсторадж новых данных phoneSelection
   * (перенесено из старого компонента формы)
   */
  const checkOldData = useCallback(() => {
    checkOldPhone(phoneRawValue, dispatch)
  }, [dispatch, phoneRawValue])

  useEffect(() => {
    checkOldData()
  }, [checkOldData])

  /**
   * Слушаем события клика снаружи формы
   */
  useEffect(() => {
    window.addEventListener(formName(config).clickOutsideForm, listener)

    return () => {
      window.removeEventListener(formName(config).clickOutsideForm, listener)
    }
  }, [config, listener])

  /**
   * Для таймера для СМС
   */
  useEffect(() => {
    let currentDate = new Date()

    if (
      !timerCountdown &&
      smsCodeSettings?.timeToResend &&
      smsCodeSettings?.timeToResend?.getTime() >= currentDate.getTime()
    ) {
      setTimerCountdown(smsCodeSettings?.timeToResend?.getTime() - currentDate.getTime())
    }
  }, [timerCountdown, smsCodeSettings])

  /**
   * При очищении таймера
   */
  const onClearCountdown = () => {
    dispatch(clearSmsCodeTime(config.source))
    setTimerCountdown(0)
  }

  /**
   * Обновить капчу/смс
   * @param protectionType
   */
  const sendProtectionOnceAgain = protectionType => {
    switch (protectionType) {
      case 'smsCode':
        dispatch(startLoading())
        dispatch(setFormValue('smsCode', ''))
        refreshProtection(smsCodeSettings.ticketId)
          .then(r => {
            dispatch(
              setSmsCodeSettings(config.source, {
                visible: true,
                timeToResend: getTimeToResend(r?.data?.attributes),
                ticketId: r?.data?.id,
              }),
            )
          })
          .catch(e => {
            console.error('unable to send sms code', e)
          })
          .finally(() => {
            dispatch(stopLoading())
          })
        break

      case 'captcha':
        dispatch(startLoading())
        dispatch(setFormValue('captcha', ''))
        refreshProtection(captchaSettings.ticketId)
          .then(r => {
            dispatch(
              setCaptchaSettings(config.source, {
                visible: true,
                captchaSrc: r?.data?.attributes?.question,
                ticketId: r?.data?.id,
              }),
            )
          })
          .catch(e => {
            console.error('unable receive captcha', e)
          })
          .finally(() => {
            dispatch(stopLoading())
          })
        break

      default:
        console.error('unknown protection type')
    }
  }

  return (
    <form
      noValidate
      className={[s.form, useWidgetStyles ? s.formInWidget : ''].join(' ')}
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
      key={formName(config).formKey}
      data-project-name={getProjectName()}
    >
      <button type="submit" ref={submitButton} style={{ display: 'none' }}>
        {t('carOrderForm.send')}
      </button>
      {showPersonalDataSection && (
        <div className={s.form__item}>
          <FormBlock title={personalSectionTitle(config)}>
            {isVisible('name') && (
              <div className={s.form__item}>
                <Input
                  ref={register}
                  label={t('form.yourName')}
                  id={fieldIdentityFormatter(formName(config).formKey, 'name')}
                  name="name"
                  required={isRequired('name')}
                  errors={Boolean(errors.name)}
                  errorsText={errors?.name?.message}
                  handleChange={v => dispatch(setFormValue('name', v))}
                  value={name}
                  useWidgetStyles={useWidgetStyles}
                />
              </div>
            )}

            {isVisible('lastName') && (
              <div className={s.form__item}>
                <Input
                  ref={register}
                  label={t('form.yourLastName')}
                  id={fieldIdentityFormatter(formName(config).formKey, 'lastName')}
                  name="lastName"
                  required={isRequired('lastName')}
                  errors={Boolean(errors.lastName)}
                  errorsText={errors?.lastName?.message}
                  handleChange={v => dispatch(setFormValue('lastName', v))}
                  value={lastName}
                  useWidgetStyles={useWidgetStyles}
                />
              </div>
            )}

            {isVisible('secondName') && (
              <div className={s.form__item}>
                <Input
                  ref={register}
                  label={t('form.yourSecondName')}
                  id={fieldIdentityFormatter(formName(config).formKey, 'secondName')}
                  name="secondName"
                  required={isRequired('secondName')}
                  errors={Boolean(errors.secondName)}
                  errorsText={errors?.secondName?.message}
                  handleChange={v => dispatch(setFormValue('secondName', v))}
                  value={secondName}
                  useWidgetStyles={useWidgetStyles}
                />
              </div>
            )}
          </FormBlock>
        </div>
      )}
      <div className={s.form__item}>
        <FormBlock title={contactsSectionTitle(config)}>
          <div className={s.form__item}>
            <PhoneInput
              ref={register}
              label={t('form.phoneNumber')}
              id={fieldIdentityFormatter(formName(config).formKey, 'phone')}
              name="phone"
              country={country}
              value={phoneRawValue}
              setPhoneCountry={country => dispatch(setPhoneCountry(country))}
              setPhoneValue={({ phone, phoneRawValue }) => dispatch(setPhoneValue({ phone, phoneRawValue }))}
              required={true}
              errors={Boolean(errors.phone)}
              errorsText={errors?.phone?.message}
              useWidgetStyles={useWidgetStyles}
              defaultCountryCode={defaultCountryCode}
              possibleCountryCodes={possibleCountryCodes}
            />
          </div>

          {isVisible('email') && (
            <div className={s.form__item}>
              <Input
                ref={register}
                label={t('form.email')}
                id={fieldIdentityFormatter(formName(config).formKey, 'email')}
                name="email"
                required={isRequired('email')}
                errors={Boolean(errors.email)}
                errorsText={errors?.email?.message}
                handleChange={v => dispatch(setFormValue('email', v))}
                value={email}
                useWidgetStyles={useWidgetStyles}
              />
            </div>
          )}
        </FormBlock>
      </div>
      {isVisible('promoCode') && (
        <div className={s.form__item}>
          <Input
            ref={register}
            label={t('form.promoCode')}
            id={fieldIdentityFormatter(formName(config).formKey, 'promoCode')}
            name="promoCode"
            required={isRequired('promoCode')}
            errors={Boolean(errors.promoCode)}
            errorsText={errors?.promoCode?.message}
            handleChange={v => dispatch(setFormValue('promoCode', v))}
            value={promoCode}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      <div className={s.form__item}>
        <Checkbox
          ref={register}
          label={personalDataLabel(visual, type)}
          id={fieldIdentityFormatter(formName(config).formKey, 'personalDataProcessing')}
          name="agreement"
          required={true}
          // errors={Boolean(errors.agreement)}
          errorsText={errors?.agreement?.message}
          checked={agreement}
          handleChange={v => dispatch(setFormValue('agreement', v))}
          useWidgetStyles={useWidgetStyles}
        />
      </div>
      {isCommunicationProcessingAcceptedCheckboxVisible(visual) && (
        <div className={s.form__item}>
          <Checkbox
            ref={register}
            label={communicationProcessingLabel(visual, type)}
            id={fieldIdentityFormatter(formName(config).formKey, 'communicationProcessing')}
            name="communicationProcessing"
            required={false}
            // errors={Boolean(errors.agreement)}
            errorsText={errors?.communicationProcessing?.message}
            checked={communicationAccepted}
            handleChange={v => dispatch(setCommunicationProcessing('accepted', v))}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      {isCommunicationProcessingCheckboxVisible(visual, 'email') && (
        <div className={s.form__item}>
          <Checkbox
            ref={register}
            label={'email'}
            id={fieldIdentityFormatter(formName(config).formKey, 'communicationProcessingEmail')}
            name="communicationProcessingEmail"
            required={false}
            // errors={Boolean(errors.agreement)}
            errorsText={''}
            checked={communicationEmail}
            handleChange={v => dispatch(setCommunicationProcessing('email', v))}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      {isCommunicationProcessingCheckboxVisible(visual, 'phone') && (
        <div className={s.form__item}>
          <Checkbox
            ref={register}
            label={'phone'}
            id={fieldIdentityFormatter(formName(config).formKey, 'communicationProcessingPhone')}
            name="communicationProcessingPhone"
            required={false}
            // errors={Boolean(errors.agreement)}
            errorsText={''}
            checked={communicationPhone}
            handleChange={v => dispatch(setCommunicationProcessing('phone', v))}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      {isCommunicationProcessingCheckboxVisible(visual, 'post') && (
        <div className={s.form__item}>
          <Checkbox
            ref={register}
            label={'post'}
            id={fieldIdentityFormatter(formName(config).formKey, 'communicationProcessingPost')}
            name="communicationProcessingPost"
            required={false}
            // errors={Boolean(errors.agreement)}
            errorsText={''}
            checked={communicationPost}
            handleChange={v => dispatch(setCommunicationProcessing('post', v))}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      {isCommunicationProcessingCheckboxVisible(visual, 'sms') && (
        <div className={s.form__item}>
          <Checkbox
            ref={register}
            label={'sms'}
            id={fieldIdentityFormatter(formName(config).formKey, 'communicationProcessingSms')}
            name="communicationProcessingSms"
            required={false}
            // errors={Boolean(errors.agreement)}
            errorsText={''}
            checked={communicationSms}
            handleChange={v => dispatch(setCommunicationProcessing('sms', v))}
            useWidgetStyles={useWidgetStyles}
          />
        </div>
      )}
      {/*<div className={s.form__item}>*/}
      {/*  <Checkbox*/}
      {/*    ref={register}*/}
      {/*    label={agreementText(visual, type, deprecated)}*/}
      {/*    id={fieldIdentityFormatter(formName(config).formKey, 'agreement')}*/}
      {/*    name="agreement"*/}
      {/*    required={true}*/}
      {/*    // errors={Boolean(errors.agreement)}*/}
      {/*    errorsText={errors?.agreement?.message}*/}
      {/*    checked={agreement}*/}
      {/*    handleChange={v => dispatch(setFormValue('agreement', v))}*/}
      {/*    useWidgetStyles={useWidgetStyles}*/}
      {/*  />*/}
      {/*</div>*/}
      {captchaSettings && captchaSettings.visible && (
        <div className={s.form__item}>
          <div className={[s.captcha_wrapper, useWidgetStyles ? s.small_form : s.big_form].join(' ')}>
            {captchaError && <div className={s.captcha_error}>{t('form.captchaError')}</div>}
            <div className={[s.captcha, useWidgetStyles ? s.small_form : s.big_form].join(' ')}>
              <div className={s.captcha__col}>
                <img src={captchaSettings.captchaSrc} alt="" />
              </div>
              <div className={s.captcha__col}>
                <Input
                  ref={register}
                  label={t('form.captcha')}
                  id={fieldIdentityFormatter(formName(config).formKey, 'captcha')}
                  name="captcha"
                  required={isRequired('captcha')}
                  errors={Boolean(errors.captcha)}
                  errorsText={errors?.captcha?.message}
                  handleChange={v => dispatch(setFormValue('captcha', v))}
                  value={captcha}
                  useWidgetStyles={useWidgetStyles}
                  forCaptcha={true}
                />
              </div>
            </div>
            <div
              className={[s.captcha_refresh, s.active].join(' ')}
              onClick={sendProtectionOnceAgain.bind(this, 'captcha')}
            >
              {t('form.resendCaptcha')}
            </div>
          </div>
        </div>
      )}
      {smsCodeSettings && smsCodeSettings.visible && (
        <div className={s.form__item}>
          <div className={[s.captcha_wrapper, useWidgetStyles ? s.small_form : s.big_form].join(' ')}>
            {smsCodeError && <div className={s.captcha_error}>{t('form.smsCodeError')}</div>}
            <div className={s.captcha}>
              <div className={s.captcha__col}>
                <div className={s.sms_text}>{t('form.smsCodeHint')}</div>
              </div>
              <div className={s.captcha__col}>
                <Input
                  ref={register}
                  label={t('form.smsCode')}
                  id={fieldIdentityFormatter(formName(config).formKey, 'smsCode')}
                  name="smsCode"
                  required={isRequired('smsCode')}
                  errors={Boolean(errors.smsCode)}
                  errorsText={errors?.smsCode?.message}
                  handleChange={v => dispatch(setFormValue('smsCode', v))}
                  value={smsCode}
                  useWidgetStyles={useWidgetStyles}
                  forCaptcha={true}
                />
              </div>
            </div>
            <div className={[s.captcha_refresh, timerCountdown ? '' : s.active].join(' ')}>
              {timerCountdown > 0 ? (
                <Timer countdown={timerCountdown} onClearCountdown={onClearCountdown.bind(this)} />
              ) : (
                <span onClick={sendProtectionOnceAgain.bind(this, 'smsCode')}>{t('form.resendSmsCode')}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </form>
  )
}
function mapStateToProps(state, { type, config }) {
  let deprecated, visual, defaultCountryCode, possibleCountryCodes, locale
  let car, widgetId, currency // для получения нажатий на кнопку в виджете в футере и форме ОО

  const {
    form: { values, agreements, phoneSelection, captchaSettings, smsCodeSettings, captchaError, smsCodeError },
    settings: { limitForSaving },
  } = state

  const { name, lastName, email, agreement, smsCode, captcha, secondName, promoCode } = values
  const {
    communication: {
      accepted: communicationAccepted,
      phone: communicationPhone,
      sms: communicationSms,
      email: communicationEmail,
      post: communicationPost,
    },
  } = agreements
  let { country, phoneRawValue } = phoneSelection

  if (
    [
      FORM_SOURCE.CAR_ORDER,
      FORM_SOURCE.WIDGET,
      FORM_SOURCE.ONLINE_PAYMENT,
      FORM_SOURCE.TRADE_IN_STANDALONE,
      FORM_SOURCE.WIDGET_AUTH,
    ].includes(config.source)
  ) {
    // + для получения нажатий на кнопку в виджете в футере и форме ОО
    const {
      settings: { widget_id: coWidgetId, currency: coCurrency },
      car: coCar,
    } = state

    car = coCar
    widgetId = coWidgetId
    currency = coCurrency
    // - для получения нажатий на кнопку в виджете в футере и форме ОО

    // + для определения локализации в ТИ форме
    const isTradeInForm = config.source === FORM_SOURCE.TRADE_IN_STANDALONE
    let coOrTiLocale

    const {
      form: { defaultCountryCode: coDefaultCountryCode, possibleCountryCodes: coPossibleCountryCodes },
    } = state

    let {
      settings: { deprecated: coDeprecated, visual: coVisual, locale: coLocale },
    } = state

    coOrTiLocale = coLocale

    let tiVisual = null
    if (isTradeInForm) {
      let {
        tradeInStandalone: { locale: tiLocale, visual },
      } = state
      coOrTiLocale = tiLocale
      tiVisual = visual
    }
    // - для определения локализации в ТИ форме

    deprecated = coDeprecated
    visual = tiVisual ? tiVisual : coVisual
    defaultCountryCode = coDefaultCountryCode
    possibleCountryCodes = coPossibleCountryCodes
    locale = coOrTiLocale
  } else if (config.source === FORM_SOURCE.BUY_MODEL) {
    const {
      buyModelStandalone: {
        visual: bmVisual,
        defaultCountryCode: bmDefaultCountryCode,
        possibleCountryCodes: bmPossibleCountryCodes,
        locale: bmLocale,
      },
    } = state

    deprecated = {}
    visual = bmVisual
    defaultCountryCode = bmDefaultCountryCode
    possibleCountryCodes = bmPossibleCountryCodes
    locale = bmLocale
  } else if (config.source === FORM_SOURCE.SIMPLE_FORM) {
    const {
      simpleForm: {
        config: {
          visual: sfVisual,
          defaultCountryCode: sfDefaultCountryCode,
          possibleCountryCodes: sfPossibleCountryCodes,
          locale: sfLocale,
        },
      },
    } = state

    deprecated = {}
    visual = sfVisual
    defaultCountryCode = sfDefaultCountryCode
    possibleCountryCodes = sfPossibleCountryCodes
    locale = sfLocale

    if (!country) {
      country = sfDefaultCountryCode
    }
  }

  return {
    name,
    lastName,
    secondName,
    promoCode,
    phoneRawValue,
    email,
    smsCode,
    captcha,
    agreement,
    communicationAccepted,
    communicationPhone,
    communicationSms,
    communicationEmail,
    communicationPost,
    country,
    visual,
    deprecated,
    type,
    defaultCountryCode,
    possibleCountryCodes,
    locale,

    car,
    widgetId,
    currency,

    captchaSettings: captchaSettings[config.source],
    smsCodeSettings: smsCodeSettings[config.source],
    captchaError: captchaError[config.source],
    smsCodeError: smsCodeError[config.source],
    limitForSaving: limitForSaving[config.source],

    isVisible: key => {
      return visibilityHelper(visual, key, type)
    },

    isRequired: key => {
      return requirementHelper(visual, key, type)
    },
  }
}

export default connect(mapStateToProps)(Form)
