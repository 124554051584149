module.exports = {
  title: 'Get an offer',
  send: 'Send',
  dealership: 'Dealership',
  dealershipError: 'Select a dealership to continue',

  requiredFiled: 'This field is required',
  incorrectFiled: 'This field is filled incorrectly',
  promoCodeError: 'You can use numbers and Latin letters, maximum 15 characters',

  personalData: 'Personal Data',
  contacts: 'Contact information',

  car: 'Car',
  model: 'Model',

  search: 'Search',
  nothingFound: 'Nothing found',
}
