import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectNameHelper = (preset, detail, listVersion) => {
  if (listVersion === '2') {
    return detail.name
  }

  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.name()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.name) {
    if (typeof detail.name === 'string') {
      return detail.name
    } else if (typeof detail.name === 'function') {
      return detail.name()
    }
  }

  return ''
}
