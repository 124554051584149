module.exports = {
  state: {
    title: 'Trade-in',
    subtitleWithBenefit: 'Trade-in with benefit',
    subtitleWithoutBenefit: 'Trade-in',
    initialAdditionalText: 'Rate your vehicle online',
    additionalTextWithCarData: 'I give my { brand } { model }, { year }',
    autoEstimationIsImpossible: 'Automatic estimation is not possible',
    withoutTradeIn: 'Without Trade-in',
    benefitHint: {
      auto: 'This benefit applies in the case of buying this car in a trade-in',
      moto: 'This benefit applies in the case of buying this motorcycle in a trade-in',
      other: 'This benefit applies in the case of buying this vehicle in a trade-in',
    },
  },
  screen: {},
  estimations: {
    goToTradeInList: 'Evaluate by parameters from the catalog',
    reset: 'Your current score and entered data will be reset',
    goToTradeInByIdentity: 'Evaluate by VIN or State number',
  },
}
