import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import s from './advantages.module.scss'

function Advantages({
  usualAdvantages,
  onlinePaymentAdvantages,
  showOnlinePayment,
  priceToolTipEnabled,
  hideUsualAdvantages,
  hideOnlinePaymentAdvantages,
  vehicleType,
}) {
  const { t } = useTranslation()

  const showAdvantages = Boolean(!hideUsualAdvantages || !hideOnlinePaymentAdvantages)

  const defaultUsualAdvantages = () => {
    let res = []
    if (!priceToolTipEnabled) {
      res.push(t('benefitsList.saveTermsUntil48Hours'))
    }
    res.push(t('benefitsList.personalManager'))
    res.push(t(`benefitsList.smsAndStatus.${vehicleType}`))

    return res
  }

  const defaultOnlinePaymentAdvantages = () => {
    let res = []
    if (!priceToolTipEnabled) {
      res.push(t('benefitsList.saveTermsUntilTneEnd'))
    }
    res.push(t(`benefitsList.carBooking.${vehicleType}`))
    res.push(t('benefitsList.returnOnlinePaymentEasily'))

    return res
  }

  const renderBenefitsItems = (a, i) => (
    <div className={s.advantages__item} key={i}>
      <div className={s.advantage}>
        <div className={s.advantage__text}>{a}</div>
      </div>
    </div>
  )

  const usualAdvantagesItems = usualAdvantages.map(renderBenefitsItems),
    onlinePaymentAdvantagesItems = onlinePaymentAdvantages.map(renderBenefitsItems),
    defaultUsualAdvantagesItems = defaultUsualAdvantages().map(renderBenefitsItems),
    defaultOnlinePaymentAdvantagesItems = defaultOnlinePaymentAdvantages().map(renderBenefitsItems)

  return (
    <div className={s.wrapper} data-project-name={getProjectName()}>
      {showAdvantages && (
        <div className={s.wrapper__top}>
          {!hideUsualAdvantages && (
            <div className={s.wrapper__col}>
              {usualAdvantages.length === 0 && <div className={s.advantages}>{defaultUsualAdvantagesItems}</div>}
              {usualAdvantages.length > 0 && <div className={s.advantages}>{usualAdvantagesItems}</div>}
            </div>
          )}

          {showOnlinePayment && !hideOnlinePaymentAdvantages && (
            <div className={s.wrapper__col}>
              {onlinePaymentAdvantages.length === 0 && (
                <div className={s.advantages}>{defaultOnlinePaymentAdvantagesItems}</div>
              )}
              {onlinePaymentAdvantages.length > 0 && <div className={s.advantages}>{onlinePaymentAdvantagesItems}</div>}
            </div>
          )}
        </div>
      )}
      {priceToolTipEnabled && (
        <div className={s.wrapper__bottom}>
          <div>{t(`car.tooltipAlert.${vehicleType}`)}</div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  settings: {
    visual: { advantages, buttons },
    priceToolTipEnabled,
    vehicleType,
  },
  onlinePayment: { enabled },
}) {
  return {
    priceToolTipEnabled,
    hideUsualAdvantages: advantages.hideUsual,
    hideOnlinePaymentAdvantages: advantages.hideOnlinePayment,
    usualAdvantages: advantages.usual,
    onlinePaymentAdvantages: advantages.onlinePayment,
    showOnlinePayment: enabled && !buttons.goToOnlinePayment.hideFromBaseForm,
    vehicleType,
  }
}

export default connect(mapStateToProps)(Advantages)
