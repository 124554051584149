import { COUNTRY_CODES } from '../constants'

export const emailRegex = /.+@.+\..+/

export const isPhoneValid = (phone, country) => {
  return COUNTRY_CODES[country || 'russia'].regex.test(phone)
}

export const isEmailValid = email => {
  return emailRegex.test(email)
}

export const isCarIdentityValid = value => {
  if (!value || typeof value !== 'string') {
    return false
  }

  if (value.length < 8 && value.length > 17) {
    return false
  }

  return GRZValidator(value) || VINValidator(value)
}

export const GRZValidator = grz => {
  if (!grz) {
    return false
  }
  grz = grz.replace(/\s/g, '')?.toUpperCase()
  return /^[АВЕКМНОРСТУХ][0-9]{3}[АВЕКМНОРСТУХ]{2}[0-9]{2,3}$/.test(grz)
}

export const VINValidator = vin => {
  // регулярка на вин номер от автотеки
  vin = vin.toLowerCase()
  return /^[A-HJ-NPR-Za-hj-npr-zАВСЕНКМРТХУавсенкмртху0-9]{17}$/.test(vin)
}
