module.exports = {
  choose: 'Выберите дилерский центр, чтобы продолжить',
  available: 'Доступно',
  booked: 'Подобрать аналог',
  errorMessage:
    'К сожалению, произошла ошибка при загрузке информации от выбранного вами дилерского центра. Попробуйте выбрать другой дилерский центр или воспользуйтесь виджетом позднее.',
  yourDealership: 'Ваш дилерский центр',
  selected: 'Выбран',
  unavailableHint: {
    auto: 'Данный автомобиль {carStatus}.<br />Вы можете сохранить заявку и менеджер подберет Вам аналогичный автомобиль.',
    moto: 'Данный мотоцикл {carStatus}.<br />Вы можете сохранить заявку и менеджер подберет Вам аналогичный мотоцикл.',
    other:
      'Данное ТС находится в статусе {carStatus}.<br />Вы можете сохранить заявку и менеджер подберет Вам аналогичное ТС.',
  },
}
