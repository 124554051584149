export const formatMileage = (value, unit = 'км') => {
  if (parseInt(value) <= 0) {
    return ''
  }

  if (parseInt(value) < 999) {
    return `${value} ${unit}`
  }

  const v = (value + '').split('').reverse()
  let iterator = 0
  let text = ''

  for (const i of v) {
    text = i + text

    iterator++
    if (iterator === 3) {
      iterator = 0
      text = ' ' + text
    }
  }

  return `${text} ${unit}`
}
