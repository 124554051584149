import s from './input.module.scss'

function Input({ value, handleChange, disabled, label, hasError = false, uppercase = false }) {
  const classes = () => {
    let c = [s.input]

    if (hasError) {
      c.push(s.withError)
    }

    if (uppercase) {
      c.push(s.uppercase)
    }

    return c.join(' ')
  }
  return (
    <div className={s.wrapper}>
      {label && <label className={s.wrapper__label}>{label}</label>}
      <div className={s.wrapper__input}>
        <input type="text" disabled={disabled} className={classes()} onChange={handleChange} value={value} />
      </div>
    </div>
  )
}

export default Input
