import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect, useDispatch } from 'react-redux'
import { polyfill } from 'seamless-scroll-polyfill'

import CarOrderForm from '../carOrderForm/main/main'
import { FORM_SOURCE } from '../constants'
import CreditCalcStandalone from '../CreditCalcStandalone'
import SimpleFormApp from '../SimpleFormApp'
import { hideCarOrderPopup, setCustomSource, showCarOrderPopup } from '../store/actions'
import TradeInStandaloneApp from '../TradeInStandaloneApp'
import s from './modal.module.scss'

polyfill()

function Modal({ config, carOrderPopupVisibility }) {
  const dispatch = useDispatch()

  const [localState, setLocaSstate] = useState({ initialized: false })

  useEffect(() => {
    if (config.instantOpen && !localState.initialized) {
      dispatch(showCarOrderPopup())
      setLocaSstate({ initialized: true })
    }
  })

  const closeModal = event => {
    if (event.target.classList.contains(s.modal)) {
      dispatch(hideCarOrderPopup())
    }
  }

  if (config.customSource) {
    setCustomSource(config.customSource)
  }

  const modalContent = () => {
    switch (config.show) {
      case 'TradeIn':
        return <TradeInStandaloneApp config={{ ...config, showFailedLoadingComponent: true }} />

      case 'Credit':
        return <CreditCalcStandalone config={{ ...config, type: 'credit', showFailedLoadingComponent: true }} />

      case 'Leasing':
        return <CreditCalcStandalone config={{ ...config, type: 'leasing', showFailedLoadingComponent: true }} />

      case 'Form':
        return <SimpleFormApp config={{ ...config, showFailedLoadingComponent: true }} />

      default:
        return <CarOrderForm config={{ ...config, source: FORM_SOURCE.CAR_ORDER }} />
    }
  }

  return (
    <div>
      {carOrderPopupVisibility && (
        <div className={s.modal} data-show={carOrderPopupVisibility} onClick={e => closeModal(e)}>
          <PerfectScrollbar
            className={s.modal__container}
            options={{
              maxScrollbarLength: 200,
            }}
          >
            <div className={s.modal__close}>
              <button className={s.modalClose} onClick={() => dispatch(hideCarOrderPopup())}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
                    fill="#222222"
                  />
                </svg>
              </button>
            </div>

            <div className={s.modal__content}>{modalContent()}</div>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ screen: { carOrderPopupVisibility } }) {
  return {
    carOrderPopupVisibility,
  }
}

export default connect(mapStateToProps)(Modal)
