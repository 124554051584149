import './_theme_generic_v2.scss'

import { createRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { getProjectName } from '../../../helpers/app/detectors/project_name'
import { clearSelectedAccessories, disableAccessory, enableAccessory, goToIndex } from '../../../store/actions'
import TopIcon from '../../../svgComponents/top'
import Accessory from '../../Parts/Accessories/Accessory'
import AccessoryCategory from '../../Parts/Accessories/Category'
import {
  accessoriesScreenButtonText,
  formattedWelcomeListMessage,
  isListStickyButtonDisabled,
} from '../../Parts/Accessories/helpers'
import Button from '../../Ui/button'
import s from './accessories.module.scss'

function Accessories({ accessories, selected, carName, vehicleType, currency }) {
  const [isToTopButtonVisible, setIsToTopButtonVisible] = useState(false)
  const [hasOnlySelectedBtnBeenClicked, setHasOnlySelectedBtnBeenClicked] = useState(false)
  const refContent = createRef()
  const refStickyButtons = createRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  /**
   * Текст на кнопке
   * @type {string}
   */
  const buttonText = accessoriesScreenButtonText(selected)

  /**
   * Информирующий текст
   * @type {string}
   */
  const formattedWelcomeMessage = formattedWelcomeListMessage({ selected, carName, vehicleType })

  /**
   * Заблокированы ли прилипающие кнопки
   * @type {boolean}
   */
  const isStickyButtonDisabled = isListStickyButtonDisabled({ hasOnlySelectedBtnBeenClicked, selected })

  /**
   * Подскролл наверх
   */
  const scrollToTop = () => {
    if (refContent?.current) {
      refContent.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }
  }
  /**
   * Отображать ли кнопку подскролла наверх
   * @type {(...args: any[]) => any}
   */
  const contentScrollHandler = useCallback(() => {
    if (refContent?.current) {
      const _currentScrollPosition = refContent.current.scrollTop

      setIsToTopButtonVisible(Boolean(_currentScrollPosition > 100))
    }
  }, [refContent])

  /**
   * Отображать ли кнопку подскролла наверх
   */
  useEffect(() => {
    if (refContent?.current) {
      refContent.current.addEventListener('scroll', contentScrollHandler)
    }
  }, [contentScrollHandler, refContent])

  /**
   * Выбран ли аксессуар
   * @param item - аксессуар
   * @returns {boolean}
   */
  const isChecked = item => {
    for (const s of selected) {
      if (item.id === s.id && item.attributes.accessoryId === s.attributes.accessoryId) {
        return true
      }
    }

    return false
  }

  /**
   * Заблокирован ли выбор аксессуара
   * @param item - аксессуар
   * @returns {boolean}
   */
  const interferingAccessory = item => {
    if (!item.attributes.restrictedIds) {
      return false
    }

    if (!selected) {
      return false
    }

    for (const s of selected) {
      if (item.attributes.restrictedIds.includes(s.attributes.accessoryId)) {
        return t('accessories.whatDoYouNeedToRemove', { accessory: s.attributes.name })
      }
    }

    return false
  }

  /**
   * Переключение выбора аксессуара
   * @param accessory - аксессуар
   */
  const toggleAccessory = accessory => {
    let checked = isChecked(accessory)

    if (checked) {
      dispatch(disableAccessory(accessory))
    } else {
      dispatch(enableAccessory(accessory))
    }
  }

  /**
   * Категории
   * @type {function(): []}
   */
  const categories = useCallback(() => {
    const c = []

    accessories.forEach(accessory => {
      if (!c.includes(accessory.attributes.subGroup)) {
        if (hasOnlySelectedBtnBeenClicked) {
          if (checkedAccessories(accessory.attributes.subGroup)?.length) {
            c.push(accessory.attributes.subGroup)
          }
        } else {
          c.push(accessory.attributes.subGroup)
        }
      }
    })
    return c
  }, [accessories, hasOnlySelectedBtnBeenClicked])

  /**
   * Сгруппированные аксессуары
   * @type {(...args: any[]) => any}
   */
  const groupedAccessories = useCallback(
    category => {
      if (hasOnlySelectedBtnBeenClicked) {
        return checkedAccessories(category)
      } else {
        return accessories.filter(accessory => accessory.attributes.subGroup === category)
      }
    },
    [accessories, hasOnlySelectedBtnBeenClicked],
  )

  /**
   * Выбранные аксессуары в категории
   * @param category - категория аксессуаров
   * @returns {*}
   */
  const checkedAccessories = category => {
    return accessories.filter(accessory => isChecked(accessory) && accessory.attributes.subGroup === category)
  }

  /**
   * Подсколл к категории
   * @param category - категория аксессуаров
   */
  const scrollToCategory = category => {
    const id = 'kdx_category_' + category
    const el = document.querySelector(`#${id}`)

    if (refContent?.current) {
      const stickyButtonsHeight = refStickyButtons?.current?.scrollHeight || 50

      refContent.current.scrollTo({
        top: el.getBoundingClientRect().top - 48 - stickyButtonsHeight, // высота хэдэра и фильтров
        behavior: 'smooth',
      })
    }
  }

  /**
   * Показать только выбранные
   */
  const showOnlySelected = () => {
    setHasOnlySelectedBtnBeenClicked(!hasOnlySelectedBtnBeenClicked)
    scrollToTop()
  }

  /**
   * Очистить выбранные
   */
  const clearSelected = () => {
    if (hasOnlySelectedBtnBeenClicked) {
      setHasOnlySelectedBtnBeenClicked(false)
    }

    dispatch(clearSelectedAccessories())
    scrollToTop()
  }

  return (
    <div className={`${s.wrapper} accessories-all-screen`}>
      <div className={s.wrapper__content} ref={refContent}>
        <div className={s.content}>
          <div className={s.content__title}>{formattedWelcomeMessage}</div>

          {!hasOnlySelectedBtnBeenClicked && (
            <div className={s.content__categories}>
              <div className={s.categories}>
                {categories().map((category, i) => {
                  return (
                    <div key={i} className={s.categories__item} onClick={() => scrollToCategory(i)}>
                      {category}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <div className={s.content__stickyButtons} ref={refStickyButtons}>
            <div className={s.buttons}>
              <button className={s.buttons__item} disabled={isStickyButtonDisabled} onClick={() => showOnlySelected()}>
                {hasOnlySelectedBtnBeenClicked
                  ? t('accessories.showAllOnScreen')
                  : t('accessories.showSelectedOnScreen')}
              </button>
              <button className={s.buttons__item} disabled={isStickyButtonDisabled} onClick={() => clearSelected()}>
                {t('accessories.removeAllOnScreen')}
              </button>
            </div>
          </div>

          <div className={s.content__accessories}>
            <div className={s.accessories}>
              {categories().map((category, i) => {
                return (
                  <div key={i} className={s.accessories__category} id={'kdx_category_' + i}>
                    <div className={s.category}>
                      <AccessoryCategory title={category}>
                        {groupedAccessories(category).map(accessory => {
                          return (
                            <div key={accessory.id} className={s.category__accessory}>
                              <Accessory
                                onToggleAccessory={() => toggleAccessory(accessory)}
                                props={{
                                  ...accessory,
                                  currency,
                                  hasOnlySelectedBtnBeenClicked: hasOnlySelectedBtnBeenClicked,
                                  isChecked: isChecked(accessory),
                                  interferingAccessory: interferingAccessory(accessory),
                                }}
                              />
                            </div>
                          )
                        })}
                      </AccessoryCategory>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={s.wrapper__footer}>
        <div className={s.footer}>
          <div className={s.footer__action}>
            <Button text={buttonText} isDisabled={!selected.length} handleClick={() => dispatch(goToIndex())} />
          </div>
          <div className={[s.footer__top, isToTopButtonVisible ? s.isVisible : ''].join(' ')}>
            <div className={s.toTop} onClick={() => scrollToTop()}>
              <TopIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  settings: { vehicleType, currency },
  accessories: { accessories, selected },
  car: { name: carName },
}) {
  return {
    accessories,
    selected,
    carName,
    vehicleType,
    currency,
  }
}
export default connect(mapStateToProps)(Accessories)
