import _cloneDeep from 'lodash/cloneDeep'

const INIT_STATE = {
  accessories: [],
  selected: [],
}

const accessories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'DISABLE_ACCESSORY':
      return {
        ...state,
        selected: state.selected.filter(accessory => {
          return (
            accessory.id !== action.accessory.id ||
            accessory.attributes.identity !== action.accessory.attributes.identity
          )
        }),
      }

    case 'ENABLE_ACCESSORY': {
      const selected = _cloneDeep(state.selected)
      selected.push(action.accessory)

      return {
        ...state,
        selected,
      }
    }

    case 'SET_ACCESSORIES':
      return {
        ...state,
        accessories: action.accessories,
      }

    case 'CLEAR_SELECTED_ACCESSORIES':
      return {
        ...state,
        selected: [],
      }

    default:
      return state
  }
}

export default accessories
