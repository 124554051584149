import { createSelector } from 'reselect'

import { leasingAmount, parseNumber } from '../../helpers'

const carPriceSelector = state => state.car.price
const selectedBenefitsSelector = state => state.benefits.selected
const selectedAccessoriesSelector = state => state.accessories.selected
const leasingParamsSelector = state => state.leasing.parameters
const leasingBenefitSelector = state => state.leasing.benefitValue
const carModelDataSelector = state => state.car.modelData
const carProductTypeSelector = state => state.car.productType

export const createLeasingCalculateProgramsPayload = createSelector(
  [
    carPriceSelector,
    selectedBenefitsSelector,
    selectedAccessoriesSelector,
    leasingParamsSelector,
    leasingBenefitSelector,
    carModelDataSelector,
    carProductTypeSelector,
  ],
  (carPrice, selectedBenefits, selectedAccessories, leasingParams, leasingBenefit, carModelData, carProductType) => {
    const amount = leasingAmount(carPrice, selectedBenefits, selectedAccessories, leasingBenefit)
    const { buybackPayment, buybackPaymentPercentage } = leasingParams
    return {
      price: amount,
      initialFeeAmount: parseNumber(leasingParams.initialFee),
      initialFeePercentage: parseInt(leasingParams.initialFeePercentage) || 0,
      buybackPayment: parseInt(buybackPayment),
      buybackPaymentPercentage: parseInt(buybackPaymentPercentage),
      period: parseInt(leasingParams.period) || 0,
      modelData: carModelData,
      productType: carProductType,
    }
  },
)
