export const BULLET_TYPE = {
  CARS: 'cars',
  TRADE_IN: 'trade_in',
  CREDIT: 'credit',
}

export const BULLET_FILLED_TYPE = {
  GREY: 'grey',
  BORDERED: 'bordered',
}

export const BULLET_SIZE = {
  BIG: 'big',
  SMALL: 'small',
}

export const SCREEN_TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

export const SCREEN_TYPE = w => {
  if (w > 1264) {
    return SCREEN_TYPES.DESKTOP
  } else if (w > 600) {
    return SCREEN_TYPES.TABLET
  } else {
    return SCREEN_TYPES.MOBILE
  }
}
