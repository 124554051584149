import i18n from 'i18next'
import { load } from 'redux-localstorage-simple'

import {
  ACTION_TYPES,
  CREDIT_SETTINGS_BEHAVIOR,
  CREDIT_STATUS_FAILED,
  CREDIT_STATUS_NO_DATA,
  CREDIT_STATUS_NONE,
  CREDIT_STATUS_RECEIVED,
  FINANCE_USER_BEHAVIOUR,
  FORM_TYPES,
  SCREEN_CREDIT,
  SCREEN_INDEX,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../constants'
import {
  carPriceFromTI,
  checkForAuth,
  creditAmount,
  formType,
  getDefaultCreditSettings,
  loadCreditPrograms,
  makeNumber,
} from '../../helpers'
import { CREDIT_TYPES } from '../types'
import {
  creditOpened,
  dataLayer,
  goToAuthScreen,
  hideWidget,
  setOpenedFromApi,
} from './index'

export const setCreditDefaultSettingsAvailable = payload => ({
  type: CREDIT_TYPES.SET_CREDIT_DEFAULT_SETTINGS_AVAILABLE,
  payload,
})

export const setInitfeePercent = payload => ({
  type: 'TEMP_INITFEE_PERCENT',
  payload,
})

export const setBuybackPercent = payload => ({
  type: 'TEMP_BUYBACK_PERCENT',
  payload,
})

export const setCreditParams = params => ({
  type: CREDIT_TYPES.SET_CREDIT_PARAMS,
  ...params,
})

export const setCreditBenefitValue = payload => ({
  type: CREDIT_TYPES.SET_CREDIT_BENEFIT_VALUE,
  payload,
})

export const setCreditDisableBuybackPayment = payload => ({
  type: CREDIT_TYPES.SET_CREDIT_DISABLE_BUYBACK_PAYMENT,
  payload,
})

export const goToCredit = (dropCreditState = true) => dispatch => {
  dispatch(creditOpened())

  if (dropCreditState) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS))
  }

  dispatch({
    type: 'GO_TO',
    screen: SCREEN_CREDIT,
  })

  dispatch(dataLayer({ event: 'credit', status: 'open' }))
}

export const setCreditPeriods = payload => ({
  type: CREDIT_TYPES.SET_CREDIT_PERIODS,
  payload,
})

export const setCreditPeriod = period => ({
  type: CREDIT_TYPES.SET_CREDIT_PERIOD,
  period,
})

export const setCreditInitialFee = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue) / 100

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    tradeIn: {
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    },
    credit: {
      parameters: { buybackPayment },
      benefitValue: creditBenefitAmount,
    },
  } = getState()

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
    initialFee: value,
    initialFeePercentage: Math.round((value * 100) / amount),
  })

  if (value + buybackPayment > Math.round(amount * 0.95)) {
    let buybackPayment = Math.round(amount * 0.95) - value

    if (buybackPayment < 0) {
      buybackPayment = 0
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
      buybackPayment,
      buybackPaymentPercentage: Math.round((buybackPayment * 100) / amount),
    })
  }
}

export const adjustInitialFee = (rawValue, fromTradeIn = false) => (dispatch, getState) => {
  let value = makeNumber(rawValue) / 100

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    tradeIn: {
      ownerCarPrice,
      ownerCarPriceEnabled,
      previousTradeInValue,
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
      rate: {
        result: { possibleCarPrice },
      },
    },
    credit: {
      parameters: { buybackPayment, initialFee },
      benefitValue: creditBenefitAmount,
    },
  } = getState()

  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  const realTICarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  if (tradeInEnabled && realTICarPrice > 0) {
    if (fromTradeIn && previousTradeInValue > 0 && previousTradeInValue !== value) {
      // Если изменение пришло из Trade In'a, то мы пытаемся хитро посчитать
      // Если был ранее TradeIn установлен - то вычисляем разницу ( previous - value )
      // И разницу вычитаем из старого первоначального взноса
      // В таком случае у нас будут ситуации
      // ---
      // Первоначальный взнос: 50 000; Старое значение Trade In: 40 000; Новое значение Trade In: 50 000; Разница: 40 000 - 50 000 = - 10 000
      // Итого новый первоначальный взнос будет 50 000 - ( - 10 000 ) = 60 000
      // ---
      // Первоначальный взнос: 50 000; Старое значение Trade In: 50 000; Новое значение Trade In: 40 000; Разница: 50 000 - 40 000 = 10 000
      // Итого новый первоначальный взнос будет: 50 000 - ( 10 000 ) = 40 000
      value = initialFee - (previousTradeInValue - value)
    }

    if (value < realTICarPrice) {
      value = realTICarPrice
    }
  }

  if (value > Math.round(amount * 0.95)) {
    value = Math.round(amount * 0.95)
  }

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
    initialFee: value,
    initialFeePercentage: Math.round((value * 100) / amount),
  })

  if (value + buybackPayment > Math.round(amount * 0.95)) {
    let buybackPayment = Math.round(amount * 0.95) - value
    if (buybackPayment < 0) {
      buybackPayment = 0
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
      buybackPayment,
      buybackPaymentPercentage: Math.round((buybackPayment * 100) / amount),
    })
  }
}

export const setCreditInitialFeePercentage = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    tradeIn: {
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
      ownerCarPrice,
      ownerCarPriceEnabled,
      rate: {
        result: { possibleCarPrice },
      },
    },
    credit: {
      parameters: { buybackPayment },
      benefitValue: creditBenefitAmount,
    },
  } = getState()

  const realTICarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  // если включен ТИ, то минимальное значение процента ПВ
  if (tradeInEnabled) {
    const minInitialFeePercentage = Math.round((realTICarPrice * 100) / amount)

    if (value < minInitialFeePercentage) {
      value = minInitialFeePercentage
    }
  }

  let initialFee = (amount * value) / 100

  // если ТИ включен и определена стоимость машины и ПВ меньше стоимости машины в ТИ
  if (tradeInEnabled && realTICarPrice > 0 && initialFee < realTICarPrice) {
    initialFee = realTICarPrice
  }

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
    initialFee,
    initialFeePercentage: value,
  })

  // если сумма ПВ + ОП больше суммы кредита, то пересчитываем ОП
  if (initialFee + buybackPayment > Math.floor(amount * 0.95)) {
    let buybackPayment = Math.floor(amount * 0.95) - initialFee
    if (buybackPayment < 0) {
      buybackPayment = 0
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
      buybackPayment,
      buybackPaymentPercentage: Math.round((buybackPayment * 100) / amount),
    })
  }
}

export const adjustCreditBuybackPayment = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue) / 100

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    tradeIn: {
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
      ownerCarPrice,
      ownerCarPriceEnabled,
      rate: {
        result: { possibleCarPrice },
      },
    },
    credit: {
      parameters: { initialFee },
      benefitValue: creditBenefitAmount,
    },
  } = getState()

  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  const realTICarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  if (tradeInEnabled) {
    const maxBuybackPayment = Math.floor(amount * 0.95) - realTICarPrice

    if (value > maxBuybackPayment) {
      value = maxBuybackPayment
    }
  }

  if (value > Math.floor(amount * 0.95)) {
    value = Math.floor(amount * 0.95)
  }

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
    buybackPayment: value,
    buybackPaymentPercentage: Math.round((value * 100) / amount),
  })

  if (value + initialFee > Math.floor(amount * 0.95)) {
    let initialFee = amount * 0.95 - value
    if (initialFee < 0) {
      initialFee = 0
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const setCreditBuybackPayment = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue) / 100

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    credit: {
      parameters: { initialFee },
      benefitValue: creditBenefitAmount,
    },
    tradeIn: {
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    },
  } = getState()

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
    buybackPayment: value,
    buybackPaymentPercentage: Math.round((value * 100) / amount),
  })

  if (value + initialFee > Math.floor(amount * 0.95)) {
    let initialFee = amount * 0.95 - value

    if (initialFee < 0) {
      initialFee = 0
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const setCreditBuybackPaymentPercentage = rawValue => (dispatch, getState) => {
  let value = makeNumber(rawValue)

  const {
    car: { price },
    benefits: { selected },
    accessories: { selected: selectedAccessories },
    tradeIn: {
      behaviourByUser: tradeInBehaviourByUser,
      benefitValue: tradeInBenefitAmount,
      benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
      ownerCarPrice,
      ownerCarPriceEnabled,
      rate: {
        result: { possibleCarPrice },
      },
    },
    credit: {
      parameters: { initialFee },
      benefitValue: creditBenefitAmount,
    },
  } = getState()

  const tradeInEnabled = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  const amount = creditAmount({
    price,
    selectedBenefits: selected,
    selectedAccessories,
    tradeInUserBehaviour: tradeInBehaviourByUser,
    tradeInBenefitAmount,
    tradeInBenefitAmountWithoutTradeIn,
    creditBenefitAmount,
  })

  const realTICarPrice = carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled)

  if (tradeInEnabled) {
    const maxBuybackPaymentPercentage = Math.round(((amount - realTICarPrice) * 100) / amount)

    if (value > maxBuybackPaymentPercentage) {
      value = maxBuybackPaymentPercentage
    }
  }

  const buybackPayment = (amount * value) / 100

  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_BUYBACK_PAYMENT,
    buybackPayment,
    buybackPaymentPercentage: value,
  })

  if (buybackPayment + initialFee > Math.round(amount * 0.95)) {
    let initialFee = amount * 0.95 - buybackPayment

    if (initialFee < 0) {
      initialFee = 0
    }

    if (initialFee < realTICarPrice) {
      initialFee = realTICarPrice
    }

    dispatch({
      type: CREDIT_TYPES.SET_CREDIT_INITIAL_FEE,
      initialFee,
      initialFeePercentage: Math.round((initialFee * 100) / amount),
    })
  }
}

export const setAdditionalParameter = (name, value) => (dispatch, getState) => {
  switch (name) {
    case 'life_insurance':
      dispatch({
        type: CREDIT_TYPES.SET_LIFE_INSURANCE,
        value,
      })
      break

    case 'kasko_insurance':
      dispatch({
        type: CREDIT_TYPES.SET_KASKO_INSURANCE,
        value,
      })
      break

    default:
      return
  }
}

export const setPrograms = programs => (dispatch, getState) => {
  const uniqueBanks = []
  const combinedPrograms = []

  if (programs) {
    programs.forEach(program => {
      if (!uniqueBanks.includes(program.bank.id)) {
        uniqueBanks.push(program.bank.id)
        combinedPrograms.push(program)
      }
    })
  }

  // если есть промо программа, то надо ее допушить в combinedPrograms (если ее там нет)
  const promoProgram = getState().credit.promoProgram
  if (promoProgram && promoProgram.enabled && promoProgram.identity) {
    const promoProgramInCombinedPrograms = combinedPrograms.find(cp => {
      return cp.id.toString() === promoProgram.identity.toString()
    })

    const promoProgramInAllPrograms = programs.find(p => {
      return p.id.toString() === promoProgram.identity.toString()
    })

    // если ее нет, то допушим
    if (!promoProgramInCombinedPrograms && promoProgramInAllPrograms?.id) {
      combinedPrograms.push(promoProgramInAllPrograms)
    }
  }

  // если в localStorage есть активная кредитная программа, надо проверить, чтоб она была не схлопнута
  const dataFromLocalStorage = load({
    states: ['credit'],
    namespace: '@kodix/smartContract',
    namespaceSeparator: '/',
  })

  const activeProgramIdFromLocalStorage =
    dataFromLocalStorage &&
    dataFromLocalStorage.credit &&
    dataFromLocalStorage.credit.active_program &&
    dataFromLocalStorage.credit.active_program.id
      ? dataFromLocalStorage.credit.active_program.id
      : ''

  if (activeProgramIdFromLocalStorage) {
    const activeProgramInCombinedPrograms = combinedPrograms.find(cp => {
      return cp.id === activeProgramIdFromLocalStorage
    })

    // если ее нет, то допушим
    if (!activeProgramInCombinedPrograms) {
      combinedPrograms.push(dataFromLocalStorage.credit.active_program)
    }
  }

  const activeProgram = getState().credit.active_program

  if (activeProgram.id) {
    const activeProgramsWithNewParams = programs.find(program => {
      return program.id === activeProgram.id
    })

    // если есть выбранная программа, то находим ее и снова выбираем
    // иначе если нет программы то удаляем данные кредитной программы
    if (activeProgramsWithNewParams) {
      dispatch(setActiveProgram(activeProgramsWithNewParams))
    } else {
      dispatch(clearActiveProgram)
    }
  }

  dispatch(setCreditPlaceholder(uniqueBanks.length > 1 ? combinedPrograms[0]?.payment : programs[0]?.payment || null))

  let res = uniqueBanks.length === 1 || programs.length < 10 ? programs : combinedPrograms

  // если есть промо программа, то она должна быть первой
  if (promoProgram && promoProgram.enabled && promoProgram.identity) {
    res.sort(function (a, b) {
      return a.id.toString() === promoProgram.identity.toString() ? -1 : 1
    })
  }

  dispatch({
    type: CREDIT_TYPES.SET_PROGRAMS,
    programs: res,
  })
}

export const clearActiveProgram = (dispatch, getState) => {
  const active_program = {
    id: '',
  }
  dispatch({
    type: CREDIT_TYPES.SET_ACTIVE_PROGRAM,
    active_program,
  })

  dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS))
}

export const setActiveProgram = (active_program, checkForAuthRequired = false) => (dispatch, getState) => {
  const {
    settings: {
      visual: { fields },
    },
  } = getState()

  if (checkForAuthRequired && formType() === FORM_TYPES.NEW_TYPE) {
    const personalDataErrors = checkForAuth(fields)

    if (personalDataErrors.length) {
      dispatch(
        goToAuthScreen({
          action: ACTION_TYPES.SET_CREDIT_PROGRAM,
          screen: SCREEN_CREDIT,
          additionalData: active_program,
        }),
      )

      dispatch(dataLayer({ event: 'credit', status: 'open' }))
    }
  }

  dispatch({
    type: CREDIT_TYPES.SET_ACTIVE_PROGRAM,
    active_program,
  })
}

export const setCreditStatus = status => (dispatch, getState) => {
  dispatch({
    type: CREDIT_TYPES.SET_CREDIT_STATUS,
    status,
  })
}

export const enableCredit = (redirect = false, openedFromApi = false, openedFromMainScreen = true) => (
  dispatch,
  getState,
) => {
  const state = getState()

  if (state.credit.behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED))
  }

  if (state.credit.behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS) {
    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_IN_PROGRESS))
  }

  if (openedFromApi) {
    dispatch(setOpenedFromApi(false))
    dispatch(hideWidget())
    dispatch({ type: 'GO_TO', screen: SCREEN_INDEX })
  }

  if (redirect) {
    dispatch({
      type: 'GO_TO',
      screen: SCREEN_INDEX,
    })
  }

  // если включили кредит нажанием на "выбрать" внутри экрана кредита, то apply, иначе on при включении с главного экрана
  if (!openedFromMainScreen || openedFromApi) {
    dispatch(dataLayer({ event: 'credit', status: 'apply', buttonName: i18n.t('credit.choose') }))
  } else {
    dispatch(dataLayer({ event: 'credit', status: 'on' }))

    if (state.credit.behaviourByUser === FINANCE_USER_BEHAVIOUR.LEASING_APPLIED) {
      dispatch(dataLayer({ event: 'leasing', status: 'off' }))
    }
  }
}

export const setFinanceBehaviourByUser = payload => ({
  type: CREDIT_TYPES.SET_CREDIT_BEHAVIOUR_BY_USER,
  payload,
})

export const disableCredit = () => dispatch => {
  dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CASH))
  dispatch(dataLayer({ event: 'credit', status: 'off' }))
}

export const switchCredit = value => dispatch => {
  if (value) {
    dispatch(enableCredit(false))
  } else {
    dispatch(disableCredit())
  }
}

export const bootCreditParameters = ({ initialFeePercentage, buybackPaymentPercentage, period }, price) => dispatch => {
  dispatch({
    type: CREDIT_TYPES.BOOT_CREDIT_PARAMETERS,
    initialFee: (initialFeePercentage / 100) * price,
    initialFeePercentage,
    buybackPayment: (buybackPaymentPercentage / 100) * price,
    buybackPaymentPercentage,
    period,
  })
}

export const getDefaultCreditConditions = () => (dispatch, getState) => {
  const state = getState()

  if (!state.credit.defaultSettingsAvailable) {
    return
  }

  const { modelData, price, productType, identity } = state.car

  const getDefaultSettingsPayload = {
    data: {
      modelData,
      price,
      productType,
      identity,
    },
  }

  getDefaultCreditSettings(state.settings.widget_id, getDefaultSettingsPayload)
    .then(response => {
      if (response?.data) {
        dispatch({ type: CREDIT_TYPES.SET_DEFAULT_CONDITIONS, payload: response.data })
      }
    })
    .catch(e => console.error(e))
}

export const calculateCreditPrograms = (creditData, applyProgram = false) => (dispatch, getState) => {
  const state = getState()

  if (state.credit.behaviour !== CREDIT_SETTINGS_BEHAVIOR.ENABLED) {
    return false
  }

  loadCreditPrograms(state.settings.widget_id, JSON.stringify({ data: creditData }))
    .then(({ data }) => {
      if (data && data.length > 0) {
        dispatch(setCreditStatus(CREDIT_STATUS_RECEIVED))
        dispatch(setPrograms(data))

        // если есть данные из localStorage по активной кредитной программе
        const dataFromLocalStorage = load({
          states: ['credit'],
          namespace: '@kodix/smartContract',
          namespaceSeparator: '/',
        })

        const activeProgramIdFromLocalStorage =
          dataFromLocalStorage &&
          dataFromLocalStorage.credit &&
          dataFromLocalStorage.credit.active_program &&
          dataFromLocalStorage.credit.active_program.id
            ? dataFromLocalStorage.credit.active_program.id
            : ''

        if (activeProgramIdFromLocalStorage) {
          const activeProgram = data.find(d => {
            return d.id === activeProgramIdFromLocalStorage
          })

          // если такой программы нет, то очистим активную кредитную программу и сбрасываем статусы
          if (!activeProgram) {
            dispatch(clearActiveProgram)
            dispatch(setCreditStatus(CREDIT_STATUS_NONE))
            // иначе - переустановим
          } else {
            dispatch(setActiveProgram(activeProgram))

            // сразу применяем программу, при первоначальной загрузке виджета
            if (applyProgram) {
              dispatch(enableCredit(false, false))
            }
          }
        }
      } else {
        dispatch(setCreditStatus(CREDIT_STATUS_NO_DATA))
        dispatch(setPrograms([]))
      }
    })
    .catch(e => {
      dispatch(clearActiveProgram)
      dispatch(setPrograms([]))
      dispatch(setCreditStatus(CREDIT_STATUS_FAILED))
    })
}

export const setCreditCondition = payload => ({
  type: CREDIT_TYPES.SET_CONDITION,
  payload,
})

export const setCreditOnlineApproval = payload => ({
  type: CREDIT_TYPES.SET_ONLINE_APPROVE,
  payload,
})

export const setCreditPlaceholder = payload => ({ type: CREDIT_TYPES.SET_CREDIT_PLACEHOLDER, payload })

export const setCreditPromoProgram = payload => ({
  type: CREDIT_TYPES.SET_PROMO_PROGRAM,
  payload,
})
