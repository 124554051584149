import i18n from '../../../i18n'

export const languageSetter = locale => {
  if (locale === 'Ru') {
    i18n.changeLanguage('ru-RU')
  }

  if (locale === 'En') {
    i18n.changeLanguage('en-US')
  }

  if (locale === 'De') {
    i18n.changeLanguage('de-DE')
  }
}
