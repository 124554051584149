import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TRADE_IN_ESTIMATION_TYPE, TRADE_IN_STEP_WEIGHTS, TRADE_IN_STEP_WEIGHTS_BY_IDENTITY } from '../../../constants'
import { toggleTradeInStep } from '../../../store/actions'
import s from './styles/list.module.scss'

function TradeInStandaloneListOfSteps({ step, weight, partner, estimationType }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const setActiveTradeInStep = (key, isDisabled, isActive) => {
    if (isDisabled) {
      return
    }
    if (isActive) {
      return
    }
    Promise.resolve(dispatch(toggleTradeInStep(key))).then(() => {
      const evt = new CustomEvent('tradeInStepWasSet')
      window.dispatchEvent(evt)
    })
  }

  const AutoRuByIdentitySteps = ['identity', 'city', 'mileage']
  const AutoRuSteps = [
    'brand',
    'model',
    'generation',
    'body',
    'modification',
    'year',
    'color',
    'owner',
    'city',
    'mileage',
  ]
  const MobileDeSteps = [
    'brand',
    'model',
    'body',
    'year',
    'color',
    'owner',
    'engine',
    'gearbox',
    'interiorColor',
    'interiorType',
    'mileage',
  ]
  const activeSteps = estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ ? AutoRuByIdentitySteps : AutoRuSteps
  const activeStepsWeights =
    estimationType === TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ
      ? TRADE_IN_STEP_WEIGHTS_BY_IDENTITY
      : TRADE_IN_STEP_WEIGHTS

  const steps = Object.keys(activeStepsWeights).map(key => {
    if (activeSteps.includes(key)) {
      const isDisabled = weight < activeStepsWeights[key]
      const isActive = step === key

      let classes = () => {
        let c = [s.list__item]

        if (isActive) {
          c.push(s.list__item_active)
        }

        if (isDisabled) {
          c.push(s.list__item_disabled)
        }

        return c.join(' ')
      }

      return (
        <div onClick={() => setActiveTradeInStep(key, isDisabled, isActive)} className={classes()} key={key}>
          {t(`tradeIn.${key}.title`)}
        </div>
      )
    }
  })

  return <div className={s.list}>{steps}</div>
}

function mapStateToProps({ tradeIn: { step, weight, partner } }) {
  return {
    step,
    weight,
    partner,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneListOfSteps)
