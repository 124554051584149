// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_button_wrapper__3_VNv{background:transparent;bottom:20px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;left:auto;max-width:190px;position:fixed;right:20px;top:auto;z-index:2}.main_button__3NGOU{background:black;color:white;font-family:inherit;font-size:16px;word-break:break-word;text-align:center;padding:20px;border-radius:1px;transition:all ease-in-out 0.5s;box-shadow:none}.main_button__3NGOU:hover{cursor:pointer;transition:all ease-in-out 0.5s;transform:scale(1.1);box-shadow:rgba(0,0,57,0.5) 0 8px 24px,rgba(17,17,26,0.4) 0 16px 56px}\n", "",{"version":3,"sources":["webpack://src/ButtonForPopup/main/main.module.scss"],"names":[],"mappings":"AAAA,4BACE,sBAAuB,CACvB,WAAY,CACZ,0BAAmB,CAAnB,uBAAmB,CAAnB,kBAAmB,CACnB,SAAU,CACV,eAAgB,CAChB,cAAe,CACf,UAAW,CACX,QAAS,CACT,SAAU,CACX,oBAGC,gBAAiB,CACjB,WAAY,CACZ,mBAAoB,CACpB,cAAe,CACf,qBAAsB,CACtB,iBAAkB,CAClB,YAAa,CACb,iBAAkB,CAClB,+BAAgC,CAChC,eAAgB,CAVlB,0BAaI,cAAe,CACf,+BAAgC,CAChC,oBAAqB,CACrB,qEAA6E","sourcesContent":[".button_wrapper {\n  background: transparent;\n  bottom: 20px;\n  height: fit-content;\n  left: auto;\n  max-width: 190px;\n  position: fixed;\n  right: 20px;\n  top: auto;\n  z-index: 2;\n}\n\n.button{\n  background: black;\n  color: white;\n  font-family: inherit;\n  font-size: 16px;\n  word-break: break-word;\n  text-align: center;\n  padding: 20px;\n  border-radius: 1px;\n  transition: all ease-in-out 0.5s;\n  box-shadow: none;\n\n  &:hover{\n    cursor: pointer;\n    transition: all ease-in-out 0.5s;\n    transform: scale(1.1);\n    box-shadow: rgba(0, 0, 57, 0.5) 0 8px 24px, rgba(17, 17, 26, 0.4) 0 16px 56px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_wrapper": "main_button_wrapper__3_VNv",
	"button": "main_button__3NGOU"
};
export default ___CSS_LOADER_EXPORT___;
