import { forwardRef } from 'react'

import { getProjectName } from '../../../../helpers/app/detectors/project_name'
import s from './checkbox.module.scss'

const Checkbox = forwardRef((props, ref) => {
  return (
    <div
      className={[s.fieldBox, props.useWidgetStyles ? s.fieldBoxInWidget : ''].join(' ')}
      data-project-name={getProjectName()}
    >
      <div className={s.checkboxBox}>
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          ref={ref}
          // className={[s.formCheckbox, props.errors ? s.formCheckboxWithErrors : ''].join(' ')}
          className={s.formCheckbox}
          checked={props.checked}
          onChange={e => props.handleChange(e.target.checked)}
        />

        <label htmlFor={props.id} className={s.formLabel}>
          <span className={s.formLabelText} dangerouslySetInnerHTML={{ __html: props.label }} />
        </label>
      </div>
      {<div className={s.errorsText}>{props.errorsText}</div>}
    </div>
  )
})

export default Checkbox
