import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { SCREEN_PROMO_PROGRAM } from '../../../constants'
import { goToPromoProgramScreen, goToScreen } from '../../../store/actions'
import s from './promoProgram.module.scss'

function PromoProgram({ promoProgram }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const goToPromoProgramScreenHandler = () => {
    dispatch(goToScreen(SCREEN_PROMO_PROGRAM))
    dispatch(goToPromoProgramScreen())
  }

  return (
    <div className={s.jaggedWrapper}>
      <div className={s.jaggedWrapper__inner} />
      <div className={s.content}>
        <div className={s.content__item}>
          {promoProgram?.screenSettings?.title?.length > 27
            ? `${promoProgram?.screenSettings?.title.substr(0, 25)}...`
            : promoProgram?.screenSettings?.title}
        </div>
        <div className={s.content__item} onClick={() => goToPromoProgramScreenHandler()}>
          {t(`credit.promoProgram.moreDetails`)}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ credit: { promoProgram } }) {
  return {
    promoProgram,
  }
}

export default connect(mapStateToProps)(PromoProgram)
