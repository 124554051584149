import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { currencyScale, formatCurrency } from '../../../helpers'
import { formID } from '../../Conditions/formID'
import { InputBox } from '../Common'
import Line from './Line'
import s    from './styles/range.module.scss'
import {getProjectName}                  from "../../../helpers/app/detectors/project_name";

const Range = ({
  carPrice,
  labelText,
  id,
  tip,
  onChange,
  value,
  onChangeRange,
  valueRange,
  control,
  register,
  setValue,
  currentRef,
  currency,
  isLeasing,
  isOnlyPercent,
  dividedNumber,
  disabled,
}) => {
  useEffect(() => setValue(id, value), [id, setValue, value])

  const carPriceRange = id === formID.carPrice

  const isAllowed = values => {
    if (isLeasing && carPriceRange) {
      const v = Number(values.value)
      return v >= 1000000 && v <= dividedNumber
    }

    if (isLeasing && !carPriceRange) {
      return Number(values.value) <= carPrice / 2
    }

    return Number(values.value) <= Number(carPrice)
  }

  return (
    <InputBox labelText={labelText} id={id} tip={tip} isLeasing={isLeasing} isOnlyPercent={isOnlyPercent}>
      <Controller
        data-project-name={getProjectName()}
        as={<NumberFormat getInputRef={el => (currentRef.current = el)} />}
        name={id}
        control={control}
        value={value}
        onValueChange={values => onChange(Number(values.value))}
        onFocus={() => currentRef.current.focus()}
        isAllowed={isAllowed}
        allowNegative={false}
        allowLeadingZeros={false}
        className={s.input}
        id={id}
        thousandSeparator={' '}
        decimalScale={currencyScale(currency)}
        placeholder={`0 ${formatCurrency(currency)}`}
        isNumericString={false}
        suffix={` ${formatCurrency(currency)}`}
        maxLength={12}
        defaultValue={''}
        autoComplete="off"
        disabled={disabled}
      />
      <Line
        id={id}
        carPrice={carPrice}
        dividedNumber={dividedNumber}
        onChange={onChangeRange}
        value={valueRange}
        register={register}
        currentRef={currentRef}
        isLeasing={isLeasing}
        disabled={disabled}
      />
    </InputBox>
  )
}

export default Range
