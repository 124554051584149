import i18n from 'i18next'

/**
 * Номер блока
 * @param isTradeInSection
 * @param isFinanceSection
 * @param isBenefitsSection
 * @returns {number}
 */
export const getBlockNumber = ({ isTradeInSection, isFinanceSection, isBenefitsSection }) => {
  if (isTradeInSection && isFinanceSection && isBenefitsSection) {
    return 4
  } else if (
    (isTradeInSection && isFinanceSection && !isBenefitsSection) ||
    (isTradeInSection && isBenefitsSection && !isFinanceSection) ||
    (isBenefitsSection && isFinanceSection && !isTradeInSection)
  ) {
    return 3
  } else if (
    (!isTradeInSection && !isFinanceSection && isBenefitsSection) ||
    (!isTradeInSection && !isBenefitsSection && isFinanceSection) ||
    (!isBenefitsSection && !isFinanceSection && isTradeInSection)
  ) {
    return 2
  } else {
    return 1
  }
}

/**
 * Список категорий для превью (неполный)
 * @returns {[]}
 */
export const categoriesForPreview = accessories => {
  const c = []

  accessories.forEach(accessory => {
    if (!c.includes(accessory.attributes.subGroup) && c.length < 3) {
      c.push(accessory.attributes.subGroup)
    }
  })

  c.push(i18n.t('accessories.showAll'))

  return c
}

/**
 * Поясняющий текст для screen Index -> state
 * @param selected - выбранные аксессуары
 * @param carName - название авто
 * @returns {string}
 */
export const formattedWelcomeMessage = ({ selected, carName, vehicleType }) => {
  if (selected?.length) {
    if (carName) {
      return i18n.t('accessories.selectedAccessoriesWithCarName', { carName })
    }
    return i18n.t(`accessories.selectedAccessoriesWithoutCarName.${vehicleType}`)
  }
  if (carName) {
    return i18n.t('accessories.selectAccessoriesWithCarName', { carName })
  }
  return i18n.t(`accessories.selectAccessoriesWithoutCarName.${vehicleType}`)
}

/**
 * Текст на кнопке на экране акс.
 * @param selected - выбранные аксессуары
 * @returns {string}
 */
export const accessoriesScreenButtonText = selected => {
  if (selected.length) {
    return `${i18n.t('accessories.addToPurchase')} (${selected.length})`
  }
  return i18n.t('accessories.addToPurchase')
}

/**
 * Поясняющий текст для списка аксуссуаров
 * @param hasOnlySelectedBtnBeenClicked - состояние списка = показать только выбранные
 * @param carName - название авто
 * @returns {string}
 */
export const formattedWelcomeListMessage = ({ hasOnlySelectedBtnBeenClicked, carName, vehicleType }) => {
  if (hasOnlySelectedBtnBeenClicked) {
    return i18n.t('accessories.selectedAccessories')
  }

  if (carName) {
    return i18n.t('accessories.selectAccessoriesWithCarName', { carName })
  }

  return i18n.t(`accessories.selectAccessoriesWithoutCarName.${vehicleType}`)
}

/**
 * Заблокирваны ли кнопки "показать выбранные" и "снять выбор" на списке
 * @param hasOnlySelectedBtnBeenClicked - состояние списка = показать только выбранные
 * @param selected - выбранные аксесусары
 * @returns {boolean}
 */
export const isListStickyButtonDisabled = ({ hasOnlySelectedBtnBeenClicked, selected }) => {
  if (hasOnlySelectedBtnBeenClicked) {
    return false
  }

  return !selected.length
}
