import './_theme_generic_v2.scss'

import { forwardRef, useEffect, useRef, useState } from 'react'
import MaskedInput from 'react-number-format'

import { COUNTRY_CODES } from '../../../../constants'
import { getProjectName } from '../../../../helpers/app/detectors/project_name'
import ClearIcon from '../../../../svgComponents/clear'
import DropdownArrow from '../../../../svgComponents/dropdownArrow'
import s from './phoneInput.module.scss'

const PhoneInput = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false)

  const inputBoxRef = useRef()

  const select = useRef(null)
  const dropdown = useRef(null)

  const handleSelectClick = () => setIsOpened(isOpen => !isOpen)

  // закрывать дропдаун на клики за его пределами
  const handleOutsideClick = event => {
    if (dropdown.current.contains(event.target)) {
      return
    }

    if (select.current.contains(event.target)) {
      return
    }

    setIsOpened(false)
  }

  // открыть или закрыть дропдаун
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      setIsOpened(isOpened => !isOpened)
      select.current.focus()
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setIsOpened(false)
      select.current.focus()
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      event.preventDefault()

      if (!isOpened) {
        setIsOpened(true)
      }

      const dropdownList = dropdown.current.firstElementChild
      const dropdownItem = dropdownList.firstElementChild

      if (!dropdownItem) {
        return
      }

      dropdownItem.focus()
    }
  }

  const handleOutsideKey = event => {
    if (event.key === 'Escape') {
      setIsOpened(false)
      select.current.focus()
    }
  }

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [isOpened])

  const handleDropdownClick = event => {
    props.setPhoneCountry(event.currentTarget.dataset.value)
    setIsOpened(false)
    select.current.focus()
  }

  // взаимодействие с элементами дропдауна
  const handleDropdownKey = event => {
    if (event.key === 'Enter') {
      props.setPhoneCountry(event.target.dataset.value)
      setIsOpened(false)
      select.current.focus()
    }

    // выделить следующий элемент
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight' || event.key === 'Tab') {
      event.preventDefault()

      const nextElement = event.target?.nextElementSibling

      if (!nextElement) {
        const firstElement = event.target?.parentElement.firstElementChild
        firstElement.focus()
        return
      }

      nextElement.focus()
    }

    // выделить предыдущий элемент
    if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      event.preventDefault()

      const previousElement = event.target?.previousElementSibling

      if (!previousElement) {
        const lastElement = event.target?.parentElement.lastElementChild
        lastElement.focus()
        return
      }

      previousElement.focus()
    }
  }

  useEffect(() => {
    if (isOpened) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isOpened])

  const CountryList = Object.entries(COUNTRY_CODES).map((item, ix) => {
    if (props?.possibleCountryCodes?.includes(item[0])) {
      return (
        <li
          className={s.country}
          tabIndex={0}
          key={item[1].id}
          data-value={item[0]}
          onClick={handleDropdownClick}
          onKeyDown={handleDropdownKey}
          data-project-name={getProjectName()}
        >
          <span className={s.country__img}>
            <img src={item[1].icon} alt="" />
          </span>
          <span className={s.country__name}>{item[1].name}</span>
          <span className={s.country__code}>{item[1].code}</span>
        </li>
      )
    }
  })

  return (
    <div
      className={[s.fieldBox, props.useWidgetStyles ? s.fieldBoxInWidget : ''].join(' ')}
      data-project-name={getProjectName()}
    >
      {props.country && (
        <div className={s.inputBox} ref={inputBoxRef}>
          <div
            className={s.selectedCountry}
            onClick={handleSelectClick}
            onKeyDown={handleKeyDown}
            ref={select}
            tabIndex={0}
          >
            <span className={s.selectedCountry__abbr}>{COUNTRY_CODES[props.country].abbr}</span>
            <span className={[s.selectedCountry__arrow, isOpened ? s.selectedCountry__arrow_opened : ''].join(' ')}>
              <DropdownArrow />
            </span>
          </div>
          <MaskedInput
            getInputRef={ref}
            className={[s.input, props.errors ? s.inputWithErrors : ''].join(' ')}
            id={props.id}
            name={props.name}
            type="tel"
            autoComplete="off"
            value={props.value || ''}
            allowEmptyFormatting={true}
            format={COUNTRY_CODES[props.country].mask}
            mask="_"
            onValueChange={values => {
              const { formattedValue, value } = values
              props.setPhoneValue({
                phone: formattedValue,
                phoneRawValue: value,
              })
            }}
          />
          <label htmlFor={props.id} className={s.label}>
            {props.label} {props.required && '*'}
          </label>
          {props.value && (
            <div
              className={s.clear}
              onClick={() => {
                props.setPhoneValue({
                  phone: '',
                  phoneRawValue: '',
                })
              }}
            >
              <ClearIcon />
            </div>
          )}
          <div
            className={[s.countriesListBox, isOpened ? s.countriesListBox_opened : s.countriesListBox_closed].join(' ')}
            ref={dropdown}
          >
            <ul className={s.countriesList}>{CountryList}</ul>
          </div>
        </div>
      )}
      {<div className={s.errorsText}>{props.errorsText}</div>}
    </div>
  )
})

export default PhoneInput
