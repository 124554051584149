import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { TRADE_IN_DATA_FILLED, TRADE_IN_ESTIMATION_TYPE, TRADE_IN_RATE_STATUS } from '../../../constants'
import { formatMileage, formatPrice, isTradeInByIdentityDataFilled } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import {
  applyTradeIn,
  firstOpenTradeIn,
  rateTradeInByIdentity,
  resetBrand,
  setRateStatusInitial,
  setStepWeight,
  setTradeInEstimationType,
} from '../../../store/actions'
import TradeInCarIdentity from '../../Parts/TradeIn/CarIdentity'
import TradeInCity from '../../Parts/TradeIn/City'
import TradeInMileage from '../../Parts/TradeIn/Mileage'
import Button from '../../Ui/button'
import s from '../tradeInList/TradeInList.module.scss'

function TradeInAutoRuCalculator({
  status,
  complete,
  openedFromApi,
  possibleCarPriceFormatted,
  possibleCarPriceFromFormatted,
  possibleCarPriceToFormatted,
  hidePartnerInTexts,
  title,
  subtitle,
  partner,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onRateTradeIn = () => {
    dispatch(rateTradeInByIdentity(false))
  }

  const handleApplyButton = () => {
    if (openedFromApi) {
      dispatch(
        applyTradeIn(false, {
          useCustomPrice: true,
          openedFromMainScreen: false,
          openedFromApi: openedFromApi,
        }),
      )
    } else {
      dispatch(
        applyTradeIn(true, {
          useCustomPrice: true,
          openedFromMainScreen: false,
        }),
      )
    }
  }

  const resultSubtitleText = () => {
    if (status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE) {
      return hidePartnerInTexts
        ? t('tradeIn.rateResultSuccessDescriptionWithoutRF')
        : t('tradeIn.rateResultSuccessDescription')
    } else if (status === TRADE_IN_RATE_STATUS.FAILED) {
      return t('tradeIn.rateResultFailed')
    } else if (status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE) {
      return t('tradeIn.rateResultZeroPrice')
    } else {
      return ''
    }
  }

  const goToTradeInList = () => {
    dispatch(setTradeInEstimationType(TRADE_IN_ESTIMATION_TYPE.LIST))
    dispatch(setRateStatusInitial())
    dispatch(firstOpenTradeIn())
    dispatch(resetBrand(partner))
    dispatch(setStepWeight('brand'))
  }

  return (
    <div className={s.wrapper} data-project-name={getProjectName()}>
      <div className={s.wrapper__top}>
        <div className="kdxsc-accordion-list" data-project-name={getProjectName()}>
          <div className="kdxsc-accordion-list__item no-separator">
            <TradeInCarIdentity forceOpened={true} />
          </div>
          <div className="kdxsc-accordion-list__item no-separator">
            <TradeInMileage forceOpened={true} />
          </div>
          <div className="kdxsc-accordion-list__item no-separator">
            <TradeInCity forceOpened={true} />
          </div>
        </div>
        <div className="kdxsc-tradein-estimation-type-block" data-project-name={getProjectName()}>
          <div onClick={goToTradeInList} className="kdxsc-tradein-estimation-type-switcher">
            {t('trade_in.estimations.goToTradeInList')}
          </div>
          <div className="kdxsc-tradein-estimation-type-hint">{t('trade_in.estimations.reset')}</div>
        </div>
      </div>
      <div className={s.wrapper__bottom}>
        {status === TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <Button
              handleClick={onRateTradeIn}
              isDisabled={!complete}
              text={complete ? t('tradeIn.rate') : t('tradeIn.fillAllFields')}
            />
          </div>
        )}

        {status !== TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <div className={s.result}>
              <div className={s.result__title}>{t('tradeIn.rateResult')}</div>
              {resultSubtitleText() && (
                <div className={s.result__subtitle} dangerouslySetInnerHTML={{ __html: resultSubtitleText() }} />
              )}

              {[TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE, TRADE_IN_RATE_STATUS.SUCCESS_WITH_ZERO_PRICE].includes(
                status,
              ) && (
                <div className={s.result__content}>
                  <div className={s.resultContent}>
                    <div className={s.resultContent__title}>{title}</div>
                    <div className={s.resultContent__info}>{subtitle}</div>
                    {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                      <div className={s.resultContent__price}>{possibleCarPriceFormatted}</div>
                    )}
                    {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                      <div className={s.resultContent__price_range}>
                        {t('tradeIn.rateResultPriceFromTo', {
                          from: possibleCarPriceFromFormatted,
                          to: possibleCarPriceToFormatted,
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {status === TRADE_IN_RATE_STATUS.SUCCESS_WITH_PRICE && (
                <div className={s.result__info}>
                  {hidePartnerInTexts
                    ? t('tradeIn.rateResultSuccessDescription2WithoutRF')
                    : t('tradeIn.rateResultSuccessDescription2')}
                </div>
              )}
            </div>
          </div>
        )}

        {status !== TRADE_IN_RATE_STATUS.INITIAL && (
          <div className={s.wrapper__block}>
            <Button handleClick={handleApplyButton} text={t('tradeIn.apply')} />
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  settings: { currency },
  tradeIn: {
    weight,
    values,
    hidePartnerInTexts,
    partner,
    rate: {
      status,
      result: {
        possibleCarPrice,
        tradeIn: { from, to },
      },
    },
  },
  api: { openedFromApi },
}) {
  return {
    weight,
    status,

    title: [values.brand.name, values.model.name].filter(v => v !== '').join(' '),
    subTitle: [values.year.value, values.owner.name, formatMileage(values.mileage.value)]
      .filter(v => v !== '')
      .join(', '),

    complete: isTradeInByIdentityDataFilled(values) === TRADE_IN_DATA_FILLED.ALL_VALUES_FILLED,

    possibleCarPriceFormatted: formatPrice(possibleCarPrice, currency),
    possibleCarPriceFromFormatted: formatPrice(from, currency),
    possibleCarPriceToFormatted: formatPrice(to, currency),
    currency,
    openedFromApi,
    hidePartnerInTexts,
    partner,
  }
}

export default connect(mapStateToProps)(TradeInAutoRuCalculator)
