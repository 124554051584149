import { connect } from 'react-redux'

function TradeInStandaloneYearInput({ dictionaryValue }) {
  return <div>{dictionaryValue}</div>
}

function mapStateToProps({
  tradeIn: {
    values: {
      year: { value: dictionaryValue },
    },
  },
}) {
  return {
    dictionaryValue,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneYearInput)
