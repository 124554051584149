import { useTranslation } from 'react-i18next'

import { creditStandaloneLoader } from '../../../helpers'
import { formID } from '../../Conditions/formID'
import Select from '../Select'
import SelectCity from '../SelectCity'

const CityDealerships = ({
  currentRef,
  nextRef,
  register,
  setValue,
  onChange,
  widgetId,
  activeDealershipWidgetId,
  isLeasing,
}) => {
  const { t } = useTranslation()

  const selectDealership = d => {
    if (activeDealershipWidgetId === d.widgetId) return

    onChange(d)

    // нужно загрузить данные по виджету, только если текущий widgetId != dealership.widgetId
    if (widgetId === d.widgetId) return

    creditStandaloneLoader({
      buybackPaymentPercentage: d?.forCreditStandalone?.buybackPaymentPercentage,
      clientModels: d?.forCreditStandalone?.clientModels,
      condition: d?.forCreditStandalone?.condition,
      currency: d?.forCreditStandalone?.currency,
      gapInsurance: d?.forCreditStandalone?.gapInsurance,
      initialFeePercentage: d?.forCreditStandalone?.initialFeePercentage,
      kaskoInsurance: d?.forCreditStandalone?.kaskoInsurance,
      lifeInsurance: d?.forCreditStandalone?.lifeInsurance,
      locale: d?.forCreditStandalone?.locale,
      onlineApprove: d?.forCreditStandalone?.onlineApprove,
      period: d?.forCreditStandalone?.period,
      splitCarSelect: d?.forCreditStandalone?.splitCarSelect,
      visual: d?.forCreditStandalone?.visual,
      widgetId: d?.widgetId,
      possiblePeriods: d?.forCreditStandalone?.possiblePeriods,
      defaultCountryCode: d?.forCreditStandalone?.defaultCountryCode,
      possibleCountryCodes: d?.forCreditStandalone?.possibleCountryCodes,
      priceToolTipEnabled: d?.forCreditStandalone?.priceToolTipEnabled,
      showProgramsBeforePersonalData: d?.forCreditStandalone?.showProgramsBeforePersonalData,
      defaultSettingsAvailable: d?.forCreditStandalone?.defaultSettingsAvailable,
      disableSmartContractLink: d?.forCreditStandalone?.disableSmartContractLink,
      disableSmartContractLogo: d?.forCreditStandalone?.disableSmartContractLogo,
    })
  }

  return (
    <SelectCity
      wide={true}
      dataType={'dealershipsCity'}
      isLeasing={isLeasing}
      onChange={selectDealership}
      labelText={t('ccS.dealershipsCity.dealershipCity')}
      placeholder={t('ccS.dealershipsCity.chooseCity')}
      fieldId={formID.dealerships}
      register={register}
      setValue={setValue}
      currentRef={currentRef}
      nextRef={nextRef}
    />
  )
}

export default CityDealerships
