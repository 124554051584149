module.exports = {
  initialPrice: {
    auto: 'Anfangspreis',
    moto: 'Anfangspreis',
    other: 'Anfangspreis',
  },
  finalPrice: 'Endpreis',
  title: {
    auto: 'Fahrzeugpreis',
    moto: 'Fahrzeugpreis',
    other: 'Fahrzeugpreis',
  },
  tooltipAlert: {
    auto:
      'The price is recommended, to agree on the final cost of the car, taking into account the conditions of purchase, the manager of the official dealership will contact you',
    moto:
      'The price is recommended, to agree on the final cost of the motorcycle, taking into account the conditions of purchase, the manager of the official dealership will contact you',
    other:
      'The price is recommended, to agree on the final cost of the vehicle, taking into account the conditions of purchase, the manager of the official dealership will contact you',
  },
  // tooltipAlert:
  //   'Der Preis ist eine erste Annahme. Ihr Ansprechpartner vom Händler wird sich mit Ihnen in Verbindung setzen, um den endgültigen Preis unter Berücksichtigung aller Vertragsbedingungen zu bestätigen.',
}
