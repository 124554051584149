module.exports = {
  initialPrice: {
    auto: 'Цена автомобиля',
    moto: 'Цена мотоцикла',
    other: 'Цена ТС',
  },
  finalPrice: 'После выбранных опций',
  title: {
    auto: 'Стоимость автомобиля',
    moto: 'Стоимость мотоцикла',
    other: 'Стоимость ТС',
  },
  tooltipAlert: {
    auto:
      'Цена является рекомендованной. Менеджер официального дилерского центра свяжется с вами для согласования финальной стоимости автомобиля',
    moto:
      'Цена является рекомендованной, для согласования финальной стоимости мотоцикла с учетом условий приобретения с Вами свяжется менеджер официального дилерского центра',
    other:
      'Цена является рекомендованной, для согласования финальной стоимости ТС с учетом условий приобретения с Вами свяжется менеджер официального дилерского центра',
  },
}
