export const availableCarsCountInDealership = d => {
  if (!d.cars) {
    return 0
  }

  let count = 0

  d.cars.forEach(c => {
    if (c.status === 'available') {
      count++
    }
  })

  return count
}
