import { useEffect, useRef, useState } from 'react'

import s from './tooltip.module.scss'

function Tooltip({ children, position, tooltipContent, fullHeight }) {
  const [open, setOpen] = useState(false)

  const content = useRef()
  const trigger = useRef()

  const openTooltip = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }

  const handleOutsideClick = event => {
    if (content?.current?.contains(event?.target) || trigger?.current?.contains(event?.target)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mouseover', handleOutsideClick) // mouseover
    } else {
      document.removeEventListener('mouseover', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mouseover', handleOutsideClick)
    }
  }, [open])

  let classes = () => {
    let c = [s.tooltip__content]

    switch (position) {
      case 'bottom':
        c.push(s.tooltip__content_bottom)
        break
      case 'top':
        c.push(s.tooltip__content_top)
        break
      case 'left':
        c.push(s.tooltip__content_left)
        break
      default:
        c.push(s.tooltip__content_right)
        break
    }

    return c.join(' ')
  }

  return (
    <div className={s.tooltip}>
      {open && (
        <div className={classes()}>
          <div className={s.content} ref={content}>
            <div className={fullHeight ? s.content__inner_full : s.content__inner}>
              <div className={s.inner}>{tooltipContent}</div>
            </div>
          </div>
        </div>
      )}
      <div className={s.tooltip__trigger} onMouseOver={e => openTooltip(e)} ref={trigger}>
        {children}
      </div>
    </div>
  )
}

export default Tooltip
