import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import { setIsCarIdentityValid, setTradeInCarIdentity } from '../../../store/actions'
import Input from '../../Ui/input'
import s from '../../Ui/input/input.module.scss'

function TradeInCarIdentity({ step, value, forceOpened, isCarIdentityValid }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setCarIdentity = e => {
    const v = e.target.value
    const t = v?.length >= 17 ? v?.substr(0, 17) : v
    if (!isCarIdentityValid) {
      dispatch(setIsCarIdentityValid(true))
    }
    dispatch(setTradeInCarIdentity({ payload: t }))
  }

  return (
    <div
      className={'kdxsc-accordion-item ' + (step === 'identity' || forceOpened ? 'kdxsc-accordion-item--opened' : '')}
    >
      <div className="kdxsc-accordion-item__line">
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.identity.title')}
          <div className="kdxsc-accordion-item__subtitle">{value}</div>
        </div>
        {!forceOpened && <span className="kdxsc-accordion-item__icon" />}
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <div className="kdxsc-tradein-item">
            <div className="kdxsc-tradein-item__input mb0">
              <div className={s.wrapper}>
                <div className={s.wrapper__input}>
                  <Input
                    value={value}
                    disabled={false}
                    handleChange={e => setCarIdentity(e)}
                    hasError={!isCarIdentityValid}
                    uppercase={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    values: {
      identity: { value },
    },
    isCarIdentityValid,
  },
}) {
  return {
    value: value || '',
    isCarIdentityValid,
  }
}

export default connect(mapStateToProps)(TradeInCarIdentity)
