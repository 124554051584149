import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { getProjectName } from '../../helpers/app/detectors/project_name'
import { goToIndex, goToSmartContractFromWidget, hideWidget } from '../../store/actions'
import Button from '../Ui/button'

function SuccessScreen({ saved_sc, widget_id, calledFromList, car, currency }) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const openSmartContract = e => {
    dispatch(goToSmartContractFromWidget())

    window.dataLayer &&
      window.dataLayer.push({
        event: 'SmartContract_OnlineDeal',
        Status: '',
        buttonName: e?.target?.innerText || 'Не удалось определить текст на кнопке перехода в большой СК',
        carDetail: {
          identity: car.identity,
          name: car.name,
          modelData: car.modelData,
          productType: car.productType,
        },
        widget_id: widget_id,
        deal_id: `${saved_sc.genericId.toUpperCase()}-${saved_sc.id}`,
        eventValue: car.price,
        currency,
      })

    window.open(saved_sc.url, '_blank')
  }

  const goToSite = e => {
    let ending = 'сайт'

    if (calledFromList) {
      dispatch(goToIndex())
      ending = 'первый экран'
    } else {
      dispatch(hideWidget())
    }

    window.dataLayer &&
      window.dataLayer.push({
        event: 'SmartContract_ReturnSite',
        Status: '',
        buttonName: e?.target?.innerText || 'Не удалось определить текст на кнопке перехода назад на ' + ending,
        carDetail: {
          identity: car.identity,
          name: car.name,
          modelData: car.modelData,
          productType: car.productType,
        },
        widget_id: widget_id,
        deal_id: `${saved_sc.genericId.toUpperCase()}-${saved_sc.id}`,
        eventValue: car.price,
        currency,
      })
  }

  return (
    <div className="kdxsc-success-screen" data-project-name={getProjectName()}>
      <div className="kdxsc-success-screen__icon">
        <div className="kdxsc-success-screen-icon" />
      </div>
      <div className="kdxsc-success-screen__text">
        {saved_sc.url ? t('success.description') : t('success.descriptionWithoutLink')}
      </div>
      <div className="kdxsc-success-screen__buttons kdxsc-success-screen-buttons">
        {saved_sc.url && (
          <div className="kdxsc-success-screen-buttons__item">
            <Button handleClick={openSmartContract} text={t('success.goToSmartContract')} id="kdxsc_online" />
          </div>
        )}
        <div className="kdxsc-success-screen-buttons__item">
          <Button handleClick={goToSite} text={t('success.backToSite')} id="kdxsc_return_site" type={'bordered'} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ saved_sc, settings: { widget_id, calledFromList, currency }, car }) {
  return {
    widget_id,
    saved_sc,
    calledFromList,
    car,
    currency,
  }
}

export default connect(mapStateToProps)(SuccessScreen)
