import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { CREDIT_STANDALONE } from '../../../store/types'
import { InputBox } from '../Common'
import s            from './styles/select.module.scss'
import {getProjectName}      from "../../../helpers/app/detectors/project_name";

const Select = ({
  dataType,
  wide,
  disabled,
  data,
  labelText,
  placeholder,
  fieldId,
  tip,
  showComplectations,
  showBrand,
  onChange,
  carModelId,
  register,
  setValue,
  nextRef,
  currentRef,
}) => {
  const [open, setOpen] = useState(false)
  const [valuesData, setValuesData] = useState(data)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const select = useRef()
  const dropdown = useRef()
  const hiddenInputRef = useRef()

  const handleInputChange = e => {
    filterValues(e.target.value)

    if (!open) {
      setOpen(true)
    }
  }

  const handleInputFocus = e => {
    setOpen(true)

    if (carModelId) {
      e.target.value = ''
      hiddenInputRef.current.value = ''
      filterValues('')
      onChange(null)
    }
  }

  const handleInputChangeKey = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()

      if (!open) {
        setOpen(true)
      }

      const dropdownList = select.current.lastElementChild
      const dropdownItem = dropdownList.firstElementChild

      if (!dropdownItem) {
        return
      }

      dropdownItem.focus()
    }
  }

  const handleSelectChange = e => {
    setOpen(false)
    const value = data.find(item => item?.id === e.target.dataset.id || item?.widgetId === e.target.dataset.id)

    if (value?.type === 'dealership') {
      dispatch({ type: CREDIT_STANDALONE.SET_SHOW_COMPLECTATIONS, payload: value.showComplectations })
      dispatch({ type: CREDIT_STANDALONE.SET_SHOW_BRAND_IN_MODEL_SELECT, payload: value.showBrandInModelSelect })
    }
    setValue(fieldId, e.target.dataset.id)
    onChange(value)
    currentRef.current.value =
      (showComplectations && value?.modelData.complectation) || value?.name || value.dealershipName

    setTimeout(() => nextRef.current.focus(), 150)
  }

  const handleSelectChangeKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()

      setOpen(false)

      const value = data.find(item => item?.id === e.target.dataset.id || item?.widgetId === e.target.dataset.id)
      if (value?.type === 'dealership') {
        dispatch({ type: CREDIT_STANDALONE.SET_SHOW_COMPLECTATIONS, payload: value.showComplectations })
        dispatch({ type: CREDIT_STANDALONE.SET_SHOW_BRAND_IN_MODEL_SELECT, payload: value.showBrandInModelSelect })
      }
      setValue(fieldId, e.target.dataset.id)
      onChange(value)
      currentRef.current.value =
        (showComplectations && value?.modelData.complectation) || value?.name || value.dealershipName

      setTimeout(() => nextRef.current.focus(), 150)
    }

    // выделить следующий элемент
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'Tab') {
      e.preventDefault()

      const nextElement = e.target?.nextElementSibling

      if (!nextElement) {
        const firstElement = e.target?.parentElement.firstElementChild
        firstElement.focus()
        return
      }

      nextElement.focus()
    }

    // выделить предыдущий элемент
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()

      const previousElement = e.target?.previousElementSibling

      if (!previousElement) {
        const lastElement = e.target?.parentElement.lastElementChild
        lastElement.focus()
        return
      }

      previousElement.focus()
    }
  }

  const filterValues = useCallback(
    newValue => {
      const filteredValues = data.filter(value => value?.name.toLowerCase().includes(newValue.toLowerCase().trim()))
      setValuesData(filteredValues)
    },
    [data],
  )

  // TODO: remove
  const handleSelectOpen = () => setOpen(open => !open)

  const handleOutsideClick = event => {
    if (select.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleOutsideKey = useCallback(
    event => {
      if (event.key === 'Escape') {
        setOpen(false)
        currentRef.current.blur()
      }
    },
    [currentRef],
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
      document.addEventListener('keydown', handleOutsideKey)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleOutsideKey)
    }
  }, [open, handleOutsideKey])

  useEffect(() => {
    if (open) {
      dropdown.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [open])

  const renderValuesList = () => {
    if (!data.length) {
      return (
        <li className={s.item} data-status="not-found">
          {t('ccS.conditions.notFound') || 'Ничего не найдено'}
        </li>
      )
    }

    return data.map(value => {
      return (
        <li
          className={s.item}
          onClick={handleSelectChange}
          onKeyDown={handleSelectChangeKey}
          key={value?.id || value?.widgetId}
          tabIndex={0}
          data-id={value?.id || value?.widgetId}
        >
          {(showComplectations && value?.modelData?.complectation) || value?.name || value?.dealershipName}
        </li>
      )
    })
  }

  return (
    <InputBox labelText={labelText} id={fieldId} tip={tip} wide={wide}>
      <div className={s.box} ref={select} data-project-name={getProjectName()}>
        <input
          autoComplete="off"
          className={s.hidden_input}
          name={fieldId}
          ref={e => {
            hiddenInputRef.current = e
            register(e)
          }}
          onFocus={() => {
            currentRef.current.focus()
            setOpen(true)
          }}
        />
        <input
          ref={currentRef}
          className={s.input}
          id={fieldId}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyDown={handleInputChangeKey}
          onFocus={handleInputFocus}
          placeholder={placeholder}
          data-open={open}
          data-wide={wide}
          data-dealerships={dataType === 'dealerships'}
          disabled={disabled || dataType === 'dealerships'}
        />
        <div
          className={s.arrow}
          onClick={disabled ? null : handleSelectOpen}
          data-wide={wide}
          data-disabled={disabled}
        />
        <ul className={s.list} data-open={open} ref={dropdown}>
          {renderValuesList()}
        </ul>
      </div>
    </InputBox>
  )
}

function mapStateToProps(state, ownProps) {
  const { dataType, isLeasing } = ownProps
  if (dataType === 'cars')
    return {
      data: state.credit_standalone.conditions.cars,
    }
  if (dataType === 'carsSelect') {
    return {
      data: state.credit_standalone.conditions.selectCars,
      showBrand: state.credit_standalone.showBrandInModelSelect,
    }
  }

  if (dataType === 'dealerships')
    return {
      data: isLeasing ? state.credit_standalone.dealerships.leasing : state.credit_standalone.dealerships.credit,
    }
}

export default connect(mapStateToProps)(Select)
