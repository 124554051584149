import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const deprecatedButtonGeneratorHelper = (preset, tooltipData, text, config) => {
  let generated = []

  const [platform] = presetParams(preset)

  // preset detail generator(text)
  if (platform) {
    if (platform === PLATFORM_ONE_PLATFORM) {
      const buttons = onePlatform().detail.generator(text)

      if (Array.isArray(buttons)) {
        return buttons
      } else if (!buttons) {
        return []
      }

      return [buttons]
    } else {
      return []
    }
  }

  // detail generator(text)
  const generator = config?.config?.detail.generator || config?.detail?.generator

  if (generator && typeof generator === 'function') {
    const elements = generator(text)

    if (Array.isArray(elements) || NodeList.prototype.isPrototypeOf(elements)) {
      generated.push(...elements)
    } else {
      generated.push(elements)
    }
  }

  // old function - generate_button
  if (config.generate_button && typeof config.generate_button === 'function') {
    const elements = config.generate_button(text)

    if (Array.isArray(elements)) {
      generated.push(...elements)
    } else {
      generated.push(elements)
    }
  }

  // old function - element_to_find
  if (config.element_to_find && typeof config.element_to_find === 'function') {
    const original = config.element_to_find()

    if (!original) {
      throw new Error('unable to find element to clone')
    }

    const classNames = original.classList || []
    const tagName = original.tagName.toLowerCase()

    const clone = document.createElement(tagName)
    for (const name of classNames) {
      clone.classList.add(name)
    }

    clone.classList.add('kdxsc-target')
    clone.innerHTML = text

    original.parentNode.appendChild(clone)

    generated.push(clone)
  }

  return generated
}

export const additionalButtonGenerator = preset => {
  const [platform] = presetParams(preset)

  if (platform === PLATFORM_ONE_PLATFORM) {
    return onePlatform().detail.additionalButtonGenerator()
  }

  return null
}
