module.exports = {
  onlinePaymentHintText: 'Онлайн предоплата доступна после сохранения условий',
  totalTooltip: {
    firstZeroPaymentHintText: {
      auto: 'В качестве первоначального взноса<br/>будет учтена стоимость вашего автомобиля<br/>в Трейд-ин',
      moto: 'В качестве первоначального взноса<br/>будет учтена стоимость вашего мотоцикла<br/>в Трейд-ин',
      other: 'В качестве первоначального взноса<br/>будет учтена стоимость вашего ТС<br/>в Трейд-ин',
    },
    firstAdditionalPaymentHintText: {
      auto: 'Вам необходимо доплатить {payment}<br/>к стоимости своего автомобиля,<br/>сданного в Трейд-ин, чтобы заплатить<br/> выбранный вами первоначальный взнос',
      moto: 'Вам необходимо доплатить {payment}<br/>к стоимости своего мотоцикла,<br/>сданного в Трейд-ин, чтобы заплатить<br/> выбранный вами первоначальный взнос',
      other:
        'Вам необходимо доплатить {payment}<br/>к стоимости своего ТС,<br/>сданного в Трейд-ин, чтобы заплатить<br/> выбранный вами первоначальный взнос',
    },
    creditAmount:
      'Сумма кредита, равная <span class="kdxsc-credit-total-header__price">{ payment }</span>, <br/>включает в себя',
  },
  withTax: 'с налогом',
}
