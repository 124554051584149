import { useTranslation } from 'react-i18next'

import { formatPrice } from '../../../helpers'
import { getProjectName } from '../../../helpers/app/detectors/project_name'
import ICircleIcon from '../../../svgComponents/iCircle'
import Tooltip from '../tooltip'
import s from './blockVariant.module.scss'

function BlockVariant({
  firstRow,
  secondRow,
  firstPrice,
  secondPrice,
  tooltipContent,
  tooltipProgramContent,
  isActive,
  isWithoutCalculation,
  showLink,
  linkBehaviour,
  linkText,
  checkboxBehaviour,
  id,
  type,
  currency,
  isCredit,
  from,
  isDisabled,
}) {
  const { t } = useTranslation()

  const checkboxDisabled = isDisabled === true

  return (
    <div className={s.variant} data-project-name={getProjectName()}>
      <div className={s.variantRow}>
        <div className={s.variantRow__item}>
          <div className={s.radio}>
            <input
              id={id}
              type="checkbox"
              onChange={checkboxBehaviour}
              checked={isActive}
              disabled={checkboxDisabled}
              className={[s.checkbox, type === 'calculation' ? s.checkboxRounded : ''].join(' ')}
            />
            <label htmlFor={id} className={s.checkboxLabel}>
              {firstRow}
            </label>
          </div>
          <div className={s.addition}>
            {secondRow && (
              <div
                className={[s.addition__text, type === 'calculation' ? s.addition__text_dashed : ''].join(' ')}
                dangerouslySetInnerHTML={{ __html: secondRow.split('\n').join('<br>') }}
              />
            )}
            {showLink && linkBehaviour && (
              <div className={s.addition__link} onClick={linkBehaviour}>
                {linkText}
              </div>
            )}
          </div>
        </div>

        <div className={s.variantRow__item}>
          <div className={s.prices}>
            {firstPrice && (
              <div className={[s.prices__item, isActive && !checkboxDisabled ? s.active : ''].join(' ')}>
                <span className={s.price}>{formatPrice(firstPrice, currency)}</span>
                {tooltipContent && (
                  <Tooltip position="left" fullHeight={true} tooltipContent={tooltipContent}>
                    <ICircleIcon />
                  </Tooltip>
                )}
              </div>
            )}
            {secondPrice && (
              <div
                className={[
                  s.prices__item,
                  isActive ? s.active : '',
                  isWithoutCalculation && !/[0-9]/.test(secondPrice) ? s.fwLight : '',
                ].join(' ')}
              >
                <span className={/[0-9]/.test(secondPrice) ? s.price : ''}>
                  {from && <span className={s.from}>{t('from')}</span>}
                  {secondPrice} {/[0-9]/.test(secondPrice) && isCredit && '/ ' + t('months')}
                </span>
                {tooltipProgramContent && (
                  <Tooltip position="left" fullHeight={true} tooltipContent={tooltipProgramContent}>
                    <ICircleIcon />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockVariant
