import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

import { InputBox } from '../UI/Common'
import ls           from '../UI/Range/styles/line.module.scss'
import rs           from '../UI/Range/styles/range.module.scss'

export const BaseRange = props => {
  const {
    labelText,
    id,
    tip,
    onChange,
    value,
    control,
    register,
    setValue,
    currentRef,
    disabled,
    suffix,
    min,
    max,
  } = props

  const rangeLineRef = useRef(null)

  const onFocus = useCallback(() => currentRef.current.focus(), [currentRef])
  const valueChange = useCallback(({ floatValue }) => onChange(floatValue), [onChange])

  useEffect(() => {
    const onBlur = e => {
      const value = parseInt(e.target.value)
      if (isNaN(value) || value < min) setValue(id, min)
      if (value > max) setValue(id, max)
    }

    currentRef.current.addEventListener('blur', onBlur)
    return () => currentRef.current.removeEventListener('blur', onBlur)
  }, [currentRef, id, max, min, setValue])

  return (
    <InputBox labelText={labelText} id={id} tip={tip} isLeasing={true}>
      <Controller
        as={<NumberFormat getInputRef={el => (currentRef.current = el)} />}
        name={id}
        id={id}
        control={control}
        value={value}
        onValueChange={valueChange}
        onFocus={onFocus}
        allowNegative={false}
        decimalScale={0}
        defaultValue={value}
        suffix={suffix}
        autoComplete="off"
        className={rs.input}
        disabled={disabled}
      />
      <BaseLine
        rangeLineRef={rangeLineRef}
        rangeInputRef={register}
        value={value}
        onChange={onChange}
        disabled={disabled}
        min={min}
        max={max}
      />
    </InputBox>
  )
}

const BaseLine = props => {
  const { rangeLineRef, value, onChange, min, max, rangeInputRef, disabled } = props

  useLayoutEffect(() => {
    rangeLineRef.current.style.width = `${((Number(value) - min) / (max - min)) * 100}%`
  }, [max, min, rangeLineRef, value])

  const rangeChange = useCallback(({ target }) => onChange(target.value), [onChange])

  return (
    <section className={ls.range_container}>
      <div className={ls.range_fill} ref={rangeLineRef} />
      <input
        style={{ width: '100%' }}
        className={ls.range}
        type="range"
        min={min}
        max={max}
        step="1"
        name={'leasing-term'}
        tabIndex={-1}
        onChange={rangeChange}
        value={value}
        ref={rangeInputRef}
        disabled={disabled}
      />
    </section>
  )
}

const RangeTerms = props => {
  const { possibleTerms, value, onChange, id, setValue } = props
  const { t } = useTranslation()

  const [minmax, setMinmax] = useState(props.data.MONTH_MINMAX)

  const { min, max } = minmax

  const onChangeTerm = useCallback(
    value => {
      const res = Math.min(Math.max(value || 0, min), max)
      setValue(id, res)
      onChange(String(res * props.data.MONTH_DIVIDER))
    },
    [id, max, min, onChange, setValue],
  )

  useLayoutEffect(() => {
    const minmaxTerms = findMinMaxTerms(possibleTerms, props)
    setMinmax(minmaxTerms)
  }, [possibleTerms])

  const translatePrefix = getSuffix(possibleTerms, value, props)
  const suffix = props.defaultSettingsAvailable
    ? ' ' + t('months')
    : translatePrefix
    ? t(`credit.periods.${translatePrefix}`).slice(1)
    : ''
  return (
    <BaseRange
      {...props}
      value={monthToYear(value, props)}
      min={min}
      max={max}
      suffix={suffix}
      onChange={onChangeTerm}
    />
  )
}

export default RangeTerms

const monthToYear = (moth, props) =>
  Math.floor(moth / props.data.MONTH_DIVIDER)

const getSuffix = (possibleTerms, month, props) => {
  if (Number(month) === 12 && props.defaultSettingsAvailable) {
    month = '13'
  }

  const term = possibleTerms.find(({ value }) => value === parseInt(month))

  return term?.text || ''
}
function findMinMaxTerms(arrayTerms, props) {
  const [min, ...rest] = arrayTerms.sort((a, b) => a.value - b.value).map(obj => monthToYear(obj.value, props))
  return { min, max: rest[rest.length - 1] }
}
