import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

import s from './styles/input.module.scss'

function TradeInStandalonePartsInput({ value, onSetValue, name, focus }) {
  const { t } = useTranslation()

  const inputRef = useRef()

  const buffer = useRef()

  useEffect(() => {
    if (buffer.current === focus) {
      return
    }

    buffer.current = focus

    if (['brand', 'model', 'city', 'mileage', 'identity'].includes(focus)) {
      onSetValue('')
      inputRef.current.focus()
    }
  }, [focus, onSetValue])

  const content = () => {
    if (name === 'mileage') {
      return (
        <NumberFormat
          getInputRef={el => (inputRef.current = el)}
          className={s.input}
          name={name}
          value={value === 0 ? '' : value}
          onValueChange={e => onSetValue(e.value)}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={' '}
          decimalScale={0}
          placeholder={t('tradeInS.enterMileage')}
          isNumericString={false}
          suffix={` ${t('tradeInS.kilometers')}`}
          maxLength={10}
          defaultValue={''}
          autoComplete="off"
        />
      )
    } else {
      return (
        <input
          ref={inputRef}
          placeholder={t('tradeInS.inputPlaceholder')}
          value={value}
          onChange={e => onSetValue(e.target.value)}
          className={s.input}
          type="text"
        />
      )
    }
  }

  return content()
}

export default TradeInStandalonePartsInput
