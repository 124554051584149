import './_theme_generic_v2.scss'

import { getProjectName } from '../../../../helpers/app/detectors/project_name'

function TradeInRadio({ variants, name, checked, onSet }) {
  const handleSet = event => {
    const {
      target: { value },
    } = event

    const variant = variants.find(variant => variant.id === value)

    onSet({
      id: variant.id,
      name: variant.name,
      external: variant.external,
    })
  }

  return (
    <div className="kdxsc-tradein-item" data-project-name={getProjectName()}>
      <div className="kdxsc-tradein-item__items">
        <div className="kdxsc-row">
          {variants.map(variant => {
            return (
              <div className="kdxsc-row__item" key={`${name}-id-${variant.id}`}>
                <input
                  className="kdxsc-radio"
                  name={name}
                  type="radio"
                  checked={checked === variant.id}
                  id={`${name}-id-${variant.id}`}
                  value={variant.id}
                  onChange={handleSet}
                />
                <label htmlFor={`${name}-id-${variant.id}`}>{variant.name}</label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TradeInRadio
