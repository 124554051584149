function DealershipCarIcon() {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M39.5834 27.0833C39.3459 27.0833 39.1063 27.0021 38.9126 26.8375C38.5126 26.5042 29.1667 18.5292 29.1667 10.4167C29.1667 4.67292 33.8397 0 39.5834 0C45.3272 0 50.0001 4.67292 50.0001 10.4167C50.0001 18.5292 40.6543 26.5042 40.2542 26.8375C40.0605 27.0021 39.8209 27.0833 39.5834 27.0833ZM39.5834 2.08333C34.9876 2.08333 31.2501 5.82083 31.2501 10.4167C31.2501 16.3917 37.4938 22.7 39.5834 24.6417C41.673 22.6979 47.9167 16.3917 47.9167 10.4167C47.9167 5.82083 44.1792 2.08333 39.5834 2.08333Z"
            fill="black"
          />
          <path
            d="M39.5834 14.5833C37.2855 14.5833 35.4167 12.7146 35.4167 10.4167C35.4167 8.11875 37.2855 6.25 39.5834 6.25C41.8813 6.25 43.7501 8.11875 43.7501 10.4167C43.7501 12.7146 41.8813 14.5833 39.5834 14.5833ZM39.5834 8.33333C38.4334 8.33333 37.5001 9.26666 37.5001 10.4167C37.5001 11.5667 38.4334 12.5 39.5834 12.5C40.7334 12.5 41.6667 11.5667 41.6667 10.4167C41.6667 9.26666 40.7334 8.33333 39.5834 8.33333Z"
            fill="black"
          />
          <path
            d="M10.4167 50C8.11875 50 6.25 48.1312 6.25 45.8333C6.25 43.5354 8.11875 41.6666 10.4167 41.6666C12.7146 41.6666 14.5833 43.5354 14.5833 45.8333C14.5833 48.1312 12.7146 50 10.4167 50ZM10.4167 43.75C9.26666 43.75 8.33333 44.6833 8.33333 45.8333C8.33333 46.9833 9.26666 47.9167 10.4167 47.9167C11.5667 47.9167 12.5 46.9833 12.5 45.8333C12.5 44.6833 11.5667 43.75 10.4167 43.75Z"
            fill="black"
          />
          <path
            d="M39.5834 50C37.2855 50 35.4167 48.1312 35.4167 45.8333C35.4167 43.5354 37.2855 41.6666 39.5834 41.6666C41.8813 41.6666 43.7501 43.5354 43.7501 45.8333C43.7501 48.1312 41.8813 50 39.5834 50ZM39.5834 43.75C38.4334 43.75 37.5001 44.6833 37.5001 45.8333C37.5001 46.9833 38.4334 47.9167 39.5834 47.9167C40.7334 47.9167 41.6667 46.9833 41.6667 45.8333C41.6667 44.6833 40.7334 43.75 39.5834 43.75Z"
            fill="black"
          />
          <path
            d="M44.6103 47.9167H42.977C42.402 47.9167 41.9353 47.45 41.9353 46.875C41.9353 46.3 42.402 45.8333 42.977 45.8333H44.6103C45.8812 45.8333 47.0166 45.0729 47.502 43.8958L47.8395 43.0708C48.0458 42.5708 47.8228 41.9813 47.3437 41.7542C31.727 34.3646 16.7145 33.3792 2.72076 38.8271C2.25201 39.0083 1.98743 39.5417 2.11451 40.0417L2.98743 43.4771C3.34159 44.8646 4.58534 45.8333 6.01659 45.8333H7.27076C7.84576 45.8333 8.31243 46.3 8.31243 46.875C8.31243 47.45 7.84576 47.9167 7.26868 47.9167H6.01659C3.63118 47.9167 1.55618 46.3021 0.968678 43.9896L0.0957609 40.5542C-0.291739 39.025 0.512428 37.4479 1.96451 36.8854C16.5124 31.2188 32.0812 32.2271 48.2333 39.8708C49.7229 40.575 50.3979 42.3292 49.7666 43.8625L49.427 44.6875C48.6208 46.65 46.7312 47.9167 44.6103 47.9167Z"
            fill="black"
          />
          <path
            d="M36.6459 47.9166H13.3542C12.7792 47.9166 12.3126 47.4499 12.3126 46.8749C12.3126 46.2999 12.7792 45.8333 13.3542 45.8333H36.6459C37.2209 45.8333 37.6876 46.2999 37.6876 46.8749C37.6876 47.4499 37.2209 47.9166 36.6459 47.9166Z"
            fill="black"
          />
          <path
            d="M19.6292 41.3937C17.9771 41.3937 16.4063 40.6062 15.4188 39.2479L12.698 35.5083C12.3605 35.0437 12.4625 34.3917 12.9271 34.0542C13.3917 33.7167 14.0438 33.8187 14.3813 34.2833L17.1021 38.0229C17.7709 38.9458 18.8896 39.4229 20.0146 39.2854L33.6896 37.5771L34.4813 35.9917C34.7396 35.4792 35.3667 35.2687 35.8792 35.525C36.3938 35.7833 36.6021 36.4083 36.3459 36.9229L35.3042 39.0062C35.1479 39.3167 34.8459 39.5312 34.5 39.5729L20.275 41.3542C20.0605 41.3792 19.8438 41.3937 19.6292 41.3937Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default DealershipCarIcon
