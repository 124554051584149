import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { load } from 'redux-localstorage-simple'
import { polyfill } from 'seamless-scroll-polyfill'

import Car from './Components/Parts/Car/Car'
import Footer from './Components/Parts/Footer'
import Header from './Components/Parts/Header'
import Accessories from './Components/Screen/accessories/Accessories'
import AuthScreen from './Components/Screen/Auth'
import Credit from './Components/Screen/credit/Credit'
import ErrorScreen from './Components/Screen/Error'
import Index from './Components/Screen/index/Index'
import Leasing from './Components/Screen/leasing/Leasing'
import OnlinePayment from './Components/Screen/onlinePayment/OnlinePayment'
import PaymentFailed from './Components/Screen/PaymentFailed'
import PaymentStart from './Components/Screen/PaymentStart'
import PromoProgram from './Components/Screen/promoProgram/PromoProgram'
import SuccessScreen from './Components/Screen/Success'
import TradeInAutoRuCalculator from './Components/Screen/tradeInAutoRuCalculator/TradeInAutoRuCalculator'
import TradeInList from './Components/Screen/tradeInList/TradeInList'
import {
  FINANCE_USER_BEHAVIOUR,
  FORM_SOURCE,
  SCREEN_ACCESSORIES,
  SCREEN_AUTH,
  SCREEN_CREDIT,
  SCREEN_ERROR,
  SCREEN_INDEX,
  SCREEN_LEASING,
  SCREEN_ONLINE_PAYMENT,
  SCREEN_ONLINE_PAYMENT_FAILED,
  SCREEN_ONLINE_PAYMENT_START,
  SCREEN_PROMO_PROGRAM,
  SCREEN_SUCCESS,
  SCREEN_TRADE_IN,
  TI_PARTNER,
  TRADE_IN_ESTIMATION_TYPE,
  TRADE_IN_OP_UPDATED_EVENT,
} from './constants'
import {
  bootBatch2,
  bootBatch2PayloadCreator,
  bootMainInfo,
  dealershipsFormatter,
  dealershipsSetter,
  detectAdditionalOptionsHelper,
  detectCustomDataHelper,
  detectDealershipsHelper,
  detectDiscountsHelper,
  detectIdentityHelper,
  detectModelDataHelper,
  detectPriceHelper,
  detectProductTypeHelper,
  normalCarAndWidgetForBoot,
  supplementConfigHelper,
} from './helpers'
import { getProjectName } from './helpers/app/detectors/project_name'
import { SmartContactOnList } from './on_list'
import { InitSmartContractOnListV2 } from './on_list_v2'
import { setActiveProgram, setBuybackPercent, setCreditPeriod, setInitfeePercent } from './store/actions/credit'
import {
  hideWidget,
  setDiscounts,
  setFinanceBehaviourByUser,
  setModelData,
  setProductType,
  setTradeInStateFromOP,
  setWidgetConfig,
} from './store/actions/index'
import { getSavedCreditParams, getSavedSelectedProgram } from './store/helpers/credit_standalone'

polyfill()

function App({
  config,
  screen,
  visible,
  loading,
  activeDealership,
  isWidgetLoaded,
  dealerships,
  partner,
  estimationType,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const panelContent = useRef()
  const panelTop = useRef()
  const carBlock = useRef()

  const defineTradeInScreen = () => {
    if (partner !== TI_PARTNER.AUTO_RU) {
      return <TradeInList />
    }
    switch (estimationType) {
      case TRADE_IN_ESTIMATION_TYPE.LIST:
        return <TradeInList />
      case TRADE_IN_ESTIMATION_TYPE.AUTORU_VIN_GRZ:
        return <TradeInAutoRuCalculator />
      default:
        return <TradeInAutoRuCalculator />
    }
  }

  const handleOPTradeInUpdate = useCallback(
    e => {
      const state = e.detail

      if (state.domainData.enabled) {
        dispatch(setTradeInStateFromOP(state))
      }
    },
    [dispatch],
  )

  useEffect(() => {
    document.addEventListener(TRADE_IN_OP_UPDATED_EVENT, handleOPTradeInUpdate)

    return () => {
      document.removeEventListener(TRADE_IN_OP_UPDATED_EVENT, handleOPTradeInUpdate)
    }
  }, [handleOPTradeInUpdate])

  useEffect(() => {
    const savedCreditParams = getSavedCreditParams()

    if (savedCreditParams?.initFeePercent || String(savedCreditParams?.initFeePercent) === '0') {
      dispatch(setInitfeePercent(Number(savedCreditParams.initFeePercent)))
    }

    if (savedCreditParams?.buybackPercent || String(savedCreditParams?.buybackPercent) === '0') {
      dispatch(setBuybackPercent(Number(savedCreditParams.buybackPercent)))
    }

    // если период 6,7 лет, то утанавливаем период = 5 лет
    let term = savedCreditParams?.term
    if (term) {
      term = Number(term)

      if (term >= 72) {
        term = 60
      }

      dispatch(setCreditPeriod(term))
    }

    const savedSelectedProgram = getSavedSelectedProgram()

    // если есть id и также срок менее 5 лет, то применяем программу, иначе не применяем программу
    if (savedSelectedProgram?.id && Number(savedCreditParams?.term) <= 60) {
      dispatch(setActiveProgram(savedSelectedProgram))
      dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED))
    }
  }, [dispatch])

  const closeWidget = event => {
    if (event.target.classList.contains('kdxsc-wrapper')) {
      dispatch(hideWidget())
    }
  }

  /**
   * Загрузка данных с БЭ
   * если вызов произошел со списка, то кнопку не копируем, а сразу открываем виджет
   */
  const loadData = useCallback(
    (
      widget_id,
      modelData,
      productType,
      discounts,
      pagePrice,
      safeIdentity,
      calledFromList,
      dontOpenWidget,
      palette,
      header_text,
      form_agreement_text,
      detail,
      cars,
      dealerships,
      additionalOptions,
      customData,
    ) => {
      const noWidgetDefined = !widget_id || widget_id === 'no-widget-id'
      const areDealerships = Boolean(dealerships || dealerships.length)

      if (noWidgetDefined && !areDealerships) {
        console.error('[smart.contract] unable to boot widget due no widget ID provided')
        return
      }

      dispatch(setWidgetConfig(config))

      const dataFromLocalStorage = load({
        states: ['tradeIn', 'credit', 'benefits', 'form', 'leasing', 'accessories'],
        namespace: '@kodix/smartContract',
        namespaceSeparator: '/',
      })

      const isBootBatchV1 = Boolean(calledFromList || !dealerships || !dealerships.length)
      const isBootBatchV2 = Boolean(!calledFromList || (dealerships && dealerships.length))

      window.scLogger.log('[app::isBootBatchV1]', isBootBatchV1)
      window.scLogger.log('[app::isBootBatchV2]', isBootBatchV2)

      if (isBootBatchV1) {
        const essentialDataForLoading = {
          widget_id: widget_id,
          pagePrice: pagePrice,
          safeIdentity: safeIdentity,
        }

        window.scLogger.log('[app::dealerships]', dealerships)

        if (dealerships && dealerships.length) {
          dealershipsSetter(dealerships)

          let normalData = normalCarAndWidgetForBoot(dealerships)

          window.scLogger.log('[app::normalData]', normalData)

          if (normalData && Object.values(normalData)?.length) {
            essentialDataForLoading.widget_id = normalData.widgetId
            essentialDataForLoading.pagePrice = normalData.price
            essentialDataForLoading.safeIdentity = normalData.identity
          }
        }

        window.scLogger.log('[app::essentialDataForLoading]', essentialDataForLoading)

        bootMainInfo(
          {
            widget_id: essentialDataForLoading.widget_id,
            pagePrice: essentialDataForLoading.pagePrice,
            safeIdentity: essentialDataForLoading.safeIdentity,
            modelData,
            productType,
            calledFromList,
            dontOpenWidget,
            discounts,
            header_text,
            form_agreement_text,
            palette,
            detail,
            additionalOptions,
            customData,
          },
          config,
          dataFromLocalStorage,
          true,
        )

        return
      }

      if (isBootBatchV2) {
        const card_identity = 'card_detailPage'

        let obj = {
          [safeIdentity]: {
            card_identity,
            dealerships,
            discounts,
            model_data: modelData,
            price: pagePrice,
            productType,
            widget_id,
          },
        }

        let p = bootBatch2PayloadCreator(obj)

        bootBatch2('detail', JSON.stringify({ data: p }))
          .then(({ data }) => {
            let cardData = data[card_identity]

            if (!cardData) {
              throw new Error('There is no cardData for this car')
            }

            // устанавливаем ДЦ и пишем их в стор
            let availableDealerships = dealershipsFormatter(cardData)
            dealershipsSetter(availableDealerships)

            let normalData = normalCarAndWidgetForBoot(availableDealerships)

            //открываем виджет с конфигурацией нужного ДЦ, но основное блюрим
            bootMainInfo(
              {
                widget_id: normalData.widgetId,
                pagePrice: normalData.price,
                safeIdentity: normalData.identity,
                modelData: modelData,
                productType: productType,
                calledFromList,
                dontOpenWidget,
                discounts,
                header_text,
                form_agreement_text,
                palette,
                detail,
                additionalOptions,
              },
              config,
              dataFromLocalStorage,
              true,
            )
          })
          .catch(e => console.error(e))
      }
    },
    [config, dispatch],
  )

  /**
   * Кнопка на детальной странице и списке
   * Boot приложения - точка входа.
   */
  useEffect(() => {
    // инициализация нового списка

    if (config?.listVersion === '2') {
      if (!config?.calledFromList) {
        InitSmartContractOnListV2(config)
        return
      }

      // открытие виджета на новом списке
      if (config?.detail) {
        const {
          additionalOptions,
          dealerships,
          discounts,
          identity,
          // link,
          modelData,
          // name,
          // picture,
          price,
          productType,
        } = config.detail
        const { calledFromList, dontOpenWidget, widget_id } = config

        dispatch(setModelData(modelData))
        dispatch(setProductType(productType))
        dispatch(setDiscounts(discounts))

        if (identity) {
          loadData(
            widget_id,
            modelData,
            productType,
            discounts,
            price,
            identity,
            calledFromList,
            dontOpenWidget,
            undefined, // palette,
            undefined, // header_text,
            undefined, // form_agreement_text,
            config.detail,
            undefined, // cars,
            dealerships,
            additionalOptions,
            {}, // customData,
          )
        }

        return
      }

      return
    }

    // инициализация старого списка

    const fullConfig = supplementConfigHelper(config.preset, config)

    const {
      widget_id,
      detail,
      palette,
      preset,
      header_text,
      form_agreement_text,
      calledFromList,
      dontOpenWidget,
      cars,
    } = fullConfig

    let safeIdentity = ''
    let pagePrice = ''
    let modelData = ''
    let productType = ''
    let discounts = ''
    let dealerships = ''
    let pageAdditionalOptions = {}
    let customData = {}

    try {
      safeIdentity = detectIdentityHelper(preset, detail, fullConfig)
      pagePrice = detectPriceHelper(preset, detail, fullConfig)
      modelData = detectModelDataHelper(preset, detail)
      productType = detectProductTypeHelper(preset, detail)
      discounts = detectDiscountsHelper(preset, detail)
      dealerships = detectDealershipsHelper(preset, detail)
      pageAdditionalOptions = detectAdditionalOptionsHelper(preset, detail)

      if (config?.calledFromList) {
        customData = detectCustomDataHelper(preset, detail, config.calledFromList)
      } else {
        customData = detectCustomDataHelper(preset, detail)
      }
    } catch (e) {
      console.error('SC can not be mount - ', e)
    }

    dispatch(setModelData(modelData))
    dispatch(setProductType(productType))
    dispatch(setDiscounts(discounts))

    if (safeIdentity) {
      loadData(
        widget_id,
        modelData,
        productType,
        discounts,
        pagePrice,
        safeIdentity,
        calledFromList,
        dontOpenWidget,
        palette,
        header_text,
        form_agreement_text,
        detail,
        cars,
        dealerships,
        pageAdditionalOptions,
        customData,
      )
    }

    if (!fullConfig.calledFromList) {
      // Предотвращаем повторный вызов SmartContractOnList
      SmartContactOnList(fullConfig)
    }
  }, [config, loadData, dispatch])

  const footerClasses = () => {
    if (dealerships.length < 2) {
      return 'kdxsc-panel__bottom'
    } else {
      return activeDealership.widgetId && isWidgetLoaded ? 'kdxsc-panel__bottom' : 'kdxsc-panel__bottom_blurred'
    }
  }

  return (
    <div>
      {visible && (
        <div className="kdxsc-wrapper" onClick={e => closeWidget(e)} data-project-name={getProjectName()}>
          <div className="kdxsc-wrapper__inner">
            <div className="kdxsc-panel conveythis-no-translate">
              {loading && (
                <div className="kdxsc-loading-state-wrapper">
                  <div className="kdxsc-loading-state">
                    <div className="kdxsc-loading-state__icon">
                      <div className="kdxsc-loading-state-icon loading-icon" />
                    </div>
                    <div className="kdxsc-loading-state__text">{t('loading', 'Loading ...')}</div>
                  </div>
                </div>
              )}

              <div className="kdxsc-panel__top" ref={panelTop}>
                <div className="kdxsc-panel-top">
                  <div className="kdxsc-panel-top__header">
                    <Header />
                  </div>
                  {screen === SCREEN_INDEX && (
                    <div className="kdxsc-panel-top__car" ref={carBlock}>
                      <Car />
                    </div>
                  )}
                </div>
              </div>

              <div className="kdxsc-panel__main" ref={panelContent}>
                {screen === SCREEN_INDEX && <Index panelTop={panelTop} config={config} />}
                {screen === SCREEN_TRADE_IN && defineTradeInScreen()}
                {screen === SCREEN_SUCCESS && <SuccessScreen />}
                {screen === SCREEN_ERROR && <ErrorScreen />}
                {screen === SCREEN_CREDIT && <Credit />}
                {screen === SCREEN_LEASING && <Leasing />}
                {screen === SCREEN_ONLINE_PAYMENT && <OnlinePayment />}
                {screen === SCREEN_ONLINE_PAYMENT_FAILED && <PaymentFailed />}
                {screen === SCREEN_ONLINE_PAYMENT_START && <PaymentStart />}
                {screen === SCREEN_AUTH && <AuthScreen />}
                {screen === SCREEN_ACCESSORIES && <Accessories />}
                {screen === SCREEN_PROMO_PROGRAM && <PromoProgram />}
              </div>

              {screen === SCREEN_INDEX && (
                <div className={footerClasses()}>
                  <Footer config={{ ...config, source: FORM_SOURCE.WIDGET }} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({
  screen: { screen, visible, loading },
  settings: { activeDealership, isWidgetLoaded, dealerships },
  tradeIn: { partner, estimationType },
}) {
  return {
    screen,
    visible,
    loading,
    activeDealership,
    isWidgetLoaded,
    dealerships,
    partner,
    estimationType,
  }
}

export default connect(mapStateToProps)(App)
