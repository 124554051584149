import _uniq from 'lodash/uniq'
import React, { useCallback, useEffect, useState } from 'react'
import { load } from 'redux-localstorage-simple'

import CarOrderForm from './carOrderForm/main/main'
import { FORM_SOURCE } from './constants'
import {
  bootBuyModel,
  buyModelStandaloneDealershipsSetter,
  buyModelStandaloneLoader,
  clearProtections,
} from './helpers'
import { setActiveDealershipFromBuyModelStore } from './helpers/form/activeDealershipSetter'
import store from './store/store'

function BuyModelStandaloneApp({ config }) {
  const [canBoot, setCanBoot] = useState(true)

  const loadData = useCallback(
    widget_ids => {
      bootBuyModel(JSON.stringify({ data: widget_ids }))
        .then(({ data }) => {
          if (Object.keys(data).length === 0) {
            console.error('Buy Model Standalone: no widgets available')
            setCanBoot(false)
            return
          }

          // записываем ДЦ в стор
          let items = []
          for (let i in data) {
            if (data.hasOwnProperty(i)) {
              let dealership = null
              if (data[i].dealership) {
                dealership = {
                  name: data[i].dealership.name,
                  address: data[i].dealership.address,
                }
              }

              items.push({
                dealership,
                clientName: data[i].clientName,
                widget_name: data[i].widgetName,
                widget_id: i,
                car: null,
                forBuyModel: {
                  visual: data[i].visual,
                  locale: data[i].locale,
                  currency: data[i].currency,
                  clientModels: data[i].clientModels,
                  disableSmartContractLink: data[i].disableSmartContractLink,
                  defaultCountryCode: data[i].defaultCountryCode,
                  possibleCountryCodes: data[i].possibleCountryCodes,
                },
              })
            }
          }

          buyModelStandaloneDealershipsSetter(items) // Set Buy Model Dealerships

          // загружаем 1ый виджет или предустановленный виджет
          let widgetIdToLoad = Object.keys(data)[0]
          let widgetToLoad = data[widgetIdToLoad]
          let defaultWidgetIdFromConfig = config.defaultWidgetId
          if (defaultWidgetIdFromConfig && data[defaultWidgetIdFromConfig]) {
            widgetToLoad = data[defaultWidgetIdFromConfig]
            widgetIdToLoad = defaultWidgetIdFromConfig

            // ставим активный ДЦ сразу, если можно
            if (defaultWidgetIdFromConfig === widgetIdToLoad) {
              let dealershipsFromStore = store.getState().buyModelStandalone.dealerships
              let dealershipToSet = dealershipsFromStore.find(d => d.widgetId === widgetIdToLoad)
              setActiveDealershipFromBuyModelStore(dealershipToSet)
            }
          }

          const dataFromLocalStorage = load({
            states: ['form'],
            namespace: '@kodix/smartContract',
            namespaceSeparator: '/',
          })

          clearProtections(FORM_SOURCE.BUY_MODEL)

          let success = buyModelStandaloneLoader({
            visual: widgetToLoad.visual,
            locale: widgetToLoad.locale,
            currency: widgetToLoad.currency,
            widgetId: widgetIdToLoad,
            clientModels: widgetToLoad.clientModels,
            disableSmartContractLink: widgetToLoad.disableSmartContractLink,
            defaultCountryCode: widgetToLoad.defaultCountryCode,
            possibleCountryCodes: widgetToLoad.possibleCountryCodes,
            initialBoot: true,
            dataFromLocalStorage,
          })

          if (!success) {
            setCanBoot(false)
          }
        })
        .catch(e => {
          console.error(e)
          setCanBoot(false)
        })
    },
    [config.defaultWidgetId],
  )

  useEffect(() => {
    if (!config.widgetId) {
      setCanBoot(false)
      return
    }

    let formattedWidgetsIds = []

    if (typeof config.widgetId == 'string') {
      formattedWidgetsIds = [config.widgetId]
    } else if (Array.isArray(config.widgetId)) {
      let uniqueWidgets = _uniq(config.widgetId)

      uniqueWidgets.forEach(w => {
        formattedWidgetsIds.push(w)
      })
    }

    loadData(formattedWidgetsIds)
  }, [config, loadData])

  return (
    canBoot && (
      <div className="kdxsc-form-page">
        <CarOrderForm config={{ ...config, source: FORM_SOURCE.BUY_MODEL, placement: 'page' }} />
      </div>
    )
  )
}

export default BuyModelStandaloneApp
