module.exports = {
  state: {
    title: 'Financial services',
    yourCarCostsMoreThanCarYouWant: {
      auto: 'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased car',
      moto: 'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased motorcycle',
      other:
        'You cannot apply the credit program, because the cost of your car in Trade-in with all benefits and additional offers exceeds the cost of the purchased vehicle',
    },
    subtitleWithBenefit: 'Credit purchase with benefit',
    subtitleWithoutBenefit: 'Credit purchase',
    initialAdditionalText: 'Choose credit program',
    initialAdditionalTextWithoutEstimation: 'Choose credit parameters',
    additionalTextWithCreditData: '{ bank }. { program }, { rate }%, { period } mo.',
    additionalTextWithCreditParameters:
      'You have chosen a credit with an initial fee { initialFee } for a period of { period } mo.',
    buyWithCash: 'Cash purchase',
    autoEstimationIsImpossible: 'Automatic estimation is not possible',
    unavailable: 'Unavailable',
    benefitHint: {
      auto: 'This advantage applies in the case of buying this car on credit',
      moto: 'This advantage applies in the case of buying this motorcycle on credit',
      other: 'This advantage applies in the case of buying this vehicle on credit',
    },
    activeProgramHint: 'Credit payment according to the program of your choice',
    defaultProgramHint:
      'Minimum credit payment for programs with an initial payment of {initialFee}, a buyback payment of {buybackPayment} and a credit period of {period} months.',
    defaultProgramNoBuybackPaymentHint:
      'Minimum credit payment for programs with an initial payment of {initialFee} and a credit period of {period} months.',
  },
  screen: {},
  promoProgram: {
    choose: 'Apply',
    moreDetails: 'More details',
  },
}
