import { connect } from 'react-redux'

function TradeInStandaloneGenerationInput({ dictionaryValue }) {
  return <div>{dictionaryValue}</div>
}

function mapStateToProps({
  tradeIn: {
    values: {
      generation: { name: dictionaryValue },
    },
  },
}) {
  return {
    dictionaryValue,
  }
}

export default connect(mapStateToProps)(TradeInStandaloneGenerationInput)
