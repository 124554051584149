import { PLATFORM_ONE_PLATFORM } from '../../../constants'
import { onePlatform } from '../../../presets'
import { presetParams } from '../../preset_params'

export const detectModelDataHelper = (preset, detail) => {
  if (preset) {
    const [platform] = presetParams(preset)

    switch (platform) {
      case PLATFORM_ONE_PLATFORM:
        return onePlatform().detail.modelData()
      default:
        throw new Error('Unexpected preset [' + preset + ']')
    }
  }

  if (detail?.modelData) {
    if (typeof detail.modelData === 'string') {
      return detail.modelData
    } else if (typeof detail.modelData === 'function') {
      return detail.modelData()
    }
  }

  return ''
}
