import { setDealerships } from '../../../store/actions'
import { setBuyModelStandaloneDealerships } from '../../../store/actions/buy_model_standalone'
import {
  setCreditOrLeasingStandaloneDealerships,
  setCreditStandaloneDealershipsCitySetter,
} from '../../../store/actions/credit_standalone'
import { setTradeInStandaloneSetDealerships } from '../../../store/actions/trade_in_standalone'
import store from '../../../store/store'

const normalizeDealerships = data => {
  const dealerships = []

  // проходим по массиву
  data.forEach(item => {
    if (item.dealership) {
      dealerships.push({
        dealershipName: item.dealership.name,
        dealershipAddress: item.dealership.address,
        dealershipCity: item.dealership.city,
        type: 'dealership',
        showComplectations: item.showComplectations,
        showBrandInModelSelect: item.showBrandInModelSelect,
        clientName: item.clientName,
        widgetName: item.widget_name,
        widgetId: item.widget_id || item.widgetId,
        cars: item.cars,
        forTradeInStandalone: item.forTradeInStandalone || null,
        forCreditStandalone: item.forCreditStandalone || null,
        forBuyModel: item.forBuyModel || null,
      })
    } else {
      dealerships.push({
        dealershipName: '',
        dealershipAddress: '',
        type: 'client',
        clientName: item.clientName,
        widgetName: item.widget_name,
        widgetId: item.widget_id || item.widgetId,
        cars: item.cars,
        forTradeInStandalone: item.forTradeInStandalone || null,
        forCreditStandalone: item.forCreditStandalone || null,
        forBuyModel: item.forBuyModel || null,
      })
    }
  })

  return dealerships
}

export const dealershipsSetter = data => {
  const dealerships = normalizeDealerships(data)

  store.dispatch(setDealerships(dealerships))
}

export const tradeInStandaloneDealershipsSetter = data => {
  const dealerships = normalizeDealerships(data)

  store.dispatch(setTradeInStandaloneSetDealerships(dealerships))
}

export const buyModelStandaloneDealershipsSetter = data => {
  const dealerships = normalizeDealerships(data)

  store.dispatch(setBuyModelStandaloneDealerships(dealerships))
}

export const creditOrLeasingStandaloneDealershipsSetter = ({ data, calcType }) => {
  const dealerships = normalizeDealerships(data)

  store.dispatch(setCreditOrLeasingStandaloneDealerships({ calcType, dealerships }))
}
export const creditStandaloneDealershipsCitySetter = ({ data, calcType }) => {
  const city = normalizeDealerships(data)

  store.dispatch(setCreditStandaloneDealershipsCitySetter({ calcType, city }))
}
