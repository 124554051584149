import { createRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import {
  FINANCE_USER_BEHAVIOUR,
  LEASING_RATE_STATUS,
  LEASING_SETTINGS_BEHAVIOR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import { carPriceFromTI, creditAmount, leasingAmount } from '../../../helpers'
import {
  adjustLeasingBuybackPaymentPercentage,
  calculateLeasingPrograms,
  enableLeasing,
  goToIndex,
  setFinanceBehaviourByUser,
  setLeasingActiveProgram,
  setLeasingBuybackPayment,
  setLeasingBuybackPaymentPercentage,
  setLeasingInitialFee,
  setLeasingInitialFeePercentage,
  setLeasingPeriod,
} from '../../../store/actions'
import { createLeasingCalculateProgramsPayload } from '../../../store/helpers/leasing'
import BlockOne from '../../Ui/blockOne'
import Button from '../../Ui/button'
import Input from '../../Ui/input'
import InputWithRange from '../../Ui/inputWithRange'
import Program from '../../Ui/program'
import RangeTerms from '../../Ui/rangeTerms'
import Terms from '../../Ui/terms'
import Tooltip from '../../Ui/tooltip'
import TooltipTable from '../../Ui/tooltipTable'
import s from './leasing.module.scss'

function Leasing({
  leasingParameters,
  defaultConditions,
  tradeIn,
  programs,
  status,
  behaviour,
  activeProgram,
  currency,
  leasingAmount,
  periods,
  vehicleType,
  visual,
}) {
  const leasingContent = createRef()

  const leasingData = useSelector(createLeasingCalculateProgramsPayload)

  const debouncedCalculate = useDebouncedCallback(() => dispatch(calculateLeasingPrograms(leasingData)), 150)

  const calculate = useCallback(() => debouncedCalculate(), [debouncedCalculate])

  useEffect(calculate, [calculate, leasingParameters])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { showRemainingPaymentInProgram } = visual

  const {
    initialFee,
    initialFeePercentage,
    period,
    buybackPayment = 0,
    buybackPaymentPercentage = 0,
  } = leasingParameters

  const chooseProgram = p => {
    const leasingContentCurrent = leasingContent.current

    dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.LEASING_IN_PROGRESS))

    Promise.resolve(dispatch(setLeasingActiveProgram(p))).then(() => {
      setTimeout(() => {
        leasingContentCurrent.scrollTo({
          top: leasingContentCurrent.scrollHeight,
          behavior: 'smooth',
        })
      }, 300)
    })
  }

  const leasingAmountLabel = () => {
    return (
      <Tooltip position="bottom" fullHeight={true} tooltipContent={<TooltipTable type={'leasingAmount'} />}>
        <div className={s.label}>
          <div className={s.label__text}>{t(`leasing.screen.carPrice.${vehicleType}`)}</div>
          <span className={s.label__tooltip}>
            <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
                fill="#222222"
              />
            </svg>
          </span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={s.wrapper} ref={leasingContent}>
      <div className={s.wrapper__top}>
        <div className={s.wrapper__block}>
          <BlockOne title={''}>
            <div className={s.row}>
              <div className={s.row__col50}>
                <Input value={leasingAmount.toLocaleString('ru-RU')} disabled={true} label={leasingAmountLabel()} />
              </div>
              <div className={s.row__col50}>
                <InputWithRange
                  label={t('leasing.screen.initialFee')}
                  inputValue={initialFee.toLocaleString('ru-RU')}
                  rangeValue={initialFeePercentage}
                  disabled={false}
                  inputChangeHandler={e => dispatch(setLeasingInitialFee(e.target.value))}
                  rangeChangeHandler={e => dispatch(setLeasingInitialFeePercentage(e.target.value))}
                  rangeValues={{ min: 0, max: 50 }}
                />
              </div>
              {showRemainingPaymentInProgram && (
                <div className={s.row__col50}>
                  <InputWithRange
                    label={buybackPaymentLabel(t)}
                    inputValue={buybackPaymentPercentage}
                    inputChangeHandler={e => {
                      const value = e.target.value
                      const res = Math.min(Math.max(value || 0, 0), 50)
                      dispatch(setLeasingBuybackPaymentPercentage(res))
                    }}
                    inputBlurHandler={e => dispatch(adjustLeasingBuybackPaymentPercentage(e.target.value))}
                    disabled={false}
                    rangeValue={buybackPaymentPercentage}
                    rangeChangeHandler={e => dispatch(setLeasingBuybackPaymentPercentage(e.target.value))}
                    rangeValues={{ min: 0, max: 50 }}
                    isOnlyPercent={true}
                  />
                </div>
              )}
              {showRemainingPaymentInProgram && (
                <div className={s.row__col50}>
                  <RangeTerms
                    periods={periods}
                    value={period}
                    onChange={p => dispatch(setLeasingPeriod(p))}
                    label={t('leasing.screen.term')}
                  />
                </div>
              )}
            </div>
            {!showRemainingPaymentInProgram && (
              <div className={[s.row, s.mt16].join(' ')}>
                <div className={s.row__col100}>
                  <Terms
                    periods={periods}
                    period={period}
                    setPeriodHandler={p => dispatch(setLeasingPeriod(p))}
                    label={t('leasing.screen.term')}
                  />
                </div>
              </div>
            )}
          </BlockOne>
        </div>
        {status === LEASING_RATE_STATUS.PROGRAMS_RECEIVED && (
          <div className={s.wrapper__block}>
            <BlockOne title={t('leasing.screen.chooseCreditProgram', { count: programs?.length })}>
              <div className={s.programs}>
                {programs.map(program => {
                  return (
                    <div className={s.programs__item} key={program.id} onClick={() => chooseProgram(program)}>
                      <Program
                        program={program}
                        isActive={program.id === activeProgram?.id}
                        currency={currency}
                        type="leasing"
                        showRedemptionValue={false}
                        onlyOneBank={[...new Set(programs.map(p => p.bank.id))].length === 1}
                      />
                    </div>
                  )
                })}
              </div>
            </BlockOne>
          </div>
        )}
        {status === LEASING_RATE_STATUS.NO_PROGRAMS_FOUND && (
          <div className={s.wrapper__block}>
            <BlockOne title={t('leasing.screen.noPrograms.title')}>{t('leasing.screen.noPrograms.body')}</BlockOne>
          </div>
        )}
        {behaviour === LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS && (
          <div className={s.wrapper__block}>
            <BlockOne title={t('leasing.screen.withoutCalculations.title')} centered={true}>
              <div className={s.withoutCalculation}>
                <div className={s.withoutCalculation__text}>{t('leasing.screen.withoutCalculations.body')}</div>
                <div className={s.withoutCalculation__button}>
                  <Button
                    text={t('leasing.screen.withoutCalculations.button')}
                    handleClick={() => dispatch(goToIndex())}
                  />
                </div>
              </div>
            </BlockOne>
          </div>
        )}
        {status === LEASING_RATE_STATUS.ERROR && (
          <div className={s.wrapper__block}>
            <BlockOne title={t('leasing.screen.error.title')}>{t('leasing.screen.error.body')}</BlockOne>
          </div>
        )}
      </div>

      {activeProgram?.id && (
        <div className={s.wrapper__bottom}>
          <Button
            text={t('credit.choose')}
            type={'bordered'}
            handleClick={() => dispatch(enableLeasing(true, false))}
          />
        </div>
      )}
    </div>
  )
}

/**
 * Лейбл к остаточному платежу - текст + тултип
 * @returns {*}
 */
const buybackPaymentLabel = t => {
  return (
    <Tooltip position="bottom" fullHeight={true} tooltipContent={t('credit.buybackPaymentHint')}>
      <div className={`${s.label} ${s.label_indent}`}>
        <div className={s.label__text}>{t('credit.buybackPayment')}</div>
        <div className={s.label__tooltip}>
          <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.99984 11.3334C3.05424 11.3334 0.666504 8.94568 0.666504 6.00008C0.666504 3.05448 3.05424 0.666748 5.99984 0.666748C8.94544 0.666748 11.3332 3.05448 11.3332 6.00008C11.3332 8.94568 8.94544 11.3334 5.99984 11.3334ZM5.99984 10.2667C8.35625 10.2667 10.2665 8.3565 10.2665 6.00008C10.2665 3.64367 8.35625 1.73341 5.99984 1.73341C3.64342 1.73341 1.73317 3.64367 1.73317 6.00008C1.73317 8.3565 3.64342 10.2667 5.99984 10.2667ZM5.4665 3.33341H6.53317V4.40008H5.4665V3.33341ZM5.4665 5.46675H6.53317V8.66675H5.4665V5.46675Z"
              fill="#222222"
            />
          </svg>
        </div>
      </div>
    </Tooltip>
  )
}

function mapStateToProps({
  settings: {
    widget_id,
    currency,
    vehicleType,
    visual: { leasing },
  },
  car: { price },
  benefits: { selected },
  accessories: { selected: selectedAccessories },
  credit: { defaultConditions, benefitValue: creditBenefitAmount },
  leasing: { parameters: leasingParameters, programs, status, behaviour, activeProgram, benefitValue, periods },
  tradeIn: {
    rate: {
      result: { possibleCarPrice },
    },
    behaviourByUser: tradeInBehaviorByUser,
    benefitValue: tradeInBenefitAmount,
    benefitValueWithoutTradeIn: tradeInBenefitAmountWithoutTradeIn,
    ownerCarPriceEnabled,
    ownerCarPrice,
  },
}) {
  const tradeInEnabled = tradeInBehaviorByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED

  return {
    widget_id,
    programs,
    status,
    behaviour,
    leasingParameters,
    activeProgram,
    currency,
    periods,
    leasingAmount: leasingAmount(price, selected, selectedAccessories, benefitValue),
    vehicleType,
    defaultConditions,
    tradeIn: {
      tradeInEnabled,
      possibleCarPrice: carPriceFromTI(possibleCarPrice, ownerCarPrice, ownerCarPriceEnabled),
    },
    creditAmount: creditAmount({
      price,
      selectedBenefits: selected,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviorByUser,
      tradeInBenefitAmount,
      tradeInBenefitAmountWithoutTradeIn,
      creditBenefitAmount,
    }),
    visual: leasing,
  }
}

export default connect(mapStateToProps)(Leasing)
