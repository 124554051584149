import {
  CREDIT_SETTINGS_BEHAVIOR,
  FINANCE_USER_BEHAVIOUR,
  LEASING_SETTINGS_BEHAVIOR,
  TRADE_IN_USER_BEHAVIOUR,
} from '../../../constants'
import {
  bootCreditParameters,
  calculateCreditPrograms,
  clearActiveProgram,
  getDefaultCreditConditions,
  setAdditionalParameter,
  setCreditBenefitValue,
  setCreditCondition,
  setCreditDefaultSettingsAvailable,
  setCreditDisableBuybackPayment,
  setCreditOnlineApproval,
  setCreditParams,
  setCreditPeriods,
  setCreditPlaceholder,
  setCreditPromoProgram,
  setFinanceBehaviourByUser,
} from '../../../store/actions'
import store from '../../../store/store'
import { creditAmount } from '../../credit_amount'
import { periodsFormatter } from './periods'

/**
 * Установка блока финансирования
 * @param finance - все финансирование (кредит, лизинга, отсутствие покупки за наличку)
 * @param discounts - выгоды c фронта из фида
 * @param isCustomBenefitsOn - можно ли использовать discounts
 * @param price - цена авто
 * @param dataFromLocalStorage - данные из локалстораджа
 */
export const financeSetter = ({ finance, discounts, isCustomBenefitsOn, price, dataFromLocalStorage }) => {
  const state = store.getState()

  const selectedBenefits = state.benefits.selected
  const selectedAccessories = state.accessories.selected
  const tradeIn = state.tradeIn
  const tradeInCarPrice = tradeIn?.ownerCarPrice || tradeIn?.rate?.result?.possibleCarPrice || 0
  const tradeInBehaviourByUser = tradeIn?.behaviourByUser
  const tradeInBenefitValue = tradeIn?.benefitValue
  const tradeInEnabledByUser = tradeInBehaviourByUser === TRADE_IN_USER_BEHAVIOUR.APPLIED
  const creditBE = finance?.credit
  //const leasingBE = finance?.leasing // на будущее
  //const disableCashBE = finance?.disableCash
  const creditLS = dataFromLocalStorage?.credit
  //const leasingLS = dataFromLocalStorage?.leasing
  const disableCreditBuybackPayment = finance.credit.disableBuybackPayment

  const { periodsForStore: creditPeriodsForStore, numericPeriods: creditNumericPeriods } = periodsFormatter(
    creditBE.possiblePeriods,
  )
  // const { periodsForStore: leasingPeriodsForStore, numericPeriods: leasingNumericPeriods } = periodsFormatter(
  //   leasingBE.possiblePeriods,
  // )

  regularCreditDataSetter(creditBE, creditPeriodsForStore)

  if (isCreditEnabledFromSettings(creditBE)) {
    // промо программа
    if (finance?.credit?.promoProgram) {
      store.dispatch(setCreditPromoProgram(finance.credit.promoProgram))
    }

    store.dispatch(getDefaultCreditConditions())
    store.dispatch(setCreditDefaultSettingsAvailable(finance.credit.defaultSettingsAvailable))

    // выгода за кредит (с БЭ и ФЭ)
    let calculatedCreditBenefitValue = creditBenefitsSetter(creditBE, isCustomBenefitsOn, discounts)

    // ставим выгоду за кредит
    store.dispatch(setCreditBenefitValue(calculatedCreditBenefitValue))
    // прячем или нет выбор остаточного платежа
    store.dispatch(setCreditDisableBuybackPayment(disableCreditBuybackPayment))

    // сумма кредита для расчета
    const amount = creditAmount({
      price,
      selectedBenefits,
      selectedAccessories,
      tradeInUserBehaviour: tradeInBehaviourByUser,
      tradeInBenefitAmount: tradeInBenefitValue,
      tradeInBenefitAmountWithoutTradeIn: tradeIn?.benefitValueWithoutTradeIn,
      creditBenefitAmount: calculatedCreditBenefitValue,
    })

    // ставим параметры (первоначальный взнос, остаточный платеж, период) из БЭ и ЛС
    const parameters = creditParametersFormatter(
      creditBE,
      creditLS,
      amount,
      creditNumericPeriods,
      tradeInCarPrice,
      tradeInEnabledByUser,
      disableCreditBuybackPayment,
    )

    store.dispatch(setAdditionalParameter('kasko_insurance', parameters.kasko_insurance))
    store.dispatch(setAdditionalParameter('life_insurance', parameters.life_insurance))
    store.dispatch(
      bootCreditParameters(
        {
          initialFeePercentage: parameters.initialFeePercentage,
          buybackPaymentPercentage: parameters.buybackPaymentPercentage,
          period: parameters.period,
        },
        amount,
      ),
    )

    if (creditBE?.programs[0]?.payment) {
      store.dispatch(setCreditPlaceholder(creditBE.programs[0].payment))
    }

    // если есть кредитная программа в локалсторадже,
    // то делаем запрос в кредитный сервис,
    // и сравниваем ид программ и ставим программу, если она есть
    if (creditLS?.active_program?.id) {
      // сбрасываем активную программу, если с бека пришли настройки c партнёром, отличным от партнёра из ЛС
      if (creditLS?.partner && creditLS.partner !== creditBE.partner) {
        store.dispatch(clearActiveProgram)
      } else {
        const p = store.getState().credit.parameters

        const creditDataForCalculations = {
          buyback_payment_amount: p.buybackPayment,
          buyback_payment_percentage: p.buybackPaymentPercentage,
          initial_fee_amount: p.initialFee,
          initial_fee_percentage: p.initialFeePercentage,
          kasko_insurance: p.kasko_insurance,
          life_insurance: p.life_insurance,
          model_data: state.car.modelData,
          period: p.period,
          price: amount,
          productType: state.car.productType,
        }

        // placeholder, status, behaviourByUser ставится внутри
        if (state.credit?.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED) {
          store.dispatch(calculateCreditPrograms(creditDataForCalculations, true))
        } else {
          store.dispatch(calculateCreditPrograms(creditDataForCalculations))
        }
      }
    } else {
      // если есть активная программа, но кредит не applied (для кредит enabled)
      if (
        !state.credit?.active_program?.id &&
        state.credit?.behaviourByUser === FINANCE_USER_BEHAVIOUR.CREDIT_APPLIED &&
        state.credit.behaviour === CREDIT_SETTINGS_BEHAVIOR.ENABLED
      ) {
        store.dispatch(clearActiveProgram)
      }
    }
  } else {
    // сбрасываем все
    // store.dispatch(clearActiveProgram)
    if (!isLeasingEnabledFromSettings) {
      store.dispatch(setFinanceBehaviourByUser(FINANCE_USER_BEHAVIOUR.NONE))
    }
  }
}

/*
const creditSetter = () => {}
const leasingSetter = () => {}
 */

const isCreditEnabledFromSettings = credit => {
  return [CREDIT_SETTINGS_BEHAVIOR.ENABLED, CREDIT_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS].includes(
    credit.behaviour,
  )
}

const isLeasingEnabledFromSettings = leasing => {
  return [LEASING_SETTINGS_BEHAVIOR.ENABLED, LEASING_SETTINGS_BEHAVIOR.ENABLED_WITHOUT_CALCULATIONS].includes(
    leasing.condition.behaviour,
  )
}

/**
 * Возвращает сумму выгод с БЭ и ФЭ (если можно)
 * @param creditBE - данные с БЭ
 * @param isCustomBenefitsOn - можно ли использовать данные с ФЭ
 * @param discounts - скидки с ФЭ
 * @returns {number} - сумма выгод
 */
const creditBenefitsSetter = (creditBE, isCustomBenefitsOn, discounts) => {
  let calculatedCreditBenefitValue = creditBE.benefitValue

  if (isCustomBenefitsOn) {
    calculatedCreditBenefitValue += discounts.credit

    if (creditBE?.defaultKaskoInsurance) {
      calculatedCreditBenefitValue += discounts.insurance
    }
  }

  return calculatedCreditBenefitValue
}

/**
 * Данные, которые ставятся с БЭ всегда
 * @param creditBE - данные с БЭ
 * @param creditPeriodsForStore - отоформатированные периоды для установки в стор
 */
const regularCreditDataSetter = (creditBE, creditPeriodsForStore) => {
  // партнер и поведение
  store.dispatch(
    setCreditParams({
      partner: creditBE.partner,
      behaviour: creditBE.behaviour,
    }),
  )

  // кондишн
  store.dispatch(setCreditCondition(creditBE.condition))

  // онлайн одобрение
  store.dispatch(setCreditOnlineApproval(creditBE.onlineApprove))

  // периоды
  store.dispatch(setCreditPeriods(creditPeriodsForStore))
}

/**
 * Сравнение параметров БЭ с ЛС
 * @param creditBE
 * @param creditLS
 * @param amount
 * @param creditNumericPeriods
 * @param tradeInCarPrice
 * @param tradeInEnabledByUser
 * @param disableCreditBuybackPayment
 * @returns {{period: number, kasko_insurance: *, life_insurance: *, initialFeePercentage: number, buybackPaymentPercentage: number}}
 */
const creditParametersFormatter = (
  creditBE,
  creditLS,
  amount,
  creditNumericPeriods,
  tradeInCarPrice,
  tradeInEnabledByUser,
  disableCreditBuybackPayment,
) => {
  const parameters = {
    initialFeePercentage: parseInt(creditBE.defaultInitialFeePercentage),
    buybackPaymentPercentage: disableCreditBuybackPayment ? 0 : parseInt(creditBE.defaultBuybackPaymentPercentage),
    period: parseInt(creditBE.defaultPeriod),
    kasko_insurance: creditBE.defaultKaskoInsurance,
    life_insurance: creditBE.defaultLifeInsurance,
  }

  const parametersLS = creditLS?.parameters

  if (parametersLS) {
    if (disableCreditBuybackPayment) {
      parametersLS.buybackPaymentPercentage = 0
    }
    const initialFeePercentageAndBuybackPaymentPercentageSumAvailable =
      parametersLS.initialFeePercentage + parametersLS.buybackPaymentPercentage < 96

    const initialFeeAmountAndBuybackPaymentAmountSumAvailable =
      parametersLS.initialFee + parametersLS.buybackPayment < amount

    if (
      parametersLS.initialFeePercentage > 0 &&
      parametersLS.initialFeePercentage < 96 &&
      initialFeePercentageAndBuybackPaymentPercentageSumAvailable &&
      initialFeeAmountAndBuybackPaymentAmountSumAvailable
    ) {
      parameters.initialFeePercentage = parseInt(parametersLS.initialFeePercentage)
    }
    if (
      parametersLS.buybackPaymentPercentage > 0 &&
      parametersLS.buybackPaymentPercentage < 96 &&
      initialFeePercentageAndBuybackPaymentPercentageSumAvailable &&
      initialFeeAmountAndBuybackPaymentAmountSumAvailable
    ) {
      parameters.buybackPaymentPercentage = parseInt(parametersLS.buybackPaymentPercentage)
    }

    // можем ставить только допустимый год в ЛС, иначе с БЭ
    if (creditNumericPeriods.includes(parseInt(parametersLS?.period))) {
      parameters.period = parseInt(parametersLS?.period)
    }

    // считаем % первоначального взноса по стоимости автомобиля в трейд ин (0 или больше 0)
    const initialFeePercentageOfCarPriceInTradeIn = Math.ceil((tradeInCarPrice * 100) / amount)

    // считаем % остаточного платежа в зависимости от % первоначального взноса
    const buybackPaymentPercentageOfCarPriceInTradeIn = 96 - initialFeePercentageOfCarPriceInTradeIn

    if (parameters.initialFeePercentage > 96 || parameters.initialFeePercentage < 0) {
      parameters.initialFeePercentage = 0
    }

    if (parameters.buybackPaymentPercentage > 96 || parameters.buybackPaymentPercentage < 0) {
      parameters.buybackPaymentPercentage = 0
    }

    // если % ПВ меньше чем минимальный, то он = минимальному
    if (parameters.initialFeePercentage < initialFeePercentageOfCarPriceInTradeIn && tradeInEnabledByUser) {
      parameters.initialFeePercentage = initialFeePercentageOfCarPriceInTradeIn
    }

    // если % ОП больше чем максимальный, то он = максимальный
    if (parameters.buybackPaymentPercentage > buybackPaymentPercentageOfCarPriceInTradeIn && tradeInEnabledByUser) {
      parameters.buybackPaymentPercentage = 0
    }

    // parameters.kasko_insurance = parametersLS.kasko_insurance
    // parameters.life_insurance = parametersLS.life_insurance
  }

  return parameters
}
