import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { TRADE_IN_OWNERS } from '../../../constants'
import { setTradeInOwner, toggleTradeInStep } from '../../../store/actions'
import TradeInRadio from './Helpers/Radio'

function TradeInOwner({ step, id, name }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSet = ({ id, name }) => {
    dispatch(setTradeInOwner({ id, name }))
  }

  const translatedOwner = () => {
    const original = JSON.parse(JSON.stringify(TRADE_IN_OWNERS))

    for (const key in original) {
      if (!original.hasOwnProperty(key)) {
        continue
      }

      original[key].name = t(`tradeIn.owner.names.${original[key].name}`)
    }

    return original
  }

  return (
    <div className={'kdxsc-accordion-item ' + (step === 'owner' ? 'kdxsc-accordion-item--opened' : '')}>
      <div
        className="kdxsc-accordion-item__line"
        onClick={() => {
          dispatch(toggleTradeInStep('owner'))
        }}
      >
        <div className="kdxsc-accordion-item__title">
          {t('tradeIn.owner.title')}
          <div className="kdxsc-accordion-item__subtitle">{name || t('tradeIn.notSelected')}</div>
        </div>
        <span className="kdxsc-accordion-item__icon" />
      </div>

      <div className="kdxsc-accordion-item__inner">
        <div className="kdxsc-accordion-item__content">
          <TradeInRadio name="owner" variants={translatedOwner()} checked={id} onSet={onSet} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({
  tradeIn: {
    step,
    values: {
      owner: { id, name },
    },
  },
}) {
  return {
    step,
    id,
    name,
  }
}

export default connect(mapStateToProps)(TradeInOwner)
