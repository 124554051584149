import { instanceIs } from './instance'

const makeUrl = dataFromResponse => {
  const domain = instanceIs('europe') ? 'https://c.smartaline.com' : 'https://c.smart-contract.digital'
  return `${domain}/${(dataFromResponse.genericId + '-' + dataFromResponse.id).toUpperCase()}`
}

export const createdEventPayload = (widgetId, dataToSend, dataFromResponse, savedFrom = '') => ({
  widgetId,
  source: dataToSend.widgetSource,
  smartContract: {
    url: makeUrl(dataFromResponse),
    uniqueId: (dataFromResponse.genericId + '-' + dataFromResponse.id).toUpperCase(),
  },
  personalData: dataToSend.personalData,
  car: dataToSend.car || {},
  savedFrom,
})
